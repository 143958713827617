<template>
  <div class="mcd-page-shell__content mds-page-shell__content">
    <mds-layout-grid>
      <mds-row>
        <mds-col :cols="4">
          <mds-button-container>
            <mds-button
              variation="primary"
              size="small"
              :disabled="showAddWorkflowNotificationsModal"
              @click="showAddWorkflowNotifications"
            >
              Add Workflow Notification
            </mds-button>
          </mds-button-container>
          <div v-if="!showReportConfig">
            <mds-loader size="large" />
          </div>
          <mds-table
            v-if="showReportConfig"
            row-hover
            style="margin-top: 20px"
          >
            <mds-tbody>
              <template
                v-for="(row, index) in allReportsDetails.result.content"
              >
                <mds-tr
                  :key="index"
                >
                  <mds-td>
                    {{ row.name }}
                  </mds-td>
                  <mds-td>
                    <mds-button-container right-aligned>
                      <mds-button
                        variation="primary"
                        size="small"
                        @click="getReportById(index,row)"
                      >
                        Open
                      </mds-button>
                      <mds-button
                        variation="secondary"
                        size="small"
                        @click="displayDialogBox('deleteBox',row)"
                      >
                        Delete
                      </mds-button>
                    </mds-button-container>
                  </mds-td>
                </mds-tr>
              </template>
            </mds-tbody>
          </mds-table>
        </mds-col>
        <mds-col :cols="8">
          <div v-if="showLoader">
            <mds-loader size="large" />
          </div>
          <div
            v-else-if="showAddWorkflowNotificationsModal"
            :class="{ disabled: dialogType !== '' }"
          >
            <mds-form size="small">
              <h3 class="mds-header__title component-title">
                <small>{{ workflowNotificationDialogHeader }}</small>
              </h3>
              <mds-input
                v-model="workflowNotificationName"
                size="small"
                label="Name"
                maxlength="75"
                placeholder="Please provide workflow notification name"
                :required="true"
              />
              <mds-button
                :id="'config-btn'"
                icon="plus"
                size="small"
                variation="primary"
                type="button"
                @click="showAddWorkflowModal"
              >
                Add Workflows
              </mds-button>
              <div class="scrollable">
                <mds-table
                  v-if="workflowsToAdd.length > 0"
                  style="margin: 10px 10px"
                >
                  <mds-thead>
                    <mds-th> Added Workflows </mds-th>
                  </mds-thead>
                  <mds-tbody>
                    <template v-for="(row, index) in finalWorkflowToAdd">
                      <mds-tr
                        :key="index + row.name + row.id"
                      >
                        <mds-td>
                          {{ row['name'] }}
                        </mds-td>
                      </mds-tr>
                      <div
                        :id="index + 1"
                        :key="index + 99999"
                        style="padding-left: 10px;display: flex;
                        justify-content: space-between;
                        flex-direction: column;
                        width:500px"
                      >
                        <mds-table>
                          <mds-tbody>
                            <mds-tr
                              v-for="(prow, pindex) in finalWorkflowParamsToAdd.get(row.id)"
                              :key="pindex + prow.name + prow.id"
                            >
                              <mds-td>
                                <mds-icon
                                  name="triangle-fill-right"
                                  title="paramRow"
                                  size="small"
                                />
                                {{ prow['name'] }}
                              </mds-td>
                            </mds-tr>
                          </mds-tbody>
                        </mds-table>
                      </div>
                    </template>
                  </mds-tbody>
                </mds-table>
              </div>
              <mds-fieldset
                horizontal
                variation="checkbox-group"
                legend="When"
                :required="true"
              >
                <mds-checkbox
                  v-for="(key, index) in eventList "
                  :key="'field' + index"
                  :checked="key.checked"
                  name="event-checkbox"
                  class="checkbox-inline"
                  @change="addSelectedEvents(key,$event)"
                >
                  {{ key.text }}
                </mds-checkbox>
              </mds-fieldset>
            </mds-form>
            <mds-layout-grid style="margin-top: 15px;">
              <mds-row>
                <mds-col :cols="4">
                  <mds-textarea
                    v-model="recipients"
                    label="To:"
                    placeholder="Enter recipient email addresses
                                (separated by space, comma or semi-colon)"
                    :required="true"
                  />
                </mds-col>
                <mds-col :cols="8">
                  <div :class="{ 'disabled': !isScheduleEnabled }">
                    <schedule-cron
                      v-model="isScheduleEnabled"
                      :show-dialog="isScheduleEnabled"
                      :existing-cron-expr="cronExpr"
                      :existing-time-zone="timeZone"
                      @close="isScheduleEnabled = false"
                      @updateCron="handleUpdate"
                    />
                  </div>
                </mds-col>
              </mds-row>
            </mds-layout-grid>
            <mds-button-container
              style="padding-top: 10px;"
              right-aligned
            >
              <mds-button
                variation="primary"
                :disabled="finalWorkflowParamsToAdd.size == 0"
                @click="saveWorkflowNotification"
              >
                Save
              </mds-button>
              <mds-button
                variation="secondary"
                @click="closeModal"
              >
                Close
              </mds-button>
            </mds-button-container>
          </div>
          <mds-alert
            v-if="dialogType == 'successBox'"
            class="div-centered-top"
            dismissible="false"
            variation="success"
          >
            Your report has been successfully {{ alertMessage }}.
            <span><mds-button
              id="adSuccessButton"
              slot="mds-alert-custom-action"
              size="medium"
              variation="icon-only"
              icon="remove"
              style="top:5px;"
              @click="displayDialogBox('')"
            >
              close
            </mds-button></span>
          </mds-alert>
          <mds-alert
            v-if="dialogType == 'errorBox'"
            class="div-centered-top"
            dismissible="false"
            variation="error"
          >
            <div class="right-middle">
              {{ errorMessage }}<br>
              <b>{{ notMatchedArray.join() }}</b><br>
              <span><mds-button
                id="adSuccessButton"
                slot="mds-alert-custom-action"
                size="medium"
                variation="icon-only"
                icon="remove"
                style="top:5px;"
                @click="displayDialogBox('')"
              >
                close
              </mds-button></span>
            </div>
          </mds-alert>
          <mds-alert
            v-if="dialogType == 'deleteBox'"
            title="Delete Workflow Notifictaion"
            variation="warning"
            class="div-centered"
            dismissible="false"
          >
            Are you sure you want to delete report <b>{{ deleteReport.name }}</b> ?<br><br>
            <mds-button-container
              right-aligned
            >
              <mds-button
                size="medium"
                variation="secondary"
                @click="displayDialogBox('')"
              >
                Cancel
              </mds-button>
              <mds-button
                size="medium"
                variation="primary"
                @click="deleteReportById(deleteReport)"
              >
                Yes, Proceed
              </mds-button>
            </mds-button-container>
          </mds-alert>
        </mds-col>
      </mds-row>
    </mds-layout-grid>
    <mds-dialog
      v-if="toggleWorkflowAddModal"
      v-model="toggleWorkflowAddModal"
      action-required
    >
      <div v-if="showWorkflowAddTable">
        <mds-search-field
          v-model="searchFilter"
          placeholder="Search Workflow"
          label="Search"
          style="width: 50%;margin-bottom: 1%;"
          @mds-search-field-input-cleared="clearSearchBar"
        />
        <mds-table>
          <mds-thead>
            <mds-th
              v-for="(header, index) in workflowTableHeaders"
              :key="index"
              style="padding-left: 30px;"
            >
              {{ header.text }}
            </mds-th>
          </mds-thead>
        </mds-table>
        <mds-table
          multiselection
          row-hover
        >
          <mds-thead
            hidden-header
          >
            <mds-th
              v-for="(header, index) in workflowTableHeaders"
              :key="index"
            >
              {{ header.text }}
            </mds-th>
          </mds-thead>
          <mds-tbody>
            <template
              v-for="(row, index) in paginatedData"
            >
              <mds-tr
                :key="index"
                type="expandable"
                :expanded="row.expanded"
                :aria-controls="index + 1"
                :checked="row.checked"
                :multiselection-label="row.name"
                @click.native="toggleRow(row.index, $event)"
                @keyup.enter.native="toggleRow(row.index, $event)"
                @mds-tr-multiselection-changed="handleRowSelectEvent(row.index, $event)"
              >
                <mds-td
                  v-for="(header, i) in workflowTableHeaders"
                  :key="i"
                >
                  {{ row[header.fieldName] }}
                </mds-td>
              </mds-tr>
              <div
                v-if="row.expanded"
                :id="index + 1"
                :key="index + 99999"
                style="padding-left: 20px;display: flex;
                justify-content: space-between;
                width:500px"
              >
                <div
                  v-if="showParamLoader"
                >
                  <mds-loader size="small" />
                </div>
                <mds-table
                  v-else
                  multiselection
                  row-hover
                >
                  <mds-tbody>
                    <mds-tr
                      v-for="(pRow, tIndex) in workflowParams[row.index].workFlowJobModel
                        .parameterSetGroupModel.parameterSetModels"
                      :key="tIndex"
                      :checked="pRow.checked"
                      :multiselection-label="pRow.name"
                      @mds-tr-multiselection-changed="handleParameterRowSelectEvent(row.index,row.id,pRow,$event)"
                    >
                      <mds-td
                        v-for="(header, i) in paramHeaders"
                        :key="i"
                        style="text-align: left;"
                      >
                        {{ pRow[header.fieldName] }}
                      </mds-td>
                    </mds-tr>
                  </mds-tbody>
                </mds-table>
              </div>
            </template>
          </mds-tbody>
        </mds-table>
        <mds-pagination
          :total-items="activeWorkflowList.length"
          show-items-info
          :page="paginationOptions.page"
          :page-sizes="pageSize"
          @mds-pagination-page-changed="handlePageChange"
        />
      </div>
      <template #mds-dialog-actions-right>
        <mds-button-container
          style="padding-top: 10px;"
          right-aligned
        >
          <mds-button
            variation="primary"
            @click="showSaveWorkflowsModal"
          >
            Save
          </mds-button>
          <mds-button
            variation="secondary"
            @click="closeWorkflowAddModal"
          >
            Close
          </mds-button>
        </mds-button-container>
      </template>
    </mds-dialog>
  </div>
</template>

<script>
import { MdsLayoutGrid, MdsRow, MdsCol } from '@mds/layout-grid';
import {
  MdsTable, MdsThead, MdsTh, MdsTbody, MdsTr, MdsTd,
} from '@mds/data-table';
import { MdsButton, MdsButtonContainer } from '@mds/button';
import MdsForm from '@mds/form';
import MdsIcon from '@mds/icon';
import MdsCheckbox from '@mds/checkbox';
import MdsFieldset from '@mds/fieldset';
import MdsTextarea from '@mds/textarea';
import MdsInput from '@mds/input';
import MdsDialog from '@mds/dialog';
import MdsSearchField from '@mds/search-field';
import MdsPagination from '@mds/pagination';
import MdsLoader from '@mds/loader';
import { mapActions, mapGetters } from 'vuex';
import axios from 'axios';
import { getUserName } from '../../utils/authService';
import ScheduleCron from '../Scheduler/ScheduleCron.vue';


export default {
  name: 'WorkflowNotifications',
  components: {
    MdsLayoutGrid,
    ScheduleCron,
    MdsCol,
    MdsRow,
    MdsIcon,
    MdsTextarea,
    MdsDialog,
    MdsFieldset,
    MdsCheckbox,
    MdsTable,
    MdsThead,
    MdsTh,
    MdsTbody,
    MdsTr,
    MdsTd,
    MdsButton,
    MdsButtonContainer,
    MdsForm,
    MdsInput,
    MdsPagination,
    MdsLoader,
    MdsSearchField,
  },
  props: {

  },
  data() {
    return {
      allWorkflowDetails: [],
      toggle: true,
      showAddWorkflowNotificationsModal: false,
      showScheduleCronDialog: false,
      showParamLoader: false,
      showSearchLoader: false,
      showLoader: false,
      toggleWorkflowAddModal: false,
      isScheduleEnabled: false,
      isNew: true,
      workflowsToAdd: [],
      finalWorkflowToAdd: [],
      workflowParams: [],
      allReportsDetails: [],
      notMatchedArray: [],
      reportDetails: {},
      workflowParamsToAdd: new Map(),
      finalWorkflowParamsToAdd: new Map(),
      selectedEvents: new Set(),
      cronExpr: '0 0 16 ? * MON-FRI *',
      timeZone: 'America/Chicago',
      workflowNotificationName: '',
      recipients: '',
      currentReportId: '',
      errorMessage: '',
      alertMessage: '',
      deleteReport: '',
      dialogType: '',
      searchFilter: '',
      paginationOptions: {
        firstItem: 1,
        pageSize: 15,
        page: 1,
      },
      pageSize: [15, 30, 50, -1],
      workflowTableHeaders: [
        {
          text: 'Workflow',
          fieldName: 'name',
        },
        {
          text: 'Owner',
          fieldName: 'owner',
        },
      ],
      paramHeaders: [
        {
          text: 'Parameter',
          fieldName: 'name',
        },
        {
          text: 'Description',
          fieldName: 'description',
        },
      ],
      eventList: [{
        fieldName: 'first_start',
        text: 'a workflow starts',
      },
      {
        fieldName: 'last_complete',
        text: 'a workflow completes',
      },
      {
        fieldName: 'any_start',
        text: 'a curve starts',
      },
      {
        fieldName: 'any_complete',
        text: 'a curve ends',
      },
      {
        fieldName: 'scheduled',
        text: 'scheduled',
      }],
      contentPayload: {
        name: '',
        desc: '',
        recipients: [],
        workflows: [],
        cron: '',
        timezone: '',
      },
      statusPayload: {
        jsonrpc: '2.0',
        method: '',
        params: {},
      },
      paramsPayload: {
        path: '/reportcfgs',
      },
    };
  },
  computed: {
    showWorkflowAddTable() {
      return this.allWorkflowDetails.length > 0;
    },
    fullCronExpr() {
      return `${this.cronExpr} (${this.timeZone})`;
    },
    paginatedData() {
      // set new start and end values based on the updated pagination options
      const start = this.paginationOptions ? this.paginationOptions.firstItem - 1 : 0;
      const end = this.paginationOptions && this.paginationOptions.pageSize !== -1
        ? start + this.paginationOptions.pageSize
        : this.activeWorkflowList.length;

      // use the start and end values to return the visible page slice
      return this.activeWorkflowList.slice(start, end);
    },
    showReportConfig() {
      return 'result' in this.allReportsDetails;
    },
    workflowNotificationDialogHeader() {
      let name = 'Update Worflow Notification';
      if (this.isNew) {
        name = 'Create Worflow Notification';
      }
      return name;
    },
    activeWorkflowList() {
      return this.searchFilter === ''
        ? this.allWorkflowDetails
        : this.allWorkflowDetails.filter(
          wf => wf.name.toLowerCase().indexOf(this.searchFilter.toLowerCase()) > -1,
        );
    },
  },
  watch: {
    searchFilter(newVal) {
      if (newVal !== '') {
        this.paginationOptions = {
          firstItem: 1,
          pageSize: 15,
          page: 1,
        };
      }
    },
  },
  mounted() {
    this.getAllReports(false);
    this.showAddWorkflowNotifications();
  },
  methods: {
    ...mapActions('workflowModule', ['getAllWorkflowsDetails']),
    async showAddWorkflowNotifications() {
      this.showLoader = true;
      if (!this.showWorkflowAddTable) {
        const response = await this.getAllWorkflowsDetails();
        this.allWorkflowDetails = response.data;
      }
      const alreadyAddedWfs = this.workflowsToAdd.map(wf => wf.id);
      this.allWorkflowDetails.forEach((row, index) => {
        this.$set(this.allWorkflowDetails[index], 'index', index);
        this.$set(this.allWorkflowDetails[index], 'expanded', false);
        if (alreadyAddedWfs.includes(row.id)) {
          row.checked = true;
        }
      });
      this.showLoader = false;
      this.showAddWorkflowNotificationsModal = true;
    },
    displayDialogBox(dialogValue, report) {
      this.dialogType = dialogValue;
      this.deleteReport = report;
    },
    calculateAddAndRemove() {
      const checkedWf = this.allWorkflowDetails.filter(wf => wf.checked);
      // wfs to add
      const alreadyAddedWfs = this.workflowsToAdd.map(wf => wf.id);
      this.workflowsToAdd.push(...(checkedWf.filter(wf => !alreadyAddedWfs.includes(wf.id))));
      // wfs to remove
      const uncheckedWf = this.allWorkflowDetails.filter(wf => !wf.checked);
      const uncheckedWfIds = uncheckedWf.map(wf => wf.id);
      this.workflowsToAdd = this.workflowsToAdd.filter(wf => !uncheckedWfIds.includes(wf.id));
    },
    showSaveWorkflowsModal() {
      this.calculateAddAndRemove();
      this.finalWorkflowParamsToAdd = new Map(this.workflowParamsToAdd);
      this.finalWorkflowToAdd = JSON.parse(JSON.stringify(this.workflowsToAdd));
      this.toggleWorkflowAddModal = !this.toggleWorkflowAddModal;
    },
    showAddWorkflowModal() {
      this.searchFilter = '';
      this.toggleWorkflowAddModal = true;
    },
    /**
     * Creates payload and executes post api to save report
     */
    async saveWorkflowNotification() {
      if (!this.validateInputs()) {
        return;
      }
      let psetsArray = [];
      this.contentPayload.name = this.workflowNotificationName;
      this.contentPayload.recipients = this.recipients.trim().toLowerCase().split(new RegExp('[,;\\s]+', 'g'));
      this.contentPayload.cron = this.isScheduleEnabled ? this.cronExpr : '';
      this.contentPayload.timezone = this.timeZone;
      this.finalWorkflowParamsToAdd.forEach((value, key) => {
        const workflowDetailsPayload = {
          id: '',
          events: [],
          psets: [],
        };
        workflowDetailsPayload.id = key;
        workflowDetailsPayload.events = Array.from(this.selectedEvents);
        psetsArray = [];
        if (value) {
          value.forEach((parameter) => {
            psetsArray.push(parameter.uuid);
          });
        }
        workflowDetailsPayload.psets = psetsArray;
        this.contentPayload.workflows.push(workflowDetailsPayload);
      });

      this.statusPayload.method = this.isNew ? 'POST' : 'PUT';
      this.paramsPayload.accept = 'application/json';
      this.paramsPayload.path = this.isNew ? '/reportcfgs' : `/reportcfgs/${this.currentReportId}`;
      this.$set(this.paramsPayload, 'content-type', 'application/json');
      this.$set(this.paramsPayload, 'content', this.contentPayload);
      this.$set(this.statusPayload, 'params', this.paramsPayload);

      const isValidRecipients = await this.validateEmail();
      if (this.dialogType === 'errorBox') {
        return;
      }
      await axios.post('/api/workflows/reportcfgs', this.statusPayload, {
        timeout: 30000, // Timeout in milliseconds (e.g., 30 seconds)
      }).then((response) => {
        if (response && (response.status === 200) && !response.data[0]) {
          this.dialogType = 'successBox';
          this.alertMessage = this.isNew ? 'saved' : 'updated';
        } else {
          this.errorMessage = response.Message || response.data[0][0].Message;
          this.dialogType = 'errorBox';
        }
      }).catch((error) => {
        this.errorMessage = 'Error occured while saving workflow reports';
        this.dialogType = 'errorBox';
      }).finally(() => {
        this.getAllReports(true);
        this.closeModal();
      });
    },
    validateInputs() {
      if (this.workflowNotificationName.trim() === '') {
        this.errorMessage = 'Please provide name for workflow report';
        this.dialogType = 'errorBox';
        return false;
      } if (this.recipients === '') {
        this.errorMessage = 'Please provide valid emailIds for workflow report';
        this.dialogType = 'errorBox';
        return false;
      } if (this.selectedEvents.size === 0) {
        this.errorMessage = 'Please provide atleast one trigger for workflow report';
        this.dialogType = 'errorBox';
        return false;
      } if (this.isScheduleEnabled && this.cronExpr === '') {
        this.errorMessage = 'Please provide schedule information';
        this.dialogType = 'errorBox';
        return false;
      }
      const contentArray = this.allReportsDetails.result.content;
      let hasMatch = contentArray.some(report => report.name === this.workflowNotificationName);
      if (this.isNew && hasMatch) {
        this.errorMessage = `A Report named ${this.workflowNotificationName} already exists`;
        this.dialogType = 'errorBox';
        return false;
      }

      if (!this.isNew) {
        hasMatch = contentArray.filter(report => report.name !== this.reportDetails.name)
          .some(report => report.name === this.workflowNotificationName);
        if (hasMatch) {
          this.errorMessage = `A Report named ${this.workflowNotificationName} already exists`;
          this.dialogType = 'errorBox';
          return false;
        }
      }


      return true;
    },
    /**
     * Retrive all reports associated with user
     * @param {*} isRefresh
     */
    async getAllReports(isRefresh) {
      const parameterPayLoad = {};
      parameterPayLoad.path = '/reportcfgs';
      parameterPayLoad.accept = 'application/json';
      this.statusPayload.method = 'GET';
      this.statusPayload.params = parameterPayLoad;
      if (!isRefresh && this.showReportConfig) {
        return;
      }

      await axios.post('/api/workflows/reportcfgs', this.statusPayload, {
        timeout: 30000, // Timeout in milliseconds (e.g., 30 seconds)
      }).then(async (response) => {
        if (Object.keys(response.data).length !== 0) {
          this.allReportsDetails = response.data;
        } else {
          this.errorMessage = 'Error occured while fetching user configured workflow reports';
          this.dialogType = 'errorBox';
        }
      }).catch((error) => {
        this.errorMessage = 'Error occured while fetching user configured workflow reports';
        this.dialogType = 'errorBox';
      });
    },
    /**
     * Retrive report based on report ID
     * @param {*} index
     * @param {*} report
     */
    async getReportById(index, report) {
      this.showLoader = true;
      const parameterPayLoad = {};
      parameterPayLoad.path = `/reportcfgs/${report.id}`;
      parameterPayLoad.accept = 'application/json';
      this.statusPayload.method = 'GET';
      this.statusPayload.params = parameterPayLoad;

      await axios.post('/api/workflows/reportcfgs', this.statusPayload, {
        timeout: 30000, // Timeout in milliseconds (e.g., 30 seconds)
      }).then(async (response) => {
        if (Object.keys(response.data).length !== 0 && response.data.result.status === 200) {
          this.reportDetails = response.data.result.content;
          await this.setComponentData();
          this.finalWorkflowParamsToAdd = new Map(this.workflowParamsToAdd);
          this.finalWorkflowToAdd = JSON.parse(JSON.stringify(this.workflowsToAdd));
          this.isNew = false;
          this.currentReportId = report.id;
        } else {
          this.errorMessage = 'Error occured fetching workflow report';
          this.dialogType = 'errorBox';
        }
      }).catch((error) => {
        this.errorMessage = 'Error occured fetching workflow report';
        this.dialogType = 'errorBox';
      });
      this.showAddWorkflowNotificationsModal = true;
      this.showLoader = false;
    },
    async setComponentData() {
      this.clearComponentData();
      this.cronExpr = this.reportDetails.cron === '' ? this.cronExpr : this.reportDetails.cron;
      if (this.reportDetails.cron !== '') {
        this.isScheduleEnabled = true;
      }
      this.timeZone = this.reportDetails.timezone;
      this.recipients = this.reportDetails.recipients.join(',');
      this.workflowNotificationName = this.reportDetails.name;

      // Set Workflow and Parameter Sets
      if (!this.showWorkflowAddTable) {
        const response = await this.getAllWorkflowsDetails();
        this.allWorkflowDetails = response.data;
      }
      const apiPromises = [];
      this.reportDetails.workflows.forEach((workflowResponse) => {
        const apiPromise = this.setWorkflowAndParameterSet(workflowResponse);
        apiPromises.push(apiPromise);
      });
      await Promise.all(apiPromises);
    },
    async setWorkflowAndParameterSet(workflowResponse) {
      const wfIndex = this.allWorkflowDetails.findIndex(
        workflow => workflow.id === workflowResponse.id,
      );
      await this.getWorkflowParamAndUUID(wfIndex);
      // sets checked state for selected workflows and parameters
      this.$set(this.allWorkflowDetails[wfIndex], 'checked', true);
      this.workflowsToAdd.push(this.allWorkflowDetails[wfIndex]);
      const parameterSet = workflowResponse.psets;
      if (!this.workflowParamsToAdd[workflowResponse.id]) {
        this.workflowParamsToAdd.set(workflowResponse.id, []);
      }
      parameterSet.forEach((uuid) => {
        this.workflowParams[wfIndex].workFlowJobModel.parameterSetGroupModel.parameterSetModels.forEach((params) => {
          if (params.uuid === uuid) {
            this.$set(params, 'checked', true);
            this.workflowParamsToAdd.get(workflowResponse.id).push(params);
          }
        });
      });
      workflowResponse.events.forEach((selectedEvent) => { // set event details
        this.eventList.forEach((event) => {
          if (event.fieldName === selectedEvent) {
            event.checked = true;
            this.selectedEvents.add(event.fieldName);
          }
          if (event.fieldName === 'scheduled' && this.isScheduleEnabled) {
            event.checked = true;
          }
        });
      });
    },
    /**
     * Delete Workflow report based on ID
     * @param {*} report
     */
    async deleteReportById(report) {
      const parameterPayLoad = {};
      this.dialogType = '';
      parameterPayLoad.path = `/reportcfgs/${report.id}`;
      this.statusPayload.method = 'DELETE';
      this.statusPayload.params = parameterPayLoad;

      await axios.post('/api/workflows/reportcfgs', this.statusPayload, {
        timeout: 30000, // Timeout in milliseconds (e.g., 30 seconds)
      }).then((response) => {
        if (response && response.status === 200 && !response.data[0]) {
          this.dialogType = 'successBox';
          this.alertMessage = 'deleted';
        } else {
          this.errorMessage = response.Message || response.data[0][0].Message;
          this.dialogType = 'errorBox';
        }
      }).catch((error) => {
        this.errorMessage = 'Error occured while deleted Workflow Report';
        this.dialogType = 'errorBox';
      }).finally(() => {
        this.getAllReports(true);
        this.closeModal();
      });
    },
    async getWorkflowParamAndUUID(index) {
      const workflowId = this.allWorkflowDetails[index].id;
      if (this.workflowParams[index]) {
        return;
      }
      await axios.get(`/api/workflows/${workflowId}/parameter-set-groups`, {
        timeout: 30000, // Timeout in milliseconds (e.g., 30 seconds)
      }).then(async (response) => {
        if (Object.keys(response.data).length !== 0) {
          this.$set(this.workflowParams, index, response.data);
        }
      }).catch((error) => {
        if (error.code === 'ECONNABORTED') {
          this.$set(this.workflowParams, index, {});
        } else {
          this.errorMessage = 'Error occured while fetching parameter-set-groups';
          this.dialogType = 'errorBox';
          this.$set(this.allWorkflowDetails[index], 'expanded', false);
        }
      });
    },
    /**
     * Validates email address provided in report, user needs to present in LDS to be validated.
     */
    async validateEmail() {
      const trimmedRecipient = this.recipients.trim().toLowerCase();
      let recipientsArray = trimmedRecipient.split(new RegExp('[,;\\s]+', 'g'));
      recipientsArray = recipientsArray.map(email => email.trim());
      this.notMatchedArray = [];
      const params = {
        users: recipientsArray.join(','),
      };
      await axios.get('/api/users/stats', { params }, {
        timeout: 30000, // Timeout in milliseconds (e.g., 30 seconds)
      }).then((response) => {
        const respnseData = response.data;
        if (response && (response.status === 200 || response.status === 304)) {
          if (Object.keys(respnseData).length > 0) {
            recipientsArray.forEach((recipient) => {
              if (!(recipient in respnseData)) {
                this.notMatchedArray.push(recipient);
              }
            });
          } else {
            this.notMatchedArray.push(recipientsArray);
          }
          if (this.notMatchedArray.length > 0) {
            this.errorMessage = 'The following user IDs are not recognized:';
            this.dialogType = 'errorBox';
          }
        } else {
          this.errorMessage = response.data[0].message;
          this.dialogType = 'errorBox';
        }
      }).catch((error) => {
        this.errorMessage = 'Error occured while validating emails';
        this.dialogType = 'errorBox';
      });
    },
    /**
     * Add all workflow parametersets associated with selected workflow.
     * @param {*} index
     * @param {*} isChecked
     */
    async handleRowSelectEvent(index, isChecked) {
      // set the checked property for the workflow row to the checked value of the checkbox
      if (this.allWorkflowDetails[index].checked !== isChecked) {
        this.$set(this.allWorkflowDetails[index], 'checked', isChecked);
        this.calculateAddAndRemove();
        this.$set(this.allWorkflowDetails[index], 'expanded', true);
        const status = this.allWorkflowDetails[index].expanded;
        if (status && isChecked) {
          this.showParamLoader = true;
          await this.getWorkflowParamAndUUID(index);
          this.showParamLoader = false;
        }
        this.addAllWorkflowParamsForWorkflow(index, isChecked);
      }
    },
    async toggleRow(index, event) {
      if (event.target.tagName.toLowerCase() !== 'td') {
        return;
      }
      const status = this.allWorkflowDetails[index].expanded
        ? !this.allWorkflowDetails[index].expanded
        : true;
      this.$set(this.allWorkflowDetails[index], 'expanded', status);
      if (status) {
        this.showParamLoader = true;
        await this.getWorkflowParamAndUUID(index);
        this.showParamLoader = false;
      }
    },
    addSelectedEvents(selectedEvent, isChecked) {
      if (isChecked) {
        if (selectedEvent.fieldName === 'scheduled') {
          this.isScheduleEnabled = true;
        } else {
          this.selectedEvents.add(selectedEvent.fieldName);
        }
        selectedEvent.checked = isChecked;
      } else {
        selectedEvent.checked = isChecked;
        if (selectedEvent.fieldName === 'scheduled') {
          this.isScheduleEnabled = false;
        } else {
          this.selectedEvents.delete(selectedEvent.fieldName); // Remove on uncheck
        }
      }
    },
    getSelectedValuesArray() {
      return Array.from(this.selectedValues);
    },
    addAllWorkflowParamsForWorkflow(rowIndex, isChecked) {
      const workflowId = this.allWorkflowDetails[rowIndex].id;
      if (isChecked && this.workflowParams[rowIndex]) {
        if (!this.workflowParamsToAdd.get(workflowId)) {
          this.workflowParamsToAdd.set(workflowId, []);
        }
        this.workflowParams[rowIndex].workFlowJobModel.parameterSetGroupModel.parameterSetModels.forEach((params, index) => {
          this.workflowParamsToAdd.get(workflowId).push(params);
          this.$set(params, 'checked', isChecked);
        });
      } else if (!isChecked && this.workflowParamsToAdd.get(workflowId)) {
        this.workflowParams[rowIndex].workFlowJobModel.parameterSetGroupModel.parameterSetModels.forEach((params, index) => {
          this.$set(params, 'checked', isChecked);
        });
        this.workflowParamsToAdd.delete(workflowId);
      }
    },
    handleUpdate(cron, timezone) {
      if (timezone === '') timezone = 'America/Chicago';
      this.cronExpr = cron;
      this.timeZone = timezone;
    },
    handleParameterRowSelectEvent(index, workflowId, pRow, isChecked) {
      if (isChecked && !pRow.checked) {
        if (!this.workflowParamsToAdd.get(workflowId)) {
          this.workflowParamsToAdd.set(workflowId, []);
        }
        this.workflowParamsToAdd.get(workflowId).push(pRow);
        this.$set(pRow, 'checked', isChecked);
        this.$set(this.allWorkflowDetails[index], 'checked', true);
        this.calculateAddAndRemove();
      } else if (!isChecked && this.workflowParamsToAdd.get(workflowId)) {
        const workflowPRows = this.workflowParamsToAdd.get(workflowId);
        const indexToRemove = workflowPRows.findIndex(
          data => data === pRow,
        );
        if (indexToRemove !== -1) {
          workflowPRows.splice(indexToRemove, 1);
          if (workflowPRows.length === 0) {
            this.workflowParamsToAdd.delete(workflowId);
            this.$set(this.allWorkflowDetails[index], 'checked', false);
            this.calculateAddAndRemove();
          }
        }
        this.$set(pRow, 'checked', isChecked);
      }
    },
    handlePageChange(paginationOptions) {
      // on page change, retrieve the new pagination options from the pagination component
      this.paginationOptions = paginationOptions;
    },
    closeModal() {
      this.clearComponentData();
      this.showAddWorkflowNotificationsModal = !this.showAddWorkflowNotificationsModal;
    },
    closeWorkflowAddModal() {
      this.workflowParamsToAdd = new Map(this.finalWorkflowParamsToAdd);
      this.workflowsToAdd = JSON.parse(JSON.stringify(this.finalWorkflowToAdd));
      this.toggleWorkflowAddModal = !this.toggleWorkflowAddModal;
    },
    clearSearchBar() {
      this.searchFilter = '';
    },
    clearComponentData() {
      this.workflowsToAdd = [];
      this.finalWorkflowToAdd = [];
      this.notMatchedArray = [];
      this.workflowParamsToAdd = new Map();
      this.finalWorkflowParamsToAdd = new Map();
      this.selectedEvents = new Set();
      this.workflowNotificationName = '';
      this.cronExpr = '0 0 16 ? * MON-FRI *';
      this.timeZone = 'America/Chicago';
      this.isNew = true;
      this.showScheduleCronDialog = false;
      this.currentReportId = '';
      this.recipients = '';
      this.eventList.forEach((event) => { // reset eventlist
        event.checked = false;
      });
      this.allWorkflowDetails.forEach((workflow, index) => { // reset worflowdetails and parameter details
        workflow.expanded = false;
        workflow.checked = false;
        if (this.workflowParams[index]) {
          this.workflowParams[index].workFlowJobModel.parameterSetGroupModel.parameterSetModels.forEach((params) => {
            this.$set(params, 'checked', false);
          });
        }
      });

      this.contentPayload = {
        name: '',
        desc: '',
        recipients: [],
        workflows: [],
        cron: '',
        timezone: '',
      };
      this.statusPayload = {
        jsonrpc: '2.0',
        method: '',
        params: {},
      };
      this.paramsPayload = {
        path: '/reportcfgs',
      };
      this.deleteReport = '';
      this.isScheduleEnabled = false;
    },
  },
};
</script>

<style>

.scrollable {
  margin: 15px 0px;
  height: 220px;
  width: 700px;
  overflow-y: auto;
  overflow-x: auto;
  background-color: #FFFFFF;
  border: 1px solid #AAAAAA;
}
.div-centered-top {
    position: fixed;
    top: 20%;
    left: 60%;
    transform: translate(-60%, -50%);
    z-index: 9999;
}
.disabled {
  opacity: 0.5;
  pointer-events: none;
}
.right-middle {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
</style>
