<template>
  <div class="page-container">
    <template v-if="showPageError">
      <div class="reports-error">
        <mds-notification
          visible
          title="ERROR"
          status="error"
          dismissible="false"
        >
          {{ pageError !== ''? pageError : $t('labels.feed.feedListError') }}
        </mds-notification>
      </div>
    </template>
    <template v-else>
      <div class="page">
        <div
          v-if="hideSourcesPanel"
          class="btn"
        >
          <mds-button
            style="transform: translate(10px, 25px) rotate(90deg);"
            variation="flat"
            size="small"
            @click="showSourcePanel"
          >
            SOURCES
          </mds-button>
        </div>
        <FeedSource v-if="!hideSourcesPanel" />
        <FeedList @hide-sources="hideSources" />
        <FeedKeyDeleteKeys />
        <DataDeleteOptions />
      </div>
    </template>
  </div>
</template>

<script>
import { FEATURES } from '@/utils/constants.js';
import FeedSource from './FeedSources';
import FeedList from './FeedList';
import FeedKeyDeleteKeys from './FeedKeyDeleteKeys';
import DataDeleteOptions from './DataDeleteOptions.vue';

export default {
  name: 'FeedKeyDelete',
  components: {
    FeedKeyDeleteKeys,
    FeedList,
    FeedSource,
    DataDeleteOptions,
  },
  data() {
    return {
      hideSourcesPanel: false,
      showPageError: false,
      pageError: '',
      tabsContent: [{
        text: 'Data Preview',
        id: 'data',
        active: true,
      },
      {
        text: 'Metadata',
        id: 'metadata',
        active: false,
      },
      ],
    };
  },
  created() {
    if ((this.isUserEntitled && !this.isUserEntitled([FEATURES.FEED_MANAGEMENT]))) {
      this.showPageError = true;
      this.pageError = this.$t('labels.common.featureNotEntitled');
    }
  },
  methods: {
    hideSources() {
      if (!this.hideSourcesPanel) {
        this.hideSourcesPanel = !this.hideSourcesPanel;
      }
    },
    showSourcePanel() {
      this.hideSourcesPanel = !this.hideSourcesPanel;
    },
    setActiveItem(event) {
      this.tabsContent.forEach((item) => {
        item.active = item.id === event.currentTarget.id;
      });
    },
  },
};
</script>

<style lang="scss">
  @import '~@mds/constants';
  @import '~@mds/fonts';
  @import '~@mds/typography';

.page-container {
  border: solid 1px;
  width: 100%;
  margin: auto;
  color: #65778D;
  -webkit-box-shadow: 0 0 2px 0 rgba(131,131,131,0.52);
  box-shadow: 0 0 5px 0 rgba(131,131,131,0.52);
}
.page {
  display: flex;
}
  .btn {
    box-sizing: border-box;
    background-color: white;
    border: 7px solid whitesmoke;
  }

</style>
