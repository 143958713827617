const state = {
  searchedWorkflows: [],
  userWorkflows: [],
  paramSet: {},
  formulae: {},
  currentWorkflowManager: {},
  selectedWorkflow: {
    workflowUI: {},
    paramSet: {},
    formulae: [],
    newlyAddedFormule: [],
    operation: '',
    nodes: {},
    targets: {},
    links: {},
  },
  createNewWorkflow: {
    workflowUI: {},
    paramSet: {},
    newlyAddedFormule: [],
    operation: 'create',
    nodes: {},
    targets: {},
    links: {},
  },
  allWorkflowsForName: [],
  formulaDataNodeSet: {},
  workflowName: '',
  currentUnsavedFormula: '',
  removedFormulae: [],
};
export default state;
