<template>
  <div id="user-management" class="mcd-page-shell__content mds-page-shell__content">
    <not-entitled-user v-if="showPageError" />
    <div v-else>
      <div
        v-if="overlay"
        id="mds-overlay"
        class="mds-overlay"
      />
      <mds-loader
        v-show="showloader"
        class="div-centered"
        size="large"
      />
      <mds-layout-grid>
        <div class="border-bottom-solid">
          <mds-row>
            <mds-col :cols="8">
              <h1 class="mds-header__title component-title">
                <small>{{ $t('labels.userManagement.name') }}</small>
              </h1>
            </mds-col>
            <mds-col :cols="4">
              <mds-search-field
                v-model="searchFilter"
                placeholder="Search"
                label="Search"
                @keyup.prevent="filterKeywords($event)"
                @mds-search-field-input-cleared="clearSearchBar"
              />
            </mds-col>
          </mds-row>
        </div>
        <div class="custom-padding">
          <mds-row>
            <mds-col :cols="6">
              <mds-row>
                <mds-col :cols="2">
                  <label class="mds-form__label custom-margin">
                    {{ $t('labels.userManagement.company') }}
                  </label>
                </mds-col>
                <mds-col
                  :cols="10"
                >
                  <mds-combo-box
                    ref="companyComboRef"
                    label=""
                    placeholder="Select Company"
                    :data-set="activeCompanyList"
                    @input="getCompanyName"
                  />
                </mds-col>
              </mds-row>
            </mds-col>
            <mds-col
              :cols="6"
            >
              <mds-row>
                <mds-col
                  :cols="4"
                >
                  <label class="mds-form__label custom-margin">
                    {{ $t('labels.userManagement.active') }} = {{ displayActive }}
                  </label>
                </mds-col>
                <mds-col
                  :cols="4"
                >
                  <label class="mds-form__label custom-margin">
                    {{ $t('labels.userManagement.inactive') }} = {{ displayInActive }}
                  </label>
                </mds-col>
              </mds-row>
            </mds-col>
          </mds-row>
        </div>
        <div
          v-if="showActive"
          class="custom-padding"
        >
          <mds-row>
            <mds-col :cols="2">
              <label class="mds-form__label custom-margin">
                {{ $t('labels.userManagement.active') }}
              </label>
            </mds-col>
            <mds-col :cols="8"></mds-col>
            
            <mds-col :cols="2" style="display: flex; justify-content: end;">
              <vue-json-to-csv
                :json-data="this.activeLogs"
                :labels="activeLabelsForReport"
                :csv-title="companyName + '_active_user_csv'"
                :separator="';'"
                @success="val => handleSuccess(val)"
                @error="val => handleError(val)">
                <mds-button variation="primary" icon="download" type="button">
                  <b>Active Users Report</b>
                </mds-button>
              </vue-json-to-csv>
            </mds-col>
          </mds-row>
          <div class="padding-top">
            <mds-row>
              <mds-col>
                <mds-data-table
                  ref="activeTable"
                  name="active"
                  size="medium"
                  :fixed-first-col="respond"
                  row-hover
                  :header-configs="activeHeaders"
                  :row-data="activeUsersPaginationList"
                  class="break-word"
                  :pagination-config="paginationOpts"
                  @mds-data-table-page-change="pagenate"
                  @mds-data-table-sort-change="onSort"
                />
                <mds-pagination
                  style="padding-top: 2%;"
                  show-items-info
                  show-items-select
                  :total-items="paginationOpts.totalItems"
                  :page-sizes="paginationOpts.pageSizes"
                  :page="paginationOpts.page"
                  :page-size="paginationOpts.pageSize"
                  @mds-pagination-page-changed="pagenate"
                />
              </mds-col>
            </mds-row>
          </div>
        </div>
        <div
          v-if="showInActive"
          class="custom-padding"
          style="margin-top: 5%;"
        >
          <mds-row>
            <mds-col :cols="2">
              <label class="mds-form__label custom-margin">
                {{ $t('labels.userManagement.inactive') }}
              </label>
            </mds-col>
            <mds-col :cols="8"></mds-col>
            
            <mds-col :cols="2" style="display: flex; justify-content: end;">
              <vue-json-to-csv
                :json-data="this.inactiveLogs"
                :labels="inactiveLabelsForReport"
                :csv-title="companyName + '_inactive_user_csv'"
                :separator="';'"
                @success="val => handleSuccess(val)"
                @error="val => handleError(val)">
                <mds-button variation="primary" icon="download" type="button">
                  <b>In-active Users Report</b>
                </mds-button>
              </vue-json-to-csv>
            </mds-col>
          </mds-row>
          <div class="padding-top">
            <mds-row>
              <mds-col>
                <mds-data-table
                  ref="inactiveTable"
                  name="inactive"
                  size="medium"
                  :fixed-first-col="respond"
                  row-hover
                  :header-configs="inactiveHeaders"
                  :row-data="inactiveUsersPaginationList"
                  class="break-word"
                  :pagination-config="paginationOptions"
                  @mds-data-table-page-change="paginateTable"
                  @mds-data-table-sort-change="onSort"
                />
                <mds-pagination
                  show-items-info
                  show-items-select
                  :total-items="paginationOptions.totalItems"
                  :page-sizes="paginationOptions.pageSizes"
                  :page="paginationOptions.page"
                  :page-size="paginationOptions.pageSize"
                  @mds-pagination-page-changed="paginateTable"
                />
              </mds-col>
            </mds-row>
          </div>
        </div>
      </mds-layout-grid>
      <div
        id="mds-notification"
        class="mds-notification mds-notification--top-center mds-notification--below-masthead mds-notification--active custom-top"
      >
        <mds-notification-group>
          <mds-notification
            ref="errorDialog"
            :list="JSON.stringify(errorText)"
            role="alertdialog"
            status="error"
            size="small"
            dismiss-delay="10000"
            dismissible="true"
            removable="false"
            class="z-index-9999"
          >
            {{ $t('labels.userManagement.errorDialogMsg') }}
          </mds-notification>
        </mds-notification-group>
      </div>
    </div>
  </div>
</template>

<script>
import { MdsLayoutGrid, MdsRow, MdsCol } from '@mds/layout-grid';
import MdsLoader from '@mds/loader';
import MdsComboBox from '@mds/combo-box';
import { MdsDataTable } from '@mds/data-table-v3';
import { mapGetters, mapActions } from 'vuex';
import MdsSearchField from '@mds/search-field';
import MdsPagination from '@mds/pagination';
import { getUserRoles, getUserCompany } from '../../utils/authService';
import NotEntitledUser from '../Errors/NotEntitledUser';
import VueJsonToCsv from 'vue-json-to-csv'

export default {
  name: 'User',
  components: {
    MdsLayoutGrid,
    MdsRow,
    MdsCol,
    MdsLoader,
    MdsComboBox,
    MdsDataTable,
    MdsSearchField,
    MdsPagination,
    NotEntitledUser,
    VueJsonToCsv,
  },
  data() {
    return {
      showActive: false,
      showInActive: false,
      searchFilter: '',
      overlay: false,
      showloader: false,
      showPageError: false,
      activeLogs: [],
      inactiveLogs: [],
      activeCompanyList: [],
      respond: false,
      activeUsersPaginationList: [],
      inactiveUsersPaginationList: [],
      inActiveFilterList: [],
      activeFilterList: [],
      displayActive: 0,
      displayInActive: 0,
      errorText: [],
      keyCodes: [9, 13, 16, 17, 18, 27, 45, 122, 144, 20, 27],
      paginationOpts: {
        pageSizes: [10, 20, 50, 100],
        pageSize: 10,
        page: 1,
        totalItems: this.activeLogs === undefined ? 0 : this.activeLogs.length,
        showItemsInfo: true,
        showItemsSelect: true,
      },
      paginationOptions: {
        pageSizes: [10, 20, 50, 100],
        pageSize: 10,
        page: 1,
        totalItems: this.inactiveLogs === undefined ? 0 : this.inactiveLogs.length,
        showItemsInfo: true,
        showItemsSelect: true,
      },
      activeHeaders: [
        {
          text: 'Name',
          fieldName: 'name',
          sortable: true,
        },
        {
          text: 'Login',
          fieldName: 'login',
          sortable: true,
        },
        {
          text: 'Admin',
          fieldName: 'admin',
          sortable: true,
        },
        {
          text: 'MP Excel Add-In',
          fieldName: 'lea',
          sortable: true,
        },
        {
          text: 'Markets',
          fieldName: 'cdb',
          sortable: true,
        },
        {
          text: 'UIA - Workflow Manager',
          fieldName: 'cduiWfm',
          sortable: true,
        },
        {
          text: 'UIA-Feed Management',
          fieldName: 'cduiFeed',
          sortable: true,
        },
        {
          text: 'UIA - Data Alert',
          fieldName: 'cduiDataAlert',
          sortable: true,
        },
        {
          text: 'Publisher',
          fieldName: 'pub',
          sortable: true,
        },
      ],
      inactiveHeaders: [
        {
          text: 'Name',
          fieldName: 'name',
          sortable: true,
        },
        {
          text: 'Login',
          fieldName: 'login',
          sortable: true,
        },
      ],
      activeLabelsForReport: {
        name: { title: 'Name' },
        login: { title: 'Login' },
        admin: { title: 'Admin' },
        lea: { title: 'MP Excel Add-In' },
        cdb: { title: 'Markets' },
        cduiWfm: { title: 'UIA - Workflow Manager' },
        cduiFeed: { title: 'UIA-Feed Management' },
        cduiDataAlert: { title: 'UIA - Data Alert' },
        pub: { title: 'Publisher' },
      },
      inactiveLabelsForReport: {
        name: { title: 'Name' },
        login: { title: 'Login' },
      },
      companyName: '',
    };
  },
  computed: {
    ...mapGetters({
      companyList: 'userModule/getCompanyList',
      activeUsers: 'userModule/getActiveUsers',
      inactiveUsers: 'userModule/getInactiveUsers',
      activeCount: 'userModule/getActiveCount',
      inactiveCount: 'userModule/getInActiveCount',
    }),
  },
  created() {
    if (getUserRoles().includes('ROLE_LDS_ADMIN') || getUserRoles().includes('ROLE_LDS_SUPPORT_ADMIN')) {
      this.overlay = true;
      this.showloader = true;
      this.getCompanyList().then((response) => {
        if (response !== undefined && response.status === 500) {
          this.showPageError = true;
        } else {
          this.activeCompanyList = this.companyList;
          this.overlay = false;
        }
        this.showloader = false;
      });
    } else if (getUserRoles().includes('ROLE_LDS_CO_ADMIN') && !getUserRoles().includes('ROLE_LDS_SUPPORT_ADMIN')) {
      if (getUserCompany()) {
        this.activeCompanyList = [{
          text: getUserCompany(),
          value: getUserCompany(),
        }];
        this.overlay = false;
        this.showloader = false;
      } else {
        this.showPageError = true;
      }
    } else {
      this.showPageError = true;
    }
  },
  methods: {
    ...mapActions('userModule', ['getCompanyList', 'getAllUsersForCompany', 'getCompanyUserReport']),
    getCompanyName(event) {
      this.overlay = true;
      this.showloader = true;
      this.showActive = false;
      this.showInActive = false;
      this.activeUsersPaginationList = [];
      this.inactiveUsersPaginationList = [];
      this.paginationOpts['page'] = 1;
      this.paginationOptions['pageSize'] = 10;
      this.paginationOptions['page'] = 1;
      this.paginationOpts['pageSize'] = 10;
      this.displayActive = 0;
      this.displayInActive = 0;
      this.errorText = [];
      this.companyName = event[0];
      if (getUserRoles().includes('ROLE_LDS_ADMIN')
      || getUserRoles().includes('ROLE_LDS_SUPPORT_ADMIN')
      || getUserRoles().includes('ROLE_LDS_CO_ADMIN')
      ) {
        this.getCompanyUserReport(event[0]).then((response) => {
          this.generateActiveInactiveUserData(response);
        });
        // this.getAllUsersForCompany(event[0]).then((response) => {
          // this.generateActiveInactiveUserData(response);
        // });
      }
      return true;
    },
    generateActiveInactiveUserData(response) {
      if (response.status === 200) {
        this.activeLogs = this.activeUsers;
        this.inactiveLogs = this.inactiveUsers;
        this.displayActive = this.activeCount || 0;
        this.displayInActive = this.inactiveCount || 0;
        if (this.activeLogs !== undefined && this.activeLogs.length > 0) {
          this.showActive = true;
          this.getActiveUserListCountForPagination();
        }
        if (this.inactiveLogs !== undefined && this.inactiveLogs.length > 0) {
          this.showInActive = true;
          this.getInActiveUserListCountForPagination();
        }
      } else if (response.status === 204) {
        this.errorText.push('Company does not have any user.');
        this.$refs.errorDialog.open();
      } else if (response !== undefined && response.message !== undefined
                          && response.message === 'Request failed with status code 500') {
        this.errorText.push('Internal Server Error.');
        this.$refs.errorDialog.open();
      } else {
        this.errorText.push('Internal Server Error with status code 500');
        this.$refs.errorDialog.open();
      }
      this.showloader = false;
      this.overlay = false;
    },
    pagenate(paginationOpts) {
      this.paginationOpts['page'] = paginationOpts.page;
      this.paginationOpts['pageSize'] = paginationOpts.pageSize;
      const pageNum = paginationOpts.page;
      const { pageSize } = paginationOpts;
      this.goToPage(pageNum, pageSize);
    },
    goToPage(pageNum, pageSize) {
      this.activeUsersPaginationList = this.activeLogs.filter((row, index) => {
        const start = (pageNum - 1) * pageSize;
        const end = pageNum * pageSize;
        return index >= start && index < end;
      });
    },
    getActiveUserListCountForPagination() {
      this.paginationOpts['totalItems'] = this.activeLogs.length;
      this.activeUsersPaginationList = this.activeLogs.slice(0, this.paginationOpts.pageSize);
    },
    getInActiveUserListCountForPagination() {
      this.paginationOptions['totalItems'] = this.inactiveLogs.length;
      this.inactiveUsersPaginationList = this.inactiveLogs.slice(0, this.paginationOptions.pageSize);
    },
    paginateTable(paginationOptions) {
      this.paginationOptions['page'] = paginationOptions.page;
      this.paginationOptions['pageSize'] = paginationOptions.pageSize;
      const pageNum = paginationOptions.page;
      const { pageSize } = paginationOptions;
      this.goToSelectedPage(pageNum, pageSize);
    },
    goToSelectedPage(pageNum, pageSize) {
      this.inactiveUsersPaginationList = this.inactiveLogs.filter((row, index) => {
        const start = (pageNum - 1) * pageSize;
        const end = pageNum * pageSize;
        return index >= start && index < end;
      });
    },
    onSort(arg) {
      const order = arg.sortOrder;
      this.showRows = this.activeUsersPaginationList.sort(
        (a, b) => (a[`${arg.sortField}`] >= b[`${arg.sortField}`] ? order : -order),
        0,
        {
          sensitivity: 'base',
        },
      );
      return true;
    },
    filterKeywords(event) {
      const keyCode = event.keyCode || event.which;
      // Don't validate the input if below arrow,tab capslock,shift and ctrl keys were pressed
      if (this.keyCodes.includes(keyCode) || (keyCode >= 35 && keyCode <= 40)) {
        return false;
      }
      this.searchKeyword();
      return true;
    },
    searchKeyword() {
      this.activeLogs = this.activeUsers.filter(obj => Object.keys(obj).some(
        key => obj[key] !== null
            && typeof obj[key] === 'string'
            && obj[key].toLowerCase().indexOf(this.searchFilter.toLowerCase()) > -1,
      ));
      this.getActiveUserListCountForPagination();
      this.pagenate(this.paginationOpts);

      this.inactiveLogs = this.inactiveUsers.filter(obj => Object.keys(obj).some(
        key => obj[key] !== null
            && typeof obj[key] === 'string'
            && obj[key].toLowerCase().indexOf(this.searchFilter.toLowerCase()) > -1,
      ));
      this.getInActiveUserListCountForPagination();
      this.paginateTable(this.paginationOptions);
    },
    clearSearchBar() {
      this.searchFilter = '';
      this.searchKeyword();
      return true;
    },
  },
};
</script>

<style  lang="scss" scoped>
@import 'src/assets/styles/components/custom/user';

#user-management {
  width: 90%;
}

</style>
