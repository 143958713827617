const state = {
  feedList: [],
  pageStatus: 'LOADING',
  feedDetails: {},
  errorMessage: '',
  userRoles: [],
  activeFeedName: '',
  feedEntitlements: {},
  userDatasource: [],
  units: ['DAY', 'HOUR', 'MINUTE', 'SECOND', 'MILLISECOND'],
  dataTypes: ['String(255)', 'Number(20,8)', 'DECIMAL'],
  coreFeed: false,
  rootContracts: {},
  metadata: [],
  feedGroups: [],
  feedGroupPageStatus: 'LOADING',
  userFeeds: [],
  groupErrorMessage: '',
  userDatasources: [],
  feedGroupEntitlements: [],
  privateFeedUsers: [],
  feedRoots: {},
  activeUsers: [],
  // feedKeys: {},
};
export default state;
