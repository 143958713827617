<template>
  <div class="mcd-page-shell__content mds-page-shell__content">
    <div>
      <div
        v-if="showAdvanced"
        :class="{ disabled: dialogType !== '' }"
      >
        <mds-layout-grid>
          <mds-row>
            <mds-col
              :cols="4"
            >
              <div
                style="display: flex; justify-content: left; width: 50%;margin-bottom: 5%;"
              >
                <h3>
                  <b>Formula List</b>
                </h3>
              </div>
              <mds-button-container style="width: 50%;margin-bottom: 2%;">
                <mds-button
                  variation="primary"
                  @click="showFormulaEditorModal()"
                >
                  Create New Formula
                </mds-button>
              </mds-button-container>
              <mds-search-field
                v-model="searchFilter"
                placeholder="Search Formula"
                label="Search"
                style="width: 80%; margin-bottom: 1%;"
                @mds-search-field-input-cleared="clearSearchBar"
              />
              <div v-if="!showFormulaConfig">
                <mds-loader size="large" />
              </div>
              <div
                class="table-container"
                style="margin-top: 20px; border: none;"
              >
                <mds-table
                  v-if="showFormulaConfig"
                  row-hover
                  style="margin-top: 20px; border: none;"
                >
                  <mds-tbody>
                    <template
                      v-for="(row, index) in activeFormulaList"
                    >
                      <mds-tr
                        :key="index"
                      >
                        <mds-td>
                          {{ row.name }}
                        </mds-td>
                        <mds-td style="padding-right: 10px;">
                          <mds-button-container right-aligned>
                            <mds-button
                              variation="primary"
                              size="small"
                              @click="getFormulaByName(index,row.name)"
                            >
                              Open
                            </mds-button>
                            <mds-button
                              variation="secondary"
                              size="small"
                              @click="displayDialogBox('deleteBox',row)"
                            >
                              Delete
                            </mds-button>
                          </mds-button-container>
                        </mds-td>
                      </mds-tr>
                    </template>
                  </mds-tbody>
                </mds-table>
              </div>
            </mds-col>
            <mds-col :cols="8">
              <mds-row
                align-vertical="center"
              >
                <mds-col :cols="3">
                  <h3>
                    <b>Formula Editor</b>
                  </h3>
                </mds-col>
                <mds-col :cols="9">
                  <mds-button-group
                    :content="buttonGroupContent"
                    aria-label="Theme"
                    style="margin: 8px; padding-left: 185px; justify-content: right;"
                    @mds-button-group-item-active="setActiveItem"
                  />
                </mds-col>
              </mds-row>
              <mds-row
                align-vertical="center"
                style="margin-bottom: 2%;"
              >
                <mds-col style="display: flex; justify-content: right;">
                  <mds-button-container>
                    <mds-button
                      v-if="showPreviewEnabled"
                      variation="primary"
                      @click="showCode()"
                    >
                      Show Code
                    </mds-button>
                    <mds-button
                      v-else
                      variation="primary"
                      @click="showFormulaPreviewDialog()"
                    >
                      Show Preview
                    </mds-button>
                  </mds-button-container>
                </mds-col>
              </mds-row>
              <mds-row>
                <div class="tabs">
                  <div
                    v-for="(tab, index) in tabs"
                    :key="index"
                    :class="{ active: activeTabIndex === index }"
                    class="tab"
                    @mouseenter="showCloseButton = index"
                    @mouseleave="showCloseButton = null"
                  >
                    <span
                      class="tab-name"
                      @click="selectTab(index)"
                    >
                      {{ tab.name }}<span v-if="tab.isModified && tab.content !== ''">*</span>
                    </span>
                    <button
                      v-if="showCloseButton === index"
                      class="close-tab"
                      @click="handleTabClose(index)"
                    >
                      ×
                    </button>
                  </div>
                  <div
                    v-if="tabs.length < 12"
                    class="add-tab"
                    @click="addTab"
                  >
                    +
                  </div>
                </div>
              </mds-row>
              <div v-if="showLoader">
                <mds-loader size="large" />
              </div>
              <div
                v-else
              >
                <mds-row
                  v-if="!tabs[activeTabIndex].isPreviewEnabled"
                >
                  <div
                    v-for="(tab, index) in tabs"
                    v-show="activeTabIndex === index"
                    :id="'editor-' + index"
                    :key="index"
                    style="height: 500px; width: 100%;"
                  />
                </mds-row>
                <mds-row>
                  <div
                    v-if="tabs[activeTabIndex].isPreviewEnabled"
                    class="table-container"
                  >
                    <mds-table

                      row-hover
                      style="height: 500px; width: 100%;"
                    >
                      <mds-thead>
                        <mds-th class="formula-prev-result">
                          Date
                        </mds-th>
                        <template v-for="(_, index) in tabs[activeTabIndex].previewContent[0].slice(1)">
                          <mds-th
                            :key="index"
                            class="formula-prev-result"
                            style="text-align: center;"
                          >
                            {{ index }}
                          </mds-th>
                        </template>
                      </mds-thead>
                      <mds-tbody>
                        <template v-for="(dataRow, index) in tabs[activeTabIndex].previewContent">
                          <mds-tr :key="index">
                            <template v-for="(data, dIndex) in dataRow">
                              <mds-td
                                :key="dIndex"
                                style="text-align: center;"
                                :class="getClassesForPreviewData(data)"
                              >
                                {{ data }}
                              </mds-td>
                            </template>
                          </mds-tr>
                        </template>
                      </mds-tbody>
                    </mds-table>
                  </div>
                </mds-row>
              </div>
              <mds-row
                style="margin-top: 1rem;"
              >
                <mds-col style="display: flex; justify-content: right;">
                  <mds-button
                    v-if="isNewFormula"
                    variation="primary"
                    :disabled="activeTab.content === ''"
                    @click="showFormulaSaveDialog()"
                  >
                    Save changes
                  </mds-button>
                  <mds-button
                    v-else-if="!showPreviewEnabled"
                    variation="primary"
                    :disabled="!activeTab.isModified"
                    @click="updateFormulaByName()"
                  >
                    Update Formula Changes
                  </mds-button>
                </mds-col>
              </mds-row>
            </mds-col>
          </mds-row>
        </mds-layout-grid>
      </div>
      <hr style="margin-top: 4%;">
      <mds-notification-container v-if="showFormulaEmptyBanner">
        <!-- Tinted -->
        <mds-notification
          key="error-tinted"
          variation="error"
          title="Error"
          tinted
          :dismiss-delay="3000"
          @mds-notification-dismissed="showFormulaEmptyBanner=false"
        >
          Cannot save empty formula.
        </mds-notification>
      </mds-notification-container>
      <mds-notification-container v-if="showFormulaValidationBanner">
        <mds-notification
          key="error-tinted"
          variation="error"
          title="Formula Validation Error"
          tinted
          :dismiss-delay="3000"
          @mds-notification-dismissed="showFormulaValidationBanner=false"
        >
          {{ formulaValidationErrorMessage }}
        </mds-notification>
      </mds-notification-container>
      <mds-alert
        v-if="dialogType == 'deleteBox'"
        title="Delete Formula"
        variation="warning"
        class="div-centered"
        dismissible="false"
      >
        Are you sure you want to delete formula <b>{{ deleteFormula.name }}</b> ?<br><br>
        <mds-button-container
          right-aligned
        >
          <mds-button
            size="medium"
            variation="secondary"
            @click="displayDialogBox('')"
          >
            Cancel
          </mds-button>
          <mds-button
            size="medium"
            variation="primary"
            @click="deleteFormulaByName(deleteFormula.name)"
          >
            Yes, Proceed
          </mds-button>
        </mds-button-container>
      </mds-alert>
      <mds-alert
        v-if="closeTabEvent"
        title="Delete Formula"
        variation="warning"
        class="div-centered"
        dismissible="false"
      >
        Are you sure you want to discard formula changes: <b>{{ tabs[closeTabIndex].name }}</b> ?<br><br>
        <mds-button-container
          right-aligned
        >
          <mds-button
            size="medium"
            variation="secondary"
            @click="closeTabEvent=!closeTabEvent"
          >
            Cancel
          </mds-button>
          <mds-button
            size="medium"
            variation="primary"
            @click="closeTab(closeTabIndex)"
          >
            Yes, Proceed
          </mds-button>
        </mds-button-container>
      </mds-alert>
      <mds-alert
        v-if="dialogType == 'successBox'"
        class="div-centered-top"
        variation="success"
        dismissible="false"
      >
        Your report has been successfully {{ alertMessage }}.
        <span><mds-button
          id="adSuccessButton"
          slot="mds-alert-custom-action"
          size="medium"
          variation="icon-only"
          icon="remove"
          style="top:5px;"
          @click="displayDialogBox('')"
        >
          close
        </mds-button></span>
      </mds-alert>
      <mds-alert
        v-if="dialogType == 'errorBox'"
        class="div-centered-top"
        dismissible="false"
        variation="error"
      >
        <div class="right-middle">
          {{ errorMessage }}<br>
          <span><mds-button
            id="adSuccessButton"
            slot="mds-alert-custom-action"
            size="medium"
            variation="icon-only"
            icon="remove"
            style="top:5px;"
            @click="displayDialogBox('')"
          >
            close
          </mds-button></span>
        </div>
      </mds-alert>
      <mds-dialog
        v-if="toggleFormulaSaveDialog"
        v-model="toggleFormulaSaveDialog"
        aria-label="Save Formula Dialog"
        title="Save Formula as"
        action-required
      >
        <mds-form>
          <mds-fieldset>
            <mds-input
              v-model="formulaName"
              maxlength="61"
              label="Formula Name"
              :error="showFormulaNameError"
              :error-text="[`The maximum length for a formula name is 60 characters.`]"
            />
          </mds-fieldset>
        </mds-form>
        <template #mds-dialog-actions-right>
          <mds-button-container right-aligned>
            <mds-button
              type="button"
              variation="secondary"
              @click="cancelSaveFormulaDialog"
            >
              Cancel
            </mds-button>
            <mds-button
              :disabled="showFormulaNameError"
              variation="primary"
              @click="saveFormula"
            >
              Submit
            </mds-button>
          </mds-button-container>
        </template>
      </mds-dialog>
    </div>
  </div>
</template>

<script>
import {
  MdsTable, MdsTbody, MdsTd, MdsTh, MdsThead, MdsTr,
} from '@mds/data-table';
import MdsInput from '@mds/input';
import { mapActions } from 'vuex';
import MdsDialog from '@mds/dialog';
import { MdsButton, MdsButtonContainer } from '@mds/button';
import MdsSearchField from '@mds/search-field-v4';
import MdsFieldset from '@mds/fieldset';
import MdsForm from '@mds/form';
import { MdsLayoutGrid, MdsRow, MdsCol } from '@mds/layout-grid';
import { MdsNotification, MdsNotificationContainer } from '@mds/notification';
import MdsButtonGroup from '@mds/button-group';
import loader from '@monaco-editor/loader';
import _ from 'lodash';
import axios from 'axios';
import global from '../../assets/js/globals';
import commonUtils from './scripts/commonUtils';
import lim from '../../assets/js/lim';
import {
  _safeLang,
} from './scripts/utils/mpfstep/mpfstep';
import {
  _getFullSavedFormulaCode, _getDefaultFormulaCode, _getVarList, _isFreeformFormula, _listFormulaSteps,
  _saveFormulaCodeInBubble, _formulaSection, _getValidatedUserCode, _getValidatedFreeformCode, _availVars, _getFirstFormulaError,
} from './scripts/utils/Manager';

const EOL = '\n';
export default {
  name: 'JavaScriptFormula',
  components: {
    MdsTable,
    MdsTbody,
    MdsTr,
    MdsTh,
    MdsThead,
    MdsTd,
    MdsForm,
    MdsRow,
    MdsCol,
    MdsButton,
    MdsInput,
    MdsFieldset,
    MdsLayoutGrid,
    MdsNotification,
    MdsNotificationContainer,
    MdsDialog,
    MdsButtonContainer,
    MdsButtonGroup,
    MdsSearchField,
  },
  data() {
    return {
      availVars: [],
      closeTabEvent: false,
      tabs: [
        {
          name: 'Untitled 1',
          editorInstance: null,
          content: '',
          isNew: true,
          isPreviewEnabled: false,
          previewContent: '',
          isModified: false,
        },
      ],
      showFormulaValidationBanner: false,
      showCloseButton: null,
      formulaBody: '',
      formulaName: '',
      activeTabIndex: 0,
      closeTabIndex: '',
      untitledCount: 1,
      allFormulaDetails: [],
      currentFormulaDetails: null,
      showFormulaEmptyBanner: false,
      toggleFormulaPreviewDialog: false,
      showAdvanced: false,
      formulaPreviewData: '',
      buttonGroupContent: [
        {
          id: '1', text: 'Light', active: true, val: 'vs',
        },
        {
          id: '2', text: 'Dark', active: false, val: 'vs-dark',
        },
        {
          id: '3', text: 'Contrast Dark', active: false, val: 'hc-black',
        },
        {
          id: '4', text: 'Contrast Light', active: false, val: 'hc-light',
        },
      ],
      editorTheme: 'vs',
      showLoader: false,
      toggleFormulaSaveDialog: false,
      errorMessage: '',
      alertMessage: '',
      deleteFormula: '',
      dialogType: '',
      searchFilter: '',
      paginationOptions: {
        firstItem: 1,
        pageSize: 15,
        page: 1,
      },
      pageSize: [15, 30, 50, -1],
      debounceSaveContent: _.debounce(function saveContent() {
        this.saveTabsToLocalStorage(); // Save after content changes with delay
      }, 500), // Adjust delay time as needed
    };
  },
  computed: {
    showFormulaNameError() {
      return this.formulaName.length > 60;
    },
    paginatedData() {
      // set new start and end values based on the updated pagination options
      const start = this.paginationOptions ? this.paginationOptions.firstItem - 1 : 0;
      const end = this.paginationOptions && this.paginationOptions.pageSize !== -1
        ? start + this.paginationOptions.pageSize
        : this.activeWorkflowList.length;

      // use the start and end values to return the visible page slice
      return this.activeWorkflowList.slice(start, end);
    },
    showFormulaConfig() {
      return this.allFormulaDetails.length > 0;
    },
    activeFormulaList() {
      return this.searchFilter === ''
        ? this.allFormulaDetails
        : this.allFormulaDetails.filter(
          formula => formula.name.toLowerCase().indexOf(this.searchFilter.toLowerCase()) > -1,
        );
    },
    activeTab() {
      return this.tabs[this.activeTabIndex];
    },
    isNewFormula() {
      return this.tabs[this.activeTabIndex].isNew;
    },
    showPreviewEnabled() {
      return this.tabs[this.activeTabIndex].isPreviewEnabled;
    },
  },
  watch: {
    formulaName(newValue) {
      if (newValue !== '') {
        const tab = this.tabs[this.activeTabIndex];
        tab.name = newValue;
      }
    },
    activeTabIndex(newIndex) {
      // Re-initialize the Monaco editor when switching tabs
      this.showFormulaEditor(newIndex);
      this.saveTabsToLocalStorage();
    },
    editorTheme(newValue) {
      localStorage.editorTheme = newValue;
      this.buttonGroupContent.forEach((item, index) => {
        if (item.val === this.editorTheme) {
          this.$set(this.buttonGroupContent[index], 'active', true);
        } else {
          this.$set(this.buttonGroupContent[index], 'active', false);
        }
      });
    },
  },
  mounted() {
    if (localStorage.editorTheme) {
      this.editorTheme = localStorage.editorTheme;
    }
    this.getAllFormulas(false);
    this.loadTabsContent();
    this.showFormulaEditor(0);
  },
  methods: {
    ...mapActions('workflowModule', ['runFormula']),
    setActiveItem(event) {
      this.buttonGroupContent.forEach((item) => {
        if (item.id === event.target.id) {
          item.active = true;
          const editor = this.tabs[this.activeTabIndex].editorInstance;
          editor._themeService.setTheme(item.val);
          this.tabs[this.activeTabIndex].editorInstance = editor;
          this.editorTheme = item.val;
        } else {
          item.active = false;
        }
      });
    },
    cancelSaveFormulaDialog() {
      this.formulaName = '';
      this.toggleFormulaSaveDialog = false;
    },
    handleTabClose(index) {
      if (this.tabs[index].isModified) {
        this.closeTabEvent = true;
        this.closeTabIndex = index;
      } else {
        this.closeTab(index); // Directly close the tab if not modified
      }
    },
    saveTabsToLocalStorage() {
      const serializableTabs = this.tabs.map(tab => ({
        name: tab.name,
        content: tab.content,
        isNew: tab.isNew,
        isPreviewEnabled: tab.isPreviewEnabled,
        previewContent: tab.previewContent,
        isModified: tab.isModified,
      }));
      localStorage.setItem('formulaTabs', JSON.stringify(serializableTabs));
      localStorage.setItem('untitledCount', this.untitledCount);
    },
    closeTab(index) {
      const closingTab = this.tabs[index];

      // Check if the tab being closed is untitled before decrementing
      if (closingTab.name && closingTab.name.startsWith('Untitled')) {
        this.untitledCount = Math.max(this.untitledCount - 1, 0); // Ensure it doesn't go negative
      }
      this.closeTabEvent = false;
      this.tabs.splice(index, 1);
      // If there are no tabs left, add a new one
      if (this.tabs.length === 0) {
        this.untitledCount = 0;
        this.addTab();
      }

      if (this.activeTabIndex >= this.tabs.length) {
        this.activeTabIndex = this.tabs.length - 1; // Adjust the active tab if needed
      }
      if (this.activeTabIndex === index) {
        this.showFormulaEditor(index);
      }
      this.saveTabsToLocalStorage();
    },
    closeTabByName(name) {
    // Find the index of the tab with the specified name
      const index = this.tabs.findIndex(tab => tab.name === name);

      if (index !== -1) { // If the tab is found
        this.closeTabEvent = false;
        this.tabs.splice(index, 1); // Remove the tab by index

        // Adjust the active tab if needed
        if (this.activeTabIndex >= this.tabs.length) {
          this.activeTabIndex = this.tabs.length - 1;
        }

        // Save the updated tabs list
        this.saveTabsToLocalStorage();
      }
    },
    loadTabsContent() {
      // Load tabs from localStorage if they exist
      const savedTabs = localStorage.getItem('formulaTabs');
      const storedUntitledCount = localStorage.getItem('untitledCount');
      if (savedTabs) {
        this.tabs = JSON.parse(savedTabs);
        // If there are no tabs saved, add a new default tab
        if (this.tabs.length === 0) {
          this.untitledCount = 0;
          this.addTab();
        } else {
          // Iterate through the tabs and reinitialize editor instances
          this.tabs.forEach((tab, index) => {
            this.showFormulaEditor(index); // Reinitialize the editor for each tab
          });
        }
      }

      if (storedUntitledCount) {
        this.untitledCount = parseInt(storedUntitledCount, 10);
      }
    },
    addTab() {
      // Check if the maximum tab limit has been reached
      if (this.tabs.length >= 12) {
        this.errorMessage = 'Cannot open more than 12 tabs.';
        this.dialogType = 'errorBox';
        this.showLoader = false;
        return; // Stop further execution if limit is reached
      }

      // Increment untitledCount and create a new tab
      this.untitledCount += 1;
      const newTabIndex = this.tabs.length;

      // Add new tab with placeholder content
      this.tabs.push({
        name: `Untitled ${this.untitledCount}`,
        editorInstance: null,
        content: '',
        isNew: true,
        isPreviewEnabled: false,
        previewContent: '',
        isModified: false,
      });
      // Select the new tab
      this.selectTab(newTabIndex);
    },
    selectTab(index) {
      this.activeTabIndex = index;
    },
    displayDialogBox(dialogValue, formula) {
      this.dialogType = dialogValue;
      this.deleteFormula = formula;
      this.showFormulaEditor(this.activeTabIndex);
    },
    showFormulaEditorModal() {
      this.formulaBody = '';
      this.addTab();
    },
    showFormulaSaveDialog() {
      this.toggleFormulaSaveDialog = !this.toggleFormulaSaveDialog;
    },
    /**
     * Creates payload and executes post api to save formula
     */
    async saveFormula() {
      const tab = this.tabs[this.activeTabIndex];
      const _validChars = new RegExp('^[A-Za-z0-9_.\\-\\+ ]+$');
      // Check if formulaName is valid
      if (!_validChars.test(this.formulaName)) {
        // Find invalid characters
        const invalidChars = [...this.formulaName].filter(
          char => !/^[A-Za-z0-9._\-+ ]$/.test(char),
        );
        this.errorMessage = `Invalid formula name. Invalid character(s): ${[...new Set(invalidChars)].join(', ')}`;
        this.dialogType = 'errorBox';
        return;
      }
      if (!this.validateFormula()) {
        return;
      }
      // Check for duplicate formula name in allFormulaDetails
      const duplicate = this.allFormulaDetails.some(
        formula => formula.name.toLowerCase() === tab.name.toLowerCase(),
      );
      if (duplicate) {
        this.errorMessage = `A formula with the name "${tab.name}" already exists.`;
        this.dialogType = 'errorBox';
        return; // Stop save process if a duplicate is found
      }

      const payload = {
        name: tab.name,
        type: 'JS',
        formula: tab.content,
      };

      await axios.post('/api/formulas/saveFormula', payload, {
        timeout: 30000, // Timeout in milliseconds (e.g., 30 seconds)
      }).then((response) => {
        if (response && (response.status === 201 || response.status === 200) && !response.data[0]) {
          this.dialogType = 'successBox';
          this.alertMessage = tab.isNew ? 'saved' : 'updated';
          tab.isModified = false;
          tab.isNew = false;
        } else {
          this.errorMessage = response.Message || response.data[0][0].Message;
          this.dialogType = 'errorBox';
        }
      }).catch((error) => {
        this.errorMessage = 'Error occured while saving formula';
        this.dialogType = 'errorBox';
      }).finally(() => {
        this.toggleFormulaSaveDialog = false;
        this.formulaName = '';
        this.getAllFormulas();
        this.closeModal();
      });
    },
    async updateFormulaByName() {
      this.showLoader = true;
      if (!this.validateFormula()) {
        this.showLoader = false;
        return; // Stop save process if validation fails
      }
      const tab = this.tabs[this.activeTabIndex];
      const payload = {
        name: tab.name,
        type: 'JS',
        formula: tab.content,
      };
      await axios.put(`/api/formulas/updateFormulaByName/${encodeURIComponent(tab.name)}`, payload, {
        timeout: 30000, // Timeout in milliseconds (e.g., 30 seconds)
      }).then((response) => {
        this.showLoader = false;
        if (response && (response.status === 200) && !response.data[0]) {
          this.dialogType = 'successBox';
          this.alertMessage = 'updated';
          tab.isModified = false;
        } else {
          this.errorMessage = response.Message || response.data[0][0].Message;
          this.dialogType = 'errorBox';
        }
      }).catch((error) => {
        this.showLoader = false;
        this.errorMessage = 'Error occured while updating formula';
        this.dialogType = 'errorBox';
      }).finally(() => {
        this.showLoader = false;
        this.closeModal();
      });
    },
    showFormulaEditor(index) {
      this.showAdvanced = true;
      loader.init().then((monaco) => {
        const tab = this.tabs[index];
        const editorElementId = `editor-${index}`;
        const libUri = 'js/lim';
        const parsedAvailableVars = this.availVars.map((v) => {
          let varCode = '/**\n';
          if (Object.hasOwn(v, '_descr')) {
            varCode += `
                * ${v._descr}\n`;
          }
          if (Object.hasOwn(v, '_dt') && Object.hasOwn(v._dt, '_val')) {
            varCode += `* @{type} ${v._dt._val}\n`;
          }
          if (Object.hasOwn(v, '_code') && v._code) {
            varCode += `*/\n
              var ${v._name} = ${v._code};\n`;
          } else {
            varCode += `*/\n
              var ${v._name};\n`;
          }
          return varCode;
        });
        const lib = [lim, global, ...parsedAvailableVars].join('\n');
        monaco.editor.getModels().forEach(model => model.dispose());
        monaco.languages.typescript.javascriptDefaults.addExtraLib(lib, libUri);
        monaco.editor.createModel(lib, 'javascript', monaco.Uri.parse(libUri));
        const editor = monaco.editor.create(
          document.getElementById(editorElementId), {
            value: tab.content + EOL,
            language: 'javascript',
            automaticLayout: true,
            wordWrap: 'bounded',
            theme: this.editorTheme,
            readOnly: false,
          },
        );

        // Function to set the suggestion widget position
        function setSuggestionPosition() {
          setTimeout(() => {
            const suggestWidget = document.querySelector('.monaco-editor .suggest-widget');
            if (suggestWidget) {
              const cursorPosition = editor.getPosition();
              const cursorCoords = editor.getScrolledVisiblePosition(cursorPosition);
              suggestWidget.style.left = `${cursorCoords.left + cursorCoords.width }px`;
              suggestWidget.style.top = `${cursorCoords.top + cursorCoords.height }px`;
            }
          }, 0);
        }

        // Set suggestion widget position on content change and cursor move
        editor.onDidChangeModelContent(setSuggestionPosition);
        editor.onDidChangeCursorPosition(setSuggestionPosition);

        editor.getModel().onDidChangeContent((event) => {
          const currentContent = editor.getValue().trim();
          if (tab.content !== currentContent) {
            tab.content = currentContent; // Update only if the content has changed
            tab.isModified = true;
            this.debounceSaveContent();
          }
        });
        tab.editorInstance = editor;
      });
    },
    validateFormula() {
      const tab = this.tabs[this.activeTabIndex];

      // Check if the formula is empty
      if (!tab.content) {
        this.showFormulaEmptyBanner = true;
        return false; // Return false to stop further execution
      }
      // If no errors, validation passed
      return true; // Return true to proceed
    },
    getClassesForPreviewData(data) {
      return [
        'incons',
        // !data.includes(',') && isFinite(data) && 'green',
        // (data.includes(',') || (data.includes('-') || data.includes('/'))) && 'blue',
        // !data.includes(',') && data === 'NaN' && 'red',
        [
          'Max', 'Min',
          'Avg', 'AvgPos', 'AvgNeg',
          'PctPos', 'PctNeg',
          'Variance', 'StdDev', 'ZStat',
        ].includes(data) && 'blue',
      ]
        .filter(c => c)
        .join(' ');
    },
    /**
     * Retrive all formulas associated with user
     * @param {*} isRefresh
     */
    async getAllFormulas(isRefresh) {
      await axios.get('/api/formulas/getAllFormulas', {
        timeout: 30000, // Timeout in milliseconds (e.g., 30 seconds)
      }).then(async (response) => {
        if (response && (response.status === 200)) {
          this.allFormulaDetails = response.data;
        } else {
          this.errorMessage = response.Message || response.data[0][0].Message;
          this.dialogType = 'errorBox';
        }
      }).catch((error) => {
        this.errorMessage = 'Error occured while fetching formula';
        this.dialogType = 'errorBox';
      });
    },
    /**
     * Retrive formula based on formulaName
     * @param {*} index
     * @param {*} report
     */
    async getFormulaByName(index, name) {
      this.showLoader = true;
      // Check if a tab with this name is already open
      const existingTabIndex = this.tabs.findIndex(tab => tab.name === name);
      if (existingTabIndex !== -1) {
        // Tab is already open, set it as the active tab
        this.activeTabIndex = existingTabIndex;
        this.showLoader = false;
        return;
      }
      // Check if the maximum tab limit has been reached
      if (this.tabs.length >= 12) {
        this.errorMessage = 'Cannot open more than 12 tabs.';
        this.dialogType = 'errorBox';
        this.showLoader = false;
        return; // Stop further execution if limit is reached
      }
      await axios.get(`/api/formulas/getFormulaByName/${encodeURIComponent(name)}`, {
        timeout: 30000, // Timeout in milliseconds (e.g., 30 seconds)
      }).then((response) => {
        if (response && (response.status === 200 || response.status === 304)) {
          const [firstItem] = response.data;
          this.currentFormulaDetails = firstItem;
          this.formulaBody = this.currentFormulaDetails.formula;
          this.addTab();
          const tab = this.tabs[this.activeTabIndex];
          tab.content = this.currentFormulaDetails.formula;
          tab.name = this.currentFormulaDetails.name;
          tab.isNew = false;
          this.showLoader = false;
        } else {
          this.errorMessage = response.Message || response.data[0][0].Message;
          this.dialogType = 'errorBox';
          this.showLoader = false;
        }
      }).catch((error) => {
        this.errorMessage = 'Error occured fetching formula';
        this.dialogType = 'errorBox';
        this.showLoader = false;
      });
    },
    showCode() {
      const tab = this.tabs[this.activeTabIndex];
      tab.isPreviewEnabled = false;
      tab.previewContent = '';
      this.showFormulaEditor(this.activeTabIndex);
    },
    showFormulaPreviewDialog() {
      const tab = this.tabs[this.activeTabIndex];
      const payload = {
        name: 'test',
        type: 'JS',
        formula: tab.content,
      };
      this.showLoader = true;
      this.runFormula(payload).then((response) => {
        let formulaPreviewRawData = null;
        if (typeof response.data === 'string') {
          formulaPreviewRawData = response.data.split('\n')
            .map(line => line.split(','))
            .filter(line => line[0])
            .map(dataRow => [this.prettifyDate(dataRow[0]), ...dataRow.slice(1)]);
        } else {
          formulaPreviewRawData = `${response.data}`
            .split('\n')
            .map(line => line.split(','))
            .filter(line => line[0])
            .map(dataRow => [this.prettifyDate(dataRow[0]), ...dataRow.slice(1)]);
        }
        // Check if formulaPreviewRawData is empty
        if (formulaPreviewRawData.length === 0) {
          this.dialogType = 'errorBox';
          this.errorMessage = 'Response is empty - no data.';
          return; // Early return to prevent further processing
        }
        this.formulaPreviewData = formulaPreviewRawData;
        tab.previewContent = formulaPreviewRawData;
        tab.isPreviewEnabled = true;
        this.toggleFormulaPreviewDialog = true;
      }).catch((error) => {
        this.showLoader = false;
        this.dialogType = 'errorBox';
        this.errorMessage = error.response.data;
      }).finally(() => {
        this.showLoader = false;
      });
    },
    async deleteFormulaByName(name) {
      this.showLoader = true;
      this.dialogType = '';
      await axios.delete(`/api/formulas/deleteFormulaByName/${encodeURIComponent(name)}`, {
        timeout: 30000, // Timeout in milliseconds (e.g., 30 seconds)
      }).then((response) => {
        this.showLoader = false;
        if (response && (response.status === 200) && !response.data[0]) {
          this.dialogType = 'successBox';
          this.alertMessage = 'deleted';
          this.closeTabByName(name);
        } else {
          this.errorMessage = response.Message || response.data[0][0].Message;
          this.dialogType = 'errorBox';
        }
      }).catch((error) => {
        this.showLoader = false;
        this.errorMessage = 'Error occured while deleting formula';
        this.dialogType = 'errorBox';
      }).finally(() => {
        this.showLoader = false;
        this.getAllFormulas();
      });
    },
    prettifyDate(dateString) {
      if (!dateString) return dateString;
      if (!dateString.includes('T')) return dateString;
      const [rawDate, rawTime] = dateString.split('T');
      const date = rawDate.split('-');
      const time = rawTime.split('-')[0] || rawTime.split('+')[0];
      return `${date[1]}/${date[2]}/${date[0]}, ${time}`;
    },
    closeModal() {
      this.formulaBody = '';
      this.formulaName = '';
      this.currentFormulaDetails = '';
    },
    clearSearchBar() {
      this.searchFilter = '';
    },
  },
};
</script>

<style>

.scrollable {
  margin: 15px 0px;
  height: 220px;
  width: 700px;
  overflow-y: auto;
  overflow-x: auto;
  background-color: #FFFFFF;
  border: 1px solid #AAAAAA;
}
.div-centered-top {
    position: fixed;
    top: 20%;
    left: 60%;
    transform: translate(-60%, -50%);
    z-index: 9999;
}
.disabled {
  opacity: 0.5;
  pointer-events: none;
}
.right-middle {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.prev-res-table {
  height: 45vh;
  overflow-y: auto;
  overflow-x: hidden;
  margin-top: 1rem;
}
.formula-prev-result .mds-th__inner___Mcd-ui {
  justify-content: center !important;
}

.tabs {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto; /* Allows horizontal scrolling */
  border-bottom: 1px solid #ccc;
  width: 100%; /* Full width of the layout */
  white-space: nowrap; /* Prevents wrapping of tabs to a new line */
  padding-bottom: 5px; /* Extra space for a clean appearance */
}

.tab {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 100px; /* Minimum width for each tab */
  padding: 5px 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border: 1px solid #ccc;
  margin-right: 5px;
  border-radius: 5px 5px 0 0;
  box-sizing: border-box;
  cursor: pointer;
  flex-shrink: 0; /* Prevents tabs from shrinking below min-width */
}

.tab-name {
  flex-grow: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.close-tab {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 16px;
  margin-left: 10px;
}

.add-tab {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px; /* Fixed width for the + button */
  border: 1px solid #ccc;
  border-radius: 5px;
  cursor: pointer;
  font-size: 18px;
  padding: 5px;
  box-sizing: border-box;
  flex-shrink: 0; /* Prevents the + button from shrinking */
}

.tabs::-webkit-scrollbar {
  height: 8px; /* Adjust scrollbar height */
}

.tabs::-webkit-scrollbar-thumb {
  background-color: #888; /* Scrollbar color */
  border-radius: 4px;
}

.tabs::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Scrollbar color on hover */
}

.tab.active {
  background-color: #e6e6e6;
}

.tab:hover .close-tab {
  visibility: visible; /* Show close button on hover */
}

.tab:hover {
  background-color: #e6e6e6; /* Slightly darker gray on tab hover */
}

.close-tab:hover {
  color: red; /* Red color for close button on hover (like Notepad++) */
}
.table-container {
  max-height: 400px !important; /* Adjust the height as needed */
  max-width: 100%;   /* Ensure it fits within the parent grid */
  overflow-x: auto;  /* Horizontal scrollbar if needed */
  overflow-y: auto !important;  /* Vertical scrollbar if needed */
  border: 1px solid #ddd; /* Optional border to define the table area */
}
.mds_col_height {
  max-height: 400px; /* Set height for scrollable area */
}
</style>
