import formulaEditActionsConstants from '../constants/formulaEditActions';
import parseFormulaFromConfig from './FormulaParser';
import MpFormulaStep from './utils/mpfstep/mpfstep';
import { _getFirstFormulaError } from './utils/Manager';

export default function formulaNodeValidator(formulaNodeType, formulaConfig) {
  const errors = [];
  const formulaValidatorTemplateObject = formulaEditActionsConstants
    .formulaNodeTemplateValidationObject[formulaNodeType];

  const requiredFields = Object.keys(formulaConfig).filter(
    ck => formulaValidatorTemplateObject[ck] && formulaValidatorTemplateObject[ck].required === true,
  );

  requiredFields.forEach((field) => {
    if (Array.isArray(formulaConfig[field])) {
      if (formulaValidatorTemplateObject[field].required && formulaConfig[field].length === 0) {
        errors.push(formulaValidatorTemplateObject[field].errorMessage);
      }
    } else if (formulaValidatorTemplateObject[field].required === !formulaConfig[field]) {
      errors.push(formulaValidatorTemplateObject[field].errorMessage);
    }
  });

  if (formulaNodeType === 'extrapolation') {
    if (formulaConfig.timeSeriesVar.length === 0) {
      errors.push('Please select a Curve');
    } else if (formulaConfig.method.length === 0) {
      errors.push('Please select a Method');
    } else if (formulaConfig.method[0] === 'follow_dataset') {
      if (formulaConfig.methCtrls[0].other.length === 0) {
        errors.push('Please select a Model Curve');
      } else if (formulaConfig.timeSeriesVar[0] === formulaConfig.methCtrls[0].other[0]) {
        errors.push('Curve and Model Curve must be different.');
      }
      if (formulaConfig.methCtrls[0].extendTo === 'fixed_length') {
        try {
          const temp = parseInt(formulaConfig.methCtrls[0].fixedLen, 10);
          if (temp < 1) {
            errors.push('Invalid value, must be an integer no less than 1.');
          }
        } catch {
          errors.push('Invalid value, must be an integer no less than 1.');
        }
      }
      if (formulaConfig.methCtrls[0].extendTo === 'fixed_date') {
        try {
          const temp = new Date(formulaConfig.methCtrls[0].fixedDate);
          if (temp === '' || !(temp instanceof Date) || isNaN(temp)) {
            errors.push('Unrecognized date value.');
          }
        } catch {
          errors.push('Unrecognized date value.');
        }
      }
    } else if (formulaConfig.method[0] === 'repeat_last_cycle') {
      try {
        const temp = parseInt(formulaConfig.methCtrls[1].repeats, 10);
        if (temp < 1) {
          errors.push('Invalid value, must be an integer no less than 1.');
        }
      } catch {
        errors.push('Invalid value, must be an integer no less than 1.');
      }

      if (formulaConfig.methCtrls[1].delivery.length === 0) {
        errors.push('Please select a Delivery.');
      }
      try {
        const temp = new Date(formulaConfig.methCtrls[1].stopDate);
        if (temp === '' || !(temp instanceof Date) || isNaN(temp)) {
          errors.push('Unrecognized date value.');
        }
      } catch {
        errors.push('Unrecognized date value.');
      }
    }
  } else if (formulaNodeType === 'basic_math') {
    try {
      parseFormulaFromConfig(formulaNodeType, formulaConfig);
    } catch (error) {
      if (error instanceof MpFormulaStep.ParseError) {
        errors.push(error._msg);
      }
    }
  } else if (formulaNodeType === 'free_text') {
    try {
      const code = parseFormulaFromConfig(formulaNodeType, formulaConfig);
      const resp = _getFirstFormulaError(formulaConfig.bubble, code, true, formulaConfig.vue);

      if (resp instanceof Error) {
        throw resp;
      }
    } catch (error) {
      errors.push(error.description);
    }
  }

  return [errors.length > 0, errors];
}
