const getters = {
  getFeedList: state => state.feedList,
  getFeedDetails: state => state.feedDetails,
  getUserAdminRoles: state => state.userRoles.filter(role => role === 'ROLE_LDS_ADMIN' || role === 'ROLE_LDS_CO_ADMIN'),
  getKeyColumns: state => (state.feedDetails.fields
    ? state.feedDetails.fields.filter(field => field.type.toLowerCase() === 'k') : null),
  getValueColumns: state => (state.feedDetails.fields
    ? state.feedDetails.fields.filter(field => field.type.toLowerCase() === 'v') : null),
  getPageStatus: state => state.pageStatus,
  getErrorMessage: state => state.errorMessage,
  getFeedEntitlements: state => JSON.parse(JSON.stringify(state.feedEntitlements)),
  getActiveFeedname: state => state.activeFeedName,
  getUserDatasource: state => state.userDatasource,
  getDataSource: state => state.feedList.map(element => ({ text: element.dataSource, value: element.dataSource }))
    .filter((value, index, self) => self.findIndex(s => (JSON.stringify(s) === JSON.stringify(value))) === index),
  getPrivateFeedList: state => state.feedList.filter(feed => feed.privateFeed === true),
  getCoreFeed: state => state.coreFeed,
  getRoots: state => state.rootContracts,
  getMetadata: state => state.metadata,
  getFeedGroups: state => state.feedGroups,
  getFeedGroupPageStatus: state => state.feedGroupPageStatus,
  getFeedsForFeedGroup: state => state.userFeeds,
  getGroupErrorMessage: state => state.groupErrorMessage,
  getUserDatasources: state => state.userDatasources.map(element => ({ text: element.name, value: element.name })),
  getUserFeedList: state => state.userDatasources.map(element => ({ datasource: element.name, feeds: element.feeds })),
  getFeedGroupEntitlements: state => state.feedGroupEntitlements,
  getAllFeedRoots: state => state.feedRoots,
  // getAllFeedKeys: state => state.feedKeys,
  getAllActiveUsers: state => state.activeUsers,
};

export default getters;
