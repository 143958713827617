<template>
  <div :disabled="true">
    <mds-layout-grid gutters="none">
      <mds-row>
        <mds-col :cols="12">
          <div>
            <mds-button-group
              :content="buttonGroupContent"
              aria-label="View by Super Sector"
              style="margin-left: 10px;"
              @mds-button-group-item-active="setActiveItem"
            />
            <div v-if="buttonGroupContent[0].active">
              <mds-button
                v-for="(button, index) in weekdayDataset"
                :key="button.text"
                :variation="button.enabled === true ? 'primary' : 'secondary'"
                :style="index === 0 ? 'margin: 10px;' : 'margin-right: 10px'"
                @click="selectWeekday($event, button)"
              >
                {{ button.text }}
              </mds-button>
              <mds-fieldset
                horizontal
                style="margin-left: 10px; margin-top: 20px;"
              >
                <mds-checkbox
                  id="default-trigger"
                  v-model="intraday.uiValue"
                  style="margin-top: 20px;"
                  @mouseover="toggle=true"
                  @mouseleave="toggle=false"
                  @focus="toggle=true"
                  @blur="toggle=false"
                  @change="intradayChanged"
                >
                  <b>Intraday</b>
                </mds-checkbox>
                <mds-tooltip
                  v-if="isMinuteNotZero"
                  v-model="toggle"
                  triggered-by="default-trigger"
                >
                  IntraDay Minutes (other than 60) allowed only for complete hour
                </mds-tooltip>
                <mds-range-slider
                  v-model="intradayMinute.uiValue"
                  lower-label="Minutes"
                  :min="5"
                  :max="55"
                  :step="5"
                  :decimal-points="0"
                  :disabled="!intraday.uiValue"
                />
              </mds-fieldset>
            </div>
            <div v-if="buttonGroupContent[1].active">
              <div>
                <mds-table
                  style="margin-left: 10px;"
                  row-hover
                >
                  <mds-thead
                    hidden-header
                  >
                    <mds-th />
                    <mds-th />
                    <mds-th />
                  </mds-thead>
                  <mds-tbody>
                    <mds-tr @mds-tr-multiselection-changed="handleRowSelectEvent(0)">
                      <mds-td>
                        <mds-radio-button
                          v-model="selectedRadio.uiValue"
                          value="dayOfMonth"
                        >
                          Day of the month
                        </mds-radio-button>
                      </mds-td>
                      <mds-td colspan="2">
                        <mds-range-slider
                          v-model="dayOfMonth.uiValue"
                          lower-label="Day"
                          :min="1"
                          :max="31"
                          :step="1"
                          :decimal-points="0"
                        />
                      </mds-td>
                    </mds-tr>
                    <mds-tr @mds-tr-multiselection-changed="handleRowSelectEvent(1)">
                      <mds-td colspan="3">
                        <mds-radio-button
                          v-model="selectedRadio.uiValue"
                          value="lastDayOfMonth"
                        >
                          Last day of every month
                        </mds-radio-button>
                      </mds-td>
                    </mds-tr>
                    <mds-tr @mds-tr-multiselection-changed="handleRowSelectEvent(2)">
                      <mds-td>
                        <mds-radio-button
                          v-model="selectedRadio.uiValue"
                          value="dayOfEachWeek"
                        >
                          <mds-select
                            v-model="nthDayOfMonth.uiValue"
                            hidden-label
                            label="nthDayOfMonth"
                            :options="[
                              { text: 'First', value: '1' },
                              { text: 'Second', value: '2' },
                              { text: 'Third', value: '3' },
                              { text: 'Fourth', value: '4' },
                              { text: 'Last', value: 'L'},
                            ]"
                          />
                        </mds-radio-button>
                      </mds-td>
                      <mds-td>
                        <mds-select
                          v-model="weekdayOfMonth.uiValue"
                          hidden-label
                          label="weekdayOfMonth"
                          :options="[
                            { text: 'Sunday', value: 'SUN' },
                            { text: 'Monday', value: 'MON' },
                            { text: 'Tuesday', value: 'TUE' },
                            { text: 'Wednesday', value: 'WED' },
                            { text: 'Thusday', value: 'THU' },
                            { text: 'Friday', value: 'FRI' },
                            { text: 'Saturday', value: 'SAT' },
                          ]"
                        />
                      </mds-td>
                      <mds-td> Of each Month </mds-td>
                    </mds-tr>
                  </mds-tbody>
                </mds-table>
              </div>
            </div>
            <mds-layout-grid>
              <mds-row>
                <mds-col>
                  <mds-fieldset horizontal>
                    <mds-input
                      v-model="time.uiTime"
                      label="Time"
                      type="time"
                      style="margin: 10px"
                    />
                    <mds-select
                      v-model="timeZone.dropDownTimeZone"
                      style="margin-top: 10px;"
                      label="Timezone"
                      :options="availableTz"
                    />
                  </mds-fieldset>
                </mds-col>
              </mds-row>
            </mds-layout-grid>
          </div>
        </mds-col>
      </mds-row>
    </mds-layout-grid>
    <mds-dialog
      v-if="showIntradayError"
      id="showIntradayError"
      v-model="showIntradayError"
      title=""
      hidden-title
      width="500px"
    >
      <mds-alert
        variation="error"
        title="Intraday Error"
        persistent="true"
      >
        IntraDay Minutes(other than 60) allowed only for complete hour.
      </mds-alert>
      <template #mds-dialog-actions-right>
        <mds-button-container right-aligned>
          <mds-button
            type="button"
            variation="secondary"
            @click="dismissIntradayError"
          >
            Dismiss
          </mds-button>
        </mds-button-container>
      </template>
    </mds-dialog>
  </div>
</template>

<script>
import {
  MdsTable, MdsThead, MdsTh, MdsTbody, MdsTr, MdsTd,
} from '@mds/data-table';
import MdsDialog from '@mds/dialog';
import MdsForm from '@mds/form';
import MdsFieldset from '@mds/fieldset';
import MdsInput from '@mds/input';
import { mapGetters, mapActions } from 'vuex';
import { MdsButton, MdsButtonContainer } from '@mds/button';
import MdsSelect from '@mds/select';
import MdsRadioButton from '@mds/radio-button';
import MdsTooltip from '@mds/tooltip';
import { MdsLayoutGrid, MdsRow, MdsCol } from '@mds/layout-grid';
import MdsButtonGroup from '@mds/button-group';
import MdsRangeSlider from '@mds/range-slider';
import MdsCheckbox from '@mds/checkbox';
import MdsAlert from '@mds/alert';
import axios from 'axios';
import { ZoneId, ZoneRulesProvider } from '@js-joda/core';
import timeZone from '../Workflow/constants/timezone.js';

const HOURS_PER_DAY = 24;
const MINUTES_PER_DAY = 1440;
const SECONDS_PER_DAY = 86400;
const MILLIS_PER_SECOND = 1000;
const MILLIS_PER_MINUTE = (60 * MILLIS_PER_SECOND);
const MILLIS_PER_HOUR = (60 * MILLIS_PER_MINUTE);
const MILLIS_PER_DAY = (24 * MILLIS_PER_HOUR);
const MILLIS_PER_MONTH = (28 * MILLIS_PER_DAY);
const MILLIS_PER_YEAR = (365 * MILLIS_PER_DAY);

export default {
  name: 'ScheduleCron',
  components: {
    MdsTable,
    MdsThead,
    MdsTh,
    MdsTbody,
    MdsTr,
    MdsTd,
    MdsDialog,
    MdsButton,
    MdsButtonContainer,
    MdsInput,
    MdsSelect,
    MdsFieldset,
    MdsRadioButton,
    MdsLayoutGrid,
    MdsRow,
    MdsCol,
    MdsButtonGroup,
    MdsRangeSlider,
    MdsTooltip,
    MdsCheckbox,
    MdsAlert,
  },
  props: {
    showDialog: Boolean,
    existingCronExpr: String,
    existingTimeZone: String,
  },
  data() {
    return {
      buttonGroupContent: [
        { id: '1', text: 'Daily | Weekly', active: true },
        { id: '2', text: 'Monthly', active: false },
      ],
      weekdayDataset: [
        {
          text: 'Sun',
          value: 'SUN',
          enabled: false,
          wfValue: false,
        },
        {
          text: 'Mon',
          value: 'MON',
          enabled: false,
          wfValue: false,
        },
        {
          text: 'Tue',
          value: 'TUE',
          enabled: false,
          wfValue: false,
        },
        {
          text: 'Wed',
          value: 'WED',
          enabled: false,
          wfValue: false,
        },
        {
          text: 'Thu',
          value: 'THU',
          enabled: false,
          wfValue: false,
        },
        {
          text: 'Fri',
          value: 'FRI',
          enabled: false,
          wfValue: false,
        },
        {
          text: 'Sat',
          value: 'SAT',
          enabled: false,
          wfValue: false,
        },
      ],
      intradayMinute: {
        uiValue: 5,
        wfValue: 5,
      },
      dayOfMonth: {
        uiValue: 1,
        wfValue: 1,
      },
      rangeHour: 0,
      time: {
        wfTime: '00:00',
        uiTime: '00:00',
      },
      date: '',
      toggle: false,
      isMinuteNotZero: false,
      intraday: {
        uiValue: false,
        wfValue: false,
      },
      monthlyRowSelected: 0,
      workflowData: {},
      workflowParameter: {},
      cron: '',
      timeZone: {
        wfTimeZone: '',
        dropDownTimeZone: '',
      },
      duration: {
        wfHour: '',
        sliderHour: 0,
        wfMinute: '',
        sliderMinute: 0,
      },
      showValidationError: false,
      showMainLoader: false,
      showIntradayError: false,
      selectedRadio: {
        uiValue: '',
        wfValue: '',
      },
      nthDayOfMonth: {
        uiValue: '1',
        wfValue: '1',
      },
      weekdayOfMonth: {
        uiValue: 'SUN',
        wfValue: 'SUN',
      },
      availableTz: timeZone.map(tz => ({ text: tz, value: tz })),
      readOnly: false,
      manager: {},
      isInitDone: false,
      showScheduerDialog: this.showDialog,
      showSubmitButton: false,
    };
  },
  computed: {
    enableSubmitButton() {
      if (this.weekdayDataset.some(weekDay => weekDay.wfValue !== weekDay.enabled)) {
        return true;
      }
      if (this.timeZone.wfTimeZone !== this.timeZone.dropDownTimeZone) {
        return true;
      }
      if (this.intraday.uiValue !== this.intraday.wfValue) {
        return true;
      }
      if (this.intradayMinute.uiValue !== this.intradayMinute.wfValue && this.intraday.uiValue) {
        return true;
      }
      if (this.selectedRadio.uiValue !== this.selectedRadio.wfValue) {
        return true;
      }
      if (this.dayOfMonth.uiValue !== this.dayOfMonth.wfValue) {
        return true;
      }
      if (this.nthDayOfMonth.uiValue !== this.nthDayOfMonth.wfValue) {
        return true;
      }
      if (this.weekdayOfMonth.uiValue !== this.weekdayOfMonth.wfValue) {
        return true;
      }
      return this.time.wfTime !== this.time.uiTime;
    },
    isScheduleUpdateNeeded() {
      if (this.timeZone.wfTimeZone !== this.timeZone.dropDownTimeZone) {
        return true;
      }
      if (this.weekdayDataset.some(weekDay => weekDay.wfValue !== weekDay.enabled)) {
        return true;
      }
      if (this.intraday.uiValue !== this.intraday.wfValue) {
        return true;
      }
      if (this.intradayMinute.uiValue !== this.intradayMinute.wfValue && this.intraday.uiValue) {
        return true;
      }
      if (this.selectedRadio.uiValue !== this.selectedRadio.wfValue) {
        return true;
      }
      if (this.dayOfMonth.uiValue !== this.dayOfMonth.wfValue) {
        return true;
      }
      if (this.nthDayOfMonth.uiValue !== this.nthDayOfMonth.wfValue) {
        return true;
      }
      if (this.weekdayOfMonth.uiValue !== this.weekdayOfMonth.wfValue) {
        return true;
      }
      return this.time.wfTime !== this.time.uiTime;
    },
    currentCron() {
      const [hr, min] = this.time.uiTime.split(':').map(val => parseInt(val, 10));
      if (this.buttonGroupContent[0].active) {
        const weekdaysSelected = this.weekdayDataset.filter(day => day.enabled).map(day => day.value).join(',');
        const intra = this.intraday.uiValue ? `0/${this.intradayMinute.uiValue}` : min;
        return `0 ${intra} ${hr} ? * ${weekdaysSelected} *`;
      }
      if (this.selectedRadio.uiValue === 'lastDayOfMonth') {
        return `0 ${min} ${hr} L * ? *`;
      }
      if (this.selectedRadio.uiValue === 'dayOfMonth') {
        return `0 ${min} ${hr} ${this.dayOfMonth.uiValue} * ? *`;
      }
      const dayOfEachWeek = this.nthDayOfMonth.uiValue === 'L' ? `${this.weekdayOfMonth.uiValue}L`
        : `${this.weekdayOfMonth.uiValue}#${this.nthDayOfMonth.uiValue}`;
      return `0 ${min} ${hr} ? * ${dayOfEachWeek} *`;
    },

  },
  watch: {
    enableSubmitButton(val) {
      if (val && this.isInitDone) {
        this.showSubmitButton = true;
      }
    },
    time: {
      handler(value) {
        if (value.uiTime === '') {
          this.isMinuteNotZero = false;
        } else {
          this.isMinuteNotZero = parseInt(value.uiTime.split(':')[1], 10) !== 0;
        }

        if (this.intraday.uiValue && this.isMinuteNotZero) {
          this.showIntradayError = true;
        }
      },
      deep: true,
    },
    intradayMinute: {
      handler(value) {
        if (this.isMinuteNotZero && value.uiValue !== 60) {
          this.showIntradayError = true;
        }
      },
      deep: true,
    },
    currentCron: {
      handler(value) {
        this.cron = value;
        this.$emit('updateCron', this.cron, this.timeZone.wfTimeZone);
      },
    },
    timeZone: {
      handler(value) {
        this.timeZone.wfTimeZone = value.dropDownTimeZone;
        this.$emit('updateCron', this.cron, this.timeZone.wfTimeZone);
      },
      deep: true,
    },
  },
  mounted() {
    if (this.existingCronExpr !== '') {
      this.init();
    }
  },
  methods: {
    init() {
      this.cron = this.existingCronExpr;
      this.timeZone.wfTimeZone = this.existingTimeZone;
      this.timeZone.dropDownTimeZone = this.timeZone.wfTimeZone;

      const temp = this.cron.split(' ');
      const second = temp[0];
      const minute = temp[1];
      const parsedMin = temp[1].includes('/') ? temp[1].split('/')[0] : temp[1];
      const hour = temp[2];
      const dayOfMonth = temp[3];
      const month = temp[4];
      const dayOfWeek = temp[5];
      const someField = temp[6];

      // set time
      this.time.uiTime = `${hour.length < 2 ? `0${hour}` : hour}:${parsedMin.length < 2 ? `0${parsedMin}` : parsedMin}`;
      this.time.wfTime = this.time.uiTime;

      // set monthly or weekly
      if (dayOfMonth === '?' && !(dayOfWeek.includes('#') || dayOfWeek.includes('L'))) {
        this.buttonGroupContent[0].active = true;
        this.buttonGroupContent[1].active = false;
        if (minute.includes('/')) {
          this.intraday.uiValue = true;
          this.intraday.wfValue = true;
          this.intradayMinute.uiValue = parseInt(minute.split('/')[1], 10);
          this.intradayMinute.wfValue = parseInt(minute.split('/')[1], 10);
        }
        this.buttonGroupContent[0].active = true;
        if (dayOfWeek === '*') {
          this.weekdayDataset.forEach((day) => {
            day.enabled = true;
            day.wfValue = true;
            return day;
          });
        } else if (dayOfWeek.includes('-')) {
          const [startDay, endDay] = dayOfWeek.split('-');

          let enable = false;

          // Iterate through each day in the dataset
          this.weekdayDataset.forEach((day) => {
            if (day.value === startDay) {
              enable = true;
            }

            if (enable) {
              day.enabled = true;
              day.wfValue = true;
            }

            if (day.value === endDay) {
              enable = false;
            }
          });
        } else {
          this.weekdayDataset.forEach((day) => {
            day.enabled = dayOfWeek.includes(day.value);
            day.wfValue = day.enabled;
            return day;
          });
        }
      } else {
        this.buttonGroupContent[1].active = true;
        this.buttonGroupContent[0].active = false;
        if (dayOfMonth === 'L') {
          this.selectedRadio.uiValue = 'lastDayOfMonth';
        } else if (dayOfWeek.includes('#')) {
          this.selectedRadio.uiValue = 'dayOfEachWeek';
          [this.weekdayOfMonth.uiValue, this.nthDayOfMonth.uiValue] = dayOfWeek.split('#');
          [this.weekdayOfMonth.wfValue, this.nthDayOfMonth.wfValue] = [this.weekdayOfMonth.uiValue, this.nthDayOfMonth.uiValue];
        } else if (dayOfWeek.includes('L')) {
          this.selectedRadio.uiValue = 'dayOfEachWeek';
          [this.weekdayOfMonth.uiValue, this.nthDayOfMonth.uiValue] = [dayOfWeek.replace('L', ''), 'L'];
          [this.weekdayOfMonth.wfValue, this.nthDayOfMonth.wfValue] = [this.weekdayOfMonth.uiValue, this.nthDayOfMonth.uiValue];
        } else {
          this.selectedRadio.uiValue = 'dayOfMonth';
          this.dayOfMonth.uiValue = parseInt(dayOfMonth, 10);
          this.dayOfMonth.wfValue = this.dayOfMonth.uiValue;
        }
        this.selectedRadio.wfValue = this.selectedRadio.uiValue;
      }
      this.isInitDone = true;
    },
    updateCron() {
      this.$emit('updateCron', this.cron, this.timeZone.wfTimeZone); // Emit event with new value
    },
    selectWeekday(event, button) {
      button.enabled = !button.enabled;
    },
    setActiveItem(event) {
      this.buttonGroupContent.forEach((item) => {
        if (item.id === event.currentTarget.id) {
          item.active = true;
        } else {
          item.active = false;
        }
      });
    },
    makeCron() {
      const [hr, min] = this.time.uiTime.split(':').map(val => parseInt(val, 10));
      if (this.buttonGroupContent[0].active) {
        const weekdaysSelected = this.weekdayDataset.filter(day => day.enabled).map(day => day.value).join(',');
        const intra = this.intraday.uiValue ? `0/${this.intradayMinute.uiValue}` : min;
        return `0 ${intra} ${hr} ? * ${weekdaysSelected} *`;
      }
      if (this.selectedRadio.uiValue === 'lastDayOfMonth') {
        return `0 ${min} ${hr} L * ? *`;
      }
      if (this.selectedRadio.uiValue === 'dayOfMonth') {
        return `0 ${min} ${hr} ${this.dayOfMonth.uiValue} * ? *`;
      }
      const dayOfEachWeek = this.nthDayOfMonth.uiValue === 'L' ? `${this.weekdayOfMonth.uiValue}L`
        : `${this.weekdayOfMonth.uiValue}#${this.nthDayOfMonth.uiValue}`;
      return `0 ${min} ${hr} ? * ${dayOfEachWeek} *`;
    },
    dismissIntradayError() {
      this.intraday.uiValue = false;
      this.showIntradayError = false;
    },
    intradayChanged(value) {
      if (value && this.isMinuteNotZero && this.intradayMinute.uiValue !== 60) {
        this.showIntradayError = true;
        this.$nextTick(() => {
          this.intraday.uiValue = false;
        });
      }
    },
  },
};
</script>

<style scoped>
.read-only {
  cursor: no-drop;
  pointer-events: none !important;
  opacity: 0.5 !important;
  display: block !important;
}
</style>
