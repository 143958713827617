<template>
  <div class="nested-columns-col-wrapper">
    <mds-section
      class="section-wrap"
      title="Data Delete Options"
      border-position="bottom"
    />
    <mds-form :class="{ disabled: dialogType !== ''}">
      <mds-field-set
        :size="size"
        variation="radio-group"
      >
        <mds-radio-button
          v-model="selectedDeleteOption"
          checked
          :size="size"
          name="DeleteOptions"
          value="rm_ts"
          label="Remove All Data"
        />
        <mds-radio-button
          v-model="selectedDeleteOption"
          name="DeleteOptions"
          :size="size"
          value="rm_md"
          label="Remove Metadata"
        />
        <mds-radio-button
          v-model="selectedDeleteOption"
          name="DeleteOptions"
          :size="size"
          value="rm_ks"
          label="Remove Key and Metadata"
        />
        <mds-radio-button
          v-model="selectedDeleteOption"
          name="DeleteOptions"
          :size="size"
          value="rm_recs"
          label="Remove Records"
        />
        <mds-input
          v-model="fromDateTime"
          label="FromDateTime"
          type="text"
          microcopy-below="FromDateTime should be in YYYY-MM-DDTHH:mm:ss format"
          :disabled="selectedDeleteOption !== 'rm_recs'"
          placeholder="YYYY-MM-DDTHH:mm:ss"
        />
        <mds-input
          v-model="toDateTime"
          label="ToDateTime"
          type="text"
          microcopy-below="ToDateTime should be in YYYY-MM-DDTHH:mm:ss format"
          :disabled="selectedDeleteOption !== 'rm_recs'"
          placeholder="YYYY-MM-DDTHH:mm:ss"
        />
      </mds-field-set>
    </mds-form>
    <mds-button-container right-aligned>
      <mds-button
        variation="primary"
        size="small"
        :disabled="isDeleteButtonDisabled || showLoader"
        @click="displayDialogBox('deleteBox')"
      >
        Delete
      </mds-button>
    </mds-button-container>
    <mds-inline-message
      v-if="isDeleteButtonDisabled"
      variation="warning"
    >
      {{ validationMessage }}
    </mds-inline-message>
    <div
      v-if="showLoader"
      class="div-centered"
    >
      <mds-loader size="large" />
    </div>
    <mds-alert
      v-if="dialogType == 'deleteBox'"
      title="Delete Key/Data"
      variation="warning"
      class="div-centered"
      dismissible="false"
    >
      {{ warningMessage }}<br><br>
      <div class="table-content">
        <mds-table
          size="medium"
          row-hover
        >
          <mds-tbody>
            <mds-tr
              v-for="(key) in deleteKeyValueMap"
              :key="key.Id"
            >
              <mds-td>
                {{ key }}
              </mds-td>
            </mds-tr>
          </mds-tbody>
        </mds-table>
      </div>
      <mds-button-container
        right-aligned
      >
        <mds-button
          size="medium"
          variation="secondary"
          @click="displayDialogBox('')"
        >
          Cancel
        </mds-button>
        <mds-button
          size="medium"
          variation="primary"
          @click="deleteKeyMethod()"
        >
          Yes, Proceed
        </mds-button>
      </mds-button-container>
    </mds-alert>
    <mds-alert
      v-if="dialogType == 'errorBox'"
      class="div-centered-top"
      dismissible="false"
      variation="error"
    >
      <div class="right-middle">
        {{ errorMessage }}<br>
        <span><mds-button
          id="adSuccessButton"
          slot="mds-alert-custom-action"
          size="medium"
          variation="icon-only"
          icon="remove"
          style="top:5px;"
          @click="displayDialogBox('')"
        >
          close
        </mds-button></span>
      </div>
    </mds-alert>
    <mds-alert
      v-if="dialogType == 'successBox'"
      class="div-centered-top"
      dismissible="false"
      variation="success"
    >
      {{ alertMessage }}.
      <span><mds-button
        id="adSuccessButton"
        slot="mds-alert-custom-action"
        size="medium"
        variation="icon-only"
        icon="remove"
        style="top:5px;"
        @click="displayDialogBox('')"
      >
        close
      </mds-button></span>
    </mds-alert>
  </div>
</template>
<script>
import MdsSection from '@mds/section';
import {
  MdsTable, MdsTbody, MdsTd, MdsTr,
} from '@mds/data-table';
import moment from 'moment';
import { MdsButton, MdsButtonContainer } from '@mds/button';
import MdsRadioButton from '@mds/radio-button';
import MdsInlineMessage from '@mds/inline-message';
import MdsFieldSet from '@mds/fieldset';
import MdsInput from '@mds/input';
import MdsLoader from '@mds/loader';
import MdsForm from '@mds/form';
import axios from 'axios';

export default {
  name: 'DataDeleteOptions',
  components: {
    MdsInlineMessage,
    MdsSection,
    MdsTable,
    MdsTbody,
    MdsTr,
    MdsTd,
    MdsFieldSet,
    MdsRadioButton,
    MdsInput,
    MdsForm,
    MdsButtonContainer,
    MdsButton,
    MdsLoader,
  },
  data() {
    return {
      activeItemId: '',
      feedName: '',
      selectedDeleteOption: '',
      fromDateTime: '',
      toDateTime: '',
      dialogType: '',
      size: 'small',
      errorMessage: '',
      alertMessage: '',
      validationMessage: '',
      deleteKeyValueMap: {},
      showLoader: false,
    };
  },
  computed: {
    isDeleteButtonDisabled() {
      return !this.validateDeleteOptions();
    },
    warningMessage() {
      const messages = {
        rm_ts: 'Are you sure you want to remove data for below keys?',
        rm_ks: 'Are you sure you want to remove key and metadata for below keys?',
        rm_md: 'Are you sure you want to remove metadata for below keys?',
        rm_recs: 'Are you sure you want to remove records for below keys?',
      };

      return messages[this.selectedDeleteOption];
    },
  },
  created() {
    this.$eventBus.$on('searchClearFeed', (data) => {
      this.activeItemId = data;
    });
    this.$eventBus.$on('deleteKeyValueMap', (data) => {
      this.deleteKeyValueMap = data;
    });
  },
  mounted() {
    this.$eventBus.$on('feedNameSelected', (data) => {
      this.feedName = data;
    });
  },
  methods: {
    displayDialogBox(dialogValue) {
      this.dialogType = dialogValue;
    },
    async deleteKeyMethod() {
      this.displayDialogBox('');
      this.showLoader = true;
      const urls = this.generatePutUrls();

      if (urls.length === 0) {
        return;
      }

      try {
        const responses = await Promise.all(
          urls.map(url => axios.put(url, {}, { timeout: 600000 })),
        );
        const allSuccessful = responses.every(response => response && response.status === 200);
        if (allSuccessful) {
          this.dialogType = 'successBox';
          this.alertMessage = 'Selected data operation is successfull';
        } else {
          const errorResponse = responses.find(response => !response || response.status !== 200);
          this.errorMessage = errorResponse
            ? errorResponse.Message
            : 'An unknown error occurred';
          this.dialogType = 'errorBox';
        }
      } catch (error) {
        this.errorMessage = 'An error occurred while deleting keys';
        this.dialogType = 'errorBox';
      } finally {
        this.clearState();
      }
    },
    generatePutUrls() {
      if (!this.validateDeleteOptions()) {
        this.dialogType = 'errorBox';
        return '';
      }

      const baseUrl = `api/feeds/${this.feedName}/keys?`;
      const { selectedDeleteOption, fromDateTime, toDateTime } = this;
      const deleteOptionParam = `${selectedDeleteOption}=true`;

      return Object.entries(this.deleteKeyValueMap).map(([key, value]) => {
        let url = `${baseUrl}${value}&${deleteOptionParam}`;
        if (selectedDeleteOption === 'rm_recs' && fromDateTime && toDateTime) {
          url += `&fromDateTime=${fromDateTime}&toDateTime=${toDateTime}`;
        }
        return url;
      });
    },
    validateDeleteOptions() {
      const {
        selectedDeleteOption, fromDateTime, toDateTime, deleteKeyValueMap, feedName,
      } = this;

      // Check if feedName is provided
      if (!feedName) {
        this.validationMessage = 'Feed name is required.';
        return false;
      }

      // Check if deleteKeyValueMap is empty
      if (!deleteKeyValueMap || Object.keys(deleteKeyValueMap).length === 0) {
        this.validationMessage = 'No keys are selected for deletion.';
        return false;
      }

      // Check if delete option is selected
      if (!selectedDeleteOption) {
        this.validationMessage = 'You must select a delete option.';
        return false;
      }

      // Regex for YYYY-MM-DDTHH:mm:ss format
      const dateTimeRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}$/;

      // If the selected delete option is "rm_recs", validate fromDateTime and toDateTime
      if (selectedDeleteOption === 'rm_recs') {
        // If both are empty or only one is defined, validation fails
        if (!fromDateTime || !toDateTime) {
          this.validationMessage = 'Both "from" and "to" datetime fields are required.';
          return false;
        }

        // Check if fromDateTime and toDateTime match the required format
        if (!dateTimeRegex.test(fromDateTime) || !dateTimeRegex.test(toDateTime)) {
          this.validationMessage = 'Invalid datetime format. Use YYYY-MM-DDTHH:mm:ss.';
          return false;
        }

        // Validate format and validity of fromDateTime and toDateTime using moment
        const fromDateValid = moment(fromDateTime, 'YYYY-MM-DDTHH:mm:ss', true).isValid();
        const toDateValid = moment(toDateTime, 'YYYY-MM-DDTHH:mm:ss', true).isValid();

        if (!fromDateValid || !toDateValid) {
          this.validationMessage = 'One or both datetime values are invalid.';
          return false;
        }

        // Ensure fromDateTime <= toDateTime
        if (moment(fromDateTime).isAfter(moment(toDateTime))) {
          this.validationMessage = '"From" datetime must be earlier than or equal to "To" datetime.';
          return false;
        }
      }

      // If all validations pass
      this.validationMessage = '';
      return true;
    },

    clearState() {
      this.deleteKeyValueMap = {};
      this.selectedDeleteOption = '';
      this.showLoader = false;
      this.validationMessage = '';
    },
  },
};
</script>
<style lang='scss'>
@import '~@mds/constants';
@import '~@mds/fonts';
@import '~@mds/typography';

.nested-columns-col-wrapper {
  box-sizing: border-box;
  background-color: white;
  width: 300px;
  height: 100%;
  flex-grow: 1;
  border: 7px solid whitesmoke;
  display: flex;
  flex-direction: column;
}
.section-wrap {
  padding-left: $mds-space-1-x;
}
.div-centered-top {
    position: fixed;
    top: 20%;
    left: 60%;
    transform: translate(-60%, -50%);
    z-index: 9999;
}
.disabled {
  opacity: 0.5;
  pointer-events: none;
}
.right-middle {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.table-content {
  max-height: 400px;
  overflow-y: auto;
}

</style>
