const Text = {
    invalidExecPayload: 'Unrecognized payload, expected 2D array.',
    noData:             'Response is empty - no data.',

    date:       'Date',
    origVal:    'Source Value',
    descr:      'Description',
    derivedVal: 'Curve Value',

    traceColumn: '[col_index]-trace'
};

export default Text;