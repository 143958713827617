<template>
  <div
    class="qa-launch-phase"
    :class="isEditable === 'false' || isEditable === false ? 'disabled' : ''"
  >
    <!-- eslint-disable-next-line vue/no-lone-template -->
    <template>
      <mds-loader
        v-if="showLoader"
        size="small"
        aria-label="Small Loader"
      />
      <mds-form v-if="!showErrorMsg && !showLoader">
        <mds-row style="padding-bottom: 2%;">
          <mds-col>
            <span>
              Choose which input set(s) to launch:
            </span>
          </mds-col>
        </mds-row>
        <mds-row>
          <mds-col :cols="12">
            <mds-table>
              <mds-thead style="padding-bottom: 2%;">
                <mds-th style="width: 10%;">
                  <mds-checkbox @change="allInputChanged" />
                </mds-th>
                <mds-th style="width: 60%;">
                  Input set
                </mds-th>
                <mds-th> Status </mds-th>
              </mds-thead>

              <mds-tbody>
                <mds-tr
                  v-for="(iRow, index) in inputSet"
                  :key="index"
                >
                  <mds-td style="padding-top: 1%;">
                    <mds-checkbox
                      v-model="iRow.checked"
                      :disabled="['Stopped', 'Not Started'].includes(iRow.isRunning)"
                    />
                  </mds-td>
                  <mds-td style="padding-top: 1%;">
                    {{ iRow.name }}
                  </mds-td>
                  <mds-td style="padding-top: 1%;">
                    {{ iRow.isRunning }}
                  </mds-td>
                </mds-tr>
              </mds-tbody>
            </mds-table>
          </mds-col>
        </mds-row>
      </mds-form>
      <div
        v-if="!showErrorMsg && !showLoader"
        class="bottom-plane"
      >
        <mds-row>
          <mds-col>
            <mds-checkbox
              value="accept"
              style="padding-bottom: 0px !important;"
            >
              Mark "complete" after it succeeds
            </mds-checkbox>
          </mds-col>
        </mds-row>
        <hr>
        <mds-row>
          <mds-col style="display: flex; justify-content: left;" />
          <mds-col style="display: flex; justify-content: right;">
            <mds-button
              variation="primary"
              :disabled="disableLaunch"
              @click="launchPhase(disableLaunch)"
            >
              Launch
            </mds-button>
          </mds-col>
        </mds-row>
      </div>
      <div v-if="showErrorMsg">
        <mds-empty-state
          title="Nothing to edit"
          message="Please connect proper bubble to this bubble."
          size="large"
          style="margin: auto;"
        />
      </div>
    </template>
  </div>
</template>

<script>
import {
  MdsTable, MdsThead, MdsTh, MdsTbody, MdsTr, MdsTd,
} from '@mds/data-table';
import MdsForm from '@mds/form';
import { mapGetters, mapActions } from 'vuex';
import { MdsButton } from '@mds/button';
import MdsCheckbox from '@mds/checkbox';
import { MdsRow, MdsCol } from '@mds/layout-grid';
import axios from 'axios';

export default {
  name: 'WorkflowFormulaLaunchPhaseActions',
  components: {
    MdsForm,
    MdsTable,
    MdsThead,
    MdsTh,
    MdsTbody,
    MdsTr,
    MdsTd,
    MdsRow,
    MdsCol,
    MdsButton,
    MdsCheckbox,
  },
  props: {
    node: {
      type: Object,
      default: null,
    },
    parameterSetData: {
      type: Object,
      default: null,
    },
    workflow: {
      type: Object,
      default: null,
    },
    isEditable: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      showLoader: false,
      workflowData: {},
      currentInputToEdit: {
        name: '',
        body: {},
      },
      inputSet: [],
      markDone: false,
      showErrorMsg: false,
    };
  },
  computed: {
    ...mapGetters('workflowModule', ['getParamStatus']),
    disableLaunch() {
      return this.inputSet.every(row => !row.checked);
    },
  },
  mounted() {
    this.showLoader = true;
    this.getWorkflowStatus(this.workflow.id).then(() => {
      this.workflowData = this.getParamStatus(this.workflow.id);
      for (let i = 0; i < this.workflowData.workFlowJobModel.parameterSetGroupModel.parameterSetModels.length; i++) {
        const element = this.workflowData.workFlowJobModel.parameterSetGroupModel.parameterSetModels[i];
        const currentIpSet = { checked: false };
        currentIpSet.name = element.name;
        if (element.status && element.status.finish_date === null) {
          currentIpSet.isRunning = 'Running';
          currentIpSet.runId = element.status.run_id;
        } else if (element.status && element.status.finish_date !== null) {
          currentIpSet.isRunning = 'Stopped';
        } else {
          currentIpSet.isRunning = 'Not Started';
        }
        this.inputSet.push(currentIpSet);
      }
    }).finally(() => {
      this.showLoader = false;
    });
  },
  methods: {
    ...mapActions('workflowModule', ['updateUserWorkflowsDiagram', 'getWorkflowStatus']),
    allInputChanged(value) {
      this.inputSet.forEach((row) => {
        if (row.isRunning === 'Running') {
          row.checked = value;
        }
      });
    },
    launchPhase(isDisabled) {
      if (isDisabled) {
        return;
      }
      const inputsToLaunch = this.inputSet.filter(row => row.checked);
      const payload = { target_name: this.node.data.targetName, mark_done: this.markDone };
      payload.run_id = inputsToLaunch.map(row => row.runId);
      this.showLoader = true;
      axios.post('/api/workflows/launchPhase', payload).then(() => {}).finally(() => {
        this.showLoader = false;
      });
    },
  },
};
</script>

  <style>
  .stp-table {
    border: 1px solid #515151;
    padding: 2%;
    border-radius: 3px;
    height: 300px;
    overflow-y: scroll;
  }
  .mds-select___Mcd-ui {
      margin-top: 2% !important;
  }
  .btn-row {
    padding-top: 1%;
  }
  #fullscreen .qa-launch-phase .mds-checkbox__visible-wrap___Mcd-ui {
      padding-bottom: 5% !important;
  }
  .bottom-plane .mds-checkbox__visible-wrap___Mcd-ui {
      padding-bottom: 0% !important;
      margin-top: 2%;
  }
  thead {
      border-bottom: 1px solid #1e1e1e;
  }
  tbody {
      margin-top: 2%;
  }
  .qa-launch-phase {
    height: 88% !important;
    padding: 1%;
  }
  .qa-launch-phase form {
    height: 100%;
    overflow-x: hidden;
  }
  #fullscreen .disabled {
    pointer-events: none;
    cursor: no-drop;
    opacity: 0.5;
    display: block !important;
  }
  </style>
