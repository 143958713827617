/* eslint-disable camelcase */
/* eslint-disable no-use-before-define */
/*
 * Marketplace Formula Step (mpfstep.es6)
 *
 * Defines MpFormulaStep and MpFormulaStepType which represent
 * steps within a Marketplace workflow (JS) formula.
 */

// import lim from 'lim';
import $ from 'jquery';
import _ from 'underscore';
import Text from './lang/mpfstep_en-us.es6';
import Enums, { EnumItem } from '../enums.es6';
import Numbers, { isNumber, requireNonNegativeInteger } from '../numbers.es6';
import Dates from '../dates/dates.es6';
import Strings, { requireNonEmptyString } from '../strings.es6';
import Arrays from '../arrays.es6';
import Objects, { isVoid, requireObject } from '../objects.es6';
// import UI from '../ui.es6';
import VarDataType from '../vardatatype.es6';
import ScriptVar from '../script/scriptvar.es6';


const FIXED_DATE = 'fixed_date';
const FIXED_LEN = 'fixed_length';

const EOL = '\n';

/** @type {{h: "addHours", d: "addDays", M: "addMonths", y: "addYears"}} */
const DATE_CODE_MAP = Object.freeze({
  h: 'addHours',
  d: 'addDays',
  M: 'addMonths',
  y: 'addYears',
});

/** @type {WorkflowVarDataType[]} */
const BASIC_MATH_TYPES = Object.freeze([
  VarDataType.TIME_SERIES,
  VarDataType.FLOAT,
]);

const REGEX_RELATIVE_RUN_DATE = new RegExp('^(-?\\d+)([hdDMy])$');

const PATT_VAR_NAME_USER_DEF = '[a-zA-Z][a-zA-Z_0-9]*';
const PATT_VAR_NAME_BUILT_IN = '\\$[a-zA-Z][a-zA-Z_0-9]*';
const PATT_VAR_NAME_ANY = '\\$?[a-zA-Z][a-zA-Z_0-9]*';
const PATT_RESOLVED_MATH = '\\{\\{@(\\d+)\\}\\}';
const PATH_BASIC_MATH_INPUT = `(${ PATT_VAR_NAME_ANY
}|${ PATT_RESOLVED_MATH
}|${ Numbers.FLOAT_PATTERN })`;

const REGEX_VAR_NAME_ANY = new RegExp(`^${ PATT_VAR_NAME_ANY }$`);
const REGEX_MATH_RESOLVED = new RegExp(`^${ PATT_RESOLVED_MATH }$`);
const REGEX_FLOAT = new RegExp(`^${ Numbers.FLOAT_PATTERN }$`);
const REGEX_BASIC_MATH_1 = new RegExp(`\\s*${ PATH_BASIC_MATH_INPUT
}\\s*([\\*\\/])\\s*${
  PATH_BASIC_MATH_INPUT }\\s*`);
const REGEX_BASIC_MATH_2 = new RegExp(`\\s*${ PATH_BASIC_MATH_INPUT
}\\s*([\\+\\-])\\s*${
  PATH_BASIC_MATH_INPUT }\\s*`);


/**
 * Validates the given argument to be an Array of ScriptVar objects.
 * If valid, this method returns that argument.  Otherwise,
 * this method throws.
 *
 * @param {ScriptVar[]} arg
 * @param {string} name
 * @returns {ScriptVar[]}
 * @private
 */
function _validAvailVars(arg, name) {
  if (!Arrays.isArrayOf(arg, ScriptVar)) {
    throw new TypeError(`${name }: ScriptVar[]`);
  }
  return arg;
}

/**
 * If `obj` has a property called `prop`, this method
 * returns that property's value.  Otherwise this method
 * returns `defaultVal`.
 * @param {Object} obj
 * @param {string} prop
 * @param {*} defaultVal
 * @returns {*}
 * @private
 */
function _if(obj, prop, defaultVal) {
  if (Object.hasOwnProperty.call(obj, prop)) {
    return obj[prop];
  }
  return defaultVal;
}

/**
 * Returns the language-specific text associated with `val`
 * within `langObj`.  If `val` is not defined in the dictionnary,
 * it - `val` - is returned.
 * @param {Object} langObj
 * @param {string} val
 * @returns {string}
 * @private
 */
function _safeLang(langObj, val) {
  return _if(langObj, val, val);
}


/**
 * Compares two objects using the value of their `name()` method,
 * for the purpose of sorting them.
 * @param {{name: function}} sv1
 * @param {{name: function}} sv2
 * @returns {number}
 * @private
 */
function _compareNameMethod(sv1, sv2) {
  return Strings.compare(sv1.name(), sv2.name());
}

/**
 * Creates a new row within a TABLE element, a label TD and
 * a container TD.
 * @param {jQuery} parentElm
 * @param {string} text
 * @returns {jQuery} Container TD element.
 * @private
 */
function _newTableRow(parentElm, text) {
  const tr = $('<tr>').appendTo(parentElm);
  $('<th>').appendTo(tr).text(text);
  return $('<td>').appendTo(tr);
}

/**
 * Finds the first TR element that is a parent of the given element.
 * @param {jQuery} elm
 * @returns {jQuery} JQuery wrapper around HTMLTableRowElement.
 * @private
 */
function _findTr(elm) {
  while (elm[0].nodeName !== 'TR') { elm = elm.parent(); }

  return elm;
}

/**
 * Creates an INPUT[type=radio] element, wrapped within a LABEL element.
 * @param {jQuery} parentElm
 * @param {string} name
 * @param {string} val
 * @param {string} text
 * @param {jQuery} [inputElm]
 * @returns {jQuery} JQuery wrapper to the HTMLInputRadioElement.
 * @private
 */
function _newRadioBtn(parentElm, name, val, text, inputElm) {
  const label = $('<label>').appendTo(parentElm);
  const btn = $('<input type="radio">').attr('name', name).val(val).appendTo(label);

  if (arguments.length < 5) { $('<span>').appendTo(label).text(text); } else { _newInputEmbedded(label, inputElm, text); }

  return btn;
}

/**
 * Creates an INPUT[type=checkbox] element, wrapped within a LABEL element.
 * @param {HTMLElement} parentElm
 * @param {string} val
 * @param {string} text
 * @returns {jQuery} JQuery wrapper to the HTMLInputCheckboxElement.
 * @private
 */
function _newCheckbox(parentElm, val, text) {
  const label = $('<label>').appendTo(parentElm);
  const btn = $('<input type="checkbox">').val(val).appendTo(label);

  $('<span>').appendTo(label).text(text);

  return btn;
}

/**
 * Embeds an element within arbitrary text, wherever "<INPUT>" is found.
 * @param {jQuery} parentElm
 * @param {jQuery} elmToEmbed
 * @param {string} text
 * @returns {jQuery} `elmToEmbed`
 * @private
 */
function _newInputEmbedded(parentElm, elmToEmbed, text) {
  const ph = '<INPUT>';
  const idx = text.indexOf(ph);

  if (idx < 0) { throw new Error(`IllegalStateException: <INPUT> not found in text "${ text }".`); }

  // let label = $('<label>').appendTo(parentElm);

  $('<span>').appendTo(parentElm).text(text.substring(0, idx));
  elmToEmbed.appendTo(parentElm);
  $('<span>').appendTo(parentElm).text(text.substring(idx + ph.length));

  return elmToEmbed;
}

/**
 * Creates and returns a TEXTAREA element in which users can write some text to be
 * injected into a QA error message.
 * @param {jQuery} tableElm
 * @returns {jQuery} JQuery wrapper to HTMLTextAreaElement.
 * @private
 */
function _newUserMessage(tableElm) {
  const TextUM = Text.UserMessage;

  return $('<textarea>').addClass('user-message')
    .attr('placeholder', TextUM.tooltip)
    .appendTo(_newTableRow(tableElm, TextUM.enterCustomMessage));
}

/**
 * Returns JavaScript code that creates an anonymous function
 * to return error data in a consistent way.
 * @param {string} errorTs - JavaScript expression that evaluates to a TimeSeries object.
 * @returns {string}
 * @private
 */
function _genDataDetails(errorTs) {
  return [
    '(function (errorTs, maxRows) {',
    "    var more = '';",
    '    if (errorTs.length() > maxRows) {',
    "        more    = '[' + (errorTs.length() - maxRows).toString(10) + ' more...]';",
    '        errorTs = errorTs.slice(0, maxRows);',
    '    }',
    '',
    '    return errorTs.toCsv() + more;',
    `})(${ errorTs }, 2)`,
  ].join(EOL);
}

/**
 * Generates and returns a QA error message.
 * @param {string} qaType - QA type
 * @param {string} tsVar - Name of the variable containing the data being tested.
 * @param {string} qaName - Name given to this test by user.
 * @param {string} ctxMsg - JavaScript expression that evaluates to a context message.
 * @param {string} usrMsg - Custom message entered by user to be
 *                          appended to end of QA message (raw,
 *                          not stringified.)  This method stringifies
 *                          this portion of the message.
 * @returns {string} A JavaScript expression that evaluates to a String.
 * @private
 */
function _genFailMsg(qaType, tsVar, qaName, ctxMsg, usrMsg) {
  let msg = `${qaType }: \`${ tsVar }\``;
  let msgConcat;

  if (Strings.isNonEmpty(qaName)) { msg = `${qaName } (${ msg })`; }

  if (Strings.isNonEmpty(ctxMsg)) {
    msgConcat = `${JSON.stringify(`${msg }\n`)
    } + ${
      ctxMsg}`;
  } else { msgConcat = JSON.stringify(msg); }

  if (Strings.isNonEmpty(usrMsg)) {
    msgConcat += ` + ${
      JSON.stringify(`\n\n${ Text.UserMessage.enterCustomMessage }\n`)
    } + ${
      JSON.stringify(usrMsg)}`;
  }

  return msgConcat;
}

/**
 * Adds an OPTION element to a SELECT element.
 * @param select {jQuery} HTMLSelectElement
 * @param val {string}
 * @param [text] {string}
 * @returns {jQuery} HTMLOptionElement
 * @private
 */
function _addSelOption(select, val, text) {
  if (arguments.length < 3) { text = val; }

  return $('<option>').appendTo(select).val(val).text(text);
}

/**
 * Constructs a SELECT element containing the given enum items,
 * using the given sort function.
 * @param {(EnumItem[]|Object[])} items
 * @param {Object} lang
 * @param {Object} [defaultVal]
 * @returns {jQuery} JQuery wrapper to HTMLSelectElement.
 * @private
 */
function _newEnumSelect(items, lang, defaultVal) {
  const select = $('<select>');
  items.forEach((item) => {
    _addSelOption(select, item.valueOf(), _safeLang(lang, item.toString()));
  });
  if (arguments.length > 2) {
    select.val(defaultVal.valueOf());
  }
  return select;
}

/**
 * Creates a callback function that acts as a filter of ScriptVar objects
 * for the given data-types.
 * @param {WorkflowVarDataType[]} dataTypes
 * @returns {function}
 * @private
 */
function _newDataTypeFilter(dataTypes) {
  const isValid = Arrays.isValid(dataTypes, item => VarDataType.isEnumOf(item));

  if (!isValid) {
    throw new TypeError('dataTypes: WorkflowVarDataType[]');
  }

  const mapByValue = _.indexBy(dataTypes, dataType => dataType.valueOf());

  return function (scriptVar) {
    const dt = scriptVar.dataType();
    return (mapByValue[dt.valueOf()] === dt);
  };
}

/**
 * Creates a new SELECT element that contains a list
 * of variables - sorted by name - of the given data-type(s).
 * @param {jQuery} parentElm
 * @param {WorkflowVarDataType[]} dataTypes
 * @param {ScriptVar[]} availVars
 * @returns {jQuery} JQuery wrapper to the newly created HTMLSelectElement.
 * @private
 */
function _newVarSelect(parentElm, dataTypes, availVars) {
  const select = $('<select>').appendTo(parentElm)
    .addClass('no-blank')
    .on('change', null, null, _monitorNoBlank);
  const vars = availVars.filter(_newDataTypeFilter(dataTypes));

  // sort variables by name
  vars.sort(_compareNameMethod);

  vars.forEach((scriptVar) => {
    _addSelOption(select, scriptVar.name());
  });

  return select;
}

/**
 * Sets the sensitivity - enable vs. disabled - of an INPUT element
 * and its parent LABEL element.
 * @param {jQuery} elm - JQuery wrapper to a HTMLInputElement.
 * @param {boolean} isSensitive
 * @private
 */
function _isSensitive(elm, isSensitive) {
  elm.isSensitive(isSensitive);

  const parentElm = elm[0].parentNode;
  if (parentElm.nodeName === 'LABEL') { $(parentElm).isSensitive(isSensitive); }
}

/**
 * Returns whether an INPUT element - radio or checkbox - is currently checked.
 * @param {jQuery} elm
 * @returns {boolean}
 * @private
 */
function _isChecked(elm) {
  return (elm.prop('checked') === true);
}

/**
 * Sets whether an INPUT element - radio or checkbox - is checked.
 * @param {jQuery} elm
 * @param {boolean} isChecked
 * @returns {jQuery} The given `elm`.
 * @private
 */
function _setChecked(elm, isChecked) {
  if (typeof isChecked !== 'boolean') { throw new TypeError('isChecked: Boolean'); }

  elm.prop('checked', isChecked);
  return elm;
}

/**
 * Sets a value within a SELECT option.
 * If the value is no longer available, this method
 * adds a blank value at the end of the option list,
 * for which the text is set to `val` and adds an "error"
 * CSS class to it.
 * @param {jQuery} sel
 * @param {string} val
 * @returns {jQuery} `sel`
 * @private
 */
function _setPossiblyGoneOption(sel, val) {
  sel.val(val).removeClass('error');
  if (sel.val() !== val) {
    // Handle cases when `val` is no longer available
    _addSelOption(sel, '', val);
    sel.val('').addClass('error');
  }

  return sel;
}

/**
 * Monitors a *no-blank* SELECT element.
 * @param {JQuery.Event} event
 * @private
 */
function _monitorNoBlank(event) {
  const sel = $(event.target);
  const cssMeth = ((sel.val() === '') ? 'addClass' : 'removeClass');

  sel[cssMeth]('error');
}

/**
 * Returns a clean version of `code`, where multiple-spaces
 * are replaced with only one and line-break characters
 * are replaced with regular spaces.
 * @param {string} code
 * @returns {string}
 * @private
 */
function _cleanCode(code) {
  return code.replace(new RegExp('\\s+', 'g'), ' ');
}

/**
 * Creates an INPUT element of type "text" with attributes and properties
 * specific to dates.
 * @returns {jQuery} JQuery wrapper to HTMLInputElement.
 * @private
 */
function _newDateTextInput() {
  return $('<input type="text">').attr('placeholder', Text.Date.dateFormat)
    .on('blur', null, null, _redisplayDate)
    .addClass('date');
}

/**
 * Returns whether the date input contains a valid date value.
 * @param {jQuery} dateElm
 * @returns {boolean}
 * @private
 */
function _isValidDateInput(dateElm) {
  return (Dates.stringToDate(dateElm.val()) !== null);
}

/**
 * Validates a date input element.
 * @param {jQuery} dateElm
 * @param {lim.Window} win
 * @returns {boolean} Returns false if the date element does not contain a valid date.
 * @private
 */
function _validateDateInput(dateElm, win) {
  if (_isValidDateInput(dateElm)) return true;


  dateElm.focus();
  win.warn(Text.Date.Validation.badDate.replace('[value]', dateElm.val()));
  return false;
}

/**
 * Gets the value from the given date input, in ISO-8601 format.
 * @param {jQuery} dateElm
 * @returns {string}
 * @private
 */
function _getDateTextInput(dateElm) {
  const date = Dates.stringToUTCDate(dateElm);
  return Dates.getFormatter('yyyy-MM-dd', true)(date);
}

/**
 * Sets the value of a date input element.
 * @param {jQuery} dateElm
 * @param {string} value
 * @private
 */
function _setDateTextInput(dateElm, value) {
  const date = Dates.isoStringToUTCDate(value);
  let text = value;

  if (date !== null) { text = Dates.getFormatter(Text.Date.dateFormat, true)(date); }

  dateElm.val(text);
}

/**
 * Creates an INPUT element of type "number" with attributes and properties
 * specific to positive integers.
 * @param {int} [min]
 * @returns {jQuery} JQuery wrapper to HTMLInputElement.
 * @private
 */
function _newIntInput(min) {
  const elm = $('<input type="number" step="1">');

  if (arguments.length > 0) { elm.attr('min', min); }

  return elm;
}

/**
 * Returns whether the positive-integer input contains a valid integer value.
 * @param {jQuery} numElm
 * @returns {boolean}
 * @private
 */
function _isValidIntInput(numElm) {
  // calling `val()` on numeric elements that contain
  // an invalid value returns "".
  const val = numElm.val();

  if (!Numbers.isIntegerString(val)) {
    return false;
  } if (numElm.is('[min]')
              && parseInt(val, 10) < parseInt(numElm.attr('min'), 10)) {
    return false;
  }
  return true;
}

/**
 * Validates a positive-integer input element.
 * @param {jQuery} numElm
 * @param {lim.Window} win
 * @returns {boolean} Returns false if the date element does not contain a valid date.
 * @private
 */
function _validateIntInput(numElm, win) {
  if (_isValidIntInput(numElm)) return true;


  const TextVal = Text.Integer.Validation;
  const msg = ((numElm.is('[min]'))
    ? TextVal.badValWithMin.replace('[min]', numElm.attr('min'))
    : TextVal.badVal);

  numElm.focus();
  win.warn(msg);
  return false;
}

/**
 * Creates UI controls for choosing a date relative to $RUN_DATE.
 * @param {jQuery} parentElm
 * @param {string} elmName
 * @returns {Object}
 * @private
 */
function _buildDateUI(parentElm, elmName) {
  const TextD = Text.Date;
  const daysToSub = _newIntInput(0);
  const relative = _newRadioBtn(parentElm,
    elmName,
    'relative',
    TextD.relativeToRunDate,
    daysToSub);
  const skipWeekends = _newCheckbox(parentElm, 'y', TextD.skipWeekends);
  const fixedDate = _newDateTextInput();
  const fixed = _newRadioBtn(parentElm,
    elmName,
    'fixed',
    TextD.fixed,
    fixedDate);

  const ctrls = Object.freeze({
    relative,
    daysToSubtract: daysToSub,
    skipWeekends,
    fixed,
    fixedDate,
    radioset: [relative, fixed],
  });

  relative.prop('checked', true); // default date: relative
  _dateRadioClickApply(ctrls);

  relative.on('click', null, ctrls, _dateRadioClick);
  fixed.on('click', null, ctrls, _dateRadioClick);

  return ctrls;
}

/**
 * Event handler for *click* event on date radio button.
 * @param {(JQuery.Event|MouseEvent)} event
 * @private
 */
function _dateRadioClick(event) {
  if (typeof event.data === 'string') {
    const sanitizedData = sanitize(event.data);
    _dateRadioClickApply(sanitizedData);
  } else {
    console.error('Invalid data type for event.data');
  }
}

function sanitize(input) {
  const div = document.createElement('div');
  div.textContent = input;
  return div.textContent;
}
/**
 * Handles changes to date radio button.
 * @param {Object} ctrls
 * @returns {jQuery} JQuery wrapper to HTMLElement for which
 *                   focus should/can be applied.
 * @private
 */
function _dateRadioClickApply(ctrls) {
  const isRelative = _isChecked(ctrls.relative);
  const daysToSub = ctrls.daysToSubtract;
  const { fixedDate } = ctrls;
  const focusElm = ((isRelative) ? daysToSub : fixedDate);

  _isSensitive(ctrls.skipWeekends, isRelative);
  daysToSub.isSensitive(isRelative);
  fixedDate.isSensitive(!isRelative);

  return focusElm;
}

/**
 * Sets the UI controls for relative date to the given value.
 * @param {Object} ctrls
 * @param {string} value
 * @private
 */
function _setDateUI(ctrls, value) {
  const m = REGEX_RELATIVE_RUN_DATE.exec(value);
  if (m !== null
        && (m[2] === 'd'
            || m[2] === 'D')) {
    _setChecked(ctrls.relative, true);
    ctrls.daysToSubtract.val(parseInt(m[1], 10) * -1);
    _setChecked(ctrls.skipWeekends, (m[2] === 'D'));
  } else {
    // Assuming fixed date
    _setChecked(ctrls.fixed, true);
    _setDateTextInput(ctrls.fixedDate, value);
  }

  _dateRadioClickApply(ctrls);
}

/**
 * Returns whether the given date controls are valid
 * in their current state.
 * @param {Object} ctrls
 * @param {lim.Window} win
 * @returns {boolean}
 * @private
 */
function _isValidDateUI(ctrls, win) {
  const choice = UI.getRadioValue(ctrls.radioset);

  switch (choice) {
    case 'relative':
      if (!_validateIntInput(ctrls.daysToSubtract, win)) { return false; }
      break;

    case 'fixed':
      if (!_validateDateInput(ctrls.fixedDate, win)) { return false; }
      break;

    default:
      throw new Error(`BadCodeException: Unhandled date choice (${ choice })`);
  }

  return true;
}

function _redisplayDate(event) {
  const date = Dates.stringToUTCDate(event.target.value);
  if (date !== null) { event.target.value = Dates.getFormatter(Text.Date.dateFormat, true)(date); }
}

/**
 * Returns the string value associated with the date controls,
 * according to their current state.
 * @param {Object} ctrls
 * @returns {string}
 * @private
 */
function _getDateValue(ctrls) {
  const choice = UI.getRadioValue(ctrls.radioset);
  switch (choice) {
    case 'relative':
      return `-${ ctrls.daysToSubtract.val().trim()
      }${(_isChecked(ctrls.skipWeekends)) ? 'D' : 'd'}`;

    case 'fixed':
      return _getDateTextInput(ctrls.fixedDate);

    default:
      throw new Error(`BadCodeException: Unhandled date choice (${ choice })`);
  }
}

/**
 * Returns JavaScript code to produce a date that corresponds
 * to `dateValue`.
 * @param {string} dateValue
 * @param {?string} [tz] - Name (or ID) of a time-zone.
 * @returns {string}
 * @private
 */
function _getDateCode(dateValue, tz) {
  requireNonEmptyString(dateValue, 'dateValue');

  const hasTz = (arguments.length > 1
                 && tz !== null);

  if (hasTz
        && !Strings.isNonEmpty(tz)) throw new TypeError('tz: String or null');

  const m = REGEX_RELATIVE_RUN_DATE.exec(dateValue);

  if (m !== null) {
    const numDays = parseInt(m[1], 10);

    if (numDays === 0) return '$RUN_DATE';

    if (m[2] === 'D') return `add_business_days($RUN_DATE, ${ m[1] })`;


    return `$RUN_DATE.${
      DATE_CODE_MAP[m[2]] }(${
      m[1]
    }${(hasTz) ? `, "${ tz }"` : ''
    })`;
  }

  return `as.date("${ dateValue }")`;
}

/**
 * Converts JavaScript code that represents a date into a date value.
 * @param {string} code - JavaScript code that represents a date.
 * @returns {string} Date value, such as "-0d", "-2D" or "yyyy-MM-dd".
 * @throws IllegalArgumentException - If the given JavaScript code
 *                                    is not recognized.
 * @private
 */
function _getDateValueFromCode(code) {
  // Today
  if (code === '$RUN_DATE') { return '-0d'; }

  // Minus X days
  let m = new RegExp(`\\$RUN_DATE\\.(addHours|addDays|addMonths|addYears)\\s*\\(\\s*(${ Numbers.INTEGER_PATTERN })\\s*(?:,.*?)?\\)`).exec(code);
  if (m !== null) { return m[2] + _.invert(DATE_CODE_MAP)[m[1]]; }

  // Minus X business days
  m = new RegExp(`add_business_days\\(\\$RUN_DATE, (${ Numbers.INTEGER_PATTERN })\\)`).exec(code);
  if (m !== null) { return `${m[1] }D`; }

  // Fixed date
  m = new RegExp("as\\.date\\(['\"]([\\d\\-T:.]+)['\"]\\)").exec(code);
  if (m !== null
        && Dates.isIsoDate(m[1])) { return m[1]; }

  throw new Error(`IllegalArgumentException: Unable to convert date code into date value (\`${ code }\`)`);
}

/* ******************************************************
 * Class: ParseError
 * ****************************************************** */
/**
 *
 * @param {string} msg - Error message.
 * @param {int} [index] - Index position at which the error was found.
 * @constructor
 * @name MpFormulaStep.ParseError
 */
function ParseError(msg, index) {
  this._msg = requireNonEmptyString(msg, 'msg');
  this._idx = ((arguments.length > 1) ? requireNonNegativeInteger(index, 'index') : -1);
  Object.freeze(this);
}

function _getParseErrMsg() {
  return this._msg;
}

ParseError.prototype = /** @lends MpFormulaStep.ParseError */ {
  constructor: ParseError,

  /**
     * Returns the error message.
     * @returns {string}
     * @method
     */
  getMessage: _getParseErrMsg,

  /**
     * Returns the string representation of the error.
     * @returns {string}
     * @method
     */
  toString: _getParseErrMsg,

  /**
     * Returns the index position at which the error was triggered.
     * @returns {int}
     */
  getIndexPosition() {
    return this._idx;
  },
};

/* ******************************************************
 * Class: Operator
 * ****************************************************** */
/**
 * A mathematical operator.
 * @class
 * @private
 */
class Operator {
  /**
     * @param {string} name - Operator name.
     * @param {string} rep - Mathematical representation.
     * @param {string} jsFn - JavaScript engine function (name).
     * @constructor
     */
  constructor(name, rep, jsFn) {
    this._name = requireNonEmptyString(name, 'name');
    this._rep = requireNonEmptyString(rep, 'rep');
    this._jsFn = requireNonEmptyString(jsFn, 'jsFn');

    Object.freeze(this);
  }

    static ADD = new Operator('ADD', '+', 'cell_sum');

    static SUBTRACT = new Operator('SUBTRACT', '-', 'cell_diff');

    static MULTIPLY = new Operator('MULTIPLY', '*', 'cell_product');

    static DIVIDE = new Operator('DIVIDE', '/', 'cell_quotient');

    /**
     * Returns the Operator object associated with `val`.
     * @param {string} val
     * @param {*} [defaultValue]
     * @returns {(Operator|*)}
     */
    static valueOf(val, defaultValue) {
      if (val instanceof Operator) {
        return val;
      }

      requireNonEmptyString(val, 'val');

      const valUC = val.toUpperCase();

      for (const name in Operator) {
        if (Operator.hasOwnProperty(name)
                && Operator[name] instanceof Operator) {
          const oper = Operator[name];
          if (oper._name === valUC
                    || oper._rep === valUC) return oper;
        }
      }

      if (arguments.length > 1) {
        return defaultValue;
      }
      throw new Error(`Unrecognized Operator value: ${ val}`);
    }

    /**
     * Overrides the default Object.toString() method.
     * @returns {string}
     */
    toString() {
      return this._name;
    }
}

Object.freeze(Operator);

/* ******************************************************
 * Class: BasicMath
 * ****************************************************** */

/**
 * Validates the given argument to be a ScriptVar, BasicMath or Number.
 * If valid, this method returns that argument.  Otherwise,
 * this method throws.
 *
 * @param {(ScriptVar|BasicMath|number)} arg
 * @param {string} name
 * @returns {(ScriptVar|BasicMath|number)}
 * @private
 */
function _validMathInput(arg, name) {
  if (!(((arg instanceof ScriptVar)
              && BASIC_MATH_TYPES.findIndex((dt) => {
                const [varName, value] = [arg.dataType().toString(), arg.dataType().valueOf()];
                return dt.toString() === varName && dt.valueOf() === value;
              }) >= 0)
          || (arg instanceof BasicMath)
          || isNumber(arg))) { throw new TypeError(`${name }: ScriptVar (time-series only), BasicMath or Number`); }

  return arg;
}

/**
 * Converts a basic-math input into its string representation,
 * for the purpose of building (string) formula that'll run
 * within MP's JS engine.
 * @param {(ScriptVar|BasicMath|number)} input
 * @returns {string}
 * @private
 */
function _inputToString(input) {
  if (input instanceof ScriptVar) {
    return input.name();
  } if (input instanceof BasicMath) {
    return input.jsCode();
  } if (isNumber(input)) {
    return input.toString(10);
  }
  throw new Error('BadCodeException: unsupported input type, should have been rejected during construction.');
}

/**
 * Returns the ScriptVar object of the given variable name.
 * This method is case-insensitive.
 *
 * @param {string} varName
 * @param {ScriptVar[]} availVars
 * @param {WorkflowVarDataType[]} [dataTypes]
 * @returns {ScriptVar}
 * @throws {MpFormulaStep.ParseError} If `varName` is not found,
 *                                        or the named variable's data-type differs
 *                                        from the (optionally) provided data-type.
 * @private
 */
function _getAvailVar(varName, availVars, dataTypes) {
  const varNameUC = varName.toUpperCase();

  const candidate = _.find(availVars, availVar => (availVar.name().toUpperCase() === varNameUC));

  if (!(candidate instanceof ScriptVar)) { throw new ParseError(`Unrecognized variable: ${ varName}`); }

  if (arguments.length > 2
        && dataTypes.findIndex((dt) => {
          const [name, value] = [candidate.dataType().toString(), candidate.dataType().valueOf()];
          return dt.toString() === name && dt.valueOf() === value;
        }) < 0) {
    throw new ParseError([
      'Type mismatch: ', varName, ': ', candidate.dataType(),
      ' cannot be converted to [', dataTypes.join(', '), '].',
    ].join(''));
  }

  return candidate;
}

/**
 * Replaces BasicMath placeholders with the original user expression.
 * @param {string} code
 * @param {BasicMath[]} resolved
 * @returns {string}
 * @private
 */
function _restoreUserExpr(code, resolved) {
  const r = new RegExp(PATT_RESOLVED_MATH);
  let m = r.exec(code);

  while (m !== null) {
    const idx = parseInt(m[1], 10);
    let userExpr = '???';

    if (Arrays.isValidIndex(idx, resolved)) { userExpr = resolved[idx]._ue; }

    code = code.substring(0, m.index)
             + userExpr
             + code.substring(m.index + m[0].length);
    m = r.exec(code);
  }

  return code;
}

/**
 * Returns the resolved BasicMath object referenced
 * by the given placeholder
 * @param {string} ph
 * @param {BasicMath[]} resolved
 * @param {*} [defaultValue]
 * @returns {BasicMath|*}
 * @private
 */
function _getBasicMath(ph, resolved, defaultValue) {
  const match = REGEX_MATH_RESOLVED.exec(ph);
  if (match !== null) {
    const idx = parseInt(match[1], 10);
    if (Arrays.isValidIndex(idx, resolved)) return resolved[idx];
    throw new Error(`IllegalStateException: ${ ph } not found in resolved list`);
  } else if (arguments.length > 2) return defaultValue;

  else throw new Error('IllegalArgumentException: `ph` does not represent a resolved mathematical expression.');
}

/**
 * Resolves one variable within an expression, returns its object.
 * @param {string} v
 * @param {BasicMath[]} resolved - List of resolved expressions.
 * @param {ScriptVar[]} availVars - Variables available at this time.
 * @returns {(ScriptVar|BasicMath|number)}
 * @throws {MpFormulaStep.ParseError} If `var` is not recognized.
 * @private
 */
function _resolveBasicMathVar(v, resolved, availVars) {
  if (REGEX_FLOAT.test(v)) {
    return Numbers.parseFloat(v);
  } if (REGEX_VAR_NAME_ANY.test(v)) {
    return _getAvailVar(v, availVars, BASIC_MATH_TYPES);
  }
  const bm = _getBasicMath(v, resolved, null);
  if (bm === null) throw new Error(`BadCodeException: unable to resolve \`${ v }\``);
  return bm;
}

/**
 * Resolves simple mathematical operations within `expr`.
 * @param {RegExp} regex - Regular expression used to match single operations.
 * @param {string} expr - Math expression within which operations are sought.
 * @param {BasicMath[]} resolved - Resolved operations.
 * @param {ScriptVar[]} availVars - Variables available at this time.
 * @param {boolean} isSubExpr - Whether `expr` was originally wrapped within parentheses.
 * @returns {string}
 * @private
 */
function _resolveBasicMathOperation(regex, expr, resolved, availVars, isSubExpr) {
  let match = regex.exec(expr);

  while (match !== null) {
    // Found a basic math operation

    const in1 = _resolveBasicMathVar(match[1], resolved, availVars);
    const oper = Operator.valueOf(match[3]);
    const in2 = _resolveBasicMathVar(match[4], resolved, availVars);
    const ridx = resolved.length;
    const matched = ((isSubExpr) ? `(${ match[0] })` : match[0]);

    try {
      resolved.push(new BasicMath(oper, in1, in2, matched));
    } catch (ex) {
      if (ex instanceof ParseError) { throw new ParseError(`${ex.getMessage() } (ref.: ${ _cleanCode(match[0]) })`); } else { throw ex; } // throw as is.
    }

    expr = `${expr.substring(0, match.index)
    }{{@${ ridx.toString(10) }}}${
      expr.substring(match.index + match[0].length)}`;
    match = regex.exec(expr);
  }

  return expr;
}

/**
 * Resolves `expr` and returns the placeholder pointing to the
 * index position of the BasicMath object within `resolved`.
 *
 * @param {string} expr - Expression to resolve.
 * @param {BasicMath[]} resolved - List of resolved expressions.
 * @param {ScriptVar[]} availVars - Variables available at this time.
 * @param {boolean} isSubExpr - Whether `expr` was originally wrapped within parentheses.
 * @returns {string} Placeholder to be used within bigger expression,
 *          to refer back to the corresponding BasicMath object
 *          within `resolved`.
 * @throws {MpFormulaStep.ParseError} If `expr` cannot be parsed.
 * @private
 */
function _resolveBasicMathExpr(expr, resolved, availVars, isSubExpr) {
  /*
     * Evolution of `expr` within this method:
     *
     *      a + b * c
     *      a + {{@1}}
     *      {{@2}}
     */

  expr = _resolveBasicMathOperation(REGEX_BASIC_MATH_1, expr, resolved, availVars, isSubExpr);
  expr = _resolveBasicMathOperation(REGEX_BASIC_MATH_2, expr, resolved, availVars, isSubExpr);

  // Look at what's remaining, should be a standalone value: "{{@4}}", "a" or "2.5"
  const trimmed = expr.trim();

  if (REGEX_FLOAT.test(trimmed) // Don't need to resolve.
        || REGEX_MATH_RESOLVED.test(trimmed)) // Already resolved.
  { return trimmed; }

  if (REGEX_VAR_NAME_ANY.test(trimmed)) return _getAvailVar(trimmed, availVars, BASIC_MATH_TYPES).name();

  throw new ParseError(`Unrecognized expression: ${ _cleanCode(_restoreUserExpr(expr, resolved))}`);
}

/**
 * Parses an expression, converts it to a BasicMath object.
 * @param {string} expr - Expression to parse.
 * @param {ScriptVar[]} availVars
 * @returns {string}
 * @throws {MpFormulaStep.ParseError} If `expr` cannot be parsed.
 * @private
 */
function _mathExprToJsCode(expr, availVars) {
  const m = new RegExp(PATT_RESOLVED_MATH).exec(expr);
  if (m !== null) { throw new ParseError(`Expression not allowed: ${ m[0]}`); }

  /*
     * Evolution of `expr` within this method:
     *
     *    ( ( a + b + c ) / d ) * 1.1
     *       (     {{@2}}    / d ) * 1.1
     *               {{@3}}        * 1.1
     *                  {{@4}}
     */

  const resolved = [];
  let endIdx = expr.indexOf(')');

  while (endIdx >= 0) {
    const startIdx = expr.substring(0, endIdx).lastIndexOf('(');
    if (startIdx < 0) { throw new ParseError("Unmatched ')' found."); }

    const subExpr = expr.substring(startIdx + 1, endIdx);

    expr = expr.substring(0, startIdx)
               + _resolveBasicMathExpr(subExpr, resolved, availVars, true)
               + expr.substring(endIdx + 1);
    endIdx = expr.indexOf(')');
  }

  if (expr.indexOf('(') >= 0) { throw new ParseError("Unmatched '(' found."); }

  expr = _resolveBasicMathExpr(expr, resolved, availVars, false).trim();

  const bm = _getBasicMath(expr, resolved, null);

  return ((bm !== null) ? bm.jsCode() : expr);
}

/**
 * Returns whether the given input is a number,
 * or a BasicMath object which is the result of
 * numbers only.
 * @param {(ScriptVar|BasicMath|number)} inp
 * @returns {boolean}
 * @private
 */
function _isNumericInput(inp) {
  return (isNumber(inp)
            || ((inp instanceof BasicMath)
                && inp.isPlainNumber())
            || ((inp instanceof ScriptVar)
                && (inp.dataType() === VarDataType.FLOAT
                    || inp.dataType() === VarDataType.INTEGER)));
}

/**
 * A basic math operation.
 * A.k.a addition, subtraction, multiplication, division.
 * @class
 * @private
 */
class BasicMath {
  /**
     * @param {(Operator|string)} operator
     * @param {(ScriptVar|BasicMath|number)} input1
     * @param {(ScriptVar|BasicMath|number)} input2
     * @param {string} userExpr - User expression.
     * @throws {MpFormulaStep.ParseError} If the combination of arguments
     *                     is not supported.
     * @constructor
     */
  constructor(operator, input1, input2, userExpr) {
    /** @type {Operator} */
    this._oper = Operator.valueOf(operator);

    /** @type {(ScriptVar|BasicMath|number)} */
    this._in1 = _validMathInput(input1, 'input1');

    /** @type {(ScriptVar|BasicMath|number)} */
    this._in2 = _validMathInput(input2, 'input2');

    /** @type {string} */
    this._ue = requireNonEmptyString(userExpr, 'userExpr');

    Object.freeze(this);
  }

  /**
     * Generates and returns the JavaScript code to be inserted into
     * Marketplace's JS formula.  The returned code contains no comment
     * and minimum line-breaks to enable the nesting of BasicMath objects.
     */
  jsCode() {
    const oper = this._oper;
    const in1 = this._in1;
    const in2 = this._in2;
    const s1 = _inputToString(in1);
    const s2 = _inputToString(in2);

    if (_isNumericInput(in1)
            && _isNumericInput(in2)) return `${s1 } ${ oper._rep } ${ s2}`;
    return `${oper._jsFn }(${ s1 }, ${ s2 })`;
  }

  /**
     * Returns whether this basic operation uses two numeric
     * values as its input (true) or not (false).
     * @returns {boolean}
     */
  isPlainNumber() {
    return (_isNumericInput(this._in1)
                && _isNumericInput(this._in2));
  }
}
Object.freeze(BasicMath);

/* ******************************************************
 * Class: CalendarConstructor
 * ****************************************************** */
/**
 * CalendarConstructor enum class.
 * @class
 * @private
 */
class CalendarConstructor {
  /**
     * @param {string} name - Name of the enum item.
     * @param {string} codeTemplate - JavaScript engine code template for creating a calendar.
     * @constructor
     */
  constructor(name, codeTemplate) {
    requireNonEmptyString(name, 'name');
    requireNonEmptyString(codeTemplate, 'codeTemplate');

    if (codeTemplate.indexOf('${START}') < 0
            || codeTemplate.indexOf('${END}') < 0) { throw new Error('IllegalArgumentException: missing placeholder ${START} and/or ${END}.'); }

    this._name = name;
    this._codeTemplate = codeTemplate;
  }

    static YEAR = new CalendarConstructor('year', 'Calendar.annual(${START}, ${END})');

    static QUARTER = new CalendarConstructor('quarter', 'Calendar.quarterly(${START}, ${END})');

    static MONTH = new CalendarConstructor('month', 'Calendar.monthly(${START}, ${END})');

    static DAY = new CalendarConstructor('day', 'Calendar.daily(${START}, ${END})');

    static WEEKDAY = new CalendarConstructor('weekday', 'Calendar.weekdays(${START}, ${END})');

    static HOUR = new CalendarConstructor('hour', 'Calendar.interval(${START}, ${END}, IDate.MILLIS_PER_HOUR)');

    /**
     * Returns the value of this CalendarConstructor enum item (aka its name).
     * @returns {string}
     */
    valueOf() { return this._name; }

    /**
     * Returns the string representation of this CalendarConstructor enum item
     * (aka its name, upper-cased.)
     * @returns {string}
     */
    toString() { return this._name.toUpperCase(); }

    /**
     * Generates the JS code that'll create this type of calendar.
     * @param {string} start - Start date, as JS code to be injected in JS script.
     *                         Litteral strings should be wrapped inside single-quotes ('')
     *                         or double-quotes ("").
     * @param {string} end - Enddate, as JS code to be injected in JS script.
     *                       Litteral strings should be wrapped inside single-quotes ('')
     *                       or double-quotes ("").
     * @returns {string} JavaScript code to be injected in JS script.
     */
    jsCode(start, end) {
      return this._codeTemplate.replace('${START}', requireNonEmptyString(start, 'start'))
        .replace('${END}', requireNonEmptyString(end, 'end'));
    }
}

Enums.finalize(CalendarConstructor, 'CalendarConstructor');


/* ******************************************************
 * Class: ExtrapolationMethod
 * ****************************************************** */

/**
 * Builds the UI controls for extrapolation method FOLLOW_DATASET.
 * @param {jQuery} tableElm
 * @param {ScriptVar} availVars
 * @returns {{other: jQuery, extendTo: jQuery[], fixedDate: jQuery, toRemove: jQuery[]}}
 *                   An object containing pointers to UI controls,
 *                   to be used by other methods of FOLLOW_DATASET.
 * @private
 */
function _buildUiExtrapMeth_follow_dataset(tableElm, availVars) {
  const TextFD = Text.Type.Extrapolation.FollowDataset;
  const other = _newVarSelect(_newTableRow(tableElm, TextFD.otherTimeSeries),
    [VarDataType.TIME_SERIES],
    availVars);
  const tdExtTo = _newTableRow(tableElm, TextFD.extendTo);
  const rsLenOfDs = _newRadioBtn(tdExtTo, 'extend_to', 'length_of_dataset', TextFD.datasetLength);
  const inFixedLen = _newIntInput(1);
  const rsFixedLen = _newRadioBtn(tdExtTo, 'extend_to', FIXED_LEN, TextFD.fixedLength, inFixedLen);
  const inFixedDate = _newDateTextInput();
  const rsFixedDate = _newRadioBtn(tdExtTo, 'extend_to', FIXED_DATE, TextFD.fixedDate, inFixedDate);
  const ctrls = Object.freeze({
    other,
    extendTo: Object.freeze([rsLenOfDs, rsFixedLen, rsFixedDate]),
    fixedLen: inFixedLen,
    fixedDate: inFixedDate,
    toRemove: [_findTr(other), _findTr(tdExtTo)],
  });

  rsLenOfDs.prop('checked', true)
    .add(rsFixedLen)
    .add(rsFixedDate)
    .on('click', null, ctrls, _extrapolateFollowDatasetExtToClick);

  _extrapolateFollowDatasetExtToClickApply(ctrls);

  return ctrls;
}

/**
 * Handler for *click* event of Extend To radio-set.
 * @param {(JQuery.Event|MouseEvent)} event
 * @private
 */
function _extrapolateFollowDatasetExtToClick(event) {
  _extrapolateFollowDatasetExtToClickApply(event.data).focus();
}

/**
 * Applies change to Extend To radio-set of FOLLOW_DATASET UI.
 * @param {{other: jQuery, extendTo: jQuery[], fixedDate: jQuery, toRemove: jQuery[]}} ctrls
 * @returns {jQuery} Element to which focus should/can be applied to.
 * @private
 */
function _extrapolateFollowDatasetExtToClickApply(ctrls) {
  const extTo = UI.getRadioValue(ctrls.extendTo);
  const { fixedLen } = ctrls;
  const { fixedDate } = ctrls;
  const focusElm = ((extTo === FIXED_LEN) ? fixedLen
    : ((extTo === FIXED_DATE) ? fixedDate
      : $()));

  fixedLen.isSensitive(focusElm === fixedLen);
  fixedDate.isSensitive(focusElm === fixedDate);

  return focusElm;
}

/**
 * Removes all elements listed in `ctrls.toRemove`.
 * @param {{toRemove: jQuery[]}} ctrls
 * @private
 */
function _removeUiExtrapMethCtrls(ctrls) {
  _.invoke(ctrls.toRemove, 'remove');
}

/**
 * Validates the UI controls created by `_buildUiExtrapMeth_follow_dataset()`.
 * @param {Object} ctrls
 * @param {lim.Window} win
 * @param {ScriptVar[]} availVars
 * @returns {boolean} Whether all controls are in a valid state.
 * @private
 */
function _validateUiExtrapMeth_follow_dataset(ctrls, win, availVars) {
  const TextVal = Text.Type.Extrapolation.FollowDataset.Validation;
  const myCtrls = ctrls.methCtrls;

  if (!Strings.isNonEmpty(myCtrls.other.val())) {
    win.warn(TextVal.noOther);
    return false;
  }

  if (ctrls.timeSeriesVar.val() === myCtrls.other.val()) {
    win.warn(TextVal.mustDiffer);
    return false;
  }

  const extTo = UI.getRadioValue(myCtrls.extendTo);
  switch (extTo) {
    case FIXED_LEN:
      if (!_validateIntInput(myCtrls.fixedLen, win)) { return false; }
      break;

    case FIXED_DATE:
      if (!_validateDateInput(myCtrls.fixedDate, win)) { return false; }
      break;
  }

  return true;
}

/**
 * Generates the metadata necessary to repopulate the UI controls.
 * @param {{other: jQuery, extendTo: jQuery[], fixedDate: jQuery, toRemove: jQuery[]}} ctrls
 * @returns {{ts_other: string, extend_to: string, fixed_length: string, fixed_date: string}}
 * @private
 */
function _genMdExtraMeth_follow_dataset(ctrls) {
  const extTo = ctrls.extendTo;

  return {
    ts_other: ctrls.other[0],
    extend_to: extTo,
    fixed_length: ((extTo === FIXED_LEN) ? ctrls.fixedLen : ''),
    fixed_date: ((extTo === FIXED_DATE) ? _getDateTextInput(ctrls.fixedDate) : ''),
  };
}

/**
 * Generates the JavaScript function to extrapolate a
 * time-series following another one.  This is a template
 * function; it requires no inputs.
 * @private
 */
function _genFnExtraMeth_follow_dataset() {
  return [
    'function (actual, model) {',
    '',
    '    var isColMatch = (actual.size() === model.size());',
    '',
    '    // Create chg% from `model`',
    '    var cp = change_percent(model, 1);',
    '',
    '    // Keep dates unique to `model` (not in `actual`)',
    '    cp = when_date_in_none_other(cp, actual);',
    '    cp = when_date_is_after(cp, actual.dateAt(-1));',
    '',
    '    // Create a calendar',
    '    var cal = new Calendar(cp.dates());',
    '',
    '    return extrapolate(actual, cal, function (date, val, rowIndex, colIndex) {',
    '        if (rowIndex >= 0)',
    '            return (  this.valueAt(rowIndex - 1, colIndex)                 // previous `actual` value',
    '                    * (1 + (cp.valueAsOf(date, ((isColMatch) ? colIndex : 0)) / 100) ) ); // multiply by chg%',
    '    });',
    '}',
  ].join(EOL);
}

/**
 * Generates the JavaScript code necessary to extrapolate
 * a given dataset using the chosen (other) dataset.
 * @param {Object} ctrls - UI controls of EXTRAPOLATION.
 * @param {string} varName
 * @param {ScriptVar[]} availVars
 * @returns {string}
 * @private
 */
function _genCodeExtraMeth_follow_dataset(ctrls, varName, availVars) {
  const tsVar = ctrls.timeSeriesVar[0];
  const { methCtrls } = ctrls;
  const otherVar = methCtrls[0].other[0];
  const { extendTo } = methCtrls[0];

  let code = `var ${ varName } = (${
    _genFnExtraMeth_follow_dataset()
  })(${ tsVar }, ${ otherVar })`;

  if (extendTo === FIXED_LEN) {
    code += `.slice(0, ${ methCtrls[0].fixedLen })`;
  } else if (extendTo === FIXED_DATE) {
    code += `.whenDateBeforeOrEqual("${ _getDateTextInput(methCtrls[0].fixedDate) }")`;
  }

  return `${code };`;
}

/**
 * Sets the control to match the given Spike check.
 * @param {{ts_other: string, extend_to: string, fixed_length: string, fixed_date: string}} md - A formula step's metadata.
 * @param {{other: jQuery, extendTo: jQuery[], fixedDate: jQuery, toRemove: jQuery[]}} ctrls
 * @private
 */
function _displayUiExtraMeth_follow_dataset(md, ctrls) {
  _setPossiblyGoneOption(ctrls.other, md.ts_other);
  UI.setRadioValue(ctrls.extendTo, md.extend_to);
  ctrls.fixedLen.val(md.fixed_length);
  _setDateTextInput(ctrls.fixedDate, md.fixed_date);

  _extrapolateFollowDatasetExtToClickApply(ctrls);
}

/**
 * Builds the UI controls for extrapolation method REPEAT_LAST_CYCLE.
 * @param {jQuery} tableElm
 * @param {ScriptVar} availVars
 * @returns {{repeats: jQuery, delivery: jQuery, stopDate: jQuery, toRemove: jQuery[]}}
 *                   An object containing pointers to UI controls,
 *                   to be used by other methods of REPEAT_LAST_CYCLE.
 * @private
 */
function _buildUiExtrapMeth_repeat_last_cycle(tableElm, availVars) {
  const TextRLC = Text.Type.Extrapolation.RepeatLastCycle;
  const tdRepeats = _newTableRow(tableElm, TextRLC.numRepeat);
  const repeats = _newIntInput(1).appendTo(tdRepeats);

  $('<span>').appendTo(tdRepeats).text(TextRLC.numRepeatSuffix);

  const deliv = _newEnumSelect([
    CalendarConstructor.HOUR,
    CalendarConstructor.DAY,
    CalendarConstructor.MONTH,
    CalendarConstructor.QUARTER,
    CalendarConstructor.YEAR,
  ],
  TextRLC.Calendar,
  CalendarConstructor.MONTH);
  deliv.appendTo(_newTableRow(tableElm, TextRLC.delivery));

  const stopDate = _newDateTextInput().appendTo(_newTableRow(tableElm, TextRLC.stopDate));

  return Object.freeze({
    repeats,
    delivery: deliv,
    stopDate,
    toRemove: [
      _findTr(repeats),
      _findTr(deliv),
      _findTr(stopDate),
    ],
  });
}

/**
 * Validates the UI controls created by `_buildUiExtrapMeth_repeat_last_cycle()`.
 * @param {Object} ctrls
 * @param {lim.Window} win
 * @param {ScriptVar[]} availVars
 * @returns {boolean} Whether all controls are in a valid state.
 * @private
 */
function _validateUiExtrapMeth_repeat_last_cycle(ctrls, win, availVars) {
  const { methCtrls } = ctrls;

  if (!_validateIntInput(methCtrls.repeats, win)) {
    return false;
  }
  if (!_validateDateInput(methCtrls.stopDate, win)) {
    return false;
  }
  return true;
}

/**
 * Returns JS code that declares an anonymous function for extrapolating
 * a curve by repeating the last *X* number of data-points.
 * @private
 */
function _genFnExtraMeth_repeat_last_cycle() {
  return [
    'function (base, cal, size) {',
    '',
    '    return extrapolate(base, cal, function (date, val, rowIndex, colIndex) {',
    '',
    '        var idx = rowIndex - size;',
    '        if (idx >= 0)',
    '            return this.valueAt(idx, colIndex);',
    '',
    '    });',
    '',
    '}',
  ].join(EOL);
}

/**
 * @param {Object} ctrls - UI controls of EXTRAPOLATION.
 * @param {string} varName
 * @param {ScriptVar[]} availVars
 * @returns {string} JS code necessary to extrapolate a given dataset using the chosen (other) dataset.
 * @private
 */
function _genCodeExtraMeth_repeat_last_cycle(ctrls, varName, availVars) {
  const { methCtrls } = ctrls;
  const tsVar = ctrls.timeSeriesVar[0];
  const cal = CalendarConstructor.valueOf(methCtrls[1].delivery[0]);
  const calCode = cal.jsCode(`${tsVar }.dateAt(-1)`, `'${ _getDateTextInput(methCtrls[1].stopDate) }'`);

  return `var ${ varName } = (${ _genFnExtraMeth_repeat_last_cycle()
  })(${ tsVar }, ${ calCode }, ${ methCtrls[1].repeats });`;
}

/**
 * Generates the metadata necessary to repopulate the UI controls.
 * @param {{repeats: jQuery, delivery: jQuery, stopDate: jQuery, toRemove: jQuery[]}} ctrls
 * @returns {{size: int, delivery: string, stop_date: string}}
 * @private
 */
function _genMdExtraMeth_repeat_last_cycle(ctrls) {
  return {
    size: ctrls.repeats,
    delivery: ctrls.delivery[0],
    stop_date: _getDateTextInput(ctrls.stopDate),
  };
}

/**
 * Sets the control to match the given Spike check.
 * @param {{size: int, delivery: string, stop_date: string}} md - A formula step's metadata.
 * @param {{repeats: jQuery, delivery: jQuery, stopDate: jQuery, toRemove: jQuery[]}} ctrls
 * @private
 */
function _displayUiExtraMeth_repeat_last_cycle(md, ctrls) {
  ctrls.repeats.val(md.size);
  ctrls.delivery.val(md.delivery);
  _setDateTextInput(ctrls.stopDate, md.stop_date);
}

/**
 * An enum class to help avoid too many IF statements when dealing
 * with different extrapolation methodologies.
 * @class
 * @private
 */
class ExtrapolationMethod {
  /**
     * @param {string} value
     * @param {function} buildUi
     * @param {function} removeUi
     * @param {function} validateUi
     * @param {function} genCode
     * @param {function} genMD
     * @param {function} display
     * @constructor
     */
  constructor(value, buildUi, removeUi, validateUi, genCode, genMD, display) {
    this._val = value;
    this._buildUi = buildUi;
    this._removeUi = removeUi;
    this._validateUi = validateUi;
    this._genCode = genCode;
    this._genMD = genMD;
    this._display = display;

    Object.freeze(this);
  }

  /**
     * Returns the value of this Extrapolation method.
     * @returns {string}
     */
  valueOf() { return this._val; }

  /**
     * Returns the string representation of this Extrapolation method
     * (aka upper-case value.)
     * @returns {string}
     */
  toString() { return this._val.toUpperCase(); }

    static FOLLOW_DATASET = new ExtrapolationMethod(
      'follow_dataset',
      _buildUiExtrapMeth_follow_dataset,
      _removeUiExtrapMethCtrls,
      _validateUiExtrapMeth_follow_dataset,
      _genCodeExtraMeth_follow_dataset,
      _genMdExtraMeth_follow_dataset,
      _displayUiExtraMeth_follow_dataset,
    );

    static REPEAT_LAST_CYCLE = new ExtrapolationMethod(
      'repeat_last_cycle',
      _buildUiExtrapMeth_repeat_last_cycle,
      _removeUiExtrapMethCtrls,
      _validateUiExtrapMeth_repeat_last_cycle,
      _genCodeExtraMeth_repeat_last_cycle,
      _genMdExtraMeth_repeat_last_cycle,
      _displayUiExtraMeth_repeat_last_cycle,
    );
}

Enums.finalize(ExtrapolationMethod, 'ExtrapolationMethod');


/* ******************************************************
 * Private methods - FORWARD_CURVE
 * ****************************************************** */

/**
 * Validates a name given to a step, based on type.
 * Data functions require a valid variable name, while
 * QA functions don't.
 *
 * @param {string} name - Name given to this step.
 * @param {MpFormulaStepType} stepType - Type of step.
 * @param {string} argName - Name of the argument/variable being validated.
 * @returns {string} `name`, if valid.
 * @throws TypeError - If `name` is not a String.
 * @throws IllegalArgumentException - If `name` is empty and `stepType` is a data function.
 * @private
 */
function _validName(name, stepType, argName) {
  if (typeof name !== 'string') { throw new TypeError(`${argName }: String`); }

  if (stepType._isData
        && !Strings.isNonEmpty(name)) { throw new Error('IllegalArgumentException: variable name is required for data steps.'); }

  return name;
}

/**
 * Builds the UI controls necessary to create a forward curve step.
 * @param {jQuery} tableElm - JQuery wrapper to HTMLTableBodyElement.
 * @param {ScriptVar[]} availVars
 * @returns {Object} UI controls, to be referenced later by other
 *          methods of the FORWARD_CURVE step type.
 * @private
 */
function _buildUiFwdCurve(tableElm, availVars) {
  const TextFC = Text.Type.ForwardCurve;

  const selProduct = _newVarSelect(_newTableRow(tableElm, TextFC.product),
    [VarDataType.PRODUCT],
    availVars);
  const selDeliv = $('<select>').appendTo(_newTableRow(tableElm, TextFC.delivery));
  const tdType = _newTableRow(tableElm, TextFC.curveType).addClass('type');
  const typeArb = _newRadioBtn(tdType, 'curve_type', 'forward_curve', TextFC.arbitrage);
  const typeArbFree = _newRadioBtn(tdType, 'curve_type', 'forward_curve_arbitrage_free', TextFC.arbitrageFree);
  const typeArbFreeBasic = _newCheckbox(tdType, '_basic', TextFC.arbitrageFreeBasic);
  const showNaNs = _newCheckbox(tdType, 'showNaNs', TextFC.showNans);
  const tdDate = _newTableRow(tableElm, TextFC.curveDate).addClass('date');
  const curveDate = _buildDateUI(tdDate, 'curve_date');

  const delivTypes = ['year', 'season', 'quarter', 'month', 'week', 'day', 'hour', '30-minute'];
  delivTypes.forEach((delivType) => {
    _addSelOption(selDeliv, delivType, _safeLang(TextFC.DeliveryType, delivType));
  });

  selDeliv.val('month'); // default delivery: monthly curve
  typeArb.prop('checked', true); // default curve type: plain/arbitrage
  _setDateUI(curveDate, '-0d');

  const ctrls = Object.freeze({

    product: selProduct,
    delivery: selDeliv,

    curveType: Object.freeze({
      arb: typeArb,
      arbFree: typeArbFree,
      arbFreeBasic: typeArbFreeBasic,

      radioset: [typeArb, typeArbFree],
    }),

    showNaNs,

    curveDate,
  });

  _fwdCurveTypeClickApply(ctrls);

  typeArb.on('click', null, ctrls, _fwdCurveTypeClick);
  typeArbFree.on('click', null, ctrls, _fwdCurveTypeClick);

  return ctrls;
}

/**
 * Validates the controls of a forward curve.
 * @param {Object} ctrls
 * @param {lim.Window} win
 * @param {ScriptVar[]} availVars
 * @returns {boolean} Whether all controls are in a valid state.
 * @private
 */
function _validateFwdCurve(ctrls, win, availVars) {
  const TextVal = Text.Type.ForwardCurve.Validation;

  if (!Strings.isNonEmpty(ctrls.product.val())) {
    win.warn(TextVal.noProduct);
    return false;
  }

  if (!_isValidDateUI(ctrls.curveDate, win)) {
    return false;
  }

  return true;
}

/**
 * Generates the code necessary to create a forward curve.
 * @param {Object} ctrls
 * @param {string} varName
 * @param {ScriptVar[]} availVars
 * @returns {string}
 * @private
 */
function _genCodeFwdCurve(ctrls, varName, availVars) {
  const { curveType } = ctrls;
  let curveFnName = UI.getRadioValue(curveType.radioset);
  const dateCode = _getDateValue(ctrls.curveDate);
  const productVal = ctrls.product.val();
  const deliveryVal = ctrls.delivery.val();
  const showNans = _isChecked(ctrls.showNaNs);

  if (curveType.arbFreeBasic.isSensitive()
        && _isChecked(curveType.arbFreeBasic)) { curveFnName += curveType.arbFreeBasic.val(); }

  return _genCodeFwdCurveFromVal(varName, curveFnName,
    productVal, dateCode, deliveryVal, showNans);
}

/**
 * Generates the code necessary to create a forward curve from the values.
 * @param {string} varName
 * @param {string} curveFnName
 * @param {string} productVal
 * @param {string} curveDate
 * @param {string} deliveryVal
 * @param {boolean} [showNaNs=false]
 * @returns {string}
 * @private
 */
function _genCodeFwdCurveFromVal(varName,
  curveFnName,
  productVal,
  curveDate,
  deliveryVal,
  showNaNs) {
  const dateCode = _getDateCode(curveDate);
  let code = `var ${ varName } = ${ curveFnName }(${
    productVal }, ${
    dateCode }, `
        + `"${ deliveryVal }");${ EOL}`;

  if (!showNaNs) { code += `${varName } = drop_nans(${ varName });${ EOL}`; }

  return Strings.assembleAutoGenJS({}, code);
}

/**
 * Sets the control to match the given forward curve.
 * @param {MpFormulaStep} step
 * @param {Object} ctrls
 * @private
 */
function _displayFwdCurve(step, ctrls) {
  const code = step.codeOnly();
  const regex = new RegExp(`^\\s*var ${ PATT_VAR_NAME_USER_DEF } = (forward_curve(?:_arbitrage_free)?)(_basic)?\\(` // [1], [2]
                            + `(${ PATT_VAR_NAME_BUILT_IN }), ` // product [3]
                            + '(.*?), ' // curve_date [4]
                            + '"([A-Za-z0-9\-]+)"' // delivery [5]
                            + '\\);\\s*'
                            + `(${ PATT_VAR_NAME_USER_DEF } = drop_nans\\(${ PATT_VAR_NAME_USER_DEF }\\);\\s*)?`); // drop nans [6]
  const match = regex.exec(code);

  if (match === null) { throw new Error('IllegalStateException: unable to parse unrecognized FORWARD_CURVE code snipet.'); }

  const { curveType } = ctrls;
  const product = match[3];
  const dateCode = match[4];

  _setPossiblyGoneOption(ctrls.product, product);
  ctrls.delivery.val(match[5]);

  UI.setRadioValue(curveType.radioset, match[1]);
  _setChecked(curveType.arbFreeBasic, (match[2] === curveType.arbFreeBasic.val()));
  _setChecked(ctrls.showNaNs, !Strings.isNonEmpty(match[6])); // BEWARE: show NaNs vs. drop NaNs
  _fwdCurveTypeClickApply(ctrls);

  _setDateUI(ctrls.curveDate, _getDateValueFromCode(dateCode));
}

/**
 * Event handler for *click* event on curve-type radio-set.
 * @param {(JQuery.Event|MouseEvent)} event
 * @private
 */
function _fwdCurveTypeClick(event) {
  _fwdCurveTypeClickApply(sanitize(event.data));
}

/**
 * Handles changes to curve-type radio-set.
 * @param {Object} ctrls
 * @private
 */
function _fwdCurveTypeClickApply(ctrls) {
  const { curveType } = ctrls;
  _isSensitive(curveType.arbFreeBasic, _isChecked(curveType.arbFree));
}

/* ******************************************************
 * Private methods - BASIC_MATH
 * ****************************************************** */

/**
 * Builds the UI controls necessary to create a basic computation step.
 * @param {jQuery} tableElm - JQuery wrapper to HTMLTableBodyElement.
 * @returns {Object} UI controls, to be referenced later by other
 *          methods of the FORWARD_CURVE step type.
 * @private
 */
function _buildUiBasicMath(tableElm) {
  const TextBC = Text.Type.BasicMath;

  const editor = $('<textarea>').appendTo(_newTableRow(tableElm, TextBC.math));

  lim.CodeWindow.autoIndent(editor);

  return {
    editor,
  };
}

/**
 * Validates the controls of a basic computation.
 * @param {Object} ctrls
 * @param {lim.Window} win
 * @param {ScriptVar[]} availVars
 * @returns {boolean} Whether all controls are in a valid state.
 * @private
 */
function _validateBasicMath(ctrls, win, availVars) {
  const TextVal = Text.Type.BasicMath.Validation;

  const code = ctrls.editor.val();

  if (Strings.isEmpty(code)) {
    win.warn(TextVal.isEmpty);
    return false;
  }

  try {
    _mathExprToJsCode(code, availVars);
  } catch (ex) {
    if (ex instanceof ParseError) {
      win.warn(ex.getMessage());
      return false;
    }

    win.error(TextVal.unexpectedError);
    throw ex;
  }

  return true;
}

/**
 * Generates the code necessary to create a variable that contains
 * the result of the mathematical formula.
 * @param {Object} ctrls
 * @param {string} varName
 * @param {ScriptVar[]} availVars
 * @returns {string}
 * @private
 */
function _genCodeBasicMath(ctrls, varName, availVars) {
  const mathExpr = ctrls.editor.val();
  const mathCode = _mathExprToJsCode(mathExpr, availVars);
  const code = `var ${ varName } = ${ mathCode };`;

  return Strings.assembleAutoGenJS({
    expr: mathExpr.replace(new RegExp('[\r\n|\n\r|\n|\r]', 'g'), '\\n'),
  }, code);
}

/**
 * Sets the control to match the given math expression.
 * @param {MpFormulaStep} step
 * @param {Object} ctrls
 * @private
 */
function _displayBasicMath(step, ctrls) {
  const code = step.scriptSnippet();
  const parsed = Strings.parseAutoGenJS(code);
  const origExpr = parsed.metadata.expr.replace('\\n', EOL);

  ctrls.editor.val(origExpr);
}


/* ******************************************************
 * Private methods - FREE_TEXT
 * ****************************************************** */

/**
 * Builds the UI controls necessary to create a free  text step.
 * @param {jQuery} tableElm - JQuery wrapper to HTMLTableBodyElement.
 * @returns {Object} UI controls, to be referenced later by other
 *          methods of the FORWARD_CURVE step type.
 * @private
 */
function _buildUiFreeText(tableElm) {
  const TextBC = Text.Type.FreeText;

  const editor = $('<textarea>').appendTo(_newTableRow(tableElm, TextBC.text));

  lim.CodeWindow.autoIndent(editor);

  return {
    editor,
  };
}

/**
 * Validates the controls of a Free Text.
 * @param {Object} ctrls
 * @param {lim.Window} win
 * @param {ScriptVar[]} availVars
 * @returns {boolean} Whether all controls are in a valid state.
 * @private
 */
function _validateFreeText(ctrls, win, availVars) {
  const TextVal = Text.Type.FreeText.Validation;
  const code = ctrls.editor.val();
  if (Strings.isEmpty(code)) {
    win.warn(TextVal.isEmpty);
    return false;
  }
  return true;
}

/**
 * Generates the code necessary to create a variable that contains
 * the result of the Free Text.
 * @param {Object} ctrls
 * @param {string} varName
 * @param {ScriptVar[]} availVars
 * @returns {string}
 * @private
 */
function _genCodeFreeText(ctrls, varName, availVars) {
  const freeText = ctrls.editor.val().replace(new RegExp(/[\n]+/gm), '');
  let code = `var ${ varName } = ${ freeText}`;
  if (freeText.charAt(freeText.length - 1) !== ';') {
    code += ';';
  }

  return Strings.assembleAutoGenJS({ }, code);
}

/**
 * Sets the control to match the given Free Text.
 * @param {MpFormulaStep} step
 * @param {Object} ctrls
 * @private
 */
function _displayFreeText(step, ctrls) {
  const code = step.codeOnly();
  const parsed = code.split('=')[1];
  let parsedExpr = '';
  if (parsed) {
    parsedExpr = parsed.replace(' ', '');
  }
  ctrls.editor.val(parsedExpr);
}

/* ******************************************************
 * Private methods - MISSING_DATA_QA
 * ****************************************************** */

/**
 * Builds the UI controls necessary to create a Missing Data check.
 * @param {jQuery} tableElm - JQuery wrapper to HTMLTableBodyElement.
 * @param {ScriptVar[]} availVars
 * @returns {Object} UI controls, to be referenced later by other
 *          methods of the MISSING_DATA_QA step type.
 * @private
 */
function _buildUiMissingData(tableElm, availVars) {
  const TextMD = Text.Type.MissingData;

  const tsVar = _newVarSelect(_newTableRow(tableElm, TextMD.selectTimeSeries),
    [VarDataType.TIME_SERIES],
    availVars);
  const minCnt = _newInputEmbedded(_newTableRow(tableElm, TextMD.minCount),
    _newIntInput(1),
    TextMD.minCountText);

  return {
    timeSeriesVar: tsVar,
    minCount: minCnt,
    userMessage: _newUserMessage(tableElm),
  };
}

/**
 * Validates the controls of a Missing Data check.
 * @param {Object} ctrls
 * @param {lim.Window} win
 * @param {ScriptVar[]} availVars
 * @returns {boolean} Whether all controls are in a valid state.
 * @private
 */
function _validateMissingData(ctrls, win, availVars) {
  const TextVal = Text.Type.MissingData.Validation;

  if (!Strings.isNonEmpty(ctrls.timeSeriesVar.val())) {
    win.warn(TextVal.noVar);
    return false;
  }

  if (!_validateIntInput(ctrls.minCount, win)) {
    return false;
  }

  return true;
}

/**
 * Generates the code necessary to create a variable that contains
 * the result of a validated time-series variable.
 * @param {Object} ctrls
 * @param {string} varName
 * @param {ScriptVar[]} availVars
 * @returns {string}
 * @private
 */
function _genCodeMissingData(ctrls, varName, availVars) {
  const tsVar = ctrls.timeSeriesVar.val();
  const minCnt = ctrls.minCount.val();
  const usrMsg = ctrls.userMessage.val();
  const errMsg = _genFailMsg('MISSING_DATA', tsVar, varName,
    "lim.String.build('Expected at least {} numeric values, got [{}] instead.', minCnt, counts.join(','))",
    usrMsg);

  // validate all columns.
  const code = [
    '(function (minCnt, counts) {',
    '    if (_.some(counts, function (cnt) { return cnt < minCnt; }))',
    `        Test.fail(${ errMsg });`,
    `})(${ minCnt }, reduce_to_count(${ tsVar }));`,
  ].join(EOL);

  return Strings.assembleAutoGenJS({
    ts_var: tsVar,
    min_count: minCnt,
    user_msg: usrMsg,
  }, code);
}

/**
 * Sets the control to match the given Missing Data check.
 * @param {MpFormulaStep} step
 * @param {Object} ctrls
 * @private
 */
function _displayMissingData(step, ctrls) {
  const parsed = Strings.parseAutoGenJS(step.scriptSnippet());
  const md = parsed.metadata;
  let tsVar; let minCnt; let
    usrMsg;

  if (md.hasOwnProperty('ts_var')) {
    tsVar = md.ts_var;
    minCnt = md.min_count;
    usrMsg = md.user_msg;
  } else {
    // Old way of retrieving the values.

    const regex = new RegExp(`reduce_to_count\\((${ PATT_VAR_NAME_ANY })\\), function \\(cnt\\) \\{ return cnt < (\\d+);`);
    const match = regex.exec(parsed.body);

    if (match === null) { throw new Error('IllegalStateException: unable to parse unrecognized MISSING_DATA_QA code snipet.'); }

    tsVar = match[1];
    minCnt = match[2];
    usrMsg = '';
  }

  _setPossiblyGoneOption(ctrls.timeSeriesVar, tsVar);
  ctrls.minCount.val(minCnt);
  ctrls.userMessage.val(usrMsg);
}


/* ******************************************************
 * Private methods - SPIKE_QA
 * ****************************************************** */

/**
 * Builds the UI controls necessary to create a Spike check.
 * @param {jQuery} tableElm - JQuery wrapper to HTMLTableBodyElement.
 * @param {ScriptVar[]} availVars
 * @returns {Object} UI controls, to be referenced later by other
 *          methods of the SPIKE_QA step type.
 * @private
 */
function _buildUiSpike(tableElm, availVars) {
  const TextS = Text.Type.Spike;
  const tsVar = _newVarSelect(_newTableRow(tableElm, TextS.selectTimeSeries),
    [VarDataType.TIME_SERIES],
    availVars);
  const maxChg = $('<input type="text">').appendTo(_newTableRow(tableElm, TextS.maxChange))
    .addClass('spike-value');
  const checkType = $('<select>').appendTo(_newTableRow(tableElm, TextS.type));

  _addSelOption(checkType, 'side_by_side', _safeLang(TextS, 'sideBySide'));
  _addSelOption(checkType, 'linearity', _safeLang(TextS, 'linearity'));

  const td = _newTableRow(tableElm, TextS.compareWith);
  const compareWithRow = td.parent();
  const compareWith = _newVarSelect(td,
    [VarDataType.TIME_SERIES],
    availVars);


  const ctrls = {
    timeSeriesVar: tsVar,
    maxChange: maxChg,
    checkType,
    compareWith,
    compareWithRow,
    userMessage: _newUserMessage(tableElm),
  };

  checkType.on('change', null, ctrls, _spikeTypeChanged);

  return ctrls;
}

/**
 * Handler for *change* event of spike-type.
 * @param {(JQuery.Event|MouseEvent)} event
 * @private
 */
function _spikeTypeChanged(event) {
  _spikeTypeChangedApply(event.data);
}

/**
 * Apply *change* event of spike-type SELECT element.
 * @param {Object} ctrls
 * @private
 */
function _spikeTypeChangedApply(ctrls) {
  ctrls.compareWithRow.isVisible(ctrls.checkType.val() === 'side_by_side');
}

/**
 * Validates the controls of a Spike check.
 * @param {Object} ctrls
 * @param {lim.Window} win
 * @param {ScriptVar[]} availVars
 * @returns {boolean} Whether all controls are in a valid state.
 * @private
 */
function _validateSpike(ctrls, win, availVars) {
  const TextVal = Text.Type.Spike.Validation;
  const tsVar = ctrls.timeSeriesVar.val();
  const maxChgRegex = new RegExp(`^\\s*${ Numbers.FLOAT_PATTERN }\\s*%?\\s*$`);
  const maxChgStr = ctrls.maxChange.val();

  if (!Strings.isNonEmpty(tsVar)) {
    win.warn(TextVal.noVar);
    return false;
  }

  if (!maxChgRegex.test(maxChgStr)
        || parseFloat(maxChgStr) < 0) {
    ctrls.maxChange.focus();
    win.warn(TextVal.badMaxChg.replace('[value]', maxChgStr));
    return false;
  }

  if (ctrls.checkType.val() === 'side_by_side') {
    const compareWithVar = ctrls.compareWith.val();
    if (!Strings.isNonEmpty(compareWithVar)) {
      win.warn(TextVal.noCompareWith);
      return false;
    }

    if (tsVar === compareWithVar) {
      win.warn(TextVal.sameCompareWith);
      return false;
    }
  }

  return true;
}

/**
 * Returns lines of code which create a function for checking for spikes
 * within a time-series.
 * @param {string} failMsg
 * @param {string} tsShowInErr - JS expression that evaluates to a
 *                 TimeSeries object, to be shown in error message.
 * @returns {string}
 * @private
 */
function _genSpikeFunction(failMsg, tsShowInErr) {
  return [
    'function (tsChg, maxChgNum) {',
    '',
    '    var tooHigh = when_value_greater_than(tsChg, maxChgNum),',
    '        tooLow  = when_value_less_than(tsChg, -maxChgNum);',
    '',
    '    if (   tooHigh.length() > 0',
    '        || tooLow.length() > 0 ) {',
    `        var details = ${ _genDataDetails(tsShowInErr) };`,
    `        Test.fail(${ failMsg });`,
    '    }',
    '}',
  ].join(EOL);
}

/**
 * Generates the code necessary to create a variable that contains
 * the result of a validated time-series variable.
 * @param {Object} ctrls
 * @param {string} varName
 * @param {ScriptVar[]} availVars
 * @returns {string}
 * @private
 */
function _genCodeSpike(ctrls, varName, availVars) {
  /* WARNING: JavaScript writing multi-level closured JavaScript code...
     *
     * I apologize in advance to whoever's reading this code: there's just no
     * easy to make this easier to read.
     *
     * - dgilbert */

  const tsVar = ctrls.timeSeriesVar.val();
  const checkType = ctrls.checkType.val();
  const maxChgStr = ctrls.maxChange.val().trim();
  let maxChgNum = maxChgStr;
  let isPercent = false;
  let tsChg = null;
  let tsShowInErr = null;
  let failMsg = null;
  const usrMsg = ctrls.userMessage.val();
  const md = {
    ts_var: tsVar,
    type: checkType,
    max_chg: maxChgStr,
    user_msg: usrMsg,
  };

  if (Strings.endsWith(maxChgStr, '%')) {
    isPercent = true;
    maxChgNum = maxChgStr.substring(0, maxChgStr.length - 1);
  }

  const ctxMsg = `'\`${ tsVar }\` contains variations of at least +/-${ maxChgStr
  } when compared to {}:\\n{}'`;

  switch (checkType) {
    case 'side_by_side':
      const compareWith = ctrls.compareWith.val();
      md['compare_with'] = compareWith;

      tsChg = `cell_diff(${ tsVar }, ${ compareWith })`;
      tsShowInErr = `when_date_exists_in_one_other(union(${ tsVar }, ${ compareWith }), tooHigh, tooLow)`;

      if (isPercent) { tsChg = `cell_product(cell_quotient(${ tsChg }, ${ compareWith }), 100)`; }

      failMsg = _genFailMsg('SPIKE_SIDE_BY_SIDE',
        tsVar,
        varName,
        `lim.String.build(${ ctxMsg }, '\`${ compareWith }\`', details)`,
        usrMsg);

      break;

    case 'linearity':

      tsChg = `${(isPercent) ? 'change_percent' : 'change' }(${ tsVar }, 1)`;
      tsShowInErr = `when_date_in(${ tsVar }, union(tooHigh, tooLow), 1)`;

      failMsg = _genFailMsg('SPIKE_LINEARITY',
        tsVar,
        varName,
        `lim.String.build(${ ctxMsg }, 'itself (from one date to the next)', details)`,
        usrMsg);
      break;

    default:
      throw new Error(`BadCodeException: unhandled check type (${ checkType })`);
  }

  const code = `(${ _genSpikeFunction(failMsg, tsShowInErr)
  })(${ tsChg }, ${ maxChgNum });`;

  return Strings.assembleAutoGenJS(md, code);
}

/**
 * Sets the control to match the given Spike check.
 * @param {MpFormulaStep} step
 * @param {Object} ctrls
 * @private
 */
function _displaySpike(step, ctrls) {
  const code = Strings.parseAutoGenJS(step.scriptSnippet());
  const md = code.metadata;

  _setPossiblyGoneOption(ctrls.timeSeriesVar, md.ts_var);
  ctrls.maxChange.val(md.max_chg);
  ctrls.checkType.val(md.type);
  ctrls.userMessage.val(_if(md, 'user_msg', ''));

  _spikeTypeChangedApply(ctrls);

  if (md.type === 'side_by_side') { _setPossiblyGoneOption(ctrls.compareWith, md.compare_with); }
}

/* ******************************************************
 * Private methods - IN_RANGE_QA
 * ****************************************************** */

/**
 * Builds the UI controls necessary to create a In-Range check.
 * @param {jQuery} tableElm - JQuery wrapper to HTMLTableBodyElement.
 * @param {ScriptVar[]} availVars
 * @returns {{timeSeriesVar: jQuery, low: jQuery, high: jQuery, userMessage: jQuery}}
 *          UI controls, to be referenced later by other
 *          methods of the IN_RANGE_QA step type.
 * @private
 */
function _buildUiInRange(tableElm, availVars) {
  const TextIR = Text.Type.InRange;
  const tsVar = _newVarSelect(_newTableRow(tableElm, TextIR.selectTimeSeries),
    [VarDataType.TIME_SERIES],
    availVars);
  const low = $('<input type="number">').appendTo(_newTableRow(tableElm, TextIR.low));
  const high = $('<input type="number">').appendTo(_newTableRow(tableElm, TextIR.high));

  return {
    timeSeriesVar: tsVar,
    low,
    high,
    userMessage: _newUserMessage(tableElm),
  };
}

/**
 * Validates the controls of a In-Range check.
 * @param {{timeSeriesVar: jQuery, low: jQuery, high: jQuery, userMessage: jQuery}} ctrls
 * @param {lim.Window} win
 * @param {ScriptVar[]} availVars
 * @returns {boolean} Whether all controls are in a valid state.
 * @private
 */
function _validateInRange(ctrls, win, availVars) {
  const TextVal = Text.Type.InRange.Validation;

  if (!Strings.isNonEmpty(ctrls.timeSeriesVar.val())) {
    win.warn(TextVal.noVar);
    return false;
  }

  if (!_isValidNumElm(ctrls.low, TextVal.badLow, win)
        || !_isValidNumElm(ctrls.high, TextVal.badHigh, win)) { return false; }

  if (parseFloat(ctrls.low.val()) > parseFloat(ctrls.high.val())) {
    win.warn(TextVal.lowIsHigher);
    return false;
  }

  return true;
}

/**
 * Validates an INPUT element expected to contain a numeric value.
 *
 * @param {jQuery} elm
 * @param {string} msg
 * @param {lim.Window} win
 * @returns {boolean} Whether the INPUT element contains a numeric value.
 * @private
 */
function _isValidNumElm(elm, msg, win) {
  if (Numbers.isFloatString(elm.val())) return true;


  elm.focus();
  win.warn(msg);
  return false;
}

/**
 * Generates the code necessary to create a variable that contains
 * the result of a validated time-series variable.
 * @param {{timeSeriesVar: jQuery, low: jQuery, high: jQuery, userMessage: jQuery}} ctrls
 * @param {string} varName
 * @param {ScriptVar[]} availVars
 * @returns {string}
 * @private
 */
function _genCodeInRange(ctrls, varName, availVars) {
  const tsVar = ctrls.timeSeriesVar.val();
  const low = ctrls.low.val();
  const high = ctrls.high.val();
  const usrMsg = ctrls.userMessage.val();
  const ctxMsg = `lim.String.build('\`${ tsVar }\` contains data outside of range [{}, {}]:\\n{}', low, high, details)`;

  const code = [
    '(function(tsVar, low, high) {',
    '',
    '    var tooHigh = when_value_greater_than(tsVar, high),',
    '        tooLow  = when_value_less_than(tsVar, low);',
    '',
    '    if (   tooHigh.length() > 0',
    '        || tooLow.length() > 0 ) {',
    `        var details = ${ _genDataDetails('complement(tooHigh, tooLow)') };`,
    `        Test.fail(${ _genFailMsg('OUT_OF_RANGE', tsVar, varName, ctxMsg, usrMsg) });`,
    '    }',
    '',
    `})(${ tsVar }, ${ low }, ${ high });`,
  ].join(EOL);

  return Strings.assembleAutoGenJS({
    user_msg: usrMsg,
  }, code);
}

/**
 * Sets the UI controls to match the given Spike check.
 * @param {MpFormulaStep} step
 * @param {{timeSeriesVar: jQuery, low: jQuery, high: jQuery, userMessage: jQuery}} ctrls
 * @private
 */
function _displayInRange(step, ctrls) {
  const FLOAT_PATT = Numbers.FLOAT_PATTERN;
  const parsed = Strings.parseAutoGenJS(step.scriptSnippet());
  const md = parsed.metadata;
  const code = parsed.body;
  const regex1 = new RegExp(`\\((${ PATT_VAR_NAME_ANY }),\\s*(${ FLOAT_PATT }),\\s*(${ FLOAT_PATT })\\);$`);
  const match = regex1.exec(code);
  let tsVar; let lowVal; let
    highVal;

  if (match !== null) {
    // New syntax

    tsVar = match[1];
    lowVal = match[2];
    highVal = match[3];
  } else {
    // Old syntax
    const pattSuffix = `\\((${ PATT_VAR_NAME_ANY }), (${ FLOAT_PATT })\\)`;
    const high = (new RegExp(`when_value_greater_than${ pattSuffix}`)).exec(code);
    const low = (new RegExp(`when_value_less_than${ pattSuffix}`)).exec(code);

    if (high === null
            || low === null) { throw new Error('IllegalStateException: unable to parse unrecognized IN_RANGE_QA code snipet.'); }

    tsVar = high[1];
    lowVal = low[2];
    highVal = high[2];
  }

  _setPossiblyGoneOption(ctrls.timeSeriesVar, tsVar);
  ctrls.low.val(lowVal);
  ctrls.high.val(highVal);
  ctrls.userMessage.val(_if(md, 'user_msg', ''));
}

/**
 * Builds the UI controls necessary to create an Extrapolation step.
 * @param {jQuery} tableElm - JQuery wrapper to HTMLTableBodyElement.
 * @param {ScriptVar[]} availVars
 * @returns {{tableElm: jQuery, timeSeriesVar: jQuery, meth: jQuery, lastMeth: (?ExtrapolationMethod), methCtrls: (?Object}}}
 *          UI controls, to be referenced later by other
 *          methods of the EXTRAPOLATION step type.
 * @private
 */
function _buildUiExtrapolate(tableElm, availVars) {
  const TextE = Text.Type.Extrapolation;

  const tsVar = _newVarSelect(_newTableRow(tableElm, TextE.selectTimeSeries),
    [VarDataType.TIME_SERIES],
    availVars);
  const meth = _newEnumSelect([
    ExtrapolationMethod.FOLLOW_DATASET,
    ExtrapolationMethod.REPEAT_LAST_CYCLE,
  ],
  TextE.Method,
  ExtrapolationMethod.FOLLOW_DATASET);

  meth.appendTo(_newTableRow(tableElm, TextE.howTo));

  const ctrls = {
    tableElm,
    availVars,
    timeSeriesVar: tsVar,
    meth,
    lastMeth: null,
    methCtrls: null,
  };

  meth.on('change', null, ctrls, _extrapolateMethChange);
  _extrapolateMethChangeApply(ctrls);

  return ctrls;
}

/**
 * Handler of *change* event of extrapolation method SELECT element.
 * @param {(JQuery.Event|MouseEvent)} event
 * @private
 */
function _extrapolateMethChange(event) {
  _extrapolateMethChangeApply(event.data);
}

/**
 * Applies *change* event to extrapolation method SELECT element.
 * @param {{tableElm: jQuery, timeSeriesVar: jQuery, meth: jQuery, lastMeth: (?ExtrapolationMethod), methCtrls: (?Object)}} ctrls
 * @private
 */
function _extrapolateMethChangeApply(ctrls) {
  if (ctrls.lastMeth !== null) { ctrls.lastMeth._removeUi(ctrls.methCtrls); }

  const meth = ExtrapolationMethod.valueOf(ctrls.meth.val(), null);
  ctrls.lastMeth = meth;
  if (meth !== null) { ctrls.methCtrls = meth._buildUi(ctrls.tableElm, ctrls.availVars); }
}

/**
 * Validates the controls of an Extrapolate step.
 * @param {{tableElm: jQuery, timeSeriesVar: jQuery, meth: jQuery, lastMeth: (?ExtrapolationMethod), methCtrls: (?Object)}} ctrls
 * @param {lim.Window} win
 * @param {ScriptVar[]} availVars
 * @returns {boolean} Whether all controls are in a valid state.
 * @private
 */
function _validateExtrapolate(ctrls, win, availVars) {
  const TextVal = Text.Type.Extrapolation.Validation;

  if (!Strings.isNonEmpty(ctrls.timeSeriesVar.val())) {
    win.warn(TextVal.noVar);
    return false;
  }

  if (!ctrls.lastMeth._validateUi(ctrls, win, availVars)) {
    return false;
  }

  return true;
}

/**
 * Generates the code necessary to create a variable that contains
 * the result of an extrapolated time-series variable.
 * @param {{tableElm: jQuery, timeSeriesVar: jQuery, meth: jQuery, lastMeth: (?ExtrapolationMethod), methCtrls: (?Object)}} ctrls
 * @param {string} varName
 * @param {ScriptVar[]} availVars
 * @returns {string}
 * @private
 */
function _genCodeExtrapolate(ctrls, varName, availVars) {
  const meth = ctrls.lastMeth;
  const md = Object.assign({
    ts_var: ctrls.timeSeriesVar.val(),
    method: meth.valueOf(),
  }, meth._genMD(ctrls.methCtrls));
  const code = meth._genCode(ctrls, varName, availVars);

  return Strings.assembleAutoGenJS(md, code);
}

/**
 * Sets the UI controls to match the given Extrapolate step.
 * @param {MpFormulaStep} step
 * @param {{tableElm: jQuery, timeSeriesVar: jQuery, meth: jQuery, lastMeth: (?ExtrapolationMethod), methCtrls: (?Object)}} ctrls
 * @private
 */
function _displayExtrapolate(step, ctrls) {
  const parsed = Strings.parseAutoGenJS(step.scriptSnippet());
  const md = parsed.metadata;

  _setPossiblyGoneOption(ctrls.timeSeriesVar, md.ts_var);
  ctrls.meth.val(md.method);

  _extrapolateMethChangeApply(ctrls);

  ExtrapolationMethod.valueOf(md.method)._display(md, ctrls.methCtrls);
}

/**
 * Builds the UI controls necessary to create a Merge step.
 * @param {jQuery} tableElm - JQuery wrapper to HTMLTableBodyElement.
 * @param {ScriptVar[]} availVars
 * @returns {{radioset: jQuery[], base: jQuery, others: jQuery[], fnMore: function}}
 *          UI controls, to be referenced later by other
 *          methods of the MERGE step type.
 * @private
 */
function _buildUiMerge(tableElm, availVars) {
  const TextM = Text.Type.Merge;
  const rsName = 'merge_method';
  const dataTypes = [VarDataType.TIME_SERIES];
  const tdMeth = _newTableRow(tableElm, TextM.howTo);

  $('<div>').appendTo(tdMeth).text(TextM.widthWise);
  const rsUnion = _newRadioBtn(tdMeth, rsName, 'union', TextM.union).prop('checked', true);
  const rsIntersect = _newRadioBtn(tdMeth, rsName, 'intersection', TextM.intersection);

  $('<div>').appendTo(tdMeth).text(TextM.lengthWise);
  const rsComplement = _newRadioBtn(tdMeth, rsName, 'complement', TextM.complement);
  const rsOverwrite = _newRadioBtn(tdMeth, rsName, 'overwrite', TextM.overwrite);

  const tdBase = _newTableRow(tableElm, TextM.base);
  const tdNext = _newTableRow(tableElm, TextM.next);
  const base = _newVarSelect(tdBase, dataTypes, availVars);
  const others = [
    _newVarSelect(tdNext, dataTypes, availVars),
  ];
  const btnMore = $('<button type="button">').appendTo(_newTableRow(tableElm, ''))
    .text(TextM.oneMore);
  const lastRow = _findTr(btnMore);

  const fnLess = function (event) {
    const ctx = event.data;

    ctx.row.remove();
    Arrays.remove(ctx.sel, others);
  };

  /**
     * Creates a new row in which a SELECT element is filled with variable names.
     * @returns {jQuery}
     */
  const fnMore = function () {
    const tr = $('<tr>').insertBefore(lastRow);
    const th = $('<th>').appendTo(tr).text(TextM.subsequent);
    const td = $('<td>').appendTo(tr);
    const sel = _newVarSelect(td, dataTypes, availVars);
    const ctx = {
      row: tr,
      sel,
    };

    others.push(sel);

    $('<span>').appendTo(td).text(TextM.oneLess).on('click', null, ctx, fnLess);

    return sel;
  };

  btnMore.on('click', null, null, () => { fnMore(); });

  return Object.freeze({
    radioset: [rsUnion, rsIntersect, rsComplement, rsOverwrite],
    base,
    others,
    fnMore,
  });
}

/**
 * Validates the controls of an Merge step.
 * @param {{radioset: jQuery[], base: jQuery, others: jQuery[], fnMore: function}} ctrls
 * @param {lim.Window} win
 * @param {ScriptVar[]} availVars
 * @returns {boolean} Whether all controls are in a valid state.
 * @private
 */
function _validateMerge(ctrls, win, availVars) {
  if (!Strings.isNonEmpty(ctrls.base.val())) {
    win.warn(Text.Type.Merge.Validation.noCurve);
    return false;
  }
  return true;
}

/**
 * Generates the code necessary to create a variable that contains
 * the result of a merge of time-series variables.
 * @param {{radioset: jQuery[], base: jQuery, others: jQuery[], fnMore: function}} ctrls
 * @param {string} varName
 * @param {ScriptVar[]} availVars
 * @returns {string}
 * @private
 */
function _genCodeMerge(ctrls, varName, availVars) {
  const fnName = UI.getRadioValue(ctrls.radioset);
  const delim = ', ';
  const others = _.map(ctrls.others, select => select.val()).join(delim);

  return `var ${ varName } = ${ fnName }(${ ctrls.base.val() }${delim }${others });`;
}

/**
 * Sets the UI controls to match the given Extrapolate step.
 * @param {MpFormulaStep} step
 * @param {{radioset: jQuery[], base: jQuery, others: jQuery[], fnMore: function}} ctrls
 * @private
 */
function _displayMerge(step, ctrls) {
  const code = step.codeOnly();
  const pattOther = `\\s*,\\s*(${ PATT_VAR_NAME_ANY })`;
  const regexMain = new RegExp(`^var\\s+${ PATT_VAR_NAME_ANY }\\s*=\\s*`
                                + '(union|intersection|complement|overwrite)'
                                + '\\s*\\(\\s*'
                                + `(${ PATT_VAR_NAME_ANY })(?:${ pattOther })+`
                                + '\\s*\\);$');
  let match = regexMain.exec(code);

  if (match === null) { throw new Error('IllegalStateException: unable to parse unrecognized MERGE code snipet.'); }

  UI.setRadioValue(ctrls.radioset, match[1]);
  _setPossiblyGoneOption(ctrls.base, match[2]);

  const regexOther = new RegExp(pattOther, 'g');
  const { others } = ctrls;
  let cnt = 0;
  let selOther = null;

  match = regexOther.exec(code);
  while (match !== null) {
    if (Arrays.isValidIndex(cnt, others)) { selOther = others[cnt]; } else { selOther = ctrls.fnMore(); }

    _setPossiblyGoneOption(selOther, match[1]);

    cnt++;
    match = regexOther.exec(code);
  }
}

/**
 * Builds the UI controls necessary to create a Time-Series step.
 * @param {jQuery} tableElm - JQuery wrapper to HTMLTableBodyElement.
 * @param {ScriptVar[]} availVars
 * @returns {{product: jQuery, backQty: jQuery, backUnit: jQuery, intraday: jQuery, timeZone: jQuery}}
 *          UI controls, to be referenced later by other
 *          methods of the TIME_SERIES step type.
 * @private
 */
function _buildUiTimeSeries(tableElm, availVars) {
  const TextTS = Text.Type.TimeSeries;
  const prod = _newVarSelect(_newTableRow(tableElm, TextTS.product),
    [VarDataType.PRODUCT],
    availVars);
  const tdStart = _newTableRow(tableElm, TextTS.howFarBack);
  const backQty = _newIntInput(0).appendTo(tdStart);
  const backUnit = $('<select>').appendTo(tdStart);

  const intraday = _newCheckbox(_newTableRow(tableElm, ''), 'yes', TextTS.isIntraday);
  const tz = UI.buildTimeZoneSelect().appendTo(_newTableRow(tableElm, TextTS.timeZone));

  ['h', 'd', 'D', 'M', 'y'].forEach((unit) => {
    _addSelOption(backUnit, unit, _safeLang(TextTS.TimeUnit, unit));
  });

  const ctrls = Object.freeze({
    product: prod,
    backQty,
    backUnit,
    intraday,
    timeZone: tz,
  });

  backUnit.val('D') // Default to days.
    .on('change', null, ctrls, _timeSeriesTimeUnitChange);
  intraday.on('click', null, ctrls, _timeSeriesIntradayClick);

  _timeSeriesTimeUnitChangeApply(ctrls);
  _timeSeriesIntradayClickApply(ctrls);

  return ctrls;
}

/**
 * Handler for *change* event on time-unit SELECT element of Time-Series view.
 * @param {(JQuery.Event|MouseEvent)} event
 * @private
 */
function _timeSeriesTimeUnitChange(event) {
  _timeSeriesTimeUnitChangeApply(event.data);
}

/**
 * Apply *change* event to time-unit SELECT element of Time-Series view.
 * @param {{product: jQuery, backQty: jQuery, backUnit: jQuery, intraday: jQuery, timeZone: jQuery}}  ctrls
 * @private
 */
function _timeSeriesTimeUnitChangeApply(ctrls) {
  const isIntraday = (ctrls.backUnit.val() === 'h');

  _setChecked(ctrls.intraday.isSensitive(!isIntraday), isIntraday);
  _timeSeriesIntradayClickApply(ctrls);
}

/**
 * Handler for *click* event on Intraday checkbox of Time-Series view.
 * @param {(JQuery.Event|MouseEvent)} event
 * @private
 */
function _timeSeriesIntradayClick(event) {
  _timeSeriesIntradayClickApply(event.data);
}

/**
 * Apply *click* event to Intraday checkbox of Time-Series view.
 * @param {{product: jQuery, backQty: jQuery, backUnit: jQuery, intraday: jQuery, timeZone: jQuery}} ctrls
 * @private
 */
function _timeSeriesIntradayClickApply(ctrls) {
  ctrls.timeZone.isSensitive(_isChecked(ctrls.intraday));
}

/**
 * Validates the controls of an Time-Series step.
 * @param {{product: jQuery, backQty: jQuery, backUnit: jQuery, intraday: jQuery, timeZone: jQuery}} ctrls
 * @param {lim.Window} win
 * @param {ScriptVar[]} availVars
 * @returns {boolean} Whether all controls are in a valid state.
 * @private
 */
function _validateTimeSeries(ctrls, win, availVars) {
  const TextVal = Text.Type.TimeSeries.Validation;
  if (!Strings.isNonEmpty(ctrls.product.val())) {
    win.warn(TextVal.noProduct);
    return false;
  }
  return _validateIntInput(ctrls.backQty, win);
}

/**
 * Generates the code necessary to create a time-series object from historical data.
 * @param {{product: jQuery, backQty: jQuery, backUnit: jQuery, intraday: jQuery, timeZone: jQuery}} ctrls
 * @param {string} varName
 * @param {ScriptVar[]} availVars
 * @returns {string}
 * @private
 */
function _genCodeTimeSeries(ctrls, varName, availVars) {
  const isIntraday = _isChecked(ctrls.intraday);
  let fnName = 'time_series';
  const startDate = `-${ ctrls.backQty.val() }${ctrls.backUnit.val()}`;
  const productVal = ctrls.product.val();
  let tz = null;

  if (isIntraday) {
    fnName = 'time_series_tz';
    tz = ctrls.timeZone.val();
  }
  return _genCodeTimeSeriesFromVal(varName, productVal, startDate, fnName, isIntraday, tz);
}

/**
 *  Generates the code necessary to create a time-series object from the historical data values.
 * @param {string} varName
 * @param {string} productVal
 * @param {string} startDate
 * @param {string}fnName
 * @param {boolean} isIntraDay
 * @param {?string} tz
 * @return {string}
 * @private
 */
function _genCodeTimeSeriesFromVal(varName, productVal, startDate, fnName, isIntraDay, tz) {
  const dateCode = _getDateCode(startDate);
  const code = `var ${ varName } = ${ fnName }(${
    productVal }, ${
    (isIntraDay) ? `"${ tz }", ` : ''
  }${dateCode
  });`;

  return Strings.assembleAutoGenJS({}, code);
}


/**
 * Sets the UI controls to match the given Time-Series step.
 * @param {MpFormulaStep} step
 * @param {{product: jQuery, backQty: jQuery, backUnit: jQuery, intraday: jQuery, timeZone: jQuery}} ctrls
 * @private
 */
function _displayTimeSeries(step, ctrls) {
  const match = (new RegExp(`^var\\s*${ PATT_VAR_NAME_ANY }\\s*=`
                                   + '\\s*(time_series|time_series_tz)\\s*\\('
                                   + `\\s*(${ PATT_VAR_NAME_ANY })\\s*`
                                   + '(,\\s*["\']([^"\']+)["\']\\s*)?'
                                   + ',\\s*(.*?)\\s*\\)\\s*(?:;?\\s*)?$')).exec(step.codeOnly());
  if (match === null) { throw new Error('IllegalStateException: unable to parse unrecognized TIME_SERIES code snipet.'); }

  const matchStartDate = REGEX_RELATIVE_RUN_DATE.exec(_getDateValueFromCode(match[5]));
  if (matchStartDate === null) { throw new Error('IllegalStateException: unable to parse START_DATE argument from TIME_SERIES code snipet.'); }

  ctrls.product.val(match[2]);

  ctrls.backQty.val(parseInt(matchStartDate[1], 10) * -1);
  ctrls.backUnit.val(matchStartDate[2]);
  _timeSeriesTimeUnitChangeApply(ctrls);

  _setChecked(ctrls.intraday, (match[1] === 'time_series_tz'));
  _timeSeriesIntradayClickApply(ctrls);

  if (Strings.isNonEmpty(match[3])) { ctrls.timeZone.val(match[4]); }
}

/**
 * Builds the UI controls necessary to create a Fill step.
 * @param {jQuery} tableElm - JQuery wrapper to HTMLTableBodyElement.
 * @param {ScriptVar[]} availVars
 * @returns {{timeSeriesVar: jQuery, direction: jQuery[]}}
 *          UI controls, to be referenced later by other
 *          methods of the FILL step type.
 * @private
 */
function _buildUiFill(tableElm, availVars) {
  const TextF = Text.Type.Fill;

  const tsVar = _newVarSelect(_newTableRow(tableElm, TextF.selectTimeSeries),
    [VarDataType.TIME_SERIES],
    availVars);

  const tdMode = _newTableRow(tableElm, '');
  const modeForward = _newRadioBtn(tdMode, 'fill_mode', 'fill_forward', TextF.fillForward);
  const modeBackward = _newRadioBtn(tdMode, 'fill_mode', 'fill_backward', TextF.fillBackward);

  _setChecked(modeForward, true);

  return {
    timeSeriesVar: tsVar,
    direction: [
      modeForward,
      modeBackward,
    ],
  };
}

/**
 * Validates the controls of an Fill step.
 * @param {{timeSeriesVar: jQuery, direction: jQuery[]}} ctrls
 * @param {lim.Window} win
 * @param {ScriptVar[]} availVars
 * @returns {boolean} Whether all controls are in a valid state.
 * @private
 */
function _validateFill(ctrls, win, availVars) {
  const TextVal = Text.Type.Fill.Validation;

  if (!Strings.isNonEmpty(ctrls.timeSeriesVar.val())) {
    win.warn(TextVal.noVar);
    return false;
  }

  return true;
}

/**
 * Generates the code necessary to create a variable that contains
 * the result of a time-series that has been filled forward or backward.
 * @param {{timeSeriesVar: jQuery, direction: jQuery[]}} ctrls
 * @param {string} varName
 * @param {ScriptVar[]} availVars
 * @returns {string}
 * @private
 */
function _genCodeFill(ctrls, varName, availVars) {
  const tsVar = ctrls.timeSeriesVar.val();
  const fillFn = UI.getRadioValue(ctrls.direction);
  const code = `var ${ varName } = ${ fillFn }(${ tsVar });`;

  return Strings.assembleAutoGenJS({}, code);
}


/**
 * Sets the UI controls to match the given Fill step.
 * @param {MpFormulaStep} step
 * @param {{timeSeriesVar: jQuery, direction: jQuery[]}} ctrls
 * @private
 */
function _displayFill(step, ctrls) {
  const regex = new RegExp(`^var ${ PATT_VAR_NAME_ANY } = (fill_forward|fill_backward)\\((${ PATT_VAR_NAME_ANY })\\);$`);
  const match = regex.exec(step.codeOnly());

  if (match === null) { throw new Error('IllegalStateException: unable to parse unrecognized FILL code snipet.'); }

  _setPossiblyGoneOption(ctrls.timeSeriesVar, match[2]);
  UI.setRadioValue(ctrls.direction, match[1]);
}


/**
 * Type of step.
 * @class
 */
export class MpFormulaStepType {
  /**
     * @param {string} name - Name associated with this type of step.
     * @param {boolean} isData - Whether this tyep of step represents data.
     * @param {function} fnBuildUI - Function to build the UI controls
     *                   necessary to create and maintain this type of step.
     * @param {function} fnValidate - Function to validate the UI controls.
     * @param {function} fnGenCode - Function to generate the code that makes a step.
     * @param {function} fnDisplay - Function to set the UI controls to the values
     *                   that represent an instance of MpFormulaStep.
     * @constructor
     */
  constructor(name, isData, fnBuildUI, fnValidate, fnGenCode, fnDisplay) {
    this._name = name;
    this._isData = isData;
    this._buildUI = fnBuildUI;
    this._val = fnValidate;
    this._genCode = fnGenCode;
    this._disp = fnDisplay;

    Object.freeze(this);
  }

    //                                              `valueOf`        is-data      build-UI               validate           generate code         display
    static FORWARD_CURVE = new MpFormulaStepType('forward_curve', true, _buildUiFwdCurve, _validateFwdCurve, _genCodeFwdCurve, _displayFwdCurve);

    static TIME_SERIES = new MpFormulaStepType('time_series', true, _buildUiTimeSeries, _validateTimeSeries, _genCodeTimeSeries, _displayTimeSeries);

    static MERGE = new MpFormulaStepType('merge', true, _buildUiMerge, _validateMerge, _genCodeMerge, _displayMerge);

    static BASIC_MATH = new MpFormulaStepType('basic_math', true, _buildUiBasicMath, _validateBasicMath, _genCodeBasicMath, _displayBasicMath);

    static FREE_TEXT = new MpFormulaStepType('free_text', true, _buildUiFreeText, _validateFreeText, _genCodeFreeText, _displayFreeText);

    static EXTRAPOLATION = new MpFormulaStepType('extrapolation', true, _buildUiExtrapolate, _validateExtrapolate, _genCodeExtrapolate, _displayExtrapolate);

    static MISSING_DATA_QA = new MpFormulaStepType('missing_data_qa', false, _buildUiMissingData, _validateMissingData, _genCodeMissingData, _displayMissingData);

    static SPIKE_QA = new MpFormulaStepType('spike_qa', false, _buildUiSpike, _validateSpike, _genCodeSpike, _displaySpike);

    static IN_RANGE_QA = new MpFormulaStepType('in_range_qa', false, _buildUiInRange, _validateInRange, _genCodeInRange, _displayInRange);

    static FILL = new MpFormulaStepType('fill', true, _buildUiFill, _validateFill, _genCodeFill, _displayFill);

    /**
     * Returns the MpFormulaStepType enum item associated with
     * `val`.
     * @param {string} val
     * @param {*} [defaultValue] - The value to return in case `val` is invalid.
     * @returns {(MpFormulaStepType|*)}
     */
    static valueOf(val, defaultValue) {
      const key = requireNonEmptyString(val, 'val').toUpperCase();
      if (MpFormulaStepType.hasOwnProperty(key)
            && MpFormulaStepType[key] instanceof MpFormulaStepType) {
        return MpFormulaStepType[key];
      } if (arguments.length > 1) {
        return defaultValue;
      }
      throw new Error(`Unrecognized MpFormulaStepType value: ${ val}`);
    }

    /**
     *  Generates the default forward curve code from the give values
     * @param {string} varName
     * @param {string} curveFnName
     * @param {string} productVal
     * @param {string} curveDate
     * @param {string} deliveryVal
     * @return {string}
     */
    static defaultFwdCurveCode(varName, curveFnName, productVal, curveDate, deliveryVal) {
      return _genCodeFwdCurveFromVal(varName, curveFnName, productVal, curveDate, deliveryVal);
    }

    /**
     *  Generates the default time series code from the give values
     * @param {string} varName
     * @param {string} productVal
     * @param {string} startDate
     * @param {string} fnName
     * @param {boolean} isIntraDay
     * @param {?string} tz
     * @return {string}
     */
    static defaultTimeSeriesCode(varName, productVal, startDate, fnName, isIntraDay, tz) {
      return _genCodeTimeSeriesFromVal(varName, productVal, startDate, fnName, isIntraDay, tz);
    }

    /**
     * Returns the value of this step-type.
     * @returns {string}
     */
    valueOf() { return this._name; }

    /**
     * Returns the string representation of this step-type.
     * @returns {string}
     */
    toString() { return this._name.toUpperCase(); }

    /**
     * Returns whether this type of step represents data,
     * as opposed to a QA check.
     * @returns {boolean}
     * @this {MpFormulaStepType}
     */
    isDataFunction() {
      return this._isData;
    }

    /**
     * Displays the UI controls necessary to create this type of formula step.
     * @param {(jQuery|HTMLTableSectionElement)} tableElm
     * @param {ScriptVar[]} availVars
     * @returns {Object} An object to give back to `validate()` and `newStep()`.
     */
    buildUI(tableElm, availVars) {
      let elm;

      if (isVoid(tableElm)) { throw new Error('NullPointerException: tableElm'); } else if (tableElm.nodeName === 'TBODY') { elm = $(tableElm); } else if (tableElm.length === 1
                 && tableElm[0].nodeName === 'TBODY') { elm = tableElm; } else { throw new TypeError('tableElm: HTMLTableBodyElement, or JQuery wrapper of it'); }

      _validAvailVars(availVars, 'availVars');

      return this._buildUI(elm, availVars);
    }

    /**
     * Validates the on-screen controls.  If at least one controls is invalid,
     * this method displays an error message to users and returns *false*.
     * Otherwise this method returns *true*.
     * @param {Object} ctrls - The same object returned by the `display()` method.
     * @param {lim.Window} win - The parent window, in which to display error messages.
     * @param {ScriptVar[]} availVars
     * @returns {boolean} Whether the controls are all valid.
     */
    validate(ctrls, win, availVars) {
      requireObject(ctrls, 'ctrls');

      if (!(win instanceof lim.Window)) { throw new TypeError('win: lim.Window'); }

      _validAvailVars(availVars, 'availVars');

      return (this._val(ctrls, win, availVars) === true);
    }

    /**
     * Creates a new formula step based on the current control values.
     * If any of the controls are invalid, this method behavior is undefined:
     * callers should also call `validate()` first and make sure it returns true!
     * @param {Object} ctrls - The same object returned by the `display()` method.
     * @param {string} varName - The variable name to be assigned by the new step.
     * @param {ScriptVar[]} availVars
     * @returns {MpFormulaStep}
     */
    newStep(ctrls, varName, availVars) {
      requireObject(ctrls, 'ctrls');
      _validName(varName, this, 'varName');
      _validAvailVars(availVars, 'availVars');

      return new MpFormulaStep(
        this,
        varName,
        this._genCode(ctrls, varName, availVars),
      );
    }
}

Object.freeze(MpFormulaStepType);

/**
 * @param {(MpFormulaStepType|*)} arg Argument to validate.
 * @returns {boolean} Whether `arg` is a MpFormulaStepType object.
 */
export function isMpFormulaStepType(arg) {
  return (arg instanceof MpFormulaStepType);
}

/**
 * Validates that `arg` is a MpFormulaStepType object.
 * @param {MpFormulaStepType} arg Argument to validate/
 * @param {string} argName Name given to `arg` for when error must be thrown.
 * @returns {MpFormulaStepType} Always returns `arg`.
 * @throws {TypeError} If `arg` is not a MpFormulaStepType object.
 */
export function requireMpFormulaStepType(arg, argName) {
  if (!isMpFormulaStepType(arg)) {
    throw new TypeError(`${arg }: MpFormulaStepType`);
  }
  return arg;
}

/* ******************************************************
 * Class: MpFormulaStep
 * ****************************************************** */
/**
 * Represents a step within a formula.  Each steps resolves into a given
 * variable.
 * @class
 */
class MpFormulaStep {
  /**
     * @param {MpFormulaStepType} type - Type of step.
     * @param {string} name - Variable name
     * @param {string} unparsedCode - JavaScript code previously created by `type`.
     * @constructor
     */
  constructor(type, name, unparsedCode) {
    requireMpFormulaStepType(type, 'type');
    _validName(name, type, 'name');
    requireNonEmptyString(unparsedCode, 'unparsedCode');

    const analysis = Strings.parseAutoGenJS(unparsedCode);
    let md; let
      code;

    if (analysis !== null) {
      md = analysis.metadata;
      code = analysis.body;
    } else {
      md = {};
      code = unparsedCode;
    }

    this._type = type;
    this._name = name;
    this._hdr = Objects.freeze(md);
    this._code = code;

    Object.freeze(this);
  }

    static Type = MpFormulaStepType;

    static ParseError = ParseError;

    /**
     * Converts a basic math expression into JS-Engine code.
     * This method is made public for the sole purpose of unit-testing.
     *
     * @param {string} expr
     * @param {ScriptVar[]} availVars
     * @returns {string}
     */
    static userExpressionToJsCode(expr, availVars) {
      requireNonEmptyString(expr, 'expr');
      _validAvailVars(availVars, 'availVars');

      return _mathExprToJsCode(expr, availVars);
    }

    /**
     * Returns the type of step (forward_curve, basic_math, etc.)
     * @returns {MpFormulaStepType}
     */
    type() { return this._type; }

    /**
     * Returns the variable name that is created as a result of this step.
     * @returns {string}
     */
    name() { return this._name; }

    /**
     * Builds the UI controls and sets their value to match this
     * instance.
     * @param {(jQuery|HTMLTableSectionElement)} tableElm
     * @param {ScriptVar[]} availVars
     * @returns {Object} An object to give back to `validate()` and `newStep()`.
     */
    display(tableElm, availVars) {
      const type = this._type;
      const ctrls = type.buildUI(tableElm, availVars);

      type._disp(this, ctrls);

      return ctrls;
    }


    /**
     * Returns the code associated with this step, to be inserted
     * in a bigger script, possibly with other steps.
     * @returns {string}
     */
    scriptSnippet() {
      return Strings.assembleAutoGenJS(this._hdr, this._code);
    }

    /**
     * Returns the code associated with this step, without
     * commented metadata.
     * @returns {string}
     */
    codeOnly() {
      return this._code;
    }
}
Object.freeze(MpFormulaStep);

/**
 * @param {(MpFormulaStep|*)} arg Argument to validate.
 * @returns {boolean} Whether `arg` is an instance of MpFormulaStep.
 */
export function isMpFormulaStep(arg) {
  return (arg instanceof MpFormulaStep);
}

export default MpFormulaStep;
export {
  _safeLang, _mathExprToJsCode, _genMdExtraMeth_repeat_last_cycle, _genMdExtraMeth_follow_dataset,
  _genCodeExtraMeth_follow_dataset, _genCodeExtraMeth_repeat_last_cycle,
};
