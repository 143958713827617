const global = `

const morn = {};


/* ******************************************************
    * Imports
    * ****************************************************** */
var Strings = lim.String,
    Numbers = lim.Number;

/* ******************************************************
    * Private variables
    * ****************************************************** */
var _boolValues = {
    "true":  true,
    "yes":   true,
    "false": false,
    "no":    false
};

/* ******************************************************
    * Private methods
    * ****************************************************** */
var _returnTrue = function () {
    return true;
};

var _noop = function (val) {
    return val;
};

/**
 * Returns whether \`strVal\` looks like a valid JSON value.
 * This method does a surface validation: it ensures that
 * the value uses "[]" or "{}" at its extremities.
 * The real validation will occur during the conversion.
 * @param strVal {string} The value to validate.
 * @private
 */
var _validJson = function (strVal) {

    var trimmed = Strings.trim(strVal),
        len     = trimmed.length;

    if (len < 2)
        return false;

    else {
        var firstChar = trimmed.charAt(0),
            lastChar  = trimmed.charAt(len - 1);

        return (   (firstChar === '[' && lastChar === ']')
                || (firstChar === '{' && lastChar === '}') );
    }
};

/**
 * Creates a new getter method.
 * @param fnValidate {Function} A function used to validate that
 *                   the named parameter can be returned in the
 *                   desired data-type.
 * @param fnConvert {Function} A function used to convert the
 *                  string parameter value into the desired
 *                  data-type.
 * @param expected {string} The data-type.  Used for creating errors, for
 *                 when \`fnValidate()\` returns *false*.
 * @returns {Function} A getter method.
 * @private
 */
var _newGetter = function (fnValidate, fnConvert, expected) {
    
    return function (name, defaultValue) {
        
        // Call java-powered function \`getPropertyJava()\`
        var val = Strings.requireNonEmpty(name, 'name');
        if (val !== null) {
            if (fnValidate(val)) {
                return fnConvert(val);
            } else {
                throw new TypeError(Strings.build("Parameters[\\"{}\\"]: {}", name, expected));
            }
        } else if (arguments.length > 1) {
            return defaultValue;
        } else {
            throw new Error(Strings.build("NullPointerException: Parameters[\\"{}\\"]", name));
        }
    };
};

/**
 * @namespace
 * @alias morn.Parameters
 */
var Parameters = /** @lends morn.Parameters */ {
    
    /**
     * Returns whether the named parameter exists or not.
     * 
     * @param name {string} The name of the parameter; must be a non-empty string.
     * @return {boolean}
     */
    contains: function (name) {
        // Call java-powered function \`getPropertyJava()\`
        return (Strings.requireNonEmpty(name, 'name') !== null);
    },

    /**
     * Returns the value associated with named parameter <code>name</code>,
     * as an integer value.
     *
     * @method
     * @param name {string} The name of the parameter; must be a non-empty string.
     * @param [defaultValue] The default value to return if
     *                       <code>name</code> doesn't exist.
     * @return {integer}
     * @throws NullPointerException - if <code>name</code> doesn't exist and
     *                                <code>defaultValue</code> isn't provided.
     */
    getInt: _newGetter( Numbers.isIntegerString,
                        function (val) { return parseInt(val, 10); },
                        "integer" ),

    /**
     * Returns the value associated with named parameter <code>name</code>,
     * as a float value.
     *
     * @method
     * @param name {string} The name of the parameter; must be a non-empty string.
     * @param [defaultValue] The default value to return if
     *                       <code>name</code> doesn't exist.
     * @return {number}
     * @throws NullPointerException - if <code>name</code> doesn't exist and
     *                                <code>defaultValue</code> isn't provided.
     */
    getFloat: _newGetter( Numbers.isFloatString,
                            function (val) { return parseFloat(val); },
                            "float" ),

    /**
     * Returns the value associated with named parameter <code>name</code>,
     * as a string value.
     * 
     * @method
     * @param name {string} The name of the parameter; must be a non-empty string.
     * @param [defaultValue] The default value to return if
     *                       <code>name</code> doesn't exist.
     * @return {string}
     * @throws NullPointerException - if <code>name</code> doesn't exist and
     *                                <code>defaultValue</code> isn't provided.
     */
    getString: _newGetter( _returnTrue,
                            _noop,
                            "string" ),
    
    /**
     * Returns the value associated with named parameter <code>name</code>,
     * as a date object.
     * 
     * @method
     * @param name {string} The name of the parameter; must be a non-empty string.
     * @param [defaultValue] The default value to return if
     *                       <code>name</code> doesn't exist.
     * @return {lim.IDate}
     * @throws NullPointerException - if <code>name</code> doesn't exist and
     *                                <code>defaultValue</code> isn't provided.
     */
    getDate: _newGetter( _returnTrue,
                            lim.IDate.create,
                            "string, ISO-8601 date" ),

    /**
     * Returns the value associated with named parameter <code>name</code>,
     * as a boolean value.
     * 
     * @method
     * @param name {string} The name of the parameter; must be a non-empty string.
     * @param [defaultValue] The default value to return if
     *                       <code>name</code> doesn't exist.
     * @return {boolean}
     * @throws NullPointerException - if <code>name</code> doesn't exist and
     *                                <code>defaultValue</code> isn't provided.
     */
    getBool: _newGetter( function (val) { return _boolValues.hasOwnProperty(val.toLowerCase()); },
                            function (val) { return _boolValues[val.toLowerCase()]; },
                            "boolean" ),

    /**
     * Returns the value associated with named parameter <code>name</code>,
     * as a JSON object.
     *
     * @method
     * @param name {string} The name of the parameter; must be a non-empty string.
     * @param [defaultValue] The default value to return if
     *                       <code>name</code> doesn't exist.
     * @return {Object}
     * @throws NullPointerException - if <code>name</code> doesn't exist and
     *                                <code>defaultValue</code> isn't provided.
     */
    getJson: _newGetter ( _validJson,
                            function (val) { return JSON.parse(val); },
                            "JSON" )

};


/* ******************************************************
    * Global object
    * ****************************************************** */
morn.Parameters = Object.freeze(Parameters);

/* ******************************************************
    * Interfaces
    * ****************************************************** */

/**
 * A field within a feed, key or value.
 *
 * @typedef Object morn.Feed.Field
 * @property {string} name - Field name.
 * @property {string} type - "K" for key, "V" for value.
 * @property {string} dataType - Field data-type, pertains to storage.
 */


/* ******************************************************
    * Private variables
    * ****************************************************** */
var _cacheAllFields   = {},
    _cacheValueFields = {},
    _cacheKeyFields   = {};

/* ******************************************************
    * Private methods
    * ****************************************************** */

/**
 * Validates that \`arg\` is a non-empty String.
 * @param {string} arg Argument to validate.
 * @param {string} argName Name given to \`arg\`.
 * @returns {string} Always returns \`arg\`.
 * @throws {TypeError} If \`arg\` is not a string or is empty.
 * @private
 */
function _requireNonEmptyString (arg, argName) {

    if (!lim.String.isNonEmpty(arg))
        throw new TypeError(argName, ": String, non-empty");

    return arg;
}

/**
 * Returns the complete list of fields that pertain to a feed.
 * @param {string} feedName
 * @returns {morn.Feed.Field[]} Immutable list of fields.
 * @private
 */
var _loadAllFields = function (feedName) {

    var fields = JSON.parse(getFeedFieldsJava(feedName));

    // Freeze inner objects.
    _.each(fields, function (field) {
        Object.freeze(field);
    });

    // Sort by name
    fields.sort(function (f1, f2) {
        return lim.String.compare(f1.name, f2.name);
    });

    // Freeze the list itself.
    Object.freeze(fields);

    return fields;
};

/**
 * Returns a list of fields that pertain to a feed.
 * This method first looks in the given cache, then queries
 * the database if necessary.
 *
 * @param {string} feedName
 * @param {Object.<string, morn.Feed.Field[]>} cache
 * @param {function} loader
 * @returns {morn.Feed.Field[]}
 * @private
 */
var _get = function (feedName, cache, loader) {

    var feedNameLC = _requireNonEmptyString(feedName, "feedName").toLowerCase();

    if (!cache.hasOwnProperty(feedNameLC))
        cache[feedNameLC] = loader(feedName);

    return cache[feedNameLC];
};

/**
 * Wrapper to Java function for returning fields of a feed;
 * includes caching ability.
 * @param {string} feedName
 * @returns {morn.Feed.Field[]}
 * @private
 */
var _getAllFields = function (feedName) {
    return _get(feedName, _cacheAllFields, _loadAllFields);
};

/**
 * Returns a list of fields of a certain type, possibly loading the master
 * list if needed.
 * @param {string} feedName
 * @param {string} fieldType
 * @returns {morn.Feed.Field[]} Immutable list of field(s) of the specified type.
 * @private
 */
var _loadTypedFields = function (feedName, fieldType) {

    return Object.freeze(_.filter(_getAllFields(feedName), function (field) {
        return (field.type === fieldType);
    }));
};


/**
 * Returns the list of key fields that pertain to a feed.
 * @param {string} feedName
 * @returns {morn.Feed.Field[]} Immutable list of key fields.
 * @private
 */
var _loadKeyFields = function (feedName) {
    return _loadTypedFields(feedName, "K");
};

/**
 * Returns the list of value fields that pertain to a feed.
 * @param {string} feedName
 * @returns {morn.Feed.Field[]} Immutable list of value fields.
 * @private
 */
var _loadValueFields = function (feedName) {
    return _loadTypedFields(feedName, "V");
};

/**
 * Returns the given feed's value fields.
 * @param {string} feedName
 * @returns {morn.Feed.Field[]}
 * @private
 */
var _getValueFields = function (feedName) {
    return _get(feedName, _cacheValueFields, _loadValueFields);
};

/**
 * Returns the given feed's key fields.
 * @param {string} feedName
 * @returns {morn.Feed.Field[]}
 * @private
 */
var _getKeyFields = function (feedName) {
    return _get(feedName, _cacheKeyFields, _loadKeyFields);
};


/**
 * Convenience method for plucking \`name\` out of a given list of fields.
 * @param {string} feedName
 * @param {function} getter
 * @private
 */
var _pluckName = function (feedName, getter) {
    return _.pluck(getter(feedName), 'name');
};


/**
 * @namespace
 * @alias morn.Feed
 */
var Feed = /** @lends {morn.Feed} */ {

    /**
     * Returns a list of value field names (aka columns),
     * sorted alphabetically.
     * @param {string} feedName
     * @returns {string[]} Names of value fields, sorted by name.
     */
    getValueFieldNames: function (feedName) {
        return _pluckName(feedName, _getValueFields);
    },

    /**
     * Returns an immutable list of value fields (aka columns),
     * sorted alphabetically by name.
     * @param {string} feedName
     * @returns {morn.Feed.Field[]} Value fields, sorted by name.
     */
    getValueFields: function (feedName) {
        return _getValueFields(feedName);
    },

    /**
     * Returns a list of key field names (aka columns),
     * sorted alphabetically.
     * @param {string} feedName
     * @returns {string[]} Names of key fields, sorted by name.
     */
    getKeyFieldNames: function (feedName) {
        return _pluckName(feedName, _getKeyFields);
    },

    /**
     * Returns an immutable list of key fields (aka columns),
     * sorted alphabetically by name.
     * @param {string} feedName
     * @returns {morn.Feed.Field[]} Key fields, sorted by name.
     */
    getKeyFields: function (feedName) {
        return _getKeyFields(feedName);
    },

    /**
     * Returns a list of all field names (aka columns),
     * sorted alphabetically.
     * @param {string} feedName
     * @returns {string[]} Names of key fields, sorted by name.
     */
    getAllFieldNames: function (feedName) {
        return _pluckName(feedName, _getAllFields);
    },

    /**
     * Returns an immutable list of all fields (aka columns),
     * sorted alphabetically by name.
     * @param {string} feedName
     * @returns {morn.Feed.Field[]} All fields, sorted by name.
     */
    getAllFields: function (feedName) {
        return _getAllFields(feedName);
    },

    /**
     * Validates field names.  This method does not distinguish between key fields,
     * value fields or other types of fields; it only look for the existence of a field.
     * @param {string} feedName Name of feed.
     * @param {string[]} fieldNames Name of fields to validate.
     * @returns {string[]} Validated field names, in same order, using
     *          name capitalization from feed definition (upper or lower case).
     * @throws {TypeError} If \`feedName\` is not string or if \`fieldNames\` is not string-array.
     * @throws {Error} If one of the field names is invalid.
     */
    getValidFieldNames: function (feedName, fieldNames) {

        var fields = _getAllFields(feedName),
            fieldMap = _.indexBy(fields, function (f) {
                return f.name.toLowerCase();
            }),
            rejected = _.reject(fieldNames, function (f) {
                return fieldMap.hasOwnProperty(f.toLowerCase());
            });

        if (rejected.length > 0) {
            throw new Error("Invalid field name(s): " + rejected.join(", "));
        }

        return _.map(fieldNames, function (f) {
            return fieldMap[f.toLowerCase()].name;
        });
    }
};

/* ******************************************************
    * Global Objects
    * ****************************************************** */

morn.Feed = Object.freeze(Feed);

/* *********************************************************
    * "Imports"
    * ********************************************************* */

var Arrays = lim.Arrays,
    IDate  = lim.IDate,
    Feed   = morn.Feed;

/* *********************************************************
    * Private variables
    * ********************************************************* */
var _canConstructProduct = false;
var _canConstructValuedKey = false;

var _maxNumberPerPage = 20000;

/* *********************************************************
    * Private methods
    * ********************************************************* */

/**
 * <p>
 * Validates an argument to be a boolean.
 * If valid, this method returns that argument.
 * Otherwise it throws an exception.
 * </p>
 * @param {boolean} arg Argument to validate.
 * @param {string} name Name given to \`arg\`.
 * @returns {boolean} Always returns \`arg\`.
 * @throws {TypeError} If \`arg\` is not a boolean.
 * @private
 */
function _validBool (arg, name) {

    if (typeof arg !== "boolean")
        throw new TypeError(name + ": Boolean");

    return arg;
}

/**
 * <p>
 * Validates an argument to be a string.
 * If valid, this method returns that argument.
 * Otherwise it throws an exception.
 * </p>
 * @param {boolean} arg Argument to validate, must be string, may be empty.
 * @param {string} name Name given to \`arg\`.
 * @returns {boolean} Always returns \`arg\`.
 * @throws {TypeError} If \`arg\` is not a string.
 * @private
 */
function _validString (arg, name) {

    if (typeof arg !== "string")
        throw new TypeError(name + ": String");

    return arg;
}

/**
 * <p>
 * Validates an argument to be a non-empty string.
 * If valid, this method returns that argument.
 * Otherwise it throws an exception.
 * </p>
 * @param {string} arg Non-empty.
 * @param {string} name
 * @returns {string} Non-empty.
 * @private
 */
function _validStringNE (arg, name) {

    if (!lim.String.isNonEmpty(arg))
        throw new TypeError(name + ": String, non-empty");

    return arg;
}

/**
 * <p>
 * Validates a roots argument, which must be a non-empty array
 * of non-empty strings.
 *
 * If valid, this method returns a copy of the given array.
 * Otherwise it throws an exception.
 * </p>
 * @param {string[]} roots.
 * @returns {string[]} Frozen/immutable array.
 * @private
 */
function _validRoots (roots) {

    if (   !Arrays.isValid(roots, lim.String.isNonEmpty)
        || roots.length === 0 )
        throw new TypeError("roots: Array-of-String, non-empty strings, at least one item.");

    if (!Object.isFrozen(roots)) {
        // Shallow-copy is fine, we've already ensured all items are strings.
        roots = Object.freeze(Arrays.slice(roots));
    }

    return roots;
}

function _isValidKeyValue (value) {
    return lim.String.isNonEmpty(value);
}

/**
 * <p>
 * Validates a keyValues argument, which must be an object of at least
 * one property, and must contain only non-empty string properties.
 *
 * If valid, this method returns that boject. Otherwise it throws an exception.
 * </p>
 *
 * @param {Object.<string, string>} keyValues Can only contain non-empty string values.
 * @return {Object.<string, string>} Always returns \`keyValues\`.
 * @throws {TypeError} If \`keyValues\` does not pass validation.
 * @private
 */
function _validKey (keyValues, argName) {

    if (   !(keyValues instanceof Object)
        || keyValues.constructor !== Object
        || !_.every(keyValues, _isValidKeyValue)
        || _.isEmpty(keyValues) )
        throw new TypeError(argName + ": Object, with only non-empty string properties, at least one property.");

    return keyValues;
}

/**
 * <p>
 * Validates a keyValues argument, which must be an object of at least
 * one property, and must contain only non-empty string properties.
 *
 * If valid, this method returns a copy of the given object (or a frozen clone of it).
 * Otherwise it throws an exception.
 * </p>
 *
 * @param {Object.<string, string>} keyValues Can only contain non-empty string values.
 * @return {Object.<string, string>} \`keyValues\`, or a frozen clone of it.
 * @private
 */
function _validateAndFreezeKey (keyValues) {

    _validKey(keyValues, "keyValues");

    if (Object.isFrozen(keyValues)) {
        return keyValues;
    } else {
        // Shallow-copy is fine, we've already ensured all properties are strings.
        return Object.freeze(_.clone(keyValues));
    }
}

/**
 * <p>
 * Validates the \`columns\` argument, which must be
 * a single String or an Array of String.  A single
 * string argument could contain multiple columns,
 * delimited by a comma (,).
 *
 * If valid, this method returns an Array of String,
 * possibly only one.
 *
 * If not valid, this method throws!
 * </p>
 *
 * @param {(string|string[])} columns
 * @returns {string[]} Frozen/immutable array.
 * @private
 */
function _validCols (columns) {

    if (typeof columns === 'string')
        columns = lim.String.split(columns, new RegExp('\\\\s*,+\\\\s*', "g"));  // Allow multiple columns as comma-separated string.

    else if (   !Arrays.isArrayOf(columns, 'string')
                || columns.length === 0 )
        throw new TypeError("columns: String or Array-of-String");

    else {
        columns = _.map(columns, function (col) {
            return lim.String.trim(col);
        });
    }

    if (!Arrays.isValid(columns, lim.String.isNonEmpty))
        throw new Error("IllegalArgumentException: \`columns\` contains an empty string.");

    if (columns.length !== _.uniq(columns).length)
        throw new Error("IllegalArgumentException: \`columns\` must contain all unique names");

    if (!Object.isFrozen(columns)) {
        // No need to copy because any array passed in has been remapped.
        columns = Object.freeze(columns);
    }

    return columns;
}

/**
 * <p>
 * Validates all products in the array of products
 * </p>
 * @param {morn.Product[]} products Array to validate.
 * @param {string} arrayName Name given to the array.
 * @param {string} err Error message, for when exception must be thrown.
 * @param {function} badItemPredicate Predicate that returns true for a product that fails
 *        validation.
 * @returns {morn.Product[]} Always returns \`products\`.
 * @throws {TypeError} If one of the products fails validation.
 * @private
 */
function _validProductArray (products, arrayName, err, badItemPredicate) {

    var idx = _.findIndex(products, badItemPredicate);
    if (idx >= 0)
        throw new TypeError(arrayName + "[" + idx + "]: " + err);

    return products;
}

/** @returns {string} String representation of a key. */
function _keyAsText (key) {
    return JSON.stringify(key);
}

/** @returns {string} String representation of the values, sorted by key-name. */
function _keyValuesAsText (key, delim) {

    var names = _.keys(key);

    names.sort(lim.String.compareIgnoreCase);

    return _.map(names, function (name) {
        return key[name];
    }).join(delim);
}

/**
 * <p>
 * A data-point, or key-with-value.
 * </p>
 *
 * @constructor
 * @name morn.Product.ValuedKey
 *
 * @param {Object.<string, string>} key
 * @param {string} col
 * @param {lim.IDate} date
 * @param {number} val
 * @param {lim.IDate} insertTime
 */
function ValuedKey (key, col, date, val, insertTime) {

    if (!_canConstructValuedKey)
        throw new Error("IllegalAccessException: private constructor, access denied");

    _canConstructValuedKey = false;

    this._key = key;
    this._col = col;
    this._date = date;
    this._val = val;
    this._insertTime = insertTime;

    Object.freeze(this);
}

_.extend(ValuedKey.prototype, /** @lends morn.Product.ValuedKey.prototype */ {

    /** @returns {Object.<string, string>} Data-point key, never null. */
    key: function () { return this._key; },

    /**
     * Returns the product's key as text.
     * @returns {string} Product key as text.
     */
    keyAsText: function () { return _keyAsText(this._key); },

    /**
     * <p>
     * Return the key values as a string.  If multiple value,
     * the key names are sorted alphabetically (case-insensitive)
     * and the values are joined using <code>delim</code>.
     * </p>
     * @param {string} [delim=","] - Delimiter to use for multi-value keys.
     * @returns {string} Product values as string.
     */
    keyValuesAsText: function (delim) {
        return _keyValuesAsText(
            this._key,
            ((arguments.length < 1) ? ',' : _validString(delim, "delim"))
        );
    },

    /** @returns {string} Data-point column. */
    column: function () { return this._col; },

    /** @returns {lim.IDate} Data-point date. */
    date: function () { return this._date; },

    /** @returns {number} Data-point value. */
    value: function () { return this._val; },

    /** @returns {lim.IDate} Time at which this value was inserted in the system. */
    insertTime: function () { return this._insertTime; },

    /** @returns {string} String representation of this instance, for debug purposes mostly. */
    toString: function () {
        return lim.String.build(
            "{ key: {}, col: {}, date: {}, value: {}, insertTime: {} }",
            _keyAsText(this._key),
            this._col,
            this._date.format("yyyy-MM-dd"), // not dealing with intraday data right now.
            lim.Number.toString(this._val),
            this._insertTime.toString()
        )
    }
});

Object.freeze(ValuedKey);
Object.freeze(ValuedKey.prototype);

/**
 * <p>
 *  A product is defined by a feed, key or root(s) and column(s).
 *  Use a product instance to avoid specifying these values
 *  (feed, key, root(s), column(s)) repeatedly.
 * </p>
 *
 * <p>
 *  This constructor is package-private.  Use factory methods
 *  to construct new instances.
 * </p>
 *
 * @constructor
 * @name morn.Product
 *
 * @param {string} feed Non-empty.
 * @param {?(Object)} key The key for that product; can be \`null\`.
 * @param {?(string[])} roots  he roots for that product; can be \`null\`.
 * @param {string[]} columns
 */
function Product (feed, key, roots, columns) {

    if (!_canConstructProduct)
        throw new Error("UnsupportedOperationException: access to private constructor denied");

    _canConstructProduct = false;

    this._feed  = feed;
    this._key   = key;
    this._roots = roots;
    this._cols  = columns;

    Object.freeze(this);
}

_.extend(Product.prototype, /** @lends morn.Product.prototype */ {
    /**
     * <p>
     * Returns the product's feed (name).
     * </p>
     * @returns {string}
     */
    feed: function () { return this._feed; },

    /**
     * <p>
     * Returns the product's columns.
     * </p>
     * @returns {string[]}
     */
    columns: function () { return this._cols; },

    /**
     * <p>
     * Returns the product's roots, if any.
     * This method can return \`null\`.
     * </p>
     * @returns {?(string[])}
     */
    roots: function () { return this._roots; },

    /**
     * <p>
     * Returns the product's key, if any.
     * This method can return \`null\`.
     * </p>
     * @returns {?(Object.<string, string>)} Product key, may be null.
     */
    key: function () { return this._key; },

    /**
     * <p>
     * Returns the product's key as text.
     * If this product does not have a key, this method returns "null".
     * </p>
     * @returns {string} Product key as text, may be "null" (quoted string).
     */
    keyAsText: function () { return _keyAsText(this._key); },

    /**
     * <p>
     * Return the key values as a string.  If multiple value,
     * the key names are sorted alphabetically (case-insensitive)
     * and the values are joined using <code>delim</code>.
     * </p>
     * @param {string} [delim=","] - Delimiter to use for multi-value keys.
     * @returns {string} Product values as string.
     */
    keyValuesAsText: function (delim) {
        return _keyValuesAsText(
            this._key,
            ((arguments.length < 1) ? ',' : _validString(delim, "delim"))
        );
    },

    /**
     * <p>
     * Returns a new instance with the given feed, preserving
     * the key, roots and columns.
     * </p>
     * @param {string} feed Non-empty.
     * @returns {morn.Product}
     */
    withFeed: function (feed) {

        _validStringNE(feed, "feed");

        _canConstructProduct = true;
        return new Product(feed, this._key, this._roots, this._cols);
    },

    /**
     * <p>
     * Returns a new instance with the given key, preserving
     * the feed and columns.
     * </p>
     * @param {Object} keyValues.
     * @returns {morn.Product}
     */
    withKey: function (keyValues) {

        var safeKey = _validateAndFreezeKey(keyValues);

        _canConstructProduct = true;
        return new Product(this._feed, safeKey, null, this._cols);
    },

    /**
     * <p>
     * Returns a new instance with the given roots, preserving
     * the feed and columns.
     * </p>
     * @param {string[]} roots.
     * @returns {morn.Product}
     */
    withRoots: function (roots) {

        var safeRoots = _validRoots(roots);

        _canConstructProduct = true;
        return new Product(this._feed, null, safeRoots, this._cols);
    },

    /**
     * <p>
     * Returns a new instance with the given columns, preserving
     * the feed, key and roots.
     * </p>
     * @param {(string|string[])} columns
     * @returns {morn.Product}
     */
    withColumns: function (columns) {

        var cols = _validCols(columns);

        _canConstructProduct = true;
        return new Product(this._feed, this._key, this._roots, cols);
    },

    /**
     * <p>
     * Returns a string representation of this product.
     * </p>
     * @returns {string}
     */
    toString: function () {
        return lim.String.build( "{ feed: {}, {}, cols: {} }",
                                    JSON.stringify(this._feed),
                                    (  (this._roots instanceof Array)
                                    ? "roots: " + JSON.stringify(this._roots)
                                    : "key: " + _keyAsText(this._key) ),
                                    JSON.stringify(this._cols) );
    }
});

_.extend(Product, /** @lends morn.Product */ {

    ValuedKey: ValuedKey,

    /**
     * <p>
     * Creates a new Product instance.
     * </p>
     *
     * @param {string} feed The feed name, as a non-empty string.
     * @param {(Object|string[])} keyOrRoots Keys are passed as an
     *                    Object; roots are passed as an Array.
     * @param {(string|string[])} columns The column(s), as a non-empty array of non-empty strings.
     * @returns {morn.Product}
     */
    create: function (feed, keyOrRoots, columns) {

        _validStringNE(feed, "feed");

        var roots = null,
            key   = null;

        if (keyOrRoots instanceof Array)
            roots = _validRoots(keyOrRoots);
        else if (keyOrRoots instanceof Object)
            key = _validateAndFreezeKey(keyOrRoots);
        else
            throw new TypeError("keyOrRoots: Array-of-String or Object-of-String");

        _canConstructProduct = true;
        return new Product(feed, key, roots, _validCols(columns));
    },

    /**
     * <p>
     * Searches a feed for matching products (aka keys).
     * </p>
     *
     * @param {string} feed Feed to search.
     * @param {Object.<string, string>} criterions Criterions, as key-values, in which values
     *        are regular expressions or contain wildcards (based on \`isRegex\`).
     * @param {(string|string[])} columns The column(s), as a non-empty array of non-empty strings.
     * @param {boolean} [isRegex=true] Whether criterion values are regular expressions.
     * @param {Number} pageNo : limit
     * @param {Number} maxNumberPerPage number of keys to be displayed per page
     * @returns {morn.Product[]} Products found per the given criterions, may be empty, never null.
     * @throws {TypeError} If any argument is not of the expected type.
     * @example
     * Product.find(
     *    'CME_NymexFutures',
     *    { symbol: 'CL[0-9]+F' },
     *    ['Settlement_Price']
     * )
     *   --> All January contracts for CL.
     */
    find: function (feed, criterions, columns, isRegex, pageNo, maxNumberPerPage) {

        _validStringNE(feed, "feed");
        _validKey(criterions, "criterions");
        var cols = _validCols(columns);
        var useRegex = true;

        if (arguments.length > 3)
            useRegex = _validBool(isRegex, "isRegex");

        if( maxNumberPerPage > _maxNumberPerPage)
            throw new Error("Max number per page should not be greater than "+ _maxNumberPerPage)

        var correctKeyNames = Feed.getValidFieldNames(feed, _.keys(criterions)),
            correctColNames = Feed.getValidFieldNames(feed, cols),
            correctCriterions = _.reduce(criterions, function (rv, v, k) {
                var i = Arrays.indexOf(k, correctKeyNames, null, lim.String.equalsIgnoreCase);
                rv[correctKeyNames[i]] = v;
                return rv;
            }, {});

        cols = Object.freeze(correctColNames),
            keys = JSON.parse(
                searchFeedKeysJava(
                    feed,
                    JSON.stringify(correctCriterions),
                    useRegex,
                    pageNo,
                    maxNumberPerPage
                )
            );

        return _.map(keys, function (key) {
            _canConstructProduct = true;
            return new Product(feed, Object.freeze(key), null, cols);
        });
    },

    /**
     * <p>
     * Creates a new Product instance with the given feed, root(s) and column(s).
     * </p>
     *
     * @param {string} feed The feed name, as a non-empty string.
     * @param {string[]} roots The root(s), as a non-empty array of non-empty strings.
     * @param {(string|string[])} columns The column(s), as a non-empty array of non-empty strings.
     * @returns {morn.Product}
     */
    withRoots: function (feed, roots, columns) {

        var f = _validStringNE(feed, "feed"),
            r = _validRoots(roots),
            c = _validCols(columns);

        _canConstructProduct = true;
        return new Product(f, null, r, c);
    },

    /**
     * <p>
     * Creates a new Product instance with the given feed, key-values and column(s).
     * </p>
     *
     * @param {string} feed The feed name, as a non-empty string.
     * @param {Object) keyValues The key, as a single-layer object of string values.
     * @param {(string|string[])} columns The column(s), as a non-empty array of non-empty strings.
     * @returns {morn.Product}
     */
    withKey: function (feed, keyValues, columns) {

        var f = _validStringNE(feed, "feed"),
            k = _validateAndFreezeKey(keyValues),
            c = _validCols(columns);

        _canConstructProduct = true;
        return new Product(f, k, null, c);
    },

    /**
     * <p>
     * Fetches values for all products, all columns, for the given date.
     * </p>
     * @param {morn.Product[]} products Products for which to fetch values.  All products
     *        must have a key; no roots allowed.
     * @param {(lim.IDate|string)} date Date of the values to fetch (no time info).
     * @returns {morn.Product.ValuedKey[]} List of valued-keys.
     *
     * @example
     * Product.withValues (
     *   Product.find (
     *    'CME_NymexFutures',
     *    { symbol: 'CL7[XZ]' },
     *    ['Settlement_Price']
     *   ),
     *   '2017-07-21'
     * )
     * --> List of two valued-keys, each with key, column, date,
     *     value and insert-time.
     */
    withValues: function (products, date) {

        if (!Arrays.isArrayOf(products, Product))
            throw new TypeError("products: morn.Product[]");

        var dt = IDate.valueOf(date);
        if (dt.getTimeMillis() > 0)
            throw new Error("intraday data not supported, cannot request [" + dt.toString() + "]");

        var isoDate = dt.format("yyyy-MM-dd");

        if (products.length === 0)
            return [];

        var first = products[0],
            feed  = first._feed,
            cols  = first._cols;

        _validProductArray(products, "products", "Feed mismatch", function (p) {
            return p._feed !== feed
        });
        _validProductArray(products, "products", "Column(s) mismatch", function (p) {
            return !Arrays.areEqualShuffled(cols, p._cols, true);
        });
        _validProductArray(products, "products", "Key-less product (root?)", function (p) {
            return (p._key === null);
        });

        var resp = JSON.parse(getValuedKeysJava(
            feed,
            JSON.stringify(_.pluck(products, '_key')),
            JSON.stringify(cols),
            isoDate
        ));

        var i = -1;
        try {
            return resp.map(
                /**
                 * @param {{
                 *     keys: Object.<string, string>,
                 *     col: string,
                 *     value: string,
                 *     insertTime: string }} o
                 * @returns {morn.Product.ValuedKey}
                 */
                function(o) {
                    i++;
                    _canConstructValuedKey = true;
                    return new ValuedKey(
                        Object.freeze(o.keys),
                        o.col,
                        dt,
                        parseFloat(o.value),
                        IDate.valueOf(o.insertTime)
                    );
                }
            );
        }
        catch (e) {
            throw new Error(lim.String.build(
                "Error parsing valued-keys[{}]: {}\\n\\n{}\\n\\n{}",
                i, e, JSON.stringify(resp[i]), e.stack
            ));
        }
    }

});

Object.freeze(Product);
Object.freeze(Product.prototype);

/* *********************************************************
    * Public object
    * ********************************************************* */

morn.Product = Product;

/* *************************************************
    * "import"
    * ************************************************* */
// IMPORTANT!
// Import; enum types tend to be declared early,
// possibly before imported objects are declared.

var IDate             = lim.IDate,
    Numbers           = lim.Number,
    Strings           = lim.String,
    MILLIS_PER_MINUTE = IDate.MILLIS_PER_MINUTE,
    MILLIS_PER_HOUR   = IDate.MILLIS_PER_HOUR,
    MILLIS_PER_DAY    = IDate.MILLIS_PER_DAY,
    MILLIS_PER_WEEK   = MILLIS_PER_DAY * 7;


/* *************************************************
    * Private variables
    * ************************************************* */
var _canConstruct = false;

var REGEX_DELIVERY_WITH_NUM_UNITS = new RegExp("^(\\\\d+)-([A-Z_a-z]+)");

/* *************************************************
    * Private functions
    * ************************************************* */

/**
 * Validates \`arg\` to be an IDate instance or a native Date object.
 * If valid, this method returns the given argument.
 * This method throws if the given argument is invalid.
 * @param {(lim.IDate|Date)} arg
 * @param {string} argName
 * @returns {(lim.IDate|Date)}
 * @throws TypeError - If \`arg\` is not valid.
 * @private
 */
function _validDate (arg, argName) {
    if (!IDate.is(arg)) {
        throw new TypeError(argName + ": IDate");
    }
    return arg;
}

function _midnightOf (date) {
    return _validDate(date, "date").setTimeOfDay(0);
}

/**
 * @param {int} periodSizeMillis Size of time period, in millis.
 * @returns {function(lim.IDate): lim.IDate} Time-of-day resetter: top-of-the-hour,
 *          top-of-the-minute, 30-minute-tick, etc.
 * @private
 */
function _newTimeOfDayResetter (periodSizeMillis) {
    return function (date) {
        var timeOfDay = _validDate(date, "date").getTimeMillis();
        var rem = timeOfDay % periodSizeMillis;
        return date.setTimeOfDay(timeOfDay - rem);
    }
}

function _prevSunday (date) {
    return _midnightOf(date).backToDayOfWeek(IDate.Day.SUNDAY);
}

function _firstOfMonth (date) {
    return _midnightOf(date).firstOfMonth();
}

function _firstOfQuarter (date) {
    
    var firstOfMonth  = _firstOfMonth(date),  // validates \`date\`
        mo            = firstOfMonth.getMonth(),
        q             = firstOfMonth.getQuarter(),
        mosInQuarter  = IDate.getMonthsInQuarter(q),
        firstMonthInQ = mosInQuarter[0];  // Jan, Apr, Jul or Oct
    
    return firstOfMonth.addMonths(firstMonthInQ - mo);
}

function _firstOfSeason (date) {
    
    /* IMPORTANT!
        * Seasons are NOT always defined by
        * 
        * summer = Apr-Sep
        * winter = Oct-Mar
        * 
        * It varies by commodity.  Some users will
        * also want to define their own seasons.
        */
    
    var Month        = IDate.Month,
        firstOfMonth = _firstOfMonth(date),  // validates \`date\`
        mo           = firstOfMonth.getMonth(),
        add          = 0;
    
    if (mo >= Month.OCTOBER)
        add = Month.OCTOBER - mo;
    
    else if (mo >= Month.APRIL)
        add = Month.APRIL - mo;
    
    else
        add = -(mo + 3);
    
    return firstOfMonth.addMonths(add);
}

function _firstOfYear (date) {
    
    var firstOfMonth = _firstOfMonth(date);  // validates \`date\`
    return firstOfMonth.addMonths(firstOfMonth.getMonth() * -1);
}

/**
 * @param {int} millisInterval
 * @returns {function(lim.IDate): lim.IDate} Calculates the next tick, per
 *          constant interval.
 * @private
 */
function _nextInterval (millisInterval) {
    return function (date) {
        return date.addMilliseconds(millisInterval);
    };
}

function _nextMonth (date) {
    return date.addMonths(1);
}

function _nextQuarter (date) {
    return date.addMonths(3);
}

function _nextSeason (date) {
    // IMPORTANT!
    // Seasons are NOT always defined by
    //
    // summer = Apr-Sep
    // winter = Oct-Mar
    //
    // It varies by commodity.  Some users will
    // also want to define their own seasons.
    return date.addMonths(6);
}

function _nextYear (date) {
    return date.addYears(1);
}

/**
 * @param {int} intervalMillis
 * @returns {function(lim.IDate, lim.IDate): number} Constant-interval unit calculator.
 * @private
 */
function _newConstantIntervalNumUnitsCalculator (intervalMillis) {
    return function (start, end) {
        return Math.round((end.getTime() - start.getTime()) / intervalMillis);
    }
}

/**
 * @param {string} name Name of the delivery type.
 * @param {int} size Period size.
 * @returns {string} \`"{{size}}-{{name}}"\`
 * @private
 */
function _toString (name, size) {
    if (size > 1) {
        return size + '-' + name;
    } else {
        return name;
    }
}


/* *************************************************
    * Enum: DeliveryType
    * ************************************************* */

/**
 * Represents a contract's delivery type: monthly, quarterly, seasonal, annual, daily,
 * weekend, etc.
 *
 * @constructor
 * @name morn.DeliveryType
 *
 * @param {string} name Period name.
 * @param {int} size Period size.
 * @param {int} minDuration Minimum duration of a contract which falls into this delivery type.
 * @param {int} maxDuration Maximum duration of a contract which falls into this delivery type.
 * @param {function(lim.IDate): lim.IDate} tick Function that, given a date, returns a date
 *        that represents the delivery type *tick*.
 * @param {function(lim.IDate): lim.IDate} nextTick Function that calculates the next *tick*,
 *        given the current tick.
 * @param {function(lim.IDate, lim.IDate): int} numUnitCalculator Callback that calculates
 *        the number of delivery periods (units) represented by a given date range.
 */
function DeliveryType (
        name,
        size,
        minDuration,
        maxDuration,
        tick,
        nextTick,
        numUnitCalculator ) {
    
    if (!_canConstruct) {
        throw new Error("IllegalStateException: private constructor");
    }
    _canConstruct = false;
    
    this._name        = name;
    this._size        = size;
    this._minDuration = minDuration;
    this._maxDuration = maxDuration;
    this._tick        = tick;
    this._nextTick    = nextTick;
    this._unitCalc    = numUnitCalculator;
    
    Object.freeze(this);
}

_.extend(DeliveryType.prototype, /** @lends {morn.DeliveryType.prototype} */ {

    /**
     * @returns {string} String representation of this delivery type, for debugging.
     */
    toString: function () {
        return _toString(this._name.toUpperCase(), this._size);
    },


    /**
     * <p>
     *  Returns the minimum duration (in milliseconds) for a
     *  contract's delivery period associated with this delivery type.
     * </p>
     *
     * <p>
     *  This method is provided for sorting purposes.
     * </p>
     *
     * @returns {number}
     */
    valueOf: function () {
        return this._minDuration;  // For sorting and comparing.
    },

    /** @returns {string} Delivery type name, lower-case. */
    periodName: function () {
        return this._name;
    },

    /** @returns {int} Number of units represented by this delivery period. */
    periodSize: function () {
        return this._size;
    },

    /** @returns {string} Period: name only if size == 1, otherwise "size-name". */
    period: function () {
        return _toString(this._name, this._size);
    },

    /**
     * @returns {int} Minimum duration (in milliseconds) for a contract's delivery period
     *         associated with this delivery type.
     */
    minDuration: function () {
        return this._minDuration;
    },

    /**
     * @returns {int} Maximum duration (in milliseconds) for a contract's delivery period
     *         associated with this delivery type.
     */
    maxDuration: function () {
        return this._maxDuration;
    },

    /**
     * @param {lim.IDate} startDate
     * @param {lim.IDate} endDate
     * @returns {number} Number of deliveries represented by the given date range.
     */
    numDeliveries: function (startDate, endDate) {
        return this._unitCalc(
            _validDate(startDate, "startDate"),
            _validDate(endDate,   "endDate"),
            this._size
        );
    },

    /**
     * Returns an date representing the time
     * at which the current period "ticks".
     * @param {lim.IDate} date
     * @returns {lim.IDate}
     */
    tick: function (date) {
        return this._tick(date, this._size);
    },

    /**
     * Returns an date representing the time
     * at which the next period will "tick".
     * @param {lim.IDate} date
     * @returns {lim.IDate}
     */
    nextTick: function (date) {
        return this._nextTick(this._tick(date, this._size), this._size);
    },

    /**
     * Creates an array of dates
     * that correspond to all "ticks" between
     * <code>startDate</code> and <code>endDate</code>.
     * The returned array can be used to create an
     * instance of {@link morn.Calendar}.
     *
     * @param {lim.IDate} startDate
     * @param {lim.IDate} outDate Date-time at which to stop creating ticks (aka "exclusive").
     * @returns {lim.IDate[]}
     */
    getTicks: function (startDate, outDate) {

        _validDate(startDate, "startDate");
        _validDate(outDate,   "outDate");

        var run = this._tick(startDate, this._size),
            a   = [];

        while (run < outDate) {
            a.push(run);
            run = this._nextTick(run, this._size);
        }
        if (   this._maxDuration < MILLIS_PER_DAY
            && typeof $TIME_ZONE !== "undefined"  // Use \`typeof\` before referencing \`$TIME_ZONE\`!
            && $TIME_ZONE instanceof lim.TimeZone ) {
            // If global variable \`$TIME_ZONE\` is provided,
            // we want the curve ticks to be created in
            // the provided time zone.
            a = IDate.withZone(a, $TIME_ZONE);
        }
        return a;
    },

    /**
     * @param {int} size Period size, to apply to the current DeliveryType.
     * @returns {DeliveryType} New DeliveryType instance with given period size, adjusted
     *          min and max durations.
     */
    withPeriodSize: function (periodSize) {
        if (   this._minDuration !== this._maxDuration
            || this._maxDuration >= MILLIS_PER_DAY ) {
            throw new Error(
                'Changing period size is only allowed on constant-interval, intraday delivery types.'
            );
        }
        if (!Numbers.isPositiveInteger(periodSize)) {
            throw new TypeError('periodSize: Integer, positive');
        }
        if (periodSize === this._size) {
            return this;
        } else {
            var durationMillis = (this._minDuration / this._size) * periodSize;
            _canConstruct = true;
            return new DeliveryType(
                this._name,
                periodSize,
                durationMillis,
                durationMillis,
                _newTimeOfDayResetter(durationMillis),
                _nextInterval(durationMillis),
                _newConstantIntervalNumUnitsCalculator(durationMillis)
            );
        }
    }
});

// Convenience function to shorten the length of lines during construction.
function _newEnum (
        name,
        periodSize,
        minDuration,
        maxDuration,
        tick,
        nextTick,
        numUnitCalculator ) {

    if (Numbers.isInteger(nextTick)) {
        nextTick = _nextInterval(nextTick);
    }
    _canConstruct = true;
    return new DeliveryType(
        name,
        periodSize,
        minDuration,
        maxDuration,
        tick,
        nextTick,
        numUnitCalculator
    );
}

_.extend(DeliveryType, /** @lends {morn.DeliveryType} */ {
    /** @type morn.DeliveryType */
    MINUTE: _newEnum(
        'minute',
        1,
        MILLIS_PER_MINUTE,
        MILLIS_PER_MINUTE,
        _newTimeOfDayResetter(MILLIS_PER_MINUTE),
        MILLIS_PER_MINUTE,
        _newConstantIntervalNumUnitsCalculator(MILLIS_PER_MINUTE)
    ),

    /** @type morn.DeliveryType */
    HOUR: _newEnum(
        'hour',
        1,
        MILLIS_PER_HOUR,
        MILLIS_PER_HOUR,
        _newTimeOfDayResetter(MILLIS_PER_HOUR),
        MILLIS_PER_HOUR,
        _newConstantIntervalNumUnitsCalculator(MILLIS_PER_HOUR)
    ),
    
    /** @type morn.DeliveryType */
    DAY: _newEnum(
        'day',
        1,
        23 * MILLIS_PER_HOUR,
        25 * MILLIS_PER_HOUR,
        _midnightOf,
        MILLIS_PER_DAY,
        function (start, end) {
            return Math.round((end.getTime() - start.getTime()) / MILLIS_PER_DAY);
        }
    ),
    
    /** @type morn.DeliveryType */
    WEEK: _newEnum(
        'week',
        1,
        MILLIS_PER_WEEK,
        MILLIS_PER_WEEK,
        _prevSunday,
        MILLIS_PER_WEEK,
        function (start, end) {
            return Math.round((end.getTime() - start.getTime()) / MILLIS_PER_WEEK);
        }
    ),
    /** @type morn.DeliveryType */
    ONLYWEEK: _newEnum(
        'only-week',
        1,
        MILLIS_PER_WEEK,
        MILLIS_PER_WEEK,
        _prevSunday,
        MILLIS_PER_WEEK,
        function (start, end) {
            return Math.round((end.getTime() - start.getTime()) / MILLIS_PER_WEEK);
        }
    ),
    /** @type morn.DeliveryType */
    MONTH: _newEnum(
        'month',
        1,
        26 * MILLIS_PER_DAY,
        31 * MILLIS_PER_DAY,
        _firstOfMonth,
        _nextMonth,
        IDate.diffMonths
    ),
    ONLYMONTH: _newEnum(
        'only-month',
        1,
        26 * MILLIS_PER_DAY,
        31 * MILLIS_PER_DAY,
        _firstOfMonth,
        _nextMonth,
        IDate.diffMonths
    ),
    /** @type morn.DeliveryType */
    QUARTER: _newEnum(
        'quarter',
        1,
        86 * MILLIS_PER_DAY,
        92 * MILLIS_PER_DAY,
        _firstOfQuarter,
        _nextQuarter,
        function (start, end) {
            return Math.round(IDate.diffMonths(start, end) / 3);
        }
    ),
    /** @type morn.DeliveryType */
    ONLYQUARTER: _newEnum(
        'only-quarter',
        1,
        86 * MILLIS_PER_DAY,
        92 * MILLIS_PER_DAY,
        _firstOfQuarter,
        _nextQuarter,
        function (start, end) {
            return Math.round(IDate.diffMonths(start, end) / 3);
        }
    ),
    /** @type morn.DeliveryType */
    SEASON: _newEnum(
        'season',
        1,
        120 * MILLIS_PER_DAY,
        245 * MILLIS_PER_DAY,
        _firstOfSeason,
        _nextSeason,
        function (start, end) {
            return Math.round(IDate.diffMonths(start, end) / 6);
        }
    ),
    /** @type morn.DeliveryType */
    ONLYSEASON: _newEnum(
        'only-season',
        1,
        120 * MILLIS_PER_DAY,
        245 * MILLIS_PER_DAY,
        _firstOfSeason,
        _nextSeason,
        function (start, end) {
            return Math.round(IDate.diffMonths(start, end) / 6);
        }
    ),
    /** @type morn.DeliveryType */
    YEAR: _newEnum(
        'year',
        1,
        360 * MILLIS_PER_DAY,
        366 * MILLIS_PER_DAY,
        _firstOfYear,
        _nextYear,
        function (start, end) {
            return end.getFullYear() - start.getFullYear();
        }
    ),

    /**
     * Converts a string into a DeliveryType enum item.
     * 
     * @param strType {(string|morn.DeliveryType)} A string that represents
     *                one of the named DeliveryType.
     * @param [defaultValue] {*} The default value to return
     *                       if <code>strType</code> is invalid.
     * @returns {morn.DeliveryType}
     * @throws TypeError
     *              - If <code>strType</code> is neither a string nor an
     *                instance of DeliveryType.
     * @throws IllegalArgumentException
     *              - If <code>strType</code> does not represent a named
     *                DeliveryType.
     */
    valueOf: function (strType, defaultValue) {
        if (strType instanceof DeliveryType) {
            return strType;
        } else if (typeof strType !== 'string') {
            throw new TypeError("strType: String");
        } else {
            var match = REGEX_DELIVERY_WITH_NUM_UNITS.exec(strType);
            var size = 1;
            var name;
            if (match !== null) {
                size = parseInt(match[1], 10);
                name = match[2];
            } else {
                name = strType;
            }

            /** @type {?morn.DeliveryType} */
            var deliveryType = DeliveryType[name.toUpperCase()];
            if (deliveryType instanceof DeliveryType) {
                if (size === 1) {
                    return deliveryType;
                } else {
                    return deliveryType.withPeriodSize(size);
                }
            } else if (arguments.length > 1) {
                return defaultValue;
            } else {
                throw new Error(Strings.build(
                    "IllegalArgumentException: strType: not a valid DeliveryType ({})",
                    strType
                ));
            }
        }
    }
});

Object.freeze(DeliveryType);
Object.freeze(DeliveryType.prototype);

/* *************************************************
    * PUBLIC OBJECTS
    * ************************************************* */

morn.DeliveryType = DeliveryType;

/* ****************************************************
    * "import"
    * **************************************************** */
var IDate  = lim.IDate;
var Arrays = lim.Arrays;


/* ****************************************************
    * Private constants and functions
    * **************************************************** */

var NO_NAME = "N/A";

var ACCEPTABLE_DATE_STRING = new RegExp("^\\\\d{4}(?:\\\\-\\\\d{2}(?:\\\\-\\\\d{2})?)?$");


var _validIDate = function (idate) {
    
    if (!(idate instanceof IDate))
        throw new TypeError("date: IDate");
    
    return idate;
};

var _compareHolidays = function (h1, h2) {
    
    var res = IDate.compare(h1.date, h2.date);
    if (res === 0)
        res = lim.String.compare(h1.name, h2.name);
    
    return res;
};

var _dayOfWeekFilter = function (daysOfWeek) {
    
    var boolDays;
    
    if (typeof daysOfWeek === 'boolean') {
        if (daysOfWeek === true)
            boolDays = [1,1,1,1,1,1,1];  // every day
        
        else
            boolDays = [0,1,1,1,1,1,0];  // week days only
    }
    
    else {
        if (lim.Number.isNonNegativeInteger(daysOfWeek))
            daysOfWeek = [daysOfWeek % 7];
        
        else if (Arrays.isValid(daysOfWeek, lim.Number.isNonNegativeInteger)) {
            daysOfWeek = _.map(daysOfWeek, function (dayOfWeek) {
                return dayOfWeek % 7;
            });
        }
        
        else
            throw new TypeError("daysOfWeek: Integer, Array-of-Integer or Boolean");
        
        boolDays = Arrays.newInstance(7, 0);
        for (var i = 0; i < daysOfWeek.length; i++)
            boolDays[daysOfWeek[i]] = 1;
    }
    
    
    return function (holiday) {
        return (boolDays[holiday.date.getDay()] > 0);
    };
};

var _dateAtMidnight = function (date) {
    return IDate.valueOf(date)  // validate \`date\` input
                .dropTimezoneOffset()
                .setTimeOfDay(0);
};

var _timeAtMidnight = function (date) {
    
    var dt = IDate.valueOf(date);  // validate \`date\` input
    return dt.getTimeNoZone() - dt.getTimeMillis();
};


var _toDateString = function (arg, argName, isEnd) {
    
    if (arg === null)
        return null;
    
    else if (arg instanceof IDate)
        return arg.format('yyyy-MM-dd');
    
    else if (typeof arg === 'string') {
        
        if (ACCEPTABLE_DATE_STRING.test(arg))
            return arg;
        
        else {
            try {
                var date = IDate.valueOf(arg);
                return date.format('yyyy-MM-dd');
            }
            catch (ex) {
                throw new Error("IllegalArgumentException: unsupported date format (" + argName + ": " + arg + ")");
            }
        }
    }
    
    else if (typeof arg === 'number') {
        if (   !lim.Number.isInteger(arg)
            || arg < 1000 )
            throw new Error("IllegalArgumentException: only 4-digit years are supported.");
        
        var year = arg;
        if (isEnd === true)
            year++;
        
        return lim.Number.toString(year);
    }
    
    else
        throw new TypeError(argName + ": date or string");
};

var _isBusinessDay = function (holidays, date) {
    
    return (   date.isWeekday()
            && !holidays.isHoliday(date) );
};

var _adjBusinessDay = function (holidays, date, dir) {
    
    while (!_isBusinessDay(holidays, date))
        date = date.addDays(dir);
    
    return date;
};


/* ****************************************************
    * Class: Holiday (private)
    * **************************************************** */
/**
 * @constructor
 * @private
 * @alias morn.Holidays.Holiday
 */
var Holiday = function (date, name) {
    
    /** @type lim.IDate */
    this.date = date.dropTimezoneOffset();
    
    /** @type string */
    this.name = name;
    
    Object.freeze(this);
};

// nothing to it
Holiday.prototype = {
    constructor: Holiday,
    
    toString: function () {
        return this.name + ' - ' + this.date.format('yyyy-MM-dd');
    }
};

Object.freeze(Holiday);
Object.freeze(Holiday.prototype);


/* ****************************************************
    * Class: Holidays (public)
    * **************************************************** */

/**
 * @constructor
 * @alias morn.Holidays
 * @param list {morn.Holidays.Holiday[]}
 */
var Holidays = function (list) {
    
    if (!Arrays.isArrayOf(list, Holiday))
        throw new TypeError("list: morn.Holidays.Holiday[]");
    
    // Sort so that we can use indexSearch() and indexCeiling()
    // for better performance.
    list.sort(_compareHolidays);
    
    var index     = {},
        dedupList = [];
    
    for (var i = 0, len = list.length; i < len; i++) {
        
        var holiday = list[i],
            time    = holiday.date.getTime();
        
        if (!index.hasOwnProperty(time))
            dedupList.push(holiday);
        
        else {
            
            // We have two holiday for the same date.
            // Keep the last one.
            
            dedupList[dedupList.length - 1] = holiday;
        }
        
        index[time] = holiday;
    }
    
    this._list  = Object.freeze(dedupList);
    this._index = Object.freeze(index);
    
    Object.freeze(this);
};


Holidays.prototype = /** @lends morn.Holidays.prototype */ {
    constructor: Holidays,
    
    
    /**
     * Returns whether <code>date</code> is found within the list
     * of <code>holidays</code>.
     * @param date {(lim.IDate|string)}
     * @return {boolean}
     */
    isHoliday: function (date) {
        return this._index.hasOwnProperty(_timeAtMidnight(date));
    },
    
    /**
     * Returns the date of the next holiday on record.
     * This method returns <em>null</em> if no holiday is
     * on record after <code>date</code>.
     * 
     * @param date {(lim.IDate|string)}
     * @return {?lim.IDate}
     */
    next: function (date) {
        
        date = _dateAtMidnight(date);

        var list = this._list,
            idx  = Arrays.indexFloor( date,
                                        list,
                                        'date',
                                        IDate.compare );
        if (idx < list.length - 1)
            return list[idx + 1].date;
        else
            return null;
    },
    
    /**
     * Returns the holiday corresponding to <code>date</code>.
     * If no holiday is on record for <code>date</code>, this method
     * returns <em>null</em>.
     * @param date {(lim.IDate|string)}
     * @return {(morn.Holidays.Holiday|null)}
     */
    holidayOn: function (date) {
        
        var time  = _timeAtMidnight(date),
            index = this._index;
        
        if (index.hasOwnProperty(time))
            return index[time];
        else
            return null;
    },
    
    /**
     * Returns the list of <code>Holiday</code> records found
     * between <code>start</code> and <code>end</code>, inclusively.
     * 
     * @param {?(lim.IDate|string)} [start].
     * @param {?(lim.IDate|string)} [end].
     * @param {(boolean|integer|integer[])} [daysOfWeek=false]
     *                     A list of days (of the week) to be counted.
     *                     Use <em>true</em> to count every day,
     *                     <em>false</em> to count weekdays only (default),
     *                     or provide an Integer or Array-of-Integer
     *                     to list the specific days of the week to
     *                     be counted.
     * @return {morn.Holidays.Holiday[]}
     */
    holidaysBetween: function (start, end, daysOfWeek) {

        var list     = this._list,
            numArgs  = arguments.length,
            startIdx = 0,
            endIdx   = list.length - 1,
            filter   = _dayOfWeekFilter(false);

        if (numArgs > 0) {

            // Validate and convert to dates, if necessary.

            if (start !== null)
                startIdx = Arrays.indexCeiling(_dateAtMidnight(start), list, 'date', IDate.compare);

            if (numArgs > 1) {

                if (end !== null)
                    endIdx = Arrays.indexFloor(_dateAtMidnight(end), list, 'date', IDate.compare);

                if (numArgs > 2)
                    filter = _dayOfWeekFilter(daysOfWeek);
            }
        }
        
        if (   startIdx < 0
            || endIdx   < 0 )
            return [];

        var holidays = list.slice(startIdx, endIdx + 1);

        return _.filter(holidays, filter);
    },
    
    
    /**
     * Returns how many holidays are defined
     * between <code>start</code> and <code>end</code>, inclusively.
     * 
     * @param {?(lim.IDate|string)} [start].
     * @param {?(lim.IDate|string)} [end].
     * @param {(boolean|integer|integer[])} [daysOfWeek=false]
     *                     A list of days (of the week) to be counted.
     *                     Use <em>true</em> to count every day,
     *                     <em>false</em> to count weekdays only (default),
     *                     or provide an Integer or Array-of-Integer
     *                     to list the specific days of the week to
     *                     be counted.
     * @return {integer}
     */
    count: function (start, end, daysOfWeek) {
        return this.holidaysBetween.apply(this, arguments).length;
    },

    /**
     * Returns dates for holidays found within the (optionally) given dates.
     * @param {?(lim.IDate|string)} [start].
     * @param {?(lim.IDate|string)} [end].
     * @param {(boolean|integer|integer[])} [daysOfWeek=false]
     *                     A list of days (of the week) to be counted.
     *                     Use <em>true</em> to count every day,
     *                     <em>false</em> to count weekdays only (default),
     *                     or provide an Integer or Array-of-Integer
     *                     to list the specific days of the week to
     *                     be counted.
     * @returns {lim.IDate[]} Array of holiday dates.
     */
    dates: function (start, end) {

        var holidays = this.holidaysBetween.apply(this, arguments);

        return _.map(holidays, function (holiday) {
            return holiday.date;
        });
    },
    
    /**
     * Returns whether the current date
     * is a business day.  That is, a weekday
     * (Monday through Friday) that's not a holiday (if provided).
     * 
     * @param date {lim.IDate}
     * @return {boolean}
     */
    isBusinessDay: function (date) {
        return _isBusinessDay(this, _validIDate(date));
    },
    
    /**
     * <p>
     *  Adjusts the current date to find the closest
     *  adjacent business day.  If the current date
     *  is a business day, it is returned as is.
     * </p>
     * 
     * <p>
     *  This method does not affect the <em>time</em>
     *  portion of the date object.
     * </p>
     * 
     * @param date {lim.IDate}
     * @param direction {integer} The direction in which
     *                  to look for a business day; <em>-1</em> to
     *                  look in the past, <em>1</em> to look toward
     *                  the future.
     * @returns {IDate}
     */
    adjustBusinessDay: function (date, direction) {
        
        _validIDate(date);
        
        if (   !lim.Number.isInteger(direction)
            || (   direction !== -1
                && direction !== 1 ) )
            throw new TypeError("direction: Integer, -1 or 1");
        
        return _adjBusinessDay(this, date, direction);
    },
    
    /**
     * Adds weekdays to the current date.  Holidays (if provided)
     * are skipped over; they are not counted.
     *
     * @param date {lim.IDate}.
     * @param numDays {integer}.
     * @return {lim.IDate}
     */
    addBusinessDays: function (date, numDays) {
        
        _validIDate(date);
        
        if (!lim.Number.isInteger(numDays))
            throw new TypeError("numDays: Integer");
        
        if (numDays === 0)
            return _adjBusinessDay(this, date, -1);
        
        var dir    = ((numDays > 0) ? 1 : -1),
            busDay = date;
        
        for (var i = numDays; i !== 0; i -= dir)
            busDay = _adjBusinessDay(this, busDay.addDays(dir), dir);
        
        return busDay;
    },
    
    /**
     * Returns a date object that represents the previous
     * business day, regardless of whether <code>date</code>
     * is a business or not.
     * 
     * @param date {lim.IDate}.
     * @return {lim.IDate}
     */
    previousBusinessDay: function (date) {
        return _adjBusinessDay(this, _validIDate(date).addDays(-1), -1);
    },

    /**
     * Returns a date object that represents the next
     * business day, regardless of whether <code>date</code>
     * is a business or not.
     * 
     * @param date {lim.IDate}.
     * @return {lim.IDate}
     */
    nextBusinessDay: function (date) {
        return _adjBusinessDay(this, _validIDate(date).addDays(1), 1);
    }
};


var _fromDatesAndNames = function (datesAndNames) {
    
    var list = [];
    
    for (var i = 0, len = datesAndNames.length; i < len; i++) {
        
        try {
            
            var item = datesAndNames[i],
                date = IDate.valueOf(item.date),
                name = item.name;
            
            if (typeof name !== 'string')
                throw new TypeError("name: String");
            
            list.push(new Holiday(date, name));
        }
        
        catch (ex) {
            throw new Error(ex.toString() + " (ref.: datesAndNames[" + i + "])");
        }
    }
    
    return new Holidays(list);
};

var _validHolidays = function (holidays) {
    
    if (!(holidays instanceof Holidays))
        throw new TypeError("holidays: Holidays");
    
    return holidays;
};

var _fwdToInstance = function (methodName, origArgs) {
    
    var inst = _validHolidays(origArgs[0]),
        args = Arrays.slice(origArgs, 1);
    
    return inst[methodName].apply(inst, args);
};

_.extend(Holidays, /** @lends morn.Holidays */ {

    /**
     * An immutable, empty Holidays instance.
     * @type {morn.Holidays}
     */
    EMPTY: new Holidays([]),

    /**
     * Creates a Holidays object from a list of IDate objects.
     * @param dates {lim.IDate[]}
     * @return {morn.Holidays}
     */
    datesOnly: function (dates) {

        if (!Arrays.isArrayOf(dates, IDate))
            throw new TypeError("dates: lim.IDate[]");
        
        var list = [];
        for (var i = 0, len = dates.length; i < len; i++)
            list.push(new Holiday(dates[i], NO_NAME));
        
        return new Holidays(list);
    },
    
    /**
     * A named holiday.
     * @typedef {Object} morn.Holidays.NamedHoliday
     * @property {string} name - Name of the holiday.
     * @property {(string|lim.IDate)} date - Date at which the holiday is observed.
     */
    
    /**
     * Creates a Holidays object from a list of objects, where
     * each object contains <em>date</em> and <em>name</em> properties.
     * 
     * @param datesAndNames {Array.<morn.Holidays.NamedHoliday>}
     * @return {morn.Holidays}
     */
    datesAndNames: function (datesAndNames) {
        
        if (!Arrays.isArrayOf(datesAndNames, Object))
            throw new TypeError("datesAndNames: Array-of-Object");
        
        return _fromDatesAndNames(datesAndNames);
    },
    
    /**
     * Returns a holiday calendar.
     * 
     * <p>
     *  Callers can limit the scope of the search by providing
     *  <code>start</code> and/or <code>end</code>.  Both arguments
     *  can be dates, strings or integers.
     * </p>
     * 
     * <p>
     *  If strings, the supported formats
     *  are "M/d/yyyy", "yyyy-MM-dd", "yyyy-MM" or "yyyy".
     *  Using the latter two formats for <code>end</code>
     *  makes it exclusive; "2015-01-01" will not be included
     *  when <code>end == "2015"</code>.  Otherwise, <code>end</code>
     *  is inclusive (when provided.)
     * </p>
     * 
     * <p>
     *  If integers, values must be 4-digit years, and the
     *  <code>end</code> year will be treated as inclusive.
     * </p>
     * 
     * @param calendarName {string} Example: "NERC".
     * @param [start] {?(string|integer|lim.IDate)} The calendar's start date.
     * @param [end] {?(string|integer|lim.IDate)} The calendar's end date; 
     *                             can be exclusive or inclusive depending on
     *                             the provided value (see method description).
     * @return {morn.Holidays}
     */
    getCalendar: function (calendarName, start, end) {
        
        if (!lim.String.isNonEmpty(calendarName))
            throw new TypeError("calendarName: String, non-empty");
        
        var args      = arguments,
            numArgs   = args.length;
        
        if (numArgs > 1) {
            
            start = _toDateString(start, "start", false);
            if (numArgs > 2)
                end = _toDateString(end, "end", true);
        }
        
        var payloadText = getHolidayCalendar( calendarName,
                                                start,
                                                end),
            payload     = JSON.parse(payloadText);
        
        if (!Arrays.isArrayOf(payload, Object))
            throw new Error("IllegalStateException: server response not an Array-of-Object");
        
        return _fromDatesAndNames(payload);
    },
    
    /**
     * Returns whether the current date
     * is a business day.  That is, a weekday
     * (Monday through Friday) that's not a holiday.
     * 
     * @param holidays {morn.Holidays}.
     * @param date {lim.IDate}.
     * @return {boolean}
     */
    isBusinessDay: function (holidays, date) {
        return _fwdToInstance('isBusinessDay', arguments);
    },
    
    /**
     * <p>
     *  Adjusts the current date to find the closest
     *  adjacent business day.  If the current date
     *  is a business day, it is returned as is.
     * </p>
     * 
     * <p>
     *  This method does not affect the <em>time</em>
     *  portion of the date object.
     * </p>
     * 
     * @param holidays {morn.Holidays}.
     * @param date {lim.IDate}.
     * @param direction {integer} The direction in which
     *                  to look for a business day; <em>-1</em> to
     *                  look in the past, <em>1</em> to look toward
     *                  the future.
     * @return {lim.IDate}
     */
    adjustBusinessDay: function (holidays, date, direction) {
        return _fwdToInstance('adjustBusinessDay', arguments);
    },
    
    /**
     * Adds weekdays to the current date.  Holidays
     * are skipped over; they are not counted.
     *
     * @param holidays {morn.Holidays}.
     * @param date {lim.IDate}.
     * @param numDays {integer}.
     * @return {lim.IDate}
     */
    addBusinessDays: function (holidays, date, numDays) {
        return _fwdToInstance('addBusinessDays', arguments);
    },
    
    /**
     * Returns a date object that represents the previous
     * business day, regardless of whether <code>date</code>
     * is a business or not.
     * 
     * @param holidays {morn.Holidays}.
     * @param date {lim.IDate}.
     * @return {lim.IDate}
     */
    previousBusinessDay: function (holidays, date) {
        return _fwdToInstance('previousBusinessDay', arguments);
    },

    /**
     * Returns a date object that represents the next
     * business day, regardless of whether <code>date</code>
     * is a business or not.
     * 
     * @param holidays {morn.Holidays}.
     * @param date {lim.IDate}.
     * @return {lim.IDate}
     */
    nextBusinessDay: function (holidays, date) {
        return _fwdToInstance('nextBusinessDay', arguments);
    }        
});

Object.freeze(Holidays);
Object.freeze(Holidays.prototype);


/* ****************************************************
    * PUBLIC OBJECTS
    * **************************************************** */
morn.Holidays = Holidays;

/* ****************************************************
    * "import"
    * **************************************************** */
const { IDate } = lim;
const { Arrays } = lim;


/* ****************************************************
    * Private constants and functions
    * **************************************************** */

const NO_NAME = 'N/A';

const ACCEPTABLE_DATE_STRING = new RegExp('^\\\\d{4}(?:\\\\-\\\\d{2}(?:\\\\-\\\\d{2})?)?$');


const _validIDate = function (idate) {
  if (!(idate instanceof IDate)) throw new TypeError('date: IDate');

  return idate;
};

const _compareHolidays = function (h1, h2) {
  let res = IDate.compare(h1.date, h2.date);
  if (res === 0) res = lim.String.compare(h1.name, h2.name);

  return res;
};

const _dayOfWeekFilter = function (daysOfWeek) {
  let boolDays;

  if (typeof daysOfWeek === 'boolean') {
    if (daysOfWeek === true) boolDays = [1, 1, 1, 1, 1, 1, 1]; // every day

    else boolDays = [0, 1, 1, 1, 1, 1, 0]; // week days only
  } else {
    if (lim.Number.isNonNegativeInteger(daysOfWeek)) daysOfWeek = [daysOfWeek % 7];

    else if (Arrays.isValid(daysOfWeek, lim.Number.isNonNegativeInteger)) {
      daysOfWeek = _.map(daysOfWeek, dayOfWeek => dayOfWeek % 7);
    } else throw new TypeError('daysOfWeek: Integer, Array-of-Integer or Boolean');

    boolDays = Arrays.newInstance(7, 0);
    for (let i = 0; i < daysOfWeek.length; i++) boolDays[daysOfWeek[i]] = 1;
  }


  return function (holiday) {
    return (boolDays[holiday.date.getDay()] > 0);
  };
};

const _dateAtMidnight = function (date) {
  return IDate.valueOf(date) // validate \`date\` input
    .dropTimezoneOffset()
    .setTimeOfDay(0);
};

const _timeAtMidnight = function (date) {
  const dt = IDate.valueOf(date); // validate \`date\` input
  return dt.getTimeNoZone() - dt.getTimeMillis();
};


const _toDateString = function (arg, argName, isEnd) {
  if (arg === null) return null;

  if (arg instanceof IDate) return arg.format('yyyy-MM-dd');

  if (typeof arg === 'string') {
    if (ACCEPTABLE_DATE_STRING.test(arg)) return arg;


    try {
      const date = IDate.valueOf(arg);
      return date.format('yyyy-MM-dd');
    } catch (ex) {
      throw new Error(\`IllegalArgumentException: unsupported date format (\${ argName }: \${ arg })\`);
    }
  } else if (typeof arg === 'number') {
    if (!lim.Number.isInteger(arg)
            || arg < 1000) throw new Error('IllegalArgumentException: only 4-digit years are supported.');

    let year = arg;
    if (isEnd === true) year++;

    return lim.Number.toString(year);
  } else throw new TypeError(\`\${argName }: date or string\`);
};

const _isBusinessDay = function (holidays, date) {
  return (date.isWeekday()
            && !holidays.isHoliday(date));
};

const _adjBusinessDay = function (holidays, date, dir) {
  while (!_isBusinessDay(holidays, date)) date = date.addDays(dir);

  return date;
};


/* ****************************************************
    * Class: Holiday (private)
    * **************************************************** */
/**
 * @constructor
 * @private
 * @alias morn.Holidays.Holiday
 */
const Holiday = function (date, name) {
  /** @type lim.IDate */
  this.date = date.dropTimezoneOffset();

  /** @type string */
  this.name = name;

  Object.freeze(this);
};

// nothing to it
Holiday.prototype = {
  constructor: Holiday,

  toString() {
    return \`\${this.name } - \${ this.date.format('yyyy-MM-dd')}\`;
  },
};

Object.freeze(Holiday);
Object.freeze(Holiday.prototype);


/* ****************************************************
    * Class: Holidays (public)
    * **************************************************** */

/**
 * @constructor
 * @alias morn.Holidays
 * @param list {morn.Holidays.Holiday[]}
 */
const Holidays = function (list) {
  if (!Arrays.isArrayOf(list, Holiday)) throw new TypeError('list: morn.Holidays.Holiday[]');

  // Sort so that we can use indexSearch() and indexCeiling()
  // for better performance.
  list.sort(_compareHolidays);

  const index = {};
  const dedupList = [];

  for (let i = 0, len = list.length; i < len; i++) {
    const holiday = list[i];
    const time = holiday.date.getTime();

    if (!index.hasOwnProperty(time)) dedupList.push(holiday);

    else {
      // We have two holiday for the same date.
      // Keep the last one.

      dedupList[dedupList.length - 1] = holiday;
    }

    index[time] = holiday;
  }

  this._list = Object.freeze(dedupList);
  this._index = Object.freeze(index);

  Object.freeze(this);
};


Holidays.prototype = /** @lends morn.Holidays.prototype */ {
  constructor: Holidays,


  /**
     * Returns whether <code>date</code> is found within the list
     * of <code>holidays</code>.
     * @param date {(lim.IDate|string)}
     * @return {boolean}
     */
  isHoliday(date) {
    return this._index.hasOwnProperty(_timeAtMidnight(date));
  },

  /**
     * Returns the date of the next holiday on record.
     * This method returns <em>null</em> if no holiday is
     * on record after <code>date</code>.
     *
     * @param date {(lim.IDate|string)}
     * @return {?lim.IDate}
     */
  next(date) {
    date = _dateAtMidnight(date);

    const list = this._list;
    const idx = Arrays.indexFloor(date,
      list,
      'date',
      IDate.compare);
    if (idx < list.length - 1) return list[idx + 1].date;
    return null;
  },

  /**
     * Returns the holiday corresponding to <code>date</code>.
     * If no holiday is on record for <code>date</code>, this method
     * returns <em>null</em>.
     * @param date {(lim.IDate|string)}
     * @return {(morn.Holidays.Holiday|null)}
     */
  holidayOn(date) {
    const time = _timeAtMidnight(date);
    const index = this._index;

    if (index.hasOwnProperty(time)) return index[time];
    return null;
  },

  /**
     * Returns the list of <code>Holiday</code> records found
     * between <code>start</code> and <code>end</code>, inclusively.
     *
     * @param {?(lim.IDate|string)} [start].
     * @param {?(lim.IDate|string)} [end].
     * @param {(boolean|integer|integer[])} [daysOfWeek=false]
     *                     A list of days (of the week) to be counted.
     *                     Use <em>true</em> to count every day,
     *                     <em>false</em> to count weekdays only (default),
     *                     or provide an Integer or Array-of-Integer
     *                     to list the specific days of the week to
     *                     be counted.
     * @return {morn.Holidays.Holiday[]}
     */
  holidaysBetween(start, end, daysOfWeek) {
    const list = this._list;
    const numArgs = arguments.length;
    let startIdx = 0;
    let endIdx = list.length - 1;
    let filter = _dayOfWeekFilter(false);

    if (numArgs > 0) {
      // Validate and convert to dates, if necessary.

      if (start !== null) startIdx = Arrays.indexCeiling(_dateAtMidnight(start), list, 'date', IDate.compare);

      if (numArgs > 1) {
        if (end !== null) endIdx = Arrays.indexFloor(_dateAtMidnight(end), list, 'date', IDate.compare);

        if (numArgs > 2) filter = _dayOfWeekFilter(daysOfWeek);
      }
    }

    if (startIdx < 0
            || endIdx < 0) return [];

    const holidays = list.slice(startIdx, endIdx + 1);

    return _.filter(holidays, filter);
  },


  /**
     * Returns how many holidays are defined
     * between <code>start</code> and <code>end</code>, inclusively.
     *
     * @param {?(lim.IDate|string)} [start].
     * @param {?(lim.IDate|string)} [end].
     * @param {(boolean|integer|integer[])} [daysOfWeek=false]
     *                     A list of days (of the week) to be counted.
     *                     Use <em>true</em> to count every day,
     *                     <em>false</em> to count weekdays only (default),
     *                     or provide an Integer or Array-of-Integer
     *                     to list the specific days of the week to
     *                     be counted.
     * @return {integer}
     */
  count(start, end, daysOfWeek) {
    return this.holidaysBetween.apply(this, arguments).length;
  },

  /**
     * Returns dates for holidays found within the (optionally) given dates.
     * @param {?(lim.IDate|string)} [start].
     * @param {?(lim.IDate|string)} [end].
     * @param {(boolean|integer|integer[])} [daysOfWeek=false]
     *                     A list of days (of the week) to be counted.
     *                     Use <em>true</em> to count every day,
     *                     <em>false</em> to count weekdays only (default),
     *                     or provide an Integer or Array-of-Integer
     *                     to list the specific days of the week to
     *                     be counted.
     * @returns {lim.IDate[]} Array of holiday dates.
     */
  dates(start, end) {
    const holidays = this.holidaysBetween.apply(this, arguments);

    return _.map(holidays, holiday => holiday.date);
  },

  /**
     * Returns whether the current date
     * is a business day.  That is, a weekday
     * (Monday through Friday) that's not a holiday (if provided).
     *
     * @param date {lim.IDate}
     * @return {boolean}
     */
  isBusinessDay(date) {
    return _isBusinessDay(this, _validIDate(date));
  },

  /**
     * <p>
     *  Adjusts the current date to find the closest
     *  adjacent business day.  If the current date
     *  is a business day, it is returned as is.
     * </p>
     *
     * <p>
     *  This method does not affect the <em>time</em>
     *  portion of the date object.
     * </p>
     *
     * @param date {lim.IDate}
     * @param direction {integer} The direction in which
     *                  to look for a business day; <em>-1</em> to
     *                  look in the past, <em>1</em> to look toward
     *                  the future.
     * @returns {IDate}
     */
  adjustBusinessDay(date, direction) {
    _validIDate(date);

    if (!lim.Number.isInteger(direction)
            || (direction !== -1
                && direction !== 1)) throw new TypeError('direction: Integer, -1 or 1');

    return _adjBusinessDay(this, date, direction);
  },

  /**
     * Adds weekdays to the current date.  Holidays (if provided)
     * are skipped over; they are not counted.
     *
     * @param date {lim.IDate}.
     * @param numDays {integer}.
     * @return {lim.IDate}
     */
  addBusinessDays(date, numDays) {
    _validIDate(date);

    if (!lim.Number.isInteger(numDays)) throw new TypeError('numDays: Integer');

    if (numDays === 0) return _adjBusinessDay(this, date, -1);

    const dir = ((numDays > 0) ? 1 : -1);
    let busDay = date;

    for (let i = numDays; i !== 0; i -= dir) busDay = _adjBusinessDay(this, busDay.addDays(dir), dir);

    return busDay;
  },

  /**
     * Returns a date object that represents the previous
     * business day, regardless of whether <code>date</code>
     * is a business or not.
     *
     * @param date {lim.IDate}.
     * @return {lim.IDate}
     */
  previousBusinessDay(date) {
    return _adjBusinessDay(this, _validIDate(date).addDays(-1), -1);
  },

  /**
     * Returns a date object that represents the next
     * business day, regardless of whether <code>date</code>
     * is a business or not.
     *
     * @param date {lim.IDate}.
     * @return {lim.IDate}
     */
  nextBusinessDay(date) {
    return _adjBusinessDay(this, _validIDate(date).addDays(1), 1);
  },
};


const _fromDatesAndNames = function (datesAndNames) {
  const list = [];

  for (let i = 0, len = datesAndNames.length; i < len; i++) {
    try {
      const item = datesAndNames[i];
      const date = IDate.valueOf(item.date);
      const { name } = item;

      if (typeof name !== 'string') throw new TypeError('name: String');

      list.push(new Holiday(date, name));
    } catch (ex) {
      throw new Error(\`\${ex.toString() } (ref.: datesAndNames[\${ i }])\`);
    }
  }

  return new Holidays(list);
};

const _validHolidays = function (holidays) {
  if (!(holidays instanceof Holidays)) throw new TypeError('holidays: Holidays');

  return holidays;
};

const _fwdToInstance = function (methodName, origArgs) {
  const inst = _validHolidays(origArgs[0]);
  const args = Arrays.slice(origArgs, 1);

  return inst[methodName].apply(inst, args);
};

_.extend(Holidays, /** @lends morn.Holidays */ {

  /**
     * An immutable, empty Holidays instance.
     * @type {morn.Holidays}
     */
  EMPTY: new Holidays([]),

  /**
     * Creates a Holidays object from a list of IDate objects.
     * @param dates {lim.IDate[]}
     * @return {morn.Holidays}
     */
  datesOnly(dates) {
    if (!Arrays.isArrayOf(dates, IDate)) throw new TypeError('dates: lim.IDate[]');

    const list = [];
    for (let i = 0, len = dates.length; i < len; i++) list.push(new Holiday(dates[i], NO_NAME));

    return new Holidays(list);
  },

  /**
     * A named holiday.
     * @typedef {Object} morn.Holidays.NamedHoliday
     * @property {string} name - Name of the holiday.
     * @property {(string|lim.IDate)} date - Date at which the holiday is observed.
     */

  /**
     * Creates a Holidays object from a list of objects, where
     * each object contains <em>date</em> and <em>name</em> properties.
     *
     * @param datesAndNames {Array.<morn.Holidays.NamedHoliday>}
     * @return {morn.Holidays}
     */
  datesAndNames(datesAndNames) {
    if (!Arrays.isArrayOf(datesAndNames, Object)) throw new TypeError('datesAndNames: Array-of-Object');

    return _fromDatesAndNames(datesAndNames);
  },

  /**
     * Returns a holiday calendar.
     *
     * <p>
     *  Callers can limit the scope of the search by providing
     *  <code>start</code> and/or <code>end</code>.  Both arguments
     *  can be dates, strings or integers.
     * </p>
     *
     * <p>
     *  If strings, the supported formats
     *  are "M/d/yyyy", "yyyy-MM-dd", "yyyy-MM" or "yyyy".
     *  Using the latter two formats for <code>end</code>
     *  makes it exclusive; "2015-01-01" will not be included
     *  when <code>end == "2015"</code>.  Otherwise, <code>end</code>
     *  is inclusive (when provided.)
     * </p>
     *
     * <p>
     *  If integers, values must be 4-digit years, and the
     *  <code>end</code> year will be treated as inclusive.
     * </p>
     *
     * @param calendarName {string} Example: "NERC".
     * @param [start] {?(string|integer|lim.IDate)} The calendar's start date.
     * @param [end] {?(string|integer|lim.IDate)} The calendar's end date;
     *                             can be exclusive or inclusive depending on
     *                             the provided value (see method description).
     * @return {morn.Holidays}
     */
  getCalendar(calendarName, start, end) {
    if (!lim.String.isNonEmpty(calendarName)) throw new TypeError('calendarName: String, non-empty');

    const args = arguments;
    const numArgs = args.length;

    if (numArgs > 1) {
      start = _toDateString(start, 'start', false);
      if (numArgs > 2) end = _toDateString(end, 'end', true);
    }

    // const payloadText = getHolidayCalendar(calendarName,
    //   start,
    //   end);
    const payload = '';

    if (!Arrays.isArrayOf(payload, Object)) throw new Error('IllegalStateException: server response not an Array-of-Object');

    return _fromDatesAndNames(payload);
  },

  /**
     * Returns whether the current date
     * is a business day.  That is, a weekday
     * (Monday through Friday) that's not a holiday.
     *
     * @param holidays {morn.Holidays}.
     * @param date {lim.IDate}.
     * @return {boolean}
     */
  isBusinessDay(holidays, date) {
    return _fwdToInstance('isBusinessDay', arguments);
  },

  /**
     * <p>
     *  Adjusts the current date to find the closest
     *  adjacent business day.  If the current date
     *  is a business day, it is returned as is.
     * </p>
     *
     * <p>
     *  This method does not affect the <em>time</em>
     *  portion of the date object.
     * </p>
     *
     * @param holidays {morn.Holidays}.
     * @param date {lim.IDate}.
     * @param direction {integer} The direction in which
     *                  to look for a business day; <em>-1</em> to
     *                  look in the past, <em>1</em> to look toward
     *                  the future.
     * @return {lim.IDate}
     */
  adjustBusinessDay(holidays, date, direction) {
    return _fwdToInstance('adjustBusinessDay', arguments);
  },

  /**
     * Adds weekdays to the current date.  Holidays
     * are skipped over; they are not counted.
     *
     * @param holidays {morn.Holidays}.
     * @param date {lim.IDate}.
     * @param numDays {integer}.
     * @return {lim.IDate}
     */
  addBusinessDays(holidays, date, numDays) {
    return _fwdToInstance('addBusinessDays', arguments);
  },

  /**
     * Returns a date object that represents the previous
     * business day, regardless of whether <code>date</code>
     * is a business or not.
     *
     * @param holidays {morn.Holidays}.
     * @param date {lim.IDate}.
     * @return {lim.IDate}
     */
  previousBusinessDay(holidays, date) {
    return _fwdToInstance('previousBusinessDay', arguments);
  },

  /**
     * Returns a date object that represents the next
     * business day, regardless of whether <code>date</code>
     * is a business or not.
     *
     * @param holidays {morn.Holidays}.
     * @param date {lim.IDate}.
     * @return {lim.IDate}
     */
  nextBusinessDay(holidays, date) {
    return _fwdToInstance('nextBusinessDay', arguments);
  },
});

Object.freeze(Holidays);
Object.freeze(Holidays.prototype);


/* ****************************************************
    * PUBLIC OBJECTS
    * **************************************************** */
morn.Holidays = Holidays;

/* **********************************************
  * "import"
  * ********************************************** */
var Arrays     = lim.Arrays,
    IDate      = lim.IDate,
    TimeZone   = lim.TimeZone,
    DelimFile  = lim.DelimFile,
    Holidays   = morn.Holidays,
    Parameters = morn.Parameters;

/* **********************************************
  * Private variables
  * ********************************************** */
var RUN_DATE_PROP                = "formula.run_date",
    CORRECTION_DATE_PROP         =  "formula.corr_date",
    WORKFLOW_START_TIME_PROP     = "workflow.run.start_time",
    WORKFLOW_TIME_ZONE_PROP      = "workflow.time_zone",
    RUN_DATE_DAYS_OFFSET_PROP    = "formula.run_date.offset_days",
    RUN_DATE_IS_INTRADAY_PROP    = "formula.run_date.is_intraday",
    RUN_DATE_TIME_OF_DAY_PROP    = "formula.run_date.time_of_day",
    RUN_DATE_TOP_OF_HOUR_PROP    = "formula.run_date.top_of_the_hour",
    RUN_DATE_MINUTE_IN_HOUR_PROP = "formula.run_date.minute_in_hour",

    PEAK_START_DEFAULT = IDate.time( 7, 0),  // "7:00"
    PEAK_END_DEFAULT   = IDate.time(22, 0);  // "22:00"

/* **********************************************
  * Private functions
  * ********************************************** */

var _isVoid = function (obj) {
    return (   typeof obj === 'undefined'
            || obj === null );
};


/**
 * Validates \`arg\` to be an IDate instance.
 * If valid, the argument is returned. Otherwise this method throws.
 *
 * @param {lim.IDate} arg
 * @param {string} argName
 * @returns {lim.IDate}
 * @private
 */
var _validIDate = function (arg, argName) {

    if (!(arg instanceof IDate))
        throw new TypeError(argName + ": lim.IDate");

    return arg;
};

/**
 * Validates \`arg\` to be a Holidays instance.
 * If valid, the argument is returned.  Otherwise this method throws.
 * @param {morn.Holidays} arg
 * @param {string} argName
 * @returns {morn.Holidays}
 * @private
 */
var _validHolidayCalendar = function (arg, argName) {

    if (!(arg instanceof Holidays))
        throw new TypeError(argName + ": morn.Holidays");

    return arg;
};

/**
 * Validates \`arg\` to be a valid time of day.  Valid values are:
 * 1) integer value between 0 and (MILLIS_PER_DAY - 1);
 * 2) string value in the format of "HH:mm", "HH:mm:ss" or "HH:mm:ss.SSS".
 *
 * If valid, this method returns the millisecond-since-midnight value (integer).
 * Otherwise, this method throws.
 * @param {(Integer|string)} arg - Milliseconds-since-midnight, or human-readable string.
 * @param {string} argName
 * @returns {Integer}
 * @private
 */
var _toTimeOfDay = function (arg, argName) {

    if (   lim.Number.isNonNegativeInteger(arg)
        && arg < IDate.MILLIS_PER_DAY )
        return arg;

    else {
        var ms = IDate.stringToTime(arg);
        if (ms !== null)
            return ms;
        else
            throw new TypeError(argName + ": Integer (between 0 and MILLIS_PER_DAY - 1) or String (H:mm, H:mm:ss)");
    }
};

/**
 * Validates on/off peak hour counter arguments, and creates a UnitCalculator object for
 * returning the number of on- or off- peak hours.
 * @param {Arguments} args
 * @param {Function} callback
 * @returns {morn.UnitCalculator}
 * @private
 */
var _createPeakHourCounter = function (args, callback) {

    var peakStart = PEAK_START_DEFAULT,
        peakEnd   = PEAK_END_DEFAULT,
        holiCal   = Holidays.EMPTY,
        numArgs   = args.length;

    if (numArgs > 0) {
        peakStart = _toTimeOfDay(args[0], "peakStart");

        if (numArgs > 1) {
            peakEnd = _toTimeOfDay(args[1], "peakEnd");

            if (numArgs > 2)
                holiCal = _validHolidayCalendar(args[2], "holidayCalendar");
        }

        if (peakStart > peakEnd)
            throw new Error("IllegalArgumentException: peakStart is higher than peakEnd")
    }

    return function (startDate, endDate) {

        _validIDate(startDate, "startDate");
        _validIDate(endDate,   "endDate");

        return callback(startDate, endDate, peakStart, peakEnd, holiCal);
    };
};

/**
 * Validates on/off peak hour count arguments.
 * @param {Arguments} args
 * @param {Function} Callback to return the computation for peak hours (on or off).
 * @returns {Integer}
 * @private
 */
var _execPeakHourCount = function (args, callback) {

    var startDate = _validIDate(args[0], "startDate"),
        endDate   = _validIDate(args[1], "endDate"),

        numArgs   = args.length,
        peakStart = PEAK_START_DEFAULT,
        peakEnd   = PEAK_END_DEFAULT,
        holiCal   = Holidays.EMPTY;

    if (numArgs > 2) {
        peakStart = _toTimeOfDay(args[2], "peakStart");

        if (numArgs > 3) {
            peakEnd = _toTimeOfDay(args[3], "peakEnd");

            if (numArgs > 4)
                holiCal = _validHolidayCalendar(args[4], "holidayCalendar");
        }

        if (peakStart > peakEnd)
            throw new Error("IllegalArgumentException: peakStart is higher than peakEnd")
    }

    return callback(startDate, endDate, peakStart, peakEnd, holiCal);
};


var _clone = function (obj) {
    
    var t = typeof obj;
    
    if (   obj === null
        || t === 'string'
        || t === 'number'
        || t === 'boolean'
        || t === 'undefined' )
        return obj;
    
    else if (obj instanceof Date)
        return new Date(obj.getTime());
    
    else if (Object.isFrozen(obj))
        return obj;  // immutable object does not need to be cloned.
    
    else if (typeof obj.clone === 'function')
        return obj.clone();
    
    else {
        // Manual cloning - This only works if
        // constructor can execute without arguments.
        
        var clone = new obj.constructor();
        for (var prop in obj) {
            if (obj.hasOwnProperty(prop))
                clone[prop] = _clone(obj[prop]);
            
        }
        
        return clone;
    }
};


var _withZone = function (fnName, args) {
    
    var obj = args[0];
    
    if (obj instanceof morn.TimeSeries)
        return obj[fnName].apply(obj, Arrays.slice(args, 1));
    
    else if (   obj instanceof IDate
              || Arrays.isArrayOf(obj, IDate) )
        return IDate[fnName].apply(IDate, args);
    
    else
        throw new TypeError("obj: TimeSeries, IDate or Array-of-IDate");
};


var _tsMath = function (fn) {
    
    return function (numOrTs) {
        
        var applyTo = null;
        
        if (numOrTs instanceof morn.TimeSeries)
            applyTo = morn.TimeSeries;
        
        else if (typeof numOrTs === 'number')
            applyTo = lim.Math;
        
        else
            throw new TypeError("numOrTs: Number or TimeSeries");
        
        return applyTo[fn].apply(applyTo, arguments);
    };
    
};

var _weekday = function (holidaysIdx, fnHoliday, fnDate) {
    
    return function () {
        
        if (   arguments.length > holidaysIdx
            && arguments[holidaysIdx] !== null ) {
            
            /* Has \`holidays\` argument.  Forward call to
              * \`Holidays\` static method after moving
              * the \`holidays\` argument to the first
              * (arguments) position. */
            var args = Arrays.slice(arguments),
                tmp  = args.splice(holidaysIdx, 1)[0];
            
            args.unshift(tmp);
            
            return Holidays[fnHoliday].apply(Holidays, args);
        }
        
        else {
            // Forward call directly to \`IDate\` static method, as is.
            return IDate[fnDate].apply(IDate, arguments);
        }
    };
};

/**
 * Returns the number of whole hours between two instants.
 * @param {Integer} millisStart - Start time, in milliseconds.
 * @param {Integer} millisEnd - End time, in milliseconds.
 * @returns {Integer} Number of whole hours between two instants.
 * @private
 */
var _numFullHours = function (millisStart, millisEnd) {
    return Math.floor((millisEnd - millisStart) / IDate.MILLIS_PER_HOUR);
};

/**
 * Returns the number of whole peak hours between two dates. This method
 * does not return fractions of peak hours, only whole hours.
 * @param {lim.IDate} startDate
 * @param {lim.IDate} endDate
 * @param {Integer} peakStart
 * @param {Integer} peakEnd
 * @param {morn.Holidays} holidayCalendar
 * @returns {Integer}
 * @private
 */
var _numPeakHours = function (startDate, endDate, peakStart, peakEnd, holidayCalendar) {

    // Arguments have already been validated, all are provided.

    var start  = startDate,
        end    = endDate,
        numHrs = 0;

    if (start >= end)
        return numHrs;

    var startTime = start.getTimeMillis(),
        endTime   = end.getTimeMillis();

    if (startTime > 0) {  // not midnight?

        //  Add peak hours for current day, but only if it's a business day.
        if (   holidayCalendar.isBusinessDay(start)
            && startTime < peakEnd ) {
            numHrs += _numFullHours(Math.max(peakStart, startTime), peakEnd);
        }

        start = start.setTimeOfDay(0).addDays(1);
    }

    if (endTime > 0) {  // not midnight?

        // Add peak hours for last day, but only if it's a weekday
        if (   holidayCalendar.isBusinessDay(end)
            && endTime > peakStart ) {
            numHrs += _numFullHours(peakStart, Math.min(peakEnd, endTime));
        }

        end = end.setTimeOfDay(0);
    }

    end = end.addMilliseconds(-1);

    var numDays = IDate.countDays(IDate.WEEK_DAYS, start, end)
                - holidayCalendar.count(start, end, IDate.WEEK_DAYS);

    numHrs += (numDays * _numFullHours(peakStart, peakEnd));

    return numHrs;
};


/**
 * Returns the number of whole off-peak hours between two dates. This method
 * does not return fractions of off-peak hours, only whole hours.
 * @param {lim.IDate} startDate
 * @param {lim.IDate} endDate
 * @param {Integer} peakStart
 * @param {Integer} peakEnd
 * @param {morn.Holidays} holidayCalendar
 * @returns {Integer}
 * @private
 */
var _numOffPeakHours = function (startDate, endDate, peakStart, peakEnd, holidayCalendar) {

    return (  _numFullHours(startDate.getTime(), endDate.getTime())
            - _numPeakHours(startDate, endDate, peakStart, peakEnd, holidayCalendar) );
};

/**
 * A unit-calculator that returns the number of *full* hours between
 * two dates.
 * @type {morn.UnitCalculator}
 * @private
 */
var _numHourCounter = function (startDate, endDate) {

    _validIDate(startDate, "startDate");
    _validIDate(endDate,   "endDate");

    return _numFullHours(startDate.getTime(), endDate.getTime());
};


/**
 * Utility functions.
 * 
 * @namespace
 * @alias morn.Utils
 */
var Utils = {
    
    /**
     * Returns whether <code>obj</code> is <em>undefined</em>
     * or <em>null</em>.
     * 
     * @method
     * @param obj {*}
     * @return {boolean}
     */
    isVoid: _isVoid,
    
    /**
     * Returns a clone object of whatever is passed as
     * <code>obj</code>.  This method performs a deep copy.
     * 
     * @param obj {*}
     * @return {*}
     * @throws Error - If the object is mutable, does not have a
     *                 <code>clone()</code> method, and has a constructor
     *                 that requires arguments.
     */
    clone: _clone,
    
    /**
     * <p>
     *  Moves dates to the given time zone,
     *  preserving their millisecond instant.
     * </p>
     * 
     * <p>
     *  This method changes the time zone but does not change the
     *  millisecond instant, with the effect that the values -
     *  day-of-month, hour, etc. - usually change.
     * </p>
     * 
     * @param {(morn.TimeSeries|lim.IDate|lim.IDate[])} obj
     * @param {(lim.TimeZone|string)} tz
     * @return {(morn.TimeSeries|lim.IDate|lim.IDate[])}
     * 
     * @see lim.IDate.withZone
     * @see morn.TimeSeries.withZone
     */
    withZone: function (obj, tz) {
        return _withZone('withZone', arguments);
    },
    
    /**
     * <p>
     *  Moves dates to the given time zone,
     *  preserving the values (day-of-month, hour, etc.)
     * </p>
     * 
     * <p>
     *  This method changes the time zone and the millisecond instant to
     *  preserve the values. 
     * </p>
     * 
     * @param {(morn.TimeSeries|lim.IDate|lim.IDate[])} obj
     * @param {(lim.TimeZone|string)} tz
     * @param {boolean} [fixDst=false] - When giving a time zone to a list of dates, gaps
     *                                   and overlaps can appear due to DST changes.
     *                                   Set <code>fixDst</code> to true to auto-fill the gaps and
     *                                   remove the overlaps.
     *
     *                                   When <code>obj</code> is a list of dates, this option requires
     *                                   the list of dates to be sorted chronologically.
     *
     *                                   When <code>obj</code> is a time-series, gaps are filled
     *                                   using values from the repeating hour(s) on the wall clock.
     * @return {(morn.TimeSeries|lim.IDate|lim.IDate[])}
     * 
     * @see lim.IDate.withZoneRetainFields
     * @see morn.TimeSeries.withZoneRetainFields
     */
    withZoneRetainFields: function (obj, tz, fixDst) {
        return _withZone('withZoneRetainFields', arguments);
    },
    
    /**
     * Returns the date (or date-time) associated with this script
     * execution.  Typically, when running ad-hoc scripts this return
     * today's date (at midnight).  Scripts executed from the workflow
     * manager may run with different <em>run date</em> based on
     * the workflow <em>start time</em> and <em>time zone</em>.
     * @return {lim.IDate}
     */
    getRunDate: function () {
        if (Parameters.contains(RUN_DATE_PROP)){
            return Parameters.getDate(RUN_DATE_PROP);
        }
        if (Parameters.contains(CORRECTION_DATE_PROP)){
            return Parameters.getDate(CORRECTION_DATE_PROP);
        }


        var tz = TimeZone.get(  (Parameters.contains(WORKFLOW_TIME_ZONE_PROP))
                              ? Parameters.getString(WORKFLOW_TIME_ZONE_PROP)
                              : "America/New_York" );

        /** @type lim.IDate */
        var runDt = (  (Parameters.contains(WORKFLOW_START_TIME_PROP))
                      ? Parameters.getDate(WORKFLOW_START_TIME_PROP)
                      : IDate.timeNow(tz) );

        runDt = runDt.addDays(Parameters.getInt(RUN_DATE_DAYS_OFFSET_PROP, 0), tz);

        if (!Parameters.getBool(RUN_DATE_IS_INTRADAY_PROP, false)) {

            /* daily data (the most common case):
              *  - set date to midnight;
              *  - drop time-zone info to help with "daily" data requests. */

            runDt = runDt.setTimeOfDay(0).noZone();
        }

        // Intraday logic below

        else if (Parameters.contains(RUN_DATE_TIME_OF_DAY_PROP)) {

            var timeOfDay       = Parameters.getString(RUN_DATE_TIME_OF_DAY_PROP),
                timeOfDayMillis = IDate.stringToTime(timeOfDay);

            if (timeOfDayMillis !== null)
                runDt = runDt.setTimeOfDay(timeOfDayMillis).withZoneRetainFields(tz);
        }

        else if (Parameters.contains(RUN_DATE_TOP_OF_HOUR_PROP)) {

            var hr      = runDt.getHours(),
                topOf   = Parameters.getInt(RUN_DATE_TOP_OF_HOUR_PROP),
                minInHr = Parameters.getInt(RUN_DATE_MINUTE_IN_HOUR_PROP, 0);

            if (topOf < 0)
                topOf = Math.abs(topOf);
            else if (topOf === 0)
                topOf = 1;

            runDt = runDt.setTimeOfDay(hr - (hr % topOf), minInHr);
        }

        return runDt;
    },
    
    /**
     * Convert to CSV format.
     * 
     * The main purpose of this method is to make it easy(ier) to avoid
     * IEEE-754 adjustment issues.
     * 
     * @param obj {(morn.TimeSeries|Array|Array[])}
     * @return {string}
     */
    toCsv: function (obj) {
        
        if (obj instanceof morn.TimeSeries)
            return obj.toCsv.apply(obj, Arrays.slice(arguments, 1));
        
        else if (obj instanceof Array) {
            
            var delimFile = new DelimFile(),
                len       = obj.length;
            
            if (Arrays.isArrayOf(obj, Array)) {
                // 2D array
                
                for (var i = 0; i < len; i++)
                    delimFile.addRow.apply(delimFile, obj[i]);
            }
            else {
                for (var i = 0; i < len; i++)
                    delimFile.addRow.apply(delimFile, [obj[i]]);
            }
            
            return delimFile.toCsv();
        }
        
        else
            throw new TypeError("obj: TimeSeries, Array or Array-of-Array");
    },
    
    /**
     * Convert to JSON format.
     * 
     * The main purpose of this method is to make it easy(ier) to avoid
     * IEEE-754 adjustment issues.
     * 
     * @param obj {(morn.TimeSeries|Object)}
     * @return {string}
     */
    toJson: function (obj) {
        
        if (obj instanceof morn.TimeSeries)
            return obj.toJsonString.apply(obj, Arrays.slice(arguments, 1));
        
        else if (typeof obj === 'undefined')
            return 'undefined';
        
        else {
            return JSON.stringify(obj, function (name, value) {
                if (   lim.Number.isNumber(value)  // is a number
                    && value % 1 !== 0 ) {         // is a float
                    value = lim.Math.round(value, lim.Number.precision(value));
                }
                
                return value;
            });
        }
    },
    
    /**
     * Rounds to the specified number of decimals.
     * 
     * This method accepts Number and TimeSeries as its first argument.
     * 
     * Remarks:
     * <ul>
     *  <li>
     *   If <code>numDecimals</code> is greater than 0 (zero), then the number
     *   is rounded to the specified number of decimal places.
     *  </li>
     *  <li>
     *   If <code>numDecimals</code> is 0, the number is rounded to the
     *   nearest integer.
     *  </li>
     *  <li>
     *   If <code>numDecimals</code> is less than 0 (zero), the number
     *   is rounded to the left of the decimal point.
     *  </li>
     *  
     *  <li> To always round up (away from zero), use <code>{@link morn.Utils.roundUp|roundUp}</code>. </li>
     *  <li> To always round down (torward zero), use <code>{@link morn.Utils.roundDown|roundDown}</code>. </li>
     * </ul>
     * 
     * 
     * @method
     * @param numOrTs {(number|morn.TimeSeries)}
     * @param numDecimals {integer}
     * @param [tag] {string} Only applied when <code>numOrTs</code> is
     *                     a TimeSeries, and even then it's still optional.
     * @return {(number|morn.TimeSeries)} The return type matches that of <code>numOrTs</code>.
     * 
     * @see lim.Math.round
     * @see morn.TimeSeries.round
     */
    round: _tsMath('round'),
    
    /**
     * Rounds a number up, away from 0 (zero).
     * 
     * This method accepts Number and TimeSeries as its first argument.
     * 
     * @method
     * @param numOrTs {(number|morn.TimeSeries)}
     * @param numDecimals {integer}
     * @param [tag] {string} Only applied when <code>numOrTs</code> is
     *                     a TimeSeries, and even then it's still optional.
     * @return {(number|morn.TimeSeries)} The return type matches that of <code>numOrTs</code>.
     * 
     * @see lim.Math.roundUp
     * @see morn.TimeSeries.roundUp
     */
    roundUp: _tsMath('roundUp'),
    
    /**
     * Rounds a number down, toward 0 (zero).
     * 
     * This method accepts Number and TimeSeries as its first argument.
     * 
     * @method
     * @param numOrTs {(number|morn.TimeSeries)}
     * @param numDecimals {integer}
     * @param [tag] {string} Only applied when <code>numOrTs</code> is
     *                     a TimeSeries, and even then it's still optional.
     * @return {(number|morn.TimeSeries)} The return type matches that of <code>numOrTs</code>.
     * 
     * @see lim.Math.roundDown
     * @see morn.TimeSeries.roundDown
     */
    roundDown: _tsMath('roundDown'),
    
    /**
     * Rounds a number down.  If the number is positive, this
     * method rounds towards 0 (zero).  If number is negative,
     * this method rounds away from 0.
     * 
     * This method accepts Number and TimeSeries as its first argument.
     * 
     * @method
     * @param numOrTs {(number|morn.TimeSeries)}
     * @param numDecimals {integer}
     * @param [tag] {string} Only applied when <code>numOrTs</code> is
     *                     a TimeSeries, and even then it's still optional.
     * @return {(number|morn.TimeSeries)} The return type matches that of <code>numOrTs</code>.
     * 
     * @see lim.Math.floor
     * @see morn.TimeSeries.floor
     */
    floor: _tsMath('floor'),
    
    /**
     * Rounds a number up.  If the number is positive, this
     * method rounds away from 0 (zero).  If number is negative,
     * this method rounds toward 0.
     * 
     * This method accepts Number and TimeSeries as its first argument.
     * 
     * @method
     * @param numOrTs {(number|morn.TimeSeries)}
     * @param numDecimals {integer}
     * @param [tag] {string} Only applied when <code>numOrTs</code> is
     *                     a TimeSeries, and even then it's still optional.
     * @return {(number|morn.TimeSeries)} The return type matches that of <code>numOrTs</code>.
     * 
     * @see lim.Math.ceil
     * @see morn.TimeSeries.ceil
     */
    ceil: _tsMath('ceil'),
    
    /**
     * Returns whether the current date
     * is a business day.  That is, a weekday
     * (Monday through Friday) that's not a holiday (if provided).
     * 
     * @method
     * @param date {lim.IDate}
     * @param [holidays] {?morn.Holidays} A holiday calendar.
     * @return {boolean}
     * 
     * @see morn.Holidays.isBusinessDay
     * @see lim.IDate.isWeekday
     */
    isBusinessDay: _weekday(1, 'isBusinessDay', 'isWeekday'),
    
    /**
     * <p>
     *  Adjusts the current date to find the closest
     *  adjacent business day.  If the current date
     *  is a business day, it is returned as is.
     * </p>
     * 
     * <p>
     *  This method does not affect the <em>time</em>
     *  portion of the date object.
     * </p>
     * 
     * @method
     * @param date {lim.IDate}
     * @param direction {integer} The direction in which
     *                  to look for a business day; <em>-1</em> to
     *                  look in the past, <em>1</em> to look toward
     *                  the future.
     * @param [holidays] {?morn.Holidays} A holiday calendar.
     * @return {lim.IDate}
     * 
     * @see morn.Holidays.adjustBusinessDay
     * @see lim.IDate.adjustWeekday
     */
    adjustBusinessDay: _weekday(2, 'adjustBusinessDay', 'adjustWeekday'),
    
    /**
     * Adds weekdays to the current date.  Holidays (if provided)
     * are skipped over; they are not counted.
     *
     * @method
     * @param date {lim.IDate}
     * @param numDays {integer}
     * @param [holidays] {?morn.Holidays} A holiday calendar.
     * @return {lim.IDate}
     * 
     * @see morn.Holidays.addBusinessDays
     * @see lim.IDate.addWeekdays
     */
    addBusinessDays: _weekday(2, 'addBusinessDays', 'addWeekdays'),
    
    /**
     * Returns a date object that represents the previous
     * business day, regardless of whether <code>date</code>
     * is a business or not.
     * 
     * @method
     * @param date {lim.IDate}
     * @param [holidays] {?morn.Holidays} A holiday calendar.
     * @return {lim.IDate}
     * 
     * @see morn.Holidays.previousBusinessDay
     * @see lim.IDate.previousWeekday
     */
    previousBusinessDay: _weekday(1, 'previousBusinessDay', 'previousWeekday'),
    
    /**
     * Returns a date object that represents the next
     * business day, regardless of whether <code>date</code>
     * is a business or not.
     * 
     * @method
     * @param date {lim.IDate}
     * @param [holidays] {?morn.Holidays} A holiday calendar.
     * @return {lim.IDate}
     * 
     * @see morn.Holidays.nextBusinessDay
     * @see lim.IDate.nextWeekday
     */
    nextBusinessDay: _weekday(1, 'nextBusinessDay', 'nextWeekday'),

    /**
     * Returns the number of full hours between two dates.
     * @param {lim.IDate} startDate
     * @param {lim.IDate} endDate
     * @returns {Integer} Number of whole/complete hours - fractions are ignored.
     * @method
     */
    numFullHours: _numHourCounter,

    /**
     * Returns the number of peak hours between two dates.
     *
     * This method treats weekend days as off-peak days. If a holiday calendar
     * is provided, holidays are also treated as off-peak days.
     *
     * The peak period is defined by its start (inclusive) and end (exclusive) times.
     * Peak times can be provided in millisecond values (integer) or human-readable
     * strings (H:mm, H:mm:ss, H:mm:ss.SSS).
     *
     * @param {lim.IDate} startDate
     * @param {lim.IDate} endDate
     * @param {(Integer|string)} [peakStart="7:00"] - Time at which the peak period starts (inclusive),
     *        as milliseconds since midnight (integer) or human-readable time values (H:mm, H:mm:ss,
     *        or H:mm:ss.SSS).
     * @param {(Integer|string)} [peakEnd="22:00"] - Time at which the peak period ends (exclusive),
     *        as milliseconds since midnight (integer) or human-readable time values (H:mm, H:mm:ss,
     *        or H:mm:ss.SSS).
     * @param {morn.Holidays} [holidayCalendar] Holiday calendar, to better handling of off-peak days.
     * @returns {Integer} Number of whole peak hours - fractions are ignored.
     */
    numPeakHours: function (startDate, endDate, peakStart, peakEnd, holidayCalendar) {
        return _execPeakHourCount(arguments, _numPeakHours);
    },

    /**
     * Returns the number of off-peak hours between two dates.
     *
     * This method treats weekend days as off-peak days. If a holiday calendar
     * is provided, holidays are also treated as off-peak days.
     *
     * The peak period is defined by its start (inclusive) and end (exclusive) times.
     * Peak times can be provided in millisecond values (integer) or human-readable
     * strings (H:mm, H:mm:ss, H:mm:ss.SSS).
     *
     * @param {lim.IDate} startDate
     * @param {lim.IDate} endDate
     * @param {(Integer|string)} [peakStart="7:00"] - Time at which the peak period starts (inclusive),
     *        as milliseconds since midnight (integer) or human-readable time values (H:mm, H:mm:ss,
     *        or H:mm:ss.SSS).
     * @param {(Integer|string)} [peakEnd="22:00"] - Time at which the peak period ends (exclusive),
     *        as milliseconds since midnight (integer) or human-readable time values (H:mm, H:mm:ss,
     *        or H:mm:ss.SSS).
     * @param {morn.Holidays} [holidayCalendar] Holiday calendar, to better handling of off-peak days.
     * @returns {Integer} Number of whole peak hours - fractions are ignored.
     */
    numOffPeakHours: function (startDate, endDate, peakStart, peakEnd, holidayCalendar) {
        return _execPeakHourCount(arguments, _numOffPeakHours);
    },


    /**
     * Creates a {@link morn.UnitCalculator|UnitCalculator} callback that
     * returns the number of hours between two dates.
     *
     * @returns {morn.UnitCalculator}
     */
    newHourCounter: function () {
        return _numHourCounter;
    },

    /**
     * Creates a {@link morn.UnitCalculator|UnitCalculator} callback that
     * returns the number of peak hours between two dates.
     *
     * This method treats weekend days as off-peak days. If a holiday calendar
     * is provided, holidays are also treated as off-peak days.
     *
     * The peak period is defined by its start (inclusive) and end (exclusive) times.
     * Peak times can be provided in millisecond values (integer) or human-readable
     * strings (H:mm, H:mm:ss, H:mm:ss.SSS).
     *
     * @param {(Integer|string)} [peakStart="7:00"] - Time at which the peak period starts (inclusive),
     *        as milliseconds since midnight (integer) or human-readable time values (H:mm, H:mm:ss,
     *        or H:mm:ss.SSS).
     * @param {(Integer|string)} [peakEnd="22:00"] - Time at which the peak period ends (exclusive),
     *        as milliseconds since midnight (integer) or human-readable time values (H:mm, H:mm:ss,
     *        or H:mm:ss.SSS).
     * @param {morn.Holidays} [holidayCalendar] Holiday calendar, to better handling of off-peak days.
     * @returns {morn.UnitCalculator}
     */
    newPeakHourCounter: function (peakStart, peakEnd, holidayCalendar) {
        return _createPeakHourCounter(arguments, _numPeakHours);
    },

    /**
     * Creates a {@link morn.UnitCalculator|UnitCalculator} callback that
     * returns the number of off-peak hours between two dates.
     *
     * This method treats weekend days as off-peak days. If a holiday calendar
     * is provided, holidays are also treated as off-peak days.
     *
     * The peak period is specified using the peak-period's start time (inclusive) and
     * its end time (exclusive).
     *
     * Times are provided in millisecond values (integer)
     * or human-readable strings (H:mm, H:mm:ss, H:mm:ss.SSS).
     *
     * @param {(Integer|string)} [peakStart="7:00"] - Time at which the peak period starts (inclusive),
     *        as milliseconds since midnight (integer) or human-readable time values (H:mm, H:mm:ss,
     *        or H:mm:ss.SSS).
     * @param {(Integer|string)} [peakEnd="22:00"] - Time at which the peak period ends (exclusive),
     *        as milliseconds since midnight (integer) or human-readable time values (H:mm, H:mm:ss,
     *        or H:mm:ss.SSS).
     * @param {morn.Holidays} [holidayCalendar] Holiday calendar, to better handling of off-peak days.
     * @returns {morn.UnitCalculator}
     */
    newOffPeakHourCounter: function (peakStart, peakEnd, holidayCalendar) {
        return _createPeakHourCounter(arguments, _numOffPeakHours);
    }
};


/* *******************************************
  * PUBLIC OBJECT
  * ******************************************* */

morn.Utils = Object.freeze(Utils);

/* *******************************************
  * "import"
  * ******************************************* */
var TimeZone = lim.TimeZone,
    IDate    = lim.IDate,
    Iterator = lim.Iterator,
    Holidays = morn.Holidays;


/* *******************************************
  * Private functions
  * ******************************************* */

var _returnTrue = function () {
    return true;
};

/**
 * Returns an array of dates created at given millisecond intervals,
 * between \`startDate\` and \`endDate\`.
 * @param {(lim.IDate|string)} startDate
 * @param {(lim.IDate|string)} endDate
 * @param {integer} millisInterval
 * @param {(lim.TimeZone|string)} [timeZone]
 * @returns {lim.IDate[]}
 * @private
 */
var _getIntervals = function (startDate, endDate, millisInterval, timeZone) {
    
    var run  = IDate.valueOf(startDate),
        end  = IDate.valueOf(endDate),
        list = [];
    
    if (!lim.Number.isPositiveInteger(millisInterval))
        throw new TypeError("millisInterval: Integer, positive");

    // Validate \`timeZone\` early, before going into potentially long loop.
    var tz = ((arguments.length > 3) ? TimeZone.get(timeZone) : null);

    while (run <= end) {
        list.push(run);
        run = run.addMilliseconds(millisInterval);
    }

    if (tz !== null)
        list = IDate.withZone(list, tz);

    return list;
};


var _getDays = function (startDate, endDate, filter) {
    
    var run  = IDate.valueOf(startDate),
        end  = IDate.valueOf(endDate),
        list = [];
    
    while (run <= end) {
        if (filter(run))
            list.push(run);
        run = run.addDays(1);
    }
    
    return list;
};


var _getFullWeeks = function (startDate, endDate) {
    return _getDays(startDate, endDate, _returnTrue);
};

var _getWeekdays = function (startDate, endDate, holidays) {
    
    if (arguments.length < 3)
        holidays = Holidays.EMPTY;
    
    else if (!(holidays instanceof Holidays))
        throw new TypeError("holidays: Holidays");
    
    return _getDays(startDate, endDate, function (date) {
        var dow = date.getDay();
        return (   dow > 0
                && dow < 6
                && !holidays.isHoliday(date) );
    });
    
};

var _getMonths = function (startDate, endDate, step) {
    
    var run  = IDate.valueOf(startDate),
        end  = IDate.valueOf(endDate),
        list = [];
    
    if (arguments.length < 3)
        step = 1;
    
    else if (!lim.Number.isPositiveInteger(step))
        throw new TypeError("step: Integer, positive");
    
    while (run <= end) {
        list.push(run);
        run = run.addMonths(step);
    }
    
    return list;
};


/* *******************************************
  * Class: Calendar
  * ******************************************* */

/**
 * Calendar constructor takes an array of IDate
 * and provides methods to make decisions based
 * on the dates within that calendar.
 * 
 * This constructor freezes the array argument.
 * 
 * @constructor
 * @alias morn.Calendar
 * @param dates {lim.IDate[]}
 */
var Calendar = function (dates) {
    
    if (!lim.Arrays.isArrayOf(dates, IDate))
        throw new TypeError("dates: IDate[]");
    
    this._dates = Object.freeze(dates);
    
    Object.freeze(this);
};


//Current hour setter of the calendar
Calendar.prototype = /** @lends morn.Calendar.prototype */ {
    constructor: Calendar,
        
    /**
     * Returns an Iterator of dates in this calendar.
     * @return {lim.Iterator} Iterator of {@link lim.IDate}.
     */
    iterator: function () {
        return new Iterator(this._dates);
    },
    
    /**
     * Returns an Array of dates in this calendar.
     * The array is immutable.
     * 
     * @return {lim.IDate[]} The returned array is frozen.
     */
    dates: function () {
        return this._dates;
    }
};

/* *******************************************
  * PUBLIC STATIC METHODS
  * ******************************************* */


_.extend(Calendar, /** @lends morn.Calendar */ {
    
    /**
     * Returns an array of dates representing monthly intervals.
     * Which day-of-month is used depends on <code>start</code>.
     * 
     * @param start {(lim.IDate|string)} The first date in the array.
     * @param end {(lim.IDate|string)} The highest possible date in the array.
     *                               The array might end earlier if the steps
     *                               don't land exactly on <code>end</code>.
     * @param [step=1] {integer} Provide this argument to skip every other
     *                           month, every three months, etc.
     * @return {lim.IDate[]}
     */
    monthlyDates: function (start, end, step) {
        return _getMonths.apply(Calendar, arguments);
    },
    
    /**
     * Returns a Calendar instance representing monthly intervals.
     * Which day-of-month is used depends on <code>start</code>.
     * 
     * @param start {(lim.IDate|string)} The first date in the calendar.
     * @param end {(lim.IDate|string)} The highest possible date in the calendar.
     *                               The calendar might end earlier if the steps
     *                               don't land exactly on <code>end</code>.
     * @param [step=1] {integer} Provide this argument to skip every other
     *                           month, every three months, etc.
     * @return {morn.Calendar}
     */
    monthly: function (start, end, step) {
        return new Calendar(_getMonths.apply(Calendar, arguments));
    },

    /**
     * Returns an array of dates representing quarterly intervals.
     * Which months and day-of-month are used depends on <code>start</code>.
     * 
     * @param start {(lim.IDate|string)} The first date in the array.
     * @param end {(lim.IDate|string)} The highest possible date in the array.
     *                               The array might end earlier if the steps
     *                               don't land exactly on <code>end</code>.
     * @return {lim.IDate[]}
     */
    quarterlyDates: function (start, end) {
        return _getMonths(start, end, 3);
    },
    /**
     * Returns a Calendar instance representing quarterly intervals.
     * Which months and day-of-month are used depends on <code>start</code>.
     * 
     * @param start {(lim.IDate|string)} The first date in the calendar.
     * @param end {(lim.IDate|string)} The highest possible date in the calendar.
     *                               The calendar might end earlier if the steps
     *                               don't land exactly on <code>end</code>.
     * @return {morn.Calendar}
     */
    quarterly: function (start, end) {
        return new Calendar(_getMonths(start, end, 3));
    },
    
    /**
     * Returns an array of dates representing annual intervals.
     * Which month and day-of-month are used depends on <code>start</code>.
     * 
     * @param start {(lim.IDate|string)} The first date in the array.
     * @param end {(lim.IDate|string)} The highest possible date in the array.
     *                               The array might end earlier if the steps
     *                               don't land exactly on <code>end</code>.
     * @return {lim.IDate[]}
     */
    annualDates: function (start, end) {
        return _getMonths(start, end, 12);
    },
    /**
     * Returns a Calendar instance representing annual intervals.
     * Which months and day-of-month are used depends on <code>start</code>.
     * 
     * @param start {(lim.IDate|string)} The first date in the calendar.
     * @param end {(lim.IDate|string)} The highest possible date in the calendar.
     *                               The calendar might end earlier if the steps
     *                               don't land exactly on <code>end</code>.
     * @return {morn.Calendar}
     */
    annual: function (start, end) {
        return new Calendar(_getMonths(start, end, 12));
    },
    
    
    /**
     * Returns an array of dates representing daily intervals.
     * Which hour, minutes, seconds are used depends on <code>start</code>.
     * 
     * @param start {(lim.IDate|string)} The first date in the array.
     * @param end {(lim.IDate|string)} The highest possible date in the array.
     *                               The array might end earlier if the steps
     *                               don't land exactly on <code>end</code>.
     * @return {lim.IDate[]}
     */
    dailyDates: function (start, end) {
        return _getFullWeeks(start, end);
    },
    
    /**
     * Returns a calendar instance representing daily intervals.
     * Which hour, minutes, seconds are used depends on <code>start</code>.
     * 
     * @param start {(lim.IDate|string)} The first date in the calendar.
     * @param end {(lim.IDate|string)} The highest possible date in the calendar.
     *                               The array might end earlier if the steps
     *                               don't land exactly on <code>end</code>.
     * @return {morn.Calendar}
     */
    daily: function (start, end) {
        return new Calendar(_getFullWeeks(start, end));
    },
    
    /**
     * Returns an array of dates representing daily intervals, for
     * week days only.
     * Which hour, minutes, seconds are used depends on <code>start</code>.
     * 
     * @param start {(lim.IDate|string)} The first date in the array.
     * @param end {(lim.IDate|string)} The highest possible date in the array.
     *                               The array might end earlier if the steps
     *                               don't land exactly on <code>end</code>.
     * @param [holidays] {morn.Holidays} If provided, the returned array
     *                               does not contain any dates for the
     *                               listed holidays.
     * @return {lim.IDate[]}
     */
    weekdaysDates: function (start, end, holidays) {
        return _getWeekdays.apply(Calendar, arguments);
    },
    /**
     * Returns calendar instance representing daily intervals, for
     * week days only.
     * Which hour, minutes, seconds are used depends on <code>start</code>.
     * 
     * @param start {(lim.IDate|string)} The first date in the calendar.
     * @param end {(lim.IDate|string)} The highest possible date in the calendar.
     *                               The array might end earlier if the steps
     *                               don't land exactly on <code>end</code>.
     * @param [holidays] {morn.Holidays} If provided, the returned
     *                               calendar does not contain any dates
     *                               for the listed holidays.
     * @return {morn.Calendar}
     */
    weekdays: function (start, end, holidays) {
        return new Calendar(_getWeekdays.apply(Calendar, arguments));
    },
    
    /**
     * Returns an array of dates representing intervals for every given
     * millisecond interval.
     * 
     * @param {(lim.IDate|string)} start - The first date in the array.
     * @param {(lim.IDate|string)} end - The highest possible date in the array.
     *                               The array might end earlier if the steps
     *                               don't land exactly on <code>end</code>.
     * @param {integer} millisInterval - Positive. The gap between intervals,
     *                                 in milliseconds.
     * @param {(imeZone|string)} [timeZone] - Time zone in which the resulting dates
     *                                        should be set to, for when DST changes
     *                                        must be respected.
     * @return {lim.IDate[]}
     */
    intervalDates: function (start, end, millisInterval, timeZone) {
        return _getIntervals.apply(this, arguments);
    },
    
    /**
     * Returns a calendar instance representing intervals for every given
     * millisecond interval.
     * 
     * @param {(lim.IDate|string)} start - The first date in the calendar.
     * @param {(lim.IDate|string)} end - The highest possible date in the calendar.
     *                               The array might end earlier if the steps
     *                               don't land exactly on <code>end</code>.
     * @param {integer} millisInterval - Positive. The gap between intervals,
     *                                 in milliseconds.
     * @param {(TimeZone|string)} [timeZone] - Time zone in which the resulting Calendar
     *                                        should be set to, for when DST changes
     *                                        must be respected.
     * @return {morn.Calendar}
     */
    interval: function (start, end, millisInterval, timeZone) {
        return new Calendar(_getIntervals.apply(this, arguments));
    },
    
    /**
     * An immutable, empty Calendar.
     * @type {morn.Calendar}
     */
    EMPTY: new Calendar([])
    
});


Object.freeze(Calendar);
Object.freeze(Calendar.prototype);

/* ************************************************
  * PUBLIC CLASSES
  * ************************************************ */
morn.Calendar = Calendar;

/* **********************************************
  * Interfaces
  * ********************************************** */

/**
 * @typedef Object morn.ContractValue.RawContractValue
 * @property {string} expirationDate Expiration date, "yyyy-MM-dd" or "yyyy-MM-ddTHH:mm".
 * @property {string} deliveryStartDate Delivery start, "yyyy-MM-dd" or "yyyy-MM-ddTHH:mm".
 * @property {string} deliveryEndDate Delivery end, "yyyy-MM-dd" or "yyyy-MM-ddTHH:mm".
 * @property {string} [deliveryType] Delivery type, may be missing or useless.
 * @property {Array.<Object.<string, string>>} keys Array of single-property objects.
 * @property {string} col Column name.
 * @property {string} value Likely a stringified number.
 * @property {string} insertTime "yyyy-MM-ddTHH:mm:ss.SSSZZ"
 */

/* **********************************************
  * "import"
  * ********************************************** */
var IDate        = lim.IDate,
    Arrays       = lim.Arrays,
    Logger       = lim.Logger,
    DeliveryType = morn.DeliveryType;

/* **********************************************
  * Private members
  * ********************************************** */

var _canConstructContract = false;
var _canConstructContractValue = false;
/*
    Invalidating _cacheContracts and _cacheCurveComp after a certain size is necessary for avoiding
    OOM. We keep calculating size of cache every time and element is added and then delete defined number
    of elements after a defined size.
  */
var _cacheContracts = {};
var _cacheContractsSize = 0;
var _cacheContractsKeys = [];
var _cacheCurveComp = {};
var _cacheCurveCompSize = 0;
var _cacheCurveCompKeys = [];
var MAX_CACHE_SIZE = 524288000; // 500 MB
var REDUCE_CACHE_BY = 4  ;// reduce by 1/4

/* **********************************************
  * Private methods - independent
  * ********************************************** */
var invalidateCache = function (cacheObj, cacheKeys) {
    var maxDelete = Math.floor(cacheKeys/REDUCE_CACHE_BY) ;
    try {
        for (var i = 0; i < maxDelete; i++) {
            delete cacheObj[cacheKeys[i]];
        }
        cacheKeys.splice(0,maxDelete);
    } catch (e ) {
        Logger.debug("Error while deleting cache elements _cacheContracts size {} _CacheCurveComp size {} ",_cacheContractsSize,_cacheCurveCompSize );
    }
};
var _isStringDateOrNull = function (arg) {
    return (   typeof arg === 'string'
        || arg === null
        || arg instanceof IDate
        || Arrays.isArrayOf(arg, 'string') );
};

var _forceString = function (s) {

    if (typeof s === 'string')
        return s;

    else if (s === null)
        return "null";

    else if (s instanceof IDate)
        return s.getTime().toString(10);

    else if (Arrays.isArrayOf(s, 'string'))
        return s.join(',');

    else
        throw new TypeError("s: String or null");
};

var _validFeed = function (feed) {

    if (!lim.String.isNonEmpty(feed))
        throw new TypeError("feed: String, non-empty");

    return feed;
};

/**
 * Validates \`arg\` to be a non-empty string.
 * @param {string} arg
 * @param {string} argName
 * @returns {string} \`arg\`.
 * @throws TypeError - If \`arg\` is not valid.
 * @private
 */
var _validStringNE = function (arg, argName) {

    if (!lim.String.isNonEmpty(arg))
        throw new TypeError(argName + ": String, non-empty");

    return arg;
};


var _validRoot = function (root) {
    return _validStringNE(root, "root");
};

/**
 * Validates \`roots\` to be a non-empty string or array of non-empty strings.
 * If valid, this method returns an array of string (if \`roots\` as a string,
 * it is split into an array.)
 * @param {(string|string[])} roots
 * @returns {string[]}
 * @throws TypeError - If \`roots\` is not valid.
 * @private
 */
var _validRoots = function (roots) {

    if (lim.String.isNonEmpty(roots))
        roots = lim.String.split(roots, new RegExp("\\\\s*,+\\\\s*", "g"));  // convert to array;

    if (!Arrays.isValid(roots, lim.String.isNonEmpty))
        throw new TypeError("roots: String, or String[]");

    return roots;
};

var _validColumn = function (column) {
    return _validStringNE(column, "column");
};

/**
 * Validates an argument to be one or more columns,
 * always returns the columns as an array.
 *
 * This method differs from others in that strings
 * are taken literally, for backward compatibility;
 * it doesn't support comma-separated list of columns.
 *
 * @param {(string|string[])} cols
 * @private
 */
var _validCols = function (cols) {

    if (lim.String.isNonEmpty(cols))
        return [ cols ];

    else if (Arrays.isValid(cols, lim.String.isNonEmpty))
        return cols;

    else
        throw new TypeError("cols: String or String[], non-empty");
};

/**
 * Validates that \`date\` represents a date value.
 * If valid, this method returns \`date\`.  Otherwise it throws.
 * @param {(string|lim.IDate)} date
 * @returns {lim.IDate}
 * @private
 */
var _validDate = function (date) {
    return IDate.valueOf(date);
};

/**
 * Returns the string representation of the given date, as "yyyy-MM-dd".
 * @param {lim.IDate} date
 * @returns {string} "yyyy-MM-dd"
 * @private
 */
var _toIsoDate = function (date) {
    return date.format("yyyy-MM-dd");
};

/**
 * Returns a valid date, converted to string formatted "yyyy-MM-dd".
 * @param date {(string|lim.IDate)}
 * @returns {string}
 * @private
 */
var _validDateAsIsoDate = function (date) {
    return _toIsoDate(_validDate(date));
};


var _keyAsString = function (key) {
    return key.name + ":" + key.value;
};

/**
 * Sorts contracts by:
 *   1) expiration date
 *   2) delivery-start date
 *   3) length of delivery period
 * @param {morn.Contract} c1
 * @param {morn.Contract} c2
 * @returns {number}
 */
var _compareContracts = function (c1, c2) {

    var rv = c1.expirationDate - c2.expirationDate;
    if (rv === 0) {
        // Sometimes all contracts have the same expiration date,
        // so use delivery-start date as a tie-breaker.
        rv = c1.deliveryStart() - c2.deliveryStart();

        if (rv === 0) {
            // If for some reason we have two contracts with the same
            // expiration date and same delivery-start date,
            // we use the contract size (aka its duration) as a tie-breaker.
            rv = c1.deliveryDuration - c2.deliveryDuration;
        }
    }

    return rv;
};

/**
 * @param strNum {string} A string representation of a number,
 *               or "NaN".
 * @return {number}
 */
var _toFloat = function (strNum) {
    if (lim.Number.isFloatString(strNum))
        return parseFloat(strNum);
    else if (strNum === "NaN")
        return Number.NaN;
    else
        throw new TypeError("strNum: String, float-like");
};

/**
 * @param {string} strDate
 * @param {string} name - Name of the argument, for when an error occurs.
 * @return {lim.IDate}
 */
var _toDate = function (strDate, name) {

    try {
        return IDate.valueOf(strDate);
    }
    catch (ex) {
        throw new Error("IllegalArgumentException: " + name + " is not a recognized date format (\\"" + strDate + "\\")");
    }
};

/**
 * Returns an IDate object representing \`obj[prop]\`
 * but only if \`obj.hasOwnProperty(prop)\`.
 * Otherwise, returns null.
 * @param {Object} obj
 * @param {string} prop
 * @throws Error - If the given property exists but cannot be parsed as a date value.
 * @private
 */
var _dateIfAvail = function (obj, prop) {

    if (obj.hasOwnProperty(prop))
        return _toDate(obj[prop], prop);
    else
        return null;
};

/* **********************************************
  * Class Key (private constructor)
  * ********************************************** */
/**
 * A key that defines the contract, possibly one of many.
 * This object is immutable.
 *
 * @constructor
 * @private
 * @alias morn.Contract#Key
 */
var Key = function (name, value) {

    /**
     * The name of the key.
     * @type {string}
     */
    this.name = name;

    /**
     * The value of the key.
     * @type {Object}
     */
    this.value = value;

    Object.freeze(this);
};

Key.prototype = /** @lends morn.Contract#Key */ {
    constructor: Key,

    /**
     * Returns this key as a string, formatted as
     * "{name}:{value}".
     * @return {string}
     */
    toString: function () {
        return _keyAsString(this);
    }
};

Object.freeze(Key);
Object.freeze(Key.prototype);



/* **********************************************
  * Private methods - dependent of Key
  * ********************************************** */

/**
 * Converts an array of single-property objects into one regular object
 * with (possibly) many properties.  All property names within the
 * returned object are lower-case.
 *
 *
 */
var _simpleKeys = function (keys) {

    var simple = [];

    if (keys instanceof Array) {

        for (var i = 0, len = keys.length; i < len; i++) {

            var keyRec = keys[i];
            if (   keyRec !== null
                && keyRec.constructor === Object ) {  // plain Object

                for (var prop in keyRec) {
                    if (keyRec.hasOwnProperty(prop))
                        simple.push(new Key(prop.toLowerCase(), keyRec[prop]));
                }
            }
        }
    }

    return simple;
};

/**
 * Returns whether the given object has the necessary property to create a contract.
 * This method only checks property names, not property values.  If objects are defined with
 * bad property values, we still want to throw exceptions.
 *
 * Properties we look for are: expirationDate, deliveryStartDate, deliveryEndDate.
 *
 * @param {Object} o Object to validate.
 * @returns {boolean} Whether \`o\` has the necessary property to create a contract.
 * @private
 */
function _isGoodContractObject (o) {
    return (
        o.hasOwnProperty("expirationDate")
        && o.hasOwnProperty("deliveryStartDate")
        && o.hasOwnProperty("deliveryEndDate")
    );
}


/**
 * <p>
 *  Represents a contract with a delivery period, trading expiration date,
 *  and the keys to the underlying "symbol".
 * </p>
 *
 * <p>
 *  This constructor is package-private.  Use factory methods
 *  to construct new instances.
 * </p>

  * @constructor
  * @alias morn.Contract
  * @param serverObj {Object} The object from the server in which values are
  *                  all strings.  Values are converted to their proper
  *                  data-types during construction.
  */
var Contract = function (serverObj) {

    if (!_canConstructContract)
        throw new Error("IllegalStateException: Contract constructor is private.");

    _canConstructContract = false;

    var start    = _toDate(serverObj.deliveryStartDate, "deliveryStartDate"),
        end      = _toDate(serverObj.deliveryEndDate,   "deliveryEndDate"),
        delivEnd = end,
        duration = (end.getTime() - start.getTime());

    if (   duration === 0
        || duration >=  IDate.MILLIS_PER_DAY) {

        // If duration == 0, we assume a 1-day contract that
        // starts and ends on the same day (delivery lasts
        // 24 hours.)
        //
        // If duration >= "1 day", we assume \`deliveryEndDate\`
        // represents the last day of delivery, and we
        // adjust \`delivEnd\` to be midnight of the next
        // day (the first millisecond at which the contract
        // can no longer be delivered.)
        duration += IDate.MILLIS_PER_DAY;
        delivEnd  = delivEnd.addDays(1);
    }

    this.deliveryStartDate = start;
    this.deliveryEndDate   = end;
    this._delivEnd         = delivEnd;
    this.deliveryDuration  = duration;
    this.deliveryType      = (  (serverObj.hasOwnProperty("deliveryType"))
        ? DeliveryType.valueOf(serverObj.deliveryType, null)
        : null );
    this._delivType        = serverObj.deliveryType;
    this.expirationDate    = _toDate(serverObj.expirationDate, "expirationDate");
    this._keys             = Object.freeze(_simpleKeys(serverObj.keys));

    Object.freeze(this);
};


Contract.prototype = /** @lends morn.Contract.prototype */ {
    constructor: Contract,

    /**
     * Returns the contract's duration, in days (rounded to nearest integer).
     * @return {integer}
     */
    deliveryDurationDays: function () {
        return Math.round(  this.deliveryDuration
            / IDate.MILLIS_PER_DAY );
    },

    /**
     * Returns the start of the delivery period.
     * That is, the first millisecond for which
     * the contract can be delivered, inclusive.
     * @return {lim.IDate}
     */
    deliveryStart: function () {
        return this.deliveryStartDate;
    },

    /**
     * <p>
     *  Returns the end of the delivery period, exclusive.
     *  For instance, the January 2015 monthly contract
     *  has an end date of 1/31/2015, for which
     *  <code>deliveryEnd()</code> returns <b>2015-02-01T00:00:00.000</b>.
     *  Meaning, the contract can be delivered up until
     *  <b>2015-01-31T23:59:59.999</b>.
     * </p>
     *
     * <p>
     *  This method uses <code>deliveryEndDate</code>.
     *  For contract that span 1-day or more (or have
     *  a duration of 0 millisecond), this method
     *  returns <code>deliveryEndDate.addDays(1)</code>.
     *  For other contracts - contracts with a duration
     *  of less than 1-day - this method returns the
     *  save value as <code>deliveryEndDate</code>.
     * </p>
     * @return {lim.IDate}
     */
    deliveryEnd: function () {
        return this._delivEnd;
    },

    /**
     * Return the raw delivery type value as provided from the server,
     * as a string.
     * @return {string}
     */
    deliveryTypeString: function () {
        return this._delivType;
    },

    /**
     * Returns the keys associated with this contract as
     * plain JavaScript Object.
     * @return {Object}
     */
    keys: function () {

        return _.reduce(this._keys, function (memo, key, idx) {
            memo[key.name] = key.value;
            return memo;
        }, {});
    },

    /**
     * Returns the keys associated with this contract as an array
     * (sorted in the same order as they arrived from the server.)
     * @return {morn.Contract#Key[]}
     */
    keyList: function () {
        return this._keys;
    },

    /**
     * Returns a list of key names (string) in order they arrived
     * from the server.
     * @return {string[]}
     */
    listKeys: function () {
        return _.pluck(this._keys, 'name');
    },

    /**
     * Returns the key object associated with <code>keyName</code>,
     * or <em>null</em> if not found.
     * @param keyName {string} Non-empty.
     * @return {morn.Contract#Key}
     */
    key: function (keyName) {

        if (!lim.String.isNonEmpty(keyName))
            throw new TypeError("keyName: String, non-empty");

        var idx = Arrays.indexOf(keyName, this._keys, 'name');
        if (idx >= 0)
            return this._keys[idx];
        else
            return null;
    },

    /**
     * Returns the value associated with <code>keyName</code>,
     * or <em>null</em> if not found.
     * @param keyName {string}
     * @return {*}
     */
    keyValue: function (keyName) {

        var key = this.key(keyName);
        if (key !== null)
            return key.value;
        else
            return null;
    },

    /**
     * Returns the list of key-value objects as one String formatted as
     * "name1:value1; name2:value2"
     *
     * @return {string}
     */
    keysAsText: function () {
        return _.map(this._keys, _keyAsString).join('; ');
    },

    /**
     * Returns the list of key values as one String, delimited
     * by the semicolon (';') character, as in
     * "value1;value2".
     *
     * @return {string}
     */
    keyValuesAsText: function () {
        return _.pluck(this._keys, 'value').join(';');
    },

    /**
     * Returns a string representation of this contract instance.
     * @return {string}
     */
    toString: function () {

        return lim.String.build(
            "{Contract - deliveryStart: {}, deliveryEnd: {}, expiration: {}, keys: {}}",
            this.deliveryStartDate.toString(),
            this._delivEnd.toString(),
            this.expirationDate.toString(),
            this.keyValuesAsText()
        );
    }
};


/**
 * Represents a contract value.  That is, a contract associated
 * with a (numeric) value, date, and the column (used to extract
 * the value).
 *
 * @constructor
 * @alias morn.ContractValue
 *
 * @param {morn.Contract} contract - A contract
 * @param {lim.IDate} date - Data-point date
 * @param {number} value - Data-point value
 * @param {string} column - Column name
 * @param {?(lim.IDate)} insertTime - Time \`value\` was inserted into time-store.
 */
var ContractValue = function (contract, date, value, column, insertTime) {

    if (!_canConstructContractValue)
        throw new Error("IllegalStateException: ContractValue constructor is private.");

    _canConstructContractValue = false;

    this._contract = contract;
    this._date     = date;
    this._col      = column;
    this._val      = value;
    this._inserted = insertTime;

    Object.freeze(this);  // immutable object.
};

ContractValue.prototype = /** @lends morn.ContractValue.prototype */ {
    constructor: ContractValue,

    /**
     * Returns the contract object associated with this value.
     * @return {morn.Contract}
     */
    contract: function () { return this._contract; },

    /**
     * Returns the date associate with this contract value.
     * @return {lim.IDate}
     */
    date: function () { return this._date; },

    /**
     * Returns the column pertaining to this data-point.
     * @returns {string}
     */
    column: function () { return this._col; },

    /**
     * Returns the (numeric) value from this ContractValue instance.
     * @return {number}
     */
    value: function () { return this._val; },

    /**
     * Returns the time at which this contract value was inserted into the time-store.
     * @returns {?lim.IDate}
     */
    insertTime: function () { return this._inserted; },

    /**
     * Returns a string representation of this contract-value instance.
     * @return {string}
     */
    toString: function () {

        return lim.String.build(
            "{ContractValue - Contract: {}, date: {}, col: {}, value: {}, insertTime: {}}",
            this._contract.toString(),
            this._date.toString(),
            this._col,
            this._val,
            ( (this._inserted !== null) ? this._inserted.toString() : "null" )
        );
    }
};

_.extend(ContractValue, /** @lends {morn.ContractValue} */ {

    /**
     *
     * @param {morn.ContractValue.RawContractValue[]} payload
     * @param {(lim.IDate|string)} dateOfValue
     * @returns {morn.ContractValue[]} List of ContractValue instances.
     */
    fromServerPayload: function (payload, dateOfValue) {

        if (!Arrays.isArrayOf(payload, Object))
            throw new TypeError("payload: Object[]");

        var date = _validDate(dateOfValue),
            list = [];

        _.each(payload, function (serverObj) {

            if (_isGoodContractObject(serverObj)) {

                _canConstructContract = true;
                var contract = new Contract(serverObj);

                _canConstructContractValue = true;
                list.push(new ContractValue(
                    contract,
                    date,
                    _toFloat(serverObj.value),
                    serverObj.col,
                    _dateIfAvail(serverObj, "insertTime")
                ));
            }
        });
        return list;
    }
});


/* **********************************************
  * Private methods - dependent of Contract
  * ********************************************** */

var _getCurveCompKey = function (args) {

    if (!_.every(args, _isStringDateOrNull))
        throw new TypeError("args: Array of objects, each must be String, String[], Date or null");

    var key = "";
    for (var i = 0; i < args.length; i++)
        key += "<<" + _forceString(args[i]) + ">>";

    return key;
};

/**
 * Returns all contracts of one root that are active on \`isoDate\`, or all contracts
 * every created if \`isoDate == null\`.
 * @param {string} feed - Name of feed.
 * @param {string} root - Root symbol.
 * @param {?string} isoDate - String representation of a date (ISO-8861 format).
 * @returns {morn.Contract[]} Sorted by expiration date (1),
 *          delivery-start date (2), length of delivery period (3).
 * @private
 */
var _getContractsByRoot = function (feed, root, isoDate) {

    var key = _getCurveCompKey(arguments),
        list;

    if (_cacheContracts.hasOwnProperty(key)) {
        // Don't make the same server request twice for contracts.
        list = _cacheContracts[key];
    }
    else {

        Logger.debug("Contract._getContractsByRoot - calling getContractsJava(\\"{}\\", \\"{}\\", \\"{}\\")", feed, root, isoDate);

        // See Java code for implementation of getContractsJava().
        var payloadText = getContractsJava( feed,
            root,
            isoDate ),
            payload     = JSON.parse(payloadText);

        if (!(payload instanceof Array))
            throw new Error("IllegalStateException: getContractsJava() did not return an Array");

        list = [];

        Logger.debug("Contract._getContractsByRoot - converting payload into Contract objects and sorting them...");

        _.each(payload, function (serverObj) {
            if (_isGoodContractObject(serverObj)) {
                _canConstructContract = true;
                list.push(new Contract(serverObj));
            }
        });

        // Sort by expiration date, delivery-start date and delivery duration,
        // to save work on all callers.
        list.sort(_compareContracts);
        _cacheContractsSize = _cacheContractsSize + list.toString().length;
        _cacheContractsKeys.push(key);
        _cacheContracts[key] = Object.freeze(list);
        if(_cacheContractsSize > MAX_CACHE_SIZE) {
            invalidateCache(_cacheContracts,_cacheContractsKeys);
        }
        Logger.debug("Contract._getContractsByRoot - done");
    }

    return list.slice(0);
};
/**
 * Returns all contracts of one root that are active on \`isoDate\`, or all contracts
 * every created if \`isoDate == null\`.
 * @param {string} feed - Name of feed.
 * @param {string[]} roots - List of root symbols
 * @param {?string} isoDate - String representation of a date (ISO-8861 format).
 * @returns {morn.Contract[]} In no particular sort order.
 * @private
 */
var _getContractsByRoots = function (feed, roots, isoDate) {

    // Call \`getCurveComponents()\`, once for each root.

    Logger.debug("Contract._getContractsByRoots - feed: [{}], roots [{}], date: [{}]", feed, roots, isoDate);

    var list = [];

    for (var i = 0, numRoots = roots.length; i < numRoots; i++)
        Arrays.addAll(list, _getContractsByRoot(feed, roots[i], isoDate));

    return list;
};


/**
 * Returns a list of contracts (for the one given root)
 * active as of \`isoDate\`.
 * @param feed {string}
 * @param root {string}
 * @param columns {string[]}
 * @param isoCurveDate {string}
 * @param {?(lim.IDate)} asOfDate
 * @returns {morn.ContractValue[]}
 * @private
 */
var _getContractsByRootWithValue = function (feed, root, columns, isoCurveDate, asOfDate) {

    var key = _getCurveCompKey(arguments),
        list;

    if (_cacheCurveComp.hasOwnProperty(key)) {
        // Don't make the same server request twice for contracts.
        list = _cacheCurveComp[key];
    }
    else {

        Logger.debug( "Contract._getContractsByRootWithValue - calling getCurveComponents (java) - feed [{}], root [{}], columns [{}], curveDate [{}], asofDate [{}]",
            feed, root, columns, isoCurveDate, asOfDate );

        // See Java code for implementation of getCurveComponents().
        var payloadText = getCurveComponents( feed,
            root,
            JSON.stringify(columns),
            isoCurveDate,
            ((asOfDate !== null) ? asOfDate.format("yyyy-MM-ddTHH:mm:ss.SSSZ") : null) ),
            payload     = JSON.parse(payloadText);

        if (!(payload instanceof Array))
            throw new Error("IllegalStateException: getCurveComponents() did not return an Array");

        Logger.debug("Contract._getContractsByRootWithValue - convert components into ContractValue objects...");

        list = ContractValue.fromServerPayload(payload, IDate.valueOf(isoCurveDate));
        _cacheCurveCompSize = _cacheCurveCompSize + list.toString().length;
        _cacheCurveCompKeys.push(key);
        _cacheCurveComp[key] = Object.freeze(list);
        if(_cacheCurveCompSize > MAX_CACHE_SIZE) {
            invalidateCache(_cacheCurveComp,_cacheCurveCompKeys);
        }
        Logger.debug("Contract._getContractsByRootWithValue - done");
    }

    return list.slice(0);
};

/**
 * Retrieves a list of contracts (for a list of roots)
 * active on the given \`isoCurveDate\` and their prices (or values).
 *
 * This method returns the most recent prices or values, unless
 * \`asOfDate\` is provided, in which case corrections and changes
 * made after \`asOfDate\` will be ignored.
 * @param {morn.Product} product
 * @param {string} isoCurveDate
 * @param {?(lim.IDate)} asOfDate
 * @returns {morn.ContractValue[]}
 * @private
 */
var _getContractsByRootsWithValue = function (product, isoCurveDate, asOfDate) {

    // Call \`getCurveComponents()\`, once for each root.

    Logger.debug("Contract._getContractsByRootsWithValue - {}", product);

    var feed    = product.feed(),
        roots   = product.roots(),
        columns = product.columns(),
        list    = [];

    for (var i = 0, numRoots = roots.length; i < numRoots; i++)
        Arrays.addAll(list, _getContractsByRootWithValue(feed, roots[i], columns, isoCurveDate, asOfDate));

    return list;
};

/**
 * Returns volatile contracts; that is, a small list of contracts that are
 * likely to be trading \`asOfDate\`.
 *
 * This method deals with data that is somewhat unknown, and does its best to handle
 * all scenarios. When dealing with unknown data, unexpected scenarios
 * are always possible.
 *
 * Below is a list of known scenarios this method handles.  For simplification purposes,
 * in all scenarios, the list of contracts is sorted by:
 * 1) expiration date;
 * 2) delivery start date;
 * 3) delivery end date.
 *
 * SCENARIO #1
 * Most feeds have a rolling list of active contracts.  For these feeds, we filter the list
 * down to *currently active only* and use the 4 front-most contracts..
 *
 * SCENARIO #2
 * Some feeds have their contracts' expiration dates overwritten every day; the expiration date
 * keeps being pushed back to match the current date (unless the expiration date is already
 * higher).  One framework that populates expiration dates in this way is the JS-Engine.
 *
 * For this scenario, on any given day (before data is uploaded) all contracts have an
 * expiration date of *yesterday*, so we get the list of contracts that were active
 * 7 days ago - to account for weekends and holidays - and pick 4 contracts:
 *
 * 1) the first contract in the list (i.e. the oldest);
 * 2) one that's roughly 1/3 of the list;
 * 3) another that's roughly 2/3 of the list;
 * 4) the last contract in the list (i.e. the youngest).
 *
 * SCENARIO #3
 * Some feeds contain roots that haven't traded recently.  In these cases, there will be
 * no active contracts found within the last 7 days.  For these cases, we pull ALL contracts
 * for that roots (all are expired) and pick the last 4 to have expired.
 *
 * ALL SCENARIOS
 * The method may return fewer than 4 contracts, if not enough contracts are available.
 *
 * @param {string} feed - Feed name
 * @param {string[]} roots - One or many roots (as an array)
 * @param {lim.IDate} asOfDate
 * @returns {morn.Contract[]} Small list volatile contracts, may be empty.
 * @private
 */
var _getVolatileContract = function (feed, roots, asOfDate) {

    var MAX_VOLATILE_CONTRACTS = 4;
    var THIS_FUNCTION = 'Contract._getVolatileContract';

    var asOf7daysAgo = _toIsoDate(asOfDate.addDays(-7));
    var contracts = _getContractsByRoots(feed, roots, asOf7daysAgo);

    if (contracts.length > 0) {
        contracts.sort(_compareContracts);
        var activeContracts = _.filter(contracts, function (c) {
            return (c.expirationDate >= asOfDate);
        });
        if (activeContracts.length > 0) {
            // scenario #1
            var end = Math.min(MAX_VOLATILE_CONTRACTS, activeContracts.length);
            Logger.debug(
                '{} - scenario 1 - found active contracts, using first [{}] contract(s)',
                THIS_FUNCTION, end
            );
            return Arrays.slice(activeContracts, 0, end);
        } else if (contracts.length < MAX_VOLATILE_CONTRACTS) {
            // scenario #2
            Logger.debug(
                '{} - scenario 2 - we only have [{}] contracts, using all of them.',
                THIS_FUNCTION, contracts.length
            );
            return contracts;
        } else {
            // scenario #2
            Logger.debug(
                '{} - scenario 2 - using first, 1/3, 2/3, last contracts.',
                THIS_FUNCTION
            );
            return [
                contracts[0],
                contracts[Math.floor(contracts.length / 3)],
                contracts[Math.ceil((contracts.length / 3) * 2)],
                contracts[contracts.length - 1]
            ];
        }
    } else {
        // scenario #3
        contracts = _getContractsByRoots(feed, roots, null);
        var num = Math.min(MAX_VOLATILE_CONTRACTS, contracts.length);
        if (num === 0) {
            Logger.debug('{} - found no contract whatsoever.', THIS_FUNCTION);
            return Arrays.EMPTY;
        }
        Logger.debug(
            '{} - scenario 3 - no contracts were active 7 days ago, using last [{}] contracts to expire.',
            THIS_FUNCTION, num
        );
        contracts.sort(_compareContracts);
        return Arrays.slice(contracts, contracts.length - num);
    }
};

/**
 * Returns the last data-date (up to \`asOfDate\`) found in time-series
 * of volatile contracts.
 *
 * @param {morn.Product} product
 * @param {lim.IDate} asOfDate
 * @returns {?lim.IDate}
 * @private
 */
var _getDateAsOf = function (product, asOfDate) {

    var thisFnName = "Contract._getDateAsOf";

    Logger.debug("{} - date: [{}], product: {}", thisFnName, asOfDate, product);

    var volatileContracts = _getVolatileContract(product.feed(), product.roots(), asOfDate);

    Logger.debug(
        "{} - Found [{}] volatile contracts to check.",
        thisFnName, volatileContracts.length
    );

    // To find the highest trade date, we pull the 20 values leading to \`asOfDate\`,
    // then drop all NaNs and take the highest date remaining.
    var numValuesToGetToAvoidTrailingNans = 20;

    // Last curve date is the highest trade date amongst the volatile contracts.
    var curveDate = null;

    var tsList = morn.TimeSeries.getServerDataMulti(
        product.feed(),
        volatileContracts.map(function (volatileContract) {
            return volatileContract.keys();
        }),
        product.columns(),
        null,
        asOfDate,
        numValuesToGetToAvoidTrailingNans
    );

    tsList.forEach(function (ts, i) {

        var noNans = ts.dropNaNs();
        var lastTrade = ((noNans.length() > 0) ? noNans.floorDate(asOfDate) : null);

        Logger.debug(
            "{} - contract [{}], end-date: [{}], last-trade: [{}]",
            thisFnName, volatileContracts[i].keysAsText(), asOfDate, lastTrade
        );

        if (lastTrade !== null) {
            if (curveDate === null || curveDate.isBefore(lastTrade)) {
                curveDate = lastTrade;
            }
        }
    });

    Logger.debug(
        "{} - last-curve-date found for [{}]: [{}].",
        thisFnName, product, curveDate
    );

    return curveDate;
};


/**
 * Inspects a function's arguments for a Product at \`args[0]\`.  If a
 * Product is not there, this method assumes the first three arguments
 * are feed, root(s) and column(s), and creates a Product from
 * those values.
 *
 * The returned list of arguments has a Product as the first entry,
 * with all subsequent arguments appended *as-is*.
 * @param args {Arguments}
 * @return {Object[]} The first entry is a Product object; other entries
 *               are whatever was provided in the initial call (after \`column\`).
 * @private
 */
var _toProduct = function (args) {

    var aa = Arrays.slice(args);

    if (!(aa[0] instanceof Product)) {

        var product = Product.withRoots( aa[0],
            _validRoots(aa[1]),
            _validCols(aa[2]) );
        aa.splice(0, 3, product);
    }

    return aa;
};

_.extend(Contract, /** @lends morn.Contract */ {

    /**
     * <p>
     *  Returns a list of ContractValue objects.  That is, a list of
     *  all contracts active as of a given date, along with their value
     *  (a.k.a. price) on that same date.
     * </p>
     *
     * <p>
     *  The first three arguments - feed, roots and columns -
     *  can be collapsed into one {@link morn.Product|Product} argument.
     *  When invoked in this manner, this method requires 2 arguments only.
     * </p>
     *
     * <p>
     *  If multiple columns are provided, the list contains a separate
     *  ContractValue object for each column.
     * </p>
     *
     * <p>
     *  This method returns the most recent prices (or values) for each
     *  contract by default.  This may includes corrections.  Callers
     *  can pull prices/values before corrections by providing <code>asOfDate</code>.
     * </p>
     *
     * @param {string} feed - Feed name
     * @param {(string|string[])} roots - Root(s)
     * @param {(string|string[])} columns - Column(s)
     * @param {(string|lim.IDate)} settleDate - Date at which the prices/values were settled.
     * @param {?(string|lim.IDate)} [asOfDate] - Prices/values as-of the given date.
     *                              Meaning: any corrections or changes made to contracts
     *                              after \`asOfDate\` are ignored.
     * @return {morn.ContractValue[]}
     */
    getContractsWithValue: function (feed, roots, columns, settleDate, asOfDate) {

        var args          = _toProduct(arguments),
            product       = args[0],
            isoSettleDate = _validDateAsIsoDate(args[1]),
            asOfDt        = null;

        if (   args.length > 2
            && args[2] !== null )
            asOfDt = IDate.valueOf(args[2]);

        return _getContractsByRootsWithValue(product, isoSettleDate, asOfDt);
    },

    /**
     * <p>
     *  Retrieves all contracts for a given root.  If <code>asOfDate</code>
     *  is provided, the returned list only contains contracts that are
     *  not expired as of the given date.
     * </p>
     *
     * <p>
     *  Because this method only takes a single root, it can and does
     *  guarantee that the returned list is sorted by the contracts'
     *  expiration date.
     * </p>
     *
     * @param feed {string} Name of feed.
     * @param root {string} Root symbol, only one allowed.
     * @param [asOfDate] {?(string|lim.IDate)}
     * @return {morn.Contract[]}
     */
    getContractsByRoot: function (feed, root, asOfDate) {

        feed = _validFeed(feed);
        root = _validRoot(root);

        var isoDate = null;

        if (   arguments.length > 2
            && asOfDate !== null )
            isoDate = _validDateAsIsoDate(asOfDate);

        return _getContractsByRoot(feed, root, isoDate);
    },

    /**
     * <p>
     *  Retrieves all contracts with meta data for a given root or key combination. If roots is provided then <code>asOfDate</code>
     *  is required, and the returned list only contains contracts that are
     *  not expired as of the given date.
     * </p>
     *
     * @param product {morn.Product} product.
     * @param [asOfDate] {?(string|lim.IDate)}
     * @return {morn.Contract[]}
     */

    getFeedMetaData: function (product, asOfDate) {

        var feed = product.feed(),
            roots = product.roots(),
            keys = product.key(),
            columns = product.columns(),
            contracts = [];

        feed = _validFeed(feed);

        if (roots !== null && roots instanceof Array) {
            roots = _validRoots(roots);

            var isoDate = null;
            if (arguments.length > 0
                && asOfDate !== null) {
                isoDate = _validDateAsIsoDate(asOfDate);
            } else {
                throw new TypeError("asOfDate is required to get contracts by roots.");
            }

            Logger.debug("Contract.getFeedMetaData - calling getContractsByRootWithMetaJava(\\"{}\\", \\"{}\\", \\"{}\\")", feed, roots, isoDate);

            for (var i = 0, numRoots = roots.length; i < numRoots; i++) {
                Arrays.addAll(contracts, JSON.parse(getContractsByRootWithMetaJava(feed, roots[i], isoDate)));
            }
        } else {
            Logger.debug("Contract.getFeedMetaData - calling getContractsByKeysWithMetaJava(\\"{}\\", \\"{}\\")", feed, keys);

            Arrays.addAll(contracts, JSON.parse(getContractsByKeysWithMetaJava(feed, JSON.stringify([keys]))));
        }

        return contracts.slice(0);
    },


    /**
     * Retrieves all contracts for the given root(s).  If <code>asOfDate</code>
     * is provided, the returned list only contains contracts that are
     * not expired as of the given date.
     *
     * @param feed {string} Name of feed.
     * @param roots {(string|string[])} Root symbols, one or many.
     * @param [asOfDate] {?(string|lim.IDate)}
     * @return {morn.Contract[]}
     */
    getContractsByRoots: function (feed, roots, asOfDate) {

        feed = _validFeed(feed);
        roots = _validRoots(roots);

        var isoDate = null;

        if (arguments.length > 2
            && asOfDate !== null)
            isoDate = _validDateAsIsoDate(asOfDate);

        return _getContractsByRoots(feed, roots, isoDate);
    },

    /**
     * <p>
     *  Returns the most recent traded date (up to <code>asOfDate</code>)
     *  across volatile contracts of <code>roots</code>.
     *
     *  Call this method to find "last curve date".
     * </p>
     *
     * <p>
     *  Note: this function returns <em>null</em> if it can't find
     *  any active contracts, or can't find any data for
     *  the volatile contracts.
     * </p>
     *
     * <p>
     *  The first three arguments - feed, roots and columns -
     *  can be collapsed into one {@link morn.Product|Product} argument.
     *  When invoked in this manner, this method requires 2 arguments.
     * </p>
     *
     * @param {string} feed
     * @param {(string|string[])} roots
     * @param {(string|string[])} columns
     * @param {(string|lim.IDate)} asOfDate - The date for which to
     *         retrieve the active contracts.
     * @return {?lim.IDate}
     */
    getFloorDate: function (feed, roots, columns, asOfDate) {

        var args = _toProduct(arguments);

        return _getDateAsOf(args[0], _validDate(args[1]));
    },

    /**
     * <p>
     *  Returns volatile contracts; that is, a small list of contracts that are
     *  likely to trade <code>asOfDate</code>.
     * </p>
     * <p>
     *  Below is a list of scenarios handled by this function. For all scenarios,
     *  the list of contracts is sorted by:
     * </p>
     * <p>
     *  1) expiration date; <br/>
     *  2) delivery start date; <br/>
     *  3) delivery end date. <br/>
     * </p>
     *
     * SCENARIO #1
     * <p>
     *  A root mapped to a rolling list of active contracts (most common scenario):
     *  use the 4 front-most contracts.
     * </p>
     *
     * SCENARIO #2
     * <p>
     *  A root with no predefined expiration dates (another common scenario). In this scenario,
     *  expiration dates are pushed further into the future every day, as data is acknowledged
     *  (unless the expiration date is already higher).  One framework that works like this
     *  is the JavaScript Engine.
     * </p>
     * <p>
     *  When we look at such root's contracts before data is loaded, all contracts appear
     *  expired: their expiration date is set to <em>yesterday</em>.  For these roots we get
     *  the list of contracts that were active 7 days ago (to account for weekends and
     *  holidays) and pick 4 contracts evenly spread across the list:
     * </p>
     * <p>
     *  1) the first contract in the list (i.e. the oldest); <br/>
     *  2) one that's roughly 1/3 of the list; <br/>
     *  3) another that's roughly 2/3 of the list; <br/>
     *  4) the last contract in the list (i.e. the youngest). <br/>
     * </p>
     *
     * SCENARIO #3
     * <p>
     *  A root that hasn't traded recently (rare): we use ALL contracts for that root
     *  (all are expired) and pick the last 4 - the ones that most recently expired.
     * </p>
     *
     * ALL SCENARIOS
     * <p>
     *  This function may return fewer than 4 contracts (maybe none) if not enough contracts
     *  are available.
     * </p>
     *
     * <p>
     *  This function is made public only for the purpose of testing its functionality,
     *  or debugging {@link morn.Contract.getFloorDate getFloorDate}.
     *  The implementation of how volatile contracts are calculated is subject to change
     *  without warning.
     * </p>
     *
     * @param {string} feed - Feed name
     * @param {(string|string[])} roots - Root(s)
     * @param {(lim.IDate|string)} asOfDate - Date on which we want to find the
     *        volatile contracts.
     * @returns {morn.Contract[]} May be empty.
     */
    getVolatileContracts: function (feed, roots, asOfDate) {
        return _getVolatileContract(
            _validStringNE(feed),
            _validRoots(roots),
            _validDate(asOfDate)
        );
    },

    /**
     * @returns {morn.Contract[]}
     * @deprecated Name is misleading, use {@link morn.Contract.getVolatileContracts} instead.
     */
    getVolatileContract: function (feed, roots, asofdate) {
        return Contract.getVolatileContracts.apply(this, arguments);
    }
});

Object.freeze(Contract);
Object.freeze(Contract.prototype);

Object.freeze(ContractValue);
Object.freeze(ContractValue.prototype);

/* **********************************************
  * PUBLIC CLASSES
  * ********************************************** */
morn.Contract = Contract;
morn.ContractValue = ContractValue;

var CORRECTION_DATE_PROP = "formula.corr_date";
var Parameters = morn.Parameters;

/**
 * A filter that returns whether a data-point should be *processed*.
 *
 * @callback morn.TimeSeries.DataPointFilter
 * @param {lim.IDate} date - Data-point date.
 * @param {number} value - Data-point value.
 * @param {Integer} rowIndex - Row index within the parent TimeSeries (0-based).
 * @param {Integer} colIndex - Column index within the parent TimeSeries (0-based).
 * @returns {boolean} Whether to process the given data-point.
 * @private
 */

/**
 * A <em>value modifier</em> callback is a function that modifies the existing
 * value at a given row-column position.  This callback is passed 4 arguments;
 * implementations must declare at least two of those four arguments.
 *
 * @callback morn.TimeSeries.ValueModifier
 * @param date {lim.IDate} Date of row for value being modified.
 * @param value {number} The current value associated with the row-column.
 * @param rowIndex {integer} The row index of <code>date</code> (0-based).
 * @param colIndex {integer} The column index of <code>value</code> within the row  (0-based).
 * @return {number} The new value.
 */

/**
 * When aggregating rows, a <em>date grouping method</em> callback function
 * is used to compute the row's group date.  For example, when computing
 * daily averages, the <em>date grouping method</em> will compute the daily
 * date associated with each row (typically by setting the time to midnight.)
 *
 * @callback morn.TimeSeries.DateGroupingMethod
 * @param date {lim.IDate}
 * @param tz {?lim.TimeZone}
 * @return {lim.IDate}
 *
 * @see {@link morn.TimeSeries.DateGroupingMethods} for pre-defined grouping methods.
 */

/**
 * NumericReducer defines the interface to convert a time-series
 * - or slice of - into one or more numbers.
 *
 * NumericReducers can be used to calculate group-by averages, sum, etc.
 * They can also be used to calculate rolling calculations, such as
 * correlation, change, change percent, etc.
 *
 * @callback morn.TimeSeries.NumericReducer
 * @param {morn.TimeSeries} ts - TimeSeries (slice) to reduce to numbers.
 * @returns {(number|number[])} - Values to be inserted into a new TimeSeries row.
 */

/**
 * DateReducer defines the interface to convert a time-series
 * - or slice of - into a single date.
 *
 * @callback morn.TimeSeries.DateReducer
 * @param {morn.TimeSeries} ts - TimeSeries (slice) to reduce to date.
 * @returns {lim.IDate}
 */

/**
 * <p>
 *  Callback function used to compute a single value - column or row -
 *  from a set of values.
 *  The iteratee is passed at least two arguments: running value and
 *  current value.  Other arguments may be passed depending on the type
 *  of reduction operation (column vs. row).
 * </p>
 *
 * <p>
 *  Functions which accept an <code>Iteratee</code> argument
 *  typically support an optional <em>initial value</em> argument.
 *  If a numeric value is provided as <em>initial value</em>, it
 *  is passed as the <em>running value</em> to the iteratee
 *  invoked on the first row.  Otherwise, the iteratee is not invoked on the
 *  first row; the first row's value is instead passed
 *  as the running value in the invocation of the iteratee on the next
 *  row in the list.
 * </p>
 *
 * <p>
 *  Each call to iteratee should return the new <em>runningValue</em>
 *  which will be passed to the next call, and so on, and eventually
 *  be returned as the single value computed from the set of rows.
 * </p>
 *
 * @callback morn.TimeSeries.Iteratee
 * @param runningValue {number} Running value.
 * @param rowValue {number} Current row value.
 * @param rowDate {lim.IDate} Current row date.
 * @return {number} The new <em>running value</em>.
 */

/**
 * <p>
 *  Resolves multiple values into one numeric value.
 * </p>
 *
 * <p>
 *  Use a resolver to compute a single numeric value from
 *  multiple values accumulated during a <em>reduce</em>
 *  operation.  The resolver callback is executed once,
 *  at the end of each value-set.
 * </p>
 *
 * @callback morn.TimeSeries.Resolver
 * @param memo {*} The value returned by the last call to
 *                 {@link morn.TimeSeries.Iteratee|Iteratee} within
 *                 a value-set.
 * @return {number} A numeric value computed from <code>memo</code>.
 */

/**
 * A function that computes the correlation between two columns
 * of a TimeSeries.
 * @callback morn.TimeSeries.CorrelationExecutor
 * @param {morn.TimeSeries} ts - Two-column TimeSeries.
 * @returns {Number} Correlation factor.
 */


/**
 * Options to refine/tweak a correlation calculation.
 * @typedef {Object} morn.TimeSeries.CorrelationOptions
 * @property {Number} [fill=0] - Whether NaNs should be replaced with previous value (1),
 *                               replaced with next value (-1), or excluded from the correlation (0).
 *                               1=fill_forward, -1=fill_backward, 0=exclude.
 * @property {Number} [ew_lambda] - Exponentially weighted smoothing factor. Provide this argument
 *                                to activate exponentially weighted correlation.  Valid values
 *                                range from 0 (inclusive) to 1 (exclusive).
 * @property {(lim.IDate|string)} [start_date] - How far back to start computing correlation.
 *                                When a product is given to a correlation function,
 *                                use <code>start_date</code> to limit the amount of data retrieved.
 * @property {(lim.IDate|string)} [end_date] - When to stop computing correlation.
 *                                When a product is given to a correlation function,
 *                                use <code>end_date</code> to limit the amount of data retrieved.
 * @property {(lim.TimeZone|string)} [time_zone] - When pulling intraday data, use
 *                                   <code>time_zone</code> to overwrite the default time zone.
 */

/**
 * Validated correlation options.  See {@link morn.TimeSeries.CorrelationOptions|CorrelationOptions}
 * @typedef {Object} morn.TimeSeries.ValidatedCorrelationOptions
 * @property {Number} fill - Whether NaNs should be replaced with previous value (1),
 *                           replaced with next value (-1), or excluded from the correlation (0).
 *                           1=fill_forward, -1=fill_backward, 0=exclude.
 * @property {?(Number)} ew_lambda - Exponentially weighted smoothing factor, range from 0 (inclusive)
 *                                     to 1 (exclusive).
 * @property {?(lim.IDate)} start_date - How far back to start computing correlation.
 *                          When a product is given to a correlation function,
 *                          use <code>start_date</code> to limit the amount of data retrieved.
 * @property {?(lim.IDate)} end_date - When to stop computing correlation.
 *                          When a product is given to a correlation function,
 *                          use <code>end_date</code> to limit the amount of data retrieved.
 * @property {?(lim.TimeZone)} time_zone - When pulling intraday data, use
 *                             <code>time_zone</code> to overwrite the default time zone.
 */

/**
 * Results of a linear regression.
 *
 * @typedef {Object} morn.TimeSeries.LinearRegressionResults
 * @property {number} intercept
 * @property {number[]} slopes - one per input variable.
 * @property {number} slope - first slope (same as <code>slopes[0]</code>), for convenience.
 * @property {number} r - Correlation between actual and predicted outcome.
 * @property {number} rSq - r<sup>2</sup>
 * @property {number} rSqAdj - Useful when multiple input variables were used.
 * @property {Integer} observations - Number of observations used in the regression.
 */


/* **************************************************
  * "Import"
  * ************************************************** */
var TimeZone     = lim.TimeZone,
    IDate        = lim.IDate,
    Numbers      = lim.Number,
    Strings      = lim.String,
    Arrays       = lim.Arrays,
    Logger       = lim.Logger,
    DelimFile    = lim.DelimFile,
    Iterator     = lim.Iterator,
    Utils        = morn.Utils,
    Calendar     = morn.Calendar,
    Holidays     = morn.Holidays,
    Feed         = morn.Feed,
    Product      = morn.Product,
    DeliveryType = morn.DeliveryType,
    Parameters   = morn.Parameters;

/* **************************************************
  * "Static import"
  * ************************************************** */
var _isFiniteNumber = Numbers.isNumber;
var requireNonEmptyString = Strings.requireNonEmpty;

/* **************************************************
  * Static variables
  * ************************************************** */
var DISABLE_SAVE_OPTIMIZATION = "formula.disable_save_optimization";
var DISABLE_SAVE              = "formula.is_saving_disabled";
var ALLOW_DATA_POINT_LIMIT_CHANGE = "formula.data_point_limit_change_enabled";

var CORRELATION_INSUFFICIENT = Number.NaN;

var _dataPointMax = 2000000;  // 2 million is reasonable initial limit.
var _dataPointCnt = 0;

/**
 * Creates a copy of \`dates\`, sorts it, returns it.
 * @param {lim.IDate[]} dates Array of dates.
 * @returns {lim.IDate[]} Copy of \`dates\`, sorted.
 * @private
 */
function _cloneAndSortDates (dates) {
    var clone = Arrays.slice(dates);
    clone.sort(IDate.compare);
    return clone;
}

/* **************************************************
  * Convenience methods for *reducers* (private)
  * ************************************************** */

var _arithmetic = {
    add:      function (a, b) { return a + b; },
    subtract: function (a, b) { return a - b; },
    multiply: function (a, b) { return a * b; },
    divide:   function (a, b) { return a / b; },
    power:    function (a, b) { return Math.pow(a, b); }
};

var _2MoreValsIteratee = function (oper) {

    return function (memo, value) {
        if (_isFiniteNumber(value)) {

            if (memo.cnt > 0)
                memo.run = oper(memo.run, value);
            else
                memo.run = value;

            memo.cnt++;
        }
        return memo;
    };
};

var _2MoreValsCalc = function (memo) {
    if (memo.cnt > 1)
        return memo.run;
    else
        return Number.NaN;
};

var _atLeast1ValCalc = function (memo) {
    if (memo.cnt > 0)
        return memo.run;
    else
        return Number.NaN;
};

var _defIteratee = function (memo, value) {
    return memo;  // no-op
};

/* **************************************************
  * Reducers.
  * We keep their private data in a separate variable;
  * the instances only get a (frozen) ID.
  * ************************************************** */
var _reducerseq = 0;
var _reducerdb  = {};

var _reducerCreate = function () {

    var id    = ++_reducerseq,
        undef = _.noop(),
        rec   = {
            id: id,
            isFrozen: false,
            initValue: undef,
            iteratee: _defIteratee,
            resolver: undef
        };

    _reducerdb[id] = rec;

    return id;
};

var _reducerGetById = function (id) {

    if (!_reducerdb.hasOwnProperty(id))
        throw new Error("IllegalStateException: reducer ID not found (" + id + ")");

    return _reducerdb[id];
};

var _reducerSet = function (id, prop, value) {

    var rec = _reducerGetById(id);

    if (rec.isFrozen)
        throw new Error("IllegalStateException: Reducer is frozen");

    rec[prop] = value;
};

var _reducerGet = function (reducer) {
    return _reducerGetById(reducer._id);
};

/**
 * Reduce a set of numbers - column or row - into one number.
 *
 * @constructor
 * @alias morn.TimeSeries.Reducer
 */
var Reducer = function () {
    this._id = _reducerCreate();
    Object.freeze(this);
};

Reducer.prototype = /** @lends morn.TimeSeries.Reducer.prototype */ {
    constructor: Reducer,

    /**
     * Sets the starting value for each new reduction operation.
     * Objects provided to <code>startWith</code> will be cloned
     * (deep-copy) each time a reduction operation is initiated.
     * @param initialValue {*} Accepts anything, including
     *                         <em>undefined</em>.
     * @return {morn.TimeSeries.Reducer}
     */
    startWith: function (initialValue) {
        _reducerSet(this._id, "initValue", Utils.clone(initialValue));
        return this;
    },

    /**
     * Callback function used to accumulate information
     * - or a single value - while looping through a value-set.
     *
     * @param iteratee {morn.TimeSeries.Iteratee}
     * @return {morn.TimeSeries.Reducer}
     */
    iteratee: function (iteratee) {

        if (   typeof iteratee !== 'function'
            || iteratee.length < 2 )
            throw new TypeError("iteratee: Function, 2 arguments minimum");

        _reducerSet(this._id, "iteratee", iteratee);
        return this;
    },

    /**
     * A function to resolve the information accumulated by
     * <em>iteratee</em>.  Use a resolver when accumulating multiple
     * values, to resolve those values into a single one.
     * @param resolver {morn.TimeSeries.Resolver}
     * @return {morn.TimeSeries.Reducer}
     */
    resolver: function (resolver) {

        if (   typeof resolver !== 'function'
            || resolver.length < 1 )
            throw new TypeError("resolver: Function, 1 argument");

        _reducerSet(this._id, "resolver", resolver);
        return this;
    },

    /**
     * Freezes this instance, preventing further updates to it.
     * Attempts to further update this instance will cause
     * exceptions, including attempts to call <code>freeze()</code>
     * more than once.
     * @return {morn.TimeSeries.Reducer}
     */
    freeze: function () {
        _reducerSet(this._id, "isFrozen", true);
        return this;
    }
};


_.extend(Reducer, /** @lends morn.TimeSeries.Reducer */ {

    /**
     * Returns the count of all numeric values within a value-set.
     */
    COUNT: new Reducer().startWith(0)
        .iteratee(function (memo, val) {
            if (_isFiniteNumber(val))
                memo++;
            return memo;
        })
        .freeze(),

    /**
     * Returns the sum of all numeric values within a value-set.
     */
    SUM: new Reducer().startWith(0)
        .iteratee(function (memo, val) {
            if (_isFiniteNumber(val))
                memo += val;
            return memo;
        })
        .freeze(),

    /**
     * Returns the product (multiplication) of all numeric values within a value-set.
     */
    PRODUCT: new Reducer().startWith({cnt: 0, run: null})
        .iteratee(_2MoreValsIteratee(_arithmetic.multiply))
        .resolver(_2MoreValsCalc)
        .freeze(),

    /**
     * Returns the difference between the first numeric value
     * and the sum of all subsequent numeric values within a value-set.
     */
    DIFFERENCE: new Reducer().startWith({cnt: 0, run: null})
        .iteratee(_2MoreValsIteratee(_arithmetic.subtract))
        .resolver(_2MoreValsCalc)
        .freeze(),

    /**
     * Returns the quotient (division) resulting from a cascade of division
     * starting with dividing the first value by the 2nd, using that result
     * and divided by the 3rd value, etc.
     */
    QUOTIENT: new Reducer().startWith({cnt: 0, run: null})
        .iteratee(_2MoreValsIteratee(_arithmetic.divide))
        .resolver(_2MoreValsCalc)
        .freeze(),

    /**
     * Returns the lowest value from a value-set.
     */
    MINIMUM: new Reducer().startWith({cnt: 0, run: null})
        .iteratee(_2MoreValsIteratee(Math.min))
        .resolver(_atLeast1ValCalc)
        .freeze(),

    /**
     * Returns the highest value from a value-set.
     */
    MAXIMUM: new Reducer().startWith({cnt: 0, run: null})
        .iteratee(_2MoreValsIteratee(Math.max))
        .resolver(_atLeast1ValCalc)
        .freeze(),

    /**
     * Returns the difference between the highest and the
     * lowest values within a value-set.
     */
    SPREAD: new Reducer().startWith({cnt: 0, min: 0, max: 0})
        .iteratee(
            /**
             * @param {{min: number, max: number, cnt: int}} memo
             * @param {number} value
             * @returns {{min: number, max: number, cnt: int}}
             */
            function (memo, value) {

                if (_isFiniteNumber(value)) {
                    if (memo.cnt > 0) {
                        memo.min = Math.min(memo.min, value);
                        memo.max = Math.max(memo.max, value);
                    } else {
                        memo.min = value;
                        memo.max = value;
                    }
                    memo.cnt++;
                }
                return memo;
            }
        )
        .resolver(function (memo) {
            if (memo.cnt > 0)
                return memo.max - memo.min;
            else
                return Number.NaN;
        })
        .freeze(),

    /**
     * Returns the average within a value-set.
     */
    AVERAGE: new Reducer().startWith({cnt: 0, sum: 0})
        .iteratee(
            /**
             * @param {{sum: number, cnt: int}} memo
             * @param {number} value
             * @returns {{sum: number, cnt: int}}
             */
            function (memo, value) {
                if (_isFiniteNumber(value)) {
                    memo.cnt += 1;
                    memo.sum += value;
                }
                return memo;
            }
        )
        .resolver(function (memo) {
            if (memo.cnt > 0)
                return memo.sum / memo.cnt;
            else
                return Number.NaN;
        })
        .freeze()
});

Object.freeze(Reducer);
Object.freeze(Reducer.prototype);

/** StandardMovingAverage (private) */
var StandardMovingAverage = function (numPoints) {
    this._numPts  = numPoints;
    this._vals    = Arrays.newInstance(numPoints, Number.NaN);
    this._rollIdx = 0;
    this._isReady = false;
};
StandardMovingAverage.prototype = {
    constructor: StandardMovingAverage,

    apply: function (value) {

        if (_isFiniteNumber(value)) {
            this._vals[this._rollIdx] = value;
            this._rollIdx += 1;

            if (this._rollIdx >= this._numPts) {
                this._rollIdx = 0;
                this._isReady = true;
            }
        }
        return this;
    },

    calc: function () {
        if (this._isReady)
            return _.reduce(this._vals, _arithmetic.add, 0) / this._numPts;
        else
            return Number.NaN;
    }
};

/* ****************************************************************
  * Private functions useful to all classes implemented below.
  * **************************************************************** */

/**
 * Returns whether \`val\` is a non-finite number (NaN, +Infinity, -Infinity).
 * @param {*} val
 * @returns {boolean} True if \`val\` is a number that is not finite.
 * @private
 */
var _isNonFiniteNumber = function (val) {
    return (   typeof val === "number"
        && !isFinite(val) );
};

/**
 * Ensures that \`s\` does not exceed \`maxLength\`.
 * @param {string} s
 * @param {Integer} maxLength
 * @param {string} [suffixIfTruncated=""]
 * @returns {string} A string.
 * @private
 */
var _maxLength = function (s, maxLength, suffixIfTruncated) {

    if (s.length <= maxLength)
        return s;

    else {
        var suffix = ((arguments.length > 2) ? suffixIfTruncated : "");
        return s.substring(0, maxLength) + suffix;
    }
};

/**
 * Returns an array of dates contained in the given time-series rows.
 * @param {morn.TimeSeries.Row[]} rows
 * @return {lim.IDate[]}
 * @private
 */
var _getDatesForRows = function (rows) {
    return _.pluck(rows, '_date');
};

/**
 * Returns an array of dates contained in the entire time-series given.
 * @param {morn.TimeSeries} ts
 * @returns {lim.IDate[]}
 * @private
 */
var _getDates = function (ts) {
    return _getDatesForRows(ts._rows);
};


/**
 * Returns date format appropriate for <code>dates</code>.
 *
 * @param dates {lim.IDates[]}
 * @return {string}
 */
var _getDateFormat = function (dates) {

    var dateFormat = "yyyy-MM-dd",  // Default to daily format
        hasMillis  = false,
        hasSecs    = false,
        hasTime    = false;

    // Don't bother looking at all the dates.
    // We should know what we need within the first 100 rows.
    var maxLen = Math.min(dates.length, 100);
    for (var i = 0; i < maxLen; i++) {

        var date = dates[i],
            time = date.getTimeMillis();

        if (time > 0) {
            hasTime = true;

            if (time % IDate.MILLIS_PER_MINUTE > 0) {
                hasSecs = true;

                if (time % IDate.MILLIS_PER_SECOND > 0)
                    hasMillis = true;
            }
        }
    }

    if (hasTime) {

        // Some rows have time; \`format\` should show minutes at a minimum.

        dateFormat += "THH:mm";

        if (hasSecs) {
            dateFormat += ":ss";

            if (hasMillis)
                dateFormat += ".SSS";
        }

        if (IDate.isTimezoneAware())
            dateFormat += "Z";

    }

    return dateFormat;
};

/**
 * Returns an array of string that represents the dates
 * of the given time series.  The dates are in the same
 * order as in the rows.
 *
 * @param dates {lim.IDates[]}
 * @return {string[]}
 */
var _getFormattedDates = function (dates) {

    var dateFormat = _getDateFormat(dates),
        formatter  = IDate.getFormatter(dateFormat);

    return _.map(dates, function (date) {
        return formatter(date);
    });
};


var _inTz = function (dates, tz) {
    return IDate.withZone(dates, tz);
};

var _giveTz = function (dates, tz) {
    return IDate.withZoneRetainFields(dates, TimeZone.get(tz));
};

/**
 * Mutates the given data-points.
 * @param {morn.TimeSeries.DataPoint[]} dataPoints, presumed to be immutable.
 * @param {string} tag
 * @private
 */
var _mutateDataPoints = function (dataPoints, tag) {

    var copy = dataPoints.slice(0),
        num  = copy.length,
        dataPoint;

    for (var i = 0; i < num; i++) {
        dataPoint = copy[i];
        copy[i] = dataPoint.mutate(dataPoint.value(), tag);
    }

    return copy;
};


/**
 * Creates a new TimeSeries by replacing the dates within
 * \`ts\` with the given \`dates\`.
 *
 * If DST corrections are also given, it's possible that the
 * length of the returned TimeSeries differs from \`ts.length()\`.
 *
 * @param {morn.TimeSeries} ts
 * @param {lim.IDate[]} dates
 * @param {lim.TimeZone} tz
 * @param {string} tag
 * @param {lim.IDate.DstFix[]} dstFixes
 * @returns {*|morn.TimeSeries.Reducer|morn.TimeSeries|Object}
 * @private
 */
var _replaceDates = function (ts, dates, tz, tag, dstFixes) {

    var rows    = ts._rows,
        numRows = rows.length;

    if (numRows !== dates.length)
        throw new Error("IllegalStateException: number of rows must match number of dates: " + numRows + " vs. " + dates.length);

    /** @type {morn.TimeSeries} */
    var clone = _newTs(ts);
    var numCols   = ts._size,
        dstFixIdx = 0,
        dstFixAt  = (  (dstFixes.length > dstFixIdx)
            ? dstFixes[dstFixIdx].index()
            : -1 ),
        doExclude = false;

    clone._tz = tz;

    for (var i = 0; i < numRows; i++) {

        doExclude = false;

        if (i === dstFixAt) {

            // Apply DST correction that applies at this index position.

            var dstFix    = dstFixes[dstFixIdx],
                numRem    = dstFix.numRemove(),
                toInsert  = dstFix.insert(),
                numInsert = toInsert.length;

            if (numRem > 0) {
                // Skip the next \`numRemove\` rows, but keep in mind the loop's \`i++\` instruction.
                i += numRem - 1;
                doExclude = true;
            }

            else if (numInsert > 0) {
                // Create new rows to be inserted, by repeating a portion of values near \`dstFix.index()\`.
                var idxOffset = ((dstFix.isRecreatedFromPast()) ? -numInsert : 0);

                for (var k = 0; k < numInsert; k++)
                    _addOneRow(clone, new Row(toInsert[k], _mutateDataPoints(rows[i + idxOffset + k]._vals, tag)));
            }

            // Prepare for the next DST correction.
            dstFixAt = (  (dstFixes.length > (++dstFixIdx))
                ? dstFixes[dstFixIdx].index()
                : -1 );
        }

        if (!doExclude)
            _addOneRow(clone, new Row(dates[i], _mutateDataPoints(rows[i]._vals, tag)));
    }

    return clone.freeze();
};


/**
 * Validates a date object.  In here, all dates
 * must be immutable dates (IDate).
 * @param {(lim.IDate|string)} date
 * @param {string} argName
 * @returns {lim.IDate}
 */
var _validDate = function (date, argName) {

    if (typeof date === 'string') {

        try {
            return IDate.create(date);
        }
        catch (ex) {

            if (typeof argName !== 'string')
                argName = "date";

            throw new Error("IllegalArgumentException: " + argName + " has unrecognized date format (\\"" + date + "\\")");
        }
    }

    else if (!(date instanceof IDate)) {
        if (typeof argName !== 'string')
            argName = "date";

        throw new TypeError(argName + ": IDate");
    }

    else
        return date;
};

/**
 * Returns a validated time-zone ID.
 * @param {?string} tzArgVal Time-zone ID or name, given to caller method.
 * @returns {{id: string, tz: ?lim.TimeZone}} Validated time-zone ID.
 * @private
 */
function _validTimeZoneId (tzArgVal) {
    if (   tzArgVal !== null
        && tzArgVal !== '' ) {
        var tz = TimeZone.get(tzArgVal);
        return {
            id: tz.id(),
            tz: tz
        };
    } else {
        return {
            id: "",
            tz: null
        };
    }
}

/**
 * Validates a calendar argument for the purpose of filling forward
 * or backward.
 *
 * If valid, this method returns an Array of IDate objects that correspoind
 * to the argument.
 *
 * If not valid, this method throws.
 * @param {(morn.Calendar|lim.IDate[]|morn.TimeSeries|function)} calendar
 * @param {morn.TimeSeries} ts - TimeSeries to use for first and last date,
 *                          if \`calendar\` is a function.
 * @returns {lim.IDate[]}
 * @throws TypeError - If \`calendar\` is an invalid type.
 * @private
 */
var _validFillCalendar = function (calendar, ts) {

    if (   (calendar instanceof Calendar)
        || (calendar instanceof TimeSeries) ) {
        return calendar.dates();
    } else if (Arrays.isArrayOf(calendar, IDate)) {
        return calendar;
    } else if (typeof calendar === "function") {
        var rv = calendar(ts.dateAt(0), ts.dateAt(-1));
        if (rv instanceof Calendar) {
            return rv.dates();
        } else if (Arrays.isArrayOf(rv, IDate)) {
            return rv;
        } else {
            throw new Error(
                "\`calendar\` returned neither a Calendar nor a list of dates"
            );
        }
    } else {
        throw new TypeError("calendar: Calendar, IDate[], TimeSeries or Function");
    }
};


/**
 * Validates an optional integer argument.
 *
 * If the argument was not provided, this method
 * returns \`defaultValue\`.  If provided and valid,
 * this method returns the argument that was provided.
 * Otherwise it throws TypeError.
 *
 * @param {(Arguments|Array)}args
 * @param {Integer} argIdx
 * @param {string} argName
 * @param {*} defaultValue
 * @returns {(Integer|*)}
 * @throws TypeError If the argument was provided but is not of type Integer.
 * @private
 */
var _validIntIfAvail = function (args, argIdx, argName, defaultValue) {

    if (argIdx >= args.length)
        return defaultValue;

    else if (!Numbers.isInteger(args[argIdx]))
        throw new TypeError(argName + ": Integer");

    else
        return args[argIdx];
};

/**
 * Validates an optional boolean argument.
 *
 * If the argument was not provided, this method
 * returns \`defaultValue\`.  If provided and valid,
 * this method returns the argument that was provided.
 * Otherwise it throws TypeError.
 *
 * @param {(Arguments|Array)}args
 * @param {Integer} argIdx
 * @param {string} argName
 * @param {(boolean|*)} defaultValue
 * @returns {(boolean|*)}
 * @throws TypeError If the argument was provided but is not of type Boolean.
 * @private
 */
var _validBoolIfAvail = function (args, argIdx, argName, defaultValue) {

    if (argIdx >= args.length)
        return defaultValue;

    else if (typeof args[argIdx] !== "boolean")
        throw new TypeError(argName + ": Boolean");

    else
        return args[argIdx];
};


var _repeatPush = function (array, value, num) {

    for (var i = 0; i < num; i++)
        array.push(value);

    return array;
};

var _isDataPointFinite = function (dataPoint) {
    return _isFiniteNumber(dataPoint._val);
};

var _isDataPointNotFinite = function (dataPoint) {
    return !_isDataPointFinite(dataPoint);
};


var _dataPointMutation = function (level, dataPoint) {

    var text   = dataPoint._tag,
        src    = dataPoint._src,
        numSrc = src.length;

    if (numSrc > 0) {

        /* Check the level.  If we reach level 0, then we want to stop.
          * Keep in mind level is allowed to be negative to start with;
          * negative values mean keep going until the end. */

        var subText = null;

        if (level === 1)
            subText = '...';

        else {
            var subs = [];
            for (var i = 0; i < numSrc; i++)
                subs.push(_dataPointMutation(level - 1, src[i]));

            subText = subs.join(',');
        }

        text += '(' + subText + ')';
    }

    return text;
};

/**
 * @constructor
 * @name morn.TimeSeries.DataPoint
 *
 * @param {number} value
 * @param {string} tag
 * @param {(morn.TimeSeries.DataPoint|morn.TimeSeries.DataPoint[])} [sourceDataPoints]
 *                         If DataPoint[], this constructor freezes
 *                         the array; caller should not (it cannot) modify the
 *                         array after calling this constructor.
 * @return {morn.TimeSeries.DataPoint} Must use <em>new</em> constructor keyword.
 */
var DataPoint = function (value, tag, sourceDataPoints) {

    if (typeof value !== 'number')
        throw new TypeError("value: Number");

    if (typeof tag !== 'string')
        throw new TypeError("tag: String");

    var src = null;

    if (arguments.length < 3) {
        src = Arrays.EMPTY;
    } else if (sourceDataPoints instanceof DataPoint) {
        src = Object.freeze([sourceDataPoints]);
    } else if (Arrays.isArrayOf(sourceDataPoints, DataPoint)) {
        src = Object.freeze(sourceDataPoints);
    } else {
        throw new TypeError("sourceDataPoints: DataPoint or DataPoint[]");
    }

    if ((++_dataPointCnt) > _dataPointMax) {
        throw new Error(
            "Number-of-DataPoint limit exceeded: too many TimeSeries or TimeSeries too big!"
        );
    }

    this._val = value;
    this._tag = tag;
    this._src = src;

    Object.freeze(this);
};

DataPoint.prototype = /** @lends morn.TimeSeries.DataPoint.prototype */ {
    constructor: DataPoint,

    /**
     * Returns the numeric value associated with this data-point.
     * @return {number} Can be <em>Number.NaN</em>.
     */
    value: function () {
        return this._val;
    },

    /**
     * Returns <em>true</em> if this DataPoint is NaN, +Infinity
     * or -Infinity.  Returns <em>false</em> otherwise.
     * @return {boolean}
     */
    isNaN: function () {
        return !_isDataPointFinite(this);
    },

    /**
     * Creates a new DataPoint from the current one with the specified
     * <code>value</code>.  The new DataPoint is linked to the current
     * DataPoint, for mutation history.
     * @param value {number}
     * @param tag {string}
     * @return {morn.TimeSeries.DataPoint}
     */
    mutate: function (value, tag) {
        return new DataPoint(value, tag, this);
    },


    /**
     * Returns the history of this DataPoint.
     * @param [historyLength] {integer}
     * @return {string[]}
     */
    mutation: function (historyLength) {

        if (Utils.isVoid(historyLength))
            historyLength = -1;

        else if (!Numbers.isInteger(historyLength))
            throw new TypeError("historyLength: Integer");

        return _dataPointMutation(historyLength, this);
    }

};

/* **************************************************
  * STATIC METHODS and VARIABLES
  * ************************************************** */
_.extend(DataPoint, /** @lends morn.TimeSeries.DataPoint */ {

    /**
     * A re-usable, immutable, static instance for <em>NaN</em> values.
     * @type morn.TimeSeries.DataPoint
     */
    NaN: new DataPoint(Number.NaN, "NaN")

});

Object.freeze(DataPoint);
Object.freeze(DataPoint.prototype);


/**
 * @constructor
 * @alias morn.TimeSeries.Row
 *
 * @param date {(lim.IDate|string)}
 * @param dataPoints {(morn.TimeSeries.DataPoint|morn.TimeSeries.DataPoint[])} If an array is passed,
 *                   this method freezes it; caller must not try to
 *                   modify that Array after calling this constructor.
 * @return {morn.TimeSeries.Row}
 */
var Row = function (date, dataPoints) {

    date = _validDate(date);

    var vals = null;

    if (dataPoints instanceof DataPoint)
        vals = Object.freeze([dataPoints]);

    else if (Arrays.isArrayOf(dataPoints, DataPoint))
        vals = Object.freeze(dataPoints);

    else
        throw new TypeError("dataPoints: DataPoint or DataPoint[]");

    /** @type {lim.IDate} */
    this._date = date;

    /** @type {morn.TimeSeries.DataPoint[]} */
    this._vals = vals;

    Object.freeze(this);
};

/**
 * Scans all numeric (non-NaN) data-points of a row,
 * computing some value from it.
 */
var _rowScan = function (vals, handler, defValue) {

    var run = null;

    for (var i = 0, numVals = vals.length; i < numVals; i++) {

        var val = vals[i];

        if (!val.isNaN())
            run = handler(run, val.value());
    }

    if (run !== null)
        return run;
    else
        return defValue;
};

var _rowHandlers = {

    max: function (run, value) {

        if (   run === null
            || run < value )
            return value;
        else
            return run;
    },

    min: function (run, value) {

        if (   run === null
            || run > value )
            return value;
        else
            return run;
    },

    count: function (run, value) {

        if (run === null)
            return 1;
        else
            return (run + 1);
    },

    sum: function (run, value) {

        if (run === null)
            return value;
        else
            return run + value;
    },

    avg: function (run, value) {

        if (run === null) {
            return {
                cnt: 1,
                sum: value
            };
        }
        else {
            run.cnt++;
            run.sum += value;
            return run;
        }
    }
};


Row.prototype = /** @lends {morn.TimeSeries.Row.prototype} */ {
    constructor: Row,

    /**
     * Returns the date associated with this row.
     * @returns {lim.IDate}
     */
    date: function () {
        return this._date;
    },

    /**
     * Returns the number of columns within this row.
     * @returns {Integer}
     */
    numColumns: function () {
        return this._vals.length;
    },

    /**
     * Returns a Array (copy) of the data-points within the row.  Use this method
     * when you need to efficiently re-create a row large row with mostly the same
     * data-point.
     * @return {morn.TimeSeries.DataPoint[]}
     */
    dataPoints: function () {
        return this._vals.slice(0);
    },

    /**
     * Returns the DataPoint from this Row, at the (optionally) given column.
     * @param {Integer} [columnIndex=0]
     * @returns {morn.TimeSeries.DataPoint}
     */
    dataPoint: function (columnIndex) {

        var vals = this._vals;

        if (typeof columnIndex === 'undefined')
            columnIndex = 0;  // default is first column

        else if (!Arrays.isValidIndex(columnIndex, vals))
            throw new TypeError("columnIndex: Integer, 0-" + vals.length);

        return vals[columnIndex];
    },

    /**
     * Returns the numeric value from this Row, at the (optionally) given column.
     * @param {Integer} [columnIndex=0]
     * @returns {number}
     */
    value: function (columnIndex) {
        return this.dataPoint(columnIndex).value();
    },

    /**
     * Return an array of numeric values, representing
     * all columns in this row.
     * @return {number[]}
     */
    values: function () {
        return _.map(this._vals, function (dataPoint) {
            return dataPoint._val;
        });
    },

    /**
     * Returns <em>true</em> if this Row does not contain
     * a single <em>finite</em> number.  That is, if
     * all DataPoints in this row are NaN, +Infinity
     * or -Infinity.  Returns <em>false</em> otherwise.
     * @return {boolean}
     */
    isNaN: function () {
        return !_.some(this._vals, _isDataPointFinite);
    },

    /**
     * Returns <em>true</em> if this Row contains at least
     * one NaN, +Infinity or -Infinity value.  Returns
     * <em>false</em> otherwise.
     * @return {boolean}
     */
    containsNaN: function () {
        return _.some(this._vals, _isDataPointNotFinite);
    },

    /**
     * Returns the highest numeric value found in this row
     * (amongst the columns), or <em>NaN</em> if no numeric
     * columns are found.
     * @return {number} The highest value.
     */
    max: function () {
        return _rowScan(this._vals, _rowHandlers.max, Number.NaN);
    },

    /**
     * Returns the lowest numeric value found in this row
     * (amongst the columns), or <em>NaN</em> if no numeric
     * columns are found.
     * @return {number} The lowest value.
     */
    min: function () {
        return _rowScan(this._vals, _rowHandlers.min, Number.NaN);
    },

    /**
     * Returns the count of numeric values found in this row
     * (amongst the columns), or <em>0</em> if no numeric
     * columns are found.
     * @return {integer}
     */
    count: function () {
        return _rowScan(this._vals, _rowHandlers.count, 0);
    },

    /**
     * Returns the sum of numeric values found in this row
     * (amongst the columns), or <em>0</em> if no numeric
     * columns are found.
     * @return {integer}
     */
    sum: function () {
        return _rowScan(this._vals, _rowHandlers.sum, 0);
    },

    /**
     * Returns the average of all numeric values found in this row
     * (amongst the columns), or <em>NaN</em> if no numeric
     * columns are found.
     * @return {integer}
     */
    average: function () {

        var res = _rowScan(this._vals, _rowHandlers.avg, null);

        if (res !== null)
            return (res.sum / res.cnt);
        else
            return Number.NaN;
    },

    /**
     * Returns the spread of numeric values found in this row
     * (amongst the columns), or <em>NaN</em> if no numeric
     * columns are found.
     * @return {Integer}
     */
    spread: function () {

        var min = this.min();
        if (_isFiniteNumber(min))
            return (this.max() - min);
        else
            return Number.NaN;
    }
};

Object.freeze(Row);
Object.freeze(Row.prototype);


/**
 * Represents a rows of numeric data, tied to a date.
 *
 * @constructor
 * @alias morn.TimeSeries
 */
var TimeSeries = function (size) {
    if (!Numbers.isPositiveInteger(size)) {
        throw new TypeError("size: Integer, positive");
    }
    this._rows = [];
    this._size = size;
    this._index = {};
    this._isSorted = true;
    this._tz = null;
    this._header = [];
    // IMPORTANT: Any property defined here should also be handled in _cloneTimeSeries()
};

/* *************************************************
  * Private variables (TimeSeries)
  * ************************************************* */

var _empties = {};

/* *************************************************
  * Private functions (TimeSeries)
  * ************************************************* */

/**
 * Returns an empty, immutable TimeSeries with
 * the specified number of columns.
 * @param size {integer} positive.
 * @return {morn.TimeSeries}
 */
var _getEmpty = function (size) {

    if (!_empties.hasOwnProperty(size)) {
        var emptyTs = new TimeSeries(size).freeze();
        _empties[size] = emptyTs;
    }

    return _empties[size];
};

/**
 * Clones a given TimeSeries; sets same size (number of columns) and time-zone.  No data.
 * @param {morn.TimeSeries} timeSeries
 * @returns {morn.TimeSeries} Empty TimeSeries set with same size and time-zone.
 * @private
 */
var _newTs = function (timeSeries) {
    var clone = new TimeSeries(timeSeries._size);
    clone._tz = timeSeries._tz;
    return clone;
};

/**
 * @param {morn.TimeSeries[]} tsList
 * @returns {?morn.TimeZone} Time-zone common to all TimeSeries in \`tsList\`, or null if
 *          there's any discrepency.
 * @private
 */
var _getTz = function (tsList) {

    var tz = tsList[0]._tz;

    for (var i = 1, len = tsList.length;
          i < len && tz !== null;
          i++) {

        if (tz !== tsList[i]._tz)
            tz = null;  // Different TZ found, return \`null\`.
    }

    return tz;
};

/**
 * @param {morn.TimeSeries.Row} row1
 * @param {morn.TimeSeries.Row} row2
 * @returns {int}
 * @private
 */
var _compareRowDate = function (row1, row2) {
    return IDate.compare(row1.date(), row2.date());
};


var _buildIndex = function (ts) {

    var rows  = ts._rows,
        index = {};

    for (var i = 0, len = rows.length; i < len; i++)
        index[rows[i].date().getTime()] = i;

    ts._index = index;

    return ts;
};


var _dateIndex = function (dates) {

    var index = {};

    for (var i = 0, len = dates.length; i < len; i++)
        index[dates[i].getTime()] = i;

    return index;
};


/**
 * Returns the rows of a TimeSeries sorted in chronological order.
 * @param {morn.TimeSeries}
 * @return {morn.TimeSeries.Row[]}
 */
var _getSortedRows = function (ts) {

    var rows = ts._rows;

    if (!ts._isSorted) {
        rows.sort(_compareRowDate);

        _buildIndex(ts)._isSorted = true;
    }

    return rows;
};


var _getDateIdx = function (ts, date) {

    // Sort first.
    _getSortedRows(ts);

    var time = date.getTime(),
        idx  = ts._index;

    if (idx.hasOwnProperty(time))
        return idx[time];

    else
        return -1;
};

var _validNumRows = function (numRows) {

    if (arguments.length < 1)
        return 1;

    else if (!Numbers.isInteger(numRows))
        throw new TypeError("numRows: Integer");

    else
        return numRows;
};

var _validRowIndex = function (ts, idx, isLenient) {

    if (!Numbers.isInteger(idx))
        throw new TypeError("idx: Integer");

    var numRows = ts._rows.length;

    if (idx >= 0) {
        if (idx >= numRows) {
            if (isLenient)
                return numRows;
            else
                throw new Error("ArrayIndexOutOfBoundException: idx: -" + numRows + " - " + numRows);
        }

        return idx;
    }

    else if (idx < -numRows) {

        if (isLenient)
            return 0;
        else
            throw new Error("ArrayIndexOutOfBoundException: idx: -" + numRows + " - " + numRows);
    }
    else
        return (idx + numRows);

};

var _validRowIndexSorted = function (ts, idx, isLenient) {

    var rv = _validRowIndex(ts, idx, isLenient);

    // If we haven't thrown an exception, sort the array
    // before returning the value.
    _getSortedRows(ts);

    return rv;
};


var _toTime = function (timeVal) {

    var typeOf = typeof timeVal;
    if (typeOf === 'number') {
        if (Numbers.isInteger(timeVal))
            return timeVal;
    }

    else if (typeOf === 'string') {
        var time = IDate.stringToTime(timeVal);
        if (time !== null)
            return time;
    }

    throw new TypeError("timeVal: integer or string");
};


var _getTimeSelector = function (start, end, isPeriodEnding) {

    start = _toTime(start);
    end   = _toTime(end);

    if (arguments.length < 3)
        isPeriodEnding = false;

    else if (typeof isPeriodEnding !== 'boolean')
        throw new TypeError("isPeriodEnding: boolean");

    var selector = null;

    if (isPeriodEnding === false) {

        selector = function (row, rowIdx) {

            var time = row._date.getTimeMillis();
            return (   time >= start
                && time <  end );
        };
    }
    else {

        selector = function (row, rowIdx) {

            var time = row.date().getTimeMillis();
            return (   time >  start
                && time <= end );
        };
    }

    return selector;
};

var _getDateView = function (isPeriodEnding, dayCutoff) {

    var msOffset = 0;

    if (isPeriodEnding === true)
        msOffset = -1;

    if (dayCutoff !== 0) {
        if (dayCutoff >= IDate.MILLIS_PER_HOUR * 12)  // noon
            msOffset += IDate.MILLIS_PER_DAY - dayCutoff;
        else
            msOffset -= dayCutoff;
    }

    return function (date) {
        return date.addMilliseconds(msOffset);
    };
};

var _getDayOfWeekSelector = function (daysOfWeek, isPeriodEnding, dayCutoff) {

    var days = Arrays.newInstance(7, 0);
    for (var i = 0; i < daysOfWeek.length; i++)
        days[daysOfWeek[i]] = 1;

    var dateView = _getDateView(isPeriodEnding, dayCutoff);

    return function (row, rowIdx) {
        return (days[dateView(row._date).getDay()] > 0);
    };
};

var _getPeakArgs = function (ts, args) {

    // TODO support a single Object argument, for those who like
    // named arguments.

    var numArgs        = args.length,
        holidays       = null,
        start          = "7:00",
        end            = "22:00",
        isPeriodEnding = false,
        daysOfWeek     = IDate.WEEK_DAYS;

    if (numArgs > 0) {

        if (args[0] !== null) {
            if (args[0] instanceof Holidays)
                holidays = args[0];

            else if (typeof args[0] === 'string') { // Calendar name

                var startYear = 2015,
                    endYear   = 2015;

                if (!ts.isEmpty()) {
                    startYear = ts.dateAt(0).getFullYear();
                    endYear   = ts.dateAt(-1).getFullYear();
                }

                holidays = Holidays.getCalendar(args[0], startYear, endYear);
            }
            else
                throw new TypeError("holidays: Holidays or String");
        }


        // The next three arguments will be validated as part
        // of \`_getTimeSelector()\`
        if (numArgs > 1) {

            if (args[1] !== null)
                start = args[1];

            if (numArgs > 2) {

                if (args[2] !== null)
                    end = args[2];

                if (numArgs > 3) {

                    if (args[3] !== null)
                        isPeriodEnding = args[3];

                    if (numArgs > 4) {

                        if (args[4] !== null) {
                            daysOfWeek = args[4];
                        }
                    }
                    if(numArgs > 6 && args[6])
                    {
                        if(typeof args[6] === 'string'){
                            var isoArgs = _getISOArgs(args);
                            start = isoArgs.start;
                            end = isoArgs.end;
                            daysOfWeek = isoArgs.daysOfWeek;
                          }
                        else
                        {
                            throw new TypeError("iso: Expected type String");
                        }
                    }
                }
            }
        }
    }

    // Now validating \`start\`, \`end\` and \`isPeriodEnding\`
    var filter = _getTimeSelector(start, end, isPeriodEnding);

    return {
        holidays:       holidays,
        start:          start,
        end:            end,
        isPeriodEnding: isPeriodEnding,
        timeSelector:   filter,
        daysOfWeek:     daysOfWeek
    };
};
// This method will return an object with default values for specific iso
var _getISOArgs = function(args){
    var iso = args[6],
        daysOfWeek = args[4],
        start = args[1],
        end = args[2];

    // Every ISO has same default value for all three parameters for now, can be changed as required
    switch (iso.toUpperCase()) {

        case 'CAISO':
            return {
                daysOfWeek : daysOfWeek?daysOfWeek:Object.freeze([1,2,3,4,5,6]),
                start : start?start:"07:00",
                end : end?end:"22:00"
            }
        case 'ERCOT':
            return {
                daysOfWeek : daysOfWeek?daysOfWeek:IDate.WEEK_DAYS,
                start : start?start:"07:00",
                end : end?end:"22:00"
            }
        case 'MISO':
            return {
                daysOfWeek : daysOfWeek?daysOfWeek:IDate.WEEK_DAYS,
                start : start?start:"08:00",
                end : end?end:"23:00"
            }
        case 'PJM':
            return {
                daysOfWeek : daysOfWeek?daysOfWeek:IDate.WEEK_DAYS,
                start : start?start:"08:00",
                end : end?end:"23:00"
            }
        case 'NYISO':
            return {
                daysOfWeek : daysOfWeek?daysOfWeek:IDate.WEEK_DAYS,
                start : start?start:"08:00",
                end : end?end:"23:00"
            }
        case 'AESO':
            return {
                daysOfWeek : daysOfWeek?daysOfWeek:IDate.WEEK_DAYS,
                start : start?start:"08:00",
                end : end?end:"23:00"
            }
        case 'SPP':
            return {
                daysOfWeek : daysOfWeek?daysOfWeek:IDate.WEEK_DAYS,
                start : start?start:"07:00",
                end : end?end:"22:00"
            }
        case 'ISONE':
            return {
                daysOfWeek : daysOfWeek?daysOfWeek:IDate.WEEK_DAYS,
                start : start?start:"08:00",
                end : end?end:"23:00"
            }
        default:
            throw new Error("IllegalArgumentException: unsupported iso name (" + iso + ")");
    }
}

/**
 * Converts \`obj\` to an array of dates.
 *
 * @param {(lim.IDate[]|lim.IDate|string|*)} obj
 *        Object to convert to an array of date.
 *        If a <em>string</em>, must be one ISO-compliant date string.
 *        The '*' means \`obj\` can be any instance of the \`supportedTypes\`.
 * @param {string} exceptionMsg - Message to inject into TypeError exception
 *        if \`obj\` cannot be converted to an array of IDate objects.
 * @param {...function} supportedTypes - Other supported types, or classes;
 *        instances must implement method <code>dates()</code>.
 *
 * @returns {lim.IDate[]}
 * @private
 */
var _toDateArray = function (obj, exceptionMsg, supportedTypes) {

    for (var i = 2; i < arguments.length; i++) {
        if (obj instanceof arguments[i])
            return obj.dates();
    }

    if (obj instanceof IDate)
        return [obj];

    else if (typeof obj === 'string')
        return [IDate.create(obj)];

    else if (Arrays.isArrayOf(obj, IDate))
        return obj;

    else
        throw new TypeError(exceptionMsg);
};


/**
 * Returns the index position where a row should be stored
 * within a list of rows
 */
var _getRowIdx = function (ts, row) {
    return _getDateIdx(ts, row.date());
};



var _getClosestIndex = function (ts, date, findNext) {

    date = _validDate(date);
    var idx = _getDateIdx(ts, date);
    if (idx >= 0)
        return idx;

    else
        return Arrays[findNext]( date,
            ts._rows,
            'date()',
            IDate.compare );
};


var _getFloorIndex = function (ts, date) {
    return _getClosestIndex(ts, date, 'indexFloor');
};

var _getFloorOrFirstIndex = function (ts, date) {

    var idx = _getFloorIndex(ts, date);
    if (idx >= 0)
        return idx;
    else if (ts._rows.length > 0)
        return 0;
    else
        return -1;
};

var _getCeilIndex = function (ts, date) {
    return _getClosestIndex(ts, date, 'indexCeiling');
};

var _getCeilOrLastIndex = function (ts, date) {

    var idx = _getCeilIndex(ts, date);
    if (idx >= 0)
        return idx;
    else if (ts._rows.length > 0)
        return (ts._rows.length - 1);
    else
        return -1;
};


var _getRowAtIndex = function (ts, idx, ex) {

    if (idx >= 0)
        return ts._rows[idx];

    else if (typeof ex === 'string')
        throw new Error(ex);

    else
        throw new Error("IllegalArgumentException: date not found");
};

var _getRange = function (ts, args) {

    var startIdx = 0,
        endIdx   = ts.length(),
        numArgs  = args.length;

    if (numArgs > 0) {

        var start = args[0];
        if (Numbers.isInteger(start))  // index position
            startIdx = _validRowIndexSorted(ts, start, true);

        else {  // date
            startIdx = ts.ceilIndex(start);
            if (startIdx < 0)
                startIdx = endIdx;  // start at length() which returns nothing.
        }

        if (numArgs > 1) {

            var end = args[1];

            if (Numbers.isInteger(end))  // index position
                endIdx = _validRowIndexSorted(ts, end, true);

            else {  // date
                endIdx = ts.floorIndex(end);
                if (endIdx < 0)
                    endIdx = 0;  // Will end before it starts.
                else {
                    // 1-up, endIdx is exclusive, but we want dates
                    // to be inclusive.
                    endIdx++;
                }
            }
        }
    }

    return {
        start: startIdx,
        end:   endIdx
    };
};

var _dataPointAsOfIndex = function (ts, idx, columnIndex) {

    var dp   = DataPoint.NaN,
        rows = ts._rows,
        i    = idx;

    while (   i >= 0
    && dp.isNaN() )
        dp = rows[i--].dataPoint(columnIndex);

    return dp;
};

var _dataPointAsOf = function (ts, date, columnIndex) {
    return _dataPointAsOfIndex(ts, _getFloorIndex(ts, date), columnIndex);
};

var _dataPointsAsOf = function (date, tsList) {

    var dataPoints = [];

    for (var i = 0, numTs = tsList.length; i < numTs; i++) {

        var ts     = tsList[i],
            rowIdx = _getFloorIndex(ts, date);

        for (var j = 0, numCols = ts._size; j < numCols; j++)
            dataPoints.push(_dataPointAsOfIndex(ts, rowIdx, j));
    }

    return dataPoints;
};

var _dataPointOfOrNextIndex = function (ts, idx, columnIndex) {
    var dp      = DataPoint.NaN,
        rows    = ts._rows,
        i       = idx,
        numRows = rows.length;
    if(i >= 0) {
        while (i < numRows
        && dp.isNaN())
            dp = rows[i++].dataPoint(columnIndex);
    }
    return dp;
};

var _dataPointOfOrNext = function (ts, date, columnIndex) {
    return  _dataPointOfOrNextIndex(ts, _getCeilIndex(ts, date), columnIndex);
};

var _cloneTimeSeries = function (ts, range) {
    /** @type {morn.TimeSeries} */
    var clone = _newTs(ts);

    if (arguments.length >= 2) {
        clone._rows = ts._rows.slice(range.start, range.end);
    } else {
        // All rows
        clone._rows = ts._rows.slice(0);
    }
    _buildIndex(clone);
    clone._isSorted = ts._isSorted;
    return clone;
};


/**
 * This function DOES NOT assume that the rows are sorted.
 * @param {morn.TimeSeries} ts
 * @param {Arguments} args - Caller's \`arguments\`.
 * @returns {*|morn.TimeSeries|Array.<T>|string|Blob|ArrayBuffer}
 * @private
 */

var _getRowsSubset = function (ts, args) {

    _getSortedRows(ts);

    var range = _getRange(ts, args);
    return ts._rows.slice(range.start, range.end);
};


/**
 * Adds all rows to \`ts\`, assumes \`rows\` may not be sorted.
 * @param {morn.TimeSeries} ts
 * @param {morn.TimeSeries.Row[]} rows
 * @private
 */
function _addRowsUnsorted (ts, rows) {
    // Sort \`rows\` only once, it's more efficient than sorting and re-building the index
    // each time a row is found to be out-of-order.
    rows.sort(_compareRowDate);
    _addRowsSorted(ts, rows);
}

/**
 * This method removes all non-number chars from string
 * @param date
 * @returns {*}
 */
function dateToNumber( date) {
    if(isNaN(date)) {
        return date.replace(/[-\\/]/g,"");
    }
    return date;
}
/**
 * Adds all rows to \`ts\`, assumes \`rows\` is already sorted by date, ascending.
 * @param {morn.TimeSeries} ts Mutable TimeSeries.
 * @param {morn.TimeSeries.Row[]} rows Rows to add.
 * @private
 */
function _addRowsSorted(ts, rows) {
    for (var i = 0, numRows = rows.length; i < numRows; i++) {
        _addOneRow(ts, rows[i]);
    }
}

/**
 * Adds one row to \`ts\`, resorts \`ts\` if necessary.
 * @param {morn.TimeSeries} ts
 * @param {morn.TimeSeries.Row} row
 * @returns {morn.TimeSeries} Always returns \`ts\`.
 * @private
 */
function _addOneRow (ts, row) {

    var idx  = _getRowIdx(ts, row),
        rows = ts._rows;

    if (idx >= 0) {
        rows[idx] = row;
    } else {
        idx = rows.length;
        var time = row.date().getTime();
        if (idx > 0 && rows[idx - 1].date().getTime() > time) {
            ts._isSorted = false;
        }
        rows.push(row);
        ts._index[time] = idx;
    }
    return ts;
}

/**
 * Converts one or more numbers to an array of DataPoint objects.
 * @param {(number|number[])} values
 * @param {string} tag
 * @returns {morn.TimeSeries.DataPoint[]}
 * @private
 */
var _toDataPoints = function (values, tag) {

    var dataPoints = [];
    if (typeof values === "number")
        dataPoints.push(new DataPoint(values, tag));

    else {
        for (var i = 0, len = values.length; i < len; i++)
            dataPoints.push(new DataPoint(values[i], tag));
    }

    return dataPoints;
};

/**
 * Creates a new row.
 * @param {lim.IDate} date
 * @param {(number|number[])} values
 * @param {string} tag
 * @returns {morn.TimeSeries.Row}
 * @private
 */
var _newRow = function (date, values, tag) {
    return new Row(date, _toDataPoints(values, tag));
};


var _countColumns = function (list) {

    var total = 0;

    for (var i = 0, len = list.length; i < len; i++)
        total += list[i]._size;

    return total;
};

/**
 * Validates an argument to be a TimeSeries instance.
 * If valid, this method returns the validated TimeSeries.
 * If not valid, this throws a TimeError exception.
 *
 * @param {morn.TimeSeries} timeSeries
 * @param {string} [argName="timeSeries"]
 * @returns {morn.TimeSeries}
 * @throws TypeError - If \`timeSeries\` is not a TimeSeries instance.
 * @private
 */
var _validTimeSeries = function (timeSeries, argName) {

    if (!(timeSeries instanceof TimeSeries)) {

        if (arguments.length < 2)
            argName = "timeSeries";

        throw new TypeError(argName + ": TimeSeries");
    }

    return timeSeries;
};

/**
 * Validates that \`list\` is a non-empty array of TimeSeries.
 * @param {morn.TimeSeries[]} list
 * @returns {morn.TimeSeries[]} Always returns \`list\`.
 * @throws TypeError If any item is not a TimeSeries object or if \`list\` is empty.
 * @private
 */
var _validTimeSeriesList = function (list) {
    if (   !Arrays.isArrayOf(list, TimeSeries)
        || list.length === 0 ) {
        throw new TypeError("list: TimeSeries[]");
    }
    return list;
};

/**
 * Creates a new array with \`first\` inserted at position 0.
 *
 * @param {morn.TimeSeries} first
 * @param {morn.TimeSeries[]} args
 * @returns {morn.TimeSeries[]}
 * @private
 */
var _insertAt0 = function (first, args) {

    var list = Arrays.slice(args);
    list.unshift(first);
    return list;
};

/**
 * Appends \`obj\` to \`list\`, but only if \`obj != null\`.
 * @param {Array} list
 * @param {*} obj
 * @private
 */
var _addNonNull = function (list, obj) {

    if (obj !== null)
        list.push(obj);
};



var _fwdToInstance = function (methodName, origArgs) {

    var inst = _validTimeSeries(origArgs[0]),
        args = Arrays.slice(origArgs, 1);

    return inst[methodName].apply(inst, args);

};

/**
 * @param {morn.TimeSeries[]} list
 * @returns {lim.IDate[]} Array of dates representing superset found across \`list\`, sorted.
 * @private
 */
var _unionDates = function (list) {

    _validTimeSeriesList(list);

    var ts    = list[0],
        dates = ts.dates();

    if (list.length === 1) {
        return dates;
    }

    var dateIdx = _.extend({}, ts._index);  // order may be wrong, but it doesn't matter
    for (var i = 1, len = list.length; i < len; i++) {
        ts = list[i];
        for (var j = 0, numRows = ts.length(); j < numRows; j++) {

            // Access internal _rows directly for better performance.
            var date = ts._rows[j].date(),
                time = date.getTime();

            if (!dateIdx.hasOwnProperty(time)) {
                dateIdx[time] = dates.length;
                dates.push(date);
            }
        }
    }
    dates.sort(IDate.compare);
    return dates;
};

/**
 * Returns the intersecting dates.
 * @param {morn.TimeSeries[]} list
 * @returns {lim.IDate[]} Array of dates representing dates common to all TimeSeries
 *          in \`list\`, sorted.
 * @private
 */
var _intersectionDates = function (list) {

    _validTimeSeriesList(list);

    var ts    = list[0],
        dates = ts.dates();

    if (list.length === 1) {
        return dates;
    }

    var confirmed = [];
    for (var j = 0, numDates = dates.length; j < numDates; j++) {
        var date  = dates[j],
            time  = date.getTime(),
            inAll = true;

        for ( var i = 1, len = list.length;
              i < len && inAll;
              i++ ) {

            // Access internal _index directly for better performance.
            if (!list[i]._index.hasOwnProperty(time)) {
                inAll = false;
            }
        }
        if (inAll) {
            confirmed.push(date);
        }
    }
    confirmed.sort(IDate.compare);
    return confirmed;
};

/**
 * Joins TimeSeries together, limiting or expanding each TimeSeries' dates to \`dates\`.
 * @param {morn.TimeSeries[]} list
 * @param {lim.IDate[]} dates
 * @returns {morn.TimeSeries} Joined TimeSeries, made of all provided in \`list\`.
 * @private
 */
var _joinTimeSeries = function (list, dates) {

    var numSeries = list.length,
        joined    = new TimeSeries(_countColumns(list));

    joined._tz = _getTz(list);

    for (var i = 0, numDates = dates.length; i < numDates; i++) {

        var date       = dates[i],
            time       = date.getTime(),
            dataPoints = [];

        for (var j = 0; j < numSeries; j++) {

            // Access internal variables directly for better performance.

            var ts    = list[j],
                index = ts._index;

            if (index.hasOwnProperty(time)) {
                Arrays.addAll(dataPoints, ts._rows[index[time]]._vals);
            } else {
                _repeatPush(dataPoints, DataPoint.NaN, ts._size);
            }
        }
        _addOneRow(joined, new Row(date, dataPoints));
    }
    return joined.freeze();
};

/**
 * Unionizes the list of TimeSeries object in a single
 * TimeSeries that contains all dates found across all
 * TimeSeries in the given list.
 *
 * @param {morn.TimeSeries[]} tsList
 * @returns {morn.TimeSeries}
 * @private
 */
var _union = function (tsList) {
    if (tsList.length === 1) {
        return tsList[0];
    }
    return _joinTimeSeries(tsList, _unionDates(tsList));
};

/**
 * Unionizes the list of TimeSeries object in a single
 * TimeSeries that contains dates common to all.
 *
 * @param {morn.TimeSeries[]} tsList
 * @returns {morn.TimeSeries}
 * @private
 */
var _intersection = function (tsList) {
    if (tsList.length === 1) {
        return tsList[0];
    }
    return _joinTimeSeries(tsList, _intersectionDates(tsList));
};




/**
 * This method copies rows from other TimeSeries
 * into the first TimeSeries, but leaves all instances
 * unmodified; it clones the first TimeSeries when
 * a change is needed.
 *
 * It copies NaN rows only if no row already exists.
 * It overwrites NaN rows even when <code>doOverwrite</code>
 * is <em>false</em>.
 *
 * Rows are copied whole: this method does not create
 * rows with data-points from different rows.
 * @param {morn.TimeSeries[]} list
 * @param {boolean} doOverwrite
 */
var _mergeTimeSeries = function (list, doOverwrite) {
    _validTimeSeriesList(list);
    var hasMismatch = _.some(list, function (ts) {
        return (ts._size !== list[0]._size);
    });
    if (hasMismatch) {
        throw new Error("IllegalArgumentException: mismatch number of columns");
    }

    var targetRows = list[0]._rows;
    for (var i = 1; i < list.length; i++) {
        targetRows = _mergeRows(targetRows, list[i]._rows, doOverwrite);
    }

    var clone = _newTs(list[0]);
    _addRowsSorted(clone, targetRows);
    clone.freeze();
    return clone;
};


/**
 * This method add header to TimeSeries
 *
 * @param {morn.TimeSeries[]} seriesList
 * @param {String[]} colsList
 */
var _addHeader = function(seriesList,colsList){
    var clone = _newTs(seriesList);
    for(var i = 0; i< colsList.length ; i++){
        clone._header.push(colsList[i]);
    }
    _addRowsSorted(clone,seriesList._rows)
    clone.freeze();
    return clone;
}

/**
 * Merges two sets of sorted rows.
 * @param {morn.TimeSeries.Row[]} leftRows
 * @param {morn.TimeSeries.Row[]} rightRows
 * @param {boolean} doOverwrite
 * @returns {morn.TimeSeries.Row[]} Merged rows, sorted.
 * @private
 */
function _mergeRows(leftRows, rightRows, doOverwrite) {

    var iLeft = 0;
    var iRight = 0;
    var merged = [];

    while (iLeft < leftRows.length && iRight < rightRows.length) {
        var leftRow = leftRows[iLeft];
        var rightRow = rightRows[iRight];
        var c = IDate.compare(leftRow._date, rightRow._date);
        if (c < 0) {
            merged.push(leftRow);
            iLeft++;
        } else if (c > 0) {
            merged.push(rightRow);
            iRight++;
        } else {
            if (leftRow.isNaN() || (doOverwrite && !rightRow.isNaN())) {
                merged.push(rightRow);
            } else {
                merged.push(leftRow);
            }
            iLeft++;
            iRight++;
        }
    }

    // Copy remaining rows.  Only one array has anything remaining; which one we process
    // first does not matter.
    while (iLeft < leftRows.length) {
        merged.push(leftRows[iLeft++]);
    }
    while (iRight < rightRows.length) {
        merged.push(rightRows[iRight++]);
    }
    return merged;
}


var _val0VsRange = function (thisTimeSeries, thatTimeSeries, isWithin) {

    if (!(thatTimeSeries instanceof TimeSeries))
        throw new TypeError("thatTimeSeries: TimeSeries");

    if (thisTimeSeries._size > 1)
        throw new Error("IllegalStateException: thisTimeSeries.size() > 1");

    if (thatTimeSeries._size < 2)
        throw new Error("IllegalStateException: thatTimeSeries.size() < 2");

    var list = [thatTimeSeries];

    return thisTimeSeries.select(function (row) {

        var dp = row._vals[0];

        if (dp.isNaN())
            return false;

        var val  = dp._val,
            vals = _dataPointsAsOf(row._date, list),
            min  = _rowScan(vals, _rowHandlers.min, Number.NaN);

        if (!_isFiniteNumber(min))
            return false;

        else {

            var max = _rowScan(vals, _rowHandlers.max, Number.NaN);

            return ( (   val >= min
                && val <= max ) === isWithin );
        }
    });
};

var _reduceCols = function (tsList, tag, reducer, skipNaNs, skipNanOverride) {


    if (   skipNanOverride === true
        && tsList.length > 0
        && typeof tsList[0] === 'boolean' ) {

        tsList = Arrays.slice(tsList);  // Convert to real Array object (may be Arguments)
        skipNaNs = tsList.splice(0, 1)[0];
    }

    var dates       = _unionDates(tsList),
        numCols     = _countColumns(tsList),
        clone       = new TimeSeries(1),
        dp          = null,
        reducerRec  = _reducerGet(reducer),
        initValue   = reducerRec.initValue,
        resolver    = reducerRec.resolver,
        hasInitVal  = (typeof initValue !== 'undefined'),
        hasResolver = (typeof reducerRec.resolver === 'function'),
        startIdx    = ((hasInitVal) ? 0 : 1);

    clone._tz = _getTz(tsList);

    for (var i = 0, numDates = dates.length; i < numDates; i++) {

        var date       = dates[i],
            dataPoints = _dataPointsAsOf(date, tsList),
            isAllGood  = true,
            memo;

        if (hasInitVal)
            memo = Utils.clone(initValue);
        else
            memo = dataPoints[0].value();

        for ( var j = startIdx;
              j < numCols && isAllGood;
              j++ ) {

            dp = dataPoints[j];
            if (   !skipNaNs
                && !_isDataPointFinite(dp) )
                isAllGood = false;
            else
                memo = reducerRec.iteratee(memo, dataPoints[j].value(), j);  // TODO values?
        }

        dp = new DataPoint( ( (isAllGood)
            ? ( (hasResolver)
                ? resolver(memo)
                : memo )
            : Number.NaN ),
            tag,
            dataPoints );

        _addOneRow(clone, new Row(date, dp));
    }

    return clone.freeze();
};


var _reduceRows = function (rows, numCols, reducer) {

    var numRows    = rows.length,
        res        = [],
        row        = null,
        startIdx   = 0,
        reducerRec = _reducerGet(reducer);

    if (typeof reducerRec.initValue !== 'undefined') {
        for (var j = 0; j < numCols; j++)
            res.push(Utils.clone(reducerRec.initValue));
    }

    else if (numRows === 0)
        return Arrays.newInstance(numCols, Number.NaN);

    else {

        startIdx = 1;
        row      = rows[0];  // Assume at least one row.  Caller should validate for this.

        for (var j = 0; j < numCols; j++)
            res.push(row._vals[j]._val);
    }


    for (var i = startIdx; i < numRows; i++) {

        row = rows[i];

        for (var j = 0; j < numCols; j++) {
            var rv = reducerRec.iteratee(res[j], row._vals[j]._val, i);  // TODO - \`list\` argument
            if (typeof rv !== 'undefined')
                res[j] = rv;
        }
    }

    if (typeof reducerRec.resolver === 'function')
        res = _.map(res, function (memo) { return reducerRec.resolver(memo); });

    return res;
};


/**
 * Performs an arithmetic function on one or more TimeSeries.
 * @param {Arguments} args - Caller's \`arguments\`.
 * @param {string} tag - Tag to use for mutated data-points.
 * @param {function} arithmeticFn - A two-argument function that returns a number.
 * @returns {morn.TimeSeries}
 * @private
 */
var _cellMath = function (args, tag, arithmeticFn) {

    if (!Arrays.isArrayLike(args) || args.length < 2) {
        throw new TypeError("arguments: must provide at least two.");
    }

    // Create our own copy, so we can shove DataPoints where numbers exist.
    var argList = Arrays.slice(args);

    // Make sure they all have the same size
    var numArg = argList.length;
    /** @type {morn.TimeSeries[]} */
    var tsList = [];

    for (var i = 0; i < numArg; i++) {
        var arg = argList[i];
        if (arg instanceof TimeSeries) {
            tsList.push(arg);
        } else if (typeof arg === 'number') {
            argList[i] = new DataPoint(arg, Numbers.toString(arg));
        } else {
            throw new TypeError("arguments[" + i + "]: TimeSeries or Number");
        }
    }

    if (tsList.length === 0) {
        throw new Error("Not one TimeSeries found in argument list.");
    }
    var size = tsList[0]._size;
    for (var i = 1; i < tsList.length; i++) {
        if (tsList[i]._size !== size && tsList[i]._size !== 1) {
            throw new Error("Mismatch TimeSeries size: " + size + " vs. " + tsList[i]._size);
        }
    }

    var dates    = _intersectionDates(tsList),
        numDates = dates.length,
        clone    = new TimeSeries(size);

    clone._tz = _getTz(tsList);

    for (var i = 0; i < numDates; i++) {
        var date  = dates[i];
        var stack = Arrays.newInstance(size, function () {
            return [];
        });

        for (var j = 0; j < numArg; j++) {
            var arg = argList[j];
            if (arg instanceof TimeSeries) {
                var ts  = arg,
                    row = ts.rowOf(date);

                for (var k = 0; k < size; k++) {
                    stack[k].push((ts._size > 1)
                        ? row._vals[k]
                        : row._vals[0] );
                }
            } else {  // Assuming 'number' (we already validated for this)
                for (var k = 0; k < size; k++)
                    stack[k].push(arg);
            }
        }

        var newDataPoints = [];

        for (var k = 0; k < size; k++) {
            var dps = stack[k],
                run = dps[0]._val;
            for (var j = 1; j < numArg; j++) {
                run = arithmeticFn(run, dps[j]._val);
            }
            newDataPoints.push(new DataPoint(run, tag, dps));
        }
        _addOneRow(clone, new Row(date, newDataPoints));
    }

    return clone.freeze();
};


var _inTzOne = function (date, tz) {

    if (tz !== null)
        date = date.withZone(tz);

    return date;
};

var _giveTzOne = function (date, tz) {

    if (tz !== null)
        date = date.withZoneRetainFields(tz);

    return date;
};


var _groupByDayBegin = function (date) {

    var rem = date.getTimeMillis();

    if (rem > 0)
        return date.addMilliseconds(rem * -1);
    else
        return date;
};

var _groupByDayEndOnNext = function (date) {

    var rem = date.getTimeMillis();

    if (rem > 0)
        return date.addMilliseconds(IDate.MILLIS_PER_DAY - rem);
    else
        return date;
};

var _groupByDayEndOnPrev = function (date) {

    var rem = date.getTimeMillis();

    if (rem > 0)
        return date.addMilliseconds(rem * -1);
    else
        return date.addDays(-1);
};


/**
 * @namespace
 * @alias morn.TimeSeries.DateGroupingMethods
 */
var GroupByDate = /** @lends morn.TimeSeries.DateGroupingMethods */ {

    /**
     * Groups all dates from <em>hh:00</em> up to <em>hh:59</em>
     * as <em>hh:00</em>.
     *
     * @member
     */
    HOUR_BEGINNING: function (date, tz) {

        var ms  = date.getTimeMillis(),
            rem = ms % IDate.MILLIS_PER_HOUR;

        // Going back to the top of the hour shouldn't require
        // a call to \`_inTzOne()\`.

        if (rem > 0)
            return date.addMilliseconds(rem * -1);
        else
            return date;
    },

    /**
     * Groups all dates from <em>hh:01</em> up to <em>[hh+1]:00</em>
     * as <em>[hh+1]:00</em>.
     *
     * @member
     * @see {@link morn.TimeSeries.DateGroupingMethods.HOUR_ENDING_ON_PREVIOUS|HOUR_ENDING_ON_PREVIOUS}
     */
    HOUR_ENDING_ON_NEXT: function (date, tz) {

        var ms  = date.getTimeMillis(),
            rem = ms % IDate.MILLIS_PER_HOUR;

        if (rem > 0)
            return _inTzOne(date.addMilliseconds(IDate.MILLIS_PER_HOUR - rem), tz);
        else
            return date;
    },

    /**
     * Groups all dates from <em>hh:01</em> up to <em>[hh+1]:00</em>
     * as <em>hh:00</em>.
     *
     * @member
     * @see {@link morn.TimeSeries.DateGroupingMethods.HOUR_ENDING_ON_NEXT|HOUR_ENDING_ON_NEXT}
     */
    HOUR_ENDING_ON_PREVIOUS: function (date, tz) {

        var ms  = date.getTimeMillis(),
            rem = ms % IDate.MILLIS_PER_HOUR;

        return _inTzOne(date.addMilliseconds(IDate.MILLIS_PER_HOUR - rem), tz);
    },

    /**
     * Groups all dates from <em>yyyy-MM-ddT00:00</em> up to <em>yyyy-MM-ddT23:59</em>
     * as <em>yyyy-MM-ddT00:00</em>.
     *
     * @member
     */
    DAY_BEGINNING: function (date, tz) {
        return _giveTzOne(_groupByDayBegin(date), tz);
    },

    /**
     * Groups all dates from <em>yyyy-MM-ddT00:01</em> up to <em>yyyy-MM-[dd+1]T00:00</em>
     * as <em>yyyy-MM-[dd+1]T00:00</em>.
     *
     * @member
     * @see {@link morn.TimeSeries.DateGroupingMethods.DAY_ENDING_ON_PREVIOUS|DAY_ENDING_ON_PREVIOUS}
     */
    DAY_ENDING_ON_NEXT: function (date, tz) {
        return _giveTzOne(_groupByDayEndOnNext(date), tz);
    },

    /**
     * Groups all dates from <em>yyyy-MM-ddT00:01</em> up to <em>yyyy-MM-[dd+1]T00:00</em>
     * as <em>yyyy-MM-ddT00:00</em>.
     *
     * @member
     * @see {@link morn.TimeSeries.DateGroupingMethods.DAY_ENDING_ON_NEXT|DAY_ENDING_ON_NEXT}
     */
    DAY_ENDING_ON_PREVIOUS: function (date, tz) {
        return _giveTzOne(_groupByDayEndOnPrev(date), tz);
    },

    /**
     * Groups all dates from <em>yyyy-MM-01T00:00</em> up to <em>yyyy-MM-31T23:59</em>
     * as <em>yyyy-MM-01T00:00</em>.
     *
     * @member
     */
    MONTH_BEGINNING: function (date, tz) {

        date = _groupByDayBegin(date);
        return _giveTzOne(date.firstOfMonth(), tz);
    },

    /**
     * Groups all dates from <em>yyyy-MM-01T00:01</em> up to <em>yyyy-[MM+1]-01T00:00</em>
     * as <em>yyyy-[MM+1]-01T00:00</em>.
     *
     * @member
     * @see {@link morn.TimeSeries.DateGroupingMethods.MONTH_ENDING_ON_PREVIOUS|MONTH_ENDING_ON_PREVIOUS}
     */
    MONTH_ENDING_ON_NEXT: function (date, tz) {

        date = _groupByDayEndOnNext(date);
        if (date.getDate() > 1)
            date = date.lastOfMonth().addDays(1);

        return _giveTzOne(date, tz);
    },

    /**
     * Groups all dates from <em>yyyy-MM-01T00:01</em> up to <em>yyyy-[MM+1]-01T00:00</em>
     * as <em>yyyy-MM-01T00:00</em>.
     *
     * @member
     * @see {@link morn.TimeSeries.DateGroupingMethods.MONTH_ENDING_ON_NEXT|MONTH_ENDING_ON_NEXT}
     */
    MONTH_ENDING_ON_PREVIOUS: function (date, tz) {

        date = _groupByDayEndOnPrev(date);
        return _giveTzOne(date.firstOfMonth(), tz);
    }
};


var _getGroupByDate = function (args, falseValue, trueValue) {

    var meth = falseValue;

    if (args.length > 0) {

        if (typeof args[0] !== 'boolean')
            throw new TypeError("dateGrouper: boolean or function");

        else
            meth = ( (args[0])
                ? trueValue
                : falseValue );
    }

    return meth;
};

var _getGroupByHour = function (args) {
    return _getGroupByDate( args,
        GroupByDate.HOUR_BEGINNING,
        GroupByDate.HOUR_ENDING_ON_NEXT );
};

var _getGroupByDay = function (args) {
    return _getGroupByDate( args,
        GroupByDate.DAY_BEGINNING,
        GroupByDate.DAY_ENDING_ON_PREVIOUS );
};

var _getGroupByMonth = function (args) {
    return _getGroupByDate( args,
        GroupByDate.MONTH_BEGINNING,
        GroupByDate.MONTH_ENDING_ON_PREVIOUS );
};

var _validGroupingMethod = function (groupingMethod) {

    if (typeof groupingMethod !== 'function')
        throw new TypeError("groupingMethod: function");

    return groupingMethod;
};

/**
 * Pre-defines the groups of a TimeSeries, per \`groupingMethod\`.
 * @param {morn.TimeSeries} ts
 * @param {function(lim.IDate, ?lim.TimeZone): lim.IDate} groupingMethod
 * @returns {Array.<{date: lim.IDate, rows: morn.TimeSeries.Row[]}>} Groups of a TimeSeries,
 *          sorted by dates (ascending).
 * @private
 */
var _getGroups = function (ts, groupingMethod) {

    var rows    = ts._rows,
        numRows = rows.length,
        tz      = ts._tz,
        groups  = {};

    for (var i = 0; i < numRows; i++) {
        var row   = rows[i],
            date  = groupingMethod(row._date, tz);

        if (!(date instanceof IDate)) {
            throw new Error("\`groupingMethod\` did not return a date.");
        }

        var key = date.getTime();
        if (!groups.hasOwnProperty(key)) {
            groups[key] = {
                date: date,
                rows: []
            };
        }
        groups[key].rows.push(row);
    }
    var list = _.values(groups);
    list.sort(function (g1, g2) {
        return IDate.compare(g1.date, g2.date);
    });
    return list;
};


function _groupBy (ts, groupingMethod, reducer, tag) {

    if (ts._rows.length === 0)
        return ts;

    var groups  = _getGroups(ts, groupingMethod),
        numCols = ts._size;

    /** @type {morn.TimeSeries.Row[]} */
    var rows = [];

    groups.forEach(function (group) {
        var date  = group.date,
            vals  = _reduceRows(group.rows, numCols, reducer),
            dps   = [];
        for (var j = 0; j < numCols; j++) {
            dps.push(new DataPoint(vals[j], tag));
        }
        rows.push(new Row(date, dps));
    });

    var clone = _newTs(ts);
    _addRowsSorted(clone, rows);
    return clone.freeze();
}

/**
 * Returns a new TimeSeries with fewer rows.  Which rows are carried
 * to the returned TimeSeries depend on arguments \`whenToKeep\` and \`dates\`.
 *
 * @param {morn.TimeSeries} ts
 * @param {boolean} whenToKeep
 * @param {lim.IDate[]} dates
 * @param {Integer} numBefore
 * @param {Integer} numAfter
 * @returns {morn.TimeSeries}
 * @private
 */
var _filterDates = function (ts, whenToKeep, dates, numBefore, numAfter) {

    var dateIndex = _dateIndex(dates),
        rows      = ts._rows,
        clone     = _newTs(ts);

    for (var i = 0, numRows = rows.length; i < numRows; i++) {

        var row = rows[i];
        if (dateIndex.hasOwnProperty(rows[i]._date.getTime()) === whenToKeep)
            _addSlice(clone, rows, i - numBefore, i + 1 + numAfter);
    }

    return clone.freeze();
};

var _timeSeriesFilter = {

    run: function (ts, includer, selector) {
        if (typeof selector !== 'function') {
            throw new TypeError("selector: Function");
        }

        var clone = _newTs(ts);
        ts._rows.forEach(function (row, i) {
            if (includer(selector(row, i))) {
                _addOneRow(clone, row);
            }
        });
        return clone.freeze();
    },

    includers: {

        isTrue: function (selectorValue) {
            return (selectorValue === true);
        },

        isNotTrue: function (selectorValue) {
            return (selectorValue !== true);
        }
    }
};

/* ************************************************
  * MODIFICATION/SHAPING
  * ************************************************ */

var _dateFilterAll = function (date, value, rowIndex, colIndex) {
    return true;
};

var _dateFilterByPeriod = function (start, end) {

    return function (date, value, rowIndex, colIndex) {
        return (   start.isBeforeOrEqual(date)
            && end  .isAfterOrEqual(date) );
    };
};

var _dateFilterByMonths = function (months) {

    if (!Arrays.isValid(months, Numbers.isInteger))
        throw new TypeError("months: Integer[]");

    var moIndex = Arrays.newInstance(12, 0);

    for (var i = 0, len = months.length; i < len; i++) {

        var mo = months[i];
        if (   mo < 0
            || mo > 11 )
            throw new TypeError("months[" + i + "]: Integer, 0-11");

        moIndex[mo] = 1;
    }

    return function (date, value, rowIndex, colIndex) {
        return (moIndex[date.getMonth()] === 1);
    };
};

var _dateFilterByDate = function (date) {

    var myDate = _validDate(date);

    return function (date, value, rowIndex, colIndex) {
        return IDate.areEqual(myDate, date);
    };
};


var _whenHoliday = function (ts, args, isHoliday) {

    var holidays     = args[0],
        isHourEnding = false,
        dayCutoff    = 0;

    // Validate the arguments.

    if (!(holidays instanceof Holidays))
        throw new TypeError("holidays: Holidays");

    if (args.length > 1) {

        isHourEnding = args[1];
        if (typeof isHourEnding !== 'boolean')
            throw new TypeError("isHourEnding: boolean");
    }

    if (args.length > 2)
        dayCutoff = _toTime(args[2]);

    // Arguments are validated, proceed to do the work.

    var rows    = ts._rows,
        numRows = rows.length;

    if (numRows === 0)
        return ts;

    var dateView = _getDateView(isHourEnding, dayCutoff),
        clone    = _newTs(ts);

    for (var i = 0; i < numRows; i++) {

        var row = rows[i];

        if (holidays.isHoliday(dateView(row._date)) === isHoliday)
            _addOneRow(clone, row);
    }

    return clone.freeze();
};


var _dataPointFilters = {

    /** @type {morn.TimeSeries.DataPointFilter} */
    all: function (date, value, rowIndex, colIndex) {
        return true;
    },

    /** @type {morn.TimeSeries.DataPointFilter} */
    noNaNs: function (date, value, rowIndex, colIndex) {
        return _isFiniteNumber(value);
    },

    /** @type {morn.TimeSeries.DataPointFilter} */
    onlyNaNs: function (date, value, rowIndex, colIndex) {
        return !_isFiniteNumber(value);
    }
};


var _validValueModifier = function (valueModifier) {

    if (   typeof valueModifier !== 'function'
        || valueModifier.length < 2
        || valueModifier.length > 4 )
        throw new TypeError("valueModifier: Function, with 2-4 arguments");

    return valueModifier;
};

var _basicDataPointModFilter = function (filter) {

    _validValueModifier(filter);

    return function (date, value, rowIndex, colIndex) {
        return (   _isFiniteNumber(value)
            && filter.apply(this, arguments) );
    };
};


var _dataPointModifier = function (valueModifier, tag, filter) {

    return function (dataPoint, date, rowIndex, colIndex) {

        var origValue = dataPoint.value();

        if (filter.call(this, date, origValue, rowIndex, colIndex)) {

            var isNum = !dataPoint.isNaN(),
                value = valueModifier.call(this, date, origValue, rowIndex, colIndex);

            if (   typeof value === 'number'
                && (   (   isNum
                    && value !== origValue ) )
                || (   !isNum
                    && (   (   isNaN(origValue)  // was true NaN
                        && !isNaN(value) )  // no longer true NaN
                        || (   !isNaN(origValue)  // Infinity (+/-) can compare w/ \`!==\`
                            && value !== origValue ) ) ) )
                return dataPoint.mutate(value, tag);
        }
    };
};

var _rowModifier = function (dataPointModifier) {


    return function (row, rowIndex) {

        var date   = row.date(),
            values = null;

        for (var i = 0, numCols = row.numColumns(); i < numCols; i++) {

            var dataPoint    = row.dataPoint(i),
                newDataPoint = dataPointModifier.call(this, dataPoint, date, rowIndex, i);

            if (   newDataPoint instanceof DataPoint
                && newDataPoint !== dataPoint ) {

                if (values === null) {
                    // first mod to this row, initiate list of data-points
                    values = row.dataPoints();
                }

                values[i] = newDataPoint;
            }
        }

        return values;
    };
};


var _modifyTheseRows = function (src, target, rowModifier, dir) {

    var rows    = src._rows,
        numRows = rows.length,
        start, keepLooping;

    if (dir > 0) {
        start       = 0;
        keepLooping = function (idx) {
            return (idx < numRows);
        };
    }
    else {
        start       = numRows - 1;
        keepLooping = function (idx) {
            return (idx >= 0);
        };
    }


    for (var i = start; keepLooping(i); i += dir) {

        var row    = rows[i],
            date   = row.date(),
            values = rowModifier.call(src, row, i);

        if (values instanceof Row)
            target.addRow(values);

        else if (Arrays.isArrayOf(values, DataPoint))
            target.addRow(new Row(date, values));  // Now that Rhino correctly supports \`Object.freeze()\`, we don't have to \`slice(0)\`.

        else if (Utils.isVoid(values))
            target.addRow(row);  // unmodified

        else
            throw new Error("IllegalStateException: modifier returned something other than Row, DataPoint[], null and undefined");
    }

    return target.freeze();
};


var _modifyRows = function (ts, rowModifier) {
    return _modifyTheseRows(ts, _newTs(ts), rowModifier, 1);
};

var _modifyAllRows = function (ts, tag, valAdj) {

    return _modifyRows( ts,
        _rowModifier( _filteredValueModifier( _dateFilterAll,
            valAdj,
            tag ) ) );
};


var _roundTimeSeries = function (ts, args, tagPrefix, mathFn) {

    if (!Numbers.isInteger(args[0]))
        throw new TypeError("numDecimals: Integer");

    var numDecimals = args[0],
        tag         = _validTag(args, 1, tagPrefix + "(" + numDecimals.toFixed(0) + ")");

    return _modifyAllRows (ts, tag, function (value) {
        return (lim.Math[mathFn](value, numDecimals));
    });
};


var _basicValModifier = function (adjuster) {
    return function (date, value, rowIndex, colIndex) {
        return adjuster(value);
    };
};


var _filteredValueModifier = function (filter, valAdjuster, tag) {

    var valFilter   = _basicDataPointModFilter(filter),
        valModifier = _basicValModifier(valAdjuster);

    if (typeof tag !== 'string')
        throw new TypeError("tag: String");

    return _dataPointModifier( valModifier,
        tag,
        valFilter );
};


var _adjRegex = new RegExp("^\\\\s*([\\\\-+*\\\\/])\\\\s*(\\\\d+(?:\\\\.\\\\d+)?|\\\\.\\\\d+)\\\\s*(%?)\\\\s*$");

/**
 * Converts a string adjustment into a function that adjusts
 * a number accordingly.  For instance,
 * <code>_basicValAdjuster("+10%")</code> returns a function
 * to which passing an argument of <em>1</em> would return <em>1.1</em>.
 *
 * This method also creates absolute adjustment functions, if it's called
 * with a Number argument.
 *
 * @param adj {(string|number)}
 * @return {function}
 */
var _basicValAdjuster = function (adj) {

    if (typeof adj === 'number') {
        return function (value) {
            return adj;  // absolute adjustment
        };
    }

    else if (typeof adj !== 'string')
        throw new TypeError("adj: String");

    var m = _adjRegex.exec(adj);
    if (m === null)
        throw new Error("IllegalArgumentException: adj: Unrecognized adjustment (" + adj + ")");

    var oper      = m[1],
        num       = parseFloat(m[2]),
        isPercent = (m[3] === '%');

    if (isPercent) {

        num /= 100;

        if (oper === '+') {
            oper = '*';
            num  = 1 + num;
        }
        else if (oper === '-') {
            oper = '*';
            num  = 1 - num;
        }
    }

    // Avoid IEEE round issues during mathematical operations
    num = lim.Math.round(num, Numbers.precision(num));

    switch (oper) {

        case '+':
            return function (value) {
                return value + num;
            };

        case '-':
            return function (value) {
                return value - num;
            };

        case '*':
            return function (value) {
                return value * num;
            };

        case '/':
            return function (value) {
                return value / num;
            };

        default:
            throw new Error("IllegalStateException: unsupported operator (" + oper + ")");
    }
};

var _validKeys = function (keyValues) {

    if (   !(keyValues instanceof Object)
        || keyValues.constructor !== Object )
        throw new TypeError("keyValues: Object");

    return keyValues;
};

var _validColumns = function (columns, ts) {

    if (typeof columns === 'string')
        columns = Strings.split(columns, new RegExp('\\\\s*,+\\\\s*', "g"));  // Allow multiple columns as comma-separated string.

    else if (   !Arrays.isArrayOf(columns, 'string')
        || columns.length === 0 )
        throw new TypeError("columns: String or Array-of-String");

    else {
        columns = _.map(columns, function (col) {
            return Strings.trim(col);
        });
    }

    if (!Arrays.isValid(columns, Strings.isNonEmpty))
        throw new Error("IllegalArgumentException: \`columns\` contains an empty string.");

    if (columns.length !== _.uniq(columns).length)
        throw new Error("IllegalArgumentException: \`columns\` must contain all unique names");

    if (arguments.length > 1) {
        if (columns.length !== ts._size)
            throw new Error("IllegalArgumentException: columns.length != TimeSeries.size()");
    }

    return columns;
};

/**
 * Validates column indices against a TimeSeries; \`colIndices\` must contain
 * at least one index to be valid.
 *
 * If valid, this method returns a new array containing the indices,
 * in the same order they were given.
 *
 * If not valid, this method throws.
 * @param {(Arguments|Integer[])} colIndices
 * @param {morn.TimeSeries} ts
 * @param {boolean} isNoneAllowed - Whether an empty argument set is allowed.
 * @returns {Integer[]}
 * @private
 */
var _validColIndices = function (colIndices, ts, isNoneAllowed) {

    var numCols = ts._size,
        indices;

    if (colIndices.length < 1) {
        if (!isNoneAllowed)
            throw new ReferenceError("NullPointerException: colIndices");

        else {
            indices = [];
            for (var i = 0; i < numCols; i++)
                indices.push(i);
        }
    }
    else {
        indices = _.map(colIndices, function (colIndex, argIndex) {
            if (!Arrays.isValidIndex(colIndex, numCols))
                throw new TypeError("ArrayIndexOutOfBoundException: colIndices[" + argIndex + "]: " + colIndex);

            return colIndex;
        });
    }

    return indices;
};

/**
 * Validates that the chosen DeliveryType matches the TimeSeries.
 * @param {morn.TimeSeries} ts
 * @param {morn.DeliveryType} deliveryType
 * @param {boolean} doMaxGapCheck
 * @private
 */
var _validDeliveryType = function (ts, deliveryType, doMaxGapCheck) {

    var rows    = ts._rows,
        numRows = rows.length;

    if (numRows > 0) {

        var minGap = deliveryType.minDuration(),
            maxGap = deliveryType.maxDuration(),
            last   = rows[0]._date;

        for (var i = 1; i < numRows; i++) {

            var date = rows[i]._date,
                gap  = date - last;

            if (gap < minGap)
                throw new Error("IllegalStateException: chosen \`deliveryType\` caused contracts to overlap.");
            else if (   doMaxGapCheck
                && gap > maxGap )
                throw new Error("IllegalStateException: chosen \`deliveryType\` caused gaps in contract definitions, some contracts are too far apart.");

            last = date;
        }
    }
};

var _dateTag = function (date) {

    var time = date.getTimeMillis();
    if (time > 0)
        return date.format("yyyy-MM-dd HH:mm");
    else
        return date.format("yyyy-MM-dd");
};

var _validTag = function (args, idx, defaultValue) {

    if (idx >= args.length)  // custom tag not provided, use default value.
        return defaultValue;

    else if (typeof args[idx] !== 'string')
        throw new TypeError("tag: String (optional)");

    else
        return args[idx];
};

var _getReplaceWithTag = function (args, defaultTag) {

    var replaceWith = args[0],
        tag         = null,
        isCustomTag = (args.length > 2),
        valMod      = null;

    // A bit unusual, but we validate arguments[1] before arguments[0]
    if (!isCustomTag)
        tag = defaultTag;
    else if (typeof args[1] !== 'string')
        throw new TypeError("tag: String");
    else
        tag = args[1];


    if (typeof replaceWith === 'number') {

        if (!isCustomTag)
            tag += '_with(' + Numbers.toString(replaceWith) + ')';

        valMod = function (date, value, rowIndex, colIndex) {
            return replaceWith;
        };
    }
    else if (typeof replaceWith !== 'function')
        throw new TypeError("replaceWith: Number or Function");

    else
        valMod = _validValueModifier(replaceWith);

    return {
        tag:           tag,
        valueModifier: valMod
    };
};


var _chgOver = function (prev) {
    return Math.abs(prev);
};

var _chgOverRaw = function (prev) {
    return prev;
};

var _chg = function (last, prev) {
    return (last - prev);
};

var _chgRatioImpl = function (last, prev, fnOver) {

    if (prev !== 0)
        return _chg(last, prev) / fnOver(prev);
    else
        return Number.POSITIVE_INFINITY;
};

var _chgRatio = function (last, prev) {
    return _chgRatioImpl(last, prev, _chgOver);
};

var _chgRatioRaw = function (last, prev) {
    return _chgRatioImpl(last, prev, _chgOverRaw);
};

var _chgPerc = function (last, prev) {
    return _chgRatio(last, prev) * 100;
};

var _chgPercRaw = function (last, prev) {
    return _chgRatioRaw(last, prev) * 100;
};

var _chgLN = function (last, prev) {
    return Math.log(last / prev);
};

var _change = function (ts, args, tagPrefix, calcVal) {

    var numPoints = 1;
    if (args.length > 0) {
        if (!Numbers.isPositiveInteger(args[0]))
            throw new TypeError("numPoints: Integer, positive");

        numPoints = args[0];
    }

    var rows          = ts._rows,
        numRows       = rows.length,
        numCols       = ts._size,
        clone         = _newTs(ts),
        tag           = tagPrefix + numPoints.toString(10),
        row           = null,
        prevIndex     = null,
        dataPoints    = null,
        dataPoint     = null,
        prevDataPoint = null,
        newDataPoint  = null,
        val           = 0;

    // Loop through rows, creating a new TimeSeries.
    for (var i = numPoints; i < numRows; i++) {

        row        = rows[i];
        prevIndex  = i - numPoints;
        dataPoints = [];

        for (var j = 0; j < numCols; j++) {

            dataPoint = row._vals[j];
            if (dataPoint.isNaN())
                newDataPoint = dataPoint;  // no alteration here

            else {
                prevDataPoint = _dataPointAsOfIndex(ts, prevIndex, j);

                if (prevDataPoint.isNaN())
                    val = Number.NaN;
                else
                    val = calcVal(dataPoint._val, prevDataPoint._val);

                newDataPoint = dataPoint.mutate(val, tag);
            }

            dataPoints.push(newDataPoint);
        }

        _addOneRow(clone, new Row(row._date, dataPoints));
    }

    return clone.freeze();
};

var _addSlice = function (ts, rows, startIdx, endIdx) {

    startIdx = Math.max(0,           startIdx);
    endIdx   = Math.min(rows.length, endIdx);

    for (var i = startIdx; i < endIdx; i++)
        _addOneRow(ts, rows[i]);

};

/**
 * Finds rows where the first column from <code>ts</code>
 * crosses over or under the 2nd column.
 * @param ts {morn.TimeSeries}
 * @param dir {number}
 * @param args {Integer[]} caller function's <code>argument</code>
 * @return {morn.TimeSeries}
 */
var _crossing = function (ts, dir, args) {

    if (ts._size < 2)
        throw new Error("IllegalStateException: TimeSeries.size() < 2");

    var numBefore = 0,
        numAfter  = 0;

    if (args.length >= 1) {

        numBefore = args[0];

        if (!Numbers.isInteger(numBefore))
            throw new TypeError("numBefore: Integer");

        if (args.length >= 2) {

            numAfter = args[1];

            if (!Numbers.isInteger(numAfter))
                throw new TypeError("numAfter: Integer");
        }
        else
            numAfter = numBefore;
    }

    var rows     = ts._rows,
        numRows  = rows.length,
        clone    = _newTs(ts),
        val1     = Number.NaN,
        val2     = Number.NaN,
        isVal1Ok = false,
        isVal2Ok = false,
        isFirst  = true,
        diff     = 0,
        prevDiff = 0;

    for (var i = 0; i < numRows; i++) {

        var row = rows[i],
            dp1 = row._vals[0],
            dp2 = row._vals[1];

        if (_isDataPointFinite(dp1)) {
            val1     = dp1._val;
            isVal1Ok = true;
        }

        if (_isDataPointFinite(dp2)) {
            val2     = dp2._val;
            isVal2Ok = true;
        }

        if (isVal1Ok && isVal2Ok) {

            diff = val1 - val2;

            if (isFirst)
                isFirst = false;

            else if (   (   dir >= 0        // cross-over
                && prevDiff <= 0
                && diff     >= 0 )
                || (   dir <= 0        // cross-under
                    && prevDiff >= 0
                    && diff     <= 0 ) ) {

                _addSlice(clone, rows, i - numBefore, i + 1 + numAfter);
            }

            prevDiff = diff;
        }
    }

    return clone.freeze();
};

/**
 * Inserts rows into \`ts\` at the given dates.
 * If a row already exist for a date, the existing
 * row is preserved; otherwise NaN values are inserted.
 *
 * @param {morn.TimeSeries} ts TimeSeries to use as basis, from which we add NaN rows.
 * @param {lim.IDate[]} dates - Dates to insert
 * @returns {morn.TimeSeries} New, mutable TimeSeries containing the same values as in \`ts\` but may
 *          contain new NaN rows where dates were inserted.
 * @private
 */
var _insertNans = function (ts, dates) {

    var dataPoints = Arrays.newInstance(ts._size, DataPoint.NaN);
    var datesSorted = _cloneAndSortDates(dates);

    /** @type {morn.TimeSeries.Row[]} */
    var target = [];
    var rows = ts._rows;
    var iRow = 0;
    var iDate = 0;

    while (iRow < rows.length && iDate < datesSorted.length) {
        var c = IDate.compare(rows[iRow]._date, datesSorted[iDate]);
        if (c < 0) {
            target.push(rows[iRow++]);
        } else if (c > 0) {
            target.push(new Row(datesSorted[iDate++], dataPoints));
        } else {
            target.push(rows[iRow]);
            iRow++;
            iDate++;
        }
    }
    while (iRow < rows.length) {
        target.push(rows[iRow++]);
    }
    while (iDate < datesSorted.length) {
        target.push(new Row(datesSorted[iDate++], dataPoints));
    }

    var clone = _newTs(ts);
    _addRowsSorted(clone, target);
    return clone;
};

/**
 * Ensures the given TimeSeries has all requested dates, inserts NaN rows as necessary,
 * then replaces all NaN values per \`valueModifier\`.
 * @param {morn.TimeSeries} ts
 * @param {lim.IDate[]} dates
 * @param {morn.TimeSeries.ValueModifier} valueModifier
 * @param {string} tag
 * @param {int} dir
 * @returns {morn.TimeSeries} New, frozen TimeSeries.
 * @private
 */
var _fillNans = function (ts, dates, valueModifier, tag, dir) {

    // Create mutable copy, insert NaN where dates are missing.
    var copy = _insertNans(ts, dates);

    // Run through the replaceNaN logic, effectively replacing
    // new NaN as well as existing NaN values.
    var dpMod = _dataPointModifier( valueModifier,
        tag,
        _dataPointFilters.onlyNaNs );

    return _modifyTheseRows(copy, copy, _rowModifier(dpMod), dir);
};

/**
 * Replaces all NaNs from a TimeSeries with previous or next value.
 * @param {morn.TimeSeries} ts
 * @param {Arguments} args Caller's arguments
 *        [0]: Calendar | IDate[] | function(IDate, IDate): (IDate[]|Calendar), TimeSeries
 *        [1]: string
 * @param {string} defaultTag
 * @param {function} fillFn
 * @param {Integer} dir - Direction, 1 or -1.
 * @returns {morn.TimeSeries}
 * @private
 */
var _fillTimeSeries = function (ts, args, defaultTag, fillFn, dir) {

    if (ts.isEmpty()) {
        return ts;
    }
    var numArgs = args.length,
        tag     = defaultTag,
        dates   = [];

    if (numArgs > 0) {
        dates = _validFillCalendar(args[0], ts);
        if (numArgs > 1) {
            tag = Strings.requireString(args[1], 'tag');
        }
    }
    return _fillNans(ts, dates, fillFn, tag, dir);
};

/**
 * Builds a list of indexes for where finite values are found within \`ts\`,
 * one list per column.
 * @param {morn.TimeSeries} ts
 * @return {Integer[][]} Indexes where finite values are found, grouped by columns.
 * @private
 */
var _buildValueIndexes = function (ts) {

    var numCols = ts._size,
        rows    = ts._rows,
        numRows = rows.length;

    var valueIndexes = Arrays.newInstance(numCols, function () {
        return [];
    });

    for (var i = 0; i < numRows; i++) {
        var row = rows[i];

        for (var j = 0; j < numCols; j++) {
            if (!row._vals[j].isNaN())
                valueIndexes[j].push(i);
        }
    }

    return valueIndexes;
};

/**
 * Returns a index of column names, where the key is lower-cased for consistency.
 * @param {string[]} cols
 * @returns {Object.<string, string>}
 * @private
 */
var _getIndexOfColumns = function (cols) {
    return _.indexBy(cols, function (col) {
        return col.toLowerCase();
    });
};

/**
 * Returns the list of columns not found in the given index (object).
 * Column names are treated as case-insensitive.
 * @param {Object.<string, string>} index
 * @param {string[]} cols
 * @returns {string[]}
 * @private
 */
var _colsNotIn = function (index, cols) {
    return cols.filter(function (col) {
        return !index.hasOwnProperty(col.toLowerCase());
    });
};

/**
 * Validates the given key for the product.
 * @param {morn.Product} product Product to validate.
 * @param {string} argName Name given to \`product\` for when error must be thrown.
 * @returns {morn.Product} Always returns \`product\`.
 * @private
 */
function _validProductKey (product, argName) {
    _validFeedKey(product.feed(), product.key(), argName);
    return product;
}

/**
 * Validates the given key against the specified feed.
 * @param {string} feed Feed name.
 * @param {Object.<string, string>} key Feed key-values.
 * @param {string} argName Name given to the argument being validated, for when error must
 *        be thrown.
 * @private
 */
function _validFeedKey (feed, key, argName) {
    var all      = Feed.getKeyFieldNames(feed),
        provided = Object.keys(key),
        unknown  = _colsNotIn(_getIndexOfColumns(all), provided),
        missing  = _colsNotIn(_getIndexOfColumns(provided), all);

    if (unknown.length > 0) {
        throw new Error(
            argName
            + ": Unrecognized key names for feed " + feed
            + ": [" + unknown.join(',') + "]"
        );
    }
    if (missing.length > 0) {
        throw new Error(
            argName
            + ": Missing key names for feed " + feed
            + ": [" + missing.join(',') + "]"
        );
    }
}

/**
 * Concatenates two arrays into one, leaving both input arguments
 * unchanged.
 *
 * If \`a2\` is empty, \`a1\` is returned.
 *
 * @param {Array} a1
 * @param {Array} a2
 * @returns {Array}
 * @private
 */
var _concatArrays = function (a1, a2) {

    if (a2.length === 0)
        return a1;

    else {
        var rv = a1.slice(0);
        Arrays.addAll(rv, a2);
        return rv;
    }
};

/**
 * Extend \`target\` with items from other arrays.
 * @param {Array} target
 * @param {...Array} arrays
 * @return {Array} \`target\` with items from other arrays added to it.
 * @private
 */
var _extendArray = function (target, arrays) {

    var args    = arguments,
        numArgs = args.length;

    for (var i = 1; i < numArgs; i++)
        Arrays.addAll(target, args[i]);

    return target;
};

var _dataPointAppender = function (row, dataPoint) {
    row.push(dataPoint.value());
};
var _dataPointAppenderWithTrace = function (row, dataPoint) {
    row.push(dataPoint.value(), dataPoint.mutation());
};

/**
 * Loads the current time-series into the given \`delimFile\`
 * (dates and values only).
 * @param {morn.TimeSeries} ts
 * @param {lim.DelimFile} delimFile
 * @param {boolean} includeTrace
 * @param {string[]} extraNans
 * @returns {lim.DelimFile} \`delimFile\`
 * @private
 */
var _toCsv = function (ts, delimFile, includeTrace, extraNans) {

    var rows       = ts._rows,
        header     = ts._header,
        numRows    = rows.length,
        numCols    = ts._size,
        numExtra   = extraNans.length,
        dates      = _getFormattedDates(_getDates(ts)),
        dpAppender = ( (includeTrace)
            ? _dataPointAppenderWithTrace
            : _dataPointAppender ),
        row, args, j;
    //adding code to add headers in formula results
    if(header && header.length > 0 ){
        if(header.length !== numCols + 1){
            throw new Error("Header array should have columns equal to result columns");
        }
        else{
            row = [] ;
            header.forEach( function(header,i){
                row.push(header);
            })
            delimFile.addRow.apply(delimFile, row);
        }
    }

    for (var i = 0; i < numRows; i++) {

        row  = rows[i];
        args = [ dates[i] ];

        for (j = 0; j < numCols; j++)
            dpAppender(args, row._vals[j]);

        for (j = 0; j < numExtra; j++)
            dpAppender(args, DataPoint.NaN);  // TODO - No NaNs allowed!

        delimFile.addRow.apply(delimFile, args);
    }

    return delimFile;
};

/**
 * Converts the given TimeSeries into a correctly formatted CSV payload,
 * in which the first column is the date and subsequent columns are
 * the values (where NaN values are converted to "").
 * @param {morn.TimeSeries} ts
 * @returns {string}
 * @private
 */
var _toCsvNoNan = function (ts) {

    var delimFile = _toCsv(ts, new DelimFile(), false, Arrays.EMPTY);

    return delimFile.replaceAll(_isNonFiniteNumber, "")
        .toCsv();
};

/**
 * @param {Object.<string, string>} keyValues
 * @returns {string} Tag created from \`keyValues\`.
 * @private
 */
var _tagServerData = function (keyValues) {
    return _.reduce(keyValues, function (memo, value) {
        memo.push(value);
        return memo;
    }, []).join(';');
};

/**
 * Validates the given argument to be a valid Date or ISO-string date.
 * @param {Arguments} args Caller's arguments.
 * @param {int} argIdx Index position of the date argument to validate.
 * @param {string} argName Name given to the argument to validate for when error must be thrown.
 * @returns {string} ISO-formatted date.
 * @throws {TypeError} If the argument is neither IDate or string.
 * @throws {Error} If the argument is a string but not recognized as a date (unparsable).
 * @private
 */
function _reqIsoDate (args, argIdx, argName) {
    if (Parameters.contains(CORRECTION_DATE_PROP)) {
        return _formatDate(Parameters.getDate(CORRECTION_DATE_PROP),argName);
    }
    if (argIdx >= args.length || args[argIdx] === null) {
        return null;
    } else {
        var dateInput = args[argIdx];
        return _formatDate(dateInput,argName);
    }
}

function _formatDate(dateInput,argName) {
    var date = _validDate(dateInput, argName),
        f    = 'yyyy-MM-ddTHH:mm:ss';  // base format, w/o UTC offset.

    if (   date.getTimezoneOffset() !== 0
        || (   typeof dateInput === 'string'
            && lim.DateParser.hasUTCOffset(dateInput) ) ) {
        f += 'ZZ';  // full format, including UTC offset \`[+-]hh:ss\`
    }
    return date.format(f);
}
/**
 * @param {Arguments} args Caller's arguments.
 * @param {int} argIdx Index position of the *max-results* argument to validate.
 * @param {string} argName Name given to the argument to validate for when error must be thrown.
 * @returns {int} Validated *max-results* argument, or -1 if not provided.
 * @private
 */
function _reqMaxResults (args, argIdx, argName) {
    if (argIdx >= args.length) {
        return -1;
    } else {
        var maxResults = args[argIdx];
        if (!Numbers.isInteger(maxResults)) {
            throw new TypeError(argName + ": Integer");
        }
        if (   maxResults === 0
            || maxResults < -1 ) {
            throw new Error(argName + ": must be positive or -1");
        }
        return maxResults;
    }
}

/**
 * Returns whether the given date modifier returns dates that are
 * prior to what they receive.
 * @param {lim.IDate.Modifier} dateModifier
 * @returns {boolean}
 * @private
 */
var _isBackwardDateModifier = function (dateModifier) {

    var dt  = IDate.create(0),
        mod = dateModifier(dt);

    if (dt.equals(mod))
        throw new Error("IllegalArgumentException: \`dateModifier\` returned an unmodified date");

    return (mod < dt);
};

/**
 * Returns whether two numbers have the same sign.
 * @param {Number} n1
 * @param {Number} n2
 * @param {boolean}
 * @private
 */
var _isSameSign = function (n1, n2) {
    return ((n1 < 0) === (n2 < 0));
};


/**
 *
 * @param {morn.TimeSeries} ts
 * @param {lim.IDate.Modifier} dateMod
 * @param {Integer} dir
 * @param {Integer} startIdx
 * @returns {?lim.IDate}
 * @private
 */
var _findEdgeDate = function (ts, dateMod, dir, startIdx) {

    var startDate = ts.dateAt(startIdx),
        idx       = startIdx,
        max       = ts._rows.length,
        min       = -max,
        edgeDate  = null;

    while (   idx >= min
    && idx < max
    && edgeDate === null ) {

        var dt   = ts.dateAt(idx),
            edge = dateMod(dt),
            diff = edge - startDate;

        if (   diff === 0
            || _isSameSign(dir, diff) )
            edgeDate = ts.dateAt(idx - dir);
        else
            idx += dir;
    }

    return edgeDate;
};

/**
 * Returns the default correlation options.
 * @returns {morn.TimeSeries.ValidatedCorrelationOptions}
 * @private
 */
var _newCorrelationOptions = function () {
    return {
        fill:       0,
        ew_lambda:  null,
        start_date: null,
        end_date:   null,
        time_zone:  null
    }
};

/**
 *
 * @param {morn.TimeSeries.CorrelationOptions} options
 * @returns {morn.TimeSeries.ValidatedCorrelationOptions}
 * @private
 */
var _validCorrelationOptions = function (options) {

    if (   !(options instanceof Object)
        || options.constructor !== Object )
        throw new TypeError("options: Object");

    var keys = _.keys(options),
        rv   = _newCorrelationOptions();

    _.each(_.keys(options), function (key) {

        var val = options[key];

        switch (key) {
            case "fill":
                if (!_isFiniteNumber(val))
                    throw new TypeError("options[\\"fill\\"]: Number");
                break;

            case "ew_lambda":
                if (   !_isFiniteNumber(val)
                    || val < 0
                    || val >= 1 )
                    throw new TypeError("options[\\"ew_lambda\\"]: Number, range 0-1 (exclusive)");
                break;

            case "start_date":
                val = _validDate(val, "options[\\"start_date\\"]");
                break;

            case "end_date":
                val = _validDate(val, "options[\\"end_date\\"]");
                break;

            case "time_zone":
                val = TimeZone.get(val);
                break;

            default:
                throw new Error("IllegalArgumentException: unrecognized option \\"" + key + "\\"");
        }

        rv[key] = val;
    });

    return rv;
};

/**
 *
 * @param {morn.TimeSeries.ValidatedCorrelationOptions} corrOptions
 * @returns {morn.TimeSeries.CorrelationExecutor}
 * @private
 */
var _getCorrelationFn = function (corrOptions) {

    if (corrOptions.ew_lambda !== null)
        return _newExponentiallyWeightedCorrelator(corrOptions.ew_lambda);
    else
        return _correlation;
};

/**
 * Returns whether \`p\` is a single-column product
 * made of a key (as opposed to root.)
 * @param {*} p
 * @returns {boolean}
 * @private
 */
var _isProduct_Key_SingleCol = function (p) {
    return (   p instanceof Product
        && p.key() !== null
        && p.columns().length === 1 );
};

/**
 * Returns whether \`ts\` is a single-column TimeSeries.
 * @param {*} ts
 * @returns {boolean}
 * @private
 */
var _isTimeSeries_SingleCol = function (ts) {
    return (   ts instanceof TimeSeries
        && ts.size() === 1 );
};

/**
 * Inspects a function's arguments for a Product at \`args[0]\`.  If a
 * Product is not there, this method assumes the first three arguments
 * are feed, key-values and column(s), and creates a Product from
 * those values.
 *
 * The returned list of arguments has a Product as the first entry,
 * with all subsequent arguments (if any) coming after it.
 * @param args {Arguments}
 * @return {Object[]} The first entry is a Product object; other entries
 *               are whatever was provided in the initial call (after \`columns\`).
 * @private
 */
var _toProduct = function (args) {

    var aa = Arrays.slice(args);

    if (!(aa[0] instanceof Product))
        aa.splice(0, 3, Product.withKey(aa[0], aa[1], aa[2]));

    return aa;
};

/**
 * Inspects a function's arguments for a Product at \`args[0]\`.  If a
 * Product is not there, this method assumes the first three arguments
 * are feed, root and column(s), and creates a Product from
 * those values.  This method throws if the Product has more than
 * one root.
 *
 * The returned list of arguments has a Product as the first entry,
 * with all subsequent arguments (if any) coming after it.
 * @param args {Arguments}
 * @return {Object[]} The first entry is a Product object; other entries
 *               are whatever was provided in the initial call (after \`columns\`).
 * @private
 */
var _toProductRoot = function (args) {

    var aa      = Arrays.slice(args),
        product = aa[0];

    if (!(product instanceof Product)) {
        // Wrap \`root\` into an array, forcing an error if multiple roots were passed.
        product = Product.withRoots(aa[0], [ aa[1] ], aa[2]);
        aa.splice(0, 3, product);
    }

    else if (product.roots().length > 1)
        throw new Error("UnsupportedOperationException: product contains more than one root (" + product.roots().length + ")");

    return aa;
};

/**
 * @param {string} feed Name of feed.
 * @param {Object[]} keys Stringified list of keys. Each item within the list represents a
 *        different series/TSID.
 * @param {string[]} cols Stringified list of columns.
 * @param {{id: string, tz: ?lim.TimeZone}} tz Time-zone info.
 * @param {?string} startDate Start date, yyyy-MM-dd or yyyy-MM-dd'T'HH:mm:ss.
 * @param {?string} endDate End date, yyyy-MM-dd or yyyy-MM-dd'T'HH:mm:ss.
 * @param {int} maxResults Maximum number of rows to return.
 * @return {morn.TimeSeries[]} List of time-series.
 * @private
 */
function _getRecords (feed, keys, cols, tz, startDate, endDate, maxResults) {

    Logger.info(
        "TimeSeries._getRecords - num-series [{}], start-date [{}], end-date [{}], time-zone [{}], maxResults [{}]",
        keys.length, startDate, endDate, tz.id, maxResults
    );
    Logger.info(
        "TimeSeries._getRecord - first key: feed [{}], values [{}], cols [{}]",
        feed, JSON.stringify(keys[0]), cols.join(',')
    );

    // Calling underlying Java function \`getRecords\`
    var payloadText = getRecords(
        feed,
        JSON.stringify(keys),
        JSON.stringify(cols),
        tz.id,
        startDate,
        endDate,
        maxResults
    );

    var payload = JSON.parse(payloadText);
    if (!(payload instanceof Array)) {
        throw new Error("IllegalStateException: getRecords() did not return an Array");
    }

    // If \`keys\` does not match anything known to the feed, \`getRecords\` could return an
    // empty array, depending on which implementation the underlying java worker used
    // (FormulaUtilApp vs. FormulaUtilHttpClient). We want to give a TimeSeries object
    // for each requested key, even if that key doesn't exist.
    if (payload.length < 1) {
        payload = Arrays.newInstance(keys.length, Arrays.EMPTY);
    }

    /** @type {morn.TimeSeries[]} */
    var list = payload.map(function (recs, i) {
        return _tsRecsToTs(recs, keys[i], cols, tz.tz)
    });

    Logger.info(
        "TimeSeries._getRecords - got [{}] rows across [{}] series",
        list.reduce(function (total, ts) {
            return total + ts.length();
        }, 0),
        list.length
    );

    return list;
}
/**
 * @param {string} feed Name of feed.
 * @param {Object[]} keys Stringified list of keys. Each item within the list represents a
 *        different series/TSID.
 * @param {string[]} cols Stringified list of columns.
 * @param {{id: string, tz: ?lim.TimeZone}} tz Time-zone info.
 * @param {?string} startDate Start date, yyyy-MM-dd or yyyy-MM-dd'T'HH:mm:ss.
 * @param {?string} endDate End date, yyyy-MM-dd or yyyy-MM-dd'T'HH:mm:ss.
 * @param {?string} fromInsertionDate End date, yyyy-MM-dd or yyyy-MM-dd'T'HH:mm:ss.
 * @param {int} maxResults Maximum number of rows to return.
 * @return {morn.TimeSeries[]} List of time-series.
 * @private
 */
function _getCorrections (feed, keys, cols, tz, startDate, endDate,fromInsertionDate, maxResults) {

    Logger.info(
        "TimeSeries._getCorrections - num-series [{}], start-date [{}], end-date [{}], fromInsertionDate [{}] time-zone [{}], maxResults [{}]",
        keys.length, startDate, endDate,fromInsertionDate, tz.id, maxResults
    );
    Logger.info(
        "TimeSeries._getCorrections - first key: feed [{}], values [{}], cols [{}]",
        feed, JSON.stringify(keys[0]), cols.join(',')
    );

    // Calling underlying Java function \`getRecords\`
    var payloadText = getCorrections(
        feed,
        JSON.stringify(keys),
        JSON.stringify(cols),
        tz.id,
        startDate,
        endDate,
        fromInsertionDate,
        maxResults
    );

    var payload = JSON.parse(payloadText);
    if (!(payload instanceof Array)) {
        throw new Error("IllegalStateException: getRecords() did not return an Array");
    }

    // If \`keys\` does not match anything known to the feed, \`getRecords\` could return an
    // empty array, depending on which implementation the underlying java worker used
    // (FormulaUtilApp vs. FormulaUtilHttpClient). We want to give a TimeSeries object
    // for each requested key, even if that key doesn't exist.
    if (payload.length < 1) {
        payload = Arrays.newInstance(keys.length, Arrays.EMPTY);
    }

    /** @type {morn.TimeSeries[]} */
    var list = payload.map(function (recs, i) {
        return _tsRecsToTs(recs, keys[i], cols, tz.tz)
    });

    Logger.info(
        "TimeSeries._getCorrections - got [{}] rows across [{}] series",
        list.reduce(function (total, ts) {
            return total + ts.length();
        }, 0),
        list.length
    );

    return list;
}

/**
 * @param {Array.<{date: string, values: string[]}>} recs Time-series records (server response).
 * @param {Object.<string, string>} keys Keys for this time-series (TSID).
 * @param {string[]} cols Columns.
 * @param {?lim.TimeZone} tz Time-zone.
 * @returns {morn.TimeSeries} New TimeSeries created from \`recs\`.
 * @throws {Error} If any record in \`recs\` does not contain the expected number of columns.
 * @private
 */
function _tsRecsToTs (recs, keys, cols, tz) {

    var taggedKeys = _tagServerData(keys);
    Logger.debug(
        "TimeSeries._tsRecsToTs - building time-series object for [{}]...",
        taggedKeys
    );

    var numCols = cols.length,
        tags    = cols.map(function (col) {
            return col + '(' + taggedKeys + ')';
        });

    /** @type {morn.TimeSeries.Row[]} */
    var rows = [];

    for (var i = 0, numRecs = recs.length; i < numRecs; i++) {
        var rec = recs[i];
        // if(rec.date.length === 16) {
        //     rec.date = parseCorrDate(rec.date);
        // }
        var date = IDate.create(rec.date);
        var vals = rec.values;
        var dataPoints = [];

        if (vals.length !== numCols) {
            throw new Error("Server returned mismatched number of values at row " + i);
        }
        for (var j = 0; j < numCols; j++) {
            dataPoints.push(new DataPoint(
                parseFloat(dateToNumber(vals[j])),
                tags[j]
            ));
        }
        rows.push(new Row(date, dataPoints));
    }
    var ts = new TimeSeries(numCols);
    ts._tz = tz;
    _addRowsUnsorted(ts, rows);
    return ts.freeze();
}

var parseCorrDate = function(corrDate) {
    var corrDateMonth = corrDate.substr(0,2);
    var corrDateDay = corrDate.substr(3,2);
    var corrDateYear = corrDate.substr(6,4);
    var corrDateHour = corrDate.substr(11,2);
    var corrDateMin = corrDate.substr(14,2);
    var corrDateSec = "00";
    // yyyy-MM-ddTHH:mm:ss
    return corrDateYear + '-' + corrDateMonth + '-' + corrDateDay + 'T' + corrDateHour + ':' + corrDateMin + ':' + corrDateSec;
}

/**
 *
 * @param {morn.Product} product
 * @param {?(lim.TimeZone|string)} timeZone
 * @param {?(lim.IDate|string)} [startDate]
 * @param {?(lim.IDate|string)} [endDate]
 * @param {Integer} [maxResults=-1]
 * @returns {(morn.TimeSeries)}
 * @private
 */
var _getServerData = function (
    product,
    timeZone,
    startDate,
    endDate,
    maxResults ) {

    // \`product\` has already been validated, but other
    // arguments must still be validated.

    var feed = product.feed();
    var keyValues = product.key();
    var cols = product.columns();

    if (keyValues === null) {
        throw new Error("\`product.key()\` == null");
    }

    return _getRecords(
        feed,
        [ keyValues ],  // Convert to array, for \`_getRecords\`
        cols,
        _validTimeZoneId(timeZone),
        _reqIsoDate(arguments, 2, "startDate"),
        _reqIsoDate(arguments, 3, "endDate"),
        _reqMaxResults(arguments, 4, "maxResults")
    )[0];
};

/**
 *
 * @param {morn.Product} product
 * @param {?(lim.TimeZone|string)} timeZone
 * @param {?(lim.IDate|string)} [startDate]
 * @param {?(lim.IDate|string)} [endDate]
 * @param {?(lim.IDate|string)} [fromInsertionDate]
 * @param {Integer} [maxResults=-1]
 * @returns {(morn.TimeSeries)}
 * @private
 */
var _getCorrectionData = function (
    product,
    timeZone,
    startDate,
    endDate,
    fromInsertionDate,
    maxResults ) {

    // \`product\` has already been validated, but other
    // arguments must still be validated.

    var feed = product.feed();
    var keyValues = product.key();
    var cols = product.columns();

    if (keyValues === null) {
        throw new Error("\`product.key()\` == null");
    }
    if(arguments.length < 2) {
        timeZone = null;
    }
    return _getCorrections(
        feed,
        [ keyValues ],  // Convert to array, for \`_getRecords\`
        cols,
        _validTimeZoneId(timeZone),
        _reqIsoDate(arguments, 2, "startDate"),
        _reqIsoDate(arguments, 3, "endDate"),
        _reqIsoDate(arguments, 4, "fromInsertionDate"),
        _reqMaxResults(arguments, 5, "maxResults")
    )[0];
};

/**
 * Gets multiple time-series at once.
 * @param {string} feed
 * @param {Array.<Object.<string, string>>} keys
 * @param {string[]} columns
 * @param {?string} timeZone
 * @param {?(string|lim.IDate)} [startDate]
 * @param {?(string|lim.IDate)} [endDate]
 * @param {Integer} [maxResults=-1]
 * @returns {morn.TimeSeries[]}
 * @private
 */
function _getServerDataMulti (
    feed,
    keys,
    columns,
    timeZone,
    startDate,
    endDate,
    maxResults ) {

    requireNonEmptyString(feed, "feed");
    Arrays.requireArray(keys, "keys");
    keys.forEach(function (key, i) {
        _validFeedKey(feed, key, "keys["+i+"]");
    });
    Arrays.requireNonEmpty(columns, "columns");
    columns.forEach(function (col, i) {
        Strings.requireNonEmpty(col, "columns["+i+"]");
    });

    // Make sure we have at least one key, otherwise LDS will fail.
    if (keys.length > 0) {
        return _getRecords(
            feed,
            keys,
            columns,
            _validTimeZoneId(timeZone),
            _reqIsoDate(arguments, 4, "startDate"),
            _reqIsoDate(arguments, 5, "endDate"),
            _reqMaxResults(arguments, 6, "maxResults")
        );
    } else {
        return Arrays.EMPTY;
    }
}

/**
 * Compares two TimeSeries and returns whether \`localTs\` contains any
 * new or changed data.
 *
 * @param {morn.TimeSeries} localTs
 * @param {morn.TimeSeries} remoteTs
 * @param {boolean} isPartialUpd - Whether the *partial* flag is "true".
 * @return {lim.IDate[]} Dates of rows in which changes were found.
 */
function _containsAnyNewData (localTs, remoteTs, isPartialUpd ) {

    Logger.debug("Entering TimeSeries._containsAnyNewData...");

    var local  = localTs.dropNaNs();
    var remote = remoteTs.dropNaNs();

    return (   local.length() !== remote.length()
        || _getModifiedDates(local, remote, isPartialUpd, true).length > 0 );
}

/**
 * Returns an array of modified that represents dates from \`localTs\` where the row is new
 * or contains changes when compared to \`remoteTs\`.
 *
 * This method assumes that numbers from \`remoteTs\` have already been corrected for
 * decimal approximation, and that numbers from \`localTs\` have not been corrected
 * for decimal approximation.
 *
 * @param {morn.TimeSeries} localTs Newly computed TS.
 * @param {morn.TimeSeries} remoteTs TS freshly fetched from the server.
 * @param {boolean} isPartialUpd Whether this update is a *partial* update.
 * @param {boolean} stopAtFirstDiff Whether to stop after finding the first date.
 * @returns {lim.IDate[]} Array of modified dates, limited to one date if \`stopAtFirstDiff\`
 *          is true.
 * @private
 */
function _getModifiedDates (localTs, remoteTs, isPartialUpd, stopAtFirstDiff) {

    var numRows = localTs.length(),
        numCols = localTs.size(),
        modDates = [];

    for ( var i = 0;
          i < numRows && (modDates.length === 0 || !stopAtFirstDiff);
          i++ ) {

        var localRow  = localTs._rows[i],
            date      = localRow.date(),
            remoteIdx = _getDateIdx(remoteTs, date);

        if (remoteIdx < 0) {
            modDates.push(date);
        } else {
            var localVals  = localRow._vals,
                remoteVals = remoteTs._rows[remoteIdx]._vals,
                isDiff     = false;

            for ( var j = 0;
                  j < numCols && !isDiff;
                  j++ ) {

                var localVal  = localVals[j]._val,
                    remoteVal = remoteVals[j]._val;

                if (isNaN(localVal)) {
                    if (   !isPartialUpd    // NaNs from \`localTs\` are ignored during partial updates.
                        && !isNaN(remoteVal) ) {
                        isDiff = true;
                    }
                } else if (isNaN(remoteVal)) {
                    isDiff = true;
                } else if (!_isSameNumber(localVal, remoteVal)) {
                    isDiff = true;
                }
            }
            if (isDiff) {
                modDates.push(date);
            }
        }
    }
    return modDates;
}

/**
 * @param {number} uncorrectedNumber Number which has not been decimal-approximation corrected.
 * @param {number} correctedNumber Number which has been decimal-approximation corrected.
 * @returns {boolean} Whether \`uncorrectedNumber\` and \`correctedNumbers\` are
 *          numeric equivalents.
 * @private
 * @example
 *     _isSameNumber(0.1 + 0.2, 0.3) => true
 */
function _isSameNumber (uncorrectedNumber, correctedNumber) {
    if (uncorrectedNumber === correctedNumber) {
        return true;
    } else {
        var p = Numbers.precision(uncorrectedNumber);
        if (p <= 0) {
            return false;
        } else if (p < Numbers.precision(correctedNumber)) {
            // Uncorrected number has fewer decimals: not the same.
            return false;
        } else {
            p++;
            var epsilon = Math.pow(10, -p);
            var diff = Math.abs(uncorrectedNumber - correctedNumber);
            // Logger.debug(
            //     "uncorrectedNumber=[{}], correctedNumber=[{}], diff=[{}], epsilon=[{}]",
            //     uncorrectedNumber.toString(),
            //     correctedNumber.toString(),
            //     diff.toString(),
            //     epsilon
            // );
            return (diff < epsilon);
        }
    }
}

/**
 * Saves a time-series, immediately or in batch.
 * @param {morn.TimeSeries} ts - TimeSeries to save.
 * @param {Arguments} callerArgs - Caller arguments.
 * @returns {boolean}
 * @private
 */
var _saveSeries = function (ts, callerArgs) {

    var args = _toProduct(callerArgs);

    // \`feed\`, \`keyValues\` and \`columns\` are validated;
    // no other argument is validated.

    var product      = args[0],
        cols         = product.columns(),
        isPartialUpd = _validBoolIfAvail(args, 1, "partial_updates", true);

    if (cols.length !== ts._size) {
        throw new Error("UnsupportedOperationException: mismatch columns! (" + cols.length + " vs. " + ts._size + ")");
    }

    _validProductKey(product, "product");

    // Check whether we're saving anything new.

    if (ts.isEmpty()) {
        Logger.warn("TimeSeries.save - canceled due to TimeSeries.isEmpty()");
        return false;
    }

    var tsToSave = ts;

    if (!Parameters.getBool(DISABLE_SAVE_OPTIMIZATION, false)) {
        var remoteTs = _getServerData(product, null, ts.dateAt(0), ts.dateAt(-1))
            .whenDateInAllOthers(ts);
        var modDates = _getModifiedDates(
            ts.dropNaNs(),
            remoteTs.dropNaNs(),
            isPartialUpd,
            false
        );
        if (modDates.length === 0) {
            Logger.info("TimeSeries.save - canceled due to NO_NEW_DATA");
            return false;
        }

        // trim to only what's new or changed.
        tsToSave = ts.keepDates(modDates);
    }

    if (Parameters.getBool(DISABLE_SAVE, false) === true) {
        Logger.info("TimeSeries.save - disabled due to {}", DISABLE_SAVE);
        return true;
    } else {
        // We have new data, submit to Rhino.
        var csvTimeSeries = _toCsvNoNan(tsToSave);

        Logger.info("TimeSeries.save - Proceed with saving as time-series.");
        Logger.debug("TimeSeries.save - payload:\\n{}", csvTimeSeries);

        // Callback into Rhino
        return saveSeriesJava(
            product.feed(),
            JSON.stringify(cols),
            csvTimeSeries,
            isPartialUpd,
            JSON.stringify(product.key())
        );
    }
};

/**
 * Saves the TimeSeries object as contracts.
 *
 * @param {morn.TimeSeries} ts - TimeSeries to save.
 * @param {Arguments} callerArgs - Caller arguments.
 * @param {boolean} doMaxGapCheck - Whether to check the time-series
 *        for gaps in the curve.
 * @return {boolean} Returns <em>true</em> most of the time.  Returns
 *                   <em>false</em> if optimization determined that no
 *                   new data is being saved.
 * @private
 */
var _saveAsContracts = function (ts, callerArgs, doMaxGapCheck) {

    // Validate input arguments.
    var args         = _toProductRoot(callerArgs),
        product      = args[0],
        feed         = product.feed(),
        root         = product.roots()[0],
        cols         = product.columns(),
        curveDate    = IDate.valueOf(args[1]),  // Validate \`curveDate\`
        deliveryType = DeliveryType.valueOf(args[2]);  // Validate \`deliveryType\`

    if (cols.length !== ts._size)
        throw new Error("UnsupportedOperationException: mismatch columns! (" + cols.length + " vs. " + ts._size + ")");

    _validDeliveryType(ts, deliveryType, doMaxGapCheck);

    var isPartialUpd = _validBoolIfAvail(args, 3, "partial_updates", true);

    // Check whether we're saving anything new.

    if (ts.isEmpty()) {
        Logger.warn("TimeSeries._saveAsContracts - canceled due to TimeSeries.isEmpty()");
        return false;
    }

    if (!Parameters.getBool(DISABLE_SAVE_OPTIMIZATION, false)) {
        if (!_containsAnyNewData( ts,
            morn.ForwardCurve.arbitrage( product,
                curveDate,
                deliveryType ),
            isPartialUpd ) ) {
            Logger.info("TimeSeries._saveAsContracts - canceled due to NO_NEW_DATA");
            return false;
        }
    }

    if (Parameters.getBool(DISABLE_SAVE, false) === true) {
        Logger.info("TimeSeries._saveAsContracts - disabled due to {}", DISABLE_SAVE);
        return true;
    } else {
        // We have new data, submit to Rhino.
        var csvTimeSeries = _toCsvNoNan(ts);

        Logger.info("TimeSeries._saveAsContracts - Proceed with saving as contracts/curve");
        Logger.debug( "TimeSeries._saveAsContracts - Payload:\\n{}",
            _maxLength(csvTimeSeries, 1000, "...[truncated]") );

        // Callback into Rhino
        return saveCurveJava(
            feed,
            JSON.stringify(cols),
            csvTimeSeries,
            isPartialUpd,
            root,
            curveDate.formatAdHoc(),
            deliveryType.period()
        );
    }
};


/**
 * Returns the edgiest date. That is, returns \`date1\` or \`date2\`
 * based on which one is left-most (dir == -1) or right-most (dir == 1).
 * @param {Number} dir - Non-zero
 * @param {lim.IDate} date1
 * @param {lim.IDate} date2
 * @returns {lim.IDate} Either \`date1\` or \`date2\`.
 * @private
 */
var _getEdgiestDate = function (dir, date1, date2) {

    if (_isSameSign(dir, date1 - date2))
        return date1;
    else
        return date2;
};

/**
 * Pulls historical data for the purpose of calculating a rolling correlation.
 * @param {(morn.TimeSeries|morn.Product)} product
 * @param {(lim.Date.Modifier|Integer)} range
 * @param {morn.TimeSeries.ValidatedCorrelationOptions} corrOptions
 * @private
 */
var _toCorrelationTs = function (product, range, corrOptions) {

    if (product instanceof TimeSeries)
        return product;  // Already a TimeSeries, don't perform server request.

    var start = corrOptions.start_date,
        end   = corrOptions.end_date;

    if (!Numbers.isInteger(range)) {
        if (start !== null) start = _getEdgiestDate(-1, start, range(start));
        if (end   !== null) end   = _getEdgiestDate( 1, end,   range(end));
    }

    return _getServerData(product, corrOptions.time_zone, start, end);
};

/**
 * Callback for adding a row to the correlation time-series.
 * @param {morn.TimeSeries} tsSlice
 * @param {morn.TimeSeries.NumericReducer} numReducer
 * @param {morn.TimeSeries.DateReducer} dateReducer
 * @param {string} tag
 * @returns {?morn.TimeSeries.Row}
 */
var _toReducedSlice = function (tsSlice, numReducer, dateReducer, tag) {

    var date   = dateReducer(tsSlice),
        values = numReducer(tsSlice);

    if (   date   === null
        || values === null )
        return null;
    else
        return _newRow(date, values, tag);
};

/**
 * Merges two single-column TimeSeries into one in preparation
 * for calculating correlation.
 *
 * @param {morn.TimeSeries} ts1
 * @param {morn.TimeSeries} ts2
 * @param {morn.TimeSeries.ValidatedCorrelationOptions} corrOptions
 * @param {boolean} ignoreDates - Whether to ignore dates within \`corrOptions\`.
 * @returns {morn.TimeSeries}
 * @private
 */
var _correlationPrep = function (ts1, ts2, corrOptions, ignoreDates) {

    var fill = corrOptions.fill,
        ts;

    if (fill === 0)
        ts = _intersection([ts1, ts2]);

    else if (fill > 0)
        ts = _union([ts1, ts2]).fillForward();

    else // if (fill < 0)
        ts = _union([ts1, ts2]).fillBackward();

    ts = ts.dropNaNs(true);

    if (!ignoreDates) {
        if (corrOptions.start_date !== null)
            ts = ts.whenDateAfterOrEqual(corrOptions.start_date);

        if (corrOptions.end_date !== null)
            ts = ts.whenDateBeforeOrEqual(corrOptions.end_date);
    }

    if (corrOptions.ew_lambda !== null)
        ts = ts.changeReturn();

    return ts;
};

/**
 * Calculates the correlation between the first and second
 * columns of \`ts\`.
 * @param {morn.TimeSeries} ts - Two-column TimeSeries
 * @returns {Number}
 * @type {morn.TimeSeries.NumericReducer}
 * @private
 */
var _correlation = function (ts) {

    if (ts.length() <= 1)
        return CORRELATION_INSUFFICIENT;

    var avg   = ts.reduceToAverage(),
        abSum = 0,
        a2Sum = 0,
        b2Sum = 0;

    _.each(ts._rows, function (row) {

        var a = row._vals[0]._val - avg[0],
            b = row._vals[1]._val - avg[1];

        abSum += (a * b);
        a2Sum += (a * a);
        b2Sum += (b * b);
    });

    return abSum / Math.sqrt(a2Sum * b2Sum);
};


/**
 * Constructs a function for the purposes of performing exponentially weighted
 * correlations.
 * @param {Number} smoothingFactor - range 0-1 (exclusive)
 * @returns {morn.TimeSeries.CorrelationExecutor}
 * @private
 */
var _newExponentiallyWeightedCorrelator = function (smoothingFactor) {

    /**
     * @type {morn.TimeSeries.CorrelationExecutor}
     * @see http://financetrain.com/calculating-ewma-correlation-using-excel/
     */
    return function (ts) {

        // This function assumes that some preparation work has been performed
        // to \`ts\`.  Notably, it has been transformed to contain the natural
        // logarithm values from the variations between each data-point.

        if (ts.length() <= 1)
            return CORRELATION_INSUFFICIENT;

        var a2Xw_sum  = 0,
            b2Xw_sum  = 0,
            aXbXw_sum = 0,
            w         = 1 - smoothingFactor,
            rows      = ts._rows;

        // scan in reverse
        for (var i = rows.length - 1; i >= 0; i--) {

            var row = rows[i],
                a   = row._vals[0]._val,
                b   = row._vals[1]._val;

            a2Xw_sum  += a * a * w;
            b2Xw_sum  += b * b * w;
            aXbXw_sum += a * b * w;

            w *= smoothingFactor;
        }

        return aXbXw_sum / ( Math.sqrt(a2Xw_sum) * Math.sqrt(b2Xw_sum) );
    };
};

/**
 * Returns a new date reducer callback.
 * @param {Integer} dateIdx
 * @returns {morn.TimeSeries.DateReducer}
 * @private
 */
var _newIndexBasedDateReducer = function (dateIdx) {

    return function (ts) {
        return ts.dateAt(dateIdx);
    };
};

/**
 * Performs a rolling calculation.
 *
 * @param {morn.TimeSeries} ts
 * @param {(Integer|lim.IDate.Modifier)} range
 * @param {morn.TimeSeries.DateReducer} dateReducer
 * @param {morn.TimeSeries.NumericReducer} numReducer
 * @param {string} tagBase
 * @returns {morn.TimeSeries}
 * @private
 */
var _rollingCalc = function (ts, range, dateReducer, numReducer, tagBase) {

    /** @type {morn.TimeSeries.Row[]} */
    var rows = [];

    if (Numbers.isInteger(range)) {

        // Slices are sized based on number of rows

        /** @type {Integer} */
        var sliceSize = range;
        var tag       = Strings.build("{}_{}", tagBase, range);

        for (var i = 0, end = ts.length() - sliceSize; i <= end; i++)
            _addNonNull(rows, _toReducedSlice(ts.slice(i, i + sliceSize), numReducer, dateReducer, tag));
    }

    else if (_isBackwardDateModifier(range)) {

        // Slices are sized based on date, looking backward.
        // The trick is to know where to start.
        var startDate = _findEdgeDate(ts, range, 1, 0);

        if (startDate !== null) {
            var dates = ts.dates(startDate);
            _.each(dates, function (sliceEndDate) {

                var sliceStartDate = range(sliceEndDate);
                _addNonNull(rows, _toReducedSlice(ts.slice(ts.nextDate(sliceStartDate), sliceEndDate), numReducer, dateReducer, tagBase));
            });
        }
    }
    else {  // forward-looking Date Modifier

        // Slices are sized based on date, looking forward.
        // The trick is to know where to end.
        var endDate = _findEdgeDate(ts, range, -1, -1);

        if (endDate !== null) {
            var dates = ts.dates(0, endDate);
            _.each(dates, function (sliceStartDate) {

                var sliceEndDate = range(sliceStartDate);
                _addNonNull(rows, _toReducedSlice(ts.slice(sliceStartDate, ts.prevDate(sliceEndDate)), numReducer, dateReducer, tagBase));
            });
        }
    }

    var numRows = rows.length;
    if (numRows <= 0) {
        return _getEmpty(1);
    } else {
        var rv = new TimeSeries(rows[0].numColumns());
        rv._tz = ts._tz;
        _addRowsSorted(rv, rows);
        return rv.freeze();
    }
};

/**
 * Calculates a regression.
 * @param {morn.TimeSeries} y - Predictable outcome or dependent variable; one column.
 * @param {morn.TimeSeries} x - Explanatory or independent variables; one or more column(s).
 * @param {Function} regrFn
 * @returns {morn.TimeSeries.LinearRegressionResults}
 * @private
 */
var _regressionResults = function (y, x, regrFn) {

    _validTimeSeries(y, "y");
    _validTimeSeries(x, "x");

    if (y.size() !== 1)
        throw new Error("IllegalArgumentException: y must have one column only.");

    // Drop NaN values.
    var yNoNan = y.dropNaNs(true),
        xNoNan = x.dropNaNs(true),
        dates  = _intersectionDates([yNoNan, xNoNan]);

    // Keep common dates only.
    yNoNan = yNoNan.keepDates(dates);
    xNoNan = xNoNan.keepDates(dates);

    return regrFn(yNoNan.columns(0), xNoNan.columns());
};

TimeSeries.prototype = /** @lends morn.TimeSeries.prototype */ {
    constructor: TimeSeries,


    /* *****************************************
      * PUBLIC METHODS
      * ***************************************** */

    /**
     * Freeze the instance, making it immutable.
     * @return {morn.TimeSeries} The newly frozen TimeSeries.
     */
    freeze: function () {

        // sort for the last time, if it's needed.
        _getSortedRows(this);

        Object.freeze(this);
        Object.freeze(this._rows);
        Object.freeze(this._index);
        return this;
    },


    /**
     * Returns the number of columns within this TimeSeries.
     * @return {integer} Greater or equal to 1.
     */
    numColumns: function () {
        return this._size;
    },

    /**
     * Returns the number of columns within this TimeSeries.
     * @return {integer} Greater or equal to 1.
     */
    size: function () {
        return this._size;
    },

    /**
     * Returns the number of rows within this instance.
     * @return {integer}
     */
    length: function () {
        return this._rows.length;
    },

    /**
     * Returns whether this TimeSeries is empty (has no row).
     * This is the same as <code>length() === 0</code>.
     * @return {boolean}
     */
    isEmpty: function () {
        return (this._rows.length === 0);
    },

    /**
     * Add one row to this TimeSeries
     * @param row {morn.TimeSeries.Row}
     * @return {morn.TimeSeries}
     */
    addRow: function (row) {
        if (!(row instanceof Row)) {
            throw new TypeError("row: Row");
        }
        if (row.numColumns() !== this._size) {
            throw new Error(
                "Mismatch number of columns, " + row.numColumns() + " vs. " + this._size
            );
        }
        return _addOneRow(this, row);
    },

    /**
     * Add many rows to this TimeSeries.
     * @param rows {morn.TimeSeries.Row[]}
     * @return {morn.TimeSeries}
     */
    addRows: function (rows) {
        if (!(rows instanceof Array)) {
            throw new TypeError("rows: Array");
        }

        // Validate the rows
        rows.forEach(function (row, index) {
            if (!(row instanceof Row)) {
                throw new TypeError("rows[" + index + "]: Row");
            }
            if (row.numColumns() !== this._size) {
                throw new Error(
                    "rows[" + index + "]: mismatch number of columns, " + row.numColumns() + " vs. " + this._size
                );
            }
        }, this);
        _addRowsUnsorted(this, rows);
        return this;
    },

    /**
     * Returns an iterator of rows.
     * @return {Iterator}
     */
    rowIterator: function () {
        return new Iterator(this._rows);
    },

    /**
     * Returns the index position at which <code>date</code>
     * was found, or -1 if not found.
     *
     * @param date {(lim.IDate|string)}
     * @return {integer}
     */
    dateIndex: function (date) {
        return _getDateIdx(this, _validDate(date));
    },


    /**
     * Returns whether this TimeSeries contains the specified
     * <code>date</code>.
     * @param date {(lim.IDate|string)}
     * @return {boolean}
     */
    contains: function (date) {
        // Avoid unecessary sorting by checking the index directly.
        return this._index.hasOwnProperty(_validDate(date).getTime());
    },



    /**
     * Returns whether this TimeSeries starts on or before
     * <code>date</code>.
     *
     * Returns <em>false</em> if <code>isEmpty()</code>.
     *
     * @param date {(lim.IDate|string)}
     * @return {boolean}
     */
    startsBeforeOrOn: function (date) {
        return (   this._rows.length > 0
            && this.dateAt(0).getTime() <= _validDate(date).getTime() );
    },

    /**
     * Returns whether this TimeSeries ends on or after
     * <code>date</code>.
     *
     * Returns <em>false</em> if <code>isEmpty()</code>.
     *
     * @param date {(lim.IDate|string)}
     * @return {boolean}
     */
    endsAfterOrOn: function (date) {
        return (   this._rows.length > 0
            && this.dateAt(-1).getTime() >= _validDate(date).getTime() );
    },

    /**
     * Returns whether this TimeSeries has a date range that
     * begins on or before <code>date</code> and ends
     * on or after <code>date</code>.
     *
     * Returns <em>false</em> if <code>isEmpty()</code>.
     *
     * @param date {(lim.IDate|string)}
     * @return {boolean}
     */
    surroundsDate: function (date) {

        var time = _validDate(date).getTime();

        return (   this._rows.length > 0
            && this.dateAt(0).getTime()  <= time
            && this.dateAt(-1).getTime() >= time );
    },


    /**
     * Returns the row at the specified row <code>index</code>.
     * Use a negative index value to start from the end.
     * @param {Integer} index
     * @return {morn.TimeSeries.Row}
     * @throws ArrayIndexOutOfBoundException if <code>index</code> is not within range.
     */
    rowAt: function (index) {
        return this._rows[_validRowIndexSorted(this, index, false)];
    },

    /**
     * Returns the date at the specified row <code>index</code>.
     * Use a negative index value to start from the end.
     * @param {Integer} index
     * @return {lim.IDate}
     * @throws ArrayIndexOutOfBoundException if <code>index</code> is not within range.
     */
    dateAt: function (index, fallbackValue) {
        return this.rowAt(index).date();
    },


    /**
     * Returns the numeric value at the specified \`rowIndex\` and
     * (optional) \`columnIndex\`.
     * @param {Integer} rowIndex - Row index.  Use a negative row index to start from the end.
     * @param {Integer} [columnIndex=0] - Column index.
     * @return {number} Can be <code>Number.NaN</code>.
     * @throws ArrayIndexOutOfBoundException if <code>rowIndex</code>
     *                               or <code>columnIndex</code> are not within range.
     */
    valueAt: function (rowIndex, columnIndex) {
        return this._rows[_validRowIndexSorted(this, rowIndex, false)].value(columnIndex);
    },

    /**
     * Returns the row associated with <code>date</code>.
     * @param {(lim.IDate|string)} date
     * @return {morn.TimeSeries.Row}
     * @throws IllegalArgumentException if <code>date</code> is not found.
     */
    rowOf: function (date) {
        return _getRowAtIndex( this, this.dateIndex(date),
            "IllegalArgumentException: date not found" );
    },

    /**
     * Returns the DataPoint as of the specified <code>date</code>.
     * If <code>date</code> is before <code>dateAt(0)</code>
     * this method returns DataPoint.NaN.
     *
     * This method searches backwards until it finds a finite number
     * data-point (not NaN, not +Infinity, not -Infinity).
     *
     * @param {(lim.IDate|string)} date
     * @param {Integer} [columnIndex] - Non-negative.
     * @return {morn.TimeSeries.DataPoint} Can be <code>DataPoint.NaN</code>.
     */
    dataPointAsOf: function (date, columnIndex) {
        return _dataPointAsOf(this, date, columnIndex);
    },

    /**
     * Returns the value as of the specified <code>date</code>.
     * If <code>date</code> is before <code>dateAt(0)</code>
     * this method returns NaN.
     *
     * This method searches backwards until it finds a finite number
     * (not NaN, not +Infinity, not -Infinity).
     *
     * @param date {(lim.IDate|string)}
     * @param [columnIndex] {integer} Non-negative.
     * @return {number} Can be <code>Number.NaN</code>.
     */
    valueAsOf: function (date, columnIndex) {
        return _dataPointAsOf(this, date, columnIndex).value();
    },

    /**
     * Returns the DataPoint of <code>date</code>, or the first DataPoint
     * that comes after it.  If <code>date</code> is after <code>dateAt(-1)</code>
     * this method returns DataPoint.NaN.
     *
     * This method searches forwards until it finds a finite number
     * data-point (not NaN, not +Infinity, not -Infinity).
     *
     * @param date {(lim.IDate|string)}
     * @param [columnIndex] {integer} Non-negative.
     * @return {morn.TimeSeries.DataPoint} Can be <code>DataPoint.NaN</code>.
     */
    ceilDateDataPoint: function (date, columnIndex) {
        return _dataPointOfOrNext(this, date, columnIndex);
    },

    /**
     * Returns the value or <code>date</code>, or the first value that comes
     * after it.  If <code>date</code> is after <code>dateAt(-1)</code>
     * this method returns NaN.
     *
     * This method searches forwards until it finds a finite number
     * (not NaN, not +Infinity, not -Infinity).
     *
     * @param date {(lim.IDate|string)}
     * @param [columnIndex] {integer} Non-negative.
     * @return {number} Can be <code>Number.NaN</code>.
     */
    ceilDateValue: function (date, columnIndex) {
        return _dataPointOfOrNext(this, date, columnIndex).value();
    },

    /**
     * Returns the index position of <code>date</code>, or the index
     * position of the highest date lower than <code>date</code>.
     *
     * Returns <em>-1</em> if <code>date</code> is lower than
     * <code>dateAt(0)</code>.
     *
     * @param date {(lim.IDate|string)}
     * @return {integer}
     */
    floorIndex: function (date) {
        return _getFloorIndex(this, date);
    },

    /**
     * Returns the index position of <code>date</code>, or the index
     * position of the highest date lower than <code>date</code>,
     * or <em>0</em> if <code>date</code> is lower than
     * <code>dateAt(0)</code>.
     *
     * Returns <em>-1</em> if <code>isEmpty()</code>.
     *
     * @param date {(lim.IDate|string)}
     * @return {integer}
     */
    floorOrFirstIndex: function (date) {
        return _getFloorOrFirstIndex(this, date);
    },

    /**
     * Returns the index position of the row that comes immediately
     * before <code>date</code>, or <em>-1</em> if no such exists.
     * @param {(lim.IDate|string)} date
     * @returns {Integer}
     */
    prevIndex: function (date) {
        var idx = _getCeilIndex(this, date);
        if (idx >= 0)
            return idx - 1;
        else
            return this._rows.length - 1;
    },

    /**
     * Returns the row associated with <code>date</code>, or the row
     * with the highest date lower than <code>date</code>.
     *
     * @param date {(lim.IDate|string)}
     * @return {morn.TimeSeries.Row}
     * @throws IllegalArgumentException if <code>date</code> is lower than <code>dateAt(0)</code>.
     */
    floorRow: function (date) {
        return _getRowAtIndex( this, _getFloorIndex(this, date),
            "IllegalArgumentException: date is lower than dateAt(0)" );
    },


    /**
     * Returns the row associated with <code>date</code>, or the row
     * with the highest date lower than <code>date</code>, or the first
     * row if <code>date</code> is lower than <code>dateAt(0)</code>.
     *
     * @param date {(lim.IDate|string)}
     * @return {morn.TimeSeries.Row}
     * @throws IllegalArgumentException if <code>isEmpty()</code>.
     */
    floorOrFirstRow: function (date) {
        return _getRowAtIndex( this, _getFloorOrFirstIndex(this, date),
            "IllegalStateException: instance is empty" );
    },

    /**
     * Returns the row that comes immediately before <code>date</code>.
     * @param {(lim.IDate|string)} date
     * @return {morn.TimeSeries.Row}
     * @throws IllegalArgumentException if <code>date</code> is lower than or equivalent to <code>dateAt(0)</code>.
     */
    prevRow: function (date) {

        var idx = this.prevIndex(date);
        if (idx >= 0)
            return this._rows[idx];
        else
            throw new Error("IllegalArgumentException: \`date\` is lower than or equal to dateAt(0)");
    },

    /**
     * Returns <code>date</code> if it exists in this TimeSeries,
     * or the highest date lower than <code>date</code>.
     *
     * @param date {(lim.IDate|string)}
     * @return {lim.IDate}
     * @throws IllegalArgumentException if <code>date</code> is lower than <code>dateAt(0)</code>.
     */
    floorDate: function (date) {
        return this.floorRow(date).date();
    },


    /**
     * Returns <code>date</code> if it exists in this TimeSeries,
     * or the highest date lower than <code>date</code>, or
     * <code>dateAt(0)</code> if <code>date</code> is lower than
     * <code>dateAt(0)</code>.
     *
     * @param {(lim.IDate|string)} date
     * @return {lim.IDate}
     * @throws IllegalArgumentException if <code>isEmpty()</code>.
     */
    floorOrFirstDate: function (date) {
        return this.floorOrFirstRow(date).date();
    },


    /**
     * Returns the date of the row that comes immediately before <code>date</code>.
     * @param {(lim.IDate|string)} date
     * @return {lim.IDate}
     * @throws IllegalArgumentException if <code>date</code> is lower than or equivalent to <code>dateAt(0)</code>.
     */
    prevDate: function (date) {
        return this.prevRow(date).date();
    },

    /**
     * Returns the index position of <code>date</code>, or the index
     * position of the lowest date higher than <code>date</code>.
     *
     * Returns <em>-1</em> if <code>date</code> is higher than
     * <code>dateAt(-1)</code>.
     *
     * @param date {(lim.IDate|string)}
     * @return {integer}
     */
    ceilIndex: function (date) {
        return _getCeilIndex(this, date);
    },

    /**
     * Returns the index position of <code>date</code>, or the index
     * position of the lowest date higher than <code>date</code>,
     * or <em>length() - 1</em> if <code>date</code> is higher than
     * <code>dateAt(-1)</code>.
     *
     * Returns <em>-1</em> if <code>isEmpty()</code>.
     *
     * @param date {(lim.IDate|string)}
     * @return {integer}
     */
    ceilOrLastIndex: function (date) {
        return _getCeilOrLastIndex(this, date);
    },

    /**
     * Returns the index position of the row that comes immediately
     * after <code>date</code>, or <em>-1</em> if no such exists.
     * @param {(lim.IDate|string)} date
     * @returns {Integer}
     */
    nextIndex: function (date) {
        var idx = _getFloorIndex(this, date);
        if (idx < this._rows.length - 1)
            return idx + 1;
        else
            return -1;
    },


    /**
     * Returns the row associated with <code>date</code>, or the row
     * with the lowest date higher than <code>date</code>.
     *
     * @param date {(lim.IDate|string)}
     * @return {morn.TimeSeries.Row}
     * @throws IllegalArgumentException if <code>date</code> is higher than <code>dateAt(-1)</code>.
     */
    ceilRow: function (date) {
        return _getRowAtIndex( this, _getCeilIndex(this, date),
            "IllegalArgumentException: date is higher than dateAt(-1)" );
    },


    /**
     * Returns the row associated with <code>date</code>, or the row
     * with the lowest date higher than <code>date</code>, or the last
     * row if <code>date</code> is higher than <code>dateAt(-1)</code>.
     *
     * @param date {(lim.IDate|string)}
     * @return {morn.TimeSeries.Row}
     * @throws IllegalArgumentException if <code>isEmpty()</code>.
     */
    ceilOrLastRow: function (date) {
        return _getRowAtIndex( this, _getCeilOrLastIndex(this, date),
            "IllegalStateException: instance is empty" );
    },


    /**
     * Returns the row that comes immediately after <code>date</code>.
     * @param {(lim.IDate|string)} date
     * @return {morn.TimeSeries.Row}
     * @throws IllegalArgumentException if <code>date</code> is higher than or equivalent to <code>dateAt(-1)</code>.
     */
    nextRow: function (date) {

        var idx = this.nextIndex(date);
        if (idx >= 0)
            return this._rows[idx];
        else
            throw new Error("IllegalArgumentException: \`date\` is higher than or equal to dateAt(-1)");
    },

    /**
     * Returns <code>date</code> if it exists in this TimeSeries,
     * or the lowest date higher than <code>date</code>.
     *
     * @param date {(lim.IDate|string)}
     * @return {lim.IDate}
     * @throws IllegalArgumentException if <code>date</code> is higher than <code>dateAt(-1)</code>.
     */
    ceilDate: function (date) {
        return this.ceilRow(date).date();
    },


    /**
     * Returns <code>date</code> if it exists in this TimeSeries,
     * or the lowest date higher than <code>date</code>, or
     * <code>dateAt(-1)</code> if <code>date</code> is higher than
     * <code>dateAt(-1)</code>.
     *
     * @param date {(lim.IDate|string)}
     * @return {lim.IDate}
     * @throws IllegalArgumentException if <code>isEmpty()</code>.
     */
    ceilOrLastDate: function (date) {
        return this.ceilOrLastRow(date).date();
    },

    /**
     * Returns the date of the row that comes immediately after <code>date</code>.
     * @param {(lim.IDate|string)} date
     * @return {lim.IDate}
     * @throws IllegalArgumentException if <code>date</code> is higher than or equivalent to <code>dateAt(-1)</code>.
     */
    nextDate: function (date) {
        return this.nextRow(date).date();
    },


    /**
     * <p>
     *  Saves this time-series into the given feed.
     *  Once saved, this time-series can be re-created using
     *  {@link time_series}.
     * </p>
     *
     * <p>
     *  The first three arguments - feed, keyValues and columns -
     *  can be collapsed into one {@link morn.Product|Product} argument.
     *  When invoked in this manner, this method requires 1 argument
     *  only (2 at most).
     * </p>
     *
     * @param {string} feed - The feed to save to.
     * @param {Object} keyValues - The keys and values for these numbers.
     * @param {(string|string[])} columns - A name for each of the column.
     *                  Multiple column names can still be specified
     *                  using a single string value, using commas to separate
     *                  the columns.
     * @param {boolean} [partial_updates=true] - Save using partial_updates or not.
     *                          If not set, it will default to the feed's default behavior.
     * @return {boolean} Returns <em>true</em> most of the time.  Returns
     *                   <em>false</em> if optimization determined that no
     *                   new data is being saved.
     */
    save: function () {
        return _saveSeries(this, arguments);
    },

    /**
     * <p>
     *  Saves each row of this TimeSeries as a separate futures contract,
     *  into the given feed.  Once saved, the <em>curve</em> can be re-created
     *  using {@link forward_curve}.
     * </p>
     *
     * <p>
     *  This method validates that there are no gaps in TimeSeries.
     * </p>
     *
     * <p>
     *  The first three arguments - feed, keyValues and columns -
     *  can be collapsed into one {@link morn.Product|Product} argument.
     *  When invoked in this manner, this method requires 3 argument
     *  only (4 at most).
     * </p>
     *
     * @param {string} feed - The feed to save to.
     * @param {string} root - The root symbol under which to save.
     * @param {(string|string[])} columns - A name for each of the column.
     *          Multiple column names can still be specified
     *          using a single string value, using commas to separate
     *          the columns.
     * @param {(string|lim.IDate)} curveDate - If <code>curveDate</code> is
     *          only a date - without time - make sure to pass
     *          a string value to avoid time zone offsets.  For
     *          strings, formats "yyyy-MM-dd" and "M/d/yyyy" are
     *          supported.
     * @param {(string|morn.DeliveryType)} deliveryType - Used to create contracts
     *          and validate that there are no gaps in the <em>curve</em>.
     * @param {boolean} [partial_updates=true] Whether to write with partial corrects or not.
     *                          If not set, it will default to the feed's default behavior.
     * @return {boolean} Returns <em>true</em> most of the time.  Returns
     *                   <em>false</em> if optimization determined that no
     *                   new data is being saved.
     */
    saveAsContracts: function () {
        return _saveAsContracts(this, arguments, true);
    },

    /**
     * <p>
     *  Saves each row of this TimeSeries as a separate futures contract,
     *  into the given feed.  Once saved, the <em>curve</em> can be re-created
     *  using {@link forward_curve}.
     * </p>
     *
     * <p>
     *  This method allows for gaps in TimeSeries.
     * </p>
     *
     * <p>
     *  The first three arguments - feed, keyValues and columns -
     *  can be collapsed into one {@link morn.Product|Product} argument.
     *  When invoked in this manner, this method requires 3 argument
     *  only (4 at most).
     * </p>
     *
     * @param {string} feed - The feed to save to.
     * @param {string} root - The root symbol under which to save.
     * @param {(string|string[])} columns - A name for each of the column.
     *          Multiple column names can still be specified
     *          using a single string value, using commas to separate
     *          the columns.
     * @param {(string|lim.IDate)} curveDate - If <code>curveDate</code> is
     *          only a date - without time - make sure to pass
     *          a string value to avoid time zone offsets.  For
     *          strings, formats "yyyy-MM-dd" and "M/d/yyyy" are
     *          supported.
     * @param {(string|morn.DeliveryType)} deliveryType - Used to create contracts.
     * @param {boolean} [partial_updates=true] - Whether to write with partial corrects or not.
     *          If not set, it will default to the feed's default behavior.
     * @return {boolean} Returns <em>true</em> most of the time.  Returns
     *         <em>false</em> if optimization determined that no
     *         new data is being saved, or if save functionality is disabled.
     */
    saveAsContractsWithGaps: function () {
        return _saveAsContracts(this, arguments, false);
    },

    /**
     * Returns a new TimeSeries with only the selected
     * column(s).
     * @param {...Integer} colIndices 0-base index of
     *                   column(s) within the TimeSeries.
     * @return {morn.TimeSeries}
     */
    extract: function (colIndices) {

        var indices    = _validColIndices(arguments, this, false),
            numNewCols = indices.length,
            rows       = this._rows,
            ts         = new TimeSeries(indices.length);

        ts._tz = this._tz;

        for (var i = 0, numRows = rows.length; i < numRows; i++) {
            var row        = rows[i],
                dataPoints = [];
            for (var j = 0; j < numNewCols; j++) {
                dataPoints.push(row._vals[indices[j]]);
            }
            _addOneRow(ts, new Row(row.date(), dataPoints));
        }
        return ts.freeze();
    },


    /**
     * Returns a subset of rows from this TimeSeries.
     * @param [start=0] {(integer|lim.IDate)}
     *              An integer or date
     *              that specifies where the selection starts.
     *              Use a negative integer to select from the end.
     *
     *              This argument is required in order to provide
     *              <code>end</code>.
     *
     * @param [end=length()] {(integer|lim.IDate)}
     *            An integer or date
     *            that specifies where the selection ends.
     *            If omitted, all elements from the start position
     *            to the end are selected.
     *            Use a negative integer to select from the end.
     *
     * @return {morn.TimeSeries}
     */
    slice: function (start, end) {

        var range = _getRange(this, arguments);

        return _cloneTimeSeries(this, range).freeze();
    },

    /**
     * Drops rows from the beginning of this TimeSeries.
     *
     * If <code>numRows</code> is 0 or negative, this method
     * returns the same TimeSeries, unmodified.
     *
     * @param [numRows=1] {integer}
     * @return {morn.TimeSeries}
     */
    dropFirst: function (numRows) {

        numRows = _validNumRows.apply(this, arguments);

        if (numRows > 0)
            return this.slice(numRows);
        else
            return this;
    },

    /**
     * Drops rows from the end of this TimeSeries.
     *
     * If <code>numRows</code> is 0 or negative, this method
     * returns the same TimeSeries, unmodified.
     *
     * @param [numRows=1] {integer}
     * @return {morn.TimeSeries}
     */
    dropLast: function (numRows) {

        numRows = _validNumRows.apply(this, arguments);

        if (numRows > 0)
            return this.slice(0, -numRows);
        else
            return this;
    },

    /**
     * Keeps rows from the beginning of this TimeSeries,
     * drops all other rows.
     *
     * If <code>numRows</code> is 0 or negative, this method
     * returns an empty TimeSeries.
     *
     * @param [numRows=1] {integer}
     * @return {morn.TimeSeries}
     */
    keepFirst: function (numRows) {

        numRows = _validNumRows.apply(this, arguments);

        if (numRows > 0)
            return this.slice(0, numRows);
        else
            return _getEmpty(this._size);
    },

    /**
     * Keeps rows from the end of this TimeSeries,
     * drops all other rows.
     *
     * If <code>numRows</code> is 0 or negative, this method
     * returns an empty TimeSeries.
     *
     * @param [numRows=1] {integer}
     * @return {morn.TimeSeries}
     */
    keepLast: function (numRows) {

        numRows = _validNumRows.apply(this, arguments);

        if (numRows > 0)
            return this.slice(-numRows);
        else
            return _getEmpty(this._size);
    },

    /**
     * Creates a TimeSeries from rows of this TimeSeries
     * for which the date can be found in all TimeSeries from
     * <code>otherTimeSeries</code>.
     *
     * @param otherTimeSeries {...morn.TimeSeries}
     * @return {morn.TimeSeries}
     */
    whenDateInAllOthers: function (otherTimeSeries) {

        var dates = _intersectionDates(_insertAt0(this, arguments));
        return this.keepDates(dates);
    },

    /**
     * Creates a TimeSeries from rows of this TimeSeries
     * for which the date can be found in at least one
     * TimeSeries from <code>otherTimeSeries</code>.
     *
     * @param otherTimeSeries {...morn.TimeSeries}
     * @return {morn.TimeSeries}
     */
    whenDateInOneOther: function (otherTimeSeries) {

        var dates = _unionDates(arguments);
        return this.keepDates(dates);
    },

    /**
     * Creates a TimeSeries from rows of this TimeSeries
     * for which the date cannot be found in any of the
     * TimeSeries provided in <code>otherTimeSeries</code>.
     *
     * @param otherTimeSeries {...morn.TimeSeries}
     * @return {morn.TimeSeries}
     */
    whenDateInNoneOther: function (otherTimeSeries) {

        var dates = _unionDates(arguments);
        return this.dropDates(dates);
    },

    /**
     * Creates a TimeSeries from rows of this TimeSeries
     * where dates are after <code>date</code>.
     *
     * @param date {(lim.IDate|string)}
     * @return {morn.TimeSeries}
     */
    whenDateAfter: function (date) {
        return this.slice(_getFloorIndex(this, date) + 1);
    },

    /**
     * Creates a TimeSeries from rows of this TimeSeries
     * where dates are after or equivalent to <code>date</code>.
     *
     * @param date {(lim.IDate|string)}
     * @return {morn.TimeSeries}
     */
    whenDateAfterOrEqual: function (date) {

        var idx = _getCeilIndex(this, date);
        if (idx >= 0)
            return this.slice(idx);
        else
            return _getEmpty(this._size);
    },

    /**
     * Creates a TimeSeries from rows of this TimeSeries
     * where dates are before <code>date</code>.
     *
     * @param date {(lim.IDate|string)}
     * @return {morn.TimeSeries}
     */
    whenDateBefore: function (date) {

        var idx = _getCeilIndex(this, date);
        if (idx >= 0)
            return this.slice(0, idx);
        else
            return _getEmpty(this._size);
    },

    /**
     * Creates a TimeSeries from rows of this TimeSeries
     * where dates are before or equivalent to <code>date</code>.
     *
     * @param date {(lim.IDate|string)}
     * @return {morn.TimeSeries}
     */
    whenDateBeforeOrEqual: function (date) {

        var idx = _getFloorIndex(this, date);
        if (idx >= 0)
            return this.slice(0, idx + 1);
        else
            return _getEmpty(this._size);
    },

    /**
     * Creates a TimeSeries from rows of this TimeSeries
     * when dates fall on <code>holidays</code>.
     *
     * @param holidays {morn.Holidays} A holiday calendar.
     * @param [isPeriodEnding=false] {boolean} Whether to use
     *        period-beginning (false) or period-ending (true).
     *        Only use <em>true</em> with intraday data;
     *        behavior is otherwise undefined.
     * @param [dayCutoff="00:00"] {(integer|string)} Time of day at which the
     *              day begins. If <em>integer</em>, argument
     *              represents milliseconds since midnight.
     *              If <em>string</em>, argument is expected to be
     *              in "H:mm" format.
     *              Use this argument to includes portions of the previous or
     *              next day as part of the current day.  For example, specifying
     *              "22:00" means that data on or after 10 PM will be considered
     *              part of the next day.  Similarily, "2:00" means that data up to
     *              2 AM (exclusive) will be considered part of the previous day.
     * @return {morn.TimeSeries}
     */
    whenHoliday: function (holidays, isPeriodEnding, dayCutoff) {
        return _whenHoliday(this, arguments, true);
    },

    /**
     * Creates a TimeSeries from rows of this TimeSeries
     * when dates do not fall on <code>holidays</code>.
     *
     * @param holidays {morn.Holidays} A holiday calendar.
     * @param [isPeriodEnding=false] {boolean} Whether to use
     *        period-beginning (false) or period-ending (true).
     *        Only use <em>true</em> with intraday data;
     *        behavior is otherwise undefined.
     * @param [dayCutoff="00:00"] {(integer|string)} Time of day at which the
     *              day begins. If <em>integer</em>, argument
     *              represents milliseconds since midnight.
     *              If <em>string</em>, argument is expected to be
     *              in "H:mm" format.
     *              Use this argument to includes portions of the previous or
     *              next day as part of the current day.  For example, specifying
     *              "22:00" means that data on or after 10 PM will be considered
     *              part of the next day.  Similarily, "2:00" means that data up to
     *              2 AM (exclusive) will be considered part of the previous day.
     * @return {morn.TimeSeries}
     */
    whenNotHoliday: function (holidays, isPeriodEnding, dayCutoff) {
        return _whenHoliday(this, arguments, false);
    },

    /**
     * Creates a TimeSeries from rows of this TimeSeries
     * where at least one column contains a value greater
     * than <code>value</code>.
     *
     * @param value {number}
     * @return {morn.TimeSeries}
     */
    whenValueGreater: function (value) {

        if (!_isFiniteNumber(value))
            throw new TypeError("value: Number");

        return this.select(function (row) {
            return (row.max() > value);
        });
    },

    /**
     * Creates a TimeSeries from rows of this TimeSeries
     * where at least one column contains a value greater
     * than or equal to <code>value</code>.
     *
     * @param value {number}
     * @return {morn.TimeSeries}
     */
    whenValueGreaterOrEqual: function (value) {

        if (!_isFiniteNumber(value))
            throw new TypeError("value: Number");

        return this.select(function (row) {
            return (row.max() >= value);
        });
    },

    /**
     * Creates a TimeSeries from rows of this TimeSeries
     * where at least one column contains a value less
     * than <code>value</code>.
     *
     * @param value {number}
     * @return {morn.TimeSeries}
     */
    whenValueLess: function (value) {

        if (!_isFiniteNumber(value))
            throw new TypeError("value: Number");

        return this.select(function (row) {
            return (row.min() < value);
        });
    },


    /**
     * Creates a TimeSeries from rows of this TimeSeries
     * where at least one column contains a value less
     * than or equal to <code>value</code>.
     *
     * @param value {number}
     * @return {morn.TimeSeries}
     */
    whenValueLessOrEqual: function (value) {

        if (!_isFiniteNumber(value))
            throw new TypeError("value: Number");

        return this.select(function (row) {
            return (row.min() <= value);
        });
    },

    /**
     * Creates a TimeSeries from rows of this TimeSeries
     * where the sole column of <em>this</em> TimeSeries
     * contains a value within the <em>low</em> and <em>high</em>
     * values from <code>thatTimeSeries</code> for the same date.
     *
     * If <code>thatTimeSeries</code> doesn't have a row for the given
     * date or if a column contains a NaN (NaN, +Infinity or -Infinity)
     * for that date, the previous value is
     * used to calculate <em>low</em> and <em>high</em>.
     *
     * @param thatTimeSeries {morn.TimeSeries} Must contain at least 2 columns.
     * @return {morn.TimeSeries}
     */
    whenValueWithin: function (thatTimeSeries) {
        return _val0VsRange(this, thatTimeSeries, true);
    },

    /**
     * Creates a TimeSeries from rows of this TimeSeries
     * where the sole column of <em>this</em> TimeSeries
     * contains a value NOT within the <em>low</em> and <em>high</em>
     * values from <code>thatTimeSeries</code> for the same date.
     *
     * If <code>thatTimeSeries</code> doesn't have a row for the given
     * date or if a column contains a NaN (NaN, +Infinity or -Infinity)
     * for that date, the previous value is
     * used to calculate <em>low</em> and <em>high</em>.
     *
     * @param thatTimeSeries {morn.TimeSeries} Must contain at least 2 columns.
     * @return {morn.TimeSeries}
     */
    whenValueOutsideOf: function (thatTimeSeries) {
        return _val0VsRange(this, thatTimeSeries, false);
    },

    /**
     * Returns an array of dates contained by this TimeSeries.
     * The array is sorted and mutable; changes to it will
     * not affect this TimeSeries instance.
     * @param {(Integer|lim.IDate)} [start=0]
     *              An integer or date
     *              that specifies where the selection starts.
     *              Use a negative integer to select from the end.
     *
     *              This argument is required in order to provide
     *              <code>end</code>.
     *
     * @param {(Integer|lim.IDate)} [end=length()]
     *            An integer or date
     *            that specifies where the selection ends.
     *            If omitted, all elements from the start position
     *            to the end are selected.
     *            Use a negative integer to select from the end.
     *
     * @return {lim.IDate[]} Sorted.
     */
    dates: function (start, end) {

        var rows = _getRowsSubset(this, arguments);

        return _.map(rows, function (row) {
            return row.date();
        });
    },


    /**
     * Returns an array of Row contained by this TimeSeries.
     * The array is sorted and mutable; changes to it will
     * not affect this TimeSeries instance.
     *
     * @param {(Integer|lim.IDate)} [start=0]
     *              An integer or date
     *              that specifies where the selection starts.
     *              Use a negative integer to select from the end.
     *
     *              This argument is required in order to provide
     *              <code>end</code>.
     *
     * @param {(Integer|lim.IDate)} [end=length()]
     *            An integer or date
     *            that specifies where the selection ends.
     *            If omitted, all elements from the start position
     *            to the end are selected.
     *            Use a negative integer to select from the end.
     *
     * @return {morn.TimeSeries.Row[]} Sorted.
     */
    rows: function (start, end) {
        return _getRowsSubset(this, arguments);
    },


    /**
     * Returns a list of all dates found within this
     * TimeSeries and all other TimeSeries provided.
     * @param otherTimeSeries {...morn.TimeSeries}
     * @return {lim.IDate[]}
     */
    unionDates: function (otherTimeSeries) {
        return _unionDates(_insertAt0(this, arguments));
    },

    /**
     * Returns a list of dates common to this
     * TimeSeries and all other TimeSeries provided.
     * @param otherTimeSeries {...morn.TimeSeries}
     * @return {lim.IDate[]}
     */
    intersectionDates: function (otherTimeSeries) {
        return _intersectionDates(_insertAt0(this, arguments));
    },


    /**
     * Returns a new TimeSeries instance that represent the union
     * of <code>otherTimeSeries</code> appended to this TimeSeries,
     * including all columns and all dates.
     *
     * @param otherTimeSeries {...morn.TimeSeries}
     * @return {morn.TimeSeries}
     */
    union: function (otherTimeSeries) {
        return _union(_insertAt0(this, arguments));
    },

    /**
     * Returns a new TimeSeries instance that represent the intersection
     * of <code>otherTimeSeries</code> appended to this TimeSeries,
     * including all columns but only the dates common to all.
     *
     * @param otherTimeSeries {...morn.TimeSeries}
     * @return {morn.TimeSeries}
     */
    intersection: function (otherTimeSeries) {
        return _intersection(_insertAt0(this, arguments));
    },

    /**
     * Returns a TimeSeries where all rows from
     * <code>otherTimeSeries</code> have been merged
     * into one TimeSeries.  This method does not create
     * new columns; only rows.
     *
     * This TimeSeries instance is used at the base.
     * Any other TimeSeries provided will have their
     * rows added to the <em>base</em>, only if they
     * don't already exist.
     *
     * To overwrite existing rows, use <code>overwrite()</code>.
     *
     * @param timeSeries {...morn.TimeSeries}
     * @return {morn.TimeSeries}
     * @throws IllegalArgumentException if the number of columns
     *                                  does not match between all
     *                                  TimeSeries.
     */
    complement: function (otherTimeSeries) {
        return TimeSeries.complement.apply(this, _insertAt0(this, arguments));
    },


    /**
     * Returns a TimeSeries where all rows from
     * <code>otherTimeSeries</code> have been merged
     * into one TimeSeries.  This method does not create
     * new columns; only rows.
     *
     * This TimeSeries instance is used at the base.
     * The rows of <code>otherTimeSeries</code> overwrite
     * the <em>base</em>.
     *
     * Use <code>complement()</code> if you do not wish to
     * overwrite rows.
     *
     * @param otherTimeSeries {...morn.TimeSeries}
     * @return {morn.TimeSeries}
     * @throws IllegalArgumentException if the number of columns
     *                                  does not match between all
     *                                  TimeSeries.
     */
    overwrite: function (otherTimeSeries) {
        return TimeSeries.overwrite.apply(this, _insertAt0(this, arguments));
    },

    /**
     * <p>
     *  Returns a new TimeSeries created off this TimeSeries
     *  without <i>NaN</i> rows.
     * </p>
     * <p>
     *  If <code>noSingleNaN</code> is false or not provided,
     *  this method drops rows that contain not one
     *  single finite value (only NaN, +Infinity, -Infinity).
     *  In this way, the returned TimeSeries could still contain
     *  NaN values, if one of the column contains a finite value.
     * </p>
     * <p>
     *  If <code>noSingleNaN</code> is true, this method
     *  drops rows where a non-finite value is found, in any column.
     *  In this way, the returned TimeSeries does not contain
     *  any NaN value.
     * </p>
     *
     * @param {boolean} [noSingleNaN=false] - If true, the resulting
     *         TimeSeries contains only finite numbers.
     * @return {morn.TimeSeries}
     */
    dropNaNs: function (noSingleNaN) {

        var isSingleNaN = _validBoolIfAvail(arguments, 0, "noSingleNaN", false),
            passFilter;

        if (isSingleNaN === false) {
            passFilter = function (row) {
                return !row.isNaN();
            };
        } else {
            passFilter = function (row) {
                return !row.containsNaN();
            };
        }
        // Access private members directly for better performance.

        var rows    = this._rows,
            reduced = _newTs(this);

        for (var i = 0, len = rows.length; i < len; i++) {
            var row = rows[i];
            if (passFilter(row)) {
                _addOneRow(reduced, row);
            }
        }
        return reduced.freeze();
    },


    /**
     * Applies a time offset to all rows in this TimeSeries.
     * @param offset {(integer|function)} Integers are treated as millisecond
     *               offsets.  Functions are treated as callbacks; they
     *               are called with a date argument (the row date) and
     *               must return a date value (the new row date).
     * @return {morn.TimeSeries}
     */
    offset: function (offset) {

        var callback = null,
            tag      = 'offset';

        if (typeof offset === 'function') {

            if (offset.length !== 1)
                throw new TypeError("offset: Function, with 1 (date) argument");

            callback = offset;
        }

        else if (!_isFiniteNumber(offset))
            throw new TypeError("offset: Integer or Function");

        else if (offset === 0)  // Optimization: nothing to do.
            return this;

        else {

            callback = function (date) {
                return date.addMilliseconds(offset);
            };
            tag += '_by(' + IDate.toHumanTime(offset) + ')';
        }


        var dates = _.map(_getDates(this), callback);

        return _replaceDates(this, dates, this._tz, tag, Arrays.EMPTY);
    },

    /**
     * Adjusts all dates within this TimeSeries so that
     * they are displayed using the same instant within
     * the given time zone.
     * @param tz {(string|lim.TimeZone)}
     * @return {morn.TimeSeries}
     */
    withZone: function (tz) {

        tz = TimeZone.get(tz);  // validate \`tz\`

        var dates = _inTz(_getDates(this), tz),
            tag   = "in_timezone(" + tz.id() + ")";

        return _replaceDates(this, dates, tz, tag, Arrays.EMPTY);
    },


    /**
     * Adjusts all dates within this TimeSeries so that
     * they are displayed using the same values within
     * the (presumably) different time zone.
     * @param tz {(string|lim.TimeZone)}
     * @param {boolean} [fixDst=false] - When giving a time zone to a list of dates, gaps
     *                                   and overlaps can appear due to DST changes.
     *                                   Set <code>fixDst</code> to true to auto-fill the
     *                                   gaps and remove the overlaps.  (Gaps are filled
     *                                   using values from the repeating hour(s) on the wall clock.)
     * @return {morn.TimeSeries}
     */
    withZoneRetainFields: function (tz, fixDst) {

        tz     = TimeZone.get(tz);  // validate \`tz\`
        fixDst = _validBoolIfAvail(arguments, 1, "fixDst", false);

        var tag         = "give_timezone(" + tz.id() + ")",
            datesBefore = _getDates(this),
            datesAfter  = _giveTz(datesBefore, tz),
            fixes       = (  (fixDst && datesBefore.length > 1)
                ? IDate.getDstFixes(datesAfter, datesBefore, tz)
                : Arrays.EMPTY );

        return _replaceDates(this, datesAfter, tz, tag, fixes);
    },


    /**
     * Creates a new TimeSeries from selected rows.
     * The <code>selector</code> function is called on
     * each row; it should return <em>true</em> when the
     * given row should be included in the new TimeSeries
     * (selected).
     *
     * @param selector {function} A function that accepts one Row
     *                 argument, returns a Boolean value.
     * @return {morn.TimeSeries}
     */
    select: function (selector) {

        var filter = _timeSeriesFilter;
        return filter.run(this, filter.includers.isTrue, selector);
    },

    /**
     * Creates a new TimeSeries without the <em>dropped</em> rows.
     * The <code>dropper</code> function is called on each row;
     * it should return <em>true</em> when the given row should
     * be excluded from the new TimeSeries (dropped).
     *
     * @param dropper {function} A function that accepts a Row
     *                 object and returns a Boolean value.
     * @return {morn.TimeSeries}
     */
    drop: function (dropper) {

        var filter = _timeSeriesFilter;
        return filter.run(this, filter.includers.isNotTrue, dropper);
    },

    /**
     * Creates a new TimeSeries from rows within the given
     * time of day.
     *
     * @param start {(integer|string)} Time of day at which the
     *              period begins. If <em>integer</em>, argument
     *              represents milliseconds since midnight.
     *              If <em>string</em>, argument is expected to be
     *              in "H:mm" format.
     * @param end {(integer|string)} Time of day at which the
     *              period ends. If <em>integer</em>, argument
     *              represents milliseconds since midnight.
     *              If <em>string</em>, argument is expected to be
     *              in "H:mm" format.
     * @param [isPeriodEnding=false] {boolean} Whether to use
     *        period-beginning logic (false) or period-ending logic (true).
     *        Period-beginning treats <code>start</code> as inclusive and
     *        <code>end</code> as exclusive.  On the opposite, period-ending
     *        treats <code>start</code> as exclusive and <code>end</code>
     *        as inclusive.
     * @return {morn.TimeSeries}
     */
    whenTimeWithin: function (start, end, isPeriodEnding) {
        return this.select(_getTimeSelector.apply(this, arguments));
    },

    /**
     * Creates a new TimeSeries from rows outside of the given
     * time of day.
     *
     * @param start {(integer|string)} Time of day at which the
     *              period begins. If <em>integer</em>, argument
     *              represents milliseconds since midnight.
     *              If <em>string</em>, argument is expected to be
     *              in "H:mm" format.
     * @param end {(integer|string)} Time of day at which the
     *              period ends. If <em>integer</em>, argument
     *              represents milliseconds since midnight.
     *              If <em>string</em>, argument is expected to be
     *              in "H:mm" format.
     * @param [isPeriodEnding=false] {boolean} Whether to use
     *        period-beginning logic (false) or period-ending logic (true).
     *        Period-beginning treats <code>start</code> as inclusive and
     *        <code>end</code> as exclusive.  On the opposite, period-ending
     *        treats <code>start</code> as exclusive and <code>end</code>
     *        as inclusive.
     * @return {morn.TimeSeries}
     */
    whenTimeOutside: function (start, end, isPeriodEnding) {
        return this.drop(_getTimeSelector.apply(this, arguments));
    },

    /**
     * Creates a new TimeSeries from rows corresponding to
     * <code>daysOfWeek</code>.
     *
     * @param daysOfWeek {integer[]} A list of day-of-week to be selected.
     * @param [isPeriodEnding=false] {boolean} Whether to use
     *        period-beginning logic (false) or period-ending logic (true).
     *        Period-beginning treats the midnight mark as part of the
     *        same date.  On the opposite, period-ending treats that
     *        same midnight mark as part of the previous day.
     * @param [dayCutoff="00:00"] {(integer|string)} Time of day at which the
     *              day begins. If <em>integer</em>, argument
     *              represents milliseconds since midnight.
     *              If <em>string</em>, argument is expected to be
     *              in "H:mm" format.
     *              Use this argument to includes portions of the previous or
     *              next day as part of the current day.  For example, specifying
     *              "22:00" means that data on or after 10 PM will be considered
     *              part of the next day.  Similarily, "2:00" means that data up to
     *              2 AM (exclusive) will be considered part of the previous day.
     * @return {morn.TimeSeries}
     */
    whenDayOfWeek: function (daysOfWeek, isPeriodEnding, dayCutoff) {

        if (!Arrays.isValid(daysOfWeek, function (dayOfWeek) {
            return (   Numbers.isInteger(dayOfWeek)
                && dayOfWeek >= 0
                && dayOfWeek <= 6 );
        }))
            throw new TypeError("daysOfWeek: Array-of-Integer, range 0-6");

        var numArgs = arguments.length;

        if (numArgs < 2)
            isPeriodEnding = false;
        else if (typeof isPeriodEnding !== 'boolean')
            throw new TypeError("isPeriodEnding: Boolean");


        if (numArgs < 3)
            dayCutoff = 0;
        else
            dayCutoff = _toTime(dayCutoff);

        return this.select(_getDayOfWeekSelector(daysOfWeek, isPeriodEnding, dayCutoff));
    },

    /**
     * Creates a new TimeSeries from rows corresponding to
     * week days only (Monday through Friday).
     *
     * @param [isPeriodEnding=false] {boolean} Whether to use
     *        period-beginning logic (false) or period-ending logic (true).
     *        Period-beginning treats the midnight mark as part of the
     *        same date.  On the opposite, period-ending treats that
     *        same midnight mark as part of the previous day.
     * @param [dayCutoff="00:00"] {(integer|string)} Time of day at which the
     *              day begins. If <em>integer</em>, argument
     *              represents milliseconds since midnight.
     *              If <em>string</em>, argument is expected to be
     *              in "H:mm" format.
     *              Use this argument to includes portions of the previous or
     *              next day as part of the current day.  For example, specifying
     *              "22:00" means that data on or after 10 PM will be considered
     *              part of the next day.  Similarily, "2:00" means that data up to
     *              2 AM (exclusive) will be considered part of the previous day.
     * @return {morn.TimeSeries}
     */
    whenWeekday: function (isPeriodPending, dayCutoff) {

        var args = Arrays.slice(arguments);
        args.unshift(IDate.WEEK_DAYS);

        return this.whenDayOfWeek.apply(this, args);
    },

    /**
     * Creates a new TimeSeries from rows corresponding to
     * weekend days only (Saturday, Sunday).
     *
     * @param [isPeriodEnding=false] {boolean} Whether to use
     *        period-beginning logic (false) or period-ending logic (true).
     *        Period-beginning treats the midnight mark as part of the
     *        same date.  On the opposite, period-ending treats that
     *        same midnight mark as part of the previous day.
     * @param [dayCutoff="00:00"] {(integer|string)} Time of day at which the
     *              day begins. If <em>integer</em>, argument
     *              represents milliseconds since midnight.
     *              If <em>string</em>, argument is expected to be
     *              in "H:mm" format.
     *              Use this argument to includes portions of the previous or
     *              next day as part of the current day.  For example, specifying
     *              "22:00" means that data on or after 10 PM will be considered
     *              part of the next day.  Similarily, "2:00" means that data up to
     *              2 AM (exclusive) will be considered part of the previous day.
     * @return {morn.TimeSeries}
     */
    whenWeekend: function (isPeriodPending, dayCutoff) {

        var args = Arrays.slice(arguments);
        args.unshift(IDate.WEEKEND_DAYS);

        return this.whenDayOfWeek.apply(this, args);
    },

    /**
     * Creates a new TimeSeries from rows corresponding to peak periods.
     * By default, peak periods are defined as week days (Mon-Fri) that are not
     * holidays. Peak periods can also be defined as <code>daysOfWeek</code>.
     * Within those days, only certain hours of the day are considered peak periods.
     *
     * @param [holidays=null] {?(morn.Holidays|string)} A holiday calendar, name or object.
     * @param [start="7:00"] {?(integer|string)} Time of day at which the peak
     *                       period begins. If <em>integer</em>, argument
     *                       represents milliseconds since midnight.
     *                       If <em>string</em>, argument is expected to be
     *                       in "H:mm" format.
     * @param [end="22:00"] {?(integer|string)} Time of day at which the peak
     *                      period ends. If <em>integer</em>, argument
     *                      represents milliseconds since midnight.
     *                      If <em>string</em>, argument is expected to be
     *                      in "H:mm" format.
     * @param [isPeriodEnding=false] {?boolean} Whether to use
     *        period-beginning logic (false) or period-ending logic (true).
     *        Period-beginning treats <code>start</code> as inclusive and
     *        <code>end</code> as exclusive.  On the opposite, period-ending
     *        treats <code>start</code> as exclusive and <code>end</code>
     *        as inclusive.
     * @param [daysOfWeek=IDate.WEEK_DAYS] {integer[]} A list of day-of-week that represents
     *        peak periods.
     * @param [iso="ERCOT"]{String} iso name for setting default paramters specific to iso
     * @return {morn.TimeSeries}
     *
     * @see morn.TimeSeries#whenWeekday
     * @see morn.TimeSeries#whenNotHoliday
     * @see morn.TimeSeries#whenTimeWithin
     */
    whenPeak: function (holidays, start, end, isPeriodEnding, daysOfWeek,iso) {

        var args           = _getPeakArgs(this, arguments),
            isPeriodEnding = args.isPeriodEnding,
            daysOfWeek     = args.daysOfWeek,
            ts             = this;

        ts = ts.whenDayOfWeek(daysOfWeek, isPeriodEnding);

        if (args.holidays !== null)
            ts = ts.whenNotHoliday(args.holidays, isPeriodEnding);

        return ts.select(args.timeSelector);
    },

    /**
     * Creates a new TimeSeries from rows corresponding to off-peak periods.
     * By default, off-peak periods are defined as weekends (Sat, Sun) and holidays.
     * Additionally, off-hour periods during week days (Mon-Fri) are also
     * off-peak periods. Off-peak periods will change if <code>daysOfWeek</code>
     * is not <code>null</code>.
     *
     * @param [holidays=null] {?(morn.Holidays|string)} A holiday calendar, name or object.
     * @param [start="7:00"] {?(integer|string)} Time of day at which the peak
     *                       period begins. If <em>integer</em>, argument
     *                       represents milliseconds since midnight.
     *                       If <em>string</em>, argument is expected to be
     *                       in "H:mm" format.
     * @param [end="22:00"] {?(integer|string)} Time of day at which the peak
     *                      period ends. If <em>integer</em>, argument
     *                      represents milliseconds since midnight.
     *                      If <em>string</em>, argument is expected to be
     *                      in "H:mm" format.
     * @param [isPeriodEnding=false] {?boolean} Whether to use
     *        period-beginning logic (false) or period-ending logic (true).
     *        Period-beginning treats <code>start</code> as inclusive and
     *        <code>end</code> as exclusive.  On the opposite, period-ending
     *        treats <code>start</code> as exclusive and <code>end</code>
     *        as inclusive.
     * @param [daysOfWeek=IDate.WEEK_DAYS] {integer[]} A list of day-of-week that represents
     *        peak periods.
     * @param [iso="ERCOT"]{String} iso name for setting default paramters specific to iso
     * @return {morn.TimeSeries}
     *
     * @see morn.TimeSeries#whenWeekend
     * @see morn.TimeSeries#whenHoliday
     * @see morn.TimeSeries#whenTimeOutside
     * @see morn.TimeSeries#whenDateInNoneOther
     */
    whenOffPeak: function (holidays, start, end, isPeriodEnding, daysOfWeek,iso) {

        var onPeak = this.whenPeak.apply(this, arguments);
        return this.whenDateInNoneOther(onPeak);
    },

    /**
     * Creates a new TimeSeries by computing daily averages of the peak periods.
     *
     * @param [holidays=null] {?(morn.Holidays|string)} A holiday calendar, name or object.
     * @param [start="7:00"] {?(integer|string)} Time of day at which the peak
     *                       period begins. If <em>integer</em>, argument
     *                       represents milliseconds since midnight.
     *                       If <em>string</em>, argument is expected to be
     *                       in "H:mm" format.
     * @param [end="22:00"] {?(integer|string)} Time of day at which the peak
     *                      period ends. If <em>integer</em>, argument
     *                      represents milliseconds since midnight.
     *                      If <em>string</em>, argument is expected to be
     *                      in "H:mm" format.
     * @param [isPeriodEnding=false] {?boolean} Whether to use
     *        period-beginning logic (false) or period-ending logic (true).
     *        Period-beginning treats <code>start</code> as inclusive and
     *        <code>end</code> as exclusive.  On the opposite, period-ending
     *        treats <code>start</code> as exclusive and <code>end</code>
     *        as inclusive.
     * @param [daysOfWeek=IDate.WEEK_DAYS] {integer[]} A list of day-of-week that represents
     *        peak periods.
     * @param [isDayEnding=false] {boolean} Whether to use day-ending logic or not.
     *                            If <em>true</em>,
     *                            {@link morn.TimeSeries.DateGroupingMethods.DAY_ENDING_ON_PREVIOUS|DAY_ENDING_ON_PREVIOUS}
     *                            is used.
     * @param [iso="ERCOT"]{String} iso name for setting default paramters specific to iso
     * @return {morn.TimeSeries}
     *
     * @see morn.TimeSeries#dailyAverage
     * @see morn.TimeSeries#whenPeak
     */
    dailyAveragePeak: function (holidays, start, end, isPeriodEnding, daysOfWeek, isDayEnding,iso) {

        var onPeak = this.whenPeak.apply(this, arguments);
        var args = [];

        if (arguments.length > 5) {
            args.push(arguments[5]);
        }

        IDate.setTimezoneAware(false);
        return this.dailyAverage.apply(onPeak, args);
    },

    /**
     * Creates a new TimeSeries by computing daily averages of the off-peak periods.
     *
     * @param [holidays=null] {?(morn.Holidays|string)} A holiday calendar, name or object.
     * @param [start="7:00"] {?(integer|string)} Time of day at which the peak
     *                       period begins. If <em>integer</em>, argument
     *                       represents milliseconds since midnight.
     *                       If <em>string</em>, argument is expected to be
     *                       in "H:mm" format.
     * @param [end="22:00"] {?(integer|string)} Time of day at which the peak
     *                      period ends. If <em>integer</em>, argument
     *                      represents milliseconds since midnight.
     *                      If <em>string</em>, argument is expected to be
     *                      in "H:mm" format.
     * @param [isPeriodEnding=false] {?boolean} Whether to use
     *        period-beginning logic (false) or period-ending logic (true).
     *        Period-beginning treats <code>start</code> as inclusive and
     *        <code>end</code> as exclusive.  On the opposite, period-ending
     *        treats <code>start</code> as exclusive and <code>end</code>
     *        as inclusive.
     * @param [daysOfWeek=IDate.WEEK_DAYS] {integer[]} A list of day-of-week that represents
     *        peak periods.
     * @param [isDayEnding=false] {boolean} Whether to use day-ending logic or not.
     *                            If <em>true</em>,
     *                            {@link morn.TimeSeries.DateGroupingMethods.DAY_ENDING_ON_PREVIOUS|DAY_ENDING_ON_PREVIOUS}
     *                            is used.
     * @param [iso="ERCOT"]{String} iso name for setting default paramters specific to iso
     * @return {morn.TimeSeries}
     *
     * @see morn.TimeSeries#dailyAverage
     * @see morn.TimeSeries#whenOffPeak
     */
    dailyAverageOffPeak: function (holidays, start, end, isPeriodEnding, daysOfWeek, isDayEnding,iso) {

        var offPeak = this.whenOffPeak.apply(this, arguments);
        var args = [];

        if (arguments.length > 5) {
            args.push(arguments[5]);
        }

        IDate.setTimezoneAware(false);
        return this.dailyAverage.apply(offPeak, args);
    },

    /**
     * This method iterates through the rows of this
     * TimeSeries and calls <code>modifier</code> for every
     * row.  The <code>modifier</code> callback receives
     * two arguments:
     *   > Row row - the Row object
     *   > Number rowIndex - the index position within TimeSeries
     *
     * Within the callback, <em>this</em> points to the TimeSeries.
     *
     * To modify a row, <code>modifier</code> needs to return
     * a valid Row or DataPoint[],
     * which will be inserted into the returned TimeSeries.
     *
     * If <code>modifier</code> returns <em>null</em>
     * or <em>undefined</em>, the existing row is transfered
     * to the new TimeSeries unmodified.
     *
     * The resulting TimeSeries has the same number
     * of rows and columns as this (source) TimeSeries.
     *
     * @param modifier {function}
     * @return {morn.TimeSeries}
     * @throws IllegalStateException if <code>modifier</code> returns anything
     *         other than Row, DataPoint[], null or <em>undefined</em>.
     */
    modifyRows: function (modifier) {
        return _modifyRows(this, modifier);
    },

    /**
     * This method iterates through the data-points of this
     * TimeSeries and calls <code>modifier</code> for every
     * numerical data-point.  The <code>modifier</code> callback receives
     * four arguments:
     *   > IDate date
     *   > Number value
     *   > Integer rowIndex
     *   > Integer colIndex
     *
     * Note that <code>modifier</code> is not called for NaN data-points.
     * Within the callback, <em>this</em> points to the TimeSeries.
     *
     * To modify a data-point, <code>modifier</code> needs to return
     * a Number - NaN, +Infinity and -Infinity are acceptable returned
     * values. The new value is used to <em>mutate</em> the existing
     * data-point into a new one (for tracing purpose.)
     *
     * If <code>modifier</code> returns anything other than a
     * number - <em>null</em>, <em>undefined</em> or anything else -
     * the existing data-point is transfered to the new TimeSeries
     * unmodified.
     *
     * The resulting TimeSeries has the same number
     * of rows and columns as this (source) TimeSeries.
     *
     * @param tag {string} To document data-point mutations.
     * @param modifier {morn.TimeSeries.ValueModifier} Callback function used to replace
     *                 the existing values with new ones.
     * @return {morn.TimeSeries}
     * @throws TypeError
     *    - If <code>modifier</code> is not a Function that
     *      defines 2 to 4 arguments.
     *    - If <code>tag</code> is not a String.
     */
    modifyDataPoints: function (tag, modifier) {

        if (typeof tag !== 'string')
            throw new TypeError("tag: String");

        _validValueModifier(modifier);

        var filter            = _dataPointFilters.noNaNs,
            dataPointModifier = _dataPointModifier( modifier,
                tag,
                filter ),
            rowModifier       = _rowModifier(dataPointModifier);

        return _modifyRows(this, rowModifier);
    },

    /**
     * Use this method to replace NaN values within this
     * TimeSeries.  NaN values can be NaN, +Infinity and -Infinity.
     *
     * The <code>replaceWith</code> argument can be a Number
     * (NaN, +Infinity, -Infinity are acceptable) or a
     * Function (defining 2 to 4 arguments).
     *
     * The resulting TimeSeries has the same number
     * of rows and columns as this (source) TimeSeries.
     *
     * @param replaceWith {(number|morn.TimeSeries.ValueModifier)}
     *                    If a function, that function is used as a callback
     *                    to replace the NaN values with valid numeric values.
     *                    Note that in this case, the 2nd argument of the callback
     *                    function - <em>value</em> - will always be <em>NaN</em>.
     *                    Still, that 2nd argument must be declared.
     * @param [tag] {string} Overwrites default tag.
     * @return {morn.TimeSeries}
     * @throws TypeError
     *     - If <code>replaceWith</code> is neither a Number nor a Function;
     *     - If <code>replaceWith</code> is a function that defines less
     *       than 2 or more than 4 arguments.
     */
    replaceNaNs: function (replaceWith, tag) {

        var args  = _getReplaceWithTag(arguments, 'replace_NaNs'),
            dpMod = _dataPointModifier( args.valueModifier,
                args.tag,
                _dataPointFilters.onlyNaNs );

        return _modifyRows(this, _rowModifier(dpMod));
    },


    /**
     * Use this method to extrapolate a TimeSeries,
     * adding new dates and replacing the NaNs within it.
     * @param calendar {(morn.Calendar|lim.IDate[]|lim.IDate|string)}
     * @param replaceWith {(number|morn.TimeSeries.ValueModifier)}
     *                    If a function, that function is used as a callback
     *                    to insert numeric values in places where none exists,
     *                    or where NaNs exist.
     *                    Note that in this case, the 2nd argument of the callback
     *                    function - <em>value</em> - will always be <em>NaN</em>.
     *                    Still, that 2nd argument must be declared.
     * @param [tag="extrapolate"] {string} Overwrites default tag.
     * @return {morn.TimeSeries}
     */
    extrapolate: function (calendar, replaceWith, tag) {

        var ex    = "calendar: Calendar, IDate[], IDate or String",
            dates = _toDateArray(calendar, ex, Calendar),
            args  = _getReplaceWithTag( Arrays.slice(arguments, 1),
                'extrapolate' );

        return _fillNans(this, dates, args.valueModifier, args.tag, 1);
    },

    /**
     * Interpolates this TimeSeries, inserting missing dates and using
     * linear-interpolation to populate missing values.
     * This method replaces all NaN values found between valid numbers.
     *
     * @param {(morn.Calendar|lim.IDate[]|lim.IDate|string|function)} [calendar=[]]
     *        Dates to insert in this TimeSeries.  It can be a list of dates,
     *        one simple date (possibly as a string).  It can also be a function,
     *        in which case the function will be executed with two arguments:
     *        <code>this.dateAt(0)</code> and <code>this.dateAt(-1)</code>
     *        respectively.  The function must return an object of the previously
     *        listed data-types.
     * @param {boolean} [useLinearTime=false] - Whether the interpolation is based
     *        on row position within the TimeSeries (default), or based on the linear
     *        passage of time.
     * @returns {morn.TimeSeries}
     */
    interpolateLinear: function (calendar, useLinearTime) {

        if (this.length() < 2)
            return this;

        var dates   = Arrays.EMPTY,
            useTime = false,
            numArgs = arguments.length;

        if (numArgs > 0) {

            var cal = calendar;
            if (typeof calendar === "function") {
                if (calendar.length >= 2)
                    cal = calendar(this.dateAt(0), this.dateAt(-1));
                else
                    throw new TypeError("calendar: when Function, must take 2 date arguments");
            }

            dates = _toDateArray(cal, "calendar: Calendar, IDate[], IDate or String", Calendar);

            if (numArgs > 1) {
                if (typeof useLinearTime !== "boolean")
                    throw new TypeError("useLinearTime: Boolean");

                useTime = useLinearTime;
            }
        }

        // Create mutable copy, insert NaN where dates are missing.
        var copy = _insertNans(this, dates);

        // Indexes where finite values are found, grouped by column.
        var valueIndexes = _buildValueIndexes(copy);

        /**
         * @type {morn.TimeSeries.ValueModifier}
         * @this {morn.TimeSeries}
         */
        var valModifier = function (date, value, rowIndex, colIndex) {

            var columnIndexes = valueIndexes[colIndex],
                prevIdxIdx    = Arrays.indexFloor(rowIndex, columnIndexes),
                nextIdxIdx    = Arrays.indexCeiling(rowIndex, columnIndexes);

            if (   prevIdxIdx >= 0
                && nextIdxIdx >= 0 ) {

                var prevIdx = columnIndexes[prevIdxIdx],
                    nextIdx = columnIndexes[nextIdxIdx],

                    rows    = this._rows,
                    prevRow = rows[prevIdx],
                    nextRow = rows[nextIdx],

                    prevVal = prevRow._vals[colIndex].value(),
                    nextVal = nextRow._vals[colIndex].value(),

                    diffVal = nextVal - prevVal,

                    factor  = ( (useTime === false)
                        ? (rowIndex - prevIdx)       / (nextIdx - prevIdx)
                        : (date     - prevRow._date) / (nextRow._date - prevRow._date) );

                return prevVal + (diffVal * factor);
            }
        };


        // Run it through the replaceNaN logic, effectively replacing
        // new NaN as well as existing NaN values.
        var dpMod = _dataPointModifier( valModifier,
            "interpolation",
            _dataPointFilters.onlyNaNs );

        return _modifyTheseRows(copy, copy, _rowModifier(dpMod), 1);
    },

    /**
     * Copies the values of this TimeSeries forward.
     * @param {(morn.Calendar|lim.IDate[]|morn.TimeSeries|function)} [calendar] - An array of IDate objects,
     *                          a Calendar, a TimeSeries, or a function that receives two IDate arguments
     *                          and returns either a Calendar or array of IDate objects.
     * @param [tag="fill_forward"] {string} Overwrites default tag.
     * @return {morn.TimeSeries}
     */
    fillForward: function (calendar, tag) {

        return _fillTimeSeries(this, arguments, "fill_forward", function (date, val, row, col) {
            return _dataPointAsOfIndex(this, row, col).value();
        }, 1);
    },

    /**
     * Copies the values of this TimeSeries backward.
     * @param {(morn.Calendar|lim.IDate[]|morn.TimeSeries|function)} [calendar] - An array of IDate objects,
     *                          a Calendar, a TimeSeries, or a function that receives two IDate arguments
     *                          and returns either a Calendar or array of IDate objects.
     * @param [tag="fill_backward"] {string} Overwrites default tag.
     * @return {morn.TimeSeries}
     */
    fillBackward: function (calendar, tag) {

        return _fillTimeSeries(this, arguments, "fill_backward", function (date, val, row, col) {
            return _dataPointOfOrNextIndex(this, row, col).value();
        }, -1);
    },

    /**
     * Fills the values with NaN for missing dates.
     * @param {(morn.Calendar|lim.IDate[]|morn.TimeSeries|function)} [calendar] - An array of IDate objects,
     *                          a Calendar, a TimeSeries, or a function that receives two IDate arguments
     *                          and returns either a Calendar or array of IDate objects.
     * @param [tag="fill_nan"] {string} Overwrites default tag.
     * @return {morn.TimeSeries}
     */
    fillNan: function (calendar, tag) {
        if (this.isEmpty()) {
            return this;
        }
        var numArgs = arguments.length,
            dates   = [];

        if (numArgs > 0) {
            dates = _validFillCalendar(arguments[0], this);

        }
        return _insertNans(this, dates);
    },

    /**
     * Creates a new TimeSeries based on this TimeSeries with
     * only the specified rows included.  The rows are included
     * using dates specified by <code>datesToKeep</code> which can be
     * a TimeSeries, an IDate[], an IDate or a String.
     *
     * Optionally, the range around each date can be expanded using
     * <code>numBefore</code> and <code>numAfter</code>.
     *
     * @param {(morn.TimeSeries|lim.IDate[]|lim.IDate|string)} datesToKeep
     * @param {Integer} [numBefore=0] - How many rows from this TimeSeries should
     *                                  be included before each matched date.
     * @param {Integer} [numAfter=0] - How many rows from this TimeSeries should
     *                                  be included after each matched date.
     * @return {morn.TimeSeries}
     */
    keepDates: function (datesToKeep, numBefore, numAfter) {

        var offsetBefore = 0,
            offsetAfter  = 0,
            numArgs      = arguments.length;

        var dates = _toDateArray( datesToKeep,
            "datesToKeep: TimeSeries, IDate[], IDate or String",
            TimeSeries );

        if (numArgs > 1) {
            if (!Numbers.isInteger(numBefore))
                throw new TypeError("numBefore: Integer");

            offsetBefore = numBefore;

            if (numArgs > 2) {
                if (!Numbers.isInteger(numAfter))
                    throw new TypeError("numAfter: Integer");

                offsetAfter = numAfter;
            }
        }

        return _filterDates(this, true, dates, offsetBefore, offsetAfter);
    },



    /**
     * Creates a new TimeSeries based on this TimeSeries with the
     * specified rows excluded from it.  The rows to exclude are
     * specified using <code>datesToDrop</code> which can be a TimeSeries,
     * an IDate[], an IDate or a String.
     *
     * @param datesToDrop {(morn.TimeSeries|lim.IDate[]|lim.IDate|string)}
     * @return {morn.TimeSeries}
     */
    dropDates: function (datesToDrop) {
        return _filterDates( this,
            false,
            _toDateArray( datesToDrop,
                "datesToDrop: TimeSeries, IDate[], IDate or String",
                TimeSeries ),
            0,
            0 );
    },

    /**
     * Reduces all rows of this TimeSeries to a one-dimensional array of numbers
     * representing the sum of all rows.
     * @return {number[]} Sum within each column.  If the
     *                    time series only contains 1 column, this method
     *                    returns an array of one number.
     */
    reduceToSum: function () {
        return _reduceRows(this._rows, this._size, Reducer.SUM);
    },

    /**
     * Reduces all rows of this TimeSeries to a one-dimensional array of numbers
     * representing the count (of numeric values) of all rows.
     * @return {number[]} Count within each column.  If the
     *                    time series only contains 1 column, this method
     *                    returns an array of one number.
     */
    reduceToCount: function () {
        return _reduceRows(this._rows, this._size, Reducer.COUNT);
    },

    /**
     * Reduces all rows of this TimeSeries to a one-dimensional array of numbers
     * representing the average of all rows.
     * @return {number[]} Average within each column.  If the
     *                    time series only contains 1 column, this method
     *                    returns an array of one number.
     */
    reduceToAverage: function () {
        return _reduceRows(this._rows, this._size, Reducer.AVERAGE);
    },

    /**
     * Reduces all rows of this TimeSeries to a one-dimensional array of numbers
     * representing the lowest value within all rows.
     * @return {number[]} Lowest value within each column.  If the
     *                    time series only contains 1 column, this method
     *                    returns an array of one number.
     */
    reduceToMin: function () {
        return _reduceRows(this._rows, this._size, Reducer.MINIMUM);
    },

    /**
     * Reduces all rows of this TimeSeries to a one-dimensional array of numbers
     * representing the highest value within all rows.
     * @return {number[]} Highest value within each column.  If the
     *                    time series only contains 1 column, this method
     *                    returns an array of one number.
     */
    reduceToMax: function () {
        return _reduceRows(this._rows, this._size, Reducer.MAXIMUM);
    },

    /**
     * Reduces all rows of this TimeSeries to a one-dimensional array of numbers.
     * @param reducer {morn.TimeSeries.Reducer}
     * @return {number[]} A computed value for each column.  If the
     *                    time series only contains 1 column, this method
     *                    returns an array of one number.
     */
    reduce: function (reducer) {

        if (!(reducer instanceof Reducer))
            throw new TypeError("reducer: Reducer");

        return _reduceRows(this._rows, this._size, reducer);
    },

    /**
     * <p>
     *     Returns the correlation between matched columns from <i>this</i> and <code>that</code>
     *     TimeSeries.
     * </p>
     * <p>
     *     A note about correlation involving multi-column time-series:
     * </p>
     * <ul>
     *     <li> If only one of the two time-series has multiple columns, a correlation
     *          is calculated for each column against the other time-series' sole column. </li>
     *     <li> If both are multi-column time-series, a correlation is calculated
     *          for each matched column.  Unmatched columns result in a correlation
     *          of <i>0</i> (no correlation).
     * </ul>
     *
     * @param {morn.TimeSeries} that - Another time-series to correlate with.
     * @param {(morn.TimeSeries.CorrelationOptions|Number)} [options]
     *        Correlation options, provided as a single number (aka fill option)
     *        or multiple options wrapped in a CorrelationOptions object.
     * @returns {Number[]} One correlation result per column.  Correlations range between -1 and 1.
     */
    correlation: function (that, options) {

        _validTimeSeries(that, "that");

        var corrOptions;
        if (arguments.length < 2)
            corrOptions = _newCorrelationOptions();

        else if (_isFiniteNumber(options)) {
            corrOptions = _newCorrelationOptions();
            corrOptions.fill = options;
        }
        else
            corrOptions = _validCorrelationOptions(options);

        // Cache first column so we don't extract repeatedly.
        var thisFirstCol = this.extract(0),
            thatFirstCol = that.extract(0);

        var maxCols = Math.max(this._size, that._size),
            corrFn  = _getCorrelationFn(corrOptions),
            corr    = [];

        for (var colIdx = 0; colIdx < maxCols; colIdx++) {

            if (   this._size > 1
                && that._size > 1
                && (   colIdx >= this._size
                    || colIdx >= that._size ) )
                corr.push(0);  // Mismatched column, no correlation

            else {

                var thisColIdx = Math.min(colIdx, this._size - 1),
                    thatColIdx = Math.min(colIdx, that._size - 1);

                var thisCol = ((thisColIdx > 0) ? this.extract(thisColIdx) : thisFirstCol),
                    thatCol = ((thatColIdx > 0) ? that.extract(thatColIdx) : thatFirstCol);

                corr.push(corrFn(_correlationPrep(thisCol, thatCol, corrOptions, false)));
            }
        }

        return corr;
    },

    /**
     * A convenience method to create personalized rolling calculations.
     *
     * @param {(Integer|lim.IDate.Modifier)} sliceSize
     *        Size of each slice to be computed, reduced to a single row.
     *        If an integer, each slice contains <code>sliceSize</code> rows;
     *        <code>sliceSize</code> must be a positive integer.
     *
     *        If an IDate.Modifier, each slice is cut based on a starting date
     *        -- <code>dateAt(0), dateAt(1), dateAt(2)</code>, etc. --
     *        and ends at the date returned by <code>sliceSize(start_date)</code>,
     *        excluding that date from the slice.  Date modifiers must always return
     *        a valid date; backward modifiers are allowed.
     *
     * @param {(Integer|morn.TimeSeries.DateReducer)} dateReducer
     *        Date assigned to each slice.
     *        If an integer, each slice is assigned a date that results from
     *        <code>timeSeriesSlice.dateAt(sliceDate)</code>.
     *
     *        If a TimeSeries.DateReducer, the slice is assigned the date
     *        returned by <code>sliceDate(timeSeriesSlice)</code>.
     *
     *        If the date returned is <code>null</code>, the method skips
     *        over to the next slice.
     *
     * @param {morn.TimeSeries.NumericReducer} numReducer
     *        Calculation that transforms a TimeSeries slice into one or more
     *        number(s).
     *
     *        If <code>numReducer</code> returns null, the method
     *        skips over to the next slice.
     *
     * @param {string} [tag="rolling_calc"]
     *        Tag to be given to each data-point created by this method.
     *
     * @returns {morn.TimeSeries}
     *          The number of columns in the returned TimeSeries depends
     *          on how many number(s) are returned by <code>reducer</code>.
     * @see morn.TimeSeries#dateAt
     */
    rollingCalc: function (sliceSize, dateReducer, numReducer, tag) {

        // validate the arguments

        if (   !(   Numbers.isPositiveInteger(sliceSize)
            && sliceSize <= this.length() )
            && !IDate.isModifier(sliceSize) )
            throw new TypeError("sliceSize: IDate.Modifier or Integer (positive, <= length())");

        var dateReducerFn;
        if (Numbers.isInteger(dateReducer))
            dateReducerFn = _newIndexBasedDateReducer(dateReducer);

        else if (typeof dateReducer === "function")
            dateReducerFn = dateReducer;  // We can't validate it.  If invalid, it'll still fail fast.

        else
            throw new TypeError("dateReducer: Integer or TimeSeries.DateReducer (function)");

        if (typeof numReducer !== "function")  // Like dateReducer, if invalid it'll still fail fast, just not right now.
            throw new TypeError("numReducer: TimeSeries.NumericReducer (function)");

        if (arguments.length < 4)
            tag = "rolling_calc";
        else if (typeof tag !== "string")
            throw new TypeError("tag: String");

        return _rollingCalc(this, sliceSize, dateReducerFn, numReducer, tag);
    },


    /**
     * Rounds all numbers within the TimeSeries to a given
     * number of decimals.  If <code>numDecimals</code> is negative,
     * this method rounds to tens (-1), hundreds (-2), thousands (-3), etc.
     *
     * The round method used is the most commonly used in mathematics:
     * half-down and half-up, with middle rounded up.
     *
     * Unlike MS-Excel, negative values are rounded in the same direction
     * as positive values.  Ex.: round(-2.5) -> -2.0
     *
     * @param numDecimals {integer}
     * @param [tag] {string} Overwrites default tag.
     * @return {morn.TimeSeries}
     */
    round: function (numDecimals, tag) {
        return _roundTimeSeries(this, arguments, 'round', 'round');
    },

    /**
     * Rounds all numbers within the TimeSeries to a given
     * number of decimals.  If <code>numDecimals</code> is negative,
     * this method rounds to tens (-1), hundreds (-2), thousands (-3), etc.
     *
     * The round method used is <em>round up</em>:
     * any remaining fraction is rounded up.
     *
     * Equivalent to MS-Excel ROUNDUP(). Ex.: roundUp(-2.5) -> -3.0
     *
     * @param numDecimals {integer}
     * @param [tag] {string} Overwrites default tag.
     * @return {morn.TimeSeries}
     */
    roundUp: function (numDecimals, tag) {
        return _roundTimeSeries(this, arguments, 'round_up', 'roundUp');
    },

    /**
     * Rounds all numbers within the TimeSeries to a given
     * number of decimals.  If <code>numDecimals</code> is negative,
     * this method rounds to tens (-1), hundreds (-2), thousands (-3), etc.
     *
     * The round method used is <em>round down</em>:
     * any remaining fraction is rounded down.
     *
     * Equivalent to MS-Excel ROUNDDOWN(). Ex.: roundDown(-2.5) -> -2.0
     *
     * @param numDecimals {integer}
     * @param [tag] {string} Overwrites default tag.
     * @return {morn.TimeSeries}
     */
    roundDown: function (numDecimals, tag) {
        return _roundTimeSeries(this, arguments, 'round_down', 'roundDown');
    },


    /**
     * Rounds all numbers within the TimeSeries to a given
     * number of decimals.  If <code>numDecimals</code> is negative,
     * this method rounds to tens (-1), hundreds (-2), thousands (-3), etc.
     *
     * The round method used is <em>round up</em>:
     * any remaining fraction is rounded up.
     *
     * Equivalent to MS-Excel CEILING(). Ex.: ceil(-2.5) -> -2.0
     *
     * @param numDecimals {integer}
     * @param [tag] {string} Overwrites default tag.
     * @return {morn.TimeSeries}
     */
    ceil: function (numDecimals, tag) {
        return _roundTimeSeries(this, arguments, 'ceil', 'ceil');
    },

    /**
     * Rounds all numbers within the TimeSeries to a given
     * number of decimals.  If <code>numDecimals</code> is negative,
     * this method rounds to tens (-1), hundreds (-2), thousands (-3), etc.
     *
     * The round method used is <em>round down</em>:
     * any remaining fraction is rounded down.
     *
     * Equivalent to MS-Excel FLOOR(). Ex.: floor(-2.5) -> -3.0
     *
     * @param numDecimals {integer}
     * @param [tag] {string} Overwrites default tag.
     * @return {morn.TimeSeries}
     */
    floor: function (numDecimals, tag) {
        return _roundTimeSeries(this, arguments, 'floor', 'floor');
    },


    /**
     * Creates a new TimeSeries by computing an average
     * for each group of rows.  The groups are determined
     * by <code>groupingMethod</code>.
     *
     * @param groupingMethod {morn.TimeSeries.DateGroupingMethod}
     *                       See {@link morn.TimeSeries.DateGroupingMethods} for pre-defined grouping methods.
     * @return {morn.TimeSeries}
     */
    groupByAverage: function (groupingMethod) {

        return _groupBy ( this,
            _validGroupingMethod(groupingMethod),
            Reducer.AVERAGE,
            "group_average" );

    },

    /**
     * Creates a new TimeSeries by computing a count
     * for each group of rows, determined by <code>groupingMethod</code>.
     *
     * @param groupingMethod {morn.TimeSeries.DateGroupingMethod}
     *                       See {@link morn.TimeSeries.DateGroupingMethods} for pre-defined grouping methods.
     * @return {morn.TimeSeries}
     */
    groupByCount: function (groupingMethod) {

        return _groupBy ( this,
            _validGroupingMethod(groupingMethod),
            Reducer.COUNT,
            "group_count" );

    },

    /**
     * Creates a new TimeSeries by computing a sum
     * for each group of rows, determined by <code>groupingMethod</code>.
     *
     * @param groupingMethod {morn.TimeSeries.DateGroupingMethod}
     *                       See {@link morn.TimeSeries.DateGroupingMethods} for pre-defined grouping methods.
     * @return {morn.TimeSeries}
     */
    groupBySum: function (groupingMethod) {

        return _groupBy ( this,
            _validGroupingMethod(groupingMethod),
            Reducer.SUM,
            "group_sum" );

    },

    /**
     * Creates a new TimeSeries by computing a minimum
     * for each group of rows, determined by <code>groupingMethod</code>.
     *
     * @param groupingMethod {morn.TimeSeries.DateGroupingMethod}
     *                       See {@link morn.TimeSeries.DateGroupingMethods} for pre-defined grouping methods.
     * @return {morn.TimeSeries}
     */
    groupByMin: function (groupingMethod) {

        return _groupBy ( this,
            _validGroupingMethod(groupingMethod),
            Reducer.MINIMUM,
            "group_min" );

    },

    /**
     * Creates a new TimeSeries by computing a maximum
     * for each group of rows, determined by <code>groupingMethod</code>.
     *
     * @param groupingMethod {morn.TimeSeries.DateGroupingMethod}
     *                       See {@link morn.TimeSeries.DateGroupingMethods} for pre-defined grouping methods.
     * @return {morn.TimeSeries}
     */
    groupByMax: function (groupingMethod) {

        return _groupBy ( this,
            _validGroupingMethod(groupingMethod),
            Reducer.MAXIMUM,
            "group_max" );

    },

    /**
     * Creates a new TimeSeries by grouping rows together
     * - as per <code>groupingMethod</code>.  This method
     * computes a value for each column within each group,
     * using <code>reducer</code>.
     *
     * @param groupingMethod {morn.TimeSeries.DateGroupingMethod}
     *                       See {@link morn.TimeSeries.DateGroupingMethods} for pre-defined grouping methods.
     * @param reducer {morn.TimeSeries.Reducer}
     * @param [tag="group_by"] {string}
     * @return {morn.TimeSeries}
     */
    groupBy: function (groupingMethod, reducer, tag) {

        _validGroupingMethod(groupingMethod);

        if (!(reducer instanceof Reducer))
            throw new TypeError("reducer: Reducer");

        if (arguments.length < 3)
            tag = "group_by";
        else if (!Strings.isNonEmpty(tag))
            throw new TypeError("tag: String, non-empty");

        return _groupBy (this, groupingMethod, reducer, tag);
    },

    /**
     * Creates a new TimeSeries by computing hourly averages
     * from the data within <em>this</em>.
     *
     * @param [isHourEnding=false] {boolean} Whether to use hour-ending logic or not.
     *                            If <em>true</em>,
     *                            {@link morn.TimeSeries.DateGroupingMethods.HOUR_ENDING_ON_NEXT|HOUR_ENDING_ON_NEXT}
     *                            is used.
     * @return {morn.TimeSeries}
     */
    hourlyAverage: function (isHourEnding) {
        return _groupBy ( this,
            _getGroupByHour(arguments),
            Reducer.AVERAGE,
            "hourly_average" );
    },

    /**
     * Creates a new TimeSeries by computing hourly counts
     * (of numeric data-points) from the data within <em>this</em>.
     *
     * @param [isHourEnding=false] {boolean} Whether to use hour-ending logic or not.
     *                            If <em>true</em>,
     *                            {@link morn.TimeSeries.DateGroupingMethods.HOUR_ENDING_ON_NEXT|HOUR_ENDING_ON_NEXT}
     *                            is used.
     * @return {morn.TimeSeries}
     */
    hourlyCount: function (isHourEnding) {
        return _groupBy ( this,
            _getGroupByHour(arguments),
            Reducer.COUNT,
            "hourly_count" );
    },

    /**
     * Creates a new TimeSeries by computing daily averages
     * from the data within <em>this</em>.
     *
     * @param [isDayEnding=false] {boolean} Whether to use day-ending logic or not.
     *                            If <em>true</em>,
     *                            {@link morn.TimeSeries.DateGroupingMethods.DAY_ENDING_ON_PREVIOUS|DAY_ENDING_ON_PREVIOUS}
     *                            is used.
     * @return {morn.TimeSeries}
     */
    dailyAverage: function (isDayEnding) {
        return _groupBy ( this,
            _getGroupByDay(arguments),
            Reducer.AVERAGE,
            "daily_average" );
    },

    /**
     * Creates a new TimeSeries by computing daily counts
     * (of numeric data-points) from the data within <em>this</em>.
     *
     * @param [isDayEnding=false] {boolean} Whether to use day-ending logic or not.
     *                            If <em>true</em>,
     *                            {@link morn.TimeSeries.DateGroupingMethods.DAY_ENDING_ON_PREVIOUS|DAY_ENDING_ON_PREVIOUS}
     *                            is used.
     * @return {morn.TimeSeries}
     */
    dailyCount: function (isDayEnding) {
        return _groupBy ( this,
            _getGroupByDay(arguments),
            Reducer.COUNT,
            "daily_count" );
    },

    /**
     * Creates a new TimeSeries by computing monthly averages
     * from the data within <em>this</em>.
     *
     * @param [isMonthEnding=false] {boolean} Whether to use month-ending logic or not.
     *                            If <em>true</em>,
     *                            {@link morn.TimeSeries.DateGroupingMethods.MONTH_ENDING_ON_PREVIOUS|MONTH_ENDING_ON_PREVIOUS}
     *                            is used.
     * @return {morn.TimeSeries}
     */
    monthlyAverage: function (isMonthEnding) {
        return _groupBy ( this,
            _getGroupByMonth(arguments),
            Reducer.AVERAGE,
            "monthly_average" );
    },

    /**
     * Creates a new TimeSeries by computing monthly counts
     * (of numeric data-points) from the data within <em>this</em>.
     *
     * @param [isMonthEnding=false] {boolean} Whether to use month-ending logic or not.
     *                            If <em>true</em>,
     *                            {@link morn.TimeSeries.DateGroupingMethods.MONTH_ENDING_ON_PREVIOUS|MONTH_ENDING_ON_PREVIOUS}
     *                            is used.
     * @return {morn.TimeSeries}
     */
    monthlyCount: function (isMonthEnding) {
        return _groupBy ( this,
            _getGroupByMonth(arguments),
            Reducer.COUNT,
            "monthly_count" );
    },

    /**
     * Shapes, or adjusts all data-points within this TimeSeries.
     * The adjustment can be relative or absolute.
     * Optionally, <code>tag</code> can be provided to overwrite the default
     * one.
     *
     * @param adj {(string|number)} For relative adjustments, use a String
     *            argument ("+10%", "+1.3", "-2.5", etc.)  For absolute adjustments,
     *            simply provide the number to use, as a Number.
     * @param [tag] {string} Overwrites default tag.
     * @return {morn.TimeSeries}
     */
    shapeAll: function (adj, tag) {

        var valAdj = _basicValAdjuster(adj);

        tag = _validTag(arguments, 1, adj);

        var dpMod = _filteredValueModifier(_dateFilterAll, valAdj, tag);

        return _modifyRows(this, _rowModifier(dpMod));
    },

    /**
     * Shapes, or adjusts a period within this TimeSeries.
     * The adjustment can be relative or absolute.  The period is defined
     * by <code>start</code> and <code>end</code>; both are inclusive.
     * Optionally, <code>tag</code> can be provided to overwrite the default
     * one.
     *
     * @param adj {(string|number)} For relative adjustments, use a String
     *            argument ("+10%", "+1.3", "-2.5", etc.)  For absolute adjustments,
     *            simply provide the number to use, as a Number.
     * @param start {(lim.IDate|string)} The start of the period to shape (inclusive.)
     * @param end {(lim.IDate|string)} The end of the period to shape (inclusive.)
     * @param [tag] {string} Overwrites default tag.
     * @return {morn.TimeSeries}
     */
    shapePeriod: function (adj, start, end, tag) {

        var valAdj = _basicValAdjuster(adj);

        start = _validDate(start);
        end   = _validDate(end);
        tag   = _validTag( arguments,
            3,
            adj + "(" + _dateTag(start)
            + "," + _dateTag(end) + ")" );

        var dateFilter = _dateFilterByPeriod(start, end),
            dpMod      = _filteredValueModifier(dateFilter, valAdj, tag);

        return _modifyRows(this, _rowModifier(dpMod));

    },

    /**
     * Shapes, or adjusts data-points that pertain to the specified month(s)
     * within this TimeSeries.
     * The adjustment can be relative or absolute.  The months are specified
     * as an array of Integer (0-11).
     * Optionally, <code>tag</code> can be provided to overwrite the default
     * one.
     *
     * @param adj {(string|number)} For relative adjustments, use a String
     *            argument ("+10%", "+1.3", "-2.5", etc.)  For absolute adjustments,
     *            simply provide the number to use, as a Number.
     * @param months {integer[]} 0=Jan, 1=Feb, ..., 11=Dec.
     * @param [tag] {string} Overwrites default tag.
     * @return {morn.TimeSeries}
     */
    shapeMonths: function (adj, months, tag) {

        var valAdj     = _basicValAdjuster(adj),
            dateFilter = _dateFilterByMonths(months),
            moAbbrv    = [];

        for (var i = 0, len = months.length; i < len; i++)
            moAbbrv.push(IDate.monthAbbrev(months[i]));

        tag = _validTag(arguments, 2, adj + "(" + moAbbrv.join(',') + ")");

        var dpMod = _filteredValueModifier(dateFilter, valAdj, tag);

        return _modifyRows(this, _rowModifier(dpMod));
    },

    /**
     * Shapes, or adjusts a particular data-point within this TimeSeries.
     * The adjustment can be relative or absolute.  The data-point is defined
     * by <code>date</code>.
     * Optionally, <code>tag</code> can be provided to overwrite the default
     * one.
     *
     * @param adj {(string|number)} For relative adjustments, use a String
     *            argument ("+10%", "+1.3", "-2.5", etc.)  For absolute adjustments,
     *            simply provide the number to use, as a Number.
     * @param date {(lim.IDate|string)} The date of the data-point to adjust.
     * @param [tag] {string} Overwrites default tag.
     * @return {morn.TimeSeries}
     */
    shapePoint: function (adj, date, tag) {

        var valAdj = _basicValAdjuster(adj);

        date = _validDate(date);

        var dateFilter = _dateFilterByDate(date);

        tag = _validTag(arguments, 2, adj + "(" + _dateTag(date) + ")");

        var dpMod = _filteredValueModifier(dateFilter, valAdj, tag);

        return _modifyRows(this, _rowModifier(dpMod));
    },


    /**
     * Computes the standard moving average within each column.
     * @param {Integer} numPoints
     * @return {morn.TimeSeries}
     */
    standardMovingAverage: function (numPoints) {

        if (!Numbers.isPositiveInteger(numPoints)) {
            throw new TypeError("numPoints: Integer, positive");
        }

        var rows       = this._rows,
            numRows    = rows.length,
            numCols    = this._size,
            clone      = _newTs(this),
            tag        = "sma(" + numPoints.toFixed(0) + ")",
            running    = [],
            sma        = null,
            row        = null,
            dataPoints = null,
            dataPoint  = null;

        // Initialize a StandardMovingAverage object for each column.
        for (var j = 0; j < numCols; j++) {
            running.push(new StandardMovingAverage(numPoints));
        }

        // Loop through rows, creating a new TimeSeries.
        for (var i = 0; i < numRows; i++) {
            row        = rows[i];
            dataPoints = [];

            for (var j = 0; j < numCols; j++) {
                dataPoint = row._vals[j];
                sma       = running[j];

                sma.apply(dataPoint._val);
                dataPoints.push(dataPoint.mutate(sma.calc(), tag));
            }
            _addOneRow(clone, new Row(row._date, dataPoints));
        }
        return clone.freeze();
    },

    /**
     * <p>
     *  Computes the <em>change</em> over <code>numPoints</code>,
     *  for each column, and returns a new TimeSeries containing
     *  the computed values.  <em>Change</em> is calculated by
     *  subtracting the price from <code>numPoints</code> ago to
     *  the current price, as in:
     * </p>
     *
     * <p>
     *     <code>CURRENT_PRICE - PREV_PRICE</code>
     * </p>
     *
     * @param {Integer} [numPoints=1]
     * @return {morn.TimeSeries}
     */
    change: function (numPoints) {
        return _change(this, arguments, "chg_", _chg);
    },

    /**
     * <p>
     *  Computes the <em>percent change</em> over <code>numPoints</code>,
     *  for each column, and returns a new TimeSeries containing
     *  the computed values.  <em>Percent change</em> - or %chg -
     *  is calculated by subtracting the price from <code>numPoints</code>
     *  ago to the current price, dividing that difference by that same
     *  previous price, as in:
     * </p>
     *
     * <p>
     *     <code>( (CURRENT_PRICE - PREV_PRICE) / abs(PREV_PRICE) ) * 100</code>
     * </p>
     *
     * @param {Integer} [numPoints=1]
     * @return {morn.TimeSeries}
     */
    changePercent: function (numPoints) {
        return _change(this, arguments, "chg_percent_", _chgPerc);
    },

    /**
     * <p>
     *  Computes the <em>percent change</em> over <code>numPoints</code>,
     *  for each column, and returns a new TimeSeries containing
     *  the computed values.  <em>Percent change</em> - or %chg -
     *  is calculated by subtracting the price from <code>numPoints</code>
     *  ago to the current price, dividing that difference by that same
     *  previous price, as in:
     * </p>
     *
     * <p>
     *     <code>( (CURRENT_PRICE - PREV_PRICE) / PREV_PRICE ) * 100</code>
     * </p>
     *
     * @param {Integer} [numPoints=1]
     * @return {morn.TimeSeries}
     */
    changePercentRaw: function (numPoints) {
        return _change(this, arguments, "chg_percent_raw_", _chgPercRaw);
    },

    /**
     * <p>
     *  Computes the <em>relative change</em> over <code>numPoints</code>,
     *  for each column, and returns a new TimeSeries containing
     *  the computed values.  <em>Relative change</em> - or %chg / 100 -
     *  is calculated by subtracting the price from <code>numPoints</code>
     *  ago to the current price, dividing that difference by that same
     *  previous price, as in:
     * </p>
     *
     * <p>
     *     <code>(CURRENT_PRICE - PREV_PRICE) / abs(PREV_PRICE)</code>
     * </p>
     *
     * @param {Integer} [numPoints=1]
     * @return {morn.TimeSeries}
     */
    changeRatio: function (numPoints) {
        return _change(this, arguments, "chg_ratio_", _chgRatio);
    },

    /**
     * <p>
     *  Computes the <em>relative change</em> over <code>numPoints</code>,
     *  for each column, and returns a new TimeSeries containing
     *  the computed values.  <em>Relative change</em> - or %chg / 100 -
     *  is calculated by subtracting the price from <code>numPoints</code>
     *  ago to the current price, dividing that difference by that same
     *  previous price, as in:
     * </p>
     *
     * <p>
     *     <code>(CURRENT_PRICE - PREV_PRICE) / PREV_PRICE</code>
     * </p>
     *
     * @param {Integer} [numPoints=1]
     * @return {morn.TimeSeries}
     */
    changeRatioRaw: function (numPoints) {
        return _change(this, arguments, "chg_ratio_raw_", _chgRatioRaw);
    },

    /**
     * <p>
     *  Computes the <em>return</em> over <code>numPoints</code>,
     *  for each column, and returns a new TimeSeries containing
     *  the computed values.  <em>Return</em> is calculated as
     *  the natural logarithmic of the current price over the previous
     *  price (from <code>numPoints</code> ago), as in:
     * </p>
     *
     * <p>
     *     <code>Math.log(CURRENT_PRICE / PREV_PRICE)</code>
     * </p>
     *
     * @param {Integer} [numPoints=1]
     * @return {morn.TimeSeries}
     */
    changeReturn: function (numPoints) {
        return _change(this, arguments, "return_", _chgLN);
    },

    /**
     * Returns rows where the first column from this TimeSeries
     * crosses over or under the 2nd column.  Optionally,
     * <code>numBefore</code> and <code>numAfter</code> can be
     * used to expand the selection around rows that apply.
     *
     * @param [numBefore=0] {integer}
     * @param [numAfter] {integer} Default is <code>numBefore</code>
     *                 if provided, otherwise default is <em>0</em>.
     * @return {morn.TimeSeries}
     */
    crossing: function (numBefore, numAfter) {
        return _crossing(this, 0, arguments);
    },

    /**
     * Returns rows where the first column from this TimeSeries
     * crosses over the 2nd column.  Optionally,
     * <code>numBefore</code> and <code>numAfter</code> can be
     * used to expand the selection around rows that apply.
     *
     * @param [numBefore=0] {integer}
     * @param [numAfter] {integer} Default is <code>numBefore</code>
     *                 if provided, otherwise default is <em>0</em>.
     * @return {morn.TimeSeries}
     */
    crossingOver: function (numBefore, numAfter) {
        return _crossing(this, 1, arguments);
    },

    /**
     * Returns rows where the first column from this TimeSeries
     * crosses under the 2nd column.  Optionally,
     * <code>numBefore</code> and <code>numAfter</code> can be
     * used to expand the selection around rows that apply.
     *
     * @param [numBefore=0] {integer}
     * @param [numAfter] {integer} Default is <code>numBefore</code>
     *                 if provided, otherwise default is <em>0</em>.
     * @return {morn.TimeSeries}
     */
    crossingUnder: function (numBefore, numAfter) {
        return _crossing(this, -1, arguments);
    },

    /**
     * Returns a CSV representation of this time series.
     *
     * For backward compatibility only.
     *
     * This method only exists to replicate the original output format
     * for forward curve computation, which was made of 4 columns:
     *
     * column[0]: date, yyyy-MM-ddTHH:mm:ss.SSS
     * column[1]: number, derived value
     * column[2]: string, how derived value was calculated
     * column[3]: number, underlying contract value
     *
     * This method works with TimeSeries instances of 1 column;
     * it throws otherwise!
     *
     * @return {string}
     * @throws IllegalStateException if this instance has more than one column.
     */
    toCsvWithSource: function () {

        var numCols = this._size;
        if (numCols > 1)
            throw new Error("IllegalStateException: too many columns");

        var rows      = this._rows,
            dates     = _getFormattedDates(_getDates(this)),
            delimFile = new DelimFile();

        for (var i = 0, len = rows.length; i < len; i++) {

            var row = rows[i],
                derived = row._vals[0],
                src     = derived;

            while (src._src.length > 0)
                src = src._src[0];

            delimFile.addRow(
                dates[i],
                derived.value(),
                derived.mutation(),
                src.value()
            );
        }

        return delimFile.toCsv();
    },


    /**
     * Returns a CSV representation of this time series.
     *
     * For backward compatibility only.
     *
     * This method only exists to replicate the original output format
     * for forward curve computation, which was made of 4 columns:
     *
     * column[0]: date, yyyy-MM-ddTHH:mm:ss.SSS
     * column[1]: number, derived value
     * column[2]: string, how derived value was calculated
     * column[3]: number, underlying contract value
     *
     * This method works with TimeSeries instances of 1 column;
     * it throws otherwise!
     *
     * @return {string}
     * @throws IllegalStateException if this instance has more than one column.
     */
    calcForwardCurve: function () {
        return this.toCsvWithSource();
    },

    /**
     * Returns a CSV representation of this TimeSeries, in which
     * each row starts with an ISO formatted date (yyyy-MM-ddTHH:mm:ss.SSS)
     * followed one column for each numeric values of each column.
     *
     * If <code>includeTrace</code> is true, the columns after the date
     * alternate between numeric value value and data-point <em>trace</em>.
     *
     * For example, given a TimeSeries with 3 columns, the output of the
     * function called without argument (or <em>false</em> argument) will be:
     *
     * column[0]: date, yyyy-MM-ddTHH:mm:ss.SSS
     * column[1]: number, column #1
     * column[2]: number, column #2
     * column[3]: number, column #3
     *
     * The same TimeSeries would result in the following output if this
     * method was called with a <em>true</em> argument:
     *
     * column[0]: date, yyyy-MM-ddTHH:mm:ss.SSS
     * column[1]: number, column #1
     * column[2]: string, column #1 mutation history (or trace)
     * column[3]: number, column #2
     * column[4]: string, column #2 mutation history (or trace)
     * column[5]: number, column #3
     * column[6]: string, column #3 mutation history (or trace)
     *
     * @param [includeTrace=false] {boolean}
     * @return {string}
     */
    toCsv: function (includeTrace) {

        var delimFile = new DelimFile();

        _toCsv(this, delimFile, (includeTrace === true), Arrays.EMPTY);

        return delimFile.toCsv();
    },


    /**
     * Returns a stringified JSON object representing
     * this TimeSeries.  The content of the TimeSeries
     * is represented as an array of row objects, where each
     * row has a (stringified) date property and a values
     * array, which in turn lists the values and their trace.
     *
     * Sample output for a 2-column TimeSeries (beautified for clarity):
     *
     * [{
     *     "date": "2014-08-01T00:00:00.000",
     *     "values": [{
     *         "value": 17.755,
     *         "trace": "G0BM;Aug14"
     *     }, {
     *         "value": "NaN",
     *         "trace": "NaN"
     *     }]
     * }, {
     *     "date": "2014-09-01T00:00:00.000",
     *     "values": [{
     *         "value": 18.192,
     *         "trace": "G0BM;Sep14"
     *     }, {
     *         "value": "NaN",
     *         "trace": "NaN"
     *     }]
     * }, {
     *     "date": "2014-10-01T00:00:00.000",
     *     "values": [{
     *         "value": 21.277,
     *         "trace": "G0BM;Oct14"
     *     }, {
     *         "value": "NaN",
     *         "trace": "NaN"
     *     }]
     * }]
     *
     * @return {string}
     */
    toJsonString: function () {

        var rows      = this._rows,
            numRows   = rows.length,
            numCols   = this._size,
            dates     = _getFormattedDates(_getDates(this)),
            res       = [];

        for (var i = 0; i < numRows; i++) {

            var row    = rows[i],
                values = [],
                rowOut = {
                    date:   dates[i],
                    values: values
                };

            for (var j = 0; j < numCols; j++) {
                var dataPoint = row._vals[j];
                values.push({
                    value: Numbers.toString(dataPoint.value()),
                    trace: dataPoint.mutation()
                });
            }

            res.push(rowOut);
        }

        return JSON.stringify(res);
    },

    /** @returns {string} CSV representation of this time-series, for debugging purposes. */
    toString: function () {
        return this.toCsv(false);
    },

    /**
     * A data row in which the first item (i.e. 0) is
     * a date and other items (1 and above) are numbers.
     *
     * @typedef {Array} morn.TimeSeries.RowArray
     * @property {lim.IDate} 0 - Date for that row.
     * @property {Number} 1 - Numeric value, one per column found in origin TimeSeries,
     *                        starting at index <i>1</i>.
     */

    /**
     * Returns a two-dimensional array of the values contained in
     * this TimeSeries.
     * @param {boolean} [includeDate=false] Whether to include each row's date as
     *                  the first item in each sub-array.
     * @returns {(Number[][]|morn.TimeSeries.RowArray[])}
     */
    values: function (includeDate) {

        var isDates = _validBoolIfAvail(arguments, 0, "includeDate", false);

        return _.map(this._rows, function (row) {

            var vals = row.values();

            if (isDates === true)
                vals.unshift(row.date());

            return vals;
        });
    },

    /**
     * Returns vector(s) that represent the values contained in
     * each column(s).
     *
     * If only one column index is provided, the returned value is
     * a single vector (aka 1-dimensional array).  Otherwise,
     * if no column index or multiple column indices are given,
     * this method returns an array of vectors.
     * @param {...Integer} colIndices
     * @returns {(Number[]|Array.<Number[]>)} One vector or a list of vectors.
     */
    columns: function (colIndices) {

        var indices = _validColIndices(arguments, this, true),
            numCols = indices.length,
            vectors = Arrays.newInstance(numCols, function () {
                return [];
            });

        _.each(this._rows, function (row) {

            for (var i = 0; i < numCols; i++)
                vectors[i].push(row._vals[indices[i]]._val);

        });

        // If one-and-only-one column index as provided,
        // return a single vector.
        if (arguments.length === 1)
            return vectors[0];
        else
            return vectors;  // Otherwise return a list of vectors, possibly of one vector.
    }
};

/* *************************************************
  * PUBLIC STATIC METHODS
  * ************************************************* */

_.extend(TimeSeries, /** @lends morn.TimeSeries */ {

    /**
     * Validates <code>arg</code> to be a TimeSeries, throws otherwise.
     * @param {morn.TimeSeries} arg Argument to validate.
     * @param {string} argName Name given to <code>arg</code> for when error must be thrown.
     * @returns {morn.TimeSeries} Always returns <code>arg</code>.
     * @throws {TypeError} If <code>arg</code> is not a TimeSeries object.
     */
    requireTimeSeries: function (arg, argName) {
        if (!(arg instanceof TimeSeries)) {
            throw new TypeError(argName + ": TimeSeries");
        }
        return arg;
    },

    /**
     * Gets or sets the data-point limit; the limit at which TimeSeries fail to be
     * created due to too many data-points.
     * @param {int} [limit]
     * @returns {(int|Function)} Effective limit, or \`this\`.
     */
    dataPointLimit: function (limit) {
        if (arguments.length < 1) {
            return _dataPointMax;
        } else {
            if (!Numbers.isNonNegativeInteger(limit)) {
                throw new TypeError("limit: Integer, non-negative");
            }
            if (!Parameters.getBool(ALLOW_DATA_POINT_LIMIT_CHANGE, false)) {
                throw new Error("Cannot change data-point limit, access denied");
            }

            _dataPointMax = limit;
            return TimeSeries;
        }
    },

    /**
     * Returns an empty, immutable TimeSeries with
     * the specified number of columns.
     * @method
     * @param size {integer} positive.
     * @return {morn.TimeSeries}
     */
    empty: _getEmpty,

    /**
     * <p>
     *  Creates a new TimeSeries based on provided data.
     *  The <code>rows</code> argument must be a 2D array
     *  that represent rows of the time-series, where
     *  each row starts with a date - String or IDate -
     *  followed by numeric values (can be their string
     *  representation.)  If some rows are smaller than
     *  others, <em>NaN</em> values are added so that all
     *  rows have the same size.
     * </p>
     *
     * <p>
     *  Values <code>"NaN"</code>,
     *  <code>"null"</code>, <code>null</code> and
     *  <em>undefined</em> are treated as <em>NaN</em>.
     * </p>
     *
     * @param rows {Array[]} A 2D array containing one date and
     *                       (at least) one value per row.
     * @param [tag=hard-coded] {string}
     * @return {morn.TimeSeries}
     *
     * @example TimeSeries.create([["10/20/2014", 50]])
     *      => A TimeSeries of 1 row, 1 column.
     * @example TimeSeries.create([["10/20/2014", "45.12"], ["10/26/2014", "51.97", null]])
     *      => A TimeSeries of 2 rows, 2 columns.
     */
    create: function (rows, tag) {

        if (!(rows instanceof Array))
            throw new TypeError("rows: Array");

        if (arguments.length < 2)
            tag = "hard-coded";

        else if (typeof tag !== 'string')
            throw new TypeError("tag: String");

        else
            tag = Strings.trim(tag);


        var numRows      = rows.length,
            numCols      = 0,
            parsedValues = [],
            rowIdx,
            colIdx = 0;

        for (rowIdx = 0; rowIdx < numRows; rowIdx++) {

            var row = rows[rowIdx];

            if (!(row instanceof Array))
                throw new TypeError(Strings.build("rows[{}]: Array", rowIdx));

            var rowLen = row.length;

            if (rowLen > 0) {

                try {

                    colIdx = 0;

                    var values = [],
                        date;

                    try {
                        date = _validDate(row[0]);
                    }
                    catch (ex) {
                        throw Strings.build("invalid date \\"{}\\"", row[0]);
                    }

                    for (colIdx = 1; colIdx < rowLen; colIdx++) {

                        var rawVal = row[colIdx],
                            typeOf = typeof rawVal,
                            numVal = Number.NaN;

                        if (typeOf === 'number')
                            numVal = rawVal;

                        else if (Numbers.isFloatString(rawVal))
                            numVal = parseFloat(rawVal);

                        else if (typeOf === 'string') {

                            var normalized = Strings.trim(rawVal).toLowerCase();

                            if (   normalized === 'infinity'
                                || normalized === '+infinity' )
                                numVal = Number.POSITIVE_INFINITY;

                            else if (normalized === '-infinity')
                                numVal = Number.NEGATIVE_INFINITY;

                            else if (   normalized !== 'nan'
                                && normalized !== 'null'
                                && normalized !== '' )
                                throw Strings.build("invalid value \\"{}\\"", rawVal);

                            // else -> \`numVal\` is already set to NaN
                        }

                        else if (!Utils.isVoid(rawVal))
                            throw Strings.build("must be Number or String");

                        values.push(numVal);
                    }

                    numCols = Math.max(numCols, rowLen - 1);

                    parsedValues.push({
                        date: date,
                        values: values
                    });
                }
                catch (ex) {
                    throw new Error(Strings.build("at rows[{}][{}]: {}", rowIdx, colIdx, ex));
                }
            }
        }

        numRows = parsedValues.length;

        if (numRows === 0 || numCols === 0) {
            throw new Error("\`rows\` contains no valid row");
        }

        /** @type {morn.TimeSeries.Row[]} */
        var rowsToInsert = [];
        for (var i = 0; i < numRows; i++) {
            var row        = parsedValues[i],
                values     = row.values,
                dataPoints = [];

            for (var j = 0; j < numCols; j++) {
                var val = values[j];
                if (typeof val !== 'number') {
                    val = Number.NaN;
                }
                dataPoints.push(new DataPoint(val, tag));
            }
            rowsToInsert.push(new Row(row.date, dataPoints));
        }

        var ts = new TimeSeries(numCols);
        _addRowsUnsorted(ts, rowsToInsert);
        return ts.freeze();
    },

    /**
     * <p>
     *  Saves this time-series into the given feed.
     *  Once saved, this time-series can be re-created using
     *  {@link time_series}.
     * </p>
     *
     * <p>
     *  Arguments feed, keyValues and columns
     *  can be collapsed into one {@link morn.Product|Product} argument.
     *  When invoked in this manner, this method requires 2 arguments
     *  only (3 at most).
     * </p>
     *
     * @param {morn.TimeSeries} timeSeries
     * @param {string} feed - The feed to save to.
     * @param {Object} keyValues - The keys and values for these numbers.
     * @param {(string|string[])} columns - A name for each of the column.
     *                  Multiple column names can still be specified
     *                  using a single string value, using commas to separate
     *                  the columns.
     * @param {boolean} [partial_updates=true] - Save using partial_updates or not.
     *                          If not set, it will default to the feed's default behavior.
     * @return {boolean} Returns <em>true</em> most of the time.  Returns
     *                   <em>false</em> if optimization determined that no
     *                   new data is being saved.
     */
    save: function (timeSeries, feed, keyValues, columns, partial_updates) {
        return _fwdToInstance('save', arguments);
    },

    /**
     * <p>
     *  Saves each row of the given TimeSeries as a separate futures contract,
     *  into the given feed.  Once saved, the <em>curve</em> can be re-created
     *  using {@link forward_curve}.
     * </p>
     *
     * <p>
     *  This method validates that there are no gaps in <code>timeSeries</code>.
     * </p>
     *
     * <p>
     *  Arguments <code>feed</code>, <code>root</code> and <code>columns</code>
     *  can be collapsed into one {@link morn.Product|Product} argument.
     *  When invoked in this manner, this method requires 4 argument
     *  only (5 at most).
     * </p>
     *
     * @param {morn.TimeSeries} timeSeries
     * @param {string} feed - The feed to save to.
     * @param {string} root - The root symbol under which to save.
     * @param {(string|string[])} columns - A name for each of the column.
     *          Multiple column names can still be specified
     *          using a single string value, using commas to separate
     *          the columns.
     * @param {(string|lim.IDate)} curveDate - If <code>curveDate</code> is
     *          only a date - without time - make sure to pass
     *          a string value to avoid time zone offsets.  For
     *          strings, formats "yyyy-MM-dd" and "M/d/yyyy" are
     *          supported.
     * @param {(string|morn.DeliveryType)} deliveryType - Used to create contracts
     *          and validate that there are no gaps in the <em>curve</em>.
     * @param {boolean} [partial_updates=true] - Whether to write with partial corrects or not.
     *          If not set, it will default to the feed's default behavior.
     * @return {boolean} Returns <em>true</em> most of the time.  Returns
     *         <em>false</em> if optimization determined that no
     *         new data is being saved.
     */
    saveAsContracts: function (timeSeries, feed, root, columns, curveDate, deliveryType, partial_updates) {
        return _fwdToInstance('saveAsContracts', arguments);
    },

    /**
     * <p>
     *  Saves each row of the given TimeSeries as a separate futures contract,
     *  into the given feed.  Once saved, the <em>curve</em> can be re-created
     *  using {@link forward_curve}.
     * </p>
     *
     * <p>
     *  This method allows for gaps in <code>timeSeries</code>.
     * </p>
     *
     * <p>
     *  Arguments <code>feed</code>, <code>root</code> and <code>columns</code>
     *  can be collapsed into one {@link morn.Product|Product} argument.
     *  When invoked in this manner, this method requires 4 argument
     *  only (5 at most).
     * </p>
     *
     * @param {morn.TimeSeries} timeSeries
     * @param {string} feed - The feed to save to.
     * @param {string} root - The root symbol under which to save.
     * @param {(string|string[])} columns - A name for each of the column.
     *          Multiple column names can still be specified
     *          using a single string value, using commas to separate
     *          the columns.
     * @param {(string|lim.IDate)} curveDate - If <code>curveDate</code> is
     *          only a date - without time - make sure to pass
     *          a string value to avoid time zone offsets.  For
     *          strings, formats "yyyy-MM-dd" and "M/d/yyyy" are
     *          supported.
     * @param {(string|morn.DeliveryType)} deliveryType - Used to create contracts.
     * @param {boolean} [partial_updates=true] Whether to write with partial corrects or not.
     *          If not set, it will default to the feed's default behavior.
     * @return {boolean} Returns <em>true</em> most of the time.  Returns
     *         <em>false</em> if optimization determined that no
     *         new data is being saved.
     */
    saveAsContractsWithGaps: function (timeSeries, feed, root, columns, curveDate, deliveryType, partial_updates) {
        return _fwdToInstance('saveAsContractsWithGaps', arguments);
    },

    /**
     * Pushes all save-data operations batched during the execution of the formula so far,
     * optionally targetting specific feeds.  This method gives users the ability to
     * break large batches of data into smaller batches.
     *
     * @param {...string} feedNames - List of feed names to flush.  If none provided,
     *                    all pending data is flushed.
     * @returns {Integer} Number of ZIP files that were submitted.
     */
    flushSaves: function (feedNames) {

        if (!Arrays.isValid(arguments, Strings.isNonEmpty))
            throw new TypeError("feedNames: String[], may be empty");

        var arr = Arrays.slice(arguments);
        // Callback into Rhino
        return flushDataBatchesJava(arr.join(','));
    },

    /**
     * Sets whether save-data operations are delayed until
     * the formula completes execution or until an explicit call to
     * <code>{@link morn.TimeSeries#flushSaves}</code>.
     *
     * @param {boolean} isBatchEnabled - Whether save-data operations are batched (default false).
     */
    setBatchMode: function (isBatchEnabled) {

        if (typeof isBatchEnabled !== "boolean")
            throw new TypeError("isBatchEnabled: Boolean");

        // Callback into Rhino
        setIsBatchEnabledJava(isBatchEnabled);
    },

    /**
     * Returns a list of all dates found within all
     * TimeSeries provided.
     * @param timeSeries {...morn.TimeSeries}
     * @return {lim.IDate[]}
     */
    unionDates: function (timeSeries) {
        return _unionDates(arguments);
    },

    /**
     * Returns a list of dates common to all
     * TimeSeries provided.
     * @param timeSeries {...morn.TimeSeries}
     * @return {lim.IDate[]}
     */
    intersectionDates: function (timeSeries) {
        return _intersectionDates(arguments);
    },


    /**
     * Returns the union of all TimeSeries.  That is,
     * a new TimeSeries that contains all of the dates
     * and columns of other TimeSeries.
     *
     * @param timeSeries {...morn.TimeSeries}
     * @return {morn.TimeSeries}
     */
    union: function (timeSeries) {
        return _union(arguments);
    },

    /**
     * Returns the intersection of all TimeSeries.  That is,
     * a new TimeSeries that contains only the dates that were
     * found within all instance of TimeSeries provided as arguments.
     *
     * @param timeSeries {...morn.TimeSeries}
     * @return {morn.TimeSeries}
     */
    intersection: function (timeSeries) {
        return _intersection(arguments);
    },

    /**
     * Returns a TimeSeries where all rows from all
     * provided <code>timeSeries</code> have been merged
     * into one TimeSeries.  This method does not create
     * new columns; only rows.
     *
     * The first TimeSeries is used at the base.
     * Any other TimeSeries provided will have their
     * rows added to the <em>base</em>, only if they
     * don't already exist.
     *
     * To overwrite existing rows, use <code>overwrite()</code>.
     *
     * @param timeSeries {...morn.TimeSeries}
     * @return {morn.TimeSeries}
     * @throws IllegalArgumentException if the number of columns
     *                                  does not match between all
     *                                  TimeSeries.
     */
    complement: function (timeSeries) {
        return _mergeTimeSeries(arguments, false);
    },

    /**
     * Returns a TimeSeries where all rows from all
     * provided <code>timeSeries</code> have been merged
     * into one TimeSeries.  This method does not create
     * new columns; only rows.
     *
     * The first TimeSeries is used at the base.
     * The rows of other TimeSeries overwrite
     * the <em>base</em>.
     *
     * Use <code>complement()</code> if you do not wish to
     * overwrite rows.
     *
     * @param timeSeries {...morn.TimeSeries}
     * @return {morn.TimeSeries}
     * @throws IllegalArgumentException if the number of columns
     *                                  does not match between all
     *                                  TimeSeries.
     */
    overwrite: function (timeSeries) {
        return _mergeTimeSeries(arguments, true);
    },
    header : function(timeseries,cols){
        return _addHeader(timeseries,cols);
    },

    /**
     * Returns a new TimeSeries with only the selected
     * column(s).
     * @param timeSeries {...morn.TimeSeries}
     * @param colIndices {...integer} 0-base index of
     *                   column(s) within the TimeSeries.
     * @return {morn.TimeSeries}
     */
    extract: function (timeSeries, colIndices) {
        return _fwdToInstance('extract', arguments);
    },

    /**
     * Returns a subset of rows from the specified TimeSeries.
     * @param timeSeries {morn.TimeSeries}
     * @param [start=0] {(integer|lim.IDate)}
     *              An integer or date
     *              that specifies where the selection starts.
     *              Use a negative integer to select from the end.
     *
     *              This argument is required in order to provide
     *              <code>end</code>.
     *
     * @param [end=length()] {(integer|lim.IDate)}
     *            An integer or date
     *            that specifies where the selection ends.
     *            If omitted, all elements from the start position
     *            to the end are selected.
     *            Use a negative integer to select from the end.
     *
     * @return {morn.TimeSeries}
     */
    slice: function (timeSeries, start, end) {
        return _fwdToInstance('slice', arguments);
    },

    /**
     * Drops rows from the beginning of the given TimeSeries.
     *
     * If <code>numRows</code> is 0 or negative, this method
     * returns the same TimeSeries, unmodified.
     *
     * @param timeSeries {morn.TimeSeries}
     * @param [numRows=1] {integer}
     * @return {morn.TimeSeries}
     */
    dropFirst: function (timeSeries, numRows) {
        return _fwdToInstance('dropFirst', arguments);
    },

    /**
     * Drops rows from the end of the given TimeSeries.
     *
     * If <code>numRows</code> is 0 or negative, this method
     * returns the same TimeSeries, unmodified.
     *
     * @param timeSeries {morn.TimeSeries}
     * @param [numRows=1] {integer}
     * @return {morn.TimeSeries}
     */
    dropLast: function (timeSeries, numRows) {
        return _fwdToInstance('dropLast', arguments);
    },

    /**
     * Keeps rows from the beginning of the given TimeSeries,
     * drops all other rows.
     *
     * If <code>numRows</code> is 0 or negative, this method
     * returns an empty TimeSeries.
     *
     * @param timeSeries {morn.TimeSeries}
     * @param [numRows=1] {integer}
     * @return {morn.TimeSeries}
     */
    keepFirst: function (timeSeries, numRows) {
        return _fwdToInstance('keepFirst', arguments);
    },

    /**
     * Keeps rows from the end of the given TimeSeries,
     * drops all other rows.
     *
     * If <code>numRows</code> is 0 or negative, this method
     * returns an empty TimeSeries.
     *
     * @param timeSeries {morn.TimeSeries}
     * @param [numRows=1] {integer}
     * @return {morn.TimeSeries}
     */
    keepLast: function (timeSeries, numRows) {
        return _fwdToInstance('keepLast', arguments);
    },

    /**
     * Creates a TimeSeries with rows from the given TimeSeries
     * for which the date can be found in all TimeSeries from
     * <code>otherTimeSeries</code>.
     *
     * @param timeSeries {morn.TimeSeries}
     * @param otherTimeSeries {...morn.TimeSeries}
     * @return {morn.TimeSeries}
     */
    whenDateInAllOthers: function (timeSeries, otherTimeSeries) {
        return _fwdToInstance('whenDateInAllOthers', arguments);
    },

    /**
     * Creates a TimeSeries from rows of the given TimeSeries
     * for which the date can be found in at least one
     * TimeSeries from <code>otherTimeSeries</code>.
     *
     * @param timeSeries {morn.TimeSeries}
     * @param otherTimeSeries {...morn.TimeSeries}
     * @return {morn.TimeSeries}
     */
    whenDateInOneOther: function (timeSeries, otherTimeSeries) {
        return _fwdToInstance('whenDateInOneOther', arguments);
    },

    /**
     * Creates a TimeSeries from rows of this TimeSeries
     * for which the date cannot be found in any of the
     * TimeSeries provided in <code>otherTimeSeries</code>.
     *
     * @param timeSeries {morn.TimeSeries}
     * @param otherTimeSeries {...morn.TimeSeries}
     * @return {morn.TimeSeries}
     */
    whenDateInNoneOther: function (otherTimeSeries) {
        return _fwdToInstance('whenDateInNoneOther', arguments);
    },

    /**
     * Creates a TimeSeries from rows of the given TimeSeries
     * where dates are after <code>date</code>.
     *
     * @param timeSeries {morn.TimeSeries}
     * @param date {(lim.IDate|string)}
     * @return {morn.TimeSeries}
     */
    whenDateAfter: function (timeSeries, date) {
        return _fwdToInstance('whenDateAfter', arguments);
    },

    /**
     * Creates a TimeSeries from rows of the given TimeSeries
     * where dates are after or equivalent to <code>date</code>.
     *
     * @param timeSeries {morn.TimeSeries}
     * @param date {(lim.IDate|string)}
     * @return {morn.TimeSeries}
     */
    whenDateAfterOrEqual: function (timeSeries, date) {
        return _fwdToInstance('whenDateAfterOrEqual', arguments);
    },

    /**
     * Creates a TimeSeries from rows of the given TimeSeries
     * where dates are before <code>date</code>.
     *
     * @param timeSeries {morn.TimeSeries}
     * @param date {(lim.IDate|string)}
     * @return {morn.TimeSeries}
     */
    whenDateBefore: function (timeSeries, date) {
        return _fwdToInstance('whenDateBefore', arguments);
    },

    /**
     * Creates a TimeSeries from rows of this TimeSeries
     * where dates are before or equivalent to <code>date</code>.
     *
     * @param timeSeries {morn.TimeSeries}
     * @param date {(lim.IDate|string)}
     * @return {morn.TimeSeries}
     */
    whenDateBeforeOrEqual: function (timeSeries, date) {
        return _fwdToInstance('whenDateBeforeOrEqual', arguments);
    },

    /**
     * Creates a TimeSeries from rows of the given TimeSeries
     * when dates fall on <code>holidays</code>.
     *
     * @param timeSeries {morn.TimeSeries}
     * @param holidays {morn.Holidays} A holiday calendar.
     * @param [isPeriodEnding=false] {boolean} Whether to use
     *        period-beginning (false) or period-ending (true).
     *        Only use <em>true</em> with intraday data;
     *        behavior is otherwise undefined.
     * @param [dayCutoff="00:00"] {(integer|string)} Time of day at which the
     *              day begins. If <em>integer</em>, argument
     *              represents milliseconds since midnight.
     *              If <em>string</em>, argument is expected to be
     *              in "H:mm" format.
     *              Use this argument to includes portions of the previous or
     *              next day as part of the current day.  For example, specifying
     *              "22:00" means that data on or after 10 PM will be considered
     *              part of the next day.  Similarily, "2:00" means that data up to
     *              2 AM (exclusive) will be considered part of the previous day.
     * @return {morn.TimeSeries}
     */
    whenHoliday: function (timeSeries, holidays, isPeriodEnding, dayCutoff) {
        return _fwdToInstance('whenHoliday', arguments);
    },

    /**
     * Creates a TimeSeries from rows of the given TimeSeries
     * when dates do not fall on <code>holidays</code>.
     *
     * @param timeSeries {morn.TimeSeries}
     * @param holidays {morn.Holidays} A holiday calendar.
     * @param [isPeriodEnding=false] {boolean} Whether to use
     *        period-beginning (false) or period-ending (true).
     *        Only use <em>true</em> with intraday data;
     *        behavior is otherwise undefined.
     * @param [dayCutoff="00:00"] {(integer|string)} Time of day at which the
     *              day begins. If <em>integer</em>, argument
     *              represents milliseconds since midnight.
     *              If <em>string</em>, argument is expected to be
     *              in "H:mm" format.
     *              Use this argument to includes portions of the previous or
     *              next day as part of the current day.  For example, specifying
     *              "22:00" means that data on or after 10 PM will be considered
     *              part of the next day.  Similarily, "2:00" means that data up to
     *              2 AM (exclusive) will be considered part of the previous day.
     * @return {morn.TimeSeries}
     */
    whenNotHoliday: function (timeSeries, holidays, isPeriodPending, dayCutoff) {
        return _fwdToInstance('whenNotHoliday', arguments);
    },

    /**
     * Creates a TimeSeries from rows of the given TimeSeries
     * where at least one column contains a value greater
     * than <code>value</code>.
     *
     * @param timeSeries {morn.TimeSeries}
     * @param value {number}
     * @return {morn.TimeSeries}
     */
    whenValueGreater: function (timeSeries, value) {
        return _fwdToInstance('whenValueGreater', arguments);
    },

    /**
     * Creates a TimeSeries from rows of the given TimeSeries
     * where at least one column contains a value greater
     * than or equal to <code>value</code>.
     *
     * @param timeSeries {morn.TimeSeries}
     * @param value {number}
     * @return {morn.TimeSeries}
     */
    whenValueGreaterOrEqual: function (timeSeries, value) {
        return _fwdToInstance('whenValueGreaterOrEqual', arguments);
    },

    /**
     * Creates a TimeSeries from rows of the given TimeSeries
     * where at least one column contains a value less
     * than <code>value</code>.
     *
     * @param timeSeries {morn.TimeSeries}
     * @param value {number}
     * @return {morn.TimeSeries}
     */
    whenValueLess: function (timeSeries, value) {
        return _fwdToInstance('whenValueLess', arguments);
    },

    /**
     * Creates a TimeSeries from rows of the given TimeSeries
     * where at least one column contains a value less
     * than or equal to <code>value</code>.
     *
     * @param timeSeries {morn.TimeSeries}
     * @param value {number}
     * @return {morn.TimeSeries}
     */
    whenValueLessOrEqual: function (timeSeries, value) {
        return _fwdToInstance('whenValueLessOrEqual', arguments);
    },

    /**
     * Creates a TimeSeries from rows of the given TimeSeries
     * where the sole column of the given TimeSeries
     * contains a value within the <em>low</em> and <em>high</em>
     * values from <code>thatTimeSeries</code> for the same date.
     *
     * If <code>thatTimeSeries</code> doesn't have a row for the given
     * date or if a column contains a NaN (NaN, +Infinity or -Infinity)
     * for that date, the previous value is
     * used to calculate <em>low</em> and <em>high</em>.
     *
     * @param timeSeries {morn.TimeSeries}
     * @param thatTimeSeries {morn.TimeSeries} Must contain at least 2 columns.
     * @return {morn.TimeSeries}
     */
    whenValueWithin: function (timeSeries, thatTimeSeries) {
        return _fwdToInstance('whenValueWithin', arguments);
    },

    /**
     * Creates a TimeSeries from rows of the given TimeSeries
     * where the sole column of the given TimeSeries
     * contains a value NOT within the <em>low</em> and <em>high</em>
     * values from <code>thatTimeSeries</code> for the same date.
     *
     * If <code>thatTimeSeries</code> doesn't have a row for the given
     * date or if a column contains a NaN (NaN, +Infinity or -Infinity)
     * for that date, the previous value is
     * used to calculate <em>low</em> and <em>high</em>.
     *
     * @param timeSeries {morn.TimeSeries}
     * @param thatTimeSeries {morn.TimeSeries} Must contain at least 2 columns.
     * @return {morn.TimeSeries}
     */
    whenValueOutsideOf: function (timeSeries, thatTimeSeries) {
        return _fwdToInstance('whenValueOutsideOf', arguments);
    },

    /**
     * Returns a new TimeSeries created off the specified TimeSeries
     * without <em>NaN</em> rows.  NaN rows contain not one
     * single finite value (only NaN, +Infinity, -Infinity)
     * The returned TimeSeries only contains non-NaN rows.
     *
     * @param timeSeries {morn.TimeSeries}
     * @return {morn.TimeSeries}
     */
    dropNaNs: function (timeSeries) {
        return _fwdToInstance('dropNaNs', arguments);
    },


    /**
     * Applies a time offset to all rows in the specified TimeSeries.
     * @param timeSeries {morn.TimeSeries}
     * @param offset {(integer|function)} Integers are treated as millisecond
     *               offsets.  Functions are treated as callbacks; they
     *               are called with a date argument (the row date) and
     *               must return a date value (the new row date).
     * @return {morn.TimeSeries}
     */
    offset: function (timeSeries, offset) {
        return _fwdToInstance('offset', arguments);
    },

    /**
     * Adjusts all dates within this TimeSeries so that
     * they are displayed using the same instant within
     * the given time zone.
     *
     * @param timeSeries {morn.TimeSeries}
     * @param tz {(string|lim.TimeZone)}
     * @return {morn.TimeSeries}
     */
    withZone: function (timeSeries, tz) {
        return _fwdToInstance('withZone', arguments);
    },

    /**
     * Adjusts all dates within this TimeSeries so that
     * they are displayed using the same values within
     * the (presumably) different time zone.
     *
     * @param timeSeries {morn.TimeSeries}
     * @param tz {(string|lim.TimeZone)}
     * @param {boolean} [fixDst=false] - When giving a time zone to a list of dates, gaps
     *                                   and overlaps can appear due to DST changes.
     *                                   Set <code>fixDst</code> to true to auto-fill the
     *                                   gaps and remove the overlaps.  (Gaps are filled
     *                                   using values from the repeating hour(s) on the wall clock.)
     * @return {morn.TimeSeries}
     */
    withZoneRetainFields: function (timeSeries, tz, fixDst) {
        return _fwdToInstance('withZoneRetainFields', arguments);
    },

    /**
     * Creates a new TimeSeries from selected rows.
     * The <code>selector</code> function is called on
     * each row; it should return <em>true</em> when the
     * given row should be included in the new TimeSeries
     * (selected).
     *
     * @param timeSeries {morn.TimeSeries}
     * @param selector {function} A function that accepts one Row
     *                 argument, returns a Boolean value.
     * @return {morn.TimeSeries}
     */
    select: function (timeSeries, selector) {
        return _fwdToInstance('select', arguments);
    },


    /**
     * Creates a new TimeSeries without the <em>dropped</em> rows.
     * The <code>dropper</code> function is called on each row;
     * it should return <em>true</em> when the given row should
     * be excluded from the new TimeSeries (dropped).
     *
     * @param timeSeries {morn.TimeSeries}
     * @param dropper {function} A function that accepts a Row
     *                 object and returns a Boolean value.
     * @return {morn.TimeSeries}
     */
    drop: function (timeSeries, dropper) {
        return _fwdToInstance('drop', arguments);
    },

    /**
     * Creates a new TimeSeries from rows within the given
     * time of day.
     *
     * @param timeSeries {morn.TimeSeries}
     * @param start {(integer|string)} Time of day at which the
     *              period begins. If <em>integer</em>, argument
     *              represents milliseconds since midnight.
     *              If <em>string</em>, argument is expected to be
     *              in "H:mm" format.
     * @param end {(integer|string)} Time of day at which the
     *              period ends. If <em>integer</em>, argument
     *              represents milliseconds since midnight.
     *              If <em>string</em>, argument is expected to be
     *              in "H:mm" format.
     * @param [isPeriodEnding=false] {boolean} Whether to use
     *        period-beginning logic (false) or period-ending logic (true).
     *        Period-beginning treats <code>start</code> as inclusive and
     *        <code>end</code> as exclusive.  On the opposite, period-ending
     *        treats <code>start</code> as exclusive and <code>end</code>
     *        as inclusive.
     * @return {morn.TimeSeries}
     */
    whenTimeWithin: function (timeSeries, start, end, isPeriodEnding) {
        return _fwdToInstance('whenTimeWithin', arguments);
    },

    /**
     * Creates a new TimeSeries from rows outside of the given
     * time of day.
     *
     * @param timeSeries {morn.TimeSeries}
     * @param start {(integer|string)} Time of day at which the
     *              period begins. If <em>integer</em>, argument
     *              represents milliseconds since midnight.
     *              If <em>string</em>, argument is expected to be
     *              in "H:mm" format.
     * @param end {(integer|string)} Time of day at which the
     *              period ends. If <em>integer</em>, argument
     *              represents milliseconds since midnight.
     *              If <em>string</em>, argument is expected to be
     *              in "H:mm" format.
     * @param [isPeriodEnding=false] {boolean} Whether to use
     *        period-beginning logic (false) or period-ending logic (true).
     *        Period-beginning treats <code>start</code> as inclusive and
     *        <code>end</code> as exclusive.  On the opposite, period-ending
     *        treats <code>start</code> as exclusive and <code>end</code>
     *        as inclusive.
     * @return {morn.TimeSeries}
     */
    whenTimeOutside: function (timeSeries, start, end, isPeriodEnding) {
        return _fwdToInstance('whenTimeOutside', arguments);
    },

    /**
     * Creates a new TimeSeries from rows corresponding to
     * <code>daysOfWeek</code>.
     *
     * @param timeSeries {morn.TimeSeries}
     * @param daysOfWeek {integer[]} A list of day-of-week to be selected.
     * @param [isPeriodEnding=false] {boolean} Whether to use
     *        period-beginning logic (false) or period-ending logic (true).
     *        Period-beginning treats the midnight mark as part of the
     *        same date.  On the opposite, period-ending treats that
     *        same midnight mark as part of the previous day.
     * @param [dayCutoff="00:00"] {(integer|string)} Time of day at which the
     *              day begins. If <em>integer</em>, argument
     *              represents milliseconds since midnight.
     *              If <em>string</em>, argument is expected to be
     *              in "H:mm" format.
     *              Use this argument to includes portions of the previous or
     *              next day as part of the current day.  For example, specifying
     *              "22:00" means that data on or after 10 PM will be considered
     *              part of the next day.  Similarily, "2:00" means that data up to
     *              2 AM (exclusive) will be considered part of the previous day.
     * @return {morn.TimeSeries}
     */
    whenDayOfWeek: function (timeSeries, daysOfWeek, isPeriodEnding, dayCutoff) {
        return _fwdToInstance('whenDayOfWeek', arguments);
    },

    /**
     * Creates a new TimeSeries from rows corresponding to
     * week days only (Monday through Friday).
     *
     * @param timeSeries {morn.TimeSeries}
     * @param [isPeriodEnding=false] {boolean} Whether to use
     *        period-beginning logic (false) or period-ending logic (true).
     *        Period-beginning treats the midnight mark as part of the
     *        same date.  On the opposite, period-ending treats that
     *        same midnight mark as part of the previous day.
     * @param [dayCutoff="00:00"] {(integer|string)} Time of day at which the
     *              day begins. If <em>integer</em>, argument
     *              represents milliseconds since midnight.
     *              If <em>string</em>, argument is expected to be
     *              in "H:mm" format.
     *              Use this argument to includes portions of the previous or
     *              next day as part of the current day.  For example, specifying
     *              "22:00" means that data on or after 10 PM will be considered
     *              part of the next day.  Similarily, "2:00" means that data up to
     *              2 AM (exclusive) will be considered part of the previous day.
     * @return {morn.TimeSeries}
     */
    whenWeekday: function (timeSeries, isPeriodPending, dayCutoff) {
        return _fwdToInstance('whenWeekday', arguments);
    },

    /**
     * Creates a new TimeSeries from rows corresponding to
     * weekend days only (Saturday, Sunday).
     *
     * @param timeSeries {morn.TimeSeries}
     * @param [isPeriodEnding=false] {boolean} Whether to use
     *        period-beginning logic (false) or period-ending logic (true).
     *        Period-beginning treats the midnight mark as part of the
     *        same date.  On the opposite, period-ending treats that
     *        same midnight mark as part of the previous day.
     * @param [dayCutoff="00:00"] {(integer|string)} Time of day at which the
     *              day begins. If <em>integer</em>, argument
     *              represents milliseconds since midnight.
     *              If <em>string</em>, argument is expected to be
     *              in "H:mm" format.
     *              Use this argument to includes portions of the previous or
     *              next day as part of the current day.  For example, specifying
     *              "22:00" means that data on or after 10 PM will be considered
     *              part of the next day.  Similarily, "2:00" means that data up to
     *              2 AM (exclusive) will be considered part of the previous day.
     * @return {morn.TimeSeries}
     */
    whenWeekend: function (timeSeries, isPeriodPending, dayCutoff) {
        return _fwdToInstance('whenWeekend', arguments);
    },

    /**
     * Creates a new TimeSeries from rows corresponding to peak periods.
     * By default, peak periods are defined as week days (Mon-Fri) that are not
     * holidays. Peak periods can also be defined as <code>daysOfWeek</code>.
     * Within those days, only certain hours of the day are considered peak periods.
     *
     * @param timeSeries {morn.TimeSeries}
     * @param [holidays=null] {?(morn.Holidays|string)} A holiday calendar, name or object.
     * @param [start="7:00"] {?(integer|string)} Time of day at which the peak
     *                       period begins. If <em>integer</em>, argument
     *                       represents milliseconds since midnight.
     *                       If <em>string</em>, argument is expected to be
     *                       in "H:mm" format.
     * @param [end="22:00"] {?(integer|string)} Time of day at which the peak
     *                      period ends. If <em>integer</em>, argument
     *                      represents milliseconds since midnight.
     *                      If <em>string</em>, argument is expected to be
     *                      in "H:mm" format.
     * @param [isPeriodEnding=false] {?boolean} Whether to use
     *        period-beginning logic (false) or period-ending logic (true).
     *        Period-beginning treats <code>start</code> as inclusive and
     *        <code>end</code> as exclusive.  On the opposite, period-ending
     *        treats <code>start</code> as exclusive and <code>end</code>
     *        as inclusive.
     * @param [daysOfWeek=IDate.WEEK_DAYS] {integer[]} A list of day-of-week that represents
     *        peak periods.
     * @return {morn.TimeSeries}
     *
     * @see morn.TimeSeries.whenWeekday
     * @see morn.TimeSeries.whenNotHoliday
     * @see morn.TimeSeries.whenTimeWithin
     */
    whenPeak: function (timeSeries, holidays, start, end, isPeriodEnding, daysOfWeek) {
        return _fwdToInstance('whenPeak', arguments);
    },

    /**
     * Creates a new TimeSeries from rows corresponding to off-peak periods.
     * By default, off-peak periods are defined as weekends (Sat, Sun) and holidays.
     * Additionally, off-hour periods during week days (Mon-Fri) are also
     * off-peak periods. Off-peak periods will change if <code>daysOfWeek</code>
     * is not <code>null</code>.
     *
     * @param timeSeries {morn.TimeSeries}
     * @param [holidays=null] {?(morn.Holidays|string)} A holiday calendar, name or object.
     * @param [start="7:00"] {?(integer|string)} Time of day at which the peak
     *                       period begins. If <em>integer</em>, argument
     *                       represents milliseconds since midnight.
     *                       If <em>string</em>, argument is expected to be
     *                       in "H:mm" format.
     * @param [end="22:00"] {?(integer|string)} Time of day at which the peak
     *                      period ends. If <em>integer</em>, argument
     *                      represents milliseconds since midnight.
     *                      If <em>string</em>, argument is expected to be
     *                      in "H:mm" format.
     * @param [isPeriodEnding=false] {?boolean} Whether to use
     *        period-beginning logic (false) or period-ending logic (true).
     *        Period-beginning treats <code>start</code> as inclusive and
     *        <code>end</code> as exclusive.  On the opposite, period-ending
     *        treats <code>start</code> as exclusive and <code>end</code>
     *        as inclusive.
     * @param [daysOfWeek=IDate.WEEK_DAYS] {integer[]} A list of day-of-week that represents
     *        peak periods.
     * @return {morn.TimeSeries}
     *
     * @see morn.TimeSeries.whenWeekend
     * @see morn.TimeSeries.whenHoliday
     * @see morn.TimeSeries.whenTimeOutside
     * @see morn.TimeSeries.whenDateInNoneOther
     */
    whenOffPeak: function (timeSeries, holidays, start, end, isPeriodEnding, daysOfWeek) {
        return _fwdToInstance('whenOffPeak', arguments);
    },

    /**
     * Creates a new TimeSeries by computing daily averages of the peak periods.
     *
     * @param timeSeries {morn.TimeSeries}
     * @param [holidays=null] {?(morn.Holidays|string)} A holiday calendar, name or object.
     * @param [start="7:00"] {?(integer|string)} Time of day at which the peak
     *                       period begins. If <em>integer</em>, argument
     *                       represents milliseconds since midnight.
     *                       If <em>string</em>, argument is expected to be
     *                       in "H:mm" format.
     * @param [end="22:00"] {?(integer|string)} Time of day at which the peak
     *                      period ends. If <em>integer</em>, argument
     *                      represents milliseconds since midnight.
     *                      If <em>string</em>, argument is expected to be
     *                      in "H:mm" format.
     * @param [isPeriodEnding=false] {?boolean} Whether to use
     *        period-beginning logic (false) or period-ending logic (true).
     *        Period-beginning treats <code>start</code> as inclusive and
     *        <code>end</code> as exclusive.  On the opposite, period-ending
     *        treats <code>start</code> as exclusive and <code>end</code>
     *        as inclusive.
     * @param [daysOfWeek=IDate.WEEK_DAYS] {integer[]} A list of day-of-week that represents
     *        peak periods.
     * @param [isDayEnding=false] {boolean} Whether to use day-ending logic or not.
     *                            If <em>true</em>,
     *                            {@link morn.TimeSeries.DateGroupingMethods.DAY_ENDING_ON_PREVIOUS|DAY_ENDING_ON_PREVIOUS}
     *                            is used.
     * @param [iso="ERCOT"]{String} iso name for setting default paramters specific to iso
     * @return {morn.TimeSeries}
     *
     * @see morn.TimeSeries.dailyAverage
     * @see morn.TimeSeries.whenPeak
     */
    dailyAveragePeak: function (timeSeries, holidays, start, end, isPeriodEnding, daysOfWeek, isDayEnding,iso) {
        return _fwdToInstance('dailyAveragePeak', arguments);
    },

    /**
     * Creates a new TimeSeries by computing daily averages of the off-peak periods.
     *
     * @param timeSeries {morn.TimeSeries}
     * @param [holidays=null] {?(morn.Holidays|string)} A holiday calendar, name or object.
     * @param [start="7:00"] {?(integer|string)} Time of day at which the peak
     *                       period begins. If <em>integer</em>, argument
     *                       represents milliseconds since midnight.
     *                       If <em>string</em>, argument is expected to be
     *                       in "H:mm" format.
     * @param [end="22:00"] {?(integer|string)} Time of day at which the peak
     *                      period ends. If <em>integer</em>, argument
     *                      represents milliseconds since midnight.
     *                      If <em>string</em>, argument is expected to be
     *                      in "H:mm" format.
     * @param [isPeriodEnding=false] {?boolean} Whether to use
     *        period-beginning logic (false) or period-ending logic (true).
     *        Period-beginning treats <code>start</code> as inclusive and
     *        <code>end</code> as exclusive.  On the opposite, period-ending
     *        treats <code>start</code> as exclusive and <code>end</code>
     *        as inclusive.
     * @param [daysOfWeek=IDate.WEEK_DAYS] {integer[]} A list of day-of-week that represents
     *        peak periods.
     * @param [isDayEnding=false] {boolean} Whether to use day-ending logic or not.
     *                            If <em>true</em>,
     *                            {@link morn.TimeSeries.DateGroupingMethods.DAY_ENDING_ON_PREVIOUS|DAY_ENDING_ON_PREVIOUS}
     *                            is used.
     * @param [iso="ERCOT"]{String} iso name for setting default paramters specific to iso
     * @return {morn.TimeSeries}
     *
     * @see morn.TimeSeries.dailyAverage
     * @see morn.TimeSeries.whenOffPeak
     */
    dailyAverageOffPeak: function (timeSeries, holidays, start, end, isPeriodEnding, daysOfWeek, isDayEnding,iso) {
        return _fwdToInstance('dailyAverageOffPeak', arguments);
    },

    /**
     * This method iterates through the rows of the given
     * TimeSeries and calls <code>modifier</code> for every
     * row.  The <code>modifier</code> callback receives
     * two arguments:
     *   > Row row - the Row object
     *   > Number rowIndex - the index position within TimeSeries
     *
     * Within the callback, <em>this</em> points to the TimeSeries.
     *
     * To modify a row, <code>modifier</code> needs to return
     * a valid Row or DataPoint[],
     * which will be inserted into the returned TimeSeries.
     *
     * If <code>modifier</code> returns <em>null</em>
     * or <em>undefined</em>, the existing row is transfered
     * to the new TimeSeries unmodified.
     *
     * The resulting TimeSeries has the same number
     * of rows and columns as the given (source) TimeSeries.
     *
     * @param timeSeries {morn.TimeSeries}
     * @param modifier {function}
     * @return {morn.TimeSeries}
     * @throws IllegalStateException if <code>modifier</code> returns anything
     *         other than Row, DataPoint[], null or <em>undefined</em>.
     */
    modifyRows: function (timeSeries, modifier) {
        return _fwdToInstance('modifyRows', arguments);
    },


    /**
     * This method iterates through the data-points of the given
     * TimeSeries and calls <code>modifier</code> for every
     * numerical data-point.  The <code>modifier</code> callback receives
     * four arguments:
     *   > IDate date
     *   > Number value
     *   > Integer rowIndex
     *   > Integer colIndex
     *
     * Note that <code>modifier</code> is not called for NaN data-points.
     * Within the callback, <em>this</em> points to the TimeSeries.
     *
     * To modify a data-point, <code>modifier</code> needs to return
     * a Number - NaN, +Infinity and -Infinity are acceptable returned
     * values. The new value is used to <em>mutate</em> the existing
     * data-point into a new one (for tracing purpose.)
     *
     * If <code>modifier</code> returns anything other than a
     * number - <em>null</em>, <em>undefined</em> or anything else -
     * the existing data-point is transfered to the new TimeSeries
     * unmodified.
     *
     * The resulting TimeSeries has the same number
     * of rows and columns as the given (source) TimeSeries.
     *
     * @param timeSeries {morn.TimeSeries}
     * @param tag {string} To document data-point mutations.
     * @param modifier {morn.TimeSeries.ValueModifier} Callback function used to replace
     *                 the existing values with new ones.
     * @return {morn.TimeSeries}
     * @throws TypeError
     *    - If <code>modifier</code> is not a Function that
     *      defines 2 to 4 arguments.
     *    - If <code>tag</code> is not a String.
     */
    modifyDataPoints: function (timeSeries, tag, modifier) {
        return _fwdToInstance('modifyDataPoints', arguments);
    },


    /**
     * Use this method to replace NaN values within the given
     * TimeSeries.  NaN values can be NaN, +Infinity and -Infinity.
     *
     * The <code>replaceWith</code> argument can be a Number
     * (NaN, +Infinity, -Infinity are acceptable) or a
     * Function (defining 2 to 4 arguments).
     *
     * The resulting TimeSeries has the same number
     * of rows and columns as the given (source) TimeSeries.
     *
     * @param timeSeries {morn.TimeSeries}
     * @param replaceWith {(number|morn.TimeSeries.ValueModifier)}
     *                    If a function, that function is used as a callback
     *                    to replace the NaN values with valid numeric values.
     *                    Note that in this case, the 2nd argument of the callback
     *                    function - <em>value</em> - will always be <em>NaN</em>.
     *                    Still, that 2nd argument must be declared.
     * @param [tag] {string} Overwrites default tag.
     * @return {morn.TimeSeries}
     * @throws TypeError
     *     - If <code>replaceWith</code> is neither a Number nor a Function;
     *     - If <code>replaceWith</code> is a function that defines less
     *       than 2 or more than 4 arguments.
     */
    replaceNaNs: function (timeSeries, replaceWith, tag) {
        return _fwdToInstance('replaceNaNs', arguments);
    },


    /**
     * Use this method to extrapolate a TimeSeries,
     * adding new dates and replacing the NaNs within it.
     * @param timeSeries {morn.TimeSeries}
     * @param calendar {(morn.Calendar|lim.IDate[]|lim.IDate|string)}
     * @param replaceWith {(number|morn.TimeSeries.ValueModifier)}
     *                    If a function, that function is used as a callback
     *                    to insert numeric values in places where none exists,
     *                    or where NaNs exist.
     *                    Note that in this case, the 2nd argument of the callback
     *                    function - <em>value</em> - will always be <em>NaN</em>.
     *                    Still, that 2nd argument must be declared.
     * @param [tag] {string} Overwrites default tag.
     * @return {morn.TimeSeries}
     */
    extrapolate: function (timeSeries, calendar, replaceWith, tag) {
        return _fwdToInstance('extrapolate', arguments);
    },

    /**
     * Interpolates the given TimeSeries, inserting missing dates and using
     * linear-interpolation to populate missing values.
     * This method replaces all NaN values found between valid numbers.
     *
     * @param timeSeries {morn.TimeSeries}
     * @param {(morn.Calendar|lim.IDate[]|lim.IDate|string|function)} [calendar=[]]
     *        Dates to insert in this TimeSeries.  It can be a list of dates,
     *        one simple date (possibly as a string).  It can also be a function,
     *        in which case the function will be executed with two arguments:
     *        <code>timeSeries.dateAt(0)</code> and <code>timeSeries.dateAt(-1)</code>
     *        respectively.  The function must return an object of the previously
     *        listed data-types.
     * @param {boolean} [useLinearTime=false] - Whether the interpolation is based
     *        on row position within the TimeSeries (default), or based on the linear
     *        passage of time.
     * @returns {morn.TimeSeries}
     */
    interpolateLinear: function (timeSeries, calendar) {
        return _fwdToInstance('interpolateLinear', arguments);
    },

    /**
     * Copies the values of the given TimeSeries forward.
     * @param timeSeries {morn.TimeSeries}
     * @param {(morn.Calendar|lim.IDate[]|morn.TimeSeries|function)} [calendar] - An array of IDate objects,
     *                          a Calendar, a TimeSeries, or a function that receives two IDate arguments
     *                          and returns either a Calendar or array of IDate objects.
     * @param [tag="fill_forward"] {string} Overwrites default tag.
     * @return {morn.TimeSeries}
     */
    fillForward: function (timeSeries, calendar, tag) {
        return _fwdToInstance('fillForward', arguments);
    },

    /**
     * Copies the values of the given TimeSeries backward.
     * @param timeSeries {morn.TimeSeries}
     * @param {(morn.Calendar|lim.IDate[]|morn.TimeSeries|function)} [calendar] - An array of IDate objects,
     *                          a Calendar, a TimeSeries, or a function that receives two IDate arguments
     *                          and returns either a Calendar or array of IDate objects.
     * @param [tag="fill_backward"] {string} Overwrites default tag.
     * @return {morn.TimeSeries}
     */
    fillBackward: function (timSeries, calendar, tag) {
        return _fwdToInstance('fillBackward', arguments);
    },

    /**
     * Fills the values with NaN for missing dates.
     * @param timeSeries {morn.TimeSeries}
     * @param {(morn.Calendar|lim.IDate[]|morn.TimeSeries|function)} [calendar] - An array of IDate objects,
     *                          a Calendar, a TimeSeries, or a function that receives two IDate arguments
     *                          and returns either a Calendar or array of IDate objects.
     * @param [tag="fill_nan"] {string} Overwrites default tag.
     * @return {morn.TimeSeries}
     */
    fillNan: function (timSeries, calendar, tag) {
        return _fwdToInstance('fillNan', arguments);
    },

    /**
     * Creates a new TimeSeries based on the given TimeSeries with
     * only the specified rows included.  The rows are included
     * using dates specified by <code>datesToKeep</code> which can be
     * a TimeSeries, an IDate[], an IDate or a String.
     *
     * Optionally, the range around each date can be expanded using
     * <code>numBefore</code> and <code>numAfter</code>.
     *
     * @param timeSeries {morn.TimeSeries}
     * @param datesToKeep {(morn.TimeSeries|lim.IDate[]|lim.IDate|string)}
     * @param {Integer} [numBefore=0] - How many rows from \`timeSeries\` should
     *                                  be included before each matched date.
     * @param {Integer} [numAfter=0] - How many rows from \`timeSeries\` should
     *                                  be included after each matched date.
     * @return {morn.TimeSeries}
     */
    keepDates: function (timeSeries, datesToKeep, numBefore, numAfter) {
        return _fwdToInstance('keepDates', arguments);
    },


    /**
     * Creates a new TimeSeries based on the given TimeSeries with the
     * specified rows excluded from it.  The rows to exclude are
     * specified using <code>datesToDrop</code> which can be a TimeSeries,
     * an IDate[], an IDate or a String.
     *
     * @param timeSeries {morn.TimeSeries}
     * @param datesToDrop {(morn.TimeSeries|lim.IDate[]|lim.IDate|string)}
     * @return {morn.TimeSeries}
     */
    dropDates: function (timeSeries, datesToDrop) {
        return _fwdToInstance('dropDates', arguments);
    },

    /**
     * Reduces all rows of the TimeSeries to a one-dimensional array of numbers
     * representing the sum of all rows.
     * @param timeSeries {morn.TimeSeries}
     * @return {number[]} Sum within each column.  If the
     *                    time series only contains 1 column, this method
     *                    returns an array of one number.
     */
    reduceToSum: function (timeSeries) {
        return _fwdToInstance('reduceToSum', arguments);
    },

    /**
     * Reduces all rows of the TimeSeries to a one-dimensional array of numbers
     * representing the count (of numeric values) of all rows.
     * @param timeSeries {morn.TimeSeries}
     * @return {number[]} Count within each column.  If the
     *                    time series only contains 1 column, this method
     *                    returns an array of one number.
     */
    reduceToCount: function (timeSeries) {
        return _fwdToInstance('reduceToCount', arguments);
    },

    /**
     * Reduces all rows of the TimeSeries to a one-dimensional array of numbers
     * representing the average of all rows.
     * @param timeSeries {morn.TimeSeries}
     * @return {number[]} Average within each column.  If the
     *                    time series only contains 1 column, this method
     *                    returns an array of one number.
     */
    reduceToAverage: function (timeSeries) {
        return _fwdToInstance('reduceToAverage', arguments);
    },

    /**
     * Reduces all rows of the TimeSeries to a one-dimensional array of numbers
     * representing the lowest value within all rows.
     * @param timeSeries {morn.TimeSeries}
     * @return {number[]} Lowest value within each column.  If the
     *                    time series only contains 1 column, this method
     *                    returns an array of one number.
     */
    reduceToMin: function (timeSeries) {
        return _fwdToInstance('reduceToMin', arguments);
    },

    /**
     * Reduces all rows of the TimeSeries to a one-dimensional array of numbers
     * representing the highest value within all rows.
     * @param timeSeries {morn.TimeSeries}
     * @return {number[]} Highest value within each column.  If the
     *                    time series only contains 1 column, this method
     *                    returns an array of one number.
     */
    reduceToMax: function (timeSeries) {
        return _fwdToInstance('reduceToMax', arguments);
    },

    /**
     * Reduces all rows of the TimeSeries to a one-dimensional array of numbers.
     * @param timeSeries {morn.TimeSeries}
     * @param reducer {morn.TimeSeries.Reducer}
     * @return {number[]} A computed value for each column.  If the
     *                    time series only contains 1 column, this method
     *                    returns an array of one number.
     */
    reduce: function (timeSeries, reducer) {
        return _fwdToInstance('reduce', arguments);
    },

    /**
     * <p>
     *     Returns the correlation between matched columns from <code>timeSeries</code> and
     *     <code>otherTimeSeries</code>.
     * </p>
     * <p>
     *     A note about correlation involving multi-column time-series:
     * </p>
     * <ul>
     *     <li> If only one of the two time-series has multiple columns, a correlation
     *          is calculated for each column against the other time-series' sole column. </li>
     *     <li> If both are multi-column time-series, a correlation is calculated
     *          for each matched column.  Unmatched columns result in a correlation
     *          of <i>0</i> (no correlation).
     * </ul>
     *
     * @param {morn.TimeSeries} timeSeries - A time-series to correlate.
     * @param {morn.TimeSeries} otherTimeSeries - Another time-series to correlate with.
     * @param {(morn.TimeSeries.CorrelationOptions|Number)} [options]
     *        Correlation options, provided as a single number (aka fill option)
     *        or multiple options wrapped in a CorrelationOptions object.
     * @returns {Number[]} One correlation result per column.  Correlations range between -1 and 1.
     */
    correlation: function (timeSeries, otherTimeSeries, options) {
        return _fwdToInstance('correlation', arguments);
    },

    /**
     * Creates a matrix representing a set of correlation results between all
     * combinations of the given TimeSeries.
     * @param {(morn.TimeSeries[]|morn.TimeSeries)} timeSeries
     *        If an array is given, all time-series must be single-column.
     *        Otherwise a single time-series is expected and all combinations
     *        of its columns are correlated against one another.
     * @param {(morn.TimeSeries.CorrelationOptions|Number)} [options]
     *        Correlation options, provided as a single number (aka fill option)
     *        or multiple options wrapped in a CorrelationOptions object.
     * @returns {Number[][]} A matrix representing a set of correlation results between all
     *                       combinations of the given TimeSeries (a.k.a. symmetric diagonal).
     */
    correlationMatrix: function (timeSeries, options) {

        var list;

        if (timeSeries instanceof TimeSeries) {
            list = [];
            for (var i = 0, size = timeSeries.size(); i < size; i++)
                list.push(timeSeries.extract(i));
        }

        else {
            var isValid = Arrays.isValid(timeSeries, function (item) {
                return (   item instanceof TimeSeries
                    && item.size() === 1 );
            });

            if (!isValid)
                throw new TypeError("timeSeries: TimeSeries, or Array of single-column TimeSeries");

            list = timeSeries;
        }

        var corrOptions;
        if (arguments.length < 2)
            corrOptions = _newCorrelationOptions();

        else if (_isFiniteNumber(options)) {
            corrOptions = _newCorrelationOptions();
            corrOptions.fill = options;
        }
        else
            corrOptions = _validCorrelationOptions(options);


        var corrFn = _getCorrelationFn(corrOptions),
            size   = list.length,
            matrix = Arrays.newInstance(size, function () {
                return Arrays.newInstance(size, Number.NaN);
            });

        for (var i = 0; i < size; i++) {

            var ts1 = list[i];

            matrix[i][i] = ((ts1.length() > 1) ? 1 : CORRELATION_INSUFFICIENT);

            for (var j = i + 1; j < size; j++) {

                var ts2  = list[j],
                    corr = corrFn(_correlationPrep(ts1, ts2, corrOptions, false));

                matrix[i][j] = corr;
                matrix[j][i] = corr;  // populate both side of the diagonal within the matrix.
            }
        }

        return matrix;
    },

    /**
     * Creates a TimeSeries that represents the rolling correlation between two datasets.
     *
     * @param {(morn.TimeSeries|morn.Product)} dataset1 - First dataset to correlate, must be single-column.
     * @param {(morn.TimeSeries|morn.Product)} dataset2 - Second dataset to correlate, must be single-column.
     * @param {(lim.Date.Modifier|Integer)} range - Number of values included in each correlation,
     *                                      defined using a number of values (integer) or time period
     *                                      (date modifier).  When passing a date modifier, callers likely
     *                                      want to use a <em>negative</em> modifier, to calculate
     *                                      a correlation by looking back at the previous numbers
     *                                      (aka history, <code>IDate.modifierOfDays(-30)</code>).
     *                                      Although less common, this method does support calculating correlations
     *                                      by looking forward (toward upcoming numbers).
     * @param {morn.TimeSeries.CorrelationOptions} options - Optional parameters to refine the
     *                                             calculation of each correlation.
     * @returns {morn.TimeSeries}
     */
    correlationRoll: function (dataset1, dataset2, range, options) {

        if (   !_isProduct_Key_SingleCol(dataset1)
            && !_isTimeSeries_SingleCol(dataset1) )
            throw new TypeError("dataset1: TimeSeries or Product (key), single-column");

        if (   !_isProduct_Key_SingleCol(dataset2)
            && !_isTimeSeries_SingleCol(dataset2) )
            throw new TypeError("dataset2: TimeSeries or Product (key), single-column");

        var isNumRange = Numbers.isInteger(range);
        if (   !(   isNumRange
            && range > 1 )
            && !IDate.isModifier(range))
            throw new TypeError("range: IDate.Modifier or Integer (2 or higher)");

        var corrOptions = _validCorrelationOptions((arguments.length > 3) ? options : {}),
            corrFn      = _getCorrelationFn(corrOptions),
            ts1         = _toCorrelationTs(dataset1, range, corrOptions),
            ts2         = _toCorrelationTs(dataset2, range, corrOptions),
            ts          = _correlationPrep(ts1, ts2, corrOptions, true),
            dateIdx     = ( (isNumRange || _isBackwardDateModifier(range))
                ? -1
                : 0 );

        var dateReducer = _newIndexBasedDateReducer(dateIdx);

        // Perform the correlation
        var rv = _rollingCalc(ts, range, dateReducer, corrFn, "correlation");

        // Make sure we don't return data caller didn't ask for.
        if (corrOptions.start_date !== null)
            rv = rv.slice(corrOptions.start_date);

        if (corrOptions.end_date !== null)
            rv = rv.slice(0, corrOptions.end_date);

        return rv;
    },

    /**
     * A convenience method to create personalized rolling calculations.
     *
     * @param {morn.TimeSeries} timeSeries
     * @param {(Integer|lim.IDate.Modifier)} sliceSize
     *        Size of each slice to be computed, reduced to a single row.
     *        If an integer, each slice contains <code>sliceSize</code> rows;
     *        <code>sliceSize</code> must be a positive integer.
     *
     *        If an IDate.Modifier, each slice is cut based on a starting date
     *        -- <code>dateAt(0), dateAt(1), dateAt(2)</code>, etc. --
     *        and ends at the date returned by <code>sliceSize(start_date)</code>,
     *        excluding that date from the slice.  Date modifiers must always return
     *        a valid date; backward modifiers are allowed.
     *
     * @param {(Integer|morn.TimeSeries.DateReducer)} dateReducer
     *        Date assigned to each slice.
     *        If an integer, each slice is assigned a date that results from
     *        <code>timeSeriesSlice.dateAt(sliceDate)</code>.
     *
     *        If a TimeSeries.DateReducer, the slice is assigned the date
     *        returned by <code>sliceDate(timeSeriesSlice)</code>.
     *
     *        If the date returned is <code>null</code>, the method skips
     *        over to the next slice.
     *
     * @param {morn.TimeSeries.NumericReducer} numReducer
     *        Calculation that transforms a TimeSeries slice into one or more
     *        number(s).
     *
     *        If <code>numReducer</code> returns null, the method
     *        skips over to the next slice.
     *
     * @param {string} [tag="rolling_calc"]
     *        Tag to be given to each data-point created by this method.
     *
     * @returns {morn.TimeSeries}
     *          The number of columns in the returned TimeSeries depends
     *          on how many number(s) are returned by <code>reducer</code>.
     * @see morn.TimeSeries#dateAt
     */
    rollingCalc: function (timeSeries, sliceSize, dateReducer, numReducer, tag) {
        return _fwdToInstance('rollingCalc', arguments);
    },

    /**
     * <p>
     *  Computes the regression-through-origin value.
     * </p>
     *
     * <p>
     *  This method ignores rows that contain NaN values.  It also ignores dates
     *  not found in both <code>y</code> and <code>x</code>.
     * </p>
     *
     * @param {morn.TimeSeries} y - Predictable outcome or dependent variable; one column.
     * @param {morn.TimeSeries} x - Explanatory or independent variables; one or more column(s).
     * @returns {morn.TimeSeries.LinearRegressionResults} Regression-through-the-origin details.
     * @see https://online.stat.psu.edu/~ajw13/stat501/SpecialTopics/Reg_thru_origin.pdf
     */
    regressionThroughOrigin: function (y, x) {
        return _regressionResults(y, x, lim.Math.regressionThroughOrigin);
    },

    /**
     * <p>
     *  Computes a linear regression, returning values <code>intercept</code> and
     *  <code>slopes</code> (one slope coefficient per independent variable.)
     * </p>
     *
     * <p>
     *  This method ignores rows that contain NaN values.  It also ignores dates
     *  not found in both <code>y</code> and <code>x</code>.
     * </p>
     *
     * @param {morn.TimeSeries} y - Predictable outcome or dependent variable; one column.
     * @param {morn.TimeSeries} x - Explanatory or independent variables; one or more column(s).
     * @returns {morn.TimeSeries.LinearRegressionResults} Linear-regression details.
     * @see https://en.wikipedia.org/wiki/Linear_regression
     */
    regressionLinear: function (y, x) {
        return _regressionResults(y, x, lim.Math.linearRegression);
    },

    /**
     * Generates predicted values based on input variables and
     * the results of {@link morn.TimeSeries.regressionLinear|regressionLinear()}.
     *
     * @param {morn.TimeSeries} x
     *        Explanatory or independent variables; number of columns
     *        must match the number of <code>linearRegr.slopes</code> values.
     * @param {morn.TimeSeries.LinearRegressionResults} linearRegr
     *        Results of a linear regression.
     * @returns {morn.TimeSeries} Single-column TimeSeries that represents the
     *          predicted values.
     */
    regressionLinearPrediction: function (x, linearRegr) {

        _validTimeSeries(x, 'x');

        var predictedVals = lim.Math.linearRegressionPrediction(x.columns(), linearRegr);

        // sanity check
        if (predictedVals.length !== x.length())
            throw new Error("IllegalArgumentException: number of predicted values mismatch input");

        var tag     = "linear_regression_pred",
            rows    = x._rows,
            numRows = rows.length,
            ts      = new TimeSeries(1),
            row, val;

        ts._tz = x._tz;

        for (var i = 0; i < numRows; i++) {

            row = rows[i];
            val = predictedVals[i];

            _addOneRow(ts, new Row(row._date, new DataPoint(val, tag, row._vals)));
        }

        return ts.freeze();
    },

    /**
     * Rounds all numbers within the TimeSeries to a given
     * number of decimals.  If <code>numDecimals</code> is negative,
     * this method rounds to tens (-1), hundreds (-2), thousands (-3), etc.
     *
     * The round method used is the most commonly used in mathematics:
     * half-down and half-up, with middle rounded up.
     *
     * Unlike MS-Excel, negative values are rounded in the same direction
     * as positive values.  Ex.: round(-2.5) -> -2.0
     *
     * @param timeSeries {morn.TimeSeries}
     * @param numDecimals {integer}
     * @param [tag] {string} Overwrites default tag.
     * @return {morn.TimeSeries}
     */
    round: function (timeSeries, numDecimals, tag) {
        return _fwdToInstance('round', arguments);
    },

    /**
     * Rounds all numbers within the TimeSeries to a given
     * number of decimals.  If <code>numDecimals</code> is negative,
     * this method rounds to tens (-1), hundreds (-2), thousands (-3), etc.
     *
     * The round method used is <em>round up</em>:
     * any remaining fraction is rounded up.
     *
     * Equivalent to MS-Excel ROUNDUP(). Ex.: roundUp(-2.5) -> -3.0
     *
     * @param timeSeries {morn.TimeSeries}
     * @param numDecimals {integer}
     * @param [tag] {string} Overwrites default tag.
     * @return {morn.TimeSeries}
     */
    roundUp: function (timeSeries, numDecimals, tag) {
        return _fwdToInstance('roundUp', arguments);
    },

    /**
     * Rounds all numbers within the TimeSeries to a given
     * number of decimals.  If <code>numDecimals</code> is negative,
     * this method rounds to tens (-1), hundreds (-2), thousands (-3), etc.
     *
     * The round method used is <em>round down</em>:
     * any remaining fraction is rounded down.
     *
     * Equivalent to MS-Excel ROUNDDOWN(). Ex.: roundDown(-2.5) -> -2.0
     *
     * @param timeSeries {morn.TimeSeries}
     * @param numDecimals {integer}
     * @param [tag] {string} Overwrites default tag.
     * @return {morn.TimeSeries}
     */
    roundDown: function (timeSeries, numDecimals, tag) {
        return _fwdToInstance('roundDown', arguments);
    },

    /**
     * Rounds all numbers within the TimeSeries to a given
     * number of decimals.  If <code>numDecimals</code> is negative,
     * this method rounds to tens (-1), hundreds (-2), thousands (-3), etc.
     *
     * The round method used is <em>round up</em>:
     * any remaining fraction is rounded up.
     *
     * Equivalent to MS-Excel CEILING(). Ex.: ceil(-2.5) -> -2.0
     *
     * @param timeSeries {morn.TimeSeries}
     * @param numDecimals {integer}
     * @param [tag] {string} Overwrites default tag.
     * @return {morn.TimeSeries}
     */
    ceil: function (timeSeries, numDecimals, tag) {
        return _fwdToInstance('ceil', arguments);
    },

    /**
     * Rounds all numbers within the TimeSeries to a given
     * number of decimals.  If <code>numDecimals</code> is negative,
     * this method rounds to tens (-1), hundreds (-2), thousands (-3), etc.
     *
     * The round method used is <em>round down</em>:
     * any remaining fraction is rounded down.
     *
     * Equivalent to MS-Excel FLOOR(). Ex.: floor(-2.5) -> -3.0
     *
     * @param timeSeries {morn.TimeSeries}
     * @param numDecimals {integer}
     * @param [tag] {string} Overwrites default tag.
     * @return {morn.TimeSeries}
     */
    floor: function (timeSeries, numDecimals, tag) {
        return _fwdToInstance('floor', arguments);
    },

    /**
     * Creates a new TimeSeries by computing an average
     * for each group of rows.  The groups are determined
     * by <code>groupingMethod</code>.
     *
     * @param timeSeries {morn.TimeSeries}
     * @param groupingMethod {morn.TimeSeries.DateGroupingMethod}
     *                       See {@link morn.TimeSeries.DateGroupingMethods} for pre-defined grouping methods.
     * @return {morn.TimeSeries}
     */
    groupByAverage: function (timeSeries, groupingMethod) {
        return _fwdToInstance('groupByAverage', arguments);
    },

    /**
     * Creates a new TimeSeries by computing a count
     * for each group of rows, determined by <code>groupingMethod</code>.
     *
     * @param timeSeries {morn.TimeSeries}
     * @param groupingMethod {morn.TimeSeries.DateGroupingMethod}
     *                       See {@link morn.TimeSeries.DateGroupingMethods} for pre-defined grouping methods.
     * @return {morn.TimeSeries}
     */
    groupByCount: function (timeSeries, groupingMethod) {
        return _fwdToInstance('groupByCount', arguments);
    },

    /**
     * Creates a new TimeSeries by computing a sum
     * for each group of rows, determined by <code>groupingMethod</code>.
     *
     * @param timeSeries {morn.TimeSeries}
     * @param groupingMethod {morn.TimeSeries.DateGroupingMethod}
     *                       See {@link morn.TimeSeries.DateGroupingMethods} for pre-defined grouping methods.
     * @return {morn.TimeSeries}
     */
    groupBySum: function (timeSeries, groupingMethod) {
        return _fwdToInstance('groupBySum', arguments);
    },

    /**
     * Creates a new TimeSeries by computing a minimum
     * for each group of rows, determined by <code>groupingMethod</code>.
     *
     * @param timeSeries {morn.TimeSeries}
     * @param groupingMethod {morn.TimeSeries.DateGroupingMethod}
     *                       See {@link morn.TimeSeries.DateGroupingMethods} for pre-defined grouping methods.
     * @return {morn.TimeSeries}
     */
    groupByMin: function (timeSeries, groupingMethod) {
        return _fwdToInstance('groupByMin', arguments);
    },

    /**
     * Creates a new TimeSeries by computing a maximum
     * for each group of rows, determined by <code>groupingMethod</code>.
     *
     * @param timeSeries {morn.TimeSeries}
     * @param groupingMethod {morn.TimeSeries.DateGroupingMethod}
     *                       See {@link morn.TimeSeries.DateGroupingMethods} for pre-defined grouping methods.
     * @return {morn.TimeSeries}
     */
    groupByMax: function (timeSeries, groupingMethod) {
        return _fwdToInstance('groupByMax', arguments);
    },

    /**
     * Creates a new TimeSeries by grouping rows together
     * - as per <code>groupingMethod</code>.  This method
     * computes a value for each column within each group,
     * using <code>reducer</code>.
     *
     * @param timeSeries {morn.TimeSeries}
     * @param groupingMethod {morn.TimeSeries.DateGroupingMethod}
     *                       See {@link morn.TimeSeries.DateGroupingMethods} for pre-defined grouping methods.
     * @param reducer {morn.TimeSeries.Reducer}
     * @param [tag="group_by"] {string}
     * @return {morn.TimeSeries}
     */
    groupBy: function (timeSeries, groupingMethod, reducer, tag) {
        return _fwdToInstance('groupBy', arguments);
    },


    /**
     * Creates a new TimeSeries by computing hourly averages
     * from the data within <code>timeSeries</code>.
     *
     * @param timeSeries {morn.TimeSeries}
     * @param [isHourEnding=false] {boolean} Whether to use hour-ending logic or not.
     *                            If <em>true</em>,
     *                            {@link morn.TimeSeries.DateGroupingMethods.HOUR_ENDING_ON_NEXT|HOUR_ENDING_ON_NEXT}
     *                            is used.
     * @return {morn.TimeSeries}
     */
    hourlyAverage: function (timeSeries, isHourEnding) {
        return _fwdToInstance('hourlyAverage', arguments);
    },

    /**
     * Creates a new TimeSeries by computing hourly countS
     * (of numeric data-points) from the data within<code>timeSeries</code>.
     *
     * @param timeSeries {morn.TimeSeries}
     * @param [isHourEnding=false] {boolean} Whether to use hour-ending logic or not.
     *                            If <em>true</em>,
     *                            {@link morn.TimeSeries.DateGroupingMethods.HOUR_ENDING_ON_NEXT|HOUR_ENDING_ON_NEXT}
     *                            is used.
     * @return {morn.TimeSeries}
     */
    hourlyCount: function (timeSeries, isHourEnding) {
        return _fwdToInstance('hourlyCount', arguments);
    },

    /**
     * Creates a new TimeSeries by computing daily averages
     * from the data within <code>timeSeries</code>.
     *
     * @param timeSeries {morn.TimeSeries}
     * @param [isDayEnding=false] {boolean} Whether to use day-ending logic or not.
     *                            If <em>true</em>,
     *                            {@link morn.TimeSeries.DateGroupingMethods.DAY_ENDING_ON_PREVIOUS|DAY_ENDING_ON_PREVIOUS}
     *                            is used.
     * @return {morn.TimeSeries}
     */
    dailyAverage: function (timeSeries, isDayEnding) {
        return _fwdToInstance('dailyAverage', arguments);
    },

    /**
     * Creates a new TimeSeries by computing daily counts
     * (of numeric data-points) from the data within <code>timeSeries</code>.
     *
     * @param timeSeries {morn.TimeSeries}
     * @param [isDayEnding=false] {boolean} Whether to use day-ending logic or not.
     *                            If <em>true</em>,
     *                            {@link morn.TimeSeries.DateGroupingMethods.DAY_ENDING_ON_PREVIOUS|DAY_ENDING_ON_PREVIOUS}
     *                            is used.
     * @return {morn.TimeSeries}
     */
    dailyCount: function (timeSeries, isDayEnding) {
        return _fwdToInstance('dailyCount', arguments);
    },

    /**
     * Creates a new TimeSeries by computing monthly averages
     * from the data within <code>timeSeries</code>.
     *
     * @param timeSeries {morn.TimeSeries}
     * @param [isMonthEnding=false] {boolean} Whether to use month-ending logic or not.
     *                            If <em>true</em>,
     *                            {@link morn.TimeSeries.DateGroupingMethods.MONTH_ENDING_ON_PREVIOUS|MONTH_ENDING_ON_PREVIOUS}
     *                            is used.
     * @return {morn.TimeSeries}
     */
    monthlyAverage: function (timeSeries, isMonthEnding) {
        return _fwdToInstance('monthlyAverage', arguments);
    },

    /**
     * Creates a new TimeSeries by computing monthly counts
     * (of numeric data-points) from the data within <code>timeSeries</code>.
     *
     * @param timeSeries {morn.TimeSeries}
     * @param [isMonthEnding=false] {boolean} Whether to use month-ending logic or not.
     *                            If <em>true</em>,
     *                            {@link morn.TimeSeries.DateGroupingMethods.MONTH_ENDING_ON_PREVIOUS|MONTH_ENDING_ON_PREVIOUS}
     *                            is used.
     * @return {morn.TimeSeries}
     */
    monthlyCount: function (timeSeries, isMonthEnding) {
        return _fwdToInstance('monthlyCount', arguments);
    },

    /**
     * Shapes, or adjusts all data-points within the given TimeSeries.
     * The adjustment can be relative or absolute.
     * Optionally, <code>tag</code> can be provided to overwrite the default
     * one.
     *
     * @param timeSeries {morn.TimeSeries}
     * @param adj {(string|number)} For relative adjustments, use a String
     *            argument ("+10%", "+1.3", "-2.5", etc.)  For absolute adjustments,
     *            simply provide the number to use, as a Number.
     * @param [tag] {string} Overwrites default tag.
     * @return {morn.TimeSeries}
     */
    shapeAll: function (timeSeries, adj, tag) {
        return _fwdToInstance('shapeAll', arguments);
    },

    /**
     * Shapes, or adjusts a period within the given TimeSeries.
     * The adjustment can be relative or absolute.  The period is defined
     * by <code>start</code> and <code>end</code>; both are inclusive.
     * Optionally, <code>tag</code> can be provided to overwrite the default
     * one.
     *
     * @param timeSeries {morn.TimeSeries}
     * @param adj {(string|number)} For relative adjustments, use a String
     *            argument ("+10%", "+1.3", "-2.5", etc.)  For absolute adjustments,
     *            simply provide the number to use, as a Number.
     * @param start {(lim.IDate|string)} The start of the period to shape (inclusive.)
     * @param end {(lim.IDate|string)} The end of the period to shape (inclusive.)
     * @param [tag] {string} Overwrites default tag.
     * @return {morn.TimeSeries}
     */
    shapePeriod: function (timeSeries, adj, start, end, tag) {
        return _fwdToInstance('shapePeriod', arguments);
    },

    /**
     * Shapes, or adjusts data-points that pertain to the specified month(s)
     * within the given TimeSeries.
     * The adjustment can be relative or absolute.  The months are specified
     * as an array of Integer (0-11).
     * Optionally, <code>tag</code> can be provided to overwrite the default
     * one.
     *
     * @param timeSeries {morn.TimeSeries}
     * @param adj {(string|number)} For relative adjustments, use a String
     *            argument ("+10%", "+1.3", "-2.5", etc.)  For absolute adjustments,
     *            simply provide the number to use, as a Number.
     * @param months {integer[]} 0=Jan, 1=Feb, ..., 11=Dec.
     * @param [tag] {string} Overwrites default tag.
     * @return {morn.TimeSeries}
     */
    shapeMonths: function (timeSeries, adj, months, tag) {
        return _fwdToInstance('shapeMonths', arguments);
    },


    /**
     * Shapes, or adjusts a particular data-point within this TimeSeries.
     * The adjustment can be relative or absolute.  The data-point is defined
     * by <code>date</code>.
     * Optionally, <code>tag</code> can be provided to overwrite the default
     * one.
     *
     * @param timeSeries {morn.TimeSeries}
     * @param adj {(string|number)} For relative adjustments, use a String
     *            argument ("+10%", "+1.3", "-2.5", etc.)  For absolute adjustments,
     *            simply provide the number to use, as a Number.
     * @param date {(lim.IDate|string)} The date of the data-point to adjust.
     * @param [tag] {string} Overwrites default tag.
     * @return {morn.TimeSeries}
     */
    shapePoint: function (timeSeries, adj, date, tag) {
        return _fwdToInstance('shapePoint', arguments);
    },

    /**
     * Computes the standard moving average within each column.
     * @param {morn.TimeSeries} timeSeries
     * @param {Integer} numPoints
     * @return {morn.TimeSeries}
     */
    standardMovingAverage: function (timeSeries, numPoints) {
        return _fwdToInstance('standardMovingAverage', arguments);
    },

    /**
     * <p>
     *  Computes the <em>change</em> over <code>numPoints</code>,
     *  for each column, and returns a new TimeSeries containing
     *  the computed values.  <em>Change</em> is calculated by
     *  subtracting the price from <code>numPoints</code> ago to
     *  the current price, as in:
     * </p>
     *
     * <p>
     *     <code>CURRENT_PRICE - PREV_PRICE</code>
     * </p>
     *
     * @param timeSeries {morn.TimeSeries}
     * @param {Integer} [numPoints=1]
     * @return {morn.TimeSeries}
     */
    change: function (timeSeries, numPoints) {
        return _fwdToInstance('change', arguments);
    },

    /**
     * <p>
     *  Computes the <em>percent change</em> over <code>numPoints</code>,
     *  for each column, and returns a new TimeSeries containing
     *  the computed values.  <em>Percent change</em> - or %chg -
     *  is calculated by subtracting the price from <code>numPoints</code>
     *  ago to the current price, dividing that difference by that same
     *  previous price, as in:
     * </p>
     *
     * <p>
     *     <code>( (CURRENT_PRICE - PREV_PRICE) / abs(PREV_PRICE) ) * 100</code>
     * </p>
     *
     * @param timeSeries {morn.TimeSeries}
     * @param {Integer} [numPoints=1]
     * @return {morn.TimeSeries}
     */
    changePercent: function (timeSeries, numPoints) {
        return _fwdToInstance('changePercent', arguments);
    },

    /**
     * <p>
     *  Computes the <em>percent change</em> over <code>numPoints</code>,
     *  for each column, and returns a new TimeSeries containing
     *  the computed values.  <em>Percent change</em> - or %chg -
     *  is calculated by subtracting the price from <code>numPoints</code>
     *  ago to the current price, dividing that difference by that same
     *  previous price, as in:
     * </p>
     *
     * <p>
     *     <code>( (CURRENT_PRICE - PREV_PRICE) / PREV_PRICE ) * 100</code>
     * </p>
     *
     * @param timeSeries {morn.TimeSeries}
     * @param {Integer} [numPoints=1]
     * @return {morn.TimeSeries}
     */
    changePercentRaw: function (timeSeries, numPoints) {
        return _fwdToInstance('changePercentRaw', arguments);
    },

    /**
     * <p>
     *  Computes the <em>relative change</em> over <code>numPoints</code>,
     *  for each column, and returns a new TimeSeries containing
     *  the computed values.  <em>Relative change</em> - or %chg / 100 -
     *  is calculated by subtracting the price from <code>numPoints</code>
     *  ago to the current price, dividing that difference by that same
     *  previous price, as in:
     * </p>
     *
     * <p>
     *     <code>(CURRENT_PRICE - PREV_PRICE) / abs(PREV_PRICE)</code>
     * </p>
     *
     * @param timeSeries {morn.TimeSeries}
     * @param {Integer} [numPoints=1]
     * @return {morn.TimeSeries}
     */
    changeRatio: function (timeSeries, numPoints) {
        return _fwdToInstance('changeRatio', arguments);
    },

    /**
     * <p>
     *  Computes the <em>relative change</em> over <code>numPoints</code>,
     *  for each column, and returns a new TimeSeries containing
     *  the computed values.  <em>Relative change</em> - or %chg / 100 -
     *  is calculated by subtracting the price from <code>numPoints</code>
     *  ago to the current price, dividing that difference by that same
     *  previous price, as in:
     * </p>
     *
     * <p>
     *     <code>(CURRENT_PRICE - PREV_PRICE) / PREV_PRICE</code>
     * </p>
     *
     * @param timeSeries {morn.TimeSeries}
     * @param {Integer} [numPoints=1]
     * @return {morn.TimeSeries}
     */
    changeRatioRaw: function (timeSeries, numPoints) {
        return _fwdToInstance('changeRatioRaw', arguments);
    },

    /**
     * <p>
     *  Computes the <em>return</em> over <code>numPoints</code>,
     *  for each column, and returns a new TimeSeries containing
     *  the computed values.  <em>Return</em> is calculated as
     *  the natural logarithmic of the current price over the previous
     *  price (from <code>numPoints</code> ago), as in:
     * </p>
     *
     * <p>
     *     <code>Math.log(CURRENT_PRICE / PREV_PRICE)</code>
     * </p>
     *
     * @param timeSeries {morn.TimeSeries}
     * @param {Integer} [numPoints=1]
     * @return {morn.TimeSeries}
     */
    changeReturn: function (timeSeries, numPoints) {
        return _fwdToInstance('changeReturn', arguments);
    },

    /**
     * Returns rows where the first column from <code>timeSeries</code>
     * crosses over or under the 2nd column.  Optionally,
     * <code>numBefore</code> and <code>numAfter</code> can be
     * used to expand the selection around rows that apply.
     *
     * @param timeSeries {morn.TimeSeries}
     * @param [numBefore=0] {integer}
     * @param [numAfter] {integer} Default is <code>numBefore</code>
     *                 if provided, otherwise default is <em>0</em>.
     * @return {morn.TimeSeries}
     */
    crossing: function (timeSeries, numBefore, numAfter) {
        return _fwdToInstance('crossing', arguments);
    },

    /**
     * Returns rows where the first column from <code>timeSeries</code>
     * crosses over the 2nd column.  Optionally,
     * <code>numBefore</code> and <code>numAfter</code> can be
     * used to expand the selection around rows that apply.
     *
     * @param timeSeries {morn.TimeSeries}
     * @param [numBefore=0] {integer}
     * @param [numAfter] {integer} Default is <code>numBefore</code>
     *                 if provided, otherwise default is <em>0</em>.
     * @return {morn.TimeSeries}
     */
    crossingOver: function (timeSeries, numBefore, numAfter) {
        return _fwdToInstance('crossingOver', arguments);
    },

    /**
     * Returns rows where the first column from <code>timeSeries</code>
     * crosses under the 2nd column.  Optionally,
     * <code>numBefore</code> and <code>numAfter</code> can be
     * used to expand the selection around rows that apply.
     *
     * @param timeSeries {morn.TimeSeries}
     * @param [numBefore=0] {integer}
     * @param [numAfter] {integer} Default is <code>numBefore</code>
     *                 if provided, otherwise default is <em>0</em>.
     * @return {morn.TimeSeries}
     */
    crossingUnder: function (timeSeries, numBefore, numAfter) {
        return _fwdToInstance('crossingUnder', arguments);
    },

    /**
     * <p>
     *  Creates a 1-column TimeSeries containing the count of
     *  valid "numbers" for each row within all TimeSeries provided.
     *  NaNs are not counted, all others count as <em>1</em>.
     * </p>
     *
     * <p>
     *  For each row, if a NaN is found, this method looks for
     *  the previous number within the same column, and uses it if found.
     * </p>
     *
     * @param timeSeries {...morn.TimeSeries}
     * @return {morn.TimeSeries}
     */
    rowCount: function (timeSeries) {
        return _reduceCols(arguments, "count", Reducer.COUNT, true, false);
    },

    /**
     * <p>
     *  Creates a 1-column TimeSeries containing the sum of
     *  valid "numbers" for each row within all TimeSeries provided.
     *  NaNs are treated as <em>0</em>.
     * </p>
     *
     * <p>
     *  For each row, if a NaN is found, this method looks for
     *  the previous number within the same column, and uses it if found.
     * </p>
     *
     * <p>
     *  By default, if no previous number is found for a column, zero (0)
     *  is assumed.  Use the <code>skipNaNs</code> argument
     *  to change this default.
     * </p>
     *
     * @param [skipNaNs] {boolean} Set to <em>false</em> so that the result of a row
     *                   is NaN upon seeing any NaN within that row.
     * @param timeSeries {...morn.TimeSeries}
     * @return {morn.TimeSeries}
     * @see {morn.TimeSeries.rowAdd}
     */
    rowSum: function (timeSeries) {
        return _reduceCols(arguments, "sum", Reducer.SUM, true, true);
    },

    /**
     * <p>
     *  Creates a 1-column TimeSeries containing the sum of all columns
     *  for each row within all TimeSeries provided.
     * </p>
     *
     * <p>
     *  For each row, if a NaN is found, this method looks for
     *  the previous number within the same column, and uses it if found.
     * </p>
     *
     * <p>
     *  By default, if a valid number cannot be found by looking in the past, that row
     *  results in <em>NaN</em>.  Use the <code>skipNaNs</code> argument
     *  to change this default.
     * </p>
     *
     * @param [skipNaNs] {boolean} Set to <em>true</em> so that NaN values are
     *                   ignored (replaced with zeros).
     * @param timeSeries {...morn.TimeSeries}
     * @return {morn.TimeSeries}
     * @see {morn.TimeSeries.rowSum}
     */
    rowAdd: function (timeSeries) {
        return _reduceCols(arguments, "add", Reducer.SUM, false, true);
    },

    /**
     * <p>
     *  Creates a 1-column TimeSeries containing the result of subtracting
     *  the sum of columns 1-n from column 0, for each row within all
     *  TimeSeries provided.
     * </p>
     *
     * <p>
     *  For each row, if a NaN is found, this method looks for
     *  the previous number within the same column, and uses it if found.
     * </p>
     *
     * <p>
     *  By default, if a valid number cannot be found by looking in the past, that row
     *  results in <em>NaN</em>.  Use the <code>skipNaNs</code> argument
     *  to change this default.
     * </p>
     *
     * @param [skipNaNs] {boolean} Set to <em>true</em> so that NaN values are ignored.
     * @param timeSeries {...morn.TimeSeries}
     * @return {morn.TimeSeries}
     */
    rowSubtract: function (timeSeries) {
        return _reduceCols(arguments, "subtract", Reducer.DIFFERENCE, false, true);
    },

    /**
     * <p>
     *  Creates a 1-column TimeSeries containing the result of multiplying
     *  all columns within each row, for all TimeSeries provided.
     * </p>
     *
     * <p>
     *  For each row, if a NaN is found, this method looks for
     *  the previous number within the same column, and uses it if found.
     * </p>
     *
     * <p>
     *  By default, if a valid number cannot be found by looking in the past, that row
     *  results in <em>NaN</em>.  Use the <code>skipNaNs</code> argument
     *  to change this default.
     * </p>
     *
     * @param [skipNaNs] {boolean} Set to <em>true</em> so that NaN values are ignored.
     * @param timeSeries {...morn.TimeSeries}
     * @return {morn.TimeSeries}
     */
    rowMultiply: function (timeSeries) {
        return _reduceCols(arguments, "multiply", Reducer.PRODUCT, false, true);
    },

    /**
     * <p>
     *  Creates a 1-column TimeSeries containing the result of dividing
     *  the value of column 0 by the value of each subsequent columns,
     *  for each row within all TimeSeries provided.
     * </p>
     *
     * <p>
     *  For each row, if a NaN is found, this method looks for
     *  the previous number within the same column, and uses it if found.
     * </p>
     *
     * <p>
     *  By default, if a valid number cannot be found by looking in the past, that row
     *  results in <em>NaN</em>.  Use the <code>skipNaNs</code> argument
     *  to change this default.
     * </p>
     *
     * @param [skipNaNs] {boolean} Set to <em>true</em> so that NaN values are ignored.
     * @param timeSeries {...morn.TimeSeries}
     * @return {morn.TimeSeries}
     */
    rowDivide: function (timeSeries) {
        return _reduceCols(arguments, "divide", Reducer.QUOTIENT, false, true);
    },

    /**
     * <p>
     *  Creates a 1-column TimeSeries containing the lowest value
     *  found within each row of all TimeSeries provided.
     * </p>
     *
     * <p>
     *  For each row, if a NaN is found, this method looks for
     *  the previous number within the same column, and uses it if found.
     * </p>
     *
     * <p>
     *  By default, if a valid number cannot be found by looking in the past, that row
     *  results in <em>NaN</em>.  Use the <code>skipNaNs</code> argument
     *  to change this default.
     * </p>
     *
     * @param [skipNaNs] {boolean} Set to <em>true</em> so that NaN values are ignored.
     * @param timeSeries {...morn.TimeSeries}
     * @return {morn.TimeSeries}
     */
    rowMin: function (timeSeries) {
        return _reduceCols(arguments, "min", Reducer.MINIMUM, false, true);
    },

    /**
     * <p>
     *  Creates a 1-column TimeSeries containing the highest value
     *  found within each row of all TimeSeries provided.
     * </p>
     *
     * <p>
     *  For each row, if a NaN is found, this method looks for
     *  the previous number within the same column, and uses it if found.
     * </p>
     *
     * <p>
     *  By default, if a valid number cannot be found by looking in the past, that row
     *  results in <em>NaN</em>.  Use the <code>skipNaNs</code> argument
     *  to change this default.
     * </p>
     *
     * @param [skipNaNs] {boolean} Set to <em>true</em> so that NaN values are ignored.
     * @param timeSeries {...morn.TimeSeries}
     * @return {morn.TimeSeries}
     */
    rowMax: function (timeSeries) {
        return _reduceCols(arguments, "max", Reducer.MAXIMUM, false, true);
    },

    /**
     * <p>
     *  Creates a 1-column TimeSeries containing the spread between
     *  the highest and lowest values found within each row of
     *  all TimeSeries provided.
     * </p>
     *
     * <p>
     *  For each row, if a NaN is found, this method looks for
     *  the previous number within the same column, and uses it if found.
     * </p>
     *
     * <p>
     *  By default, if a valid number cannot be found by looking in the past, that row
     *  results in <em>NaN</em>.  Use the <code>skipNaNs</code> argument
     *  to change this default.
     * </p>
     *
     * @param [skipNaNs] {boolean} Set to <em>true</em> so that NaN values are ignored.
     * @param timeSeries {...morn.TimeSeries}
     * @return {morn.TimeSeries}
     */
    rowSpread: function (timeSeries) {
        return _reduceCols(arguments, "spread", Reducer.SPREAD, false, true);
    },

    /**
     * <p>
     *  Creates a 1-column TimeSeries containing the average of all
     *  columns for each row of all TimeSeries provided.
     * </p>
     *
     * <p>
     *  For each row, if a NaN is found, this method looks for
     *  the previous number within the same column, and uses it if found.
     * </p>
     *
     * <p>
     *  By default, if a valid number cannot be found by looking in the past, that row
     *  results in <em>NaN</em>.  Use the <code>skipNaNs</code> argument
     *  to change this default.
     * </p>
     *
     * @param [skipNaNs] {boolean} Set to <em>true</em> so that NaN values are ignored.
     * @param timeSeries {...morn.TimeSeries}
     * @return {morn.TimeSeries}
     */
    rowAverage: function (timeSeries) {
        return _reduceCols(arguments, "average", Reducer.AVERAGE, false, true);
    },


    /**
     * Calculates the sum (a.k.a. addition)
     * for each cell in all TimeSeries provided.
     * This method only returns rows (dates) that exist in all TimeSeries.
     * All TimeSeries sizes must match one another, or equal 1.
     *
     * @param {...(morn.TimeSeries|number)} addend Arguments to add, must contain
     *        at least one TimeSeries.
     * @return {morn.TimeSeries}
     */
    cellSum: function (addend) {
        return _cellMath(arguments, "cell_sum", _arithmetic.add);
    },

    /**
     * Calculates the difference (a.k.a. subtraction)
     * for each cell in all TimeSeries provided.
     * This method only returns rows (dates) that exist in all TimeSeries.
     * All TimeSeries sizes must match one another, or equal 1.
     *
     * @param {...(morn.TimeSeries|number)} subtrahend Arguments to subtract, from left
     *        to right.  Argument list must contain at least one TimeSeries.
     * @return {morn.TimeSeries}
     */
    cellDiff: function (subtrahend) {
        return _cellMath(arguments, "cell_difference", _arithmetic.subtract);
    },

    /**
     * Calculates the product (a.k.a. multiplication)
     * for each cell in all TimeSeries provided.
     * This method only returns rows (dates) that exist in all TimeSeries.
     * All TimeSeries sizes must match one another, or equal 1.
     *
     * @param {...(morn.TimeSeries|number)} multiplier Arguments to multiply, must contain
     *        at least one TimeSeries.
     * @return {morn.TimeSeries}
     */
    cellProduct: function (multiplier) {
        return _cellMath(arguments, "cell_product", _arithmetic.multiply);
    },

    /**
     * Calculates the quotient (a.k.a. division)
     * for each cell in all TimeSeries provided.
     * This method only returns rows (dates) that exist in all TimeSeries.
     * All TimeSeries sizes must match one another, or equal 1.
     *
     * @param {...(morn.TimeSeries|number)} divisor Arguments to divide, from left
     *        to right.  Argument list must contain at least one TimeSeries.
     * @return {morn.TimeSeries}
     */
    cellQuotient: function (divisor) {
        return _cellMath(arguments, "cell_quotient", _arithmetic.divide);
    },

    /**
     * Calculates the product from raising each cell in a TimeSeries to the power
     * of the next value (number or TimeSeries).
     * The <em>base</em> comes from first argument; exponent(s) come from the subsequent
     * argument(s).
     *
     * If TimeSeries, cells are matched across TimeSeries; the resulting
     * TimeSeries only contains rows (dates) that exist in all
     * TimeSeries. All TimeSeries sizes must match one another, or equal 1.
     *
     * @param {...(morn.TimeSeries|number)} power Arguments to raise to a power.
     * @return {morn.TimeSeries}
     *
     * @example
     *       TimeSeries.cellPower(ts, 2)
     *       => A TimeSeries containing the square value for each data-point from \`ts\`.
     */
    cellPower: function (power) {
        return _cellMath(arguments, "cell_quotient", _arithmetic.power);
    },

    /**
     * Creates a new TimeSeries containing exponential weight
     * assigned to each date; recent dates are given more weight.
     *
     * @param {(morn.TimeSeries|morn.Calendar|lim.IDate[])} dates
     * @param {number} lambda - Range 0-1, inclusive
     * @returns {morn.TimeSeries}
     * @example
     *     var dates = [
     *             as.date('2016-01-01'),
     *             as.date('2016-02-01'),
     *             as.date('2016-03-01'),
     *             as.date('2016-04-01')
     *     ];
     *     TimeSeries.exponentialWeight(dates, 0.94);
     *         => 2016-01-01,0.04983504
     *            2016-02-01,0.053016
     *            2016-03-01,0.0564
     *            2016-04-01,0.06
     */
    exponentialWeight: function (dates, lambda) {

        var aDates = _toDateArray(dates, "dates: TimeSeries, Calendar or IDate[]", TimeSeries, Calendar);

        if (   !_isFiniteNumber(lambda)
            || lambda < 0
            || lambda > 1 ) {
            throw new TypeError("lambda: Number, range 0-1 (inclusive)");
        }

        var datesSorted = _cloneAndSortDates(aDates);
        var w = 1 - lambda;

        /** @type {morn.TimeSeries.Row[]} */
        var rows = new Array(datesSorted.length);
        for (var i = datesSorted.length - 1; i >= 0; i--) {
            rows[i] = new Row(datesSorted[i], new DataPoint(w, "exponential_weight"));
            w *= lambda;
        }

        var ts = new TimeSeries(1);
        _addRowsSorted(ts, rows);
        return ts.freeze();
    },

    /**
     * Returns a CSV representation of the specified TimeSeries, in which
     * each row starts with an ISO formatted date (yyyy-MM-ddTHH:mm:ss.SSS)
     * followed one column for each numeric values of each column.
     *
     * If <code>includeTrace</code> is true, the columns after the date
     * alternate between numeric value value and data-point <em>trace</em>.
     *
     * For example, given a TimeSeries with 3 columns, the output of the
     * function called without argument (or <em>false</em> argument) will be:
     *
     * column[0]: date, yyyy-MM-ddTHH:mm:ss.SSS
     * column[1]: number, column #1
     * column[2]: number, column #2
     * column[3]: number, column #3
     *
     * The same TimeSeries would result in the following output if this
     * method was called with a <em>true</em> argument:
     *
     * column[0]: date, yyyy-MM-ddTHH:mm:ss.SSS
     * column[1]: number, column #1
     * column[2]: string, column #1 mutation history (or trace)
     * column[3]: number, column #2
     * column[4]: string, column #2 mutation history (or trace)
     * column[5]: number, column #3
     * column[6]: string, column #3 mutation history (or trace)
     *
     * @param [includeTrace=false] {boolean}
     * @return {string}
     */
    toCsv: function (timeSeries, includeTrace) {
        return _fwdToInstance('toCsv', arguments);
    },

    /**
     * Returns a stringified JSON object representing
     * the specified TimeSeries.  The content of the TimeSeries
     * is represented as an array of row objects, where each
     * row has a (stringified) date property and a values
     * array, which in turn lists the values and their trace.
     *
     * Sample output for a 2-column TimeSeries (beautified for clarity):
     *
     * [{
     *     "date": "2014-08-01T00:00:00.000",
     *     "values": [{
     *         "value": 17.755,
     *         "trace": "G0BM;Aug14"
     *     }, {
     *         "value": "NaN",
     *         "trace": "NaN"
     *     }]
     * }, {
     *     "date": "2014-09-01T00:00:00.000",
     *     "values": [{
     *         "value": 18.192,
     *         "trace": "G0BM;Sep14"
     *     }, {
     *         "value": "NaN",
     *         "trace": "NaN"
     *     }]
     * }, {
     *     "date": "2014-10-01T00:00:00.000",
     *     "values": [{
     *         "value": 21.277,
     *         "trace": "G0BM;Oct14"
     *     }, {
     *         "value": "NaN",
     *         "trace": "NaN"
     *     }]
     * }]
     *
     * @return {string}
     */
    toJsonString: function (timeSeries) {
        return _fwdToInstance('toJsonString', arguments);
    },

    /**
     * Returns a CDF representation of the given TimeSeries, in which
     * each row is assigned the same key values as specified
     * in <code>keyValues</code>.
     *
     * The given TimeSeries <code>size()</code> must match
     * <code>columns.length</code>.
     *
     * @param timeSeries {morn.TimeSeries}
     * @param keyValues {Object} The keys and values for these numbers.
     * @param columns {(string|string[])} A name for each of the column.
     *                  Multiple column names can still be specified
     *                  using a single string value, using commas to separate
     *                  the columns.
     * @return {string}
     */
    toCdf: function (timeSeries, keyValues, columns) {
        return _fwdToInstance('toCdf', arguments);
    },


    /**
     * <p>
     *  Retrieves a TimeSeries in the server's local time zone.
     * </p>
     *
     * <p>
     *  The first three arguments - feed, keyValues and columns -
     *  can be collapsed into one {@link morn.Product|Product} argument.
     *  When invoked in this manner, this method requires 1 argument
     *  only (4 at most).
     * </p>
     *
     * @param {string} feed Name of feed from which to pull data.
     * @param {Object.<string, string>} keyValues Key-values mapping to one series.
     * @param {(string|string[])} columns - A name for each of the column.
     *                  Multiple column names can still be specified
     *                  using a single string value, using commas to separate
     *                  the columns.
     * @param {?(lim.IDate|string)} [startDate]
     * @param {?(lim.IDate|string)} [endDate]
     * @param {Integer} [maxResults=-1]
     * @return {morn.TimeSeries}
     */
    getServerData: function ( feed,
                              keyValues,
                              columns,
                              startDate,
                              endDate,
                              maxResults ) {
        var args = _toProduct(arguments);
        args.splice(1, 0, null);  // Insert \`null\` timeZone
        return _getServerData.apply(this, args);
    },

    /**
     * <p>
     *  Retrieves a TimeSeries for corrections in the server's local time zone.
     * </p>
     *
     * <p>
     *  The first three arguments - feed, keyValues and columns -
     *  can be collapsed into one {@link morn.Product|Product} argument.
     *  When invoked in this manner, this method requires 1 argument
     *  only (4 at most).
     * </p>
     *
     * @param {string} feed Name of feed from which to pull data.
     * @param {Object.<string, string>} keyValues Key-values mapping to one series.
     * @param {(string|string[])} columns - A name for each of the column.
     *                  Multiple column names can still be specified
     *                  using a single string value, using commas to separate
     *                  the columns.
     * @param {?(lim.TimeZone|string)} timeZone
     * @param {?(lim.IDate|string)} [startDate]
     * @param {?(lim.IDate|string)} [endDate]
     * @param {?(lim.IDate|string)} [fromInsertionDate]
     * @param {Integer} [maxResults=-1]
     * @return {morn.TimeSeries}
     */
    getCorrectionData: function ( feed,
                                  keyValues,
                                  columns,
                                  timeZone,
                                  startDate,
                                  endDate,
                                  fromInsertionDate,
                                  maxResults ) {
        var args = _toProduct(arguments);
        // args.splice(1, 0, null);  // Insert \`null\` timeZone
        return _getCorrectionData.apply(this, args);
    },

    /**
     * <p>
     *  Retrieves a TimeSeries in the requested time zone.
     * </p>
     *
     * <p>
     *  The first three arguments - feed, keyValues and columns -
     *  can be collapsed into one {@link morn.Product|Product} argument.
     *  When invoked in this manner, this method requires 2 arguments
     *  only (5 at most).
     * </p>
     *
     * @param {string} feed Name of feed from which to pull data.
     * @param {Object.<string, string>} keyValues Key-values mapping to one series.
     * @param {(string|string[])} columns - A name for each of the column.
     *                  Multiple column names can still be specified
     *                  using a single string value, using commas to separate
     *                  the columns.
     * @param {?(lim.TimeZone|string)} timeZone
     * @param {?(lim.IDate|string)} [startDate]
     * @param {?(lim.IDate|string)} [endDate]
     * @param {Integer} [maxResults=-1]
     * @return {morn.TimeSeries}
     */
    getServerDataInTimeZone: function ( feed,
                                        keyValues,
                                        columns,
                                        timeZone,
                                        startDate,
                                        endDate,
                                        maxResults ) {
        return _getServerData.apply(this, _toProduct(arguments));
    },

    /**
     * Retrieves multiple TimeSeries.
     * @param {string} feed Name of feed from which to pull data.
     * @param {Array.<Object.<string, string>>} keys List of key-values.  All objects in
     *        the list must contain all key-names defined in the given feed.
     * @param {string[]} columns - A name for each column to retrieve.
     *                  Multiple column names can still be specified
     *                  using a single string value, using commas to separate
     *                  the columns.
     * @param {?(lim.IDate|string)} [startDate]
     * @param {?(lim.IDate|string)} [endDate]
     * @param {Integer} [maxResults=-1]
     * @returns {morn.TimeSeries[]}
     */
    getServerDataMulti: function (
        feed,
        keys,
        columns,
        startDate,
        endDate,
        maxResults ) {
        var args = Arrays.slice(arguments);
        args.splice(3, 0, null);  // Insert \`null\` timeZone
        return _getServerDataMulti.apply(this, args);
    },

    /**
     * Retrieves multiple TimeSeries, with all dates being in the given time-zone.
     * @param {string} feed Name of feed from which to pull data.
     * @param {Array.<Object.<string, string>>} keys List of key-values.  All objects in
     *        the list must contain all key-names defined in the given feed.
     * @param {string[]} columns - A name for each column to retrieve.
     *                  Multiple column names can still be specified
     *                  using a single string value, using commas to separate
     *                  the columns.
     * @param {?(lim.TimeZone|string)} timeZone
     * @param {?(lim.IDate|string)} [startDate]
     * @param {?(lim.IDate|string)} [endDate]
     * @param {Integer} [maxResults=-1]
     * @returns {morn.TimeSeries[]}
     */
    getServerDataMultiInTimeZone: function (
        feed,
        keys,
        columns,
        startDate,
        endDate,
        maxResults ) {
        return _getServerDataMulti.apply(this, arguments);
    }
});


TimeSeries.Row                 = Row;
TimeSeries.DataPoint           = DataPoint;
TimeSeries.DateGroupingMethods = Object.freeze(GroupByDate);
TimeSeries.Reducer             = Reducer;

Object.freeze(TimeSeries);
Object.freeze(TimeSeries.prototype);


/* *************************************************
  * PUBLIC CONSTRUCTORS
  * > TimeSeries
  * > TimeSeries.Row
  * > TimeSeries.DataPoint
  * ************************************************* */
morn.TimeSeries = TimeSeries;

/**
 * The (parsed) arguments that were given in order to create a
 * forward curve.
 *
 * @typedef {Object} ForwardCurveArguments
 * @property {morn.Product} product - Feed, root(s) and columns.
 * @property {lim.IDate} curveDate - Date of curve.
 * @property {morn.DeliveryType} deliveryType - Frequency of curve data-points (months, days, etc.)
 * @property {?lim.IDate} asOfDate - Use values as of the given date, if provided.
 * @property {Object[]} argsReorg - Re-organized argument values, starting with product, followed by
 *           all original arguments in their raw form.
 */

/**
 * A callback function that returns a number of units calculated from
 * a period defined by start and end dates (i.e. low and high dates).
 *
 * @callback morn.UnitCalculator
 * @param {lim.IDate} start
 * @param {lim.IDate} end
 * @returns {number} Number of units to associate with the given period. Likely an
 *          integer but floats (aka decimals) are supported.
 */

/**
 * Returns a weighted value based on rounded duration of each contract's
 * delivery period.  Each sub-contract delivery period is rounded to a
 * rough fraction of the higher contract.
 *
 * @callback DerivedValueCalculator
 * @param {morn.ContractValue} higherCV
 * @param {morn.ContractValue[]} subContractVs
 * @returns {number}
 *
 */

/* **************************************************
  * "Import"
  * ************************************************** */
var IDate         = lim.IDate,
    Arrays        = lim.Arrays,
    Logger        = lim.Logger,
    Parameters    = morn.Parameters,
    Product       = morn.Product,
    DeliveryType  = morn.DeliveryType,
    Calendar      = morn.Calendar,
    Contract      = morn.Contract,
    ContractValue = morn.ContractValue,
    TimeSeries    = morn.TimeSeries,
    Row           = TimeSeries.Row,
    DataPoint     = TimeSeries.DataPoint;

/* **************************************************
  * Private variables
  * ************************************************** */

/**
 * @private
 */
var _nowOverwrite = null,
    dateAndValueMap = {},
    cal = null,
    isFillCompleted =  false;

/* **************************************************
  * Private functions
  * ************************************************** */

/**
 * Returns
 * @param {morn.ContractValue} contractV
 * @returns {string}
 * @private
 */
var _contractValueTag = function (contractV) {
    return contractV.contract().keyValuesAsText();  // or .keyValuesAsText();
};

/**
 *
 * @param {morn.ContractValue} contractV
 * @returns {morn.TimeSeries.DataPoint}
 * @private
 */
var _dataPointFromContractValue = function (contractV) {

    var tag = _contractValueTag(contractV);

    if (typeof tag !== "string")
        throw new Error("IllegalStateException: contract tag builder did not return a string");

    return new DataPoint(contractV.value(), tag);
};

var _compareDuration = function(c1, c2) {
    return (c1.contract().deliveryDuration - c2.contract().deliveryDuration);
};

var _compareDeliveryPeriod = function (cv1, cv2) {

    var c1 = cv1.contract(),
        c2 = cv2.contract(),
        rv = IDate.compare(c1.deliveryStart(), c2.deliveryStart());

    if (rv === 0)
        rv = IDate.compare(c1.deliveryEnd(), c2.deliveryEnd());

    return rv;
};

/**
 * Validates an argument as a boolean value.
 * If argument is not provided, \`defaultValue\` is inserted at \`argIdx\`.
 *
 * This method throws if the given argument is not of type *boolean*.
 *
 * @param args {Arguments}
 * @param argIdx {integer} Non-negative.
 * @param argName {string} the argument name (used in exception messages.)
 * @param defaultValue {boolean}
 * @returns {boolean}
 * @private
 */
var _validBool = function (args, argIdx, argName, defaultValue) {

    if (args.length <= argIdx)
        return defaultValue;

    else if (typeof args[argIdx] !== 'boolean')
        throw new TypeError(argName + ": Boolean");

    else
        return args[argIdx];
};

/**
 * Validates that a given argument behaves like a UnitCalculator.
 * If valid, the argument is returned.
 * Otherwise, this method throws.
 * @param {morn.UnitCalculator} arg
 * @param {string} argName
 * @private
 */
var _validUnitCalculator = function (arg, argName) {

    if (   typeof arg !== "function"
        || arg.length !== 2 )
        throw new TypeError(argName + ": function, two arguments (both lim.IDate objects)");

    // test it
    var rv = arg(IDate.create(0, 0), IDate.create(IDate.MILLIS_PER_DAY, 0));
    if (!lim.Number.isNonNegativeNumber(rv))
        throw new Error("IllegalArgumentException: \`" + argName + "\` did not return a number or returned a negative number.");

    return arg;
};

var _deliveryDateFilter = function (date, stepType) {
    const stepTypeList = ["HOUR", "DAY", "WEEK", "30-MINUTE"];
    return function (contractV) {
        var contract = contractV.contract();
        if (stepTypeList.indexOf(stepType.toString()) === -1) {
            if (contract.deliveryStart() <= date
                && contract.deliveryEnd() > date) {
                return true;
            } else if (!IDate.isWeekday(date)) {
                date = IDate.adjustWeekday(date, 1);
                return (contract.deliveryStart() <= date
                    && contract.deliveryEnd() > date);
            }
        } else {
            return (contract.deliveryStart() <= date
                && contract.deliveryEnd() > date);
        }
    };
};

var _deliveryTypeFilter = function (type) {

    return function (contractV) {
        var contract = contractV.contract();
        return (   contract.deliveryDuration >= type.minDuration()
                && contract.deliveryDuration <= type.maxDuration() );
    };
};


var _isWithin = function (bigContractV, smallContractV) {

    var bigContract   = bigContractV.contract(),
        smallContract = smallContractV.contract();

    return (   smallContract.deliveryStart() >= bigContract.deliveryStart()
            && smallContract.deliveryEnd()   <= bigContract.deliveryEnd() );
};


var _isOverlap = function (bigContractV, smallContractV) {

    var bigContract   = bigContractV.contract(),
        smallContract = smallContractV.contract(),
        bigStart      = bigContract.deliveryStart(),
        bigEnd        = bigContract.deliveryEnd(),
        smallStart    = smallContract.deliveryStart(),
        smallEnd      = smallContract.deliveryEnd();

    return (   (smallStart >= bigStart && smallStart <  bigEnd)
            || (smallEnd   >  bigStart && smallEnd   <= bigEnd) );
};

var _overlapsWithOthers = function (contractV, subContractVs) {

    return _.some(subContractVs, function (subContractV) {
        return _isOverlap(subContractV, contractV);
    });
};




/**
 * Returns the list of contracts with a delivery period that is
 * active as of <code>date</code>.
 * @param contractVs {morn.ContractValue[]}
 * @param date {lim.IDate}
 * @returns {morn.ContractValue[]}
 */
var _getContractsActiveOnDate = function(contractVs, date, stepType) {
    return _.filter(contractVs, _deliveryDateFilter(date , stepType));
};

/**
 * Returns the first contract active as of <code>date</code>,
 * regardless of the contract's delivery period size.
 */
var _firstContractOnDate = function (contractVs, date, stepType) {

    var c = _.find(contractVs, _deliveryDateFilter(date, stepType));
    if (typeof c === 'undefined')
        c = null;

    return c;
};

/**
 * Get a contract from a given list, based on  type
 */
var _getContractsByDeliveryType = function (contracts, type){
    return _.filter(contracts, _deliveryTypeFilter(type));
};

/**
 * Get a contract from a given list, based on a date, type.
 */
var _getContractFromList = function (contracts, date, type) {

    var contractList = _getContractsByDeliveryType(contracts, type);

    return _firstContractOnDate(contractList, date, type);
};

/**
 * Returns a list of sub-contracts.
 * Sub-contracts are contracts for which the delivery
 * period is smaller than \`contract\` and spans
 * over some part of \`contract\`.
 *
 * This method returns a list of contracts sorted with
 * longest delivery period first.
 */
var _getSubContracts = function (contractVs, contractV) {

    /* \`contractVs\` is sorted by duration, smaller contracts first.
      * Since we're only looking for smaller contracts, we can optimize
      * by shrinking the list of contracts to only those that come before
      * \`contractV\`. */

    var subCs = [],
        idx   = Arrays.indexOf(contractV, contractVs);

    if (idx < 0)
        return subCs;  // No sub-contracts available.

    contractVs = contractVs.slice(0, idx);

    /* Look for sub-contracts (aka smaller contracts)
      * with a delivery period that overlaps that of \`contractV\`.
      * We look for sub-contracts with longer delivery periods first
      * (loop from end-to-beginning) and we ignore/exclude
      * subsequently found sub-contracts (aka smaller sub-contracts)
      * that overlap the longer sub-contracts we've already found. */

    for (var i = contractVs.length - 1; i >= 0; i--) {

        var smallerCV = contractVs[i];

        if (   _isOverlap(contractV, smallerCV)
            && !_overlapsWithOthers(smallerCV, subCs)) {

            subCs.push(smallerCV);
        }
    }

    return subCs;
};

/**
 * Returns the number of minute between two dates.
 * @param {lim.IDate} start
 * @param {lim.IDate} end
 * @returns {Integer}
 * @private
 */
var _unitCalculatorMinutes = function (start, end) {
    return Math.floor(  (end.getTime() - start.getTime())
                      / IDate.MILLIS_PER_MINUTE );
};

/**
 * Returns the number of *units* found within these two contracts'
 * overlapping period.
 * @param {morn.ContractValue} higherContractV
 * @param {morn.ContractValue} subContractV
 * @param {function} unitCalc
 * @returns {number}
 * @private
 */
var _overlapUnits = function (higherContractV, subContractV, unitCalc) {

    var higherContract = higherContractV.contract(),
        subContract    = subContractV.contract();

    var start = IDate.max( higherContract.deliveryStart(),
                            subContract.deliveryStart() ),
        end   = IDate.min( higherContract.deliveryEnd(),
                            subContract.deliveryEnd() );

    return unitCalc(start, end);
};


/**
 * Returns a callback which, given a higher contract and a list
 * of sub-contracts, return the weighted value based on the number of units
 * given to each contract's delivery period.
 *
 * @param {morn.UnitCalculator} numUnitCalculator
 * @returns {DerivedValueCalculator} Derived value calculator.
 */
var _newDerivedValueCalculator = function (numUnitCalculator) {

    return function (higherCV, subContractVs) {

        var sumUnits = 0,
            sumValue = 0;

        for (var i = 0, len = subContractVs.length; i < len; i++) {

            var subCV = subContractVs[i],
                units = _overlapUnits(higherCV, subCV, numUnitCalculator);

            sumUnits += units;
            sumValue += (units * subCV.value());
        }

        var higherC    = higherCV.contract(),
            totalUnits = numUnitCalculator( higherC.deliveryStart(),
                                            higherC.deliveryEnd());

        return (  ((higherCV.value() * totalUnits) - sumValue)
                / (totalUnits - sumUnits) );
    };
};


/**
 * Calculate a contract when this type of contract was not found
 * in the contract list, but a different type of contract was found.
 * (i.e, daily contract was not found, but monthly contract was found,
 * calculate daily contract based on monthly contract
 *
 * @param contractVs {morn.ContractValue[]}
 * @param higherCV {morn.ContractValue}
 * @returns {?morn.TimeSeries.DataPoint}
 */
var _dataPointFromHigherContract = function( contractVs,
                                              higherCV,
                                              derivation ) {

    var srcDP  = _dataPointFromContractValue(higherCV),
        subCVs = _getSubContracts(contractVs, higherCV);

    if (subCVs.length === 0) {
        // all contracts in same higher contract are missing, go up to higher contracts to find value
        return srcDP;
    }

    // Sort chronologically, as users will expect to see in the "tag" info.
    subCVs.sort(_compareDeliveryPeriod);

    var val    = derivation(higherCV, subCVs),
        srcDPs = [ srcDP ];

    for (var i = 0; i < subCVs.length; i++)
        srcDPs.push(_dataPointFromContractValue(subCVs[i]));

    return new DataPoint(val, 'derived', srcDPs);
};


/**
 * Returns a function that finds the contract with the smallest
 * delivery period that pertains to <code>date</code>.
 *
 * @param contractVs {morn.ContractValue[]}
 * @param deliveryType {morn.DeliveryType}
 * @returns {function}
 */
var _arbDataPointGetter = function (contractVs, deliveryType) {

    /* \`contracts\` has been stripped of contracts that are too small
      * for \`deliveryType\`.  Also, \`contracts\` is sorted with
      * shorter contracts first. */

    return function (date) {
        var activeCVs = _getContractsActiveOnDate(contractVs, date, deliveryType);
        if (activeCVs.length > 0)
            return _dataPointFromContractValue(activeCVs[0]);
        else
            return null;
    };
};

var _arbDataPointGetterFaster = function (contractVs, deliveryType) {

    // Originally 5000 because we wanted to play it safe.
    // Now 1000 since most monthly curves only have 120 points or so,
    // in which case 1000 is a safe threshold to separate monthly curves from hourly curves.
    if (contractVs.length < 1000) {
        return _arbDataPointGetter(contractVs,deliveryType);
    }

    var resultMap = {};

    for (var i = 0; i < contractVs.length; i++) {

        var contractValue = contractVs[i],
            contract      = contractValue.contract(),
            dataPoint     = _dataPointFromContractValue(contractValue),
            dates         = deliveryType.getTicks( contract.deliveryStart(),
                                                    contract.deliveryEnd() );

        for (var j = 0, numDates = dates.length; j < numDates; j++) {

            var dt    = dates[j],
                epoch = dt.getTime();

            if (!resultMap.hasOwnProperty(epoch))
                resultMap[epoch] = dataPoint;
        }
    }

    return function (date) {

        var epoch = date.getTime();
        if (resultMap.hasOwnProperty(epoch))
            return resultMap[epoch];
        else
            return null;  // Be consistent across all data-point-getter functions.
    };
};


var _arbFreeDataPointGetter = function (contractVs, deliveryType, derivation) {

    /* \`contractVs\` has been stripped of contracts that are too small
      * for \`deliveryType\`.  Also, \`contractVs\` is sorted with
      * shorter contracts first. */

    var pointGetter = function (date) {

        var dataPoint = null;

        if(!isFillCompleted){
            _fillDateValueMap(contractVs,deliveryType,derivation);
        }

        if(dateAndValueMap[date] != null){
            return dateAndValueMap[date];
        }

        //Find the contract within the specified 'date'
        var contractV = _getContractFromList(contractVs, date, deliveryType);

        if (contractV !== null) {
            // Found a contract for the expected delivery type;
            // nothing further to do, just use that contract.
            dataPoint = _dataPointFromContractValue(contractV);
        }

        else {

            // Look for a contract with longer delivery period
            // (aka higherContract, or \`higherC\`)

            var higherCV = _firstContractOnDate(contractVs, date, deliveryType);
            if (higherCV !== null) {
                const higherC = higherCV.contract();
                const deliveryStartDate = higherC.deliveryStart();
                if (deliveryStartDate.getMonth() == 9 && higherC.deliveryTypeString().toLowerCase() === "season") {
                    const month = date.getMonth();
                    if (month >= 9 && month <= 11) {
                        var yearHigherCV = _isYearContractPresent(contractVs, date, deliveryType)
                        if (yearHigherCV !== null) {
                            dataPoint = _derivedValues(contractVs, date, yearHigherCV, deliveryType, false);
                        } else {
                            dataPoint = _dataPointFromHigherContract(contractVs,
                                higherCV,
                                derivation);
                        }
                    } else {
                        dataPoint = _derivedValues(contractVs, date, higherCV, deliveryType, true);
                    }
                } else if (higherC.deliveryTypeString().toLowerCase() === "year") {
                    dataPoint = _derivedValues(contractVs, date, higherCV, deliveryType, false);
                } else {
                    // higherContract found, arbitrage-free is possible.
                    dataPoint = _dataPointFromHigherContract(contractVs,
                        higherCV,
                        derivation);
                }
            }
        }
        if(dataPoint != null){
            dateAndValueMap[date] = dataPoint;
        }
        return dataPoint;
    };
    return pointGetter;
};

/**
 * Derive values using dateAndValueMap, derivation logic is varies based on isJanToMarchDate flag.
 * @param contractVs
 * @param date
 * @param higherCV
 * @param stepType
 * @param isJanToMarchDate
 * @returns {morn.TimeSeries.DataPoint}
 * @private
 */
var _derivedValues = function (contractVs, date, higherCV, stepType, isJanToMarchDate) {
    var periodSize = 0;
    var periodSum = 0;
    for (var key in dateAndValueMap) {
        if (isJanToMarchDate) {
            if ((date.getYear()) - 1 === IDate.valueOf(key).getYear()) {
                var month = IDate.valueOf(key).getMonth();
                if (month >= 9 && month <= 11) { // previous years oct to dec contracts values.
                    periodSum = periodSum + dateAndValueMap[key].value();
                    periodSize++;
                }
            } else if (date.getYear() === IDate.valueOf(key).getYear()) {
                var month = IDate.valueOf(key).getMonth();
                if (month >= 0 && month <= 2) { // tick date year jan to march contracts values.
                    periodSum = periodSum + dateAndValueMap[key].value();
                    periodSize++;
                }
            }
        } else {
            if (date.getYear() !== IDate.valueOf(key).getYear()) {
                continue;
            }
            periodSum = periodSum + dateAndValueMap[key].value();
            periodSize++;
        }
    }
    var higherC = higherCV.contract();
    var totalUnits = stepType.numDeliveries(higherC.deliveryStart(), higherC.deliveryEnd());
    if (higherC.deliveryTypeString().toLowerCase() === "year") {
        switch (stepType.toString().toLowerCase()) {
            case "month":
                totalUnits = 12
                break;
            case "quarter":
                totalUnits = 4;
                break;
            case "season":
                totalUnits = 2;
                break;
        }
    }
    var value = (((higherCV.value() * totalUnits) - periodSum)
        / (totalUnits - periodSize));
    return new DataPoint(value, 'derived');
};

/**
 * Returns true if active year contract is present.
 * @param contractVs
 * @param date
 * @param stepType
 * @returns {boolean}
 * @private
 */
var _isYearContractPresent = function (contractVs,date, stepType){
    const onlyYearContractVs = _getContractsByDeliveryType(contractVs, DeliveryType.valueOf("year"));
    const higherCV = _firstContractOnDate(onlyYearContractVs, date, stepType);

    if(higherCV != null){
        return higherCV;
    }
    return null;
};

var _fillDateValueMap = function (contractVs, deliveryType, derivation){
    if(cal != null) {
        var itr = cal.iterator();
        while (itr.hasNext()) {
            var date, dataPoint = null;
            date = itr.next();
            //Find the contract within the specified 'date'
            var contractV = _getContractFromList(contractVs, date, deliveryType);
            if (contractV !== null) {
                // Found a contract for the expected delivery type;
                // nothing further to do, just use that contract.
                dataPoint = _dataPointFromContractValue(contractV);
            } else {
                // Look for a contract with longer delivery period
                // (aka higherContract, or \`higherC\`)

                var higherCV = _firstContractOnDate(contractVs, date, deliveryType);
                if (higherCV != null) {
                    var higherC = higherCV.contract();
                    var deliveryStartDate = higherC.deliveryStart();
                    var isWinter = deliveryStartDate.getMonth() == 9 && higherC.deliveryTypeString().toLowerCase() === "season";
                    var isYear = higherC.deliveryTypeString().toLowerCase() === "year";
                    if (isYear || isWinter) {
                        continue;
                    }

                    dataPoint = _dataPointFromHigherContract(contractVs,
                        higherCV,
                        derivation);
                }
            }

            if (dataPoint != null) {
                dateAndValueMap[date] = dataPoint;
            }

        }
        isFillCompleted = true;
    }
}


/**
 * Returns a function that finds a DataPoint created
 * from precise arbitrage-free logic against a list of
 * underlying contracts.
 *
 * @param contractVs {morn.ContractValue[]}
 * @param deliveryType {morn.DeliveryType}
 * @returns {function}
 */
var _preciseArbFreeDataPointGetter = function (contractVs, deliveryType) {

    return _arbFreeDataPointGetter(
        contractVs,
        deliveryType,
        _newDerivedValueCalculator(_unitCalculatorMinutes)
    );
};


/**
 * Returns a function that finds a DataPoint created
 * from precise arbitrage-free logic against a list of
 * underlying contracts.
 *
 * @param contractVs {morn.ContractValue[]}
 * @param deliveryType {morn.DeliveryType}
 * @returns {function}
 */
var _grossArbFreeDataPointGetter = function (contractVs, deliveryType) {

    return _arbFreeDataPointGetter(
        contractVs,
        deliveryType,
        _newDerivedValueCalculator(function (start, end) {
            return deliveryType.numDeliveries(start, end);
        })
    );
};


/**
 * Creates a Calendar from a list of contracts.
 *
 * @param contractVs {morn.ContractValue[]}
 * @param deliveryType {morn.DeliveryType}
 * @param dateOfCurve {lim.IDate}
 * @returns {morn.Calendar}
 */
var _createCalendar = function (contractVs, deliveryType, dateOfCurve) {

    var firstDate = null,
        outDate   = null;

    // Search the first available contract which has not expired yet.
    for (var i = 0; i < contractVs.length; i++) {

        var contractV = contractVs[i],
            contract  = contractV.contract(),
            startDate = contract.deliveryStart(),
            endDate   = contract.deliveryEnd();

        // make sure contract is not expired
        if (dateOfCurve <= contract.expirationDate) {

            if (   firstDate === null
                || firstDate > startDate ) {
                firstDate = startDate;
            }

            if (deliveryType.periodName() === "season" && contract.deliveryTypeString().toLowerCase() === "season") {
            if (   outDate === null
                || outDate < endDate ) {
                outDate = endDate;
            }
            } else if (deliveryType.periodName() !== "season" && (outDate === null
                || outDate < endDate)) {
                outDate = endDate;
            }
        }
    }

    if (firstDate === null)
        return Calendar.EMPTY;

    return new Calendar(deliveryType.getTicks(firstDate, outDate));
};

var _isoToday = function () {

    var now = (  (_nowOverwrite !== null)
                ? _nowOverwrite
                : new Date() );

    return lim.Date.getFormatter("yyyy-MM-dd")(now);
};

var _today = function () {
    return IDate.create(_isoToday());
};

/**
 * Inspects a function's arguments for a Product at \`args[0]\`.  If a
 * Product is not there, this method assumes the first three arguments
 * are feed, root(s) and column(s), and creates a Product from
 * those values.
 *
 * The returned list of arguments has a Product as the first entry,
 * with all subsequent arguments appended *as-is*.
 * @param args {Arguments}
 * @returns {Object[]} The first entry is a Product object; other entries
 *               are whatever was provided in the initial call (after \`column\`).
 * @private
 */
var _toProduct = function (args) {

    var aa = Arrays.slice(args);

    if (!(aa[0] instanceof Product)) {

        var feed  = aa[0],
            roots = aa[1],
            cols  = aa[2];

        if (typeof roots === "string")
            roots = [ roots ];

        var product = Product.withRoots(feed, roots, cols);

        aa.splice(0, 3, product);
    }

    return aa;
};

/**
 * Parsed the arguments given to a forward curve function.
 * This function assumes callers all have the same signature.
 *
 * @param {Arguments} args - Callers Argument object
 * @returns {ForwardCurveArguments}
 * @private
 */
var _parseFwdCurveArgs = function (args, asOfDateArgIndex) {

    var newArgs   = _toProduct(args),
        product   = newArgs[0],
        curveDate = newArgs[1];

    if (curveDate === null || Parameters.getBool('formula.force_latest_curve', false)) {
        curveDate = Contract.getFloorDate(product, _isoToday());
    } else {
        curveDate = IDate.valueOf(curveDate);
    }

    var delivType = DeliveryType.valueOf(newArgs[2]),
        asOfDate  = null;

    if (newArgs.length > asOfDateArgIndex) {
        asOfDate = IDate.valueOf(newArgs[asOfDateArgIndex]);
    }

    return {
        product: product,
        curveDate: curveDate,
        deliveryType: delivType,
        asOfDate: asOfDate,
        argsReorg: newArgs
    }
};


/**
 * Generates a forward curve using the given *data-point getter*.
 *
 * @param {ForwardCurveArguments} args - Inputs driving how the forward curve is created.
 * @param {function} dataPointGetterGenerator - Data-point getter and computing algorithm.
 * @returns {morn.TimeSeries}
 * @private
 */
var _getCurveTimeSeries = function (args, dataPointGetterGenerator) {

    // All arguments have been validated before this point.

    var product      = args.product,
        curveDate    = args.curveDate,
        deliveryType = args.deliveryType,
        asOfDate     = args.asOfDate;

    Logger.info( "ForwardCurve._getCurveAsTimeSeries - product {}, curveDate: [{}], delivery: [{}], asofDate: [{}]",
                  product, curveDate, deliveryType, asOfDate );

    // If \`curveDate\` is still null, we couldn't find the "most recent" curve date,
    // in which case we return an empty time-series.
    if (curveDate === null)
        return TimeSeries.empty(1);

    // Get the contracts
    var contractVs = Contract.getContractsWithValue(product, curveDate, asOfDate);

    return _contractValuesToTS(
        contractVs,
        curveDate,
        deliveryType,
        product.columns(),
        dataPointGetterGenerator
    );
};

/**
 * Creates a forward curve from server payload.
 *
 * @param {function} dataPointGetterGenerator - Data-point getter and computing algorithm.
 * @param {(Object[]|morn.ContractValue[])} contractValues
 * @param {(lim.IDate|string)} curveDate
 * @param {(morn.DeliveryType|string)} deliveryType
 * @returns {morn.TimeSeries}
 */
var _curveFromContractValues = function (
        dataPointGetterGenerator,
        contractValues,
        curveDate,
        deliveryType ) {

    if (   !Arrays.isArrayOf(contractValues, Object)
        && !Arrays.isArrayOf(contractValues, ContractValue) )
        throw new TypeError("contractValues: morn.ContractValue[] or Object[]");

    // If \`curveDate\` is still null, we couldn't find the "most recent" curve date,
    // in which case we return an empty time-series.
    if (   curveDate === null
        || contractValues.length === 0 ) {

        return TimeSeries.empty(1);
    }

    var date       = IDate.valueOf(curveDate),
        delivType  = DeliveryType.valueOf(deliveryType),

        contractVs = ( (contractValues[0] instanceof ContractValue)
                      ? contractValues
                      : ContractValue.fromServerPayload(contractValues, date) );

    var cols = _.chain(contractVs)
            .map( /** @param {morn.ContractValue} cv */ function (cv) {
                return cv.column();
            })
            .uniq()
            .value();

    cols.sort(lim.String.compareIgnoreCase);

    return _contractValuesToTS(
        contractVs,
        date,
        delivType,
        cols,
        dataPointGetterGenerator
    );
};

/**
 * @param {morn.ContractValue[]} contractVs
 * @param {lim.IDate} curveDate
 * @param {morn.DeliveryType} deliveryType
 * @param {string[]} cols
 * @param {function} dataPointGetterGenerator - Data-point getter and computing algorithm.
 * @returns {morn.TimeSeries} Forward curve from the given ContractValue objects.
 * @private
 */
var _contractValuesToTS = function (contractVs, curveDate, deliveryType, cols, dataPointGetterGenerator) {

    if (contractVs.length === 0)
        return new TimeSeries(1).freeze();

    Logger.info( "ForwardCurve._contractValuesToTS - building curve...");

    var minDuration = deliveryType.minDuration(),
        maxDuration = deliveryType.maxDuration(),
        numCols     = cols.length,
        toUnionize  = [];

    /* TODO
      * I think the correct way to create a curve is to only
      * drop the trailing contracts with NaN values, but not the leading
      * contracts.  That way, if for some reason the front contract has
      * a NaN value, it still shows up in the curve (with a NaN value, of course.)
      *
      * Although, to avoid backward-compatibility issues, all NaN contracts should be
      * dropped after \`_createCalendar\` is called, so that arbitrage-free calculations
      * remains unaffected.
      */

    // Only keep contracts with a delivery period
    // equal-or-bigger than requested \`deliveryType\`.
    contractVs = _.filter(contractVs, function (contractV) {
        var deliveryPeriod = deliveryType.periodName();
        var REGEX_FOR_ONLY_PERIOD = new RegExp("^(only)-([A-Z_a-z]+)");
        var match = REGEX_FOR_ONLY_PERIOD.exec(deliveryPeriod);
        if(match !== null){
            return ( (   lim.Number.isNumber(contractV.value())
                && contractV.contract().deliveryDuration >= minDuration && contractV.contract().deliveryDuration <= maxDuration ));
        }
        else {
            return (   lim.Number.isNumber(contractV.value())
                && contractV.contract().deliveryDuration >= minDuration );
        }
    });

    cal = _createCalendar(contractVs, deliveryType, curveDate);

    for (var i = 0; i < numCols; i++) {

        Logger.debug("ForwardCurve._contractValuesToTS - building curve for column [{}]", cols[i]);

        var col            = cols[i].toLowerCase(),
            colContractsVs = _.filter(contractVs, function (contractV) {
                return (contractV.column().toLowerCase() === col);
            });


        // Sort once so that downstream code can assume the sort order
        // (shortest delivery period first)
        colContractsVs.sort(_compareDuration);
        Logger.info(colContractsVs);
        // Create the calendar
        var itr = cal.iterator();

        // Initialize DataPointGetter
        var pointCalc  = dataPointGetterGenerator(colContractsVs, deliveryType),
            timeSeries = new TimeSeries(1),
            date, dataPoint;

        toUnionize.push(timeSeries);

        while (itr.hasNext()) {

            date       = itr.next();
            dataPoint  = pointCalc.call(this, date);

            if (morn.Utils.isVoid(dataPoint))
                dataPoint = DataPoint.NaN;

            else if (!(dataPoint instanceof DataPoint))
                throw new Error("IllegalStateException: data-point calculator did not return a DataPoint object.");

            timeSeries.addRow(new Row(date, dataPoint));
        }
        dateAndValueMap = {};
        timeSeries.freeze();
    }

    Logger.debug("ForwardCurve._contractValuesToTS - unionizing columns...");

    var rv = TimeSeries.union.apply(TimeSeries, toUnionize);

    Logger.info("ForwardCurve._contractValuesToTS - done, got [{}] rows", rv.length());
    return rv;
};


/**
 * Returns a TimeSeries created from expired contracts' value (price)
 * on their expiration date.
 * The TimeSeries dates are that of the contracts delivery start date
 * (similar to a forward curve.)
 *
 * <p>
 *  The first three arguments - feed, root and columns -
 *  can be collapsed into one {@link morn.Product|Product} argument.
 *  When invoked in this manner, this method requires 2 argument
 *  only (3 at most).
 * </p>
 *
 * @param feed {string} Feed name
 * @param root {string} Root, only one
 * @param columns {(string|string[])} One or more column name(s).
 * @param lookBack {(integer|string|lim.IDate)}
 *                        How many (expired) contracts to return.
 *                        If a string, must be a parsable date representation.
 * @param [expiredAsOf=today] {(string|lim.IDate)}
 *                        When complementing a forward curve,
 *                        <code>expiredAsOf</code> should be the same date
 *                        as <em>curve date</em> used to create the curve.
 *                        If a string, must be a parsable date representation.
 * @returns {morn.TimeSeries}
 * @private
 */
var _getExpiredContractValues = function () {

    var args    = _toProduct(arguments),
        product = args[0];

    if (product.roots().length > 1)
        throw new Error("UnsupportedOperationException: only one root allowed");

    var feed         = product.feed(),
        root         = product.roots()[0],
        columns      = product.columns(),
        lookBack     = args[1],
        expiredAsOf  = null,
        startDateIso = null,
        startIdx     = 0,
        startDate    = null;

    if (typeof lookBack === 'number') {

        if (!lim.Number.isPositiveInteger(lookBack))
            throw new TypeError("lookBack: integer, positive");

        startIdx = lookBack;
    }
    else {
        try {
            startDate    = IDate.valueOf(lookBack);
            startDateIso = startDate.format("yyyy-MM-dd");
        }
        catch (ex) {
            throw new TypeError("lookBack: integer (non-zero), IDate or recognizable date string");
        }
    }

    if (args.length < 3)
        expiredAsOf = _today();
    else
        expiredAsOf = IDate.valueOf(args[2]);

    // Get list of contracts, sorted by expiration date.
    var contracts = Contract.getContractsByRoot(feed, root, startDateIso);

    // Drop contracts that expire after \`expiredAsOf\`.
    contracts = contracts.slice(0, 1 + Arrays.indexFloor( expiredAsOf,
                                                          contracts,
                                                          'expirationDate',
                                                          IDate.compare ));

    // In case we have all expired contracts, only keep the last few
    // we're interested in (specified by \`lookBack\`).
    if (   startIdx > 0
        && startIdx < contracts.length )
        contracts = contracts.slice(contracts.length - startIdx);

    // \`contracts\` now contains only what we care about.
    // We create a TimeSeries off them, using their deliveryStartDate
    // as TS dates.

    var tsSettled = new TimeSeries(columns.length);
    for (var i = 0, numContracts = contracts.length; i < numContracts; i++) {

        var contract = contracts[i],
            tsDate   = contract.deliveryStart();

        Logger.debug( "ForwardCurve._getExpiredContractValues - get contract value at expiration [{}] {}/{}",
                      contract.keysAsText(), i + 1, numContracts );

        var ts = TimeSeries.getServerData( feed,
                                            contract.keys(),
                                            columns,
                                            contract.expirationDate,
                                            contract.expirationDate );

        /* Sometimes, we have values loaded after contracts have expired.
          * In those cases, DataOps will void the value by overwriting it
          * with NaN.
          * We only requested data on the expiration date, so we shouldn't
          * see those post-expiration NaNs, but let's drop NaNs anyway,
          * just to be safe. */
        ts = ts.dropNaNs();

        if (ts.length() > 0)
            tsSettled.addRow(new Row(tsDate, ts.rowAt(-1).dataPoints()));

        else if (!tsSettled.contains(tsDate)) {
            /* If no value found at expiration date, we insert
              * a NaN to indicate to callers we've actually looked
              * at that contract but found no value.
              * Callers can call \`dropNaNs()\` if they don't want to
              * see them.
              *
              * However, we only insert a NaN if a value doesn't already
              * exist for the same date (usually caused by bad data on staging!)
              */
            tsSettled.addRow(new Row(tsDate, Arrays.newInstance(columns.length, DataPoint.NaN)));
        }
    }

    return tsSettled.freeze();
};


/**
 * @namespace
 * @alias morn.ForwardCurve
 */
var ForwardCurve = {

    /**
     * Sets the current time for debugging purposes.
     * @param {?(string|Date)} newNow - ISO-8601 date format, time is optional.  Null is acceptable.
     * @returns void
     */
    overwriteNow: function (newNow) {

        if (newNow === null)
            _nowOverwrite = newNow;

        else if (typeof newNow === "string")
            _nowOverwrite = lim.Date.isoStringToDate(newNow);

        else if (newNow instanceof Date)
            _nowOverwrite = new Date(newNow.getTime());

        else
            throw new TypeError("newNow: String, Date or null");
    },

    /**
     * Sets the callback to be used to convert a ContractValue
     * object into a (string) tag.  The callback receives one argument -
     * a {@link morn.ContractValue ContractValue} object - and must
     * ALWAYS return a string. (If under any circumstances it returns
     * something other than a string, an exception will be thrown.
     * Take precaution to ensure it always returns a string.)
     *
     * @param {function} callback
     * @returns void
     */
    contractTagCallback: function (callback) {

        if (   typeof callback !== "function"
            || callback.length !== 1 )
            throw new TypeError("callback: Function accepting one argument");

        _contractValueTag = callback;

    },


    /**
     * <p>
     *  Returns the most recent curve date as of the given date.
     * </p>
     *
     * <p>
     *  Note: this function returns <em>null</em> if it can't find
     *  any active contracts, or can't find any data for
     *  the front contract within 7 days of <code>asOfDate</code>.
     * </p>
     *
     * <p>
     *  The first three arguments - feed, roots and columns -
     *  can be collapsed into one {@link morn.Product|Product} argument.
     *  When invoked in this manner, this method requires 2 arguments.
     * </p>
     *
     * @param {string} feed
     * @param {(string|string[])} roots
     * @param {(string|string[])} columns
     * @param {(string|lim.IDate)} asOfDate - The date for which to
     *         retrieve the active contracts.
     * @returns {?lim.IDate}
     */
    getCurveFloorDate: function (feed, roots, columns, asOfDate) {
        return Contract.getFloorDate.apply(Contract, arguments);
    },

    /**
     * <p>
     *  Creates a TimeSeries representing a forward curve, based
     *  on underlying contracts, using arbitrage logic to
     *  calculate the value of missing contracts.
     * </p>
     *
     * <p>
     *  The first three arguments - feed, roots and columns -
     *  can be collapsed into one {@link morn.Product|Product} argument.
     *  When invoked in this manner, this method requires 3 arguments only.
     * </p>
     *
     * @param {string} feed
     * @param {(string|string[])} roots
     * @param {(string|string[])} columns
     * @param {?(string|lim.IDate)} curveDate - Pass <em>null</em> for most recent curve.
     * @param {(string|morn.DeliveryType)} deliveryType
     * @param {?(string|lim.IDate)} [asOfDate] - Prices (or values) as-of the given date.
     *                              Meaning: any corrections or changes made to contracts
     *                              after <code>asOfDate</code> are ignored.
     * @returns {morn.TimeSeries}
     */
    arbitrage: function ( feed,
                          roots,
                          columns,
                          curveDate,
                          deliveryType,
                          asOfDate ) {

        var args = _parseFwdCurveArgs(arguments, 3);
        return _getCurveTimeSeries(args, _arbDataPointGetterFaster);
    },

    /**
     * <p>
     *  Creates a TimeSeries representing a forward curve based
     *  on underlying contracts, using arbitrage-free logic
     *  with precise contract delivery proportions to calculate the
     *  value of missing contracts.
     * </p>
     *
     * <p>
     *  The first three arguments - feed, roots and columns -
     *  can be collapsed into one {@link morn.Product|Product} argument.
     *  When invoked in this manner, this method requires 3 arguments only.
     * </p>
     *
     * @param {string} feed
     * @param {(string|string[])} roots
     * @param {(string|string[])} columns
     * @param {?(string|lim.IDate)} curveDate - Pass <em>null</em> for most recent curve.
     * @param {(string|morn.DeliveryType)} deliveryType
     * @param {?(string|lim.IDate)} [asOfDate] - Prices (or values) as-of the given date.
     *                              Meaning: any corrections or changes made to contracts
     *                              after <code>asOfDate</code> are ignored.
     * @returns {morn.TimeSeries}
     * @see morn.ForwardCurve.arbitrageFreeBasic
     */
    arbitrageFree: function ( feed,
                              roots,
                              columns,
                              curveDate,
                              deliveryType,
                              asOfDate ) {

        var args = _parseFwdCurveArgs(arguments, 3);
        return _getCurveTimeSeries(args, _preciseArbFreeDataPointGetter);
    },

    /**
     * <p>
     *  Creates a TimeSeries representing a forward curve based
     *  on underlying contracts, using arbitrage-free logic
     *  with rough contract delivery proportions to calculate the
     *  value of missing contracts.
     * </p>
     *
     * <p>
     *  Use this method to treat each month of the curve as
     *  one unit, instead of considering the number of days
     *  within each month.
     * </p>
     *
     * <p>
     *  The first three arguments - feed, roots and columns -
     *  can be collapsed into one {@link morn.Product|Product} argument.
     *  When invoked in this manner, this method requires 3 arguments only.
     * </p>
     *
     * @param {string} feed
     * @param {(string|string[])} roots
     * @param {(string|string[])} columns
     * @param {?(string|lim.IDate)} curveDate - Pass <em>null</em> for most recent curve.
     * @param {(string|morn.DeliveryType)} deliveryType
     * @param {?(string|lim.IDate)} [asOfDate] - Prices (or values) as-of the given date.
     *                              Meaning: any corrections or changes made to contracts
     *                              after <code>asOfDate</code> are ignored.
     * @returns {morn.TimeSeries}
     * @see morn.ForwardCurve.arbitrageFree
     */
    arbitrageFreeBasic: function ( feed,
                                    roots,
                                    columns,
                                    curveDate,
                                    deliveryType,
                                    asOfDate ) {

        var args = _parseFwdCurveArgs(arguments, 3);
        return _getCurveTimeSeries(args, _grossArbFreeDataPointGetter);
    },

    /**
     * <p>
     *  Creates a TimeSeries representing a forward curve based
     *  on underlying contracts, using arbitrage-free logic
     *  where each contract is associated with a number of units
     *  specified by the <code>unitCalculator</code> argument.
     *  That number of units is used to weight each contract's
     *  value when backing out the value of missing contracts.
     * </p>
     *
     * <p>
     *  Use this method to customize the weight of each contract
     *  in an arbitrage-free curve.
     * </p>
     *
     * <p>
     *  The first three arguments - feed, roots and columns -
     *  can be collapsed into one {@link morn.Product|Product} argument.
     *  When invoked in this manner, this method requires 4 arguments only.
     * </p>
     *
     * @param {string} feed
     * @param {(string|string[])} roots
     * @param {(string|string[])} columns
     * @param {?(string|lim.IDate)} curveDate - Pass <em>null</em> for most recent curve.
     * @param {(string|morn.DeliveryType)} deliveryType
     * @param {morn.UnitCalculator} unitCalculator - A callback function that
     *        translate a period (passed as two arguments, start and end dates)
     *        into a number of units.
     * @param {?(string|lim.IDate)} [asOfDate] - Prices (or values) as-of the given date.
     *                              Meaning: any corrections or changes made to contracts
     *                              after <code>asOfDate</code> are ignored.
     * @returns {morn.TimeSeries}
     * @see morn.ForwardCurve.arbitrageFree
     */
    arbitrageFreeCustom: function ( feed,
                                    roots,
                                    columns,
                                    curveDate,
                                    deliveryType,
                                    unitCalculator,
                                    asOfDate ) {

        var args = _parseFwdCurveArgs(arguments, 4);

        var unitCalc = _validUnitCalculator(args.argsReorg[3], "unitCalculator");

        var dataPointGetterInit = function (contractVs, deliveryType) {

            return _arbFreeDataPointGetter(
                contractVs,
                deliveryType,
                _newDerivedValueCalculator(unitCalc)
            );
        };

        return _getCurveTimeSeries(args, dataPointGetterInit);
    },

    /**
     * Creates a forward curve from server payload.
     * @param {(Object[]|morn.ContractValue[])} contractValues
     * @param {(lim.IDate|string)} curveDate
     * @param {(morn.DeliveryType|string)} deliveryType
     * @returns {morn.TimeSeries}
     */
    fromContractValues: function (contractValues, curveDate, deliveryType) {

        return _curveFromContractValues(
            _arbDataPointGetterFaster,
            contractValues,
            curveDate,
            deliveryType
        );
    },

    /**
     * <p>
     *  Creates a non-shaped forward curve, using arbitrage or
     *  arbitrage-free logic.
     * </p>
     *
     * <p>
     *  The first three arguments - feed, roots and columns -
     *  can be collapsed into one {@link morn.Product|Product} argument.
     *  When invoked in this manner, this method requires 3 arguments
     *  only (5 at most.)
     * </p>
     *
     * @deprecated Still here for backward compatibility with old syntax,
     *             but users should use {@link forward_curve},
     *             {@link forward_curve_arbitrage_free} or
     *             {@link forward_curve_arbitrage_free_basic} instead.
     * @param {string} feed
     * @param {(string|string[])} roots
     * @param {(string|string[])} - columns
     * @param {?(string|lim.IDate)} curveDate - Pass <em>null</em> for most recent curve.
     * @param {(string|morn.DeliveryType)} deliveryType
     * @param {boolean} [isArbFree] - Whether to use arbitrage (<em>false</em>)
     *                    or arbitrage-free (<em>true</em>) logic.
     * @param [showNaNs] {boolean} Whether the returned time-series
     *                             is allowed to contain NaN values.
     * @returns {morn.TimeSeries}
     */
    createNonShaped: function () {

        var args            = _toProduct(arguments),
            useArbFree      = _validBool(args, 3, "isArbFree", false),
            keepNans        = _validBool(args, 4, "showNaNs",  true),
            dataPointGetter = (  (useArbFree)
                                ? _preciseArbFreeDataPointGetter
                                : _arbDataPointGetterFaster ),

            // Need to validate \`curveDate\` and \`deliveryType\` arguments.
            fwArgs = _parseFwdCurveArgs([
                args[0],  // product
                args[1],  // curveDate
                args[2]   // deliveryType
            ], 3),

            curveTs = _getCurveTimeSeries(fwArgs, dataPointGetter );

        if (!keepNans)
            curveTs = curveTs.dropNaNs();

        return curveTs;
    },

    /**
     * Applies shaping functions (a.k.a. <em>shapes</em>) to
     * a time-series.
     *
     * @deprecated Still here for backward compatibility with old syntax,
     *             but users should use {@link forward_curve},
     *             {@link forward_curve_arbitrage_free} or
     *             {@link forward_curve_arbitrage_free_basic} instead, to
     *             create a base curve, then use one of the many shaping
     *             functions to modify data-points.
     * @param curve {morn.TimeSeries}
     * @param shaping_functions {function[]}
     * @returns {morn.TimeSeries}
     */
    createShaped: function(curve, shaping_functions) {

        if (!(curve instanceof TimeSeries))
            throw new TypeError("curve: TimeSeries");

        if (!Arrays.isArrayOf(shaping_functions, 'function'))
            throw new TypeError("shaping_functions: function[]");

        //Go through each shaping function
        for (var i = 0, len = shaping_functions.length; i < len; i++)
            curve = curve.modifyDataPoints("shape_" + (i + 1), shaping_functions[i]);

        return curve;
    },


    /**
     * <p>
     *  Returns a TimeSeries created from expired contracts' value (price)
     *  on their expiration date.
     *  The TimeSeries dates are that of the contracts delivery start date
     *  (similar to a forward curve.)
     * </p>
     *
     * <p>
     *  The first three arguments - feed, root and columns -
     *  can be collapsed into one {@link morn.Product|Product} argument.
     *  When invoked in this manner, this method requires 2 argument
     *  only (3 at most).
     * </p>
     *
     * @param feed {string} Name of feed.
     * @param root {string} Root symbol; only one allowed.
     * @param columns {(string|string[])} Name of column(s).
     * @param lookBack {(integer|string|lim.IDate)}
     *                        How many (expired) contracts to return.
     *                        If a string, must be a parsable date representation.
     * @param [expiredAsOf=today] {(string|lim.IDate)}
     *                        When complementing a forward curve,
     *                        <code>expiredAsOf</code> should be the same date
     *                        as <em>curve date</em> used to create the curve.
     *                        If a string, must be a parsable date representation.
     * @returns {morn.TimeSeries}
     */
    getValuesAtExpiration: function (feed, root, columns, lookBack, expiredAsOf) {
        return _getExpiredContractValues.apply(this, arguments);
    }
};

/* ******************************************************************
  * Public Object
  * ****************************************************************** */



morn.ForwardCurve = Object.freeze(ForwardCurve);

/**
 * Unit of measure. This object structure is exposed for debugging purposes only and
 * may change in the future without warning.
 * @typedef {Object} morn.UnitOfMeasure.Unit
 * @property {string} code code, or abbreviation.
 * @property {string} description Description.
 * @property {string} type MASS, VOLUME, ENERGY, POWER.
 * @property {string[]} aliases Other abbreviations this unit-of-measure is known to use.
 */

/* ******************************************
  * Imports
  * ****************************************** */
var Strings = lim.String;
var Arrays = lim.Arrays;
var TimeSeries = morn.TimeSeries;
var temperatureUOMs = Object.freeze({"celsius":"celsius","fahrenheit":"fahrenheit","kelvin":"kelvin"});

/**
 * @param {string} source
 * @param {string} target
 * @returns {number} Source-to-target conversion factor.
 */
function _getFactor (source, target) {
    // Nothing much to do here, other than to call into Rhino.
    return getUomConversionFactorJava(
        Strings.requireNonEmpty(source, "source"),
        Strings.requireNonEmpty(target, "target")
    );
}

/**
 * @param {string} source
 * @param {string} target
 * @returns {Function} Source-to-target conversion function.
 */
function _getFormula(source, target) {
  return getFormula(
      Strings.requireNonEmpty(source, "source"),
      Strings.requireNonEmpty(target, "target")
  );
}

/**
 * @param {function}
 * @returns {Number}
 */
function _converter(formula) {
  return function (n) {
    return formula.convert(n);
  }
}

/**
 * @param {string} source
 * @param {string} target
 * @returns {true}.
 */
function _isTemperatureUom(source, target) {
    return temperatureUOMs.hasOwnProperty(source.toLowerCase())
        && temperatureUOMs.hasOwnProperty(target.toLowerCase());
}

/**
 * <p>
 *  Utilities for units of measures - such as mass, volume, energy and power.
 *  This namespace contains methods to convert data to different units of measures, as well
 *  as a reference for supported units of measures.
 * </p>
 * <p>
 *  Supported units of measure:
 * </p>
 *
 * <table>
 *  <tr><th colspan="3"> Mass/Weight </th></tr>
 *  <tr><td> mg   </td><td> Milligram (1/1000 gram) </td><td> </td></tr>
 *  <tr><td> cg   </td><td> Centigram (100 grams)   </td><td> </td></tr>
 *  <tr><td> gm   </td><td> Gram (1/1000 Kg)        </td><td> </td></tr>
 *  <tr><td> kg   </td><td> Kilogram (1000 grams)   </td><td> </td></tr>
 *  <tr><td> 10kg </td><td> 10 Kilograms (10 Kg)    </td><td> </td></tr>
 *  <tr><td> mt   </td><td> Metric ton (1000 Kg)    </td><td> </td></tr>
 *  <tr><td> toz  </td><td> Troy ounce (31.1 grams) </td><td> </td></tr>
 *  <tr><td> lbt  </td><td> Troy pound (12 toz)     </td><td> </td></tr>
 *  <tr><td> oz   </td><td> Ounce (1/16 LB)         </td><td> </td></tr>
 *  <tr><td> lb   </td><td> Pound (16 ounces)       </td><td> </td></tr>

  *  <tr><td> gr </td><td> Grain </td><td> </td>
  *      <td> {@link https://en.wikipedia.org/wiki/Grain_(unit)} </td></tr>
  *
  *  <tr><td> scwt </td><td> Short Hundredweight (Centum weight) </td><td>cwt </td>
  *      <td> {@link https://www.investopedia.com/terms/h/hundredweight.asp} </td></tr>
  *
  *  <tr><td> lcwt </td><td> Long Hundredweight (Centum weight) </td><td> </td>
  *      <td> {@link https://www.investopedia.com/terms/h/hundredweight.asp} </td></tr>
  *
  *  <tr><td> ston </td><td> Short ton (U.S.) </td><td> s.ton, st </td>
  *      <td> {@link https://www.metric-conversions.org/weight/short-tons-to-pounds.htm} </td></tr>
  *
  *  <tr><td> lton </td><td> Long ton (U.K.) </td><td> l.ton, lt, ton </td>
  *      <td> {@link https://www.metric-conversions.org/weight/long-tons-to-pounds.htm} </td></tr>
  * </table>
  *
  * <table>
  *  <tr><th colspan="3"> Volume </th></tr>
  *  <tr><td>ltr </td><td> Liter                    </td><td> </td></td>
  *  <tr><td>hl </td><td> Hectoliter (100 liters)   </td><td> </td></td>
  *  <tr><td>kl </td><td> Kiloliter (1000 liters)   </td><td> </td></td>
  *  <tr><td>cl </td><td> Centiliter (1/100 liter)  </td><td> </td></td>
  *  <tr><td>ml </td><td> Milliliter (1/1000 liter) </td><td> </td></td>
  *  <tr><td>m3 </td><td> Cubic meter               </td><td> cbm </td></td>
  *
  *  <tr><td> gal.us </td><td> U.S. Liquid Gallon (3.7854 liters)      </td><td> gal </td>
  *      <td> {@link http://www.gallonstoliters.com} </td></tr>
  *  <tr><td> gal.uk </td><td> Imperial Gallon (4.54609 liters)        </td><td> </td>
  *      <td> {@link http://www.gallonstoliters.com} </td></tr>
  *
  *  <tr><td> fl.us </td><td> Fluid Ounce U.S. (1/128 U.S. Gallon)     </td><td> </td>
  *      <td> {@link https://en.wikipedia.org/wiki/Fluid_ounce} </td></tr>
  *  <tr><td> fl.uk </td><td> Fluid Ounce U.K. (1/160 Imperial Gallon) </td><td> </td>
  *      <td> {@link https://en.wikipedia.org/wiki/Fluid_ounce} </td></tr>
  *
  *  <tr><td> cf </td><td> Cubic feet           </td><td> </td></tr>
  *  <tr><td> mcf </td><td> Thousand cubic feet </td><td> </td></tr>
  *  <tr><td> mmcf </td><td> Million cubic feet </td><td> </td></tr>
  *  <tr><td> bcf </td><td> Billion cubic feet  </td><td> </td></tr>
  *  <tr><td> tcf </td><td> Trillion cubic feet </td><td> </td></tr>
  *  <tr><td> af </td><td> Acre-feet            </td><td> </td></tr>
  *  <tr><td> taf </td><td> Thousand acre-feet  </td><td> </td></tr>
  *
  *  <tr><td> bbl.cl  </td><td> Barrel of crude oil           </td><td> bbl  </td></tr>
  *  <tr><td> tbbl.cl </td><td> Thousand barrels of crude oil </td><td> tbbl </td></tr>
  *  <tr><td> mbbl.cl </td><td> Million barrels of crude oil  </td><td> mbbl </td></tr>
  * </table>
  *
  * <table>
  *  <tr><th colspan="3"> Energy </th></tr>
  *
  *  <tr><td> J  </td><td> Joule                     </td><td> </td></tr>
  *  <tr><td> kJ </td><td> Kilojoule (1000 joules)   </td><td> </td></tr>
  *  <tr><td> MJ </td><td> Megajoule (10^6 joules)   </td><td> </td></tr>
  *  <tr><td> GJ </td><td> Gigajoule (10^9 joules)   </td><td> </td></tr>
  *  <tr><td> TJ </td><td> Terajoule (10^12 joules)  </td><td> </td></tr>
  *  <tr><td> PJ </td><td> Petajoule (10^15 joules)  </td><td> </td></tr>
  *  <tr><td> EJ </td><td> Exajoule (10^18 joules)   </td><td> </td></tr>
  *  <tr><td> ZJ </td><td> Zettajoule (10^21 joules) </td><td> </td></tr>
  *  <tr><td> YJ </td><td> Yottajoule (10^24 joules) </td><td> </td></tr>
  *
  *  <tr><td> Wh  </td><td> Watt hour      </td><td> </td></tr>
  *  <tr><td> kWh </td><td> Kilowatt hour  </td><td> </td></tr>
  *  <tr><td> MWh </td><td> Megawatt hour  </td><td> </td></tr>
  *  <tr><td> GWh </td><td> Gigawatt hour  </td><td> </td></tr>
  *  <tr><td> TWh </td><td> Terawatt hour  </td><td> </td></tr>
  *  <tr><td> PWh </td><td> Petawatt hour  </td><td> </td></tr>
  *  <tr><td> EWh </td><td> Exawatt hour   </td><td> </td></tr>
  *  <tr><td> ZWh </td><td> Zettawatt hour </td><td> </td></tr>
  *  <tr><td> YWh </td><td> Yottawatt hour </td><td> </td></tr>
  *
  *  <tr><td> BTU </td><td> British Thermal Unit      </td><td> </td></tr>
  *  <tr><td> MBTU </td><td> Million BTUs (10^6 BTUs) </td><td> MMBTU </td></tr>
  *
  *  <tr><td> thm.us </td><td> U.S. Therm (105,480,400 joules)          </td><td> thm </td>
  *      <td> {@link https://en.wikipedia.org/wiki/Therm} </td></tr>
  *  <tr><td> dth.us </td><td> U.S. Decatherm (10 U.S. therms, ~1 MBTU) </td><td> dth </td>
  *      <td> {@link https://en.wikipedia.org/wiki/Therm} </td></tr>
  *  <tr><td> thm.uk </td><td> U.K. Therm (105,505,585.257348 joules)   </td><td> </td>
  *      <td> {@link https://en.wikipedia.org/wiki/Therm} </td></tr>
  *  <tr><td> dth.uk </td><td> U.K. Decatherm (10 U.K. therms, ~1 MBTU) </td><td> </td>
  *      <td> {@link https://en.wikipedia.org/wiki/Therm} </td></tr>
  *  <tr><td> thm.ec </td><td> Therm EC (105,506,000 joules)            </td><td> </td>
  *      <td> {@link https://en.wikipedia.org/wiki/Therm} </td></tr>
  *  <tr><td> dth.ec </td><td> Decatherm EC (10 therms EC, ~1 MBTU)     </td><td> </td>
  *      <td> {@link https://en.wikipedia.org/wiki/Therm} </td></tr>
  * </table>
  *
  * <table>
  *  <tr><th colspan="3"> Power </th></tr>
  *  <tr><td> W   </td><td> Watt                    </td></tr>
  *  <tr><td> daW </td><td> Decawatt (10 watts)     </td></tr>
  *  <tr><td> hW  </td><td> Hectowatt (100 watts)   </td></tr>
  *  <tr><td> kW  </td><td> Kilowatt (1000 watts)   </td></tr>
  *  <tr><td> MW  </td><td> Megawatt (10^6 watts)   </td></tr>
  *  <tr><td> GW  </td><td> Gigawatt (10^9 watts)   </td></tr>
  *  <tr><td> TW  </td><td> Terawatt (10^12 watts)  </td></tr>
  *  <tr><td> PW  </td><td> Petawatt (10^15 watts)  </td></tr>
  *  <tr><td> EW  </td><td> Exawatt (10^18 watts)   </td></tr>
  *  <tr><td> ZW  </td><td> Zettawatt (10^21 watts) </td></tr>
  *  <tr><td> YW  </td><td> Yottawatt (10^24 watts) </td></tr>
  * </table>
  *
  * <table>
  *  <tr><th colspan="3"> Temperature </th></tr>
  *  <tr><td> Celsius    </td><td> Celsius        </td></tr>
  *  <tr><td> Fahrenheit </td><td> Fahrenheit     </td></tr>
  *  <tr><td> Kelvin     </td><td> Kelvin         </td></tr>
  * </table>
  *
  * @namespace
  * @alias morn.UnitOfMeasure
  */
var UnitOfMeasure = Object.freeze( /** @lends morn.UnitOfMeasure */ {
    /**
     * @param {string} source Unit of measure.
     * @param {string} target Unit of measure.
     * @returns {number} Multiplication factor to convert from <code>source</code>
     *          to <code>target</code>.
     * @throws {TypeError} If either <code>source</code> or <code>target</code> are not strings,
     *         or if either is empty.
     * @throws {IllegalArgumentException} If either <code>source</code> or <code>target</code>
     *         is not recognized.
     * @throws {UnsupportedOperationException} If the conversion from <code>source</code> to
     *         <code>target</code> is not possible.
     * @see {morn.UnitOfMeasure}
     */
    getConversionFactor: function (source, target) {
        if(_isTemperatureUom(source,target))
        {
            throw Error("Factor conversion not possible for ");
        }
        return _getFormula(source, target).convert(1);
    },

    /**
     * For debugging purposes only, may not be supported going forward.
     * @returns {morn.UnitOfMeasure.Unit[]} List of valid units of measure.
     */
    listUnits: function () {
        // Nothing much to do here, other than to call into Rhino.
        return JSON.parse(getAllUOMsJava());
    },

    /**
     * For debugging purposes only, may not be supported going forward.
     * @param {string} source Unit of measure.
     * @returns {morn.UnitOfMeasure.Unit[]} List of valid units of measure to which <code>source</code> can
     *          be converted to.
     */
    listCompatibilities: function (source) {
        // Nothing much to do here, other than to call into Rhino.
        return JSON.parse(getUOMCompatibilitiesJava(Strings.requireNonEmpty(source)));
    },


  /**
     * Applies a unit-of-measure conversion to <code>data</code>.
     * @param {(number|number[]|Array.<number[]>|morn.TimeSeries)} data Data to convert.
     * @param {string} source Unit of measure to convert from.
     * @param {string} target Unit of measure to convert to.
     * @returns {(number|number[]|Array.<number[]>|morn.TimeSeries)} <code>data</code>
     *          converted to the <code>target</code> unit of measure.
     * @throws {TypeError} If <code>data</code> is not an acceptable object,
     *         if <code>target</code> or <code>source</code> are not supported units of measure.
     * @see morn.UnitOfMeasure
     */
  toUOM: function (data, source, target) {
      var formulaFunction = _getFormula(source, target);
      if (data instanceof TimeSeries) {
          return TimeSeries.modifyDataPoints(data, target,
              function (date, value) {
                  return formulaFunction.convert(value);
              });
      } else if (typeof data === "number") {
          return formulaFunction.convert(data);
      } else if (Arrays.isArrayOf(data, "number")) {
          return data.map(_converter(formulaFunction));
      } else if (Arrays.isValid(data, function (r) {
          return Arrays.isArrayOf(r, "number")
      })) {
          var converter = _converter(formulaFunction);
          return data.map(
              function (row) {
                  return row.map(converter);
              });
      } else {
          throw new TypeError(
              "data: number, number[], number[][] or morn.TimeSeries");
      }
  }
});

/* ******************************************
  * Exports
  * ****************************************** */
morn.UnitOfMeasure = UnitOfMeasure;


/* *******************************************************
  * interfaces
  * ******************************************************* */

// Keep \`{}\` off private typedef (Object) definitions; keeps it out of public JsDocs.
/**
 * @typedef Object morn.CurrencyUtils.ExchangeRateTS
 * @property {morn.TimeSeries} fromBase Exchange rates to convert from base currency (Euro).
 * @property {?morn.TimeSeries} toBase Exchange rates to convert to base currency (Euro).
 */

/**
 * @typedef Object morn.CurrencyUtils.DbRecord
 * @property {string} code Currency code
 * @property {morn.Product} product
 * @property {?morn.CurrencyUtils.ExchangeRateTS} exchangeRates
 * @private
 */

/* *******************************************************
  * imports
  * ******************************************************* */
var Numbers = lim.Number;
var Strings = lim.String;
var IDate = lim.IDate;
var Product = morn.Product;
var TimeSeries = morn.TimeSeries;

/* *******************************************************
  * Private, static variables
  * ******************************************************* */

var EUR_FEED = 'ECB_EuroFxRef';
var EUR_KEY = 'Currency';
var EUR_COL = 'Rate';

/**
 * Database of ECB_EuroFxRef time-series.
 * @type {Object.<string, morn.CurrencyUtils.DbRecord>}
 */
var _eurDb = null;

/**
 * DB of exchange rates.  Key format is "<<src>><<target>>".
 * @type {Object.<string, morn.TimeSeries>}
 * @private
 */
var _exchRates = {};

/* *******************************************************
  * Private, static methods.
  * ******************************************************* */

/**
 * @param {string} code Currency code.
 * @param {morn.Product} product Product.
 * @returns {morn.CurrencyUtils.DbRecord} New DB record with code, product and null exchangeRates.
 * @private
 */
function _newDbRec (code, product) {
    return {
        code: code,
        product: product,
        exchangeRates: null
    };
}

/**
 * @param {morn.TimeSeries} fromBase
 * @param {?morn.TimeSeries} toBase
 * @returns {morn.CurrencyUtils.ExchangeRateTS}
 * @private
 */
function _newExchRateRec (fromBase, toBase) {
    return {
        fromBase: fromBase,
        toBase: toBase
    };
}

/**
 * Creates a Key object specific to EUR_FEED.  Convenience method to work around
 * lack of ES6 feature.  Otherwise, all we need is \`{[EUR_KEY]: currency}\`.
 * @param {string} currency Currency code, upper-case.
 * @returns {{Currency: string}}
 * @private
 */
function _eurKey (currency) {
    var key = {};
    key[EUR_KEY] = currency;
    return key;
}

/**
 * Initializes the EURO database (unless already initialized) and returns it.
 * @returns {Object<string, morn.CurrencyUtils.DbRecord>}
 * @private
 */
function _initEurDb () {
    var db = {};
    var products = Product.find(EUR_FEED, _eurKey('.*'), [ EUR_COL ], true);
    products.forEach( /** @param {morn.Product} product */ function (product) {
        var code = product.key()[EUR_KEY].toUpperCase();
        db[code] = _newDbRec(code, product);
    });

    // Add hard-coded EUR currency, for code simplification (avoid unnecessary \`if\` statements).
    var eur = _newDbRec(
        "EUR",
        Product.withKey(EUR_FEED, _eurKey('EUR'), [ EUR_COL ])
    );
    var eurTs = TimeSeries.create([
        // Assume we'll never see dates prior to 1/1/1000
        [ '1000-01-01', 1]
    ]);
    eur.exchangeRates = _newExchRateRec(eurTs, eurTs);

    db["EUR"] = eur;

    // Add new currencies which depend on other currencies
    db["EUC"] =  _newDbRec(
        "EUC",
        Product.withKey(EUR_FEED, _eurKey('EUC'), [ EUR_COL ])
    );
    var eucTs = TimeSeries.create([
        [ '1000-01-01', 100]
    ]);
    db["EUC"].exchangeRates = _newExchRateRec(eucTs,null );
    db["USC"] = _newDbRec(
            "USC",
            Product.withKey(EUR_FEED, _eurKey('USC'), [ EUR_COL ])
        );
    db["GBPP"] = _newDbRec(
            "GBPP",
            Product.withKey(EUR_FEED, _eurKey('GBPP'), [ EUR_COL ])
        );
    db["GBX"] = _newDbRec(
        "GBX",
        Product.withKey(EUR_FEED, _eurKey('GBX'), [ EUR_COL ])
    );
    return db;
}


/**
 * Returns the EURO database, initializes it if needed.
 * @returns {Object<string, morn.CurrencyUtils.DbRecord>}
 * @private
 */
function _getEurDb () {
    if (_eurDb === null) {
        // atomic assignment, to avoid partial data being visible upon error.
        _eurDb = _initEurDb();
    }
    return _eurDb;
}

/**
 * @param {string} code Currency code.
 * @param {Object.<string, morn.CurrencyUtils.DbRecord>} db
 * @returns {morn.CurrencyUtils.DbRecord} TimeSeries containing exchange rates relative to the Euro.
 * @throws {Error} If \`code\` is invalid.
 * @private
 */
function _getRec (code, db) {
    if (!db.hasOwnProperty(code)) {
        throw new Error('Unrecognized currency code: ' + code);
    }
    return db[code];
}

/**
 * @param {string} code Currency code.
 * @param {string} name Name given to \`code\` for when error must be thrown.
 * @returns {string} All-upper-case \`code\`.
 * @throws {TypeError} If \`code\` is not a string or is empty.
 * @private
 */
function _upper (code, name) {
    var argName = (arguments.length > 1 ? name : 'code');
    return Strings.requireNonEmpty(code, argName).toUpperCase();
}

/**
 * @param {string} code Currency code, all-upper-case.
 * @returns {boolean} Whether \`code\` is a recognized currency code.
 * @private
 */
function _isValidCode (code) {
    return _getEurDb().hasOwnProperty(code);
}

/**
 * Validates a currency code.
 * @param {string} code Currency code to validate, may be lower-case.
 * @param {string} name Name given to \`code\` for when error must be thrown.
 * @returns {string} Always \`code.toUpperCase()\`.
 * @throws {TypeError} If \`code\` is not a string or is empty.
 * @throws {Error} If \`code\` is not a recognized currency code.
 * @private
 */
function _validCode (code, name) {
    var codeUC = _upper(code, name);
    if (!_isValidCode(codeUC)) {
        throw new Error('unsupported currency code: ' + code);
    }
    return codeUC;
}

/**
 * @param {string} code Currency code, all caps.
 * @returns {morn.CurrencyUtils.ExchangeRateTS} Exchange rates to convert to/from base currency.
 * @throws {TypeError} If \`code\` is not a string or is empty.
 * @throws {Error} If \`code\` is not recognized.
 * @private
 */
function _getEuroTs (code) {
    var rec = _getRec(code, _getEurDb());
    if (rec.exchangeRates === null) {
        rec.exchangeRates = _newExchRateRec(TimeSeries.getServerData(rec.product), null);
    }
    return rec.exchangeRates;
}

/**
 * @param {string} code Currency code, all caps.
 * @returns {morn.TimeSeries} Time series containing exchange rates from Euro to \`code\`.
 * @throws {TypeError} If \`code\` is not a string or is empty.
 * @throws {Error} If \`code\` is not recognized.
 * @private
 */
function _getRateFromEuro (code) {
    return _getEuroTs(code).fromBase;
}

/**
 * @param {string} code Currency code, all caps.
 * @returns {morn.TimeSeries} Time series containing exchange rates from \`code\` to Euro.
 * @throws {TypeError} If \`code\` is not a string or is empty.
 * @throws {Error} If \`code\` is not recognized.
 * @private
 */
function _getRateToEuro (code) {
    var exchRateTs = _getEuroTs(code);
    if (exchRateTs.toBase === null) {
        // lazy initialized, for optimimum memory footprint
        exchRateTs.toBase = TimeSeries.cellQuotient(1, exchRateTs.fromBase);
    }
    return exchRateTs.toBase;
}

/**
 *
 * @param {string} srcCurrency Currency code, all caps.
 * @param {string} targetCurrency Currency code, all caps.
 * @returns {morn.TimeSeries} Time-series that contain a history of exchange rates.
 * @private
 */
function _getExchangeRate (srcCurrency, targetCurrency) {
    var key = "<<" + srcCurrency + ">><<" + targetCurrency + ">>";
    if (!_exchRates.hasOwnProperty(key)) {
        _exchRates[key] = TimeSeries.rowMultiply(
            _getRateFromEuro(targetCurrency).dropNaNs(),
            _getRateToEuro(srcCurrency).dropNaNs()
        ).dropNaNs();
    }
    return _exchRates[key];
}



/**
 * <p>
 *  Utilities to retrieve exchange rates and convert time-series to different currencies.
 * </p>
 * <p>
 *  Available currencies:
 * </p>
 * <ul>
 *  <li> AUD - Australia Dollar </li>
 *  <li> BGN - Bulgaria Lev </li>
 *  <li> BRL - Brazil Real </li>
 *  <li> CAD - Canada Dollar </li>
 *  <li> CHF - Switzerland Franc </li>
 *  <li> CNY - China Yuan Renminbi </li>
 *  <li> CYP - Cyprus Pound (replaced by the Euro January 1 2008) </li>
 *  <li> CZK - Czech Republic Koruna </li>
 *  <li> DKK - Denmark Krone </li>
 *  <li> EEK - Estonia Kroon (replaced by the Euro January 1 2011) </li>
 *  <li> EUR - European Union Euro </li>
 *  <li> GBP - United Kingdom Pound </li>
 *  <li> HKD - Hong Kong Dollar </li>
 *  <li> HRK - Croatia Kuna </li>
 *  <li> HUF - Hungary Forint </li>
 *  <li> IDR - Indonesia Rupiah </li>
 *  <li> ILS - Israel Shekel </li>
 *  <li> INR - India Rupee </li>
 *  <li> ISK - Iceland Krona </li>
 *  <li> JPY - Japan Yen </li>
 *  <li> KRW - Korea (South) Won </li>
 *  <li> LTL - Lithuania Litas (replaced by the Euro January 1 2015) </li>
 *  <li> LVL - Latvia Lats (replaced by the Euro January 1 2014) </li>
 *  <li> MTL - Malta Lira (replaced by the Euro January 1 2008) </li>
 *  <li> MXN - Mexico Peso </li>
 *  <li> MYR - Malaysia Ringgit </li>
 *  <li> NOK - Norway Krone </li>
 *  <li> NZD - New Zealand Dollar </li>
 *  <li> PHP - Philippines Piso </li>
 *  <li> PLN - Poland Zloty </li>
 *  <li> ROL - Romania Leu (replaced by RON on July 1 2005) </li>
 *  <li> RON - Romania Leu </li>
 *  <li> RUB - Russia Ruble </li>
 *  <li> SEK - Sweden Krona </li>
 *  <li> SGD - Singapore Dollar </li>
 *  <li> SIT - Slovenia Tolar (replaced by the Euro January 1 2007) </li>
 *  <li> SKK - Slovakia Koruna (replaced by the Euro January 1 2009) </li>
 *  <li> THB - Thailand Baht </li>
 *  <li> TRL - Turkey Lira (replaced by TRY January 1 2005) </li>
 *  <li> TRY - Turkey Lira </li>
 *  <li> USD - United States Dollar </li>
 *  <li> ZAR - South Africa Rand </li>
 * </ul>
 *
 * @namespace
 * @alias morn.CurrencyUtils
 */
var CurrencyUtils = Object.freeze( /** @lends morn.CurrencyUtils */ {

    /**
     * @param {string} code Currency code to validate.
     * @returns {boolean} Whether <code>code</code> is a recognized currency code.
     * @throws {TypeError} If <code>code</code> is not a string or is empty.
     */
    isCurrencyCode: function (code) {
        return _isValidCode(_upper(code, 'code'));
    },

    /**
     * Converts <code>timeSeries</code> to the desired target currency.
     * @param {morn.TimeSeries} timeSeries Time-series to convert.
     * @param {string} srcCurrency Currency code that represent <code>timeSeries</code>
     *        currently.
     * @param {string} targetCurrency Target currency code.
     * @returns {morn.TimeSeries} A new time-series object containing the same data,
     *          converted to the desired currency (target).
     * @throws {Error} If <code>srcCurrency</code> or <code>targetCurrency</code> is invalid.
     * @throws {Error} If <code>timeSeries</code> has data prior to when exchange rate data
     *         became available.
     */
    toCurrency: function (data, srcCurrency, targetCurrency) {
        TimeSeries.requireTimeSeries(data, 'data');
        var src = _validCode(srcCurrency, 'srcCurrency');
        var target = _validCode(targetCurrency, 'targetCurrency');
        var currMap = {
            "USC" :"USD",
            "GBPP" : "GBP",
            "GBX" : "GBP"
        };
        var newTarget = currMap[target]?currMap[target]:target;
        var newSrc  = currMap[src]?currMap[src]:src;
        var exchRate = _getExchangeRate(newSrc, newTarget);

        return data.modifyDataPoints(target, function (date, value) {
            if(currMap[target] && !currMap[src]){
                return (value *100 * exchRate.valueAsOf(date, 0));
            }
            else if( currMap[src] && !currMap[target]){
                return ((value * exchRate.valueAsOf(date, 0))/100);
            }

            return (value * exchRate.valueAsOf(date, 0));
        });
    },

    /**
     * @param {string} srcCurrency Source currency code.
     * @param {string} targetCurrency Target currency code.
     * @returns {morn.TimeSeries} History of exchange-rate conversion factors,
     *          source-to-target, as a time-series.
     * @throws {Error} If <code>srcCurrency</code> or <code>targetCurrency</code> is invalid.
     */
    getExchangeRates: function (srcCurrency, targetCurrency) {
        return _getExchangeRate(
            _validCode(srcCurrency, 'srcCurrency'),
            _validCode(targetCurrency, 'targetCurrency')
        );
    },

    /**
     * @param {string} srcCurrency Source currency code.
     * @param {string} targetCurrency Target currency code.
     * @param {(lim.IDate|string)} date Effective date for the desired exchange rate.
     * @returns {number} Exchange rate effective as of <code>date</code> (source-to-target),
     *          NaN if no exchange rate was available as of <code>date</code>.
     * @throws {Error} If <code>srcCurrency</code> or <code>targetCurrency</code> is invalid.
     */
    getExchangeRate: function (srcCurrency, targetCurrency, date) {
        var src = _validCode(srcCurrency, 'srcCurrency');
        var target = _validCode(targetCurrency, 'targetCurrency');
        var dt = IDate.valueOf(date);
        return _getExchangeRate(src, target).valueAsOf(dt, 0);
    }
});

/* *******************************************************
  * exports
  * ******************************************************* */
morn.CurrencyUtils = CurrencyUtils;


/*
  * IMPORTANT:
  *
  * The purpose of this file is to promote functions, classes and constant
  * values defined in other files, from their respective namespace to the global
  * context.
  *
  * The purpose of this file IS NOT to implement ANY functionality.
  *
  */

/* *********************************************
  * "import"
  * ********************************************** */
const { IDate } = lim;
const { Holidays } = morn;
const { TimeSeries } = morn;
const { Contract } = morn;

const FwdCurve = morn.ForwardCurve;
const Currency = morn.CurrencyUtils;
const Uom = morn.UnitOfMeasure;
const { Utils } = morn;

const True = true;
const TRUE = true;
const False = false;
const FALSE = false;

const { Logger } = lim;
const { TimeZone } = lim;
const { Calendar } = morn;
const { Parameters } = morn;
const { Feed } = morn;
const { Product } = morn;
const { DeliveryType } = morn;
const { Reducer } = TimeSeries;
const { Test } = lim;
const as = Object.freeze(/** @lends as */ {
  /**
         * Creates a date based on the arguments provided.
         * @method
         * @param obj {...*}
         * @return {lim.IDate}
         * @see lim.IDate.valueOf
         */
  Date: IDate.valueOf,

  /**
         * Creates a date based on the arguments provided.
         * @method
         * @param obj {...*}
         * @return {lim.IDate}
         * @see lim.IDate.valueOf
         */
  date: IDate.valueOf,

  /**
         * Convert to CSV format.
         * @method
         * @param obj {(morn.TimeSeries|Array|Array[])}
         * @return {string}
         * @see morn.Utils.toCsv
         */
  Csv: Utils.toCsv,
  /**
         * Convert to CSV format.
         * @method
         * @param obj {(morn.TimeSeries|Array|Array[])}
         * @return {string}
         * @see morn.Utils.toCsv
         */
  csv: Utils.toCsv,

  /**
         * Convert to JSON format.
         * @method
         * @param obj {(morn.TimeSeries|Object)}
         * @return {string}
         * @see morn.Utils.toJson
         */
  Json: Utils.toJson,
  /**
         * Convert to JSON format.
         * @method
         * @param obj {(morn.TimeSeries|Object)}
         * @return {string}
         * @see morn.Utils.toJson
         */
  json: Utils.toJson,

  /**
         * Return the string representation of an object.
         * @method
         * @param obj {*}
         * @return {string}
         * @see lim.String.toString
         */
  String: lim.String.toString,
  /**
         * Return the string representation of an object.
         * @method
         * @param obj {*}
         * @return {string}
         * @see lim.String.toString
         */
  string: lim.String.toString,
});


/**
 * Returns a holiday calendar.
 *
 * <p>
 *  Callers can limit the scope of the search by providing
 *  <code>start</code> and/or <code>end</code>.  Both are
 *  strings formatted as "yyyy-MM-dd", "yyyy-MM" or "yyyy".
 *  Using the latter two as the <code>end</code> value
 *  makes it exclusive; "2015-01-01" will not be included
 *  when <code>end == "2015"</code>.  Otherwise, <code>end</code>
 *  is inclusive (when provided.)
 * </p>
 *
 * @method
 * @param calendarName {string} Example: "NERC".
 * @param [start] {string} "yyyy-MM-dd", "yyyy-MM" or "yyyy".
 * @param [end] {string} "yyyy-MM-dd", "yyyy-MM" or "yyyy".
 * @return {morn.Holidays}
 */
function holiday_calendar(calendarName, start, end) {}


/**
         * <p>
         *  Returns a TimeSeries created from expired contracts' value (price)
         *  on their expiration date.
         *  The TimeSeries dates are that of the contracts delivery start date
         *  (similar to a forward curve.)
         * </p>
         *
         * <p>
         *  The first three arguments - feed, root and columns -
         *  can be collapsed into one {@link morn.Product|Product} argument.
         *  When invoked in this manner, this method requires 2 argument
         *  only (3 at most).
         * </p>
         *
         * @method
         * @param feed {string} Name of feed.
         * @param root {string} Root symbol; only one allowed.
         * @param columns {(string|string[])} Name of column(s).
         * @param lookBack {(integer|string|lim.IDate)}
         *                        How many (expired) contracts to return.
         *                        If a string, must be a parsable date representation.
         * @param [expiredAsOf=today] {(string|lim.IDate)}
         *                        When complementing a forward curve,
         *                        <code>expiredAsOf</code> should be the same date
         *                        as <em>curve date</em> used to create the curve.
         *                        If a string, must be a parsable date representation.
         * @return {morn.TimeSeries}
         */
function value_at_expiration(feed, root, columns, lookBack, expiredAsOf) {}

/**
         * <p>
         *  Returns the most recent curve date as of the given date.
         * </p>
         *
         * <p>
         *  Note: this function returns <em>null</em> if it can't find
         *  any active contracts, or can't find any data for
         *  the front contract within 7 days of <code>asOfDate</code>.
         * </p>
         *
         * <p>
         *  The first three arguments - feed, roots and columns -
         *  can be collapsed into one {@link morn.Product|Product} argument.
         *  When invoked in this manner, this method requires 2 arguments.
         * </p>
         *
         * @param {string} feed.
         * @param {(string|string[])} roots.
         * @param {(string|string[])} columns.
         * @param {(string|lim.IDate)} asOfDate - The date for which to
         *         retrieve the active contracts.
         * @return {?lim.IDate}
         * @method
         */
function get_curve_date(feed, roots, columns, asOfDate){}

/**
 * <p>
 *  Returns a list of ContractValue objects.  That is, a list of
 *  all contracts active as of a given date, along with their value
 *  (a.k.a. price) on that same date.
 * </p>
 *
 * <p>
 *  The first three arguments - feed, roots and columns -
 *  can be collapsed into one {@link morn.Product|Product} argument.
 *  When invoked in this manner, this method requires 2 arguments only.
 * </p>
 *
 * <p>
 *  If multiple columns are provided, the list contains a separate
 *  ContractValue object for each column.
 * </p>
 *
 * <p>
 *  This method returns the most recent prices (or values) for each
 *  contract by default.  This may includes corrections.  Callers
 *  can pull prices/values before corrections by providing <code>asOfDate</code>.
 * </p>
 *
 * @param {string} feed - Feed name.
 * @param {(string|string[])} roots - Root(s).
 * @param {(string|string[])} columns - Column(s).
 * @param {(string|lim.IDate)} settleDate - Date at which the prices/values were settled.
 * @param {?(string|lim.IDate)} [asOfDate] - Prices/values as-of the given date.
 *                              Meaning: any corrections or changes made to contracts
 *                              after \`asOfDate\` are ignored.
 * @return {morn.ContractValue[]}
 * @method
 */
function contract_values_on_date(feed, roots, columns, settleDate, asOfDate) {}

// Forward curve functions - backward compatibility
function create_forward_curve() {}

/**
         * Applies shaping functions (a.k.a. <em>shapes</em>) to
         * a time-series.
         *
         * @deprecated Still here for backward compatibility with old syntax,
         *             but users should use {@link forward_curve},
         *             {@link forward_curve_arbitrage_free} or
         *             {@link forward_curve_arbitrage_free_basic} instead, to
         *             create a base curve, then use one of the many shaping
         *             functions to modify data-points.
         * @param curve {morn.TimeSeries}
         * @param shaping_functions {function[]}
         * @returns {morn.TimeSeries}
         */
function create_shaped_forward_curve(curve, shaping_functions) {}

/**
         * <p>
         *  Retrieves all contracts with meta data for a given root or key combination. If roots is provided then <code>asOfDate</code>
         *  is required, and the returned list only contains contracts that are
         *  not expired as of the given date.
         * </p>
         *
         * @param product {morn.Product} product.
         * @param [asOfDate] {?(string|lim.IDate)}
         * @return {morn.Contract[]}
         */
function meta_data(product, asOfDate) {}

// Forward curve functions
/**
         * <p>
         *  Creates a TimeSeries representing a forward curve, based
         *  on underlying contracts, using arbitrage logic to
         *  calculate the value of missing contracts.
         * </p>
         *
         * <p>
         *  The first three arguments - feed, roots and columns -
         *  can be collapsed into one {@link morn.Product|Product} argument.
         *  When invoked in this manner, this method requires 3 arguments only.
         * </p>
         *
         * @method
         * @param {string} feed.
         * @param {(string|string[])} roots.
         * @param {(string|string[])} columns.
         * @param {?(string|lim.IDate)} curveDate - Pass <em>null</em> for most recent curve.
         * @param {(string|morn.DeliveryType)} deliveryType.
         * @param {?(string|lim.IDate)} [asOfDate] - Prices (or values) as-of the given date.
         *                              Meaning: any corrections or changes made to contracts
         *                              after <code>asOfDate</code> are ignored.
         * @return {morn.TimeSeries}
         */
function forward_curve( feed, roots, columns, curveDate, deliveryType, asOfDate ) {}

/**
         * <p>
         *  Creates a TimeSeries representing a forward curve based
         *  on underlying contracts, using arbitrage-free logic
         *  with precise contract delivery proportions to calculate the
         *  value of missing contracts.
         * </p>
         *
         * <p>
         *  The first three arguments - feed, roots and columns -
         *  can be collapsed into one {@link morn.Product|Product} argument.
         *  When invoked in this manner, this method requires 3 arguments only.
         * </p>
         *
         * @method
         * @param {string} feed.
         * @param {(string|string[])} roots.
         * @param {(string|string[])} columns.
         * @param {?(string|lim.IDate)} curveDate - Pass <em>null</em> for most recent curve.
         * @param {(string|morn.DeliveryType)} deliveryType.
         * @param {?(string|lim.IDate)} [asOfDate] - Prices (or values) as-of the given date,
         *                              Meaning: any corrections or changes made to contracts
         *                              after <code>asOfDate</code> are ignored.
         * @return {morn.TimeSeries}
         *
         * @see forward_curve_arbitrage_free_basic
         */
function forward_curve_arbitrage_free(feed,
  roots,
  columns,
  curveDate,
  deliveryType,
  asOfDate ) {}

/**
         * <p>
         *  Creates a TimeSeries representing a forward curve based
         *  on underlying contracts, using arbitrage-free logic
         *  with rough contract delivery proportions to calculate the
         *  value of missing contracts.
         * </p>
         *
         * <p>
         *  Use this method to treat each month of the curve as
         *  one unit, instead of considering the number of days
         *  for each month.
         * </p>
         *
         * <p>
         *  The first three arguments - feed, roots and columns -
         *  can be collapsed into one {@link morn.Product|Product} argument.
         *  When invoked in this manner, this method requires 3 arguments only.
         * </p>
         *
         * @method
         * @param {string} feed.
         * @param {(string|string[])} roots.
         * @param {(string|string[])} columns.
         * @param {?(string|lim.IDate)} curveDate - Pass <em>null</em> for most recent curve.
         * @param {(string|morn.DeliveryType)} deliveryType.
         * @param {?(string|lim.IDate)} [asOfDate] - Prices (or values) as-of the given date,
         *                              Meaning: any corrections or changes made to contracts
         *                              after <code>asOfDate</code> are ignored.
         * @return {morn.TimeSeries}
         *
         * @see forward_curve_arbitrage_free
         */
function forward_curve_arbitrage_free_basic( feed,
  roots,
  columns,
  curveDate,
  deliveryType,
  asOfDate ) {}

/**
         * <p>
         *  Creates a TimeSeries representing a forward curve based
         *  on underlying contracts, using arbitrage-free logic
         *  where each contract is associated with a number of units
         *  specified by the <code>unitCalculator</code> argument.
         *  That number of units is used to weight each contract's
         *  value when backing out the value of missing contracts.
         * </p>
         *
         * <p>
         *  Use this method to customize the weight of each contract
         *  in an arbitrage-free curve.
         * </p>
         *
         * <p>
         *  The first three arguments - feed, roots and columns -
         *  can be collapsed into one {@link morn.Product|Product} argument.
         *  When invoked in this manner, this method requires 4 arguments only.
         * </p>
         *
         * @param {string} feed.
         * @param {(string|string[])} roots.
         * @param {(string|string[])} columns.
         * @param {?(string|lim.IDate)} curveDate - Pass <em>null</em> for most recent curve.
         * @param {(string|morn.DeliveryType)} deliveryType.
         * @param {morn.UnitCalculator} unitCalculator - A callback function that
         *        translate a period (passed as two arguments, start and end dates)
         *        into a number of units.
         * @param {?(string|lim.IDate)} [asOfDate] - Prices (or values) as-of the given date.
         *                              Meaning: any corrections or changes made to contracts
         *                              after <code>asOfDate</code> are ignored.
         * @return {morn.TimeSeries}
         * @see morn.ForwardCurve.arbitrageFree
         * @method
         */
function forward_curve_arbitrage_free_custom( feed,
  roots,
  columns,
  curveDate,
  deliveryType,
  unitCalculator,
  asOfDate ) {}

// Utilities

/**
         * Returns the date (or date-time) associated with this script
         * execution.  Typically, when running ad-hoc scripts this return
         * today's date (at midnight).  Scripts executed from the workflow
         * manager may run with different <em>run date</em> based on
         * the workflow <em>start time</em> and <em>time zone</em>.
         * @method
         * @return {lim.IDate}
         */
function get_run_date() {}

/**
         * Returns whether <code>obj</code> is <em>undefined</em>
         * or <em>null</em>.
         * @method
         * @param obj {*}
         * @return {boolean}
         */
function isVoid(obj) {}

// Number

/**
         * Returns whether <code>number</code> is a finite number.
         * @method
         * @return {boolean}
         */
function isNumber(number) {}

// Date

/**
         * Returns the number of milliseconds equivalent
         * to <code>numDays</code>.
         * @method
         * @param numDays {number}
         * @return {number}
         */
function days(numDays) {}

/**
         * Returns the number of milliseconds equivalent
         * to the given argument.
         * @method
         * @param hours {number}.
         * @param minutes {number}.
         * @param [seconds=0] {number}.
         * @param [millis=0] {number}.
         * @return {integer}
         */
function time(hours, minutes, seconds, millis) {}

/**
         * <p>
         *  Returns an IDate instance that corresponds to midnight
         *  today.
         * </p>
         *
         * <p>
         *  Specify a time zone to retrieve the midnight instance
         *  in that time zone.
         * </p>
         *
         * @method
         * @param [tz=UTC] {(string|lim.TimeZone)}.
         * @return {lim.IDate} Midnight of today
         */
function today(tz) {}

/**
         * <p>
         *  Returns an IDate instance that corresponds to now.
         *  This function is equivalent to
         *  <code>new IDate(IDate.now())</code>.
         * </p>
         *
         * <p>
         *  Specify a time zone to retrieve the same instance
         *  in that time zone.
         * </p>
         *
         * @method
         * @param [tz=UTC] {(string|lim.TimeZone)}
         * @return {lim.IDate}
         */
function now(tz) {}


/**
         * Returns how many times certain days of the week will
         * occur between <code>start</code> and <code>end</code>.
         *
         * @method
         * @param daysToCount {(Integer|Integer[])} The days of the week to be counted.
         * @param start {lim.IDate} The first day to be counted.
         * @param end {lim.IDate} The last day to be counted.
         * @return {Integer}
         */
function count_days(daysToCount, start, end) {}

/**
         * Sets whether instances of IDates acknowledge their offset to UTC.
         * @method
         * @param [useTimezone=true] {boolean}
         * @return {undefined}
         */
function use_tz(isAware) {}

/**
         * <p>
         *  Moves dates to the given time zone,
         *  preserving their millisecond instant.
         * </p>
         *
         * <p>
         *  This method changes the time zone but does not change the
         *  millisecond instant, with the effect that the values -
         *  day-of-month, hour, etc. - usually change.
         * </p>
         *
         * @method
         * @param {(morn.TimeSeries|lim.IDate|lim.IDate[])} obj
         * @param {(lim.TimeZone|string)} tz
         * @return {(morn.TimeSeries|lim.IDate|lim.IDate[])}
         *
         * @see lim.IDate.withZone
         * @see morn.TimeSeries.withZone
         */
function in_tz(obj, tz) {}


/**
         * <p>
         *  Moves dates to the given time zone,
         *  preserving the values (day-of-month, hour, etc.)
         * </p>
         *
         * <p>
         *  This method changes the time zone and the millisecond instant to
         *  preserve the values.
         * </p>
         *
         * @method
         * @param {(morn.TimeSeries|lim.IDate|lim.IDate[])} obj
         * @param {(lim.TimeZone|string)} tz
         * @param {boolean} [fixDst=false] - When giving a time zone to a list of dates, gaps
         *                                   and overlaps can appear due to DST changes.
         *                                   Set <code>fixDst</code> to true to auto-fill the gaps and
         *                                   remove the overlaps.
         *
         *                                   When <code>obj</code> is a list of dates, this option requires
         *                                   the list of dates to be sorted chronologically.
         *
         *                                   When <code>obj</code> is a time-series, gaps are filled
         *                                   using values from the repeating hour(s) on the wall clock.
         * @return {(morn.TimeSeries|lim.IDate|lim.IDate[])}
         *
         * @see lim.IDate.withZoneRetainFields
         * @see morn.TimeSeries.withZoneRetainFields
         */
function give_tz(obj, tz, fixDst) {}

/**
         * <p>
         *  Returns a copy of the given IDate without a time zone (aka "UTC").
         *  This method is equivalent to <code>withZone("UTC")</code>
         *  and <code>withZoneRetainFields("UTC")</code>, depending on the
         *  value of the <code>retainFields</code> argument.
         * </p>
         *
         * @method
         * @param idate {lim.IDate}
         * @param [retainFields=true] {boolean} Whether to retain fields or not.
         * @return {lim.IDate}
         *
         * @see lim.IDate.withZone
         * @see lim.IDate.withZoneRetainFields
         *
         * @example
         *    var idate = IDate.create("2014-01-13T14:07:54.123-0600";
         *    IDate.noZone(idate)        // "2014-01-13T14:07:54.123-0000"
         *    IDate.noZone(idate, true)  // "2014-01-13T14:07:54.123-0000"
         *    IDate.noZone(idate, false) // "2014-01-13T20:07:54.123-0000"
         */
function no_tz(idate, retainFields) {}

// TimeSeries - creating

/**
         * <p>
         *  Creates a new, custom TimeSeries based on provided data.
         *  The <code>rows</code> argument must be a 2D array
         *  that represent rows of the time-series, where
         *  each row starts with a date - String or IDate -
         *  followed by numeric values (can be their string
         *  representation.)  If some rows are smaller than
         *  others, <em>NaN</em> values are added so that all
         *  rows have the same size.
         * </p>
         *
         * <p>
         *  Values <code>"NaN"</code>,
         *  <code>"null"</code>, <code>null</code> and
         *  <em>undefined</em> are treated as <em>NaN</em>.
         * </p>
         *
         * @method
         * @param rows {Array[]} A 2D array containing one date and
         *                       (at least) one value per row.
         * @param [tag=hard-coded] {string}
         * @return {morn.TimeSeries}
         *
         * @see custom_series
         *
         * @example new_series([["10/20/2014", 50]])
         *      => A TimeSeries of 1 row, 1 column.
         * @example new_series([["10/20/2014", "45.12"], ["10/26/2014", "51.97", null]])
         *      => A TimeSeries of 2 rows, 2 columns.
         */
function new_series(rows, tag) {}

/**
         * <p>
         *  Creates a new, custom TimeSeries based on provided data.
         *  The <code>rows</code> argument must be a 2D array
         *  that represent rows of the time-series, where
         *  each row starts with a date - String or IDate -
         *  followed by numeric values (can be their string
         *  representation.)  If some rows are smaller than
         *  others, <em>NaN</em> values are added so that all
         *  rows have the same size.
         * </p>
         *
         * <p>
         *  Values <code>"NaN"</code>,
         *  <code>"null"</code>, <code>null</code> and
         *  <em>undefined</em> are treated as <em>NaN</em>.
         * </p>
         *
         * @method
         * @param rows {Array[]} A 2D array containing one date and
         *                       (at least) one value per row.
         * @param [tag=hard-coded] {string}.
         * @return {morn.TimeSeries}
         *
         * @see new_series
         *
         * @example custom_series([["10/20/2014", 50]])
         *      => A TimeSeries of 1 row, 1 column.
         * @example custom_series([["10/20/2014", "45.12"], ["10/26/2014", "51.97", null]])
         *      => A TimeSeries of 2 rows, 2 columns.
         */
function custom_series(rows, tag) {}

/**
         * <p>
         *  Retrieves a TimeSeries in the server's local time zone.
         * </p>
         *
         * <p>
         *  The first three arguments - feed, keyValues and columns -
         *  can be collapsed into one {@link morn.Product|Product} argument.
         *  When invoked in this manner, this method requires 1 argument
         *  only (4 at most).
         * </p>
         *
         * @method
         * @param {string} feed.
         * @param {Object} keyValues.
         * @param {(string|string[])} columns - A name for each of the column.
         *                  Multiple column names can still be specified
         *                  using a single string value, using commas to separate
         *                  the columns.
         * @param {?(lim.IDate|string)} [startDate]
         * @param {?(lim.IDate|string)} [endDate]
         * @param {Integer} [maxResults=-1]
         * @return {morn.TimeSeries}
         */
function time_series( feed,
  keyValues,
  columns,
  startDate,
  endDate,
  maxResults ) {}

/**
         * <p>
         *  Retrieves a TimeSeries for corrections in the server's local time zone.
         * </p>
         *
         * <p>
         *  The first three arguments - feed, keyValues and columns -
         *  can be collapsed into one {@link morn.Product|Product} argument.
         *  When invoked in this manner, this method requires 1 argument
         *  only (4 at most).
         * </p>
         *
         * @method
         * @param {string} feed.
         * @param {Object} keyValues.
         * @param {(string|string[])} columns - A name for each of the column.
         *                  Multiple column names can still be specified
         *                  using a single string value, using commas to separate
         *                  the columns.
         * @param {?(lim.TimeZone|string)} timeZone
         * @param {?(lim.IDate|string)} [startDate]
         * @param {?(lim.IDate|string)} [endDate]
         * @param {?(lim.IDate|string)} [fromInsertionDate]
         * @param {Integer} [maxResults=-1]
         * @return {morn.TimeSeries}
         */
function time_series_corr( feed,
  keyValues,
  columns,
  timeZone,
  startDate,
  endDate,
  fromInsertionDate,
  maxResults ) {}

/**
         * <p>
         *  Retrieves a TimeSeries in the requested time zone.
         * </p>
         *
         * <p>
         *  The first three arguments - feed, keyValues and columns -
         *  can be collapsed into one {@link morn.Product|Product} argument.
         *  When invoked in this manner, this method requires 2 arguments
         *  only (5 at most).
         * </p>
         *
         * @method
         * @param {string} feed
         * @param {Object} keyValues
         * @param {(string|string[])} columns - A name for each of the column.
         *                  Multiple column names can still be specified
         *                  using a single string value, using commas to separate
         *                  the columns.
         * @param {?(lim.TimeZone|string)} timeZone
         * @param {?(lim.IDate|string)} [startDate]
         * @param {?(lim.IDate|string)} [endDate]
         * @param {Integer} [maxResults=-1]
         * @return {morn.TimeSeries}
         */
function time_series_tz( feed,
  keyValues,
  columns,
  timeZone,
  startDate,
  endDate,
  maxResults ) {}

/**
         * <p>
         * Retrieves multiple TimeSeries.
         * </p>
         * @param {string} feed Name of feed from which to pull data.
         * @param {Array.<Object.<string, string>>} keys List of key-values.  All objects in
         *        the list must contain all key-names defined in the given feed.
         * @param {string[]} columns - A name for each column to retrieve.
         *                  Multiple column names can still be specified
         *                  using a single string value, using commas to separate
         *                  the columns.
         * @param {?(lim.IDate|string)} [startDate]
         * @param {?(lim.IDate|string)} [endDate]
         * @param {Integer} [maxResults=-1]
         * @returns {morn.TimeSeries[]}
         * @method
         */
function time_series_multi(
  feed,
  keys,
  columns,
  startDate,
  endDate,
  maxResults ) {}

/**
         * <p>
         * Retrieves multiple TimeSeries, with all dates being in the given time-zone.
         * </p>
         * @param {string} feed Name of feed from which to pull data.
         * @param {Array.<Object.<string, string>>} keys List of key-values.  All objects in
         *        the list must contain all key-names defined in the given feed.
         * @param {string[]} columns - A name for each column to retrieve.
         *                  Multiple column names can still be specified
         *                  using a single string value, using commas to separate
         *                  the columns.
         * @param {?(lim.TimeZone|string)} timeZone
         * @param {?(lim.IDate|string)} [startDate]
         * @param {?(lim.IDate|string)} [endDate]
         * @param {Integer} [maxResults=-1]
         * @returns {morn.TimeSeries[]}
         * @method
         */
function time_series_multi_tz(
  feed,
  keys,
  columns,
  startDate,
  endDate,
  maxResults ) {}

/**
         * <p>
         * Returns the union of all TimeSeries.  That is,
         * a new TimeSeries that contains all of the dates
         * and columns of other TimeSeries.
         * </p>
         *
         * @method
         * @param timeSeries {...morn.TimeSeries}
         * @return {morn.TimeSeries}
         */
function union(timeSeries) {}

/**
         * <p>
         * Returns the intersection of all TimeSeries.  That is,
         * a new TimeSeries that contains only the dates that were
         * found within all instance of TimeSeries provided as arguments.
         * </p>
         *
         * @method
         * @param timeSeries {...morn.TimeSeries}
         * @return {morn.TimeSeries}
         */
function intersection(timeSeries) {}

/**
         * <p>
         * Returns a TimeSeries where all rows from all
         * provided <code>timeSeries</code> have been merged
         * into one TimeSeries.  This method does not create
         * new columns; only rows.
         *
         * The first TimeSeries is used at the base.
         * Any other TimeSeries provided will have their
         * rows added to the <em>base</em>, only if they
         * don't already exist.
         *
         * To overwrite existing rows, use <code>overwrite()</code>.
         * </p>
         *
         * @method
         * @param timeSeries {...morn.TimeSeries}
         * @return {morn.TimeSeries}
         * @throws IllegalArgumentException if the number of columns
         *                                  does not match between all
         *                                  TimeSeries.
         */
function complement(timeSeries) {}

/**
         * <p>
         * Returns a TimeSeries where all rows from all
         * provided <code>timeSeries</code> have been merged
         * into one TimeSeries.  This method does not create
         * new columns; only rows.
         *
         * The first TimeSeries is used at the base.
         * The rows of other TimeSeries overwrite
         * the <em>base</em>.
         *
         * Use <code>complement()</code> if you do not wish to
         * overwrite rows.
         * </p>
         *
         * @method
         * @param timeSeries {...morn.TimeSeries}
         * @return {morn.TimeSeries}
         * @throws IllegalArgumentException if the number of columns
         *                                  does not match between all
         *                                  TimeSeries.
         */
function overwrite(timeSeries) {}


/**
         * <p>
         * Returns a TimeSeries where headers are added
         * into given TimeSeries.  This method does not create
         * new columns; only rows.
         *
         *
         * @method
         * @param timeSeries {...morn.TimeSeries}
         * @param headerArray { String []}
         * @return {morn.TimeSeries}
         * @throws IllegalArgumentException if the number of columns
         *                                  does not match between header array and
         *                                  TimeSeries.
         */
function header(timeseries, cols) {}
/**
         * <p>
         * Returns a new TimeSeries with only the selected
         * column(s).
         * </p>
         *
         * @method
         * @param timeSeries {...morn.TimeSeries}
         * @param colIndices {...integer} 0-base index of
         *                   column(s) within the TimeSeries.
         * @return {morn.TimeSeries}
         */
function extract(timeSeries, colIndices) {}

// TimeSeries - save

/**
         * <p>
         * Pushes all save-data operations batched during the execution of the formula so far,
         * optionally targetting specific feeds.  This method gives users the ability to
         * break large batches of data into smaller batches.
         * </p>
         *
         * @param {...string} feedNames - List of feed names to flush.  If none provided,
         *                    all pending data is flushed.
         * @returns {Integer} Number of ZIP files that were submitted.
         * @method
         */
function submit_pending_data(feedNames) {}

/**
         * <p>
         * Sets whether save-data operations are delayed until
         * the formula completes execution or until an explicit call to
         * <code>{@link submit_pending_data}</code>.
         * </p>
         *
         * @param {boolean} isBatchEnabled - Whether save-data operations are batched (default false).
         * @method
         */
function set_data_batch_mode(isBatchEnabled) {}

/**
         * <p>
         *  Saves this time-series into the given feed.
         *  Once saved, this time-series can be re-created using
         *  {@link time_series}.
         * </p>
         *
         * <p>
         *  Arguments feed, keyValues and columns
         *  can be collapsed into one {@link morn.Product|Product} argument.
         *  When invoked in this manner, this method requires 2 arguments
         *  only (3 at most).
         * </p>
         *
         * @method
         * @param {morn.TimeSeries} timeSeries.
         * @param {string} feed - The feed to save to.
         * @param {Object} keyValues - The keys and values for these numbers.
         * @param {(string|string[])} columns - A name for each of the column.
         *                  Multiple column names can still be specified
         *                  using a single string value, using commas to separate
         *                  the columns.
         * @param {boolean} [partial_updates=true] - Save using partial_updates or not.
         *                          If not set, it will default to the feed's default behavior.
         * @return {boolean} Returns <em>true</em> most of the time.  Returns
         *                   <em>false</em> if optimization determined that no
         *                   new data is being saved.
         */
function save_series(timeSeries, feed, keyValues, columns, partial_updates) {}

/**
         * <p>
         *  Saves each row of the given TimeSeries as a separate futures contract,
         *  into the given feed.  Once saved, the <em>curve</em> can be re-created
         *  using {@link forward_curve}.
         * </p>
         *
         * <p>
         *  This method validates that there are no gaps in <code>timeSeries</code>.
         * </p>
         *
         * <p>
         *  Arguments <code>feed</code>, <code>root</code> and <code>columns</code>
         *  can be collapsed into one {@link morn.Product|Product} argument.
         *  When invoked in this manner, this method requires 4 argument
         *  only (5 at most).
         * </p>
         *
         * @param {morn.TimeSeries} timeSeries.
         * @param {string} feed - The feed to save to.
         * @param {string} root - The root symbol under which to save.
         * @param {(string|string[])} columns - A name for each of the column.
         *          Multiple column names can still be specified
         *          using a single string value, using commas to separate
         *          the columns.
         * @param {(string|lim.IDate)} curveDate - If <code>curveDate</code> is
         *          only a date - without time - make sure to pass
         *          a string value to avoid time zone offsets.  For
         *          strings, formats "yyyy-MM-dd" and "M/d/yyyy" are
         *          supported.
         * @param {(string|morn.DeliveryType)} deliveryType - Used to create contracts
         *          and validate that there are no gaps in the <em>curve</em>.
         * @param {boolean} [partial_updates=true] - Whether to write with partial corrects or not.
         *          If not set, it will default to the feed's default behavior.
         * @return {boolean} Returns <em>true</em> most of the time.  Returns
         *         <em>false</em> if optimization determined that no
         *         new data is being saved.
         * @method
         */
function save_curve(timeSeries, feed, root, columns, curveDate, deliveryType, partial_updates) {}

/**
         * <p>
         *  Saves each row of the given TimeSeries as a separate futures contract,
         *  into the given feed.  Once saved, the <em>curve</em> can be re-created
         *  using {@link forward_curve}.
         * </p>
         *
         * <p>
         *  This method allows for gaps in <code>timeSeries</code>.
         * </p>
         *
         * <p>
         *  Arguments <code>feed</code>, <code>root</code> and <code>columns</code>
         *  can be collapsed into one {@link morn.Product|Product} argument.
         *  When invoked in this manner, this method requires 4 argument
         *  only (5 at most).
         * </p>
         *
         * @param {morn.TimeSeries} timeSeries.
         * @param {string} feed - The feed to save to.
         * @param {string} root - The root symbol under which to save.
         * @param {(string|string[])} columns - A name for each of the column.
         *          Multiple column names can still be specified
         *          using a single string value, using commas to separate
         *          the columns.
         * @param {(string|lim.IDate)} curveDate - If <code>curveDate</code> is
         *          only a date - without time - make sure to pass
         *          a string value to avoid time zone offsets.  For
         *          strings, formats "yyyy-MM-dd" and "M/d/yyyy" are
         *          supported.
         * @param {(string|morn.DeliveryType)} deliveryType - Used to create contracts.
         * @param {boolean} [partial_updates=true] - Whether to write with partial corrects or not.
         *          If not set, it will default to the feed's default behavior.
         * @return {boolean} Returns <em>true</em> most of the time.  Returns
         *         <em>false</em> if optimization determined that no
         *         new data is being saved.
         * @method
         */
function save_curve_with_gaps(timeSeries, feed, root, columns, curveDate, deliveryType, partial_updates) {}


// TimeSeries - filters


/**
         * <p>
         * Returns a subset of rows from the specified TimeSeries.
         * </p>
         *
         * @method
         * @param timeSeries {morn.TimeSeries}
         * @param start {(integer|lim.IDate)}
         *              Optional, defaults to 0.  An integer or date
         *              that specifies where the selection starts.
         *              Use a negative integer to select from the end.
         *
         *              This argument is required in order to provide
         *              <code>end</code>.
         *
         * @param end {(integer|lim.IDate)}
         *            Optional, defaults to length().  An integer or date
         *            that specifies where the selection ends.
         *            If omitted, all elements from the start position
         *            to the end are selected.
         *            Use a negative integer to select from the end.
         *
         * @return {morn.TimeSeries}
         */
function slice(timeSeries, start, end) {}

/**
         * <p>
         * Creates a new TimeSeries from selected rows.
         * The <code>selector</code> function is called on
         * each row; it should return <em>true</em> when the
         * given row should be included in the new TimeSeries
         * (selected).
         * </p>
         *
         * @method
         * @param timeSeries {morn.TimeSeries}
         * @param selector {function} A function that accepts one Row
         *                 argument, returns a Boolean value.
         * @return {morn.TimeSeries}
         * @see filter
         */
function select(timeSeries, selector) {}

/**
         * <p>
         * Creates a new TimeSeries from selected rows.
         * The <code>selector</code> function is called on
         * each row; it should return <em>true</em> when the
         * given row should be included in the new TimeSeries
         * (selected).
         * </p>
         *
         * @method
         * @param timeSeries {morn.TimeSeries}
         * @param selector {function} A function that accepts one Row
         *                 argument, returns a Boolean value.
         * @return {morn.TimeSeries}
         * @see select
         */
function filter(timeSeries, selector) {}

/**
         * <p>
         * Creates a new TimeSeries without the <em>dropped</em> rows.
         * The <code>dropper</code> function is called on each row;
         * it should return <em>true</em> when the given row should
         * be excluded from the new TimeSeries (dropped).
         * </p>
         *
         * @method
         * @param timeSeries {morn.TimeSeries}
         * @param dropper {function} A function that accepts a Row
         *                 object and returns a Boolean value.
         * @return {morn.TimeSeries}
         */
function drop(timeSeries, dropper) {}

/**
         * <p>
         * Returns a list of dates common to all
         * TimeSeries provided.
         * </p>
         * @method
         * @param timeSeries {...morn.TimeSeries}
         * @return {lim.IDate[]}
         */
function common_dates(timeSeries) {}

/**
         * <p>
         * Returns a list of all dates found within all
         * TimeSeries provided.
         * </p>
         * @method
         * @param timeSeries {...morn.TimeSeries}
         * @return {lim.IDate[]}
         */
function all_dates(timeSeries) {}

/**
         * <p>
         * Creates a new TimeSeries based on the given TimeSeries with the
         * specified rows excluded from it.  The rows to exclude are
         * specified using <code>datesToDrop</code> which can be a TimeSeries,
         * an IDate[], an IDate or a String.
         * </p>
         *
         * @method
         * @param timeSeries {morn.TimeSeries}
         * @param datesToDrop {(morn.TimeSeries|lim.IDate[]|lim.IDate|string)}
         * @return {morn.TimeSeries}
         */
function drop_dates(timeSeries, datesToDrop) {}

/**
         * <p>
         * Creates a new TimeSeries based on the given TimeSeries with
         * only the specified rows included.  The rows are included
         * using dates specified by <code>datesToKeep</code> which can be
         * a TimeSeries, an IDate[], an IDate or a String.
         *
         * Optionally, the range around each date can be expanded using
         * </p>
         * <code>numBefore</code> and <code>numAfter</code>.
         *
         * @method
         * @param timeSeries {morn.TimeSeries}
         * @param datesToKeep {(morn.TimeSeries|lim.IDate[]|lim.IDate|string)}
         * @param {Integer} [numBefore=0] - How many rows from \`timeSeries\` should
         *                                  be included before each matched date.
         * @param {Integer} [numAfter=0] - How many rows from \`timeSeries\` should
         *                                  be included after each matched date.
         * @return {morn.TimeSeries}
         */
function keep_dates(timeSeries, datesToKeep, numBefore, numAfter) {}

/**
         * <p>
         * Creates a new TimeSeries based on the given TimeSeries with
         * only the specified rows included.  The rows are included
         * using dates specified by <code>datesToKeep</code> which can be
         * a TimeSeries, an IDate[], an IDate or a String.
         * </p>
         *
         * Optionally, the range around each date can be expanded using
         * <code>numBefore</code> and <code>numAfter</code>.
         *
         * @method
         * @param timeSeries {morn.TimeSeries}
         * @param datesToKeep {(morn.TimeSeries|lim.IDate[]|lim.IDate|string)}
         * @param {Integer} [numBefore=0] - How many rows from \`timeSeries\` should
         *                                  be included before each matched date.
         * @param {Integer} [numAfter=0] - How many rows from \`timeSeries\` should
         *                                  be included after each matched date.
         * @return {morn.TimeSeries}
         */
function when_date_in(timeSeries, datesToKeep, numBefore, numAfter) {}

/**
         * <p>
         * Keeps rows from the beginning of the given TimeSeries,
         * drops all other rows.
         * </p>
         *
         * If <code>numRows</code> is 0 or negative, this method
         * returns an empty TimeSeries.
         *
         * @method
         * @param timeSeries {morn.TimeSeries}
         * @param [numRows=1] {integer}
         * @return {morn.TimeSeries}
         */
function get_first(timeSeries, numRows) {}

/**
         * <p>
         * Keeps rows from the end of the given TimeSeries,
         * drops all other rows.
         *
         *
         * If <code>numRows</code> is 0 or negative, this method
         * returns an empty TimeSeries.
         * </p>
         *
         * @method
         * @param timeSeries {morn.TimeSeries}
         * @param [numRows=1] {integer}
         * @return {morn.TimeSeries}
         */
function get_last(timeSeries, numRows) {}

/**
         * <p>
         * Drops rows from the beginning of the given TimeSeries.
         *
         * If <code>numRows</code> is 0 or negative, this method
         * returns the same TimeSeries, unmodified.
         * </p>
         *
         * @method
         * @param timeSeries {morn.TimeSeries}
         * @param [numRows=1] {integer}
         * @return {morn.TimeSeries}
         */
function drop_first(timeSeries, numRows) {}

/**
         * <p>
         * Drops rows from the end of the given TimeSeries.
         *
         * If <code>numRows</code> is 0 or negative, this method
         * returns the same TimeSeries, unmodified.
         * </p>
         *
         * @method
         * @param timeSeries {morn.TimeSeries}
         * @param [numRows=1] {integer}
         * @return {morn.TimeSeries}
         */
function drop_last(timeSeries, numRows) {}

/**
         * <p>
         * Returns a new TimeSeries created off the specified TimeSeries
         * without <em>NaN</em> rows.  NaN rows contain not one
         * single finite value (only NaN, +Infinity, -Infinity)
         * The returned TimeSeries only contains non-NaN rows.
         * </p>
         *
         * @method
         * @param timeSeries {morn.TimeSeries}
         * @return {morn.TimeSeries}
         * @see morn.TimeSeries.dropNaNs
         */
function drop_nans(timeSeries) {}
function drop_NaNs(timeSeries) {}
function drop_Nans(timeSeries) {}

/**
         * @method
         * @see drop_nans
         * @see morn.TimeSeries.dropNaNs
         */
function when_not_nan(timeSeries) {}
/**
         * @method
         * @see drop_nans
         * @see morn.TimeSeries.dropNaNs
         */
function not_nan(timeSeries) {}


/**
         * <p>
         * Creates a TimeSeries from rows of the given TimeSeries
         * when dates fall on <code>holidays</code>.
         * </p>
         * @method
         * @param timeSeries {morn.TimeSeries}
         * @param holidays {morn.Holidays} A holiday calendar.
         * @param [isPeriodEnding=false] {boolean} Whether to use
         *        period-beginning (false) or period-ending (true).
         *        Only use <em>true</em> with intraday data;
         *        behavior is otherwise undefined.
         * @param [dayCutoff="00:00"] {(integer|string)} Time of day at which the
         *              day begins. If <em>integer</em>, argument
         *              represents milliseconds since midnight.
         *              If <em>string</em>, argument is expected to be
         *              in "H:mm" format.
         *              Use this argument to includes portions of the previous or
         *              next day as part of the current day.  For example, specifying
         *              "22:00" means that data on or after 10 PM will be considered
         *              part of the next day.  Similarily, "2:00" means that data up to
         *              2 AM (exclusive) will be considered part of the previous day.
         * @return {morn.TimeSeries}
         */
function when_holiday(timeSeries, holidays, isPeriodEnding, dayCutoff) {}

/**
         * <p>
         * Creates a TimeSeries from rows of the given TimeSeries
         * when dates do not fall on <code>holidays</code>.
         * </p>
         *
         * @method
         * @param timeSeries {morn.TimeSeries}
         * @param holidays {morn.Holidays} A holiday calendar.
         * @param [isPeriodEnding=false] {boolean} Whether to use
         *        period-beginning (false) or period-ending (true).
         *        Only use <em>true</em> with intraday data;
         *        behavior is otherwise undefined.
         * @param [dayCutoff="00:00"] {(integer|string)} Time of day at which the
         *              day begins. If <em>integer</em>, argument
         *              represents milliseconds since midnight.
         *              If <em>string</em>, argument is expected to be
         *              in "H:mm" format.
         *              Use this argument to includes portions of the previous or
         *              next day as part of the current day.  For example, specifying
         *              "22:00" means that data on or after 10 PM will be considered
         *              part of the next day.  Similarily, "2:00" means that data up to
         *              2 AM (exclusive) will be considered part of the previous day.
         * @return {morn.TimeSeries}
         */
function when_not_holiday(timeSeries, holidays, isPeriodPending, dayCutoff) {}

/**
         * <p>
         * Creates a new TimeSeries from rows within the given
         * time of day.
         * </p>
         *
         * @method
         * @param timeSeries {morn.TimeSeries}
         * @param start {(integer|string)} Time of day at which the
         *              period begins. If <em>integer</em>, argument
         *              represents milliseconds since midnight.
         *              If <em>string</em>, argument is expected to be
         *              in "H:mm" format.
         * @param end {(integer|string)} Time of day at which the
         *              period ends. If <em>integer</em>, argument
         *              represents milliseconds since midnight.
         *              If <em>string</em>, argument is expected to be
         *              in "H:mm" format.
         * @param [isPeriodEnding=false] {boolean} Whether to use
         *        period-beginning logic (false) or period-ending logic (true).
         *        Period-beginning treats <code>start</code> as inclusive and
         *        <code>end</code> as exclusive.  On the opposite, period-ending
         *        treats <code>start</code> as exclusive and <code>end</code>
         *        as inclusive.
         * @return {morn.TimeSeries}
         */
function when_time_within(timeSeries, start, end, isPeriodEnding) {}

/**
         * <p>
         * Creates a new TimeSeries from rows outside of the given
         * time of day.
         * </p>
         *
         * @method
         * @param timeSeries {morn.TimeSeries}
         * @param start {(integer|string)} Time of day at which the
         *              period begins. If <em>integer</em>, argument
         *              represents milliseconds since midnight.
         *              If <em>string</em>, argument is expected to be
         *              in "H:mm" format.
         * @param end {(integer|string)} Time of day at which the
         *              period ends. If <em>integer</em>, argument
         *              represents milliseconds since midnight.
         *              If <em>string</em>, argument is expected to be
         *              in "H:mm" format.
         * @param [isPeriodEnding=false] {boolean} Whether to use
         *        period-beginning logic (false) or period-ending logic (true).
         *        Period-beginning treats <code>start</code> as inclusive and
         *        <code>end</code> as exclusive.  On the opposite, period-ending
         *        treats <code>start</code> as exclusive and <code>end</code>
         *        as inclusive.
         * @return {morn.TimeSeries}
         */
function when_time_outside(timeSeries, start, end, isPeriodEnding) {}


/**
         * <p>
         * Creates a new TimeSeries from rows corresponding to
         * <code>daysOfWeek</code>.
         * </p>
         *
         * @method
         * @param timeSeries {morn.TimeSeries}
         * @param daysOfWeek {integer[]} A list of day-of-week to be selected.
         * @param [isPeriodEnding=false] {boolean} Whether to use
         *        period-beginning logic (false) or period-ending logic (true).
         *        Period-beginning treats the midnight mark as part of the
         *        same date.  On the opposite, period-ending treats that
         *        same midnight mark as part of the previous day.
         * @param [dayCutoff="00:00"] {(integer|string)} Time of day at which the
         *              day begins. If <em>integer</em>, argument
         *              represents milliseconds since midnight.
         *              If <em>string</em>, argument is expected to be
         *              in "H:mm" format.
         *              Use this argument to includes portions of the previous or
         *              next day as part of the current day.  For example, specifying
         *              "22:00" means that data on or after 10 PM will be considered
         *              part of the next day.  Similarily, "2:00" means that data up to
         *              2 AM (exclusive) will be considered part of the previous day.
         * @return {morn.TimeSeries}
         */
function when_day_of_week(timeSeries, daysOfWeek, isPeriodEnding, dayCutoff) {}

/**
         * <p>
         * Creates a new TimeSeries from rows corresponding to
         * week days only (Monday through Friday).
         * </p>
         *
         * @method
         * @param timeSeries {morn.TimeSeries}
         * @param [isPeriodEnding=false] {boolean} Whether to use
         *        period-beginning logic (false) or period-ending logic (true).
         *        Period-beginning treats the midnight mark as part of the
         *        same date.  On the opposite, period-ending treats that
         *        same midnight mark as part of the previous day.
         * @param [dayCutoff="00:00"] {(integer|string)} Time of day at which the
         *              day begins. If <em>integer</em>, argument
         *              represents milliseconds since midnight.
         *              If <em>string</em>, argument is expected to be
         *              in "H:mm" format.
         *              Use this argument to includes portions of the previous or
         *              next day as part of the current day.  For example, specifying
         *              "22:00" means that data on or after 10 PM will be considered
         *              part of the next day.  Similarily, "2:00" means that data up to
         *              2 AM (exclusive) will be considered part of the previous day.
         * @return {morn.TimeSeries}
         */
function when_weekday(timeSeries, isPeriodPending, dayCutoff) {}


/**
         * <p>
         * Creates a new TimeSeries from rows corresponding to
         * weekend days only (Saturday, Sunday).
         * </p>
         *
         * @method
         * @param timeSeries {morn.TimeSeries}
         * @param [isPeriodEnding=false] {boolean} Whether to use
         *        period-beginning logic (false) or period-ending logic (true).
         *        Period-beginning treats the midnight mark as part of the
         *        same date.  On the opposite, period-ending treats that
         *        same midnight mark as part of the previous day.
         * @param [dayCutoff="00:00"] {(integer|string)} Time of day at which the
         *              day begins. If <em>integer</em>, argument
         *              represents milliseconds since midnight.
         *              If <em>string</em>, argument is expected to be
         *              in "H:mm" format.
         *              Use this argument to includes portions of the previous or
         *              next day as part of the current day.  For example, specifying
         *              "22:00" means that data on or after 10 PM will be considered
         *              part of the next day.  Similarily, "2:00" means that data up to
         *              2 AM (exclusive) will be considered part of the previous day.
         * @return {morn.TimeSeries}
         */
function when_weekend(timeSeries, isPeriodPending, dayCutoff) {}

/**
         * <p>
         *  Creates a new TimeSeries from rows corresponding to peak periods.
         *  By default, peak periods are defined as week days (Mon-Fri) that are not
         *  holidays. Peak periods can also be defined as <code>daysOfWeek</code>.
         *  Within those days, only certain hours of the day are considered peak periods.
         * </p>
         *
         * <p>
         *  This function is provided for convenience.
         *  There are other ways to filter <em>on-peak</em> data.
         *  See {@link when_weekday}, {@link when_not_holiday} and
         *  {@link when_time_within}.
         * </p>
         *
         * @method
         * @param timeSeries {morn.TimeSeries}.
         * @param [holidays=null] {?(morn.Holidays|string)} A holiday calendar, name or object.
         * @param [start="7:00"] {?(integer|string)} Time of day at which the peak
         *                       period begins. If <em>integer</em>, argument
         *                       represents milliseconds since midnight.
         *                       If <em>string</em>, argument is expected to be
         *                       in "H:mm" format.
         * @param [end="22:00"] {?(integer|string)} Time of day at which the peak
         *                      period ends. If <em>integer</em>, argument
         *                      represents milliseconds since midnight.
         *                      If <em>string</em>, argument is expected to be
         *                      in "H:mm" format.
         * @param [isPeriodEnding=false] {?boolean} Whether to use
         *        period-beginning logic (false) or period-ending logic (true).
         *        Period-beginning treats <code>start</code> as inclusive and
         *        <code>end</code> as exclusive.  On the opposite, period-ending
         *        treats <code>start</code> as exclusive and <code>end</code>
         *        as inclusive.
         * @param [daysOfWeek=IDate.WEEK_DAYS] {integer[]} A list of day-of-week that represents
         *        peak periods.
         * @return {morn.TimeSeries}
         *
         * @see when_weekday
         * @see when_not_holiday
         * @see when_time_within
         */
function when_peak(timeSeries, holidays, start, end, isPeriodEnding, daysOfWeek) {}

/**
         * <p>
         *  Creates a new TimeSeries from rows corresponding to off-peak periods.
         *  By default, off-peak periods are defined as weekends (Sat, Sun) and holidays.
         *  Additionally, off-hour periods during week days (Mon-Fri) are also
         *  off-peak periods. Off-peak periods will change if <code>daysOfWeek</code>
         *  is not <code>null</code>.
         * </p>
         *
         * <p>
         *  This function is provided for convenience.
         *  There are other ways to filter <em>off-peak</em> data.
         *  See {@link when_weekend}, {@link when_holiday} and
         *  {@link when_time_outside}.  Alternatively, one can create
         *  an <em>on-peak</em> data-set and consider <em>everything
         *  else</em> as off-peak, using {@link when_date_in_none_other}.
         * </p>
         *
         * @method
         * @param timeSeries {morn.TimeSeries}
         * @param [holidays=null] {?(morn.Holidays|string)} A holiday calendar, name or object.
         * @param [start="7:00"] {?(integer|string)} Time of day at which the peak
         *                       period begins. If <em>integer</em>, argument
         *                       represents milliseconds since midnight.
         *                       If <em>string</em>, argument is expected to be
         *                       in "H:mm" format.
         * @param [end="22:00"] {?(integer|string)} Time of day at which the peak
         *                      period ends. If <em>integer</em>, argument
         *                      represents milliseconds since midnight.
         *                      If <em>string</em>, argument is expected to be
         *                      in "H:mm" format.
         * @param [isPeriodEnding=false] {?boolean} Whether to use
         *        period-beginning logic (false) or period-ending logic (true).
         *        Period-beginning treats <code>start</code> as inclusive and
         *        <code>end</code> as exclusive.  On the opposite, period-ending
         *        treats <code>start</code> as exclusive and <code>end</code>
         *        as inclusive.
         * @param [daysOfWeek=IDate.WEEK_DAYS] {integer[]} A list of day-of-week that represents
         *        peak periods.
         * @return {morn.TimeSeries}
         *
         * @see when_weekend
         * @see when_holiday
         * @see when_time_outside
         * @see when_date_in_none_other
         */
function when_off_peak(timeSeries, holidays, start, end, isPeriodEnding, daysOfWeek) {}

/**
         * <p>
         *  Creates a new TimeSeries by computing daily averages of the peak periods.
         * </p>
         *
         * <p>
         *  This function is provided for convenience.
         *  There are other ways to calculate peak daily averages.
         *  See {@link daily_average} and {@link when_peak}.
         * </p>
         *
         * @method
         * @param timeSeries {morn.TimeSeries}
         * @param [holidays=null] {?(morn.Holidays|string)} A holiday calendar, name or object.
         * @param [start="7:00"] {?(integer|string)} Time of day at which the peak
         *                       period begins. If <em>integer</em>, argument
         *                       represents milliseconds since midnight.
         *                       If <em>string</em>, argument is expected to be
         *                       in "H:mm" format.
         * @param [end="22:00"] {?(integer|string)} Time of day at which the peak
         *                      period ends. If <em>integer</em>, argument
         *                      represents milliseconds since midnight.
         *                      If <em>string</em>, argument is expected to be
         *                      in "H:mm" format.
         * @param [isPeriodEnding=false] {?boolean} Whether to use
         *        period-beginning logic (false) or period-ending logic (true).
         *        Period-beginning treats <code>start</code> as inclusive and
         *        <code>end</code> as exclusive.  On the opposite, period-ending
         *        treats <code>start</code> as exclusive and <code>end</code>
         *        as inclusive.
         * @param [daysOfWeek=IDate.WEEK_DAYS] {integer[]} A list of day-of-week that represents
         *        peak periods.
         * @param [isDayEnding=false] {boolean} Whether to use day-ending logic or not.
         *                            If <em>true</em>,
         *                            {@link morn.TimeSeries.DateGroupingMethods.DAY_ENDING_ON_PREVIOUS|DAY_ENDING_ON_PREVIOUS}
         *                            is used.
         * @return {morn.TimeSeries}
         *
         * @see daily_average
         * @see when_peak
         *
         * @example
         * // Do not use day-ending logic in average calculation and set Monday through Saturday as peak periods.
         * daily_average_peak(ts, null, "6:00", "22:00", null, [MONDAY, TUESDAY, WEDNESDAY, THURSDAY, FRIDAY, SATURDAY])
         * @example
         * // Use day-ending logic in average calculation and set week days as peak periods.
         * daily_average_peak(ts, null, "6:00", "22:00", null, IDate.WEEK_DAYS, true)
         */
function daily_average_peak(timeSeries, holidays, start, end, isPeriodEnding, daysOfWeek, isDayEnding,iso) {}

/**
         * <p>
         *  Creates a new TimeSeries by computing daily averages of the off-peak periods.
         * </p>
         *
         * <p>
         *  This function is provided for convenience.
         *  There are other ways to calculate off-peak daily averages.
         *  See {@link daily_average} and {@link when_off_peak}.
         * </p>
         *
         * @method
         * @param timeSeries {morn.TimeSeries}
         * @param [holidays=null] {?(morn.Holidays|string)} A holiday calendar, name or object.
         * @param [start="7:00"] {?(integer|string)} Time of day at which the peak
         *                       period begins. If <em>integer</em>, argument
         *                       represents milliseconds since midnight.
         *                       If <em>string</em>, argument is expected to be
         *                       in "H:mm" format.
         * @param [end="22:00"] {?(integer|string)} Time of day at which the peak
         *                      period ends. If <em>integer</em>, argument
         *                      represents milliseconds since midnight.
         *                      If <em>string</em>, argument is expected to be
         *                      in "H:mm" format.
         * @param [isPeriodEnding=false] {?boolean} Whether to use
         *        period-beginning logic (false) or period-ending logic (true).
         *        Period-beginning treats <code>start</code> as inclusive and
         *        <code>end</code> as exclusive.  On the opposite, period-ending
         *        treats <code>start</code> as exclusive and <code>end</code>
         *        as inclusive.
         * @param [daysOfWeek=IDate.WEEK_DAYS] {integer[]} A list of day-of-week that represents
         *        peak periods.
         * @param [isDayEnding=false] {boolean} Whether to use day-ending logic or not.
         *                            If <em>true</em>,
         *                            {@link morn.TimeSeries.DateGroupingMethods.DAY_ENDING_ON_PREVIOUS|DAY_ENDING_ON_PREVIOUS}
         *                            is used.
         * @return {morn.TimeSeries}
         *
         * @see daily_average
         * @see when_off_peak
         *
         * @example
         * // Do not use day-ending logic in average calculation and set Monday through Saturday as peak periods.
         * daily_average_off_peak(ts, null, "6:00", "22:00", null, [MONDAY, TUESDAY, WEDNESDAY, THURSDAY, FRIDAY, SATURDAY])
         * @example
         * // Use day-ending logic in average calculation and set week days as peak periods.
         * daily_average_off_peak(ts, null, "6:00", "22:00", null, IDate.WEEK_DAYS, true)
         */
function daily_average_off_peak(timeSeries, holidays, start, end, isPeriodEnding, daysOfWeek, isDayEnding,iso) {}

/**
         *<p>
          * Creates a TimeSeries from rows of the given TimeSeries
          * where dates are after <code>date</code>.
          * </p>
          *
          * @method
          * @param timeSeries {morn.TimeSeries}.
          * @param date {(lim.IDate|string)}.
          * @return {morn.TimeSeries}
          * @see date_after
          * @see morn.TimeSeries.whenDateAfter
          */
function when_date_is_after(timeSeries, date) {}

/**
         * <p>
         * Creates a TimeSeries from rows of the given TimeSeries
         * where dates are after or equivalent to <code>date</code>.
         * </p>
         *
         * @method
         * @param timeSeries {morn.TimeSeries}.
         * @param date {(lim.IDate|string)}.
         * @return {morn.TimeSeries}
         * @see drop_before
         * @see date_after_or_on
         * @see date_on_or_after
         * @see when_date_is_after_or_on
         */
function when_date_is_on_or_after(timeSeries, date) {}

/**
         * <p>
         * Creates a TimeSeries from rows of the given TimeSeries
         * where dates are after or equivalent to <code>date</code>.
         * </p>
         *
         * @method
         * @param timeSeries {morn.TimeSeries}.
         * @param date {(lim.IDate|string)}.
         * @return {morn.TimeSeries}
         * @see drop_before
         * @see date_after_or_on
         * @see date_on_or_after
         * @see when_date_is_on_or_after
         */
function when_date_is_after_or_on(timeSeries, date) {}

/**
         * <p>
         * Creates a TimeSeries from rows of the given TimeSeries
         * where dates are before <code>date</code>.
         * </p>
         *
         * @method
         * @param timeSeries {morn.TimeSeries}.
         * @param date {(lim.IDate|string)}.
         * @return {morn.TimeSeries}
         * @see date_before
         */
function when_date_is_before(timeSeries, date) {}

/**
         * <p>
         * Creates a TimeSeries from rows of this TimeSeries
         * where dates are before or equivalent to <code>date</code>.
         * </p>
         *
         * @method
         * @param timeSeries {morn.TimeSeries}.
         * @param date {(lim.IDate|string)}.
         * @return {morn.TimeSeries}
         * @see drop_after
         * @see date_before_or_on
         * @see date_on_or_before
         * @see when_date_is_before_or_on
         */
function when_date_is_on_or_before(timeSeries, date) {}

/**
         * <p>
         * Creates a TimeSeries from rows of this TimeSeries
         * where dates are before or equivalent to <code>date</code>.
         * </p>
         *
         * @method
         * @param timeSeries {morn.TimeSeries}.
         * @param date {(lim.IDate|string)}.
         * @return {morn.TimeSeries}
         * @see drop_after
         * @see date_before_or_on
         * @see date_on_or_before
         * @see when_date_is_on_or_before
         */
function when_date_is_before_or_on(timeSeries, date) {}

/**
         * <p>
         * Creates a TimeSeries with rows from the given TimeSeries
         * for which the date can be found in all TimeSeries from
         * <code>otherTimeSeries</code>.
         * </p>
         *
         * @method
         * @param timeSeries {morn.TimeSeries}.
         * @param otherTimeSeries {...morn.TimeSeries}.
         * @return {morn.TimeSeries}
         * @see date_in_all_others
         * @see when_date_is_in_all_others
         */
function when_date_exists_in_all_others(timeSeries, otherTimeSeries) {}

/**
         * <p>
         * Creates a TimeSeries with rows from the given TimeSeries
         * for which the date can be found in all TimeSeries from
         * <code>otherTimeSeries</code>.
         * </p>
         *
         * @method
         * @param timeSeries {morn.TimeSeries}.
         * @param otherTimeSeries {...morn.TimeSeries}.
         * @return {morn.TimeSeries}
         * @see date_in_all_others
         * @see when_date_exists_in_all_others
         */
function when_date_is_in_all_others(timeSeries, otherTimeSeries) {}

/**
         * <p>
         * Creates a TimeSeries from rows of the given TimeSeries
         * for which the date can be found in at least one
         * TimeSeries from <code>otherTimeSeries</code>.
         * </p>
         *
         * @method
         * @param timeSeries {morn.TimeSeries}.
         * @param otherTimeSeries {...morn.TimeSeries}.
         * @return {morn.TimeSeries}
         * @see date_in_one_other
         * @see date_in_other
         * @see when_date_is_in_one_other
         * @see when_date_exists_in_one_other
         */
function when_date_exists_in_one_other(timeSeries, otherTimeSeries) {}

/**
         * <p>
         * Creates a TimeSeries from rows of the given TimeSeries
         * for which the date can be found in at least one
         * TimeSeries from <code>otherTimeSeries</code>.
         * </p>
         *
         * @method
         * @param timeSeries {morn.TimeSeries}.
         * @param otherTimeSeries {...morn.TimeSeries}.
         * @return {morn.TimeSeries}
         * @see date_in_one_other
         * @see date_in_other
         * @see when_date_exists_in_one_other
         */
function when_date_is_in_one_other(timeSeries, otherTimeSeries) {}

/**
         * <p>
         * Creates a TimeSeries from rows of this TimeSeries
         * for which the date cannot be found in any of the
         * TimeSeries provided in <code>otherTimeSeries</code>.
         * </p>
         *
         * @method
         * @param timeSeries {morn.TimeSeries}.
         * @param otherTimeSeries {...morn.TimeSeries}.
         * @return {morn.TimeSeries}
         * @see date_in_none_other
         * @see date_in_none
         */
function when_date_in_none_other(otherTimeSeries) {}

/**
         * <p>
         * Creates a TimeSeries from rows of this TimeSeries
         * for which the date cannot be found in any of the
         * TimeSeries provided in <code>otherTimeSeries</code>.
         * </p>
         *
         * @method
         * @param timeSeries {morn.TimeSeries}.
         * @param otherTimeSeries {...morn.TimeSeries}.
         * @return {morn.TimeSeries}
         * @see when_date_in_none_other
         * @see date_in_none
         */
function date_in_none_other(otherTimeSeries) {}

/**
         * <p>
         * Creates a TimeSeries from rows of this TimeSeries
         * for which the date cannot be found in any of the
         * TimeSeries provided in <code>otherTimeSeries</code>.
         * </p>
         *
         * @method
         * @param timeSeries {morn.TimeSeries}.
         * @param otherTimeSeries {...morn.TimeSeries}.
         * @return {morn.TimeSeries}
         * @see when_date_in_none_other
         * @see date_in_none_other
         */
function date_in_none(otherTimeSeries) {}

/**
         * <p>
         * Creates a TimeSeries from rows of the given TimeSeries
         * where dates are after <code>date</code>.
         * </p>
         *
         * @method
         * @param timeSeries {morn.TimeSeries}.
         * @param date {(lim.IDate|string)}.
         * @return {morn.TimeSeries}
         * @see when_date_is_after
         * @see morn.TimeSeries.whenDateAfter
         */
function date_after(timeSeries, date) {}

/**
         * <p>
         * Creates a TimeSeries from rows of the given TimeSeries
         * where dates are after or equivalent to <code>date</code>.
         * </p>
         *
         * @method
         * @param timeSeries {morn.TimeSeries}.
         * @param date {(lim.IDate|string)}.
         * @return {morn.TimeSeries}
         * @see drop_before
         * @see date_on_or_after
         * @see when_date_is_after_or_on
         * @see when_date_is_on_or_after
         */
function date_after_or_on(timeSeries, date) {}

/**
         * <p>
         * Creates a TimeSeries from rows of the given TimeSeries
         * where dates are after or equivalent to <code>date</code>.
         * </p>
         *
         * @method
         * @param timeSeries {morn.TimeSeries}.
         * @param date {(lim.IDate|string)}.
         * @return {morn.TimeSeries}
         * @see drop_before
         * @see date_after_or_on
         * @see when_date_is_after_or_on
         * @see when_date_is_on_or_after
         */
function date_on_or_after(timeSeries, date) {}
/**
         * <p>
         * Creates a TimeSeries from rows of the given TimeSeries
         * where dates are before <code>date</code>.
         * </p>
         *
         * @method
         * @param timeSeries {morn.TimeSeries}.
         * @param date {(lim.IDate|string)}.
         * @return {morn.TimeSeries}
         * @see when_date_is_before
         */
function date_before(timeSeries, date) {}

/**
         * <p>
         * Creates a TimeSeries from rows of this TimeSeries
         * where dates are before or equivalent to <code>date</code>.
         * </p>
         *
         * @method
         * @param timeSeries {morn.TimeSeries}.
         * @param date {(lim.IDate|string)}.
         * @return {morn.TimeSeries}
         * @see drop_after
         * @see date_on_or_before
         * @see when_date_is_on_or_before
         * @see when_date_is_before_or_on
         */
function date_before_or_on(timeSeries, date) {}

/**
         * <p>
         * Creates a TimeSeries from rows of this TimeSeries
         * where dates are before or equivalent to <code>date</code>.
         * </p>
         *
         * @method
         * @param timeSeries {morn.TimeSeries}.
         * @param date {(lim.IDate|string)}.
         * @return {morn.TimeSeries}
         * @see drop_after
         * @see date_before_or_on
         * @see when_date_is_on_or_before
         * @see when_date_is_before_or_on
         */
function date_on_or_before(timeSeries, date) {}

/**
         * <p>
         * Creates a TimeSeries with rows from the given TimeSeries
         * for which the date can be found in all TimeSeries from
         * <code>otherTimeSeries</code>.
         * </p>
         *
         * @method
         * @param timeSeries {morn.TimeSeries}.
         * @param otherTimeSeries {...morn.TimeSeries}.
         * @return {morn.TimeSeries}
         * @see when_date_is_in_all_others
         * @see when_date_exists_in_all_others
         */
function date_in_all_others(timeSeries, otherTimeSeries) {}

/**
         * <p>
         * Creates a TimeSeries from rows of the given TimeSeries
         * for which the date can be found in at least one
         * TimeSeries from <code>otherTimeSeries</code>.
         * </p>
         *
         * @method
         * @param timeSeries {morn.TimeSeries}.
         * @param otherTimeSeries {...morn.TimeSeries}.
         * @return {morn.TimeSeries}
         * @see date_in_other
         * @see when_date_is_in_one_other
         * @see when_date_exists_in_one_other
         */
function date_in_one_other(timeSeries, otherTimeSeries) {}

/**
         * <p>
         * Creates a TimeSeries from rows of the given TimeSeries
         * for which the date can be found in at least one
         * TimeSeries from <code>otherTimeSeries</code>.
         * </p>
         *
         * @method
         * @param timeSeries {morn.TimeSeries}.
         * @param otherTimeSeries {...morn.TimeSeries}.
         * @return {morn.TimeSeries}
         * @see when_date_is_in_one_other
         * @see when_date_exists_in_one_other
         * @see date_in_one_other
         */
function date_in_other(timeSeries, otherTimeSeries) {}

/**
         * <p>
         * Creates a TimeSeries from rows of the given TimeSeries
         * where dates are after or equivalent to <code>date</code>.
         * </p>
         *
         * @method
         * @param timeSeries {morn.TimeSeries}.
         * @param date {(lim.IDate|string)}.
         * @return {morn.TimeSeries}
         * @see date_after_or_on
         * @see date_on_or_after
         * @see when_date_is_after_or_on
         * @see when_date_is_on_or_after
         */
function drop_before(timeSeries, date) {}

/**
         * <p>
         * Creates a TimeSeries from rows of this TimeSeries
         * where dates are before or equivalent to <code>date</code>.
         * </p>
         *
         * @method
         * @param timeSeries {morn.TimeSeries}.
         * @param date {(lim.IDate|string)}.
         * @return {morn.TimeSeries}
         * @see date_before_or_on
         * @see date_on_or_before
         * @see when_date_is_on_or_before
         * @see when_date_is_before_or_on
         */
function drop_after(timeSeries, date) {}

/**
         * <p>
         * Creates a TimeSeries from rows of the given TimeSeries
         * where at least one column contains a value greater
         * than <code>value</code>.
         * </p>
         *
         * @method
         * @param timeSeries {morn.TimeSeries}.
         * @param value {number}.
         * @return {morn.TimeSeries}
         * @see value_greater_than
         * @see when_value_greater_than
         */
function when_value_is_greater_than(timeSeries, value) {}

/**
         * <p>
         * Creates a TimeSeries from rows of the given TimeSeries
         * where at least one column contains a value greater
         * than or equal to <code>value</code>.
         * </p>
         *
         * @method
         * @param timeSeries {morn.TimeSeries}.
         * @param value {number}.
         * @return {morn.TimeSeries}
         * @see value_greater_or_equal
         * @see when_value_greater_or_equal
         */
function when_value_is_greater_or_equal(timeSeries, value) {}

/**
         * <p>
         * Creates a TimeSeries from rows of the given TimeSeries
         * where at least one column contains a value less
         * than <code>value</code>.
         * </p>
         *
         * @method
         * @param timeSeries {morn.TimeSeries}.
         * @param value {number}.
         * @return {morn.TimeSeries}
         * @see value_less_than
         * @see when_value_less_than
         * @see when_value_is_less_than
         */
function when_value_is_less_than(timeSeries, value) {}

/**
         * <p>
         * Creates a TimeSeries from rows of the given TimeSeries
         * where at least one column contains a value less
         * than or equal to <code>value</code>.
         * </p>
         *
         * @method
         * @param timeSeries {morn.TimeSeries}.
         * @param value {number}.
         * @return {morn.TimeSeries}
         * @see value_less_or_equal
         * @see when_value_less_or_equal
         */
function when_value_is_less_or_equal(timeSeries, value) {}

/**
         * <p>
         * Creates a TimeSeries from rows of the given TimeSeries
         * where the sole column of the given TimeSeries
         * contains a value within the <em>low</em> and <em>high</em>
         * values from <code>thatTimeSeries</code> for the same date.
         *
         * If <code>thatTimeSeries</code> doesn't have a row for the given
         * date or if a column contains a NaN (NaN, +Infinity or -Infinity)
         * for that date, the previous value is
         * used to calculate <em>low</em> and <em>high</em>.
         * </p>
         *
         * @method
         * @param timeSeries {morn.TimeSeries}.
         * @param thatTimeSeries {morn.TimeSeries} Must contain at least 2 columns.
         * @return {morn.TimeSeries}
         * @see value_within
         * @see when_value_within
         */
function when_value_is_within(timeSeries, thatTimeSeries) {}

/**
         * <p>
         * Creates a TimeSeries from rows of the given TimeSeries
         * where the sole column of the given TimeSeries
         * contains a value NOT within the <em>low</em> and <em>high</em>
         * values from <code>thatTimeSeries</code> for the same date.
         *
         * If <code>thatTimeSeries</code> doesn't have a row for the given
         * date or if a column contains a NaN (NaN, +Infinity or -Infinity)
         * for that date, the previous value is
         * used to calculate <em>low</em> and <em>high</em>.
         *
         * </p>
         *
         * @method
         * @param timeSeries {morn.TimeSeries}.
         * @param thatTimeSeries {morn.TimeSeries} Must contain at least 2 columns.
         * @return {morn.TimeSeries}
         * @see value_outside
         * @see value_outside_of
         * @see when_value_outside
         * @see when_value_outside_of
         * @see when_value_is_outside_of
         */
function when_value_is_outside(timeSeries, thatTimeSeries) {}

/**
         * <p>
         * Creates a TimeSeries from rows of the given TimeSeries
         * where the sole column of the given TimeSeries
         * contains a value NOT within the <em>low</em> and <em>high</em>
         * values from <code>thatTimeSeries</code> for the same date.
         *
         * If <code>thatTimeSeries</code> doesn't have a row for the given
         * date or if a column contains a NaN (NaN, +Infinity or -Infinity)
         * for that date, the previous value is
         * used to calculate <em>low</em> and <em>high</em>.
         * </p>
         * @method
         * @param timeSeries {morn.TimeSeries}.
         * @param thatTimeSeries {morn.TimeSeries} Must contain at least 2 columns.
         * @return {morn.TimeSeries}
         * @see value_outside
         * @see value_outside_of
         * @see when_value_outside
         * @see when_value_outside_of
         * @see when_value_is_outside
         */
function when_value_is_outside_of(timeSeries, thatTimeSeries) {}

/**
         * <p>
         * Creates a TimeSeries from rows of the given TimeSeries
         * where at least one column contains a value greater
         * than <code>value</code>.
         * </p>
         *
         * @method
         * @param timeSeries {morn.TimeSeries}.
         * @param value {number}.
         * @return {morn.TimeSeries}
         * @see value_greater_than
         * @see when_value_is_greater_than
         */
function when_value_greater_than(timeSeries, value) {}

/**
         * <p>
         * Creates a TimeSeries from rows of the given TimeSeries
         * where at least one column contains a value greater
         * than or equal to <code>value</code>.
         * </p>
         *
         * @method
         * @param timeSeries {morn.TimeSeries}.
         * @param value {number}.
         * @return {morn.TimeSeries}
         * @see value_greater_or_equal
         * @see when_value_is_greater_or_equal
         */
function when_value_greater_or_equal(timeSeries, value) {}

/**
         * <p>
         * Creates a TimeSeries from rows of the given TimeSeries
         * where at least one column contains a value less
         * than <code>value</code>.
         * </p>
         *
         * @method
         * @param timeSeries {morn.TimeSeries}.
         * @param value {number}.
         * @return {morn.TimeSeries}
         * @see value_less_than
         * @see when_value_is_less_than
         */
function when_value_less_than(timeSeries, value) {}

/**
         * <p>
         * Creates a TimeSeries from rows of the given TimeSeries
         * where at least one column contains a value less
         * than or equal to <code>value</code>.
         * </p>
         *
         * @method
         * @param timeSeries {morn.TimeSeries}.
         * @param value {number}.
         * @return {morn.TimeSeries}
         * @see value_less_or_equal
         * @see when_value_is_less_or_equal
         */
function when_value_less_or_equal(timeSeries, value) {}

/**
         * <p>
         * Creates a TimeSeries from rows of the given TimeSeries
         * where the sole column of the given TimeSeries
         * contains a value within the <em>low</em> and <em>high</em>
         * values from <code>thatTimeSeries</code> for the same date.
         *
         * If <code>thatTimeSeries</code> doesn't have a row for the given
         * date or if a column contains a NaN (NaN, +Infinity or -Infinity)
         * for that date, the previous value is
         * used to calculate <em>low</em> and <em>high</em>.
         * </p>
         *
         * @method
         * @param timeSeries {morn.TimeSeries}.
         * @param thatTimeSeries {morn.TimeSeries} Must contain at least 2 columns.
         * @return {morn.TimeSeries}
         * @see value_within
         * @see when_value_is_within
         */
function when_value_within(timeSeries, thatTimeSeries) {}

/**
         * <p>
         * Creates a TimeSeries from rows of the given TimeSeries
         * where the sole column of the given TimeSeries
         * contains a value NOT within the <em>low</em> and <em>high</em>
         * values from <code>thatTimeSeries</code> for the same date.
         *
         * If <code>thatTimeSeries</code> doesn't have a row for the given
         * date or if a column contains a NaN (NaN, +Infinity or -Infinity)
         * for that date, the previous value is
         * used to calculate <em>low</em> and <em>high</em>.
         * </p>
         * @method
         * @param timeSeries {morn.TimeSeries}.
         * @param thatTimeSeries {morn.TimeSeries} Must contain at least 2 columns.
         * @return {morn.TimeSeries}
         * @see value_outside
         * @see value_outside_of
         * @see when_value_outside_of
         * @see when_value_is_outside
         * @see when_value_is_outside_of
         */
function when_value_outside(timeSeries, thatTimeSeries) {}

/**
         * <p>
         * Creates a TimeSeries from rows of the given TimeSeries
         * where the sole column of the given TimeSeries
         * contains a value NOT within the <em>low</em> and <em>high</em>
         * values from <code>thatTimeSeries</code> for the same date.
         *
         * If <code>thatTimeSeries</code> doesn't have a row for the given
         * date or if a column contains a NaN (NaN, +Infinity or -Infinity)
         * for that date, the previous value is
         * used to calculate <em>low</em> and <em>high</em>.
         * </p>
         *
         * @method
         * @param timeSeries {morn.TimeSeries}.
         * @param thatTimeSeries {morn.TimeSeries} Must contain at least 2 columns.
         * @return {morn.TimeSeries}
         * @see value_outside
         * @see value_outside_of
         * @see when_value_outside
         * @see when_value_is_outside
         * @see when_value_is_outside_of
         */
function when_value_outside_of(timeSeries, thatTimeSeries) {}

/**
         * <p>
         * Creates a TimeSeries from rows of the given TimeSeries
         * where at least one column contains a value greater
         * than <code>value</code>.
         * </p>
         *
         * @method
         * @param timeSeries {morn.TimeSeries}.
         * @param value {number}.
         * @return {morn.TimeSeries}
         * @see when_value_greater_than
         * @see when_value_is_greater_than
         */
function value_greater_than(timeSeries, value) {}

/**
         * <p>
         * Creates a TimeSeries from rows of the given TimeSeries
         * where at least one column contains a value greater
         * than or equal to <code>value</code>.
         * </p>
         *
         * @method
         * @param timeSeries {morn.TimeSeries}.
         * @param value {number}.
         * @return {morn.TimeSeries}
         * @see when_value_greater_or_equal
         * @see when_value_is_greater_or_equal
         */
function value_greater_or_equal(timeSeries, value) {}

/**
         * <p>
         * Creates a TimeSeries from rows of the given TimeSeries
         * where at least one column contains a value less
         * than <code>value</code>.
         * </p>
         *
         * @method
         * @param timeSeries {morn.TimeSeries}.
         * @param value {number}.
         * @return {morn.TimeSeries}
         * @see when_value_less_than
         * @see when_value_is_less_than
         */
function value_less_than(timeSeries, value) {}

/**
         * <p>
         * Creates a TimeSeries from rows of the given TimeSeries
         * where at least one column contains a value less
         * than or equal to <code>value</code>.
         * </p>
         *
         * @method
         * @param timeSeries {morn.TimeSeries}.
         * @param value {number}.
         * @return {morn.TimeSeries}
         * @see when_value_less_or_equal
         * @see when_value_is_less_or_equal
         */
function value_less_or_equal(timeSeries, value) {}

/**
         * <p>
         * Creates a TimeSeries from rows of the given TimeSeries
         * where the sole column of the given TimeSeries
         * contains a value within the <em>low</em> and <em>high</em>
         * values from <code>thatTimeSeries</code> for the same date.
         *
         * If <code>thatTimeSeries</code> doesn't have a row for the given
         * date or if a column contains a NaN (NaN, +Infinity or -Infinity)
         * for that date, the previous value is
         * used to calculate <em>low</em> and <em>high</em>.
         * </p>
         *
         * @method
         * @param timeSeries {morn.TimeSeries}.
         * @param thatTimeSeries {morn.TimeSeries} Must contain at least 2 columns.
         * @return {morn.TimeSeries}
         * @see when_value_within
         * @see when_value_is_within
         */
function value_within(timeSeries, thatTimeSeries) {}

/**
         * <p>
         * Creates a TimeSeries from rows of the given TimeSeries
         * where the sole column of the given TimeSeries
         * contains a value NOT within the <em>low</em> and <em>high</em>
         * values from <code>thatTimeSeries</code> for the same date.
         *
         * If <code>thatTimeSeries</code> doesn't have a row for the given
         * date or if a column contains a NaN (NaN, +Infinity or -Infinity)
         * for that date, the previous value is
         * used to calculate <em>low</em> and <em>high</em>.
         * </p>
         *
         * @method
         * @param timeSeries {morn.TimeSeries}.
         * @param thatTimeSeries {morn.TimeSeries} Must contain at least 2 columns.
         * @return {morn.TimeSeries}
         * @see value_outside_of
         * @see when_value_outside
         * @see when_value_outside_of
         * @see when_value_is_outside
         * @see when_value_is_outside_of
         */
function value_outside(timeSeries, thatTimeSeries) {}

/**
         * <p>
         * Creates a TimeSeries from rows of the given TimeSeries
         * where the sole column of the given TimeSeries
         * contains a value NOT within the <em>low</em> and <em>high</em>
         * values from <code>thatTimeSeries</code> for the same date.
         *
         * If <code>thatTimeSeries</code> doesn't have a row for the given
         * date or if a column contains a NaN (NaN, +Infinity or -Infinity)
         * for that date, the previous value is
         * used to calculate <em>low</em> and <em>high</em>.
         * </p>
         *
         * @method
         * @param timeSeries {morn.TimeSeries}.
         * @param thatTimeSeries {morn.TimeSeries} Must contain at least 2 columns.
         * @return {morn.TimeSeries}
         * @see value_outside
         * @see when_value_outside
         * @see when_value_outside_of
         * @see when_value_is_outside
         * @see when_value_is_outside_of
         */
function value_outside_of(timeSeries, thatTimeSeries) {}

/**
         * <p>
         * Returns rows where the first column from <code>timeSeries</code>
         * crosses over or under the 2nd column.  Optionally,
         * <code>numBefore</code> and <code>numAfter</code> can be
         * used to expand the selection around rows that apply.
         * </p>
         *
         * @method
         * @param timeSeries {morn.TimeSeries}.
         * @param [numBefore=0] {integer}.
         * @param [numAfter] {integer} Default is <code>numBefore</code>
         *                 if provided, otherwise default is <em>0</em>.
         * @return {morn.TimeSeries}
         * @see cross_point
         */
function crossing(timeSeries, numBefore, numAfter) {}

/**
         * <p>
         * Returns rows where the first column from <code>timeSeries</code>
         * crosses over the 2nd column.  Optionally,
         * <code>numBefore</code> and <code>numAfter</code> can be
         * used to expand the selection around rows that apply.
         *
         * </p>
         *
         * @method
         * @param timeSeries {morn.TimeSeries}.
         * @param [numBefore=0] {integer}.
         * @param [numAfter] {integer} Default is <code>numBefore</code>
         *                 if provided, otherwise default is <em>0</em>.
         * @return {morn.TimeSeries}
         * @see cross_point_over
         */
function crossing_over(timeSeries, numBefore, numAfter) {}

/**
         * <p>
         * Returns rows where the first column from <code>timeSeries</code>
         * crosses under the 2nd column.  Optionally,
         * <code>numBefore</code> and <code>numAfter</code> can be
         * used to expand the selection around rows that apply.
         * </p>
         *
         * @method
         * @param timeSeries {morn.TimeSeries}.
         * @param [numBefore=0] {integer}.
         * @param [numAfter] {integer} Default is <code>numBefore</code>
         *                 if provided, otherwise default is <em>0</em>.
         * @return {morn.TimeSeries}
         * @see cross_point_under
         */
function crossing_under(timeSeries, numBefore, numAfter) {}

/**
         * <p>
         * Returns rows where the first column from <code>timeSeries</code>
         * crosses over or under the 2nd column.  Optionally,
         * <code>numBefore</code> and <code>numAfter</code> can be
         * used to expand the selection around rows that apply.
         * </p>
         *
         * @method
         * @param timeSeries {morn.TimeSeries}.
         * @param [numBefore=0] {integer}.
         * @param [numAfter] {integer} Default is <code>numBefore</code>
         *                 if provided, otherwise default is <em>0</em>.
         * @return {morn.TimeSeries}
         * @see crossing
         */
function cross_point(timeSeries, numBefore, numAfter) {}

/**
         * <p>
         * Returns rows where the first column from <code>timeSeries</code>
         * crosses over the 2nd column.  Optionally,
         * <code>numBefore</code> and <code>numAfter</code> can be
         * used to expand the selection around rows that apply.
         * </p>
         *
         * @method
         * @param timeSeries {morn.TimeSeries}.
         * @param [numBefore=0] {integer}.
         * @param [numAfter] {integer} Default is <code>numBefore</code>
         *                 if provided, otherwise default is <em>0</em>.
         * @return {morn.TimeSeries}
         * @see crossing_over
         */
function cross_point_over(timeSeries, numBefore, numAfter) {}

/**
         * <p>
         * Returns rows where the first column from <code>timeSeries</code>
         * crosses under the 2nd column.  Optionally,
         * <code>numBefore</code> and <code>numAfter</code> can be
         * used to expand the selection around rows that apply.
         * </p>
         *
         * @method
         * @param timeSeries {morn.TimeSeries}.
         * @param [numBefore=0] {integer}.
         * @param [numAfter] {integer} Default is <code>numBefore</code>
         *                 if provided, otherwise default is <em>0</em>.
         * @return {morn.TimeSeries}
         * @see crossing_under
         */
function cross_point_under(timeSeries, numBefore, numAfter) {}


// TimeSeries - time offset

/**
         * <p>
         * Applies a time offset to all rows in the specified TimeSeries.
         * </p>
         * @method
         * @param timeSeries {morn.TimeSeries}.
         * @param offset {(integer|function)} Integers are treated as millisecond
         *               offsets.  Functions are treated as callbacks; they
         *               are called with a date argument (the row date) and
         *               must return a date value (the new row date).
         * @return {morn.TimeSeries}
         * @see time_offset
         */
function offset_time(timeSeries, offset) {}

/**
         * <p>
         * Applies a time offset to all rows in the specified TimeSeries.
         * </p>
         * @method
         * @param timeSeries {morn.TimeSeries}.
         * @param offset {(integer|function)} Integers are treated as millisecond
         *               offsets.  Functions are treated as callbacks; they
         *               are called with a date argument (the row date) and
         *               must return a date value (the new row date).
         * @return {morn.TimeSeries}
         * @see offset_time
         */
function time_offset(timeSeries, offset) {}


// TimeSeries - value modification

/**
         * <p>
         * Use this method to replace NaN values within the given
         * TimeSeries.  NaN values can be NaN, +Infinity and -Infinity.
         *
         * The <code>replaceWith</code> argument can be a Number
         * (NaN, +Infinity, -Infinity are acceptable) or a
         * Function (defining 2 to 4 arguments).
         *
         * The resulting TimeSeries has the same number
         * of rows and columns as the given (source) TimeSeries.
         * </p>
         *
         * @method
         * @param timeSeries {morn.TimeSeries}.
         * @param replaceWith {(number|morn.TimeSeries.ValueModifier)}
         *                    If a function, that function is used as a callback
         *                    to replace the NaN values with valid numeric values.
         *                    Note that in this case, the 2nd argument of the callback
         *                    function - <em>value</em> - will always be <em>NaN</em>.
         *                    Still, that 2nd argument must be declared.
         * @param [tag] {string} Overwrites default tag.
         * @return {morn.TimeSeries}
         * @throws TypeError
         *     - If <code>replaceWith</code> is neither a Number nor a Function;
         *     - If <code>replaceWith</code> is a function that defines less
         *       than 2 or more than 4 arguments.
         */
function replace_nans(timeSeries, replaceWith, tag) {}
function replace_NaNs(timeSeries, replaceWith, tag) {}
function replace_Nans(timeSeries, replaceWith, tag) {}

/**
         * <p>
         * Use this method to extrapolate a TimeSeries,
         * adding new dates and replacing the NaNs within it.
         * </p>
         *
         * @method
         * @param timeSeries {morn.TimeSeries}.
         * @param calendar {(morn.Calendar|lim.IDate[]|lim.IDate|string)}.
         * @param replaceWith {(number|morn.TimeSeries.ValueModifier)}
         *                    If a function, that function is used as a callback
         *                    to insert numeric values in places where none exists,
         *                    or where NaNs exist.
         *                    Note that in this case, the 2nd argument of the callback
         *                    function - <em>value</em> - will always be <em>NaN</em>.
         *                    Still, that 2nd argument must be declared.
         * @param [tag] {string} Overwrites default tag.
         * @return {morn.TimeSeries}
         */
function extrapolate(timeSeries, calendar, replaceWith, tag) {}

/**
         * <p>
         * Interpolates the given TimeSeries, inserting missing dates and using
         * linear-interpolation to populate missing values.
         * This method replaces all NaN values found between valid numbers.
         * </p>
         *
         * @param timeSeries {morn.TimeSeries}.
         * @param {(morn.Calendar|lim.IDate[]|lim.IDate|string|function)} [calendar=[]]
         *        Dates to insert in this TimeSeries.  It can be a list of dates,
         *        one simple date (possibly as a string).  It can also be a function,
         *        in which case the function will be executed with two arguments:
         *        <code>timeSeries.dateAt(0)</code> and <code>timeSeries.dateAt(-1)</code>
         *        respectively.  The function must return an object of the previously
         *        listed data-types.
         * @param {boolean} [useLinearTime=false] - Whether the interpolation is based
         *        on row position within the TimeSeries (default), or based on the linear
         *        passage of time.
         * @returns {morn.TimeSeries}
         * @method
         */
function interpolate_linear(timeSeries, calendar) {}

/**
         * <p>
         * Copies the values of the given TimeSeries forward.
         *</p>
          * @method
          * @param timeSeries {morn.TimeSeries}.
          * @param {(morn.Calendar|lim.IDate[]|morn.TimeSeries|function)} [calendar] - An array of IDate objects,
          *                          a Calendar, a TimeSeries, or a function that receives two IDate arguments
          *                          and returns either a Calendar or array of IDate objects.
          * @param [tag="fill_forward"] {string} Overwrites default tag.
          * @return {morn.TimeSeries}
          */
function fill_forward(timeSeries, calendar, tag) {}

/**
         * <p>
         * Copies the values of the given TimeSeries backward.
         * </p>
         *
         * @method
         * @param timeSeries {morn.TimeSeries}.
         * @param {(morn.Calendar|lim.IDate[]|morn.TimeSeries|function)} [calendar] - An array of IDate objects,
         *                          a Calendar, a TimeSeries, or a function that receives two IDate arguments
         *                          and returns either a Calendar or array of IDate objects.
         * @param [tag="fill_backward"] {string} Overwrites default tag.
         * @return {morn.TimeSeries}
         */
function fill_backward(timeSeries, calendar, tag) {}

/**
         * <p>
         * Fills the values with NaN for missing dates.
         *</p>
          * @method
          * @param timeSeries {morn.TimeSeries}.
          * @param {(morn.Calendar|lim.IDate[]|morn.TimeSeries|function)} [calendar] - An array of IDate objects,
          *                          a Calendar, a TimeSeries, or a function that receives two IDate arguments
          *                          and returns either a Calendar or array of IDate objects.
          * @param [tag="fillNan"] {string} Overwrites default tag.
          * @return {morn.TimeSeries}
          */
function fill_nan(timeSeries, calendar, tag) {}

/**
         * @method
         * @see shape_custom
         */
function modify_points(timeSeries, tag, modifier) {}

/**
         * <p>
         * This method iterates through the rows of the given
         * TimeSeries and calls <code>modifier</code> for every
         * row.  The <code>modifier</code> callback receives
         * two arguments:
         *   > Row row - the Row object
         *   > Number rowIndex - the index position within TimeSeries
         *
         * Within the callback, <em>this</em> points to the TimeSeries.
         *
         * To modify a row, <code>modifier</code> needs to return
         * a valid Row or DataPoint[],
         * which will be inserted into the returned TimeSeries.
         *
         * If <code>modifier</code> returns <em>null</em>
         * or <em>undefined</em>, the existing row is transfered
         * to the new TimeSeries unmodified.
         *
         * The resulting TimeSeries has the same number
         * of rows and columns as the given (source) TimeSeries.
         * </p>
         *
         * @method
         * @param timeSeries {morn.TimeSeries}.
         * @param modifier {function}.
         * @return {morn.TimeSeries}
         * @throws IllegalStateException if <code>modifier</code> returns anything
         *         other than Row, DataPoint[], null or <em>undefined</em>.
         */
function modify_rows(timeSeries, modifier) {}

/**
         * <p>
         * Shapes, or adjusts all data-points within the given TimeSeries.
         * The adjustment can be relative or absolute.
         * Optionally, <code>tag</code> can be provided to overwrite the default
         * one.
         * </p>
         * @method
         * @param timeSeries {morn.TimeSeries}.
         * @param adj {(string|number)} For relative adjustments, use a String
         *            argument ("+10%", "+1.3", "-2.5", etc.)  For absolute adjustments,
         *            simply provide the number to use, as a Number.
         * @param [tag] {string} Overwrites default tag.
         * @return {morn.TimeSeries}
         * @see offset_value
         * @see shape_all
         */

function value_offset(timeSeries, adj, tag) {}

/**
         * <p>
         * Shapes, or adjusts all data-points within the given TimeSeries.
         * The adjustment can be relative or absolute.
         * Optionally, <code>tag</code> can be provided to overwrite the default
         * one.
         * </p>
         *
         * @method
         * @param timeSeries {morn.TimeSeries}.
         * @param adj {(string|number)} For relative adjustments, use a String
         *            argument ("+10%", "+1.3", "-2.5", etc.)  For absolute adjustments,
         *            simply provide the number to use, as a Number.
         * @param [tag] {string} Overwrites default tag.
         * @return {morn.TimeSeries}
         * @see value_offset
         * @see shape_all
         */
function offset_value(timeSeries, adj, tag) {}

/**
         * <p>
         * Shapes, or adjusts all data-points within the given TimeSeries.
         * The adjustment can be relative or absolute.
         * Optionally, <code>tag</code> can be provided to overwrite the default
         * one.
         * </p>
         *
         * @method
         * @param timeSeries {morn.TimeSeries}.
         * @param adj {(string|number)} For relative adjustments, use a String
         *            argument ("+10%", "+1.3", "-2.5", etc.)  For absolute adjustments,
         *            simply provide the number to use, as a Number.
         * @param [tag] {string} Overwrites default tag.
         * @return {morn.TimeSeries}
         * @see value_offset
         * @see offset_value
         */
function shape_all(timeSeries, adj, tag) {}

/**
         * <p>
         * Shapes, or adjusts a period within the given TimeSeries.
         * The adjustment can be relative or absolute.  The period is defined
         * by <code>start</code> and <code>end</code>; both are inclusive.
         * Optionally, <code>tag</code> can be provided to overwrite the default
         * one.
         * </p>
         *
         * @method
         * @param timeSeries {morn.TimeSeries}.
         * @param adj {(string|number)} For relative adjustments, use a String
         *            argument ("+10%", "+1.3", "-2.5", etc.)  For absolute adjustments,
         *            simply provide the number to use, as a Number.
         * @param start {(lim.IDate|string)} The start of the period to shape (inclusive.)
         * @param end {(lim.IDate|string)} The end of the period to shape (inclusive.)
         * @param [tag] {string} Overwrites default tag.
         * @return {morn.TimeSeries}
         * @see shape_range
         */
function shape_period(timeSeries, adj, start, end, tag) {}

/**
         * <p>
         * Shapes, or adjusts a period within the given TimeSeries.
         * The adjustment can be relative or absolute.  The period is defined
         * by <code>start</code> and <code>end</code>; both are inclusive.
         * Optionally, <code>tag</code> can be provided to overwrite the default
         * one.
         * </p>
         *
         * @method
         * @param timeSeries {morn.TimeSeries}.
         * @param adj {(string|number)} For relative adjustments, use a String
         *            argument ("+10%", "+1.3", "-2.5", etc.)  For absolute adjustments,
         *            simply provide the number to use, as a Number.
         * @param start {(lim.IDate|string)} The start of the period to shape (inclusive.)
         * @param end {(lim.IDate|string)} The end of the period to shape (inclusive.)
         * @param [tag] {string} Overwrites default tag.
         * @return {morn.TimeSeries}
         * @see shape_period
         */
function shape_range(timeSeries, adj, start, end, tag) {}

/**
         * <p>
         * Shapes, or adjusts data-points that pertain to the specified month(s)
         * within the given TimeSeries.
         * The adjustment can be relative or absolute.  The months are specified
         * as an array of Integer (0-11).
         * Optionally, <code>tag</code> can be provided to overwrite the default
         * one.
         * </p>
         * @method
         * @param timeSeries {morn.TimeSeries}.
         * @param adj {(string|number)} For relative adjustments, use a String
         *            argument ("+10%", "+1.3", "-2.5", etc.)  For absolute adjustments,
         *            simply provide the number to use, as a Number.
         * @param months {integer[]} 0=Jan, 1=Feb, ..., 11=Dec.
         * @param [tag] {string} Overwrites default tag.
         * @return {morn.TimeSeries}
         */
function shape_months(timeSeries, adj, months, tag) {}

/**
         * <p>
         * Shapes, or adjusts a particular data-point within this TimeSeries.
         * The adjustment can be relative or absolute.  The data-point is defined
         * by <code>date</code>.
         * Optionally, <code>tag</code> can be provided to overwrite the default
         * one.
         * </p>
         *
         * @method
         * @param timeSeries {morn.TimeSeries}.
         * @param adj {(string|number)} For relative adjustments, use a String
         *            argument ("+10%", "+1.3", "-2.5", etc.)  For absolute adjustments,
         *            simply provide the number to use, as a Number.
         * @param date {(lim.IDate|string)} The date of the data-point to adjust.
         * @param [tag] {string} Overwrites default tag.
         * @return {morn.TimeSeries}
         */
function shape_point(timeSeries, adj, date, tag) {}

/**
         * <p>
         * This method iterates through the data-points of the given
         * TimeSeries and calls <code>modifier</code> for every
         * numerical data-point.  The <code>modifier</code> callback receives
         * four arguments:
         *   > IDate date
         *   > Number value
         *   > Integer rowIndex
         *   > Integer colIndex
         *
         * Note that <code>modifier</code> is not called for NaN data-points.
         * Within the callback, <em>this</em> points to the TimeSeries.
         *
         * To modify a data-point, <code>modifier</code> needs to return
         * a Number - NaN, +Infinity and -Infinity are acceptable returned
         * values. The new value is used to <em>mutate</em> the existing
         * data-point into a new one (for tracing purpose.)
         *
         * If <code>modifier</code> returns anything other than a
         * number - <em>null</em>, <em>undefined</em> or anything else -
         * the existing data-point is transfered to the new TimeSeries
         * unmodified.
         *
         * The resulting TimeSeries has the same number
         * of rows and columns as the given (source) TimeSeries.
         * </p>
         *
         * @method
         * @param timeSeries {morn.TimeSeries}.
         * @param tag {string} To document data-point mutations.
         * @param modifier {morn.TimeSeries.ValueModifier} Callback function used to replace
         *                 the existing values with new ones.
         * @return {morn.TimeSeries}
         * @throws TypeError
         *    - If <code>modifier</code> is not a Function that
         *      defines 2 to 4 arguments.
         *    - If <code>tag</code> is not a String.
         */
function shape_custom(timeSeries, tag, modifier) {}

/**
         * <p>
         * Computes the standard moving average within each column.
         * </p>
         *
         * @method
         * @param timeSeries {morn.TimeSeries}.
         * @param numPoints {integer}.
         * @return {morn.TimeSeries}
         * @see standard_moving_average
         */
function shape_sma(timeSeries, numPoints) {}

// Built-in calculations

/**
         * <p>
         * Computes the standard moving average within each column.
         * </p>
         *
         * @method
         * @param {morn.TimeSeries} timeSeries.
         * @param {Integer} numPoints.
         * @return {morn.TimeSeries}
         * @see shape_sma
         */
function standard_moving_average(timeSeries, numPoints) {}

/**
         * <p>
         *  Computes the <em>change</em> over <code>numPoints</code>,
         *  for each column, and returns a new TimeSeries containing
         *  the computed values.  <em>Change</em> is calculated by
         *  subtracting the price from <code>numPoints</code> ago to
         *  the current price, as in:
         * </p>
         *
         * <p>
         *     <code>CURRENT_PRICE - PREV_PRICE</code>
         * </p>
         *
         * @method
         * @param {morn.TimeSeries} timeSeries
         * @param {Integer} [numPoints=1] Must be positive.
         * @return {morn.TimeSeries}
         */
function change(timeSeries, numPoints) {}

/**
         * <p>
         *  Computes the <em>percent change</em> over <code>numPoints</code>,
         *  for each column, and returns a new TimeSeries containing
         *  the computed values.  <em>Percent change</em> - or %chg -
         *  is calculated by subtracting the price from <code>numPoints</code>
         *  ago to the current price, dividing that difference by that same
         *  previous price, as in:
         * </p>
         *
         * <p>
         *     <code>( (CURRENT_PRICE - PREV_PRICE) / abs(PREV_PRICE) ) * 100</code>
         * </p>
         *
         * @method
         * @param {morn.TimeSeries} timeSeries.
         * @param {Integer} [numPoints=1] Must be positive.
         * @return {morn.TimeSeries}
         */
function change_percent(timeSeries, numPoints) {}

/**
         * <p>
         *  Computes the <em>percent change</em> over <code>numPoints</code>,
         *  for each column, and returns a new TimeSeries containing
         *  the computed values.  <em>Percent change</em> - or %chg -
         *  is calculated by subtracting the price from <code>numPoints</code>
         *  ago to the current price, dividing that difference by that same
         *  previous price, as in:
         * </p>
         *
         * <p>
         *     <code>( (CURRENT_PRICE - PREV_PRICE) / abs(PREV_PRICE) ) * 100</code>
         * </p>
         *
         * @method
         * @param {morn.TimeSeries} timeSeries.
         * @param {Integer} [numPoints=1] Must be positive.
         * @return {morn.TimeSeries}
         * @see change_percent
         */
function percent_change(timeSeries, numPoints) {}

/**
         * <p>
         *  Computes the <em>percent change</em> over <code>numPoints</code>,
         *  for each column, and returns a new TimeSeries containing
         *  the computed values.  <em>Percent change</em> - or %chg -
         *  is calculated by subtracting the price from <code>numPoints</code>
         *  ago to the current price, dividing that difference by that same
         *  previous price, as in:
         * </p>
         *
         * <p>
         *     <code>( (CURRENT_PRICE - PREV_PRICE) / PREV_PRICE ) * 100</code>
         * </p>
         *
         * @method
         * @param {morn.TimeSeries} timeSeries.
         * @param {Integer} [numPoints=1] Must be positive.
         * @return {morn.TimeSeries}
         */
function change_percent_raw(timeSeries, numPoints) {}

/**
         * <p>
         *  Computes the <em>relative change</em> over <code>numPoints</code>,
         *  for each column, and returns a new TimeSeries containing
         *  the computed values.  <em>Relative change</em> - or %chg / 100 -
         *  is calculated by subtracting the price from <code>numPoints</code>
         *  ago to the current price, dividing that difference by that same
         *  previous price, as in:
         * </p>
         *
         * <p>
         *     <code>(CURRENT_PRICE - PREV_PRICE) / abs(PREV_PRICE)</code>
         * </p>
         *
         * @method
         * @param {morn.TimeSeries} timeSeries.
         * @param {Integer} [numPoints=1] Must be positive.
         * @return {morn.TimeSeries}
         */
function change_ratio(timeSeries, numPoints) {}

/**
         * <p>
         *  Computes the <em>relative change</em> over <code>numPoints</code>,
         *  for each column, and returns a new TimeSeries containing
         *  the computed values.  <em>Relative change</em> - or %chg / 100 -
         *  is calculated by subtracting the price from <code>numPoints</code>
         *  ago to the current price, dividing that difference by that same
         *  previous price, as in:
         * </p>
         *
         * <p>
         *     <code>(CURRENT_PRICE - PREV_PRICE) / PREV_PRICE</code>
         * </p>
         *
         * @method
         * @param {morn.TimeSeries} timeSeries.
         * @param {Integer} [numPoints=1] Must be positive.
         * @return {morn.TimeSeries}
         */
function change_ratio_raw(timeSeries, numPoints) {}

/**
         * <p>
         *  Computes the <em>return</em> over <code>numPoints</code>,
         *  for each column, and returns a new TimeSeries containing
         *  the computed values.  <em>Return</em> is calculated as
         *  the natural logarithmic of the current price over the previous
         *  price (from <code>numPoints</code> ago), as in:
         * </p>
         *
         * <p>
         *     <code>Math.log(CURRENT_PRICE / PREV_PRICE)</code>
         * </p>
         *
         * @param timeSeries {morn.TimeSeries}.
         * @param {Integer} [numPoints=1].
         * @return {morn.TimeSeries}
         * @method
         */
function change_return(timeSeries, numPoints) {}

// TimeSeries - row math

/**
         * <p>
         *  Creates a 1-column TimeSeries containing the count of
         *  valid "numbers" for each row within all TimeSeries provided.
         *  NaNs are not counted, all others count as <em>1</em>.
         * </p>
         *
         * <p>
         *  For each row, if a NaN is found, this method looks for
         *  the previous number within the same column, and uses it if found.
         * </p>
         *
         * @method
         * @param timeSeries {...morn.TimeSeries}.
         * @return {morn.TimeSeries}
         */
function row_count(timeSeries) {}

/**
         * <p>
         *  Creates a 1-column TimeSeries containing the sum of
         *  valid "numbers" for each row within all TimeSeries provided.
         *  NaNs are treated as <em>0</em>.
         * </p>
         *
         * <p>
         *  For each row, if a NaN is found, this method looks for
         *  the previous number within the same column, and uses it if found.
         * </p>
         *
         * <p>
         *  By default, if no previous number is found for a column, zero (0)
         *  is assumed.  Use the <code>skipNaNs</code> argument
         *  to change this default.
         * </p>
         *
         * @method
         * @param [skipNaNs] {boolean} Set to <em>false</em> so that the result of a row
         *                   is NaN upon seeing any NaN within that row.
         * @param timeSeries {...morn.TimeSeries}.
         * @return {morn.TimeSeries}
         */
function row_sum(timeSeries) {}

/**
         * <p>
         *  Creates a 1-column TimeSeries containing the sum of all columns
         *  for each row within all TimeSeries provided.
         * </p>
         *
         * <p>
         *  For each row, if a NaN is found, this method looks for
         *  the previous number within the same column, and uses it if found.
         * </p>
         *
         * <p>
         *  By default, if a valid number cannot be found by looking in the past, that row
         *  results in <em>NaN</em>.  Use the <code>skipNaNs</code> argument
         *  to change this default.
         * </p>
         *
         * @method
         * @param [skipNaNs] {boolean} Set to <em>true</em> so that NaN values are
         *                   ignored (replaced with zeros).
         * @param timeSeries {...morn.TimeSeries}.
         * @return {morn.TimeSeries}
         */
function row_add(timeSeries) {}

/**
         * <p>
         *  Creates a 1-column TimeSeries containing the result of subtracting
         *  the sum of columns 1-n from column 0, for each row within all
         *  TimeSeries provided.
         * </p>
         *
         * <p>
         *  For each row, if a NaN is found, this method looks for
         *  the previous number within the same column, and uses it if found.
         * </p>
         *
         * <p>
         *  By default, if a valid number cannot be found by looking in the past, that row
         *  results in <em>NaN</em>.  Use the <code>skipNaNs</code> argument
         *  to change this default.
         * </p>
         *
         * @method
         * @param [skipNaNs] {boolean} Set to <em>true</em> so that NaN values are ignored.
         * @param timeSeries {...morn.TimeSeries}.
         * @return {morn.TimeSeries}
         * @see row_diff
         * @see row_difference
         */
function row_subtract(timeSeries) {}
/**
         * <p>
         *  Creates a 1-column TimeSeries containing the result of subtracting
         *  the sum of columns 1-n from column 0, for each row within all
         *  TimeSeries provided.
         * </p>
         *
         * <p>
         *  For each row, if a NaN is found, this method looks for
         *  the previous number within the same column, and uses it if found.
         * </p>
         *
         * <p>
         *  By default, if a valid number cannot be found by looking in the past, that row
         *  results in <em>NaN</em>.  Use the <code>skipNaNs</code> argument
         *  to change this default.
         * </p>
         *
         * @method
         * @param [skipNaNs] {boolean} Set to <em>true</em> so that NaN values are ignored.
         * @param timeSeries {...morn.TimeSeries}.
         * @return {morn.TimeSeries}
         * @see row_subtract
         * @see row_difference
         */
function row_diff(timeSeries) {}
/**
         * <p>
         *  Creates a 1-column TimeSeries containing the result of subtracting
         *  the sum of columns 1-n from column 0, for each row within all
         *  TimeSeries provided.
         * </p>
         *
         * <p>
         *  For each row, if a NaN is found, this method looks for
         *  the previous number within the same column, and uses it if found.
         * </p>
         *
         * <p>
         *  By default, if a valid number cannot be found by looking in the past, that row
         *  results in <em>NaN</em>.  Use the <code>skipNaNs</code> argument
         *  to change this default.
         * </p>
         *
         * @method
         * @param [skipNaNs] {boolean} Set to <em>true</em> so that NaN values are ignored.
         * @param timeSeries {...morn.TimeSeries}.
         * @return {morn.TimeSeries}
         * @see row_subtract
         * @see row_diff
         */
function row_difference(timeSeries) {}

/**
         * <p>
         *  Creates a 1-column TimeSeries containing the result of multiplying
         *  all columns within each row, for all TimeSeries provided.
         * </p>
         *
         * <p>
         *  For each row, if a NaN is found, this method looks for
         *  the previous number within the same column, and uses it if found.
         * </p>
         *
         * <p>
         *  By default, if a valid number cannot be found by looking in the past, that row
         *  results in <em>NaN</em>.  Use the <code>skipNaNs</code> argument
         *  to change this default.
         * </p>
         *
         * @method
         * @param [skipNaNs] {boolean} Set to <em>true</em> so that NaN values are ignored.
         * @param timeSeries {...morn.TimeSeries}.
         * @return {morn.TimeSeries}
         * @see row_product
         */
function row_multiply(timeSeries) {}
/**
         * <p>
         *  Creates a 1-column TimeSeries containing the result of multiplying
         *  all columns within each row, for all TimeSeries provided.
         * </p>
         *
         * <p>
         *  For each row, if a NaN is found, this method looks for
         *  the previous number within the same column, and uses it if found.
         * </p>
         *
         * <p>
         *  By default, if a valid number cannot be found by looking in the past, that row
         *  results in <em>NaN</em>.  Use the <code>skipNaNs</code> argument
         *  to change this default.
         * </p>
         *
         * @method
         * @param [skipNaNs] {boolean} Set to <em>true</em> so that NaN values are ignored.
         * @param timeSeries {...morn.TimeSeries}.
         * @return {morn.TimeSeries}
         * @see row_multiply
         */
function row_product(timeSeries) {}

/**
         * <p>
         *  Creates a 1-column TimeSeries containing the result of dividing
         *  the value of column 0 by the value of each subsequent columns,
         *  for each row within all TimeSeries provided.
         * </p>
         *
         * <p>
         *  For each row, if a NaN is found, this method looks for
         *  the previous number within the same column, and uses it if found.
         * </p>
         *
         * <p>
         *  By default, if a valid number cannot be found by looking in the past, that row
         *  results in <em>NaN</em>.  Use the <code>skipNaNs</code> argument
         *  to change this default.
         * </p>
         *
         * @method
         * @param [skipNaNs] {boolean} Set to <em>true</em> so that NaN values are ignored.
         * @param timeSeries {...morn.TimeSeries}.
         * @return {morn.TimeSeries}
         * @see row_div
         * @see row_quotient
         */
function row_divide(timeSeries) {}
/**
         * <p>
         *  Creates a 1-column TimeSeries containing the result of dividing
         *  the value of column 0 by the value of each subsequent columns,
         *  for each row within all TimeSeries provided.
         * </p>
         *
         * <p>
         *  For each row, if a NaN is found, this method looks for
         *  the previous number within the same column, and uses it if found.
         * </p>
         *
         * <p>
         *  By default, if a valid number cannot be found by looking in the past, that row
         *  results in <em>NaN</em>.  Use the <code>skipNaNs</code> argument
         *  to change this default.
         * </p>
         *
         * @method
         * @param [skipNaNs] {boolean} Set to <em>true</em> so that NaN values are ignored.
         * @param timeSeries {...morn.TimeSeries}.
         * @return {morn.TimeSeries}
         * @see row_divide
         * @see row_quotient
         */
function row_div(timeSeries) {}
/**
         * <p>
         *  Creates a 1-column TimeSeries containing the result of dividing
         *  the value of column 0 by the value of each subsequent columns,
         *  for each row within all TimeSeries provided.
         * </p>
         *
         * <p>
         *  For each row, if a NaN is found, this method looks for
         *  the previous number within the same column, and uses it if found.
         * </p>
         *
         * <p>
         *  By default, if a valid number cannot be found by looking in the past, that row
         *  results in <em>NaN</em>.  Use the <code>skipNaNs</code> argument
         *  to change this default.
         * </p>
         *
         * @method
         * @param [skipNaNs] {boolean} Set to <em>true</em> so that NaN values are ignored.
         * @param timeSeries {...morn.TimeSeries}.
         * @return {morn.TimeSeries}
         * @see row_div
         * @see row_divide
         */
function row_quotient(timeSeries) {}

/**
         * <p>
         *  Creates a 1-column TimeSeries containing the lowest value
         *  found within each row of all TimeSeries provided.
         * </p>
         *
         * <p>
         *  For each row, if a NaN is found, this method looks for
         *  the previous number within the same column, and uses it if found.
         * </p>
         *
         * <p>
         *  By default, if a valid number cannot be found by looking in the past, that row
         *  results in <em>NaN</em>.  Use the <code>skipNaNs</code> argument
         *  to change this default.
         * </p>
         *
         * @method
         * @param [skipNaNs] {boolean} Set to <em>true</em> so that NaN values are ignored.
         * @param timeSeries {...morn.TimeSeries}.
         * @return {morn.TimeSeries}
         */
function row_min(timeSeries) {}

/**
         * <p>
         *  Creates a 1-column TimeSeries containing the highest value
         *  found within each row of all TimeSeries provided.
         * </p>
         *
         * <p>
         *  For each row, if a NaN is found, this method looks for
         *  the previous number within the same column, and uses it if found.
         * </p>
         *
         * <p>
         *  By default, if a valid number cannot be found by looking in the past, that row
         *  results in <em>NaN</em>.  Use the <code>skipNaNs</code> argument
         *  to change this default.
         * </p>
         *
         * @method
         * @param [skipNaNs] {boolean} Set to <em>true</em> so that NaN values are ignored.
         * @param timeSeries {...morn.TimeSeries}.
         * @return {morn.TimeSeries}
         */
function row_max(timeSeries) {}

/**
         * <p>
         *  Creates a 1-column TimeSeries containing the spread between
         *  the highest and lowest values found within each row of
         *  all TimeSeries provided.
         * </p>
         *
         * <p>
         *  For each row, if a NaN is found, this method looks for
         *  the previous number within the same column, and uses it if found.
         * </p>
         *
         * <p>
         *  By default, if a valid number cannot be found by looking in the past, that row
         *  results in <em>NaN</em>.  Use the <code>skipNaNs</code> argument
         *  to change this default.
         * </p>
         *
         * @method
         * @param [skipNaNs] {boolean} Set to <em>true</em> so that NaN values are ignored.
         * @param timeSeries {...morn.TimeSeries}.
         * @return {morn.TimeSeries}
         */
function row_spread(timeSeries) {}

/**
         * <p>
         *  Creates a 1-column TimeSeries containing the average of all
         *  columns for each row of all TimeSeries provided.
         * </p>
         *
         * <p>
         *  For each row, if a NaN is found, this method looks for
         *  the previous number within the same column, and uses it if found.
         * </p>
         *
         * <p>
         *  By default, if a valid number cannot be found by looking in the past, that row
         *  results in <em>NaN</em>.  Use the <code>skipNaNs</code> argument
         *  to change this default.
         * </p>
         *
         * @method
         * @param [skipNaNs] {boolean} Set to <em>true</em> so that NaN values are ignored.
         * @param timeSeries {...morn.TimeSeries}.
         * @return {morn.TimeSeries}
         * @see row_avg
         */
const row_average(timeSeries) {}
/**
         * <p>
         *  Creates a 1-column TimeSeries containing the average of all
         *  columns for each row of all TimeSeries provided.
         * </p>
         *
         * <p>
         *  For each row, if a NaN is found, this method looks for
         *  the previous number within the same column, and uses it if found.
         * </p>
         *
         * <p>
         *  By default, if a valid number cannot be found by looking in the past, that row
         *  results in <em>NaN</em>.  Use the <code>skipNaNs</code> argument
         *  to change this default.
         * </p>
         *
         * @method
         * @param [skipNaNs] {boolean} Set to <em>true</em> so that NaN values are ignored.
         * @param timeSeries {...morn.TimeSeries}.
         * @return {morn.TimeSeries}
         * @see row_average
         */
const row_avg(timeSeries) {}


// TimeSeries - math

/**
         * <p>
         * Calculates the sum (a.k.a. addition)
         * for each cell in all TimeSeries provided.
         * This method only returns rows (dates) that exist in all TimeSeries.
         * All TimeSeries sizes must match one another, or equal 1.
         * </p>
         *
         * @param addend {...(morn.TimeSeries|number)} Arguments to add, must contain
         *        at least one TimeSeries.
         * @return {morn.TimeSeries}
         * @method
         */
function cell_sum(addend) {}

/**
         * <p>
         * Calculates the difference (a.k.a. subtraction)
         * for each cell in all TimeSeries provided.
         * This method only returns rows (dates) that exist in all TimeSeries.
         * All TimeSeries sizes must match one another, or equal 1.
         * </p>
         *
         * @param subtrahend {...(morn.TimeSeries|number)} Arguments to subtract, from left
         *        to right.  Argument list must contain at least one TimeSeries.
         * @return {morn.TimeSeries}
         * @method
         */
function cell_diff(subtrahend) {}

/**
         * <p>
         * Calculates the product (a.k.a. multiplication)
         * for each cell in all TimeSeries provided.
         * This method only returns rows (dates) that exist in all TimeSeries.
         * All TimeSeries sizes must match one another, or equal 1.
         * </p>
         *
         * @param multiplier {...(morn.TimeSeries|number)} Arguments to multiply, must contain
         *        at least one TimeSeries.
         * @return {morn.TimeSeries}
         * @method
         */
function cell_product(multiplier) {}

/**
         * <p>
         * Calculates the quotient (a.k.a. division)
         * for each cell in all TimeSeries provided.
         * This method only returns rows (dates) that exist in all TimeSeries.
         * All TimeSeries sizes must match one another, or equal 1.
         * </p>
         *
         * @param subtrahend {...(morn.TimeSeries|number)} Arguments to divide, from left
         *        to right.  Argument list must contain at least one TimeSeries.
         * @return {morn.TimeSeries}
         * @method
         */
function cell_quotient(divisor) {}

/**
         * <p>
         * Calculates the product from raising each cell in a TimeSeries to the power
         * of the next value (number or TimeSeries).
         * The <em>base</em> comes from first argument; exponent(s) come from the subsequent
         * argument(s).
         *
         *
         * If TimeSeries, cells are matched across TimeSeries; the resulting
         * TimeSeries only contains rows (dates) that exist in all
         * TimeSeries. All TimeSeries sizes must match one another, or equal 1.
         * </p>
         *
         * @param power {...(morn.TimeSeries|number)} Arguments to raise to a power.
         * @return {morn.TimeSeries}
         * @method
         *
         * @example
         *       TimeSeries.cellPower(ts, 2)
         *       => A TimeSeries containing the square value for each data-point from \`ts\`.
         */
function cell_power(power) {}

/**
         * <p>
         * Creates a new TimeSeries containing exponential weight
         * assigned to each date; recent dates are given more weight.
         * </p>
         *
         * @param {(morn.TimeSeries|morn.Calendar|lim.IDate[])} dates.
         * @param {number} lambda - Range 0-1, inclusive.
         * @returns {morn.TimeSeries}
         * @example
         *     var dates = [
         *             as.date('2016-01-01'),
         *             as.date('2016-02-01'),
         *             as.date('2016-03-01'),
         *             as.date('2016-04-01')
         *     ];
         *     exponential_weight(dates, 0.94);
         *         => 2016-01-01,0.04983504
         *            2016-02-01,0.053016
         *            2016-03-01,0.0564
         *            2016-04-01,0.06
         * @method
         */
function exponential_weight(dates, lambda) {}

/**
         * <p>
         * Reduces all rows of the TimeSeries to a one-dimensional array of numbers
         * representing the sum of all rows.
         * </p>
         * @method
         * @param timeSeries {morn.TimeSeries}.
         * @return {number[]} Sum within each column.  If the
         *                    time series only contains 1 column, this method
         *                    returns an array of one number.
         */
function reduce_to_sum(timeSeries) {}

/**
         * @method
         * @see reduce_to_sum
         */
function get_sum(timeSeries) {}
/**
         * @method
         * @see reduce_to_sum
         */
function get_total(timeSeries) {}


/**
         * <p>
         * Reduces all rows of the TimeSeries to a one-dimensional array of numbers
         * representing the count (of numeric values) of all rows.
         * </p>
         * @method
         * @param timeSeries {morn.TimeSeries}.
         * @return {number[]} Count within each column.  If the
         *                    time series only contains 1 column, this method
         *                    returns an array of one number.
         */
function reduce_to_count(timeSeries) {}

/**
         * @method
         * @see reduce_to_count
         */
function get_count(timeSeries) {}


/**
         * <p>
         * Reduces all rows of the TimeSeries to a one-dimensional array of numbers
         * representing the average of all rows.
         * </p>
         * @method
         * @param timeSeries {morn.TimeSeries}.
         * @return {number[]} Average within each column.  If the
         *                    time series only contains 1 column, this method
         *                    returns an array of one number.
         */
function reduce_to_average(timeSeries) {}

/**
         * @method
         * @see reduce_to_average
         */
function get_average(timeSeries) {}
/**
         * @method
         * @see reduce_to_average
         */
function get_avg(timeSeries) {}


/**
         * <p>
         * Reduces all rows of the TimeSeries to a one-dimensional array of numbers
         * representing the lowest value within all rows.
         * </p>
         * @method
         * @param timeSeries {morn.TimeSeries}.
         * @return {number[]} Lowest value within each column.  If the
         *                    time series only contains 1 column, this method
         *                    returns an array of one number.
         */
function reduce_to_min(timeSeries) {}

/**
         * @method
         * @see reduce_to_min
         */
function get_min(timeSeries) {}
/**
         * @method
         * @see reduce_to_min
         */
function get_low(timeSeries) {}


/**
         * <p>
         * Reduces all rows of the TimeSeries to a one-dimensional array of numbers
         * representing the highest value within all rows.
         * </p>
         * @method
         * @param timeSeries {morn.TimeSeries}.
         * @return {number[]} Highest value within each column.  If the
         *                    time series only contains 1 column, this method
         *                    returns an array of one number.
         */
function reduce_to_max(timeSeries) {}

/**
         * @method
         * @see reduce_to_max
         */
function get_max(timeSeries) {}
/**
         * @method
         * @see reduce_to_max
         */
function get_high(timeSeries) {}


/**
         * <p>
         * Reduces all rows of the TimeSeries to a one-dimensional array of numbers.
         * </p>
         * @method
         * @param timeSeries {morn.TimeSeries}.
         * @param reducer {morn.TimeSeries.Reducer}.
         * @return {number[]} A computed value for each column.  If the
         *                    time series only contains 1 column, this method
         *                    returns an array of one number.
         */
function reduce(timeSeries, reducer) {}

/**
         * <p>
         *     Returns the correlation between matched columns from <code>timeSeries</code> and
         *     <code>otherTimeSeries</code>.
         * </p>
         * <p>
         *     A note about correlation involving multi-column time-series:
         * </p>
         * <ul>
         *     <li> If only one of the two time-series has multiple columns, a correlation
         *          is calculated for each column against the other time-series' sole column. </li>
         *     <li> If both are multi-column time-series, a correlation is calculated
         *          for each matched column.  Unmatched columns result in a correlation
         *          of <i>0</i> (no correlation).
         * </ul>
         *
         * @param {morn.TimeSeries} timeSeries - A time-series to correlate.
         * @param {morn.TimeSeries} otherTimeSeries - Another time-series to correlate with.
         * @param {(morn.TimeSeries.CorrelationOptions|Number)} [options]
         *        Correlation options, provided as a single number (aka fill option)
         *        or multiple options wrapped in a CorrelationOptions object.
         * @returns {Number[]} One correlation result per column.  Correlations range between -1 and 1.
         * @method
         */
function correlation(timeSeries, otherTimeSeries, options) {}

/**
         * <p>
         * Creates a matrix representing a set of correlation results between all combinations of the given TimeSeries.
         * </p>
         * @param {(morn.TimeSeries[]|morn.TimeSeries)} timeSeries - If an array is given, all time-series
         *                            must be single-column.  Otherwise a single time-series is expected
         *                            and all combinations of its columns are correlated against one another.
         * @param {(morn.TimeSeries.CorrelationOptions|Number)} [options]
         *        Correlation options, provided as a single number (aka fill option)
         *        or multiple options wrapped in a CorrelationOptions object.
         * @returns {Number[][]} A matrix representing a set of correlation results between all
         *                       combinations of the given TimeSeries (a.k.a. symmetric diagonal).
         * @method
         */
function correlation_matrix(timeSeries, options) {}

/**
         * <p>
         * Creates a TimeSeries that represents the rolling correlation between two datasets.
         * </p>
         *
         * @param {(morn.TimeSeries|morn.Product)} dataset1 - First dataset to correlate, must be single-column.
         * @param {(morn.TimeSeries|morn.Product)} dataset2 - Second dataset to correlate, must be single-column.
         * @param {(lim.Date.Modifier|Integer)} range - Number of values included in each correlation,
         *                                      defined using a number of values (integer) or time period
         *                                      (date modifier).  When passing a date modifier, callers likely
         *                                      want to use a <em>negative</em> modifier, to calculate
         *                                      a correlation by looking back at the previous numbers
         *                                      (aka history, <code>IDate.modifierOfDays(-30)</code>).
         *                                      Although less common, this method does support calculating correlations
         *                                      by looking forward (toward upcoming numbers).
         * @param {morn.TimeSeries.CorrelationOptions} options - Optional parameters to refine the
         *                                             calculation of each correlation.
         * @returns {morn.TimeSeries}
         * @method
         */
function correlation_roll(dataset1, dataset2, range, options) {}

/**
         * <p>
         * A convenience method to create personalized rolling calculations.
         * </p>
         *
         * @param {morn.TimeSeries} timeSeries.
         * @param {(Integer|lim.IDate.Modifier)} sliceSize
         *        Size of each slice to be computed, reduced to a single row.
         *        If an integer, each slice contains <code>sliceSize</code> rows;
         *        <code>sliceSize</code> must be a positive integer.
         *
         *        If an IDate.Modifier, each slice is cut based on a starting date
         *        -- <code>dateAt(0), dateAt(1), dateAt(2)</code>, etc. --
         *        and ends at the date returned by <code>sliceSize(start_date)</code>,
         *        excluding that date from the slice.  Date modifiers must always return
         *        a valid date; backward modifiers are allowed.
         *
         * @param {(Integer|morn.TimeSeries.DateReducer)} dateReducer
         *        Date assigned to each slice.
         *        If an integer, each slice is assigned a date that results from
         *        <code>timeSeriesSlice.dateAt(sliceDate)</code>.
         *
         *        If a TimeSeries.DateReducer, the slice is assigned the date
         *        returned by <code>sliceDate(timeSeriesSlice)</code>.
         *
         *        If the date returned is <code>null</code>, the method skips
         *        over to the next slice.
         *
         * @param {morn.TimeSeries.NumericReducer} numReducer
         *        Calculation that transforms a TimeSeries slice into one or more
         *        number(s).
         *
         *        If <code>numReducer</code> returns null, the method
         *        skips over to the next slice.
         *
         * @param {string} [tag="rolling_calc"]
         *        Tag to be given to each data-point created by this method.
         *
         * @returns {morn.TimeSeries}
         *          The number of columns in the returned TimeSeries depends
         *          on how many number(s) are returned by <code>reducer</code>.
         * @see morn.TimeSeries#dateAt
         * @method
         */
function rolling_calc(timeSeries, sliceSize, dateReducer, numReducer, tag) {}

/**
         * <p>
         *  Computes the regression-through-origin value.
         * </p>
         *
         * <p>
         *  This method ignores rows that contain NaN values.  It also ignores dates
         *  not found in both <code>y</code> and <code>x</code>.
         * </p>
         *
         * @param {morn.TimeSeries} y - Predictable outcome or dependent variable; one column.
         * @param {morn.TimeSeries} x - Explanatory or independent variables; one or more column(s).
         * @returns {morn.TimeSeries.LinearRegressionResults} Regression-through-the-origin details.
         * @see https://online.stat.psu.edu/~ajw13/stat501/SpecialTopics/Reg_thru_origin.pdf
         * @method
         */
function regression_through_origin(y, x) {}

/**
         * <p>
         *  Computes a linear regression, returning values <code>intercept</code> and
         *  <code>slope</code> (one slope coefficient per independent variable.)
         * </p>
         *
         * <p>
         *  This method ignores rows that contain NaN values.  It also ignores dates
         *  not found in both <code>y</code> and <code>x</code>.
         * </p>
         *
         * @param {morn.TimeSeries} y - Dependent variable or predictable outcome; one column.
         * @param {morn.TimeSeries} x - Explanatory or independent variables; one or more columns.
         * @returns {morn.TimeSeries.LinearRegressionResults} Linear-regression details.
         * @see https://en.wikipedia.org/wiki/Linear_regression
         * @method
         */
function regression_linear(y, x) {}

/**
         * <p>
         * Generates predicted values based on input variables and
         * the results of {@link regression_linear|regression_linear()}.
         * </p>
         *
         * @param {morn.TimeSeries} x
         *        Explanatory or independent variables; number of columns
         *        must match the number of <code>linearRegr.slope</code> values.
         * @param {morn.TimeSeries.LinearRegressionResults} linearRegr
         *        Results of a linear regression.
         * @returns {morn.TimeSeries} Single-column TimeSeries that represents the
         *          predicted values.
         * @method
         */
function regression_linear_prediction(x, linearRegr) {}

/**
         * <p>
         * Creates a new TimeSeries by computing an average
         * for each group of rows.  The groups are determined
         * by <code>groupingMethod</code>.
         * </p>
         *
         * @method
         * @param timeSeries {morn.TimeSeries}.
         * @param groupingMethod {morn.TimeSeries.DateGroupingMethod}
         *                       See {@link morn.TimeSeries.DateGroupingMethods} for pre-defined grouping methods.
         * @return {morn.TimeSeries}
         */
function group_by_average(timeSeries, groupingMethod) {}

/**
         * <p>
         * Creates a new TimeSeries by computing a count
         * for each group of rows, determined by <code>groupingMethod</code>.
         * </p>
         *
         * @method
         * @param timeSeries {morn.TimeSeries}.
         * @param groupingMethod {morn.TimeSeries.DateGroupingMethod}
         *                       See {@link morn.TimeSeries.DateGroupingMethods} for pre-defined grouping methods.
         * @return {morn.TimeSeries}
         */
function group_by_count(timeSeries, groupingMethod) {}

/**
         * <p>
         * Creates a new TimeSeries by computing a sum
         * for each group of rows, determined by <code>groupingMethod</code>.
         * </p>
         *
         * @method
         * @param timeSeries {morn.TimeSeries}.
         * @param groupingMethod {morn.TimeSeries.DateGroupingMethod}
         *                       See {@link morn.TimeSeries.DateGroupingMethods} for pre-defined grouping methods.
         * @return {morn.TimeSeries}
         */
function group_by_sum(timeSeries, groupingMethod) {}

/**
         * <p>
         * Creates a new TimeSeries by computing a minimum
         * for each group of rows, determined by <code>groupingMethod</code>.
         *</p>
          *
          * @method
          * @param timeSeries {morn.TimeSeries}.
          * @param groupingMethod {morn.TimeSeries.DateGroupingMethod}
          *                       See {@link morn.TimeSeries.DateGroupingMethods} for pre-defined grouping methods.
          * @return {morn.TimeSeries}
          */
function group_by_min(timeSeries, groupingMethod) {}

/**
         * <p>
         * Creates a new TimeSeries by computing a maximum
         * for each group of rows, determined by <code>groupingMethod</code>.
         * </p>
         *
         * @method
         * @param timeSeries {morn.TimeSeries}.
         * @param groupingMethod {morn.TimeSeries.DateGroupingMethod}
         *                       See {@link morn.TimeSeries.DateGroupingMethods} for pre-defined grouping methods.
         * @return {morn.TimeSeries}
         */
function group_by_max(timeSeries, groupingMethod) {}

/**
         * <p>
         * Creates a new TimeSeries by grouping rows together
         * - as per <code>groupingMethod</code>.  This method
         * computes a value for each column within each group,
         * using <code>reducer</code>.
         * </p>
         *
         * @method
         * @param timeSeries {morn.TimeSeries}.
         * @param groupingMethod {morn.TimeSeries.DateGroupingMethod}
         *                       See {@link morn.TimeSeries.DateGroupingMethods} for pre-defined grouping methods.
         * @param reducer {morn.TimeSeries.Reducer}.
         * @param [tag="group_by"] {string}.
         * @return {morn.TimeSeries}
         */
function group_by(timeSeries, groupingMethod, reducer, tag) {}

/**
         * <p>
         * Creates a new TimeSeries by computing hourly averages
         * from the data within <code>timeSeries</code>.
         * </p>
         *
         * @method
         * @param timeSeries {morn.TimeSeries}.
         * @param [isHourEnding=false] {boolean} Whether to use hour-ending logic or not.
         *                            If <em>true</em>,
         *                            {@link morn.TimeSeries.DateGroupingMethods.HOUR_ENDING_ON_NEXT|HOUR_ENDING_ON_NEXT}
         *                            is used.
         * @return {morn.TimeSeries}.
         * @see morn.TimeSeries.hourlyAverage
         * @see morn.TimeSeries.hourlyCount
         */
function hourly_average(timeSeries, isHourEnding) {}

/**
         * <p>
         * Creates a new TimeSeries by computing hourly count
         * (of numeric data-points) from the data within <code>timeSeries</code>.
         * </p>
         *
         * @method
         * @param timeSeries {morn.TimeSeries}.
         * @param [isHourEnding=false] {boolean} Whether to use hour-ending logic or not.
         *                            If <em>true</em>,
         *                            {@link morn.TimeSeries.DateGroupingMethods.HOUR_ENDING_ON_NEXT|HOUR_ENDING_ON_NEXT}
         *                            is used.
         * @return {morn.TimeSeries}
         * @see morn.TimeSeries.hourlyCount
         * @see morn.TimeSeries.hourlyAverage
         */
function hourly_count(timeSeries, isHourEnding) {}

/**
         * <p>
         * Creates a new TimeSeries by computing daily averages
         * from the data within <code>timeSeries</code>.
         * </p>
         *
         * @method
         * @param timeSeries {morn.TimeSeries}.
         * @param [isDayEnding=false] {boolean} Whether to use day-ending logic or not.
         *                            If <em>true</em>,
         *                            {@link morn.TimeSeries.DateGroupingMethods.DAY_ENDING_ON_PREVIOUS|DAY_ENDING_ON_PREVIOUS}
         *                            is used.
         * @return {morn.TimeSeries}
         */
function daily_average(timeSeries, isDayEnding) {}

/**
         * <p>
         * Creates a new TimeSeries by computing daily counts
         * (of numeric data-points) from the data within <code>timeSeries</code>.
         * </p>
         *
         * @method
         * @param timeSeries {morn.TimeSeries}.
         * @param [isDayEnding=false] {boolean} Whether to use day-ending logic or not
         *                            If <em>true</em>,
         *                            {@link morn.TimeSeries.DateGroupingMethods.DAY_ENDING_ON_PREVIOUS|DAY_ENDING_ON_PREVIOUS}
         *                            is used.
         * @return {morn.TimeSeries}
         */
function daily_count(timeSeries, isDayEnding) {}

/**
         * <p>
         * Creates a new TimeSeries by computing monthly averages
         * from the data within <code>timeSeries</code>.
         * </p>
         *
         * @method
         * @param timeSeries {morn.TimeSeries}.
         * @param [isMonthEnding=false] {boolean} Whether to use month-ending logic or not
         *                            If <em>true</em>,
         *                            {@link morn.TimeSeries.DateGroupingMethods.MONTH_ENDING_ON_PREVIOUS|MONTH_ENDING_ON_PREVIOUS}
         *                            is used.
         * @return {morn.TimeSeries}
         */
function monthly_average(timeSeries, isMonthEnding) {}

/**
         * <p>
         * Creates a new TimeSeries by computing monthly counts
         * (of numeric data-points) from the data within <code>timeSeries</code>.
         * </p>
         *
         * @method
         * @param timeSeries {morn.TimeSeries}.
         * @param [isMonthEnding=false] {boolean} Whether to use month-ending logic or not.
         *                            If <em>true</em>,
         *                            {@link morn.TimeSeries.DateGroupingMethods.MONTH_ENDING_ON_PREVIOUS|MONTH_ENDING_ON_PREVIOUS}
         *                            is used.
         * @return {morn.TimeSeries}
         */
function monthly_count(timeSeries, isMonthEnding) {}

/**
         * <p>
         * Rounds to the specified number of decimals.
         *
         * This method accepts Number and TimeSeries as its first argument.
         *
         * Remarks:
         * <ul>
         *  <li>
         *   If <code>numDecimals</code> is greater than 0 (zero), then the number
         *   is rounded to the specified number of decimal places.
         *  </li>
         *  <li>
         *   If <code>numDecimals</code> is 0, the number is rounded to the
         *   nearest integer.
         *  </li>
         *  <li>
         *   If <code>numDecimals</code> is less than 0 (zero), the number
         *   is rounded to the left of the decimal point.
         *  </li>
         *
         *  <li> To always round up (away from zero), use <code>{@link round_up}</code>. </li>
         *  <li> To always round down (torward zero), use <code>{@link round_down}</code>. </li>
         * </ul>
         * </p>
         *
         * @method
         * @param numOrTs {(number|morn.TimeSeries)}.
         * @param numDecimals {integer}.
         * @param [tag] {string} Only applied when <code>numOrTs</code> is
         *                     a TimeSeries, and even then it's still optional.
         * @return {(number|morn.TimeSeries)} The return type matches that of <code>numOrTs</code>.
         * @see lim.Math.round
         * @see morn.TimeSeries.round
         */
function round(fn) {}

/**
         * <p>
         * Rounds a number up, away from 0 (zero).
         *
         * This method accepts Number and TimeSeries as its first argument.
         * </p>
         *
         * @method
         * @param numOrTs {(number|morn.TimeSeries)}.
         * @param numDecimals {integer}.
         * @param [tag] {string} Only applied when <code>numOrTs</code> is
         *                     a TimeSeries, and even then it's still optional.
         * @return {(number|morn.TimeSeries)} The return type matches that of <code>numOrTs</code>.
         * @see lim.Math.roundUp
         * @see morn.TimeSeries.roundUp
         */
function round_up(fn) {}

/**
         * <p>
         * Rounds a number down, toward 0 (zero).
         *
         * This method accepts Number and TimeSeries as its first argument.
         * </p>
         *
         * @method
         * @param numOrTs {(number|morn.TimeSeries)}.
         * @param numDecimals {integer}.
         * @param [tag] {string} Only applied when <code>numOrTs</code> is
         *                     a TimeSeries, and even then it's still optional.
         * @return {(number|morn.TimeSeries)} The return type matches that of <code>numOrTs</code>.
         * @see lim.Math.roundDown
         * @see morn.TimeSeries.roundDown
         */
function round_down(fn) {}

/**
         * <p>
         * Rounds a number up.  If the number is positive, this
         * method rounds away from 0 (zero).  If number is negative,
         * this method rounds toward 0.
         *
         * This method accepts Number and TimeSeries as its first argument.
         * </p>
         *
         * @method
         * @param numOrTs {(number|morn.TimeSeries)}.
         * @param numDecimals {integer}.
         * @param [tag] {string} Only applied when <code>numOrTs</code> is
         *                     a TimeSeries, and even then it's still optional.
         * @return {(number|morn.TimeSeries)} The return type matches that of <code>numOrTs</code>.
         * @see lim.Math.ceil
         * @see morn.TimeSeries.ceil
         */
function ceil(fn) {}

/**
         * @method
         * @see ceil
         */
function ceiling(fn) {}

/**
         * <p>
         * Rounds a number down.  If the number is positive, this
         * method rounds towards 0 (zero).  If number is negative,
         * this method rounds away from 0.
         *
         * This method accepts Number and TimeSeries as its first argument.
         * </p>
         *
         * @method
         * @param numOrTs {(number|morn.TimeSeries)}.
         * @param numDecimals {integer}.
         * @param [tag] {string} Only applied when <code>numOrTs</code> is
         *                     a TimeSeries, and even then it's still optional.
         * @return {(number|morn.TimeSeries)} The return type matches that of <code>numOrTs</code>.
         * @see lim.Math.floor
         * @see morn.TimeSeries.floor
         */
function floor(fn) {}

// TimeSeries - print/output

/**
         * <p>
         * Convert to CSV format.
         * </p>
         * @method
         * @param obj {(morn.TimeSeries|Array|Array[])}.
         * @return {string}
         * @see morn.Utils.toCsv
         */
function to_csvfunction (obj) {}

/**
         * <p>
         * Convert to JSON format.
         * </p>
         * @method
         * @param obj {(morn.TimeSeries|Object)}.
         * @return {string}
         * @see morn.Utils.toJson
         */
function to_jsonfunction (obj) {}

/**
         * <p>
         * Return the string representation of an object.
         * </p>
         * @method
         * @param obj {*}.
         * @return {string}
         * @see lim.String.toString
         */
function to_string(obj) {}


// Business days

/**
         * <p>
         * Returns whether the current date
         * is a business day.  That is, a weekday
         * (Monday through Friday) that's not a holiday (if provided).
         * </p>
         * @method
         * @param date {lim.IDate}.
         * @param [holidays] {?morn.Holidays} A holiday calendar.
         * @return {boolean}
         *
         * @see morn.Holidays.isBusinessDay
         * @see lim.IDate.isWeekday
         */
function is_business_day(holidaysIdx, fnHoliday, fnDate) {}

/**
         * <p>
         *  Adjusts the current date to find the closest
         *  adjacent business day.  If the current date
         *  is a business day, it is returned as is.
         * </p>
         *
         * <p>
         *  This method does not affect the <em>time</em>
         *  portion of the date object.
         * </p>
         *
         * @method
         * @param date {lim.IDate}.
         * @param direction {integer} The direction in which
         *                  to look for a business day; <em>-1</em> to
         *                  look in the past, <em>1</em> to look toward
         *                  the future.
         * @param [holidays] {?morn.Holidays} A holiday calendar.
         * @return {lim.IDate}
         *
         * @see morn.Holidays.adjustBusinessDay
         * @see lim.IDate.adjustWeekday
         */
function adj_business_day(holidaysIdx, fnHoliday, fnDate) {}

/**
         * <p>
         * Adds weekdays to the current date.  Holidays (if provided)
         * are skipped over; they are not counted.
         *</p>
          * @method
          * @param date {lim.IDate}.
          * @param numDays {integer}.
          * @param [holidays] {?morn.Holidays} A holiday calendar.
          * @return {lim.IDate}
          *
          * @see morn.Holidays.addBusinessDays
          * @see lim.IDate.addWeekdays
          */
function add_business_days(holidaysIdx, fnHoliday, fnDate) {}

/**
         * <p>
         * Returns a date object that represents the previous
         * business day, regardless of whether <code>date</code>
         * is a business or not.
         * </p>
         *
         * @method
         * @param date {lim.IDate}.
         * @param [holidays] {?morn.Holidays} A holiday calendar.
         * @return {lim.IDate}
         *
         * @see morn.Holidays.previousBusinessDay
         * @see lim.IDate.previousWeekday
         */
function prev_business_day(holidaysIdx, fnHoliday, fnDate) {}

/**
         * <p>
         * Returns a date object that represents the next
         * business day, regardless of whether <code>date</code>
         * is a business or not.
         * </p>
         *
         * @method
         * @param date {lim.IDate}.
         * @param [holidays] {?morn.Holidays} A holiday calendar.
         * @return {lim.IDate}
         *
         * @see morn.Holidays.nextBusinessDay
         * @see lim.IDate.nextWeekday
         */
function next_business_day(holidaysIdx, fnHoliday, fnDate) {}

/**
         * <p>
         * Converts <code>timeSeries</code> to the desired target currency.
         * </p>
         * @param {morn.TimeSeries} timeSeries Time-series to convert.
         * @param {string} srcCurrency Currency code that represent <code>timeSeries</code>
         *        currently.
         * @param {string} targetCurrency Target currency code.
         * @returns {morn.TimeSeries} A new time-series object containing the same data,
         *          converted to the desired currency (target).
         * @throws {Error} If <code>srcCurrency</code> or <code>targetCurrency</code> is invalid.
         * @throws {Error} If <code>timeSeries</code> has data prior to when exchange rate data
         *         became available.
         * @see morn.CurrencyUtils
         * @method
         * @example
         * // Convert \`ts\` from USD to CAD.
         * convert_currency(ts, "USD", "CAD")
         */
function convert_currency(data, srcCurrency, targetCurrency) {}

/**
         * <p>
         * Returns exchange rates from source currency code to target currency code
         * </p>
         * @param {string} srcCurrency Source currency code.
         * @param {string} targetCurrency Target currency code.
         * @returns {morn.TimeSeries} History of exchange-rate conversion factors,
         *          source-to-target, as a time-series.
         * @throws {Error} If <code>srcCurrency</code> or <code>targetCurrency</code> is invalid.
         * @see morn.CurrencyUtils
         * @method
         */
function get_exchange_rates(srcCurrency, targetCurrency) {}

/**
         * <p>
         * Returns exchange rate from source currency code to target currency code
         * </p>
         * @param {string} srcCurrency Source currency code.
         * @param {string} targetCurrency Target currency code.
         * @param {(lim.IDate|string)} date Effective date for the desired exchange rate.
         * @returns {number} Exchange rate effective as of <code>date</code> (source-to-target),
         *          NaN if no exchange rate was available as of <code>date</code>.
         * @throws {Error} If <code>srcCurrency</code> or <code>targetCurrency</code> is invalid.
         * @see morn.CurrencyUtils
         * @method
         */
function get_exchange_rate(srcCurrency, targetCurrency, date) {}

/**
         * <p>
         * Applies a unit-of-measure conversion to <code>data</code>.
         * </p>
         * @param {(number|number[]|Array.<number[]>|morn.TimeSeries)} data Data to convert.
         * @param {string} source Unit of measure to convert from.
         * @param {string} target Unit of measure to convert to.
         * @returns {(number|number[]|Array.<number[]>|morn.TimeSeries)} <code>data</code>
         *          converted to the <code>target</code> unit of measure.
         * @throws {TypeError} If <code>data</code> is not an acceptable object,
         *         if <code>target</code> or <code>source</code> are not supported units of measure.
         * @see morn.UnitOfMeasure
         * @method
         * @example
         * // Convert \`ts\` from metric tons to kilograms.
         * convert_uom(ts, "mt", "kg")
         * @example
         * // Convert an array of numbers from metric tons to kilograms.
         * convert_uom([ 1, 2, 3 ], "mt", "kg")  // [1000, 2000, 3000]
         */
function convert_uom(data, source, target) {}

/**
         * <p>
         * Applies a unit-of-measure conversion to <code>data</code>.
         * </p>
         * @param {string} source Unit of measure.
         * @param {string} target Unit of measure.
         * @returns {number} Multiplication factor to convert from <code>source</code>
         *          to <code>target</code>.
         * @throws {TypeError} If either <code>source</code> or <code>target</code> are not strings,
         *         or if either is empty.
         * @throws {IllegalArgumentException} If either <code>source</code> or <code>target</code>
         *         is not recognized.
         * @throws {UnsupportedOperationException} If the conversion from <code>source</code> to
         *         <code>target</code> is not possible.
         * @see {morn.UnitOfMeasure}
         * @method
         * @example
         * uom_conversion_factor("mt", "kg");  // 1000
         * uom_conversion_factor("kg", "mt");  // 0.001
         */
function uom_conversion_factor(source, target) {}

`;

export default global;
