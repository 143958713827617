import axios from 'axios';
import { rootCertificates } from 'tls';
import { getUserName, getUserCompany } from '../../../utils/authService';
import templateString from '../../../utils/stringUtils';
import getAPIConfigs from './config.js';

const config = getAPIConfigs();
let companyName = '';
let replacement;

function getGroupDetails(group) {
  return axios.get(config.GROUP_DETAILS_API, {
    params: {
      companyName,
      groupName: group,
    },
  }).then(response => response.data).catch(errors => errors.response);
}

function chunk(array, size) {
  if (!array) return [];
  const firstChunk = array.slice(0, size);
  if (!firstChunk.length) {
    return array;
  }
  return [firstChunk].concat(chunk(array.slice(size, array.length), size));
}

function entitleGroupToFeed(groupDetails, feedName, deleteFlag, groupName) {
  if (deleteFlag) {
    groupDetails.feedNames.splice(
      groupDetails.feedNames.findIndex(group => group === feedName), 1,
    );
  } else {
    groupDetails.feedNames.push(feedName);
  }


  return axios.put(config.GROUP_COMPANY_API, groupDetails, { params: { companyName, deleteFlag, groupName } })
    .then((response) => {
      const data = response;
      data.name = groupDetails.name;
      data.delete = deleteFlag;
      return data;
    }).catch(errors => errors.response);
}

// function fetchFeedKeysPaginated(feedName, page = 1, previousResponse = []) {
//   replacement = {
//     FEED_NAME: feedName,
//     PAGE: page,
//   };
//   return axios.get(templateString(config.GET_FEED_KEYS, replacement)) // Append the page number to the base URL
//     .then(response => response.data)
//     .then((newResponse) => {
//       newResponse.pop();
//       const response = [...previousResponse, ...newResponse]; // Combine the two arrays

//       if (newResponse.length > 1) {
//         page++;

//         return fetchFeedKeysPaginated(feedName, page, response);
//       }

//       return response;
//     });
// }

const actions = {
  getUserFeeds({ commit }) {
    const USER_NAME = getUserName();
    replacement = {
      USER_NAME,
    };
    return axios
      .get(templateString(config.API_USER_FEEDS, replacement))
      .then((response) => {
        commit('ADD_FEEDS', response.data.sort((a, b) => {
          const nameA = a.name.toUpperCase(); // ignore upper and lowercase
          const nameB = b.name.toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }

          // names must be equal
          return 0;
        }));
        commit('USER_FEEDS', response.data.sort((a, b) => {
          const nameA = a.name.toUpperCase(); // ignore upper and lowercase
          const nameB = b.name.toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }

          // names must be equal
          return 0;
        }));
        commit('SET_PAGE_STATUS', 'SUCCESS');
        return response;
      })
      .catch((errors) => {
        commit('SET_PAGE_STATUS', 'ERROR');
        return errors;
      });
  },
  getFeedDetailsByName({ commit }, feedName) {
    replacement = {
      FEED_NAME: feedName,
    };
    return new Promise((resolve, reject) => {
      axios
        .get(templateString(config.FEED_DETAILS_API, replacement))
        .then((response) => {
          commit('SET_FEED_DETAILS', response.data);
          commit('SET_PAGE_STATUS', 'SUCCESS');
          commit('SET_ACTIVE_FEED', feedName);
          resolve();
        })
        .catch((error) => {
          commit('SET_PAGE_STATUS', 'ERROR');
          if (error.response.status === 403) {
            commit('SET_ERROR_MESSAGE', `You do not have authorization to access ${feedName}`);
          } else if (error.response.status === 404) {
            commit('SET_ERROR_MESSAGE', `${feedName} not found`);
          } else {
            commit('SET_ERROR_MESSAGE', 'Internal Server Error, Please try again after sometime.');
          }
          commit('SET_PAGE_STATUS', 'ERROR');
          reject(error);
        });
    });
  },

  async getEntitlements({ commit }, feedName) {
    const USER_NAME = getUserName();
    replacement = {
      USER_NAME,
      FEED_NAME: feedName,
      COMPANY_NAME: companyName,
    };
    return axios
      .get(config.FEED_ENTILEMENT_API, {
        params: {
          userName: USER_NAME,
          feedName,
          companyName,
        },
      })
      .then((response) => {
        commit('SET_FEED_ENTITLEMENTS', response.data);
        return response;
      })
      .catch(errors => errors.response);
  },

  async getGroupEntitlements({ commit }, groupName) {
    if (companyName === '') {
      companyName = getUserCompany();
    }
    replacement = {
      COMPANY_NAME: companyName,
      GROUP_NAME: groupName,
    };
    return axios
      .get(templateString(config.GET_GROUP_USERS_API, replacement))
      .then((response) => {
        commit('SET_FEED_GROUP_ENTITLEMENTS', response.data);
        return response;
      })
      .catch(errors => errors.response);
  },

  deleteFeed({ commit }, feedName) {
    const USER_NAME = getUserName();
    replacement = {
      USER_NAME,
      FEED_NAME: feedName,
    };
    return axios
      .delete(config.DELETE_FEED_API, {
        params: {
          userName: USER_NAME,
          feedName,
        },
      })
      .then(response => response)
      .catch(error => error.response);
  },
  getUserRoles({ commit }) {
    const USER_NAME = getUserName();
    replacement = {
      USER_NAME,
    };
    return axios
      .get(templateString(config.USER_ROLES_API, replacement))
      .then((response) => {
        commit('SET_USER_ROLES', response.data.roles);
      })
      .catch((error) => {
        throw error;
      });
  },
  async getActiveUsers({ commit }) {
    if (companyName === '') {
      companyName = getUserCompany();
    }
    return axios
      .get(config.ACTIVE_USERS_API, {
        params: {
          companyName,
        },
      })
      .then((response) => {
        commit('SET_ACTIVE_USERS', response.data.userList.map(u => u.toLowerCase()));
        return response;
      })
      .catch(errors => errors.response);
  },

  async saveReadEntitlements({ commit, state }, payload) {
    payload.numsubs = 1000;
    const obj = {
      payload,
    };
    return axios.put(config.LICENSE_UPDATE_API, obj)
      .then(response => response)
      .catch(errors => errors.response);
  },

  async postSaveReadEntitlements({ commit }, payload) {
    companyName = getUserCompany();
    const requestPayload = {
      feed: payload.feedName,
      company: companyName,
      numsubs: payload.numsubs === '-1' ? -1 : 1000,
      users: payload.users,
    };
    return axios.post(templateString(config.LICENSE_SAVE_API), requestPayload)
      .then(response => response)
      .catch(errors => errors.response);
  },

  saveWriteEntitlements({ commit }, payload) {
    const requestPayload = { writers: payload.users };
    return axios
      .post(config.WRITERS_API, requestPayload, {
        params: {
          feedName: payload.feedName,
          deleteValue: payload.delete,
        },
      })
      .then(response => response)
      .catch(errors => errors.response);
  },

  async saveGroupEntitlements({ commit }, payload) {
    const responses = [];
    let groupDetails = {};
    const { groupList } = payload;
    const { feedName } = payload;
    /* eslint-disable no-await-in-loop */
    for (let index = 0; index < groupList.length; index++) {
      groupDetails = await getGroupDetails(groupList[index].name);
      if (!groupDetails['feedNames']) {
        groupDetails.feedNames = [];
      }
      if (Object.keys(groupDetails).length > 0) {
        const response = entitleGroupToFeed(
          groupDetails,
          feedName,
          groupList[index].delete,
          groupList[index].name,
        );
        responses.push(response);
      }
    }
    /* eslint-disable no-await-in-loop */
    const data = await Promise.all(responses);
    return data;
  },

  deleteLicenseByName({ commit }, license) {
    return axios
      .delete(config.DELETE_LICENSE_API, { params: { licenseName: license } })
      .then(response => response)
      .catch(error => error.response);
  },
  getDatasource({ commit }) {
    const USER_NAME = getUserName();
    replacement = {
      USER_NAME,
    };
    return axios
      .get(templateString(config.USER_DATASOURCE_API, replacement))
      .then((response) => {
        commit('SET_USER_DATASOURCE', response.data);
      })
      .catch(errors => errors);
  },
  createNewFeed({ commit }, payload) {
    return axios
      .post(templateString(config.NEW_FEED_API), payload)
      .then(response => response)
      .catch(errors => errors.response);
  },
  getSearchKeywords({ commit }, keyword) {
    replacement = {
      KEYWORD: keyword,
    };
    return axios
      .get(templateString(config.LDS_V2_SEARCH, replacement))
      .then(response => response)
      .catch(errors => errors.response);
  },

  getKeyValuesList({ commit }, payload) {
    replacement = {
      FEED_NAME: payload.feedName,
    };
    return axios
      .get(templateString(config.FEED_KEY_VALUES, replacement), {
        params: {
          keyValue: payload.keys,
        },
      })
      .then(response => response)
      .catch(errors => errors.response);
  },
  getLicenseForFeed({ commit }, payload) {
    replacement = {
      FEED_NAME: payload.feedName,
      COMPANY_NAME: payload.company,
    };
    return axios.get(templateString(config.FEED_LICENSE, replacement))
      .then((response) => {
        commit('SET_PRIVATE_FEED_USERS', response.data);
        return response;
      }).catch(errors => errors.response);
  },
  setCompanyName({ commit }) {
    if (!companyName) {
      companyName = getUserCompany();
    }
  },
  async checkCoreFeed({ commit }, feedName) {
    replacement = {
      FEED_NAME: feedName,
    };
    return axios
      .get(templateString(config.CHECK_CORE_FEED_API, replacement))
      .then((response) => {
        if (response.status === 200) {
          commit('CORE_FEED', true);
        } else {
          commit('CORE_FEED', false);
        }
      })
      .catch((errors) => {
        commit('CORE_FEED', false);
      });
  },
  getCurves({ commit }, feedName) {
    replacement = {
      FEED_NAME: feedName,
    };
    return axios
      .get(templateString(config.CURVE_API, replacement))
      .then((response) => {
        commit('SET_ROOT_CONTRACTS', response.data);
        return response;
      })
      .catch(error => error.response);
  },
  getMetadataForKeys({ commit }, payload) {
    replacement = {
      FEED_NAME: payload.feedName,
    };
    return axios
      .get(templateString(config.MD_API, replacement), {
        params: {
          feedName: payload.feedName,
          keyValue: payload.keys,
        },
      })
      .then((response) => {
        commit('SET_METADATA', response.data);
        return response;
      })
      .catch(error => error.response);
  },
  getKeyData({ commit }, payload) {
    replacement = {
      FEED_NAME: payload.feedName,
      CODE_NAME: payload.codeName,
    };
    return axios
      .get(templateString(config.KEYS_API, replacement))
      .then(response => response)
      .catch(error => error.response);
  },

  getMetadataForRoot({ commit }, payload) {
    replacement = {
      FEED_NAME: payload.feedName,
      ROOT: encodeURIComponent(payload.keys),
    };
    return axios
      .get(templateString(config.MD_ROOT_API, replacement))
      .then((response) => {
        const metadata = [];
        metadata.push(response.data.properties);
        commit('SET_METADATA', metadata);
        return response;
      })
      .catch(error => error.response);
  },

  getFeedGroups({ commit, state }) {
    const COMPANY_NAME = getUserCompany();
    replacement = {
      COMPANY_NAME,
    };
    return axios
      .get(templateString(config.GET_FEED_GROUP_API, replacement))
      .then((response) => {
        commit('SET_FEED_GROUPS', response.data);
        commit('SET_FEED_GROUP_PAGE', 'SUCCESS');
        return response;
      })
      .catch((error) => {
        commit('SET_FEED_GROUP_PAGE', 'ERROR');
        commit('SET_GROUP_ERROR_MESSAGE', error);
        throw error;
      });
  },
  getGroupFeedsList({ commit }, groupName) {
    const COMPANY_NAME = getUserCompany();
    replacement = {
      COMPANY_NAME,
      GROUP_NAME: groupName,
    };
    return axios
      .get(templateString(config.GET_GROUP_FEEDS_API, replacement))
      .then((response) => {
        const groupFeeds = {
          groupName,
          feedList: response.data,
        };
        commit('SET_GROUP_FEEDS', groupFeeds);
        commit('SET_FEED_GROUP_PAGE', 'SUCCESS');
        return response;
      })
      .catch((error) => {
        commit('SET_FEED_GROUP_PAGE', 'ERROR');
        throw error;
      });
  },

  async getUserFeedsForFeedGroup({ commit, state }) {
    if (state.userFeeds.length === 0) {
      const USER_NAME = getUserName();
      replacement = {
        USER_NAME,
      };
      return axios
        .get(templateString(config.API_USER_FEEDS, replacement))
        .then((response) => {
          commit('USER_FEEDS', response.data);
          commit('SET_FEED_GROUP_PAGE', 'SUCCESS');
          return response;
        })
        .catch((errors) => {
          commit('SET_FEED_GROUP_PAGE', 'ERROR');
          return errors;
        });
    }
    return false;
  },

  saveGroupChanges({ commit }, params) {
    const COMPANY_NAME = getUserCompany();
    replacement = {
      COMPANY_NAME,
    };
    let reqGroupUsersData = '';
    if (params.action !== 'DELETE') {
      reqGroupUsersData = axios({
        method: params.action === 'ADD' ? 'post' : 'put',
        url: templateString(config.API_FEED_GROUPS, replacement),
        data: params.value,
      });
    } else {
      const replacement1 = {
        COMPANY_NAME,
        GROUP_NAME: params.value.name,
      };
      reqGroupUsersData = axios({
        method: 'delete',
        url: templateString(config.API_DELETE_FEED_GROUP, replacement1),
        data: params.value.name,
      });
    }
    return reqGroupUsersData
      .then((response) => {
        commit('SET_PAGE_STATUS', 'SUCCESS');
        return response;
      })
      .catch(error => error.response);
  },
  getUserDatasources({ commit }) {
    const USER_NAME = getUserName();
    replacement = {
      USER_NAME,
    };
    return axios
      .get(templateString(config.API_USER_DATASOURCES, replacement))
      .then((response) => {
        commit('ADD_DATASOURCES_FEEDS', response.data);
        commit('SET_PAGE_STATUS', 'SUCCESS');
        return response;
      })
      .catch((errors) => {
        commit('SET_PAGE_STATUS', 'ERROR');
        return errors;
      });
  },

  updateSettingValue({ commit }, payload) {
    const userName = getUserName();
    const { keyName } = payload;
    const { value } = payload;
    return axios
      .get(config.API_UPDATE_USER_SETTING, {
        params: {
          userName,
          keyName,
          value,
        },
      })
      .then(response => response)
      .catch((errors) => {
        commit('SET_PAGE_STATUS', 'ERROR');
        return errors;
      });
  },

  getFeedRoots({ commit }, feedName) {
    replacement = {
      FEED_NAME: feedName,
    };
    return new Promise((resolve, reject) => {
      axios
        .get(templateString(config.GET_FEED_ROOTS, replacement))
        .then((response) => {
          if (response.status === 200) {
            commit('ADD_FEED_ROOTS', response.data);
          }
          resolve();
        })
        .catch((errors) => {
          reject(errors);
        });
    });
  },

  getKeysForFeed({ commit }, feedName) {
    return fetchFeedKeysPaginated(feedName, 1, [])
      .then((response) => {
        const keys = {};
        response.forEach((kv) => {
          kv.keys.forEach((kvPair) => {
            if (Object.hasOwnProperty.call(keys, kvPair.key)) {
              keys[kvPair.key].push(kvPair.value);
            } else {
              keys[kvPair.key] = [kvPair.value];
            }
          });
        });
        commit('ADD_FEED_KEYS', { feedName, keys });
        return keys;
      })
      .catch(errors => errors);
  },
};

export default actions;
