<template>
  <div class="wf-edit-action">
    <mds-notification-container v-if="showError">
      <!-- Tinted -->
      <mds-notification
        key="error-tinted"
        variation="error"
        title="Error"
        tinted
        :dismiss-delay="3000"
        @mds-notification-dismissed="hideError"
      >
        {{ errorMessage }}
      </mds-notification>
    </mds-notification-container>
    <mds-loader
      v-if="showLoader"
      size="small"
      aria-label="Small Loader"
    />
    <mds-form
      v-else-if="multiFeed"
      :class="isEditable === 'false' || isEditable === false ? 'disabled' : ''"
    >
      <div style="margin-bottom: 1%;">
        <mds-row>
          <mds-col
            :cols="3"
            style="padding-top: 0.5%;"
          >
            <strong>Variable Name (case-sensitive): </strong>
          </mds-col>
          <mds-col :cols="4">
            <input
              v-model="variableValue"
              maxlength="25"
              placeholder="Variable Name"
              label="Variable Name (case-sensitive): "
              :class="isNewNode === 'false' || isNewNode === false ? 'disabled mds-form__input' : 'mds-form__input'"
              @keydown.tab.prevent="disableTab"
            >
          </mds-col>
        </mds-row>
      </div>
      <span v-if="variableValue !== ''"><strong>${{ variableValue }}</strong></span>
      <div style="margin-top: 1%;">
        <div><span>Multiple products are defined across different input sets.</span></div>
        <div style="margin-top: 1%;">
          <mds-button-container left-aligned>
            <mds-button
              v-if="isEditable"
              type="button"
              variation="secondary"
              @click="overwriteAllProducts()"
              @keydown.tab.prevent="disableTab"
            >
              Overwrite all products.
            </mds-button>
          </mds-button-container>
        </div>
        <mds-row>
          <mds-col
            :cols="1"
            style="padding-top: 3%;"
          >
            <span><strong>Runs: </strong></span>
          </mds-col>
          <mds-col
            style="padding-top: 3%;"
          >
            <mds-row>
              <mds-col>
                <mds-checkbox
                  checked
                  disabled
                  value="accept"
                >
                  When data arrives
                </mds-checkbox>
              </mds-col>
            </mds-row>
            <mds-row>
              <mds-col
                :cols="'1'"
                style="padding-top: 3%;"
              >
                <mds-checkbox
                  :checked="expireSet === true || expireSet === 'true'"
                  value="true"
                  @change="expireSet = !expireSet"
                  @keydown.tab.prevent="disableTab"
                  @keydown.space.prevent="disableTab"
                  @focus="disableTab"
                >
                  At
                </mds-checkbox>
              </mds-col>
              <mds-col
                style="padding-top: 1%;"
              >
                <mds-input
                  v-model="expireTime"
                  label=""
                  type="time"
                  :disabled="!expireSet"
                  @keydown.tab.prevent="disableTab"
                  @keydown.space.prevent="disableTab"
                />
                <span>({{ manager._priv.tz }})</span>
              </mds-col>
              <mds-col
                style="padding-top: 3%;"
              >
                <span> if data hasn't arrived</span>
              </mds-col>
            </mds-row>
          </mds-col>

          <mds-col :cols="3" />
        </mds-row>
        <div v-if="isEditable">
          <hr v-if="isEditable">
          <mds-button-container right-aligned>
            <mds-button
              v-if="isEditable"
              type="button"
              variation="primary"
              :disabled="validateSaveButton"
              @click="saveParameter(validateSaveButton)"
            >
              Save
            </mds-button>
          </mds-button-container>
        </div>
      </div>
    </mds-form>
    <mds-form
      v-else
      :class="isEditable === 'false' || isEditable === false ? 'disabled' : ''"
    >
      <div style="margin-bottom: 1%;">
        <mds-row style="margin-bottom: 1%;">
          <span style="margin-left: 1.25%;"><strong>Variable Name (case-sensitive): </strong></span>
        </mds-row>
        <mds-row>
          <mds-col :cols="6">
            <input
              v-model="variableValue"
              maxlength="25"
              placeholder="Variable Name"
              label="Variable Name (case-sensitive): "
              :class="isNewNode === 'false' || isNewNode === false ? 'mds-form__input' : 'mds-form__input'"
              @keydown.tab.prevent="disableTab"
            >
          </mds-col>
        </mds-row>
      </div>
      <!-- <span
        v-if="showNameError"
        id="bubbleName"
        class="mds-form__field-error"
        role="alert"
      >
        <span
          class="mds-form__field-error-text"
          style="margin-bottom: 10px;"
        >
          Please enter valid variable name. (check entered bubble name already declered or empty).
        </span>
      </span> -->
      <span v-if="variableValue !== ''"><strong>${{ variableValue }}</strong></span>
      <div>
        <mds-row>
          <mds-col
            :cols="1"
            style="padding-top: 1%;"
          >
            <strong>Source: </strong>
          </mds-col>
          <mds-col :cols="4">
            <mds-select
              v-model="selectedSource"
              label=""
              :options="dataSetForSources"
              @change="sourceChangeListener()"
              @keydown.tab.prevent="disableTab"
            />
          </mds-col>
          <mds-col />
        </mds-row>
        <mds-row>
          <mds-col
            :cols="1"
            style="padding-top: 1%;"
          >
            <span><strong>Feed: </strong></span>
          </mds-col>
          <mds-col :cols="4">
            <mds-select
              v-model="selectedFeed"
              label=""
              :options="dataSetForFeed"
              :error="inputFeedNotAvailable"
              :error-text="errorMessageForFeed"
              @change="feedChangeListener()"
              @keydown.tab.prevent="disableTab"
            />
          </mds-col>
          <mds-col />
        </mds-row>
        <mds-row>
          <mds-col
            :cols="1"
            style="padding-top: 1%;"
          >
            <span><strong>Columns: </strong></span>
          </mds-col>
          <mds-col :cols="4">
            <mds-combo-box
              v-model="selectedColumns"
              multiple
              label=""
              :data-set="dataSetForColumns"
              :disabled="disableColumns"
              :error="inputFeedNotAvailable"
              @input="columnChangeListener"
              @change="columnChangeListener"
              @update:modelValue="columnChangeListener"
              @keydown.tab.prevent="disableTab"
              @keydown.space.prevent="disableTab"
              @focus="disableTab"
            />
          </mds-col>
          <mds-col />
        </mds-row>
        <mds-row>
          <mds-col
            :cols="1"
            style="padding-top: 1%;"
          >
            <span><strong>Type: </strong></span>
          </mds-col>
          <mds-col
            :cols="4"
            style="padding-top: 1%;"
          >
            <div v-if="!enableType">
              <div v-if="currentInputIsForwardCurve">
                <mds-tag
                  size="medium"
                  style="margin-bottom: 10px;"
                >
                  Variable Type: Forward Curves
                </mds-tag>
                <div>
                  <mds-combo-box
                    v-model="selectedRoots"
                    multiple
                    :display-top="true"
                    :data-set="currentFeedRoots"
                    @keydown.tab.prevent="disableTab"
                    @keydown.space.prevent="disableTab"
                  />
                </div>
              </div>
              <div v-else>
                <mds-tag
                  size="medium"
                  style="margin-bottom: 10px;"
                >
                  Variable Type: Timeseries Data
                </mds-tag>
                <div>
                  <mds-input
                    v-for="(key, index) in Object.keys(selectedKeys)"
                    :key="index"
                    v-model="selectedKeys[key]"
                    :label="key"
                    :error="inputFeedNotAvailable"
                    @keydown.tab.prevent="disableTab"
                    @keydown.space.prevent="disableTab"
                  />
                </div>
              </div>
            </div>
            <div
              v-else
              style="margin-bottom: 1%;"
            >
              <mds-row>
                <mds-col>
                  <mds-radio-button
                    v-model="isForwardCurveChk"
                    name="selected-example"
                    :checked="isForwardCurveChk === true || isForwardCurveChk === 'true'"
                    value="true"
                    @keydown.tab.prevent="disableTab"
                    @keydown.space.prevent="disableTab"
                  >
                    Forward curve (futures contracts)
                  </mds-radio-button>
                </mds-col>
                <mds-col>
                  <mds-radio-button
                    v-model="isForwardCurveChk"
                    name="selected-example"
                    :checked="isForwardCurveChk === false || isForwardCurveChk === 'false'"
                    value="false"
                    @keydown.tab.prevent="disableTab"
                    @keydown.space.prevent="disableTab"
                  >
                    Time-series (history)
                  </mds-radio-button>
                </mds-col>
              </mds-row>
              <mds-row>
                <mds-col>
                  <div
                    v-if="enableSymbolroots && (isForwardCurveChk === true || isForwardCurveChk === 'true')"
                    style="margin-top: 1%;"
                  >
                    <mds-combo-box
                      v-model="selectedRoots"
                      multiple
                      :display-top="true"
                      :data-set="currentFeedRoots"
                      label="Root(s)"
                      @keydown.tab.prevent="disableTab"
                      @keydown.space.prevent="disableTab"
                    />
                  </div>
                  <div v-else>
                    <mds-input
                      v-for="(key, index) in Object.keys(selectedKeys)"
                      :key="index"
                      v-model="selectedKeys[key]"
                      :label="key"
                      @keydown.tab.prevent="disableTab"
                    />
                  </div>
                </mds-col>
              </mds-row>
            </div>
          </mds-col>
          <mds-col />
        </mds-row>
        <mds-row>
          <mds-col
            :cols="1"
            style="padding-top: 1%;"
          >
            <span><strong>Runs: </strong></span>
          </mds-col>
          <mds-col
            style="padding-top: 1%;"
          >
            <mds-row>
              <mds-col>
                <mds-checkbox
                  checked
                  disabled
                  value="accept"
                  @keydown.tab.prevent="disableTab"
                  @keydown.space.prevent="disableTab"
                >
                  When data arrives
                </mds-checkbox>
              </mds-col>
            </mds-row>
            <mds-row>
              <mds-col
                :cols="2"
                style="padding-top: 3%;"
              >
                <mds-checkbox
                  :checked="expireSet === true || expireSet === 'true'"
                  value="true"
                  @change="expireSet = !expireSet"
                  @keydown.tab.prevent="disableTab"
                  @keydown.space.prevent="disableTab"
                  @focus="disableTab"
                >
                  At
                </mds-checkbox>
              </mds-col>
              <mds-col
                style="padding-top: 1%;"
              >
                <mds-input
                  v-model="expireTime"
                  label=""
                  type="time"
                  :disabled="!expireSet"
                  @keydown.tab.prevent="disableTab"
                  @keydown.space.prevent="disableTab"
                />
                <span v-if="manager._priv">({{ manager._priv.tz }})</span>
              </mds-col>
              <mds-col
                style="padding-top: 3%;"
              >
                <span> if data hasn't arrived</span>
              </mds-col>
            </mds-row>
          </mds-col>
          <mds-col />
        </mds-row>
        <!-- Hiding this layout since TS Correction will be handled in real-time -->
        <mds-row v-if="false && !currentInputIsForwardCurve">
          <mds-col
            :cols="1"
            style="padding-top: 1%;"
          >
            <span><strong>Extras: </strong></span>
          </mds-col>
          <mds-col
            :cols="9"
            style="padding-top: 1%;"
          >
            <mds-row>
              <mds-col>
                <mds-checkbox
                  :checked="enableCorrection === true || enableCorrection === 'true'"
                  value="true"
                  @change="enableCorrection = !enableCorrection"
                  @keydown.tab.prevent="disableTab"
                  @keydown.space.prevent="disableTab"
                >
                  TimeSeries Correction
                </mds-checkbox>
              </mds-col>
            </mds-row>
          </mds-col>
          <mds-col />
        </mds-row>
        <mds-row>
          <mds-col :cols="1" />
          <mds-col :cols="10">
            <mds-row v-if="enableCorrection && !currentInputIsForwardCurve">
              <mds-col :cols="3">
                <div class="input-label-custom">
                  <span>LookBack</span><br>
                  <span><b>From Days</b></span>
                </div>
                <mds-input
                  v-model="correctionData.lookBackFromDays"
                  label=""
                  type="number"
                  @keydown.tab.prevent="disableTab"
                />
              </mds-col>
              <mds-col :cols="3">
                <div class="input-label-custom">
                  <span>LookBack</span><br>
                  <span><b>To Days</b></span>
                </div>
                <mds-input
                  v-model="correctionData.lookBackToDays"
                  label=""
                  type="number"
                  @keydown.tab.prevent="disableTab"
                />
              </mds-col>
              <mds-col :cols="3">
                <div class="input-label-custom">
                  <span>LookBack</span><br>
                  <span><b>From Insertion Days</b></span>
                </div>
                <mds-input
                  v-model="correctionData.lookBackFromInsertionDays"
                  label=""
                  type="number"
                  @keydown.tab.prevent="disableTab"
                />
              </mds-col>
              <mds-col :cols="3">
                <div class="input-label-custom">
                  <span /><br>
                  <span><b>TimeZone</b></span>
                </div>
                <mds-combo-box
                  v-model="correctionData.timeZone"
                  :display-top="true"
                  :data-set="timeZoneDataset"
                  label=""
                  @keydown.tab.prevent="disableTab"
                />
              </mds-col>
            </mds-row>
          </mds-col>
        </mds-row>
      </div>
      <div v-if="isEditable">
        <hr v-if="isEditable">
        <mds-button-container right-aligned>
          <mds-button
            v-if="isEditable"
            type="button"
            variation="primary"
            :disabled="validateSaveButton"
            @click="saveParameter(validateSaveButton)"
          >
            Save
          </mds-button>
        </mds-button-container>
      </div>
    </mds-form>
  </div>
</template>

<script>
import MdsForm from '@mds/form';
import MdsInput from '@mds/input';
import { mapGetters, mapActions } from 'vuex';
import { MdsButton, MdsButtonContainer } from '@mds/button';
import MdsComboBox from '@mds/combo-box';
import MdsSelect from '@mds/select';
import MdsRadioButton from '@mds/radio-button';
import { MdsTag } from '@mds/tag';
import { MdsRow, MdsCol } from '@mds/layout-grid';
import MdsCheckbox from '@mds/checkbox';
import { MdsNotification, MdsNotificationContainer } from '@mds/notification';
import _ from 'lodash';
import CryptoJS from 'crypto-js';
import timeZone from '../../../constants/timezone';
import { _editDatasetCB, _buildPsg } from '../../../scripts/utils/Manager';
import EventBus from '../../../../../main';

const defaultOption = {
  text: '*** Choose one ***',
  value: 'default',
};

export default {
  name: 'WorkflowDataEditActions',
  components: {
    MdsForm,
    MdsButton,
    MdsButtonContainer,
    MdsInput,
    MdsComboBox,
    MdsSelect,
    MdsTag,
    MdsRow,
    MdsCol,
    MdsCheckbox,
    MdsRadioButton,
    MdsNotification,
    MdsNotificationContainer,
  },
  props: {
    node: {
      type: Object,
      default: null,
    },
    workflowData: {
      type: Object,
      default: null,
    },
    nodeVarName: {
      type: String,
      default: null,
    },
    formulas: {
      type: Array,
      default: null,
    },
    parameterSetData: {
      type: Object,
      default: null,
    },
    workflow: {
      type: Object,
      required: false,
      default: null,
    },
    targetData: {
      type: Object,
      required: false,
      default: null,
    },
    isNewNode: {
      type: Boolean,
      required: false,
      default: false,
    },
    nodeVarNameEdit: {
      type: String,
      default: null,
    },
    isEditable: {
      type: Boolean,
      required: false,
      default: false,
    },
    badFormulaBubbles: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      showLoader: false,
      headers: [
        {
          text: 'Name',
          fieldName: 'name',
          style: {
            width: '150px',
          },
        },
        {
          text: 'Description',
          fieldName: 'description',
          style: {
            width: '150px',
          },
        },
      ],
      paramHeaders: [],
      inputData: [],
      toggle: false,
      currentInputToEdit: {
        name: '',
        body: {
          cols: '',
          key: '',
          prov: '',
          source: '',
          feed: '',
          expireSet: false,
          expireTime: '',
        },
      },
      allUserDataSources: {},
      userFeeds: [],
      dataSetForSources: [],
      selectedSource: [],
      dataSetForFeed: [],
      selectedFeed: [],
      currentFeedDetails: {},
      dataSetForColumns: [],
      disableColumns: false,
      selectedColumns: [],
      currentFeedRoots: [],
      currentInputIsForwardCurve: false,
      selectedKeys: {},
      selectedRoots: [],
      currentInputIsVariableType: false,
      variableDataType: '',
      variableValue: '',
      minMaxDates: {
        min: new Date(1970, 0, 1),
        max: new Date(2050, 11, 31),
      },
      disableCopyButton: true,
      multiFeed: false,
      expireTime: '',
      expireSet: false,
      modifiedParameters: [],
      enableType: false,
      enableSymbolroots: false,
      isForwardCurveChk: true,
      paramSet: {},
      colTypes: {},
      enableCorrection: false,
      correctionData: {
        lookBackFromDays: 0,
        lookBackToDays: 0,
        lookBackFromInsertionDays: 0,
        timeZone: null,
      },
      timeZoneDataset: timeZone.map(tz => ({ text: tz, value: tz })),
      showRunError: false,
      showKeyError: false,
      showColsError: false,
      showFeedError: false,
      showSourceError: false,
      showNameError: false,
      manager: {},
      currentBubble: {},
      prevVariableValue: '',
      errorMessage: '',
      showError: false,
      currentFeedKeys: [],

      // Parameter model status
      updateParameterSetData: {
        add: [],
        update: [],
        delete: { psgIds: [], paramIds: [] },
        psgToRename: [],
        currentPsgVersion: '1',
      },
      updatedVariableMap: {},
      addVariableMap: {},
      inputFeedNotAvailable: false,
      errorMessageForFeed: [],
      prevExpireSet: false,
      prevExpireTime: '',
    };
  },
  computed: {
    ...mapGetters('workflowModule', [
      'getParamStatus',
      'getWorkflowFormulas',
      'getEditableWorkflowDetails',
      'getEditableWorkflowUI',
      'getEditableParamSet',
      'getEditableFormulae',
      'getEditableNewFormulae',
      'getEditableNodes',
      'getEditableLinks',
      'getEditableTargets',
      'getCreateNewWorkflowDetails',
      'getCreateNewWorkflowUI',
      'getCreateNewParamSet',
      'getCreateNewFormulae',
      'getallWorkflowsForName',
      'getCurrentWorkflowManager',
    ]),
    ...mapGetters('feedModule', ['getFeedList', 'getFeedDetails', 'getAllFeedRoots', 'getErrorMessage']),
    selectionStatus() {
      // filter your rows by the checked property
      const filtered = this.inputData.filter(row => row.checked === true);

      // isIndeterminate = there are more than one rows checked but not all
      // isAllSelected = all rows are checked
      return {
        isIndeterminate: filtered.length > 0 && filtered.length < this.inputData.length,
        isAllSelected: filtered.length === this.inputData.length,
      };
    },
    areKeysFilled() {
      return !Object.keys(this.selectedKeys).some(k => this.selectedKeys[k].trim().length === 0);
    },
    validateSaveButton() {
      return !!this.inputFeedNotAvailable;
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    ...mapActions('feedModule', ['getUserDatasources', 'getUserFeeds', 'getFeedDetailsByName', 'getFeedRoots']),
    ...mapActions('workflowModule', ['setCurrentWorkflowManager']),
    async init() {
      this.showLoader = true;
      this.variableValue = this.nodeVarNameEdit.replace(/[^a-zA-Z0-9_]/g, '');
      this.paramSet = this.getEditableWorkflowDetails.paramSet;

      this.manager = this.getCurrentWorkflowManager;
      if (this.manager._priv) {
        this.updateParameterSetData = this.manager._priv.updateParameterSetData;
        if (this.updateParameterSetData.update.length > 0) {
          const updatedVariableMapObj = {};
          for (let i = 0; i < this.updateParameterSetData.update.length; i++) {
            const element = this.updateParameterSetData.update[i];
            const varName = element.propKey.replace('udef.ds.', '').split('.')[0];
            const varKey = element.propKey.replace('udef.ds.', '').split('.')[1];
            if (updatedVariableMapObj[`${varName}`]) {
              updatedVariableMapObj[`${varName}`] = updatedVariableMapObj[`${varName}`];
            } else {
              updatedVariableMapObj[`${varName}`] = [];
            }
            updatedVariableMapObj[`${varName}`].push(i);
          }
          this.updatedVariableMap = updatedVariableMapObj;
        }

        if (this.updateParameterSetData.add.length > 0) {
          const addVariableMapObj = {};
          for (let i = 0; i < this.updateParameterSetData.add.length; i++) {
            const element = this.updateParameterSetData.add[i];
            let varName = '';
            for (let j = 0; j < element.parameterModels.length; j++) {
              const e = element.parameterModels[j];
              // eslint-disable-next-line prefer-destructuring
              varName = e.propKey.replace('udef.ds.', '').split('.')[0];
              const varKey = e.propKey.replace('udef.ds.', '').split('.')[1];
              if (addVariableMapObj[`${varName}`]) {
                addVariableMapObj[`${varName}`] = addVariableMapObj[`${varName}`];
              } else {
                addVariableMapObj[`${varName}`] = [];
              }
            }
            if (varName) {
              addVariableMapObj[`${varName}`].push(i);
            }
          }
          this.addVariableMap = addVariableMapObj;
        }
      }
      this.inputData = JSON.parse(JSON.stringify(this.manager.inputData));
      this.inputData.forEach((row) => {
        row.visible = true;
      });
      this.paramHeaders = this.manager.paramHeaders;
      this.colTypes = this.manager.colTypes;
      this.rawPsgModel = this.manager.rawPsgModel;
      let updateBody = this.manager._priv.updateParameterSetData;
      if (!updateBody) {
        updateBody = {
          add: [], update: [], delete: { psgIds: [], paramIds: [] }, psgToRename: [],
        };
        this.manager._priv.updateParameterSetData = updateBody;
      } else {
        this.psgToAdd = updateBody.add;
        this.modifiedParameters = updateBody.update;
        this.deletedParameters = updateBody.delete;
        this.renamedRows = updateBody.psgToRename;
      }

      let lastFeed = '';
      let lastSource = '';
      this.userFeeds = this.getFeedList;
      let lastCols = [];
      let lastKeys = [];
      if (!this.isNewNode) {
        this.prevVariableValue = this.variableValue;
        if (this.inputData.length > 1) {
          const hashes = {};
          this.inputData.forEach((row) => {
            if (row[this.variableValue]) {
              const temp = row[this.variableValue];
              delete temp.data;
              hashes[CryptoJS.MD5(JSON.stringify(temp)).toString()] = temp;
              if (Object.keys(hashes).length > 1) {
                this.multiFeed = true;
              }
            }
          });
          if (!this.multiFeed) {
            lastFeed = this.inputData[0][this.variableValue].feed ? this.inputData[0][this.variableValue].feed.trim() : '';
            lastSource = this.inputData[0][this.variableValue].source
              ? this.inputData[0][this.variableValue].source.trim() : '';
            lastCols = this.inputData[0][this.variableValue].cols;
            lastKeys = this.inputData[0][this.variableValue].key ? this.inputData[0][this.variableValue].key : [];
          }
        } else {
          lastFeed = this.inputData[0][this.variableValue].feed ? this.inputData[0][this.variableValue].feed.trim() : '';
          lastSource = this.inputData[0][this.variableValue].source ? this.inputData[0][this.variableValue].source.trim() : '';
          lastCols = this.inputData[0][this.variableValue].cols;
          lastKeys = this.inputData[0][this.variableValue].key ? this.inputData[0][this.variableValue].key : [];
        }
        this.currentInputIsVariableType = false;
        if (this.inputData[0][this.variableValue].isForwardCurve) {
          this.selectedRoots = this.inputData[0][this.variableValue].key;
          this.isForwardCurveChk = 'true';
        } else {
          this.selectedKeys = this.inputData[0][this.variableValue].key;
          this.isForwardCurveChk = 'false';
        }
      }

      this.expireSet = this.manager._priv.bubbles[this.node.id]._data.expire_set || false;
      this.expireTime = this.manager._priv.bubbles[this.node.id]._data.expire_time || '12:00';
      this.prevExpireSet = this.expireSet;
      this.prevExpireTime = this.expireTime;

      if (this.userFeeds.length === 0) {
        try {
          this.getUserFeeds().then(async () => {
            await this.extractDataSourcesFromFeeds(lastFeed, lastCols);
          }).finally(() => {
            if (this.allUserDataSources[lastSource].feeds) {
              if (this.inputFeedNotAvailable) {
                this.dataSetForSources.unshift(defaultOption);
                this.dataSetForFeed.push({
                  text: lastFeed,
                  value: lastFeed,
                });
                this.selectedSource = 'default';
              } else {
                this.selectedSource[0] = lastSource;
                this.dataSetForFeed = this.allUserDataSources[lastSource].feeds.map((f) => {
                  f.text = f.name;
                  f.value = f.name;
                  return f;
                });
              }
              this.dataSetForFeed.unshift(defaultOption);
            }
            this.$nextTick(() => {
              this.selectedFeed = [lastFeed];
            });
          });
        } catch {
          this.showLoader = false;
        }
      } else {
        await this.extractDataSourcesFromFeeds(lastFeed, lastCols);
        this.selectedSource[0] = lastSource;
        if (this.inputFeedNotAvailable) {
          this.dataSetForSources.unshift(defaultOption);
          this.dataSetForFeed.push({
            text: lastFeed,
            value: lastFeed,
          });
          this.selectedSource = 'default';
        } else {
          this.selectedSource[0] = lastSource;

          if (this.allUserDataSources[lastSource]) {
            this.dataSetForFeed = this.allUserDataSources[lastSource].feeds.map((f) => {
              f.text = f.name;
              f.value = f.name;
              return f;
            });
          }
        }
        this.$nextTick(() => {
          this.selectedFeed = [lastFeed];
        });
      }
    },
    arraysEqual(a, b) {
      if (a === b) return true;
      if (a == null || b == null) return false;
      if (a.length !== b.length) return false;

      // If you don't care about the order of the elements inside
      // the array, you should sort both arrays here.
      // Please note that calling sort on an array will modify that array.
      // you might want to clone your array first.

      for (let i = 0; i < a.length; ++i) {
        if (a[i] !== b[i]) return false;
      }
      return true;
    },
    determineVariableType(value) {
      let varType = null;
      let varValue = null;
      try {
        varType = typeof JSON.parse(value);
        varValue = JSON.stringify(JSON.parse(value));
      } catch {
        if (/\d{4}-\d{2}-\d{2}/gm.test(value)) {
          varValue = new Date(value);
          // eslint-disable-next-line eqeqeq
          if (varValue == 'Invalid Date') {
            varType = 'string';
            varValue = value;
          } else {
            varType = 'date';
          }
        } else {
          varType = 'string';
        }
      }
      return { type: varType, value: varValue };
    },
    sourceChangeListener() {
      if (this.selectedSource.length === 0) {
        return;
      }
      this.inputFeedNotAvailable = false;
      // change feed
      this.dataSetForFeed = this.allUserDataSources[this.selectedSource].feeds.map((f) => {
        f.text = f.name;
        f.value = f.name;
        return f;
      });
      this.dataSetForFeed.unshift(defaultOption);
      this.selectedFeed = [defaultOption.text];
      this.feedChangeListener();
    },
    feedChangeListener() {
      // clear columns
      this.$set(this, 'selectedColumns', []);
      this.dataSetForColumns = [];
      this.disableColumns = true;
      // clear keys
      this.selectedKeys = {};
      // clear roots
      this.selectedRoots = [];
      // fetch feed details
      if (this.selectedFeed[0] !== defaultOption.text) {
        this.fetchFeedDetails(this.selectedFeed, []);
      }
    },
    sortDataSources(a, b) {
      const fa = a.text.toLowerCase();
      const fb = b.text.toLowerCase();

      if (fa < fb) {
        return -1;
      }
      if (fa > fb) {
        return 1;
      }
      return 0;
    },
    async extractDataSourcesFromFeeds(feedname, cols) {
      const datasourceSet = {};
      this.getFeedList.forEach((feed) => {
        if (Object.hasOwnProperty.call(datasourceSet, feed.dataSource)) {
          datasourceSet[feed.dataSource]['feeds'].push(feed);
        } else {
          datasourceSet[feed.dataSource] = {
            text: feed.dataSource,
            value: feed.dataSource,
            feeds: [feed],
          };
        }
      });
      this.allUserDataSources = datasourceSet;
      this.dataSetForSources = Object.values(datasourceSet).sort(this.sortDataSources);
      await this.fetchFeedDetails(feedname, cols);
    },
    columnChangeListener(event) {
      if (this.selectedFeed[0] !== defaultOption.text) {
        this.fetchFeedDetails(this.selectedFeed, this.selectedColumns);
      }
    },
    async fetchFeedDetails(feedname, cols) {
      if (this.currentFeedDetails || !this.getFeedDetails || this.getFeedDetails.name !== feedname) {
        try {
          await this.getFeedDetailsByName(feedname);
          this.currentFeedDetails = this.getFeedDetails;
        } catch (error) {
          if (error.response.status === 404 || error.response.status === 403) {
            this.inputFeedNotAvailable = true;
            this.errorMessageForFeed[0] = this.getErrorMessage.replace(feedname, 'feed');

            cols.forEach((col) => {
              this.dataSetForColumns.push({
                text: col,
                value: col,
              });
            });
            this.$set(this, 'selectedColumns', cols);
          }
          this.showLoader = false;
        }
        this.selectedProvider = this.currentFeedDetails.provider;
        this.extractFeedColumns(cols);
      } else {
        this.extractFeedColumns(cols);
      }

      this.currentFeedKeys = [];
      if (this.currentFeedDetails.fields) {
        for (let i = 0; i < this.currentFeedDetails.fields.length; i++) {
          const element = this.currentFeedDetails.fields[i];
          if (element.type.toLowerCase() === 'k') {
            this.currentFeedKeys.push(element.fieldName);
          }
        }
      }

      if (Object.hasOwnProperty.call(this.getAllFeedRoots, feedname)) {
        this.currentFeedRoots = this.getAllFeedRoots[feedname].map(root => ({ text: root['root'], value: root['root'] }));

        if (this.currentFeedRoots.length > 0) {
          this.enableType = true;
          this.enableSymbolroots = true;
        } else {
          this.enableType = false;
          this.enableSymbolroots = false;
        }
        this.showLoader = false;
      } else {
        this.getFeedRoots(feedname).then(() => {
          this.currentFeedRoots = this.getAllFeedRoots[feedname].map(root => ({ text: root['root'], value: root['root'] }));

          if (this.currentFeedRoots.length > 0) {
            this.enableType = true;
            this.enableSymbolroots = true;
          } else {
            this.enableType = false;
            this.enableSymbolroots = false;
          }
          this.showLoader = false;
        }).catch(() => {
          this.showLoader = false;
        });
      }
    },
    extractFeedColumns(cols) {
      if (this.currentFeedDetails.fields) {
        this.dataSetForColumns = this.currentFeedDetails.fields
          .filter(field => field.type.localeCompare('k', 'en', { sensitivity: 'base' }))
          .map((field) => {
            field.text = field.fieldName;
            field.value = field.fieldName;
            return field;
          });
      }

      if (this.selectedKeys && Object.keys(this.selectedKeys).length === 0) {
        this.showLoader = true;
        if (this.currentFeedDetails.fields) {
          this.currentFeedDetails.fields
            .filter(field => !field.type.localeCompare('k', 'en', { sensitivity: 'base' }))
            .forEach((element) => {
              this.$set(this.selectedKeys, element.fieldName, '');
            });
        }
      }

      this.$set(this, 'selectedColumns', cols);
      this.disableColumns = false;
      this.showLoader = false;
    },
    overwriteAllProducts() {
      // toggling the view
      this.multiFeed = !this.multiFeed;

      // clearing the variables
      this.selectedSource = [];
      this.selectedFeed = [];
      this.dataSetForFeed = [];
      this.$set(this, 'selectedColumns', []);
      this.dataSetForColumns = [];
      this.currentInputIsForwardCurve = null;
      this.selectedKeys = [];
      this.selectedRoots = [];
    },
    updateCurrentInput() {
      this.manager = this.getCurrentWorkflowManager;
      const re = '^[a-zA-Z][a-zA-Z_0-9]*$';
      if (this.variableValue === '' || !(new RegExp(re).test(this.variableValue))) {
        this.showError = true;
        this.errorMessage = 'varName has characters not acceptable in variable names.';
        return true;
      }
      this.currentInputToEdit.name = this.variableValue;
      this.currentInputToEdit.prevName = this.prevVariableValue;
      this.currentInputToEdit.body.source = Array.isArray(this.selectedSource) ? this.selectedSource[0] : this.selectedSource;
      this.currentInputToEdit.body.feed = Array.isArray(this.selectedFeed) ? this.selectedFeed[0] : this.selectedFeed;
      this.currentInputToEdit.body.prov = Array.isArray(this.selectedProvider) ? this.selectedProvider[0] : this.selectedProvider;
      this.currentInputToEdit.body.cols = this.selectedColumns;

      if (!this.enableType && !this.currentInputIsForwardCurve) {
        if (!this.areKeysFilled) {
          this.errorMessage = 'Product selection is incomplete, please fill all values.';
          this.showError = true;
          return true;
        }
        this.currentInputToEdit.body.key = this.selectedKeys;
      } else if (!this.enableType && this.currentInputIsForwardCurve) {
        this.currentInputToEdit.body.key = this.selectedRoots ? this.selectedRoots : [];
      } else if (this.enableType && (this.isForwardCurveChk === 'true')) {
        this.currentInputToEdit.body.key = this.selectedRoots ? this.selectedRoots : [];
      } else {
        if (!this.areKeysFilled) {
          this.errorMessage = 'Product selection is incomplete, please fill all values.';
          this.showError = true;
          return true;
        }
        this.currentInputToEdit.body.key = this.selectedKeys;
      }

      this.currentInputToEdit.body.expireSet = this.expireSet;
      // eslint-disable-next-line vue/no-mutating-props
      this.node.data.expire_set = this.expireSet;
      // eslint-disable-next-line vue/no-mutating-props
      this.node.data.expire_time = this.expireTime;
      this.manager._priv.bubbles[this.node.id].data('expire_set', this.expireSet);
      this.manager._priv.bubbles[this.node.id].data('expire_time', this.expireTime);
      this.setCurrentWorkflowManager(this.manager);
      this.currentInputToEdit.body.expireTime = this.expireTime;

      // if (this.enableCorrection) {
      //   // eslint-disable-next-line vue/no-mutating-props
      //   this.node.data.correctionData = this.correctionData;
      // }
      // // eslint-disable-next-line vue/no-mutating-props
      // this.node.data.enableCorrection = this.enableCorrection;
      // eslint-disable-next-line vue/no-mutating-props
      this.node.data.currentInputIsForwardCurve = this.currentInputIsForwardCurve || false;

      // -----------------------------------------------
      let flag = 0;
      if (!this.isNewNode) {
        if (this.prevVariableValue !== this.variableValue) {
          flag = 1;
        }
      }
      const dataObj = {
        mgr: this.manager,
        ui: this.getEditableWorkflowUI.ui,
        varName: this.variableValue.trim(),
        isVarNameEdited: flag,
        bubble: this.manager._priv.bubbles[this.node.id],
        dataset: this.manager._priv.datasets,
        displayed: {},
        firstRow: 0,
        lastRow: Math.max(0, this.manager._priv.psgBuffer.length - 1),
        feed: {
          name: this.currentInputToEdit.body.feed ? this.currentInputToEdit.body.feed : '',
          provider: this.currentFeedDetails.provider ? this.currentFeedDetails.provider : '',
          dataSource: this.currentInputToEdit.body.source ? this.currentInputToEdit.body.source : '',
          keys: this.currentFeedKeys,
        },
        type: (this.isForwardCurveChk === 'true') ? 'Type.ROOTS' : 'Type.KEYS',
        cols: this.currentInputToEdit.body.cols,
        keyOrRoots: this.currentInputToEdit.body.key,
        isKeySet: !this.isForwardCurveChk,
        expireSet: this.expireSet,
        expireTimeUi: this.expireTime === '00:00' ? '' : this.expireTime,
        // isEnableCorrection: this.enableCorrection,
        // correctionDataUI: this.correctionData,
        isCurrentInputIsForwardCurve: this.currentInputIsForwardCurve || false,
        operation: 'edit',
      };

      this.currentBubble = this.manager._priv.bubbles[this.node.id];
      try {
        if (!_editDatasetCB(dataObj, this)) {
          return true;
        }
      } catch (err) {
        console.error(err);
      }

      return false;
    },
    braodcastFormulaErrorMsg() {
      this.$emit('gotFormulaError', this.badFormulaBubbles, this.errorMessage);
    },
    hideError() {
      this.showError = false;
      this.errorMessage = '';
    },
    saveParameter(isDisabled) {
      if (isDisabled) {
        return;
      }
      if (this.multiFeed) {
        this.manager._priv.bubbles[this.node.id]._data.expire_set = this.expireSet;
        this.manager._priv.bubbles[this.node.id]._data.expire_time = this.expireTime;

        if (this.prevExpireSet !== this.expireSet || this.prevExpireTime !== this.expireTime) {
          EventBus.$emit('diagramChanged');
        }
        this.setCurrentWorkflowManager(this.manager);
        this.$eventBus.$emit('close-edit-action');
        return;
      }
      if (this.updateCurrentInput()) {
        return;
      }
      // Existing data bubble values Edited
      // 1. Variable Name changed
      // 2. feilds value changed
      if (!this.isNewNode) {
        const varsToAdd = {};
        this.updateParameterSetData.add.forEach((psg) => {
          if (psg && Object.hasOwn(psg, 'parameterModels')) {
            psg.parameterModels.forEach((p) => {
              if (p.propKey.startsWith(`udef.ds.${this.currentInputToEdit.prevName}.`)) {
                varsToAdd[p.propKey] = p;
              }
            });
          }
        });

        const varsToUpdate = {};
        this.updateParameterSetData.update.forEach((p) => {
          if (p.propKey.startsWith(`udef.ds.${this.currentInputToEdit.prevName}.`)) {
            varsToUpdate[p.propKey] = p;
          }
        });

        // update if already present in updateParameterSetData.add
        this.updateParameterSetData.add.forEach((psg) => {
          if (psg && Object.hasOwn(psg, 'parameterModels')) {
            psg.parameterModels.forEach((p) => {
              if (p.propKey.startsWith(`udef.ds.${this.currentInputToEdit.prevName}.`)) {
                const propType = p.propKey.split('.')[3];
                p.propKey = p.propKey.replace(this.currentInputToEdit.prevName, this.currentInputToEdit.name);
                if (propType === 'cols' || propType === 'key') {
                  p.propValue = JSON.stringify(this.currentInputToEdit.body[propType]);
                } else {
                  p.propValue = this.currentInputToEdit.body[propType];
                }
              }
            });
          }
        });

        // update if already present updateParameterSetData.update
        if (Object.keys(varsToUpdate).length > 0) {
          this.updateParameterSetData.update.forEach((p) => {
            if (p.propKey.startsWith(`udef.ds.${this.currentInputToEdit.prevName}.`)) {
              const propType = p.propKey.split('.')[3];
              p.propKey = p.propKey.replace(this.currentInputToEdit.prevName, this.currentInputToEdit.name);
              if (propType === 'cols' || propType === 'key') {
                p.propValue = JSON.stringify(this.currentInputToEdit.body[propType]);
              } else {
                p.propValue = this.currentInputToEdit.body[propType];
              }
            }
          });
        } else {
          // if not present then add in updateParameterSetData.update if param has id
          this.parameterSetData.parameterSetModels.forEach((psg) => {
            if (psg && Object.hasOwn(psg, 'parameterModels')) {
              psg.parameterModels.forEach((p) => {
                if (p.propKey.startsWith(`udef.ds.${this.currentInputToEdit.prevName}.`)) {
                  const propType = p.propKey.split('.')[3];
                  p.propKey = p.propKey.replace(this.currentInputToEdit.prevName, this.currentInputToEdit.name);
                  if (propType === 'cols' || propType === 'key') {
                    p.propValue = JSON.stringify(this.currentInputToEdit.body[propType]);
                  } else {
                    p.propValue = this.currentInputToEdit.body[propType];
                  }
                  if (Object.hasOwn(p, 'id')) {
                    this.updateParameterSetData.update.push(p);
                  }
                }
              });
            }
          });
        }

        // update this.parameterSetData.parameterSetModels and inputData rows
        this.parameterSetData.parameterSetModels.forEach((psg) => {
          if (psg && Object.hasOwn(psg, 'parameterModels')) {
            psg.parameterModels.forEach((p) => {
              if (p.propKey.startsWith(`udef.ds.${this.currentInputToEdit.prevName}.`)) {
                const propType = p.propKey.split('.')[3];
                p.propKey = p.propKey.replace(this.currentInputToEdit.prevName, this.currentInputToEdit.name);
                if (propType === 'cols' || propType === 'key') {
                  p.propValue = JSON.stringify(this.currentInputToEdit.body[propType]);
                } else {
                  p.propValue = this.currentInputToEdit.body[propType];
                }
              }
            });
          }

          const index = this.inputData.findIndex(row => row.name === psg.name);
          if (index >= 0 && Object.hasOwn(this.inputData[index], '_currentServerModel')) {
            this.inputData[index]._currentServerModel.parameterModels.forEach((p) => {
              if (p.propKey.startsWith(`udef.ds.${this.currentInputToEdit.prevName}.`)) {
                const propType = p.propKey.split('.')[3];
                p.propKey = p.propKey.replace(this.currentInputToEdit.prevName, this.currentInputToEdit.name);
                if (propType === 'cols' || propType === 'key') {
                  p.propValue = JSON.stringify(this.currentInputToEdit.body[propType]);
                } else {
                  p.propValue = this.currentInputToEdit.body[propType];
                }
              }
            });
            this.inputData[index]._isModified = true;
          }
        });

        this.$emit('dataBubbleEdited', this.parameterSetData, this.currentInputToEdit, this.node);
      } else {
        const currentupdateParameterSetData = {
          add: this.manager._priv.updateParameterSetData.add,
        };
        for (let i = 0; i < this.parameterSetData.parameterSetModels.length; i++) {
          let currentupdateParameterSetDataObj = {};
          if (Object.hasOwn(this.parameterSetData.parameterSetModels[i], 'id')) {
            currentupdateParameterSetDataObj = {
              id: this.parameterSetData.parameterSetModels[i].id,
              name: this.parameterSetData.parameterSetModels[i].name,
              description: this.parameterSetData.parameterSetModels[i].description,
              uuid: this.parameterSetData.parameterSetModels[i].uuid,
              parameterModels: [],
            };
          } else {
            currentupdateParameterSetDataObj = {
              name: this.parameterSetData.parameterSetModels[i].name,
              description: this.parameterSetData.parameterSetModels[i].description,
              parameterModels: [],
            };
          }

          const prop = { propKey: '', propValue: '' };

          prop.propKey = `udef.ds.${this.currentInputToEdit.name}.cols`;
          prop.propValue = JSON.stringify(this.currentInputToEdit.body.cols);
          currentupdateParameterSetDataObj.parameterModels.push(JSON.parse(JSON.stringify(prop)));

          prop.propKey = `udef.ds.${this.currentInputToEdit.name}.feed`;
          prop.propValue = this.currentInputToEdit.body.feed;
          currentupdateParameterSetDataObj.parameterModels.push(JSON.parse(JSON.stringify(prop)));

          prop.propKey = `udef.ds.${this.currentInputToEdit.name}.key`;
          prop.propValue = JSON.stringify(this.currentInputToEdit.body.key);
          currentupdateParameterSetDataObj.parameterModels.push(JSON.parse(JSON.stringify(prop)));

          prop.propKey = `udef.ds.${this.currentInputToEdit.name}.prov`;
          prop.propValue = this.currentInputToEdit.body.prov;
          currentupdateParameterSetDataObj.parameterModels.push(JSON.parse(JSON.stringify(prop)));

          prop.propKey = `udef.ds.${this.currentInputToEdit.name}.source`;
          prop.propValue = this.currentInputToEdit.body.source;
          currentupdateParameterSetDataObj.parameterModels.push(JSON.parse(JSON.stringify(prop)));

          // eslint-disable-next-line vue/no-mutating-props
          this.parameterSetData.parameterSetModels[i].parameterModels.push(...currentupdateParameterSetDataObj.parameterModels);

          if (Object.hasOwn(currentupdateParameterSetDataObj, 'id')) {
            currentupdateParameterSetData.add.push(currentupdateParameterSetDataObj);
          } else {
            const index = currentupdateParameterSetData.add.findIndex(p => p.name === currentupdateParameterSetDataObj.name);
            if (index >= 0) {
              currentupdateParameterSetData.add[index].parameterModels = _.unionBy(currentupdateParameterSetDataObj
                .parameterModels, currentupdateParameterSetData.add[index].parameterModels, 'propKey');
            } else {
              currentupdateParameterSetData.add.push(currentupdateParameterSetDataObj);
            }
          }
        }
        this.updateParameterSetData.add = currentupdateParameterSetData.add;
        this.$emit('newDataBubbleAdded', this.parameterSetData, this.currentInputToEdit, this.node);
      }
    },
    isEditedAgain() {
      if (this.updateParameterSetData.add.length > 0) {
        const arr = [];
        for (let i = 0; i < this.updateParameterSetData.add.length; i++) {
          for (let j = 0; j < this.updateParameterSetData.add[i].parameterModels.length; j++) {
            const element = this.updateParameterSetData.add[i].parameterModels[j];
            if ((this.currentInputToEdit.name === this.currentInputToEdit.prevName)
              && element.propKey.startsWith(`udef.ds.${this.currentInputToEdit.name}`)) {
              return 0;
            }
            if (element.propKey.startsWith(`udef.ds.${this.currentInputToEdit.prevName}`)) {
              arr.push(i);
              break;
            }
          }
        }
        this.updateParameterSetData.add = this.updateParameterSetData.add.filter((_, index) => !arr.includes(index));
        return 1;
      }
      return -1;
    },
    disableTab(event) {
      // Prevent the default behavior for the tab key
      if (event.key === 'Tab') {
        event.preventDefault();
      }
    },
  },
};
</script>

<style>
.wf-edit-action {
  height: 96% !important;
  padding: 1%;
  overflow-y: auto;
  overflow-x: hidden;
}
.wf-edit-action form {
  height: 100% !important;
}
#fullscreen .disabled {
  pointer-events: none;
  cursor: no-drop;
  opacity: 0.5;
  display: block !important;
}
#fullscreen .input-label-custom {
  font-size: 17px;
}
</style>
