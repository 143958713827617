<template>
  <div class="wf-edit-action">
    <mds-notification-container v-if="showError">
      <!-- Tinted -->
      <mds-notification
        key="error-tinted"
        variation="error"
        title="Error"
        tinted
        :dismiss-delay="3000"
        @mds-notification-dismissed="hideError"
      >
        {{ errorMessage }}
      </mds-notification>
    </mds-notification-container>
    <mds-loader
      v-if="showLoader"
      size="small"
      aria-label="Small Loader"
    />
    <mds-form
      v-else-if="multiFeed"
      :class="isEditable === 'false' || isEditable === false ? 'disabled' : ''"
    >
      <div style="margin-bottom: 1%;">
        <mds-row>
          <mds-col
            :cols="3"
            style="padding-top: 1%;"
          >
            <strong>What would you like to publish? </strong>
          </mds-col>
          <mds-col :cols="4">
            <mds-select
              v-model="variableToPublish"
              placeholder="*** Variables from previous formula ***"
              :options="currentVar"
              label=""
              @click="variableToPublishError = false"
              @keydown.tab.prevent="disableTab"
            />
            <span
              v-if="variableToPublishError"
              id="bubbleName"
              class="mds-form__field-error"
              role="alert"
            >
              <span
                class="mds-form__field-error-text"
                style="margin-bottom: 10px;"
              >
                Please select valid variable to publish.
              </span>
            </span>
          </mds-col>
        </mds-row>
      </div>
      <span v-if="variableValue !== ''"><strong>${{ variableValue }}</strong></span>
      <div style="margin-top: 1%;">
        <div><span>Multiple products are defined across different input sets.</span></div>
        <div style="margin-top: 1%;">
          <mds-button-container left-aligned>
            <mds-button
              type="button"
              variation="secondary"
              @click="overwriteAllProducts()"
              @keydown.tab.prevent="disableTab"
            >
              Overwrite all products.
            </mds-button>
          </mds-button-container>
        </div>
      </div>
      <div v-if="asContracts && isForwardCurveChk === 'true'">
        <mds-row style="padding-top: 1%;">
          <mds-col
            :cols="2"
            style="padding-top: 1%;"
          >
            <span><strong>Contract size: </strong></span>
          </mds-col>
          <mds-col
            :cols="3"
          >
            <mds-select
              v-model="delivType"
              label=""
              :options="[
                { text: '30-Minute', value: '30-minute' },
                { text: 'Hour', value: 'hour' },
                { text: 'Day', value: 'day' },
                { text: 'Week', value: 'week' },
                { text: 'Month', value: 'month' },
                { text: 'Quater', value: 'quater' },
                { text: 'Season', value: 'season' },
                { text: 'Year', value: 'year' },
              ]"
              @keydown.tab.prevent="disableTab"
            />
          </mds-col>
          <mds-col style="padding-top: 1.5%;">
            <mds-checkbox
              v-model="withGaps"
              value="true"
              checked
              @keydown.tab.prevent="disableTab"
            >
              Allow gaps between contracts.
            </mds-checkbox>
          </mds-col>
        </mds-row>
        <mds-row>
          <mds-col
            :cols="2"
            style="padding-top: 1%;"
          >
            <span><strong>Curve date: </strong></span>
          </mds-col>
          <mds-col
            :cols="2"
          >
            <mds-select
              v-model="dir"
              label=""
              :options="[
                { text: '-', value: '-' },
                { text: '+', value: '+' },
              ]"
              @keydown.tab.prevent="disableTab"
            />
          </mds-col>
          <mds-col
            :cols="2"
            style="padding-top: 0.5%;"
          >
            <mds-input
              v-model="offset"
              type="number"
              step="1"
              min="0"
              label=""
              @keydown.tab.prevent="disableTab"
            />
          </mds-col>
          <mds-col
            :cols="3"
          >
            <mds-select
              v-model="unit"
              label=""
              :options="[
                { text: 'Days', value: 'd' },
                { text: 'Business days', value: 'D' },
                { text: 'Hours', value: 'H' },
                { text: 'Minutes', value: 'm' },
              ]"
              @keydown.tab.prevent="disableTab"
            />
          </mds-col>
        </mds-row>
      </div>
    </mds-form>
    <mds-form
      v-else
      :class="isEditable === 'false' || isEditable === false ? 'disabled' : ''"
    >
      <div style="height: 90% !important; overflow-y: scroll; overflow-x: hidden; ">
        <div
          style="margin-bottom: 1%;"
        >
          <mds-row>
            <mds-col
              :cols="6"
              style="padding-left: 1.5%;"
            >
              <mds-select
                v-model="variableToPublish"
                placeholder="*** Variables from previous formula ***"
                :options="currentVar"
                label="What would you like to publish? "
                @click="variableToPublishError = false"
                @change="varNameChangeListener()"
                @keydown.tab.prevent="disableTab"
              />
              <span
                v-if="variableToPublishError"
                id="bubbleName"
                class="mds-form__field-error"
                role="alert"
              >
                <span
                  class="mds-form__field-error-text"
                  style="margin-bottom: 10px;"
                >
                  Please select valid variable to publish.
                </span>
              </span>
            </mds-col>
          </mds-row>
        </div>
        <span v-if="variableValue !== ''"><strong>${{ variableValue }}</strong></span>
        <div>
          <mds-row>
            <mds-col
              :cols="1"
              style="padding-top: 1%;"
            >
              <strong>Source: </strong>
            </mds-col>
            <mds-col :cols="4">
              <mds-select
                v-model="selectedSource"
                label=""
                :options="dataSetForSources"
                @change="sourceChangeListener()"
                @keydown.tab.prevent="disableTab"
              />
            </mds-col>
            <mds-col />
          </mds-row>
          <mds-row>
            <mds-col
              :cols="1"
              style="padding-top: 1%;"
            >
              <span><strong>Feed: </strong></span>
            </mds-col>
            <mds-col :cols="4">
              <mds-select
                v-model="selectedFeed"
                label=""
                :options="dataSetForFeed"
                :error="inputFeedNotAvailable"
                :error-text="errorMessageForFeed"
                @change="feedChangeListener()"
                @keydown.tab.prevent="disableTab"
              />
            </mds-col>
            <mds-col />
          </mds-row>
          <mds-row>
            <mds-col
              :cols="1"
              style="padding-top: 1%;"
            >
              <span><strong>Columns: </strong></span>
            </mds-col>
            <mds-col :cols="4">
              <mds-combo-box
                v-model="selectedColumns"
                multiple
                label=""
                :display-top="true"
                :data-set="dataSetForColumns"
                :disabled="disableColumns"
                :error="inputFeedNotAvailable"
                @input="columnChangeListener(disableColumns)"
                @keydown.tab.prevent="disableTab"
              />
            </mds-col>
            <mds-col />
          </mds-row>
          <mds-row>
            <mds-col
              :cols="1"
              style="padding-top: 1%;"
            >
              <span><strong>Type: </strong></span>
            </mds-col>
            <mds-col
              :cols="4"
              style="padding-top: 1%;"
            >
              <div v-if="!enableType">
                <div v-if="currentInputIsForwardCurve">
                  <mds-tag
                    size="medium"
                    style="margin-bottom: 10px;"
                  >
                    Variable Type: Forward Curves
                  </mds-tag>
                  <div>
                    <mds-combo-box
                      v-model="selectedRoots"
                      multiple
                      :display-top="true"
                      :data-set="currentFeedRoots"
                      @keydown.tab.prevent="disableTab"
                    />
                  </div>
                </div>
                <div v-else>
                  <mds-tag
                    size="medium"
                    style="margin-bottom: 10px;"
                  >
                    Variable Type: Timeseries Data
                  </mds-tag>
                  <div>
                    <mds-input
                      v-for="(key, index) in Object.keys(selectedKeys)"
                      :key="index"
                      v-model="selectedKeys[key]"
                      :label="key"
                      :error="inputFeedNotAvailable"
                      @keydown.tab.prevent="disableTab"
                    />
                  </div>
                </div>
              </div>
              <div
                v-else
                style="margin-bottom: 1%;"
              >
                <mds-row>
                  <mds-col>
                    <mds-radio-button
                      v-model="isForwardCurveChk"
                      name="selected-example"
                      :checked="isForwardCurveChk === true || isForwardCurveChk === 'true'"
                      value="true"
                      :class="isForwardCurveChk === '' || isForwardCurveChk === false ? 'disable' : ''"
                      @keydown.tab.prevent="disableTab"
                    >
                      Forward curve (futures contracts)
                    </mds-radio-button>
                  </mds-col>
                  <mds-col>
                    <mds-radio-button
                      v-model="isForwardCurveChk"
                      name="selected-example"
                      :checked="isForwardCurveChk === false || isForwardCurveChk === 'false'"
                      :class="isForwardCurveChk === '' || isForwardCurveChk === false ? 'disable' : ''"
                      value="false"
                      @keydown.tab.prevent="disableTab"
                    >
                      Time-series (history)
                    </mds-radio-button>
                  </mds-col>
                </mds-row>
                <mds-row>
                  <mds-col>
                    <div
                      v-if="enableSymbolroots && (isForwardCurveChk === true || isForwardCurveChk === 'true')"
                      style="margin-top: 1%;"
                    >
                      <mds-row>
                        <mds-col>
                          <mds-combo-box
                            v-if="notNewKey"
                            v-model="selectedRoots"
                            :display-top="true"
                            :data-set="currentFeedRoots"
                            label="Root(s)"
                            @keydown.tab.prevent="disableTab"
                          />
                          <mds-input
                            v-else
                            v-model="addedNewKey"
                            label="Add new key"
                            @keydown.tab.prevent="disableTab"
                            @focusout="addNewLabel($event)"
                          />
                        </mds-col>
                        <mds-col>
                          <div v-if="!notNewKey">
                            <mds-button
                              id="remove-add-new-key"
                              variation="icon-only"
                              icon="remove"
                              type="button"
                              style="margin-top: 30px;"
                              @click.stop="removeAddNewKey()"
                            />
                            <mds-tooltip
                              v-model="toggleRemoveAddNewKey"
                              triggered-by="remove-add-new-key"
                              :position="['right-center']"
                            >
                              Remove new key
                            </mds-tooltip>
                          </div>
                          <div v-else>
                            <mds-button
                              id="add-new-key"
                              variation="icon-only"
                              icon="plus"
                              type="button"
                              style="margin-top: 30px;"
                              @click.stop="addNewKey()"
                            />
                            <mds-tooltip
                              v-model="toggleaddNewKey"
                              triggered-by="add-new-key"
                              :position="['right-center']"
                            >
                              Add new key
                            </mds-tooltip>
                          </div>
                        </mds-col>
                      </mds-row>
                    </div>
                    <div v-else>
                      <mds-input
                        v-for="(key, index) in Object.keys(selectedKeys)"
                        :key="index"
                        v-model="selectedKeys[key]"
                        :label="key"
                        @keydown.tab.prevent="disableTab"
                      />
                    </div>
                  </mds-col>
                </mds-row>
              </div>
            </mds-col>
            <mds-col />
          </mds-row>
          <div v-if="isForwardCurveChk === 'true'">
            <mds-row style="padding-top: 1%;">
              <mds-col
                :cols="1"
                style="padding-top: 1%;"
              >
                <span><strong>Contract size: </strong></span>
              </mds-col>
              <mds-col
                :cols="3"
              >
                <mds-select
                  v-model="delivType"
                  label=""
                  :options="[
                    { text: '30-Minute', value: '30-minute' },
                    { text: 'Hour', value: 'hour' },
                    { text: 'Day', value: 'day' },
                    { text: 'Week', value: 'week' },
                    { text: 'Month', value: 'month' },
                    { text: 'Quater', value: 'quater' },
                    { text: 'Season', value: 'season' },
                    { text: 'Year', value: 'year' },
                  ]"
                  @keydown.tab.prevent="disableTab"
                />
              </mds-col>
              <mds-col style="padding-top: 1.5%;">
                <mds-checkbox
                  v-model="withGaps"
                  value="true"
                  checked
                  @keydown.tab.prevent="disableTab"
                >
                  Allow gaps between contracts.
                </mds-checkbox>
              </mds-col>
            </mds-row>
            <mds-row>
              <mds-col
                :cols="1"
                style="padding-top: 1%;"
              >
                <span><strong>Curve date: </strong></span>
              </mds-col>
              <mds-col
                :cols="2"
              >
                <mds-select
                  v-model="dir"
                  label=""
                  :options="[
                    { text: '-', value: '-' },
                    { text: '+', value: '+' },
                  ]"
                  @keydown.tab.prevent="disableTab"
                />
              </mds-col>
              <mds-col
                :cols="2"
                style="padding-top: 0.5%;"
              >
                <mds-input
                  v-model="offset"
                  type="number"
                  step="1"
                  min="0"
                  label=""
                  @keydown.tab.prevent="disableTab"
                />
              </mds-col>
              <mds-col
                :cols="3"
              >
                <mds-select
                  v-model="unit"
                  label=""
                  :options="[
                    { text: 'Days', value: 'd' },
                    { text: 'Business days', value: 'D' },
                    { text: 'Hours', value: 'H' },
                    { text: 'Minutes', value: 'm' },
                  ]"
                  @keydown.tab.prevent="disableTab"
                />
              </mds-col>
            </mds-row>
          </div>
        </div>
      </div>
      <div v-if="isEditable">
        <hr v-if="isEditable">
        <mds-button-container right-aligned>
          <mds-button
            v-if="isEditable"
            type="button"
            variation="primary"
            :disabled="validateSaveButton"
            @click="saveParameter(validateSaveButton)"
          >
            Save
          </mds-button>
        </mds-button-container>
      </div>
    </mds-form>
  </div>
</template>

<script>
import {
  MdsTable, MdsThead, MdsTh, MdsTbody, MdsTr, MdsTd,
} from '@mds/data-table';
import MdsDialog from '@mds/dialog';
import MdsForm from '@mds/form';
import MdsFieldset from '@mds/fieldset';
import MdsInput from '@mds/input';
import { mapGetters, mapActions } from 'vuex';
import { MdsButton, MdsButtonContainer } from '@mds/button';
import MdsComboBox from '@mds/combo-box';
import MdsSelect from '@mds/select';
import MdsDatePicker from '@mds/date-picker';
import MdsRadioButton from '@mds/radio-button';
import { MdsTag } from '@mds/tag';
import MdsTextarea from '@mds/textarea';
import { MdsLayoutGrid, MdsRow, MdsCol } from '@mds/layout-grid';
import { MdsNotification, MdsNotificationContainer } from '@mds/notification';
import MdsCheckbox from '@mds/checkbox';
import _ from 'lodash';
import CryptoJS from 'crypto-js';
import { _editSaveCB, _getVarsInFormula, _goodVarName } from '../../../scripts/utils/Manager';

const defaultOption = {
  text: '*** Choose one ***',
  value: 'default',
};

export default {
  name: 'WorkflowPublishEditActions',
  components: {
    MdsForm,
    MdsButton,
    MdsButtonContainer,
    MdsInput,
    MdsComboBox,
    MdsSelect,
    MdsTag,
    MdsRow,
    MdsCol,
    MdsCheckbox,
    MdsRadioButton,
    MdsNotification,
    MdsNotificationContainer,
  },
  props: {
    node: {
      type: Object,
      default: null,
    },
    workflowData: {
      type: Object,
      default: null,
    },
    nodeVarName: {
      type: String,
      default: null,
    },
    formulas: {
      type: Array,
      default: null,
    },
    parameterSetData: {
      type: Object,
      default: null,
    },
    workflow: {
      type: Object,
      required: false,
      default: null,
    },
    isNewNode: {
      type: Boolean,
      required: false,
      default: false,
    },
    nodeVarNameEdit: {
      type: String,
      default: null,
    },
    isEditable: {
      type: Boolean,
      required: false,
      default: false,
    },
    badFormulaBubbles: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      showLoader: false,
      headers: [
        {
          text: 'Name',
          fieldName: 'name',
          style: {
            width: '150px',
          },
        },
        {
          text: 'Description',
          fieldName: 'description',
          style: {
            width: '150px',
          },
        },
      ],
      paramHeaders: [],
      inputData: [],
      toggle: false,
      currentInputToEdit: {
        name: '',
        prevName: '',
        prevVarToPublish: '',
        variableToPublish: '',
        body: {
          cols: '',
          key: '',
          prov: '',
          source: '',
          feed: '',
        },
      },
      allUserDataSources: {},
      userFeeds: [],
      dataSetForSources: [],
      selectedSource: [],
      dataSetForFeed: [],
      selectedFeed: [],
      currentFeedDetails: {},
      dataSetForColumns: [],
      disableColumns: false,
      selectedColumns: [],
      currentFeedRoots: [],
      currentInputIsForwardCurve: false,
      selectedKeys: {},
      selectedRoots: [],
      currentInputIsVariableType: false,
      variableDataType: '',
      variableValue: '',
      minMaxDates: {
        min: new Date(1970, 0, 1),
        max: new Date(2050, 11, 31),
      },
      disableCopyButton: true,
      multiFeed: false,
      expireTime: '',
      expireSet: false,
      modifiedParameters: [],
      enableType: false,
      enableSymbolroots: false,
      isForwardCurveChk: true,
      coonectedFormula: '',
      currentVar: [],
      variableToPublish: [],
      variableToPublishError: false,
      unit: 'd',
      offset: '0',
      dir: '-',
      withGaps: true,
      delivType: 'month',
      curveDateAdj: '',
      asContracts: false,
      paramSet: {},
      colTypes: {},
      errorMessage: '',
      showError: false,
      currentFeedKeys: [],

      // Parameter model status
      updateParameterSetData: {
        add: [],
        update: [],
        delete: { psgIds: [], paramIds: [] },
        psgToRename: [],
        currentPsgVersion: '1',
      },
      updatedVariableMap: {},
      addVariableMap: {},
      toggleaddNewKey: false,
      notNewKey: true,
      addedNewKey: '',
      toggleRemoveAddNewKey: false,
      inputFeedNotAvailable: false,
      errorMessageForFeed: [],
    };
  },
  computed: {
    ...mapGetters('workflowModule', [
      'getParamStatus',
      'getWorkflowFormulas',
      'getEditableWorkflowDetails',
      'getEditableWorkflowUI',
      'getEditableParamSet',
      'getEditableFormulae',
      'getEditableNewFormulae',
      'getEditableNodes',
      'getEditableLinks',
      'getEditableTargets',
      'getCreateNewWorkflowDetails',
      'getCreateNewWorkflowUI',
      'getCreateNewParamSet',
      'getCreateNewFormulae',
      'getallWorkflowsForName',
      'getCurrentWorkflowManager',
    ]),
    ...mapGetters('feedModule', ['getFeedList', 'getFeedDetails', 'getAllFeedRoots', 'getErrorMessage']),
    selectionStatus() {
      // filter your rows by the checked property
      const filtered = this.inputData.filter(row => row.checked === true);

      // isIndeterminate = there are more than one rows checked but not all
      // isAllSelected = all rows are checked
      return {
        isIndeterminate: filtered.length > 0 && filtered.length < this.inputData.length,
        isAllSelected: filtered.length === this.inputData.length,
      };
    },
    validateSaveButton() {
      return !!this.inputFeedNotAvailable;
    },
  },
  beforeMount() {

  },
  mounted() {
    this.init();
  },
  methods: {
    ...mapActions('feedModule', ['getUserDatasources', 'getUserFeeds', 'getFeedDetailsByName', 'getFeedRoots']),
    ...mapActions('workflowModule', [
      'getUserWorkflows',
      'getWorkflowStatus',
      'getFormulasForWorkflow',
      'setUserWorkflow',
      'getParameterSet',
      'setCurrentEditableWorkflow',
      'setNewlyCreateWorkflow',
      'setCurrentEditableWorkflow',
      'setCurrentWorkflowManager',
      'setCurrentEditParamSet',
      'setCurrentEditWorkflowUI',
    ]),
    async init() {
      this.showLoader = true;

      this.paramSet = this.getEditableWorkflowDetails.paramSet;
      this.manager = this.getCurrentWorkflowManager;

      if (this.manager._priv) {
        this.updateParameterSetData = this.manager._priv.updateParameterSetData;
        if (this.updateParameterSetData.update.length > 0) {
          const updatedVariableMapObj = {};
          for (let i = 0; i < this.updateParameterSetData.update.length; i++) {
            const element = this.updateParameterSetData.update[i];
            const varName = element.propKey.replace('udef.ds.', '').split('.')[0];
            const varKey = element.propKey.replace('udef.ds.', '').split('.')[1];
            if (updatedVariableMapObj[`${varName}`]) {
              updatedVariableMapObj[`${varName}`] = updatedVariableMapObj[`${varName}`];
            } else {
              updatedVariableMapObj[`${varName}`] = [];
            }
            updatedVariableMapObj[`${varName}`].push(i);
          }
          this.updatedVariableMap = updatedVariableMapObj;
        }

        if (this.updateParameterSetData.add.length > 0) {
          const addVariableMapObj = {};
          for (let i = 0; i < this.updateParameterSetData.add.length; i++) {
            const element = this.updateParameterSetData.add[i];
            let varName = '';
            for (let j = 0; j < element.parameterModels.length; j++) {
              const e = element.parameterModels[j];
              // eslint-disable-next-line prefer-destructuring
              varName = e.propKey.replace('udef.ds.', '').split('.')[0];
              const varKey = e.propKey.replace('udef.ds.', '').split('.')[1];
              if (addVariableMapObj[`${varName}`]) {
                addVariableMapObj[`${varName}`] = addVariableMapObj[`${varName}`];
              } else {
                addVariableMapObj[`${varName}`] = [];
              }
            }
            addVariableMapObj[`${varName}`].push(i);
          }
          this.addVariableMap = addVariableMapObj;
        }
      }

      this.inputData = JSON.parse(JSON.stringify(this.manager.inputData));
      this.inputData.forEach((row) => {
        row.visible = true;
      });
      this.paramHeaders = this.manager.paramHeaders;
      this.colTypes = this.manager.colTypes;
      this.rawPsgModel = this.manager.rawPsgModel;
      let updateBody = this.manager._priv.updateParameterSetData;
      if (!updateBody) {
        updateBody = {
          add: [], update: [], delete: { psgIds: [], paramIds: [] }, psgToRename: [],
        };
        this.manager._priv.updateParameterSetData = updateBody;
      } else {
        this.psgToAdd = updateBody.add;
        this.modifiedParameters = updateBody.update;
        this.deletedParameters = updateBody.delete;
        this.renamedRows = updateBody.psgToRename;
      }

      const currentVarObj = _getVarsInFormula(this.getCurrentWorkflowManager._priv.bubbles[this.node.id]._ins[0]._src, {});

      if (!currentVarObj) {
        this.showError = true;
        this.errorMessage = 'Cannot find any variable being defined in the previous formula;'
                            + ' nothing to publish.\n\nTry editing the formula first.';
      }

      const currentVarArr = Object.values(currentVarObj);
      for (let i = 0; i < currentVarArr.length; i++) {
        const element = currentVarArr[i];
        if (!this.isNewNode) {
          if (this.getCurrentWorkflowManager._priv.bubbles[this.node.id]._data.srcVar.trim() === element._name.trim()) {
            this.variableToPublish = [element._name.trim()];
            this.currentInputToEdit.variableToPublish = element._name.trim();
            this.currentInputToEdit.prevVarToPublish = element._name.trim();
          }
        }
        const obj = {
          text: `${element._name} <= ${element._code}`.substring(0, 75).concat('...'),
          value: `${element._name.trim()}`,
        };
        this.currentVar.push(obj);
      }

      let lastFeed = '';
      let lastSource = '';
      this.userFeeds = this.getFeedList;
      let lastCols = [];
      let lastKeys = [];
      if (!this.isNewNode) {
        this.variableValue = this.nodeVarNameEdit.replace(/[^a-zA-Z0-9_]/g, '');
        this.currentInputToEdit.prevName = this.variableValue;
        this.currentInputToEdit.name = this.variableValue;

        const hashes = {};
        if (this.inputData.length > 1) {
          this.inputData.forEach((row) => {
            if (row[this.variableValue]) {
              const temp = row[this.variableValue];
              delete temp.data;
              hashes[CryptoJS.MD5(JSON.stringify(temp)).toString()] = temp;
              if (Object.keys(hashes).length > 1) {
                this.multiFeed = true;
              }
            }
          });
          if (!this.multiFeed) {
            lastFeed = this.inputData[0][this.variableValue].feed ? this.inputData[0][this.variableValue].feed.trim() : '';
            lastSource = this.inputData[0][this.variableValue].source
              ? this.inputData[0][this.variableValue].source.trim() : '';
            lastCols = this.inputData[0][this.variableValue].cols;
            lastKeys = this.inputData[0][this.variableValue].key ? this.inputData[0][this.variableValue].key : [];
            this.currentInputIsForwardCurve = this.inputData[0][this.variableValue].isForwardCurve;
          }
        } else {
          lastFeed = this.inputData[0][this.variableValue].feed ? this.inputData[0][this.variableValue].feed.trim() : '';
          lastSource = this.inputData[0][this.variableValue].source ? this.inputData[0][this.variableValue].source.trim() : '';
          lastCols = this.inputData[0][this.variableValue].cols;
          lastKeys = this.inputData[0][this.variableValue].key ? this.inputData[0][this.variableValue].key : [];
        }
        this.currentInputIsVariableType = false;
        if (this.inputData[0][this.variableValue].isForwardCurve) {
          this.selectedRoots = this.inputData[0][this.variableValue].key;
          this.isForwardCurveChk = 'true';
          this.asContracts = true;
        } else {
          this.selectedKeys = this.inputData[0][this.variableValue].key;
          this.isForwardCurveChk = 'false';
          this.asContracts = false;
        }
      } else {
        this.currentInputToEdit.prevName = '';
        this.currentInputToEdit.name = '';
      }

      this.asContracts = this.node.data.asContracts ? this.node.data.asContracts : false;
      if (this.asContracts) {
        this.curveDateAdj = this.node.data.curveDateAdj ? this.node.data.curveDateAdj : '';
        this.getRelDateCode(this.curveDateAdj);
      }
      this.delivType = this.node.data.delivType ? this.node.data.delivType : 'month';
      this.withGaps = this.node.data.withGaps ? this.node.data.withGaps : false;

      if (this.userFeeds.length === 0) {
        try {
          this.getUserFeeds().then(async () => {
            await this.extractDataSourcesFromFeeds(lastFeed, lastCols);
          }).finally(() => {
            if (this.allUserDataSources[lastSource].feeds) {
              if (this.inputFeedNotAvailable) {
                this.dataSetForSources.unshift(defaultOption);
                this.dataSetForFeed.push({
                  text: lastFeed,
                  value: lastFeed,
                });
                this.selectedSource = 'default';
              } else {
                this.selectedSource[0] = lastSource;
                this.dataSetForFeed = this.allUserDataSources[lastSource].feeds.map((f) => {
                  f.text = f.name;
                  f.value = f.name;
                  return f;
                });
              }
              this.dataSetForFeed.unshift(defaultOption);
            }
            this.$nextTick(() => {
              this.selectedFeed = [lastFeed];
            });
          });
        } catch {
          this.showLoader = false;
        }
      } else {
        await this.extractDataSourcesFromFeeds(lastFeed, lastCols);
        this.selectedSource = lastSource;
        if (this.allUserDataSources[lastSource] && this.allUserDataSources[lastSource].feeds) {
          if (this.inputFeedNotAvailable) {
            this.dataSetForSources.unshift(defaultOption);
            this.dataSetForFeed.push({
              text: lastFeed,
              value: lastFeed,
            });
            this.selectedSource = 'default';
          } else {
            this.selectedSource = lastSource;

            if (this.allUserDataSources[lastSource]) {
              this.dataSetForFeed = this.allUserDataSources[lastSource].feeds.map((f) => {
                f.text = f.name;
                f.value = f.name;
                return f;
              });
            }
          }
        }
        this.$nextTick(() => {
          this.selectedFeed = [lastFeed];
        });
      }
    },
    varNameChangeListener() {
      if (this.variableToPublish.length === 0) {
        return;
      }
      this.currentInputToEdit.variableToPublish = Array.isArray(this.variableToPublish) ? this.variableToPublish[0] : this.variableToPublish;
      this.variableValue = _goodVarName(this.variableToPublish, this.getCurrentWorkflowManager._priv.dataset, this.getCurrentWorkflowManager);
      this.currentInputToEdit.name = this.variableValue;
    },
    arraysEqual(a, b) {
      if (a === b) return true;
      if (a == null || b == null) return false;
      if (a.length !== b.length) return false;

      // If you don't care about the order of the elements inside
      // the array, you should sort both arrays here.
      // Please note that calling sort on an array will modify that array.
      // you might want to clone your array first.

      for (let i = 0; i < a.length; ++i) {
        if (a[i] !== b[i]) return false;
      }
      return true;
    },
    getRelDateCode(relDateVal) {
      const regex = new RegExp('^([-+])(\\d+)([dDHm])$');
      if (relDateVal) {
        const match = regex.exec(relDateVal);

        if (match === null) throw new Error(`IllegalStateException: relative-date value is unrecognized (${ relDateVal })`);

        [this.dir, this.offset, this.unit] = match.slice(1);
      }
    },
    findConnectedFromula() {
      const connectedNodeID = this.node.data.connectedFormulaNodeId;
      let connectedFormulaUUID = '';
      for (let i = 0; i < this.workflow.ui.bubbles.length; i++) {
        const element = this.workflow.ui.bubbles[i];
        if (element.id === connectedNodeID) {
          connectedFormulaUUID = element.props.formula_id;
        }
      }
      for (let i = 0; i < this.formulas.length; i++) {
        const element = this.formulas[i];
        if (element.uuid === connectedFormulaUUID) {
          this.coonectedFormula = element.formula;
        }
      }

      this.decodeFormulaForSteps();
    },
    determineVariableType(value) {
      let varType = null;
      let varValue = null;
      try {
        varType = typeof JSON.parse(value);
        varValue = JSON.stringify(JSON.parse(value));
      } catch {
        if (/\d{4}-\d{2}-\d{2}/gm.test(value)) {
          varValue = new Date(value);
          // eslint-disable-next-line eqeqeq
          if (varValue == 'Invalid Date') {
            varType = 'string';
            varValue = value;
          } else {
            varType = 'date';
          }
        } else {
          varType = 'string';
        }
      }
      return { type: varType, value: varValue };
    },
    decodeFormulaForSteps() {
      const formuladecode = this.coonectedFormula.split('\n\nvar');
      const count = 2;
      const varList = [];
      let currentVarName = '';
      for (let i = 0; i < formuladecode.length; i++) {
        const element = formuladecode[i];
        // eslint-disable-next-line prefer-destructuring
        currentVarName = element.split(' =')[0];
        varList.push(element.split(' =')[0].replace(/[$]/g, ''));
      }

      let dataVarName = '';
      for (let i = 0; i < formuladecode.length; i++) {
        const element = formuladecode[i];
        if (element.includes('time_series')) {
          const obj = {
            text: `var ${ element.split(';\n\n/')[0].replace('=', '<=')}`,
            value: `var ${ element.split(';\n\n/')[0].replace('=', '<=')}`,
          };
          this.currentVar.push(obj);
          dataVarName = this.variableValue === '' ? (`${element.split(' =')[0] }`).trim() : this.variableValue;
        } else if (element.includes('cell_sum')) {
          const obj = {
            text: `var ${ element.split(';\n\n/')[0].replace('=', '<=')}`,
            value: `var ${ element.split(';\n\n/')[0].replace('=', '<=')}`,
          };
          this.currentVar.push(obj);
        }
      }
      let maxcount = 1;
      const filteredArray = varList.filter(element => element.includes(dataVarName));
      for (let i = 0; i < filteredArray.length; i++) {
        const element = filteredArray[i];
        maxcount = (element.split('_')[1] !== '') && (element.split('_')[1] > maxcount) ? element.split('_')[1] : maxcount;
      }
      this.variableValue = `${dataVarName }_${ ++maxcount}`;
    },
    sourceChangeListener() {
      if (this.selectedSource.length === 0) {
        return;
      }
      this.inputFeedNotAvailable = false;
      // change feed
      this.dataSetForFeed = this.allUserDataSources[this.selectedSource].feeds.map((f) => {
        f.text = f.name;
        f.value = f.name;
        return f;
      });
      this.dataSetForFeed.unshift(defaultOption);
      this.selectedFeed = [defaultOption.text];
      this.feedChangeListener();
      this.asContracts = false;
      this.isForwardCurveChk = 'false';
      this.enableType = false;
    },
    feedChangeListener() {
      // clear columns
      this.$set(this, 'selectedColumns', []);
      this.dataSetForColumns = [];
      this.disableColumns = true;
      // clear keys
      this.selectedKeys = {};
      // clear roots
      this.selectedRoots = [];
      // fetch feed details
      if (this.selectedFeed[0] !== defaultOption.text) {
        this.fetchFeedDetails(this.selectedFeed, []);
      }
    },
    sortDataSources(a, b) {
      const fa = a.text.toLowerCase();
      const fb = b.text.toLowerCase();

      if (fa < fb) {
        return -1;
      }
      if (fa > fb) {
        return 1;
      }
      return 0;
    },
    async extractDataSourcesFromFeeds(feedname, cols) {
      const datasourceSet = {};
      this.getFeedList.forEach((feed) => {
        if (Object.hasOwnProperty.call(datasourceSet, feed.dataSource)) {
          datasourceSet[feed.dataSource]['feeds'].push(feed);
        } else {
          datasourceSet[feed.dataSource] = {
            text: feed.dataSource,
            value: feed.dataSource,
            feeds: [feed],
          };
        }
      });
      this.allUserDataSources = datasourceSet;
      this.dataSetForSources = Object.values(datasourceSet).sort(this.sortDataSources);
      await this.fetchFeedDetails(feedname, cols);
    },
    columnChangeListener(isDisabled) {
      if (isDisabled) {
        return;
      }
      if (this.selectedFeed[0] !== defaultOption.text) {
        this.fetchFeedDetails(this.selectedFeed, this.selectedColumns);
      }
    },
    async fetchFeedDetails(feedname, cols) {
      if (this.currentFeedDetails || !this.getFeedDetails || this.getFeedDetails.name !== feedname) {
        try {
          await this.getFeedDetailsByName(feedname);
          this.currentFeedDetails = this.getFeedDetails;
        } catch (error) {
          if (error.response.status === 404 || error.response.status === 403) {
            this.inputFeedNotAvailable = true;
            this.errorMessageForFeed[0] = this.getErrorMessage.replace(feedname, 'feed');

            cols.forEach((col) => {
              this.dataSetForColumns.push({
                text: col,
                value: col,
              });
            });

            this.$set(this, 'selectedColumns', cols);
          }
          this.showLoader = false;
        }

        this.selectedProvider = this.currentFeedDetails.provider;
        this.extractFeedColumns(cols);
      } else {
        this.extractFeedColumns(cols);
      }

      this.currentFeedKeys = [];
      if (this.currentFeedDetails.fields) {
        for (let i = 0; i < this.currentFeedDetails.fields.length; i++) {
          const element = this.currentFeedDetails.fields[i];
          if (element.type.toLowerCase() === 'k') {
            this.currentFeedKeys.push(element.fieldName);
          }
        }
      }

      if (Object.hasOwnProperty.call(this.getAllFeedRoots, feedname)) {
        this.currentFeedRoots = this.getAllFeedRoots[feedname].map(root => ({ text: root['root'], value: root['root'] }));

        if (this.currentFeedRoots.length > 0) {
          this.enableType = true;
          this.enableSymbolroots = true;
        } else {
          this.enableType = false;
          this.enableSymbolroots = false;
        }
        this.showLoader = false;
        if (this.currentFeedRoots.length > 0) {
          let flag = 0;
          for (let i = 0; i < this.currentFeedRoots.length; i++) {
            if (this.currentFeedRoots.value === this.inputData[0][this.variableValue].key[0]) {
              flag = 1;
            }
          }
          if (flag === 0) {
            this.currentFeedRoots.push({
              text: `${this.inputData[0][this.variableValue].key[0] } -- [new]`,
              value: this.inputData[0][this.variableValue].key[0],
            });
          }
        } else {
          this.notNewKey = true;
        }
      } else {
        this.getFeedRoots(feedname).then(() => {
          this.currentFeedRoots = this.getAllFeedRoots[feedname].map(root => ({ text: root['root'], value: root['root'] }));

          if (this.currentFeedRoots.length > 0) {
            this.enableType = true;
            this.enableSymbolroots = true;
          } else {
            this.enableType = false;
            this.enableSymbolroots = false;
          }
          this.showLoader = false;
          if (this.currentFeedRoots.length > 0) {
            let flag = 0;
            for (let i = 0; i < this.currentFeedRoots.length; i++) {
              if (this.currentFeedRoots.value === this.inputData[0][this.variableValue].key[0]) {
                flag = 1;
              }
            }
            if (flag === 0) {
              this.currentFeedRoots.push({
                text: `${this.inputData[0][this.variableValue].key[0] } -- [new]`,
                value: this.inputData[0][this.variableValue].key[0],
              });
            }
          } else {
            this.notNewKey = true;
          }
        }).catch(() => {
          this.showLoader = false;
        });
      }
    },
    extractFeedColumns(cols) {
      if (this.currentFeedDetails.fields) {
        this.dataSetForColumns = this.currentFeedDetails.fields
          .filter(field => field.type.localeCompare('k', 'en', { sensitivity: 'base' }))
          .map((field) => {
            field.text = field.fieldName;
            field.value = field.fieldName;
            return field;
          });
      }

      if (this.selectedKeys && Object.keys(this.selectedKeys).length === 0) {
        this.showLoader = true;
        if (this.currentFeedDetails.fields) {
          this.currentFeedDetails.fields
            .filter(field => !field.type.localeCompare('k', 'en', { sensitivity: 'base' }))
            .forEach((element) => {
              this.$set(this.selectedKeys, element.fieldName, '');
            });
        }
      }
      this.$set(this, 'selectedColumns', cols);
      this.disableColumns = false;
      this.showLoader = false;
    },
    overwriteAllProducts() {
      // toggling the view
      this.multiFeed = !this.multiFeed;

      // clearing the variables
      this.selectedSource = [];
      this.selectedFeed = [];
      this.dataSetForFeed = [];
      this.$set(this, 'selectedColumns', []);
      this.dataSetForColumns = [];
      this.currentInputIsForwardCurve = null;
      this.selectedKeys = [];
      this.selectedRoots = [];
    },
    addNewKey() {
      this.notNewKey = false;
    },
    removeAddNewKey() {
      this.notNewKey = true;
    },
    addNewLabel() {
      if (this.addedNewKey !== '') {
        const obj = {
          text: !this.addedNewKey.includes(' -- [new]') ? `${this.addedNewKey} -- [new]` : this.addNewKey,
          value: this.addedNewKey,
        };
        this.selectedRoots = [this.addedNewKey];
        this.currentFeedRoots.push(obj);
        this.notNewKey = true;
        this.addedNewKey = '';
      }
    },
    updateCurrentInput() {
      this.manager = this.getCurrentWorkflowManager;

      this.currentInputToEdit.body.feed = Array.isArray(this.selectedFeed) ? this.selectedFeed[0] : this.selectedFeed;
      this.currentInputToEdit.body.source = Array.isArray(this.selectedSource) ? this.selectedSource[0] : this.selectedSource;
      this.currentInputToEdit.body.prov = Array.isArray(this.selectedProvider) ? this.selectedProvider[0] : this.selectedProvider;
      this.currentInputToEdit.body.asContracts = this.isForwardCurveChk === 'true';

      this.currentInputToEdit.body.cols = this.selectedColumns;
      if (!this.enableType && !this.currentInputIsForwardCurve) {
        this.currentInputToEdit.body.key = this.selectedKeys;
      } else if (!this.enableType && this.currentInputIsForwardCurve) {
        this.currentInputToEdit.body.key = this.selectedRoots ? this.selectedRoots : [];
      } else if (this.enableType && (this.isForwardCurveChk === 'true')) {
        this.currentInputToEdit.body.key = this.selectedRoots ? this.selectedRoots : [];
      } else {
        this.currentInputToEdit.body.key = this.selectedKeys;
      }
      // eslint-disable-next-line vue/no-mutating-props
      this.node.data.asContracts = this.isForwardCurveChk === 'true';
      this.currentInputToEdit.body.curveDateAdj = `${this.dir }${ this.offset }${ this.unit}`;
      // eslint-disable-next-line vue/no-mutating-props
      this.node.data.curveDateAdj = `${this.dir }${ this.offset }${ this.unit}`;
      this.currentInputToEdit.body.delivType = this.delivType;
      // eslint-disable-next-line vue/no-mutating-props
      this.node.data.delivType = this.delivType;
      this.currentInputToEdit.body.withGaps = this.withGaps;
      // eslint-disable-next-line vue/no-mutating-props
      this.node.data.withGaps = this.withGaps;
      let flag = 0;
      if (!this.isNewNode) {
        if (this.currentInputToEdit.prevName !== this.currentInputToEdit.name) {
          flag = 1;
        }
      } else {
        flag = 1;
      }
      const dataObj = {
        mgr: this.manager,
        ui: this.getEditableWorkflowUI.ui,
        isVarNameEdited: flag,
        bubble: this.manager._priv.bubbles[this.node.id],
        dataset: this.manager._priv.datasets,
        displayed: {},
        firstRow: 0,
        lastRow: Math.max(0, this.manager._priv.psgBuffer.length - 1),
        feed: {
          name: this.currentInputToEdit.body.feed ? this.currentInputToEdit.body.feed : '',
          provider: this.currentFeedDetails.provider ? this.currentFeedDetails.provider : '',
          dataSource: this.currentInputToEdit.body.source ? this.currentInputToEdit.body.source : '',
          keys: this.currentFeedKeys,
        },
        type: this.isForwardCurveChk === 'true' ? 'Type.ROOTS' : 'Type.KEYS',
        cols: this.currentInputToEdit.body.cols,
        keyOrRoots: this.currentInputToEdit.body.key,
        isKeySet: this.isForwardCurveChk === 'true',
        expireTimeUi: this.expireTime,
        curveDate: this.curveDateAdj,
        // has to be a full name of bubble 'srcVar + _count'
        varName: this.variableValue.trim(),
        // has to be a name of bubble 'srcVar'
        varToSave: Array.isArray(this.variableToPublish) ? this.variableToPublish[0] : this.variableToPublish,
        asContracts: this.asContracts,
        delivType: this.delivType,
        withGaps: this.withGaps,
        offset: this.offset,
        dir: this.dir,
        unit: this.unit,
        operation: 'edit',
      };
      this.currentBubble = this.manager._priv.bubbles[this.node.id];
      if (!_editSaveCB(dataObj, this)) {
        return true;
      }

      // -------------------------------
      return false;
    },
    braodcastFormulaErrorMsg() {
      this.$emit('gotFormulaError', this.badFormulaBubbles, this.errorMessage);
    },
    hideError() {
      this.showError = false;
    },
    saveParameter(isDisabled) {
      if (isDisabled || this.updateCurrentInput()) {
        return;
      }

      // Existing data bubble values Edited
      // 1. Variable Name changed
      // 2. feilds value changed
      if (!this.isNewNode) {
        const varsToAdd = {};
        this.updateParameterSetData.add.forEach((psg) => {
          if (psg && Object.hasOwn(psg, 'parameterModels')) {
            psg.parameterModels.forEach((p) => {
              if (p.propKey.startsWith(`udef.ds.${this.currentInputToEdit.prevName}.`)) {
                varsToAdd[p.propKey] = p;
              }
            });
          }
        });

        const varsToUpdate = {};
        this.updateParameterSetData.update.forEach((p) => {
          if (p.propKey.startsWith(`udef.ds.${this.currentInputToEdit.prevName}.`)) {
            varsToUpdate[p.propKey] = p;
          }
        });

        // update if already present in updateParameterSetData.add
        this.updateParameterSetData.add.forEach((psg) => {
          if (psg && Object.hasOwn(psg, 'parameterModels')) {
            psg.parameterModels.forEach((p) => {
              if (p.propKey.startsWith(`udef.ds.${this.currentInputToEdit.prevName}.`)) {
                const propType = p.propKey.split('.')[3];
                p.propKey = p.propKey.replace(this.currentInputToEdit.prevName, this.currentInputToEdit.name);
                if (propType === 'cols' || propType === 'key') {
                  p.propValue = JSON.stringify(this.currentInputToEdit.body[propType]);
                } else {
                  p.propValue = this.currentInputToEdit.body[propType];
                }
              }
            });
          }
        });

        // update if already present updateParameterSetData.update
        if (Object.keys(varsToUpdate).length > 0) {
          this.updateParameterSetData.update.forEach((p) => {
            if (p.propKey.startsWith(`udef.ds.${this.currentInputToEdit.prevName}.`)) {
              const propType = p.propKey.split('.')[3];
              p.propKey = p.propKey.replace(this.currentInputToEdit.prevName, this.currentInputToEdit.name);
              if (propType === 'cols' || propType === 'key') {
                p.propValue = JSON.stringify(this.currentInputToEdit.body[propType]);
              } else {
                p.propValue = this.currentInputToEdit.body[propType];
              }
            }
          });
        } else {
          // if not present then add in updateParameterSetData.update if param has id
          this.parameterSetData.parameterSetModels.forEach((psg) => {
            if (psg && Object.hasOwn(psg, 'parameterModels')) {
              psg.parameterModels.forEach((p) => {
                if (p.propKey.startsWith(`udef.ds.${this.currentInputToEdit.prevName}.`)) {
                  const propType = p.propKey.split('.')[3];
                  p.propKey = p.propKey.replace(this.currentInputToEdit.prevName, this.currentInputToEdit.name);
                  if (propType === 'cols' || propType === 'key') {
                    p.propValue = JSON.stringify(this.currentInputToEdit.body[propType]);
                  } else {
                    p.propValue = this.currentInputToEdit.body[propType];
                  }
                  if (Object.hasOwn(p, 'id')) {
                    this.updateParameterSetData.update.push(p);
                  }
                }
              });
            }
          });
        }

        // update this.parameterSetData.parameterSetModels and inputData rows
        this.parameterSetData.parameterSetModels.forEach((psg) => {
          if (psg && Object.hasOwn(psg, 'parameterModels')) {
            psg.parameterModels.forEach((p) => {
              if (p.propKey.startsWith(`udef.ds.${this.currentInputToEdit.prevName}.`)) {
                const propType = p.propKey.split('.')[3];
                p.propKey = p.propKey.replace(this.currentInputToEdit.prevName, this.currentInputToEdit.name);
                if (propType === 'cols' || propType === 'key') {
                  p.propValue = JSON.stringify(this.currentInputToEdit.body[propType]);
                } else {
                  p.propValue = this.currentInputToEdit.body[propType];
                }
              }
            });
          }

          const index = this.inputData.findIndex(row => row.name === psg.name);
          if (index >= 0 && Object.hasOwn(this.inputData[index], '_currentServerModel')) {
            this.inputData[index]._currentServerModel.parameterModels.forEach((p) => {
              if (p.propKey.startsWith(`udef.ds.${this.currentInputToEdit.prevName}.`)) {
                const propType = p.propKey.split('.')[3];
                p.propKey = p.propKey.replace(this.currentInputToEdit.prevName, this.currentInputToEdit.name);
                if (propType === 'cols' || propType === 'key') {
                  p.propValue = JSON.stringify(this.currentInputToEdit.body[propType]);
                } else {
                  p.propValue = this.currentInputToEdit.body[propType];
                }
              }
            });
            this.inputData[index]._isModified = true;
          }
        });

        this.$emit('publishBubbleEdited', this.parameterSetData, this.currentInputToEdit, this.node);
      } else {
        const currentupdateParameterSetData = {
          add: this.manager._priv.updateParameterSetData.add,
        };
        for (let i = 0; i < this.parameterSetData.parameterSetModels.length; i++) {
          let currentupdateParameterSetDataObj = {};
          if (Object.hasOwn(this.parameterSetData.parameterSetModels[i], 'id')) {
            currentupdateParameterSetDataObj = {
              id: this.parameterSetData.parameterSetModels[i].id,
              name: this.parameterSetData.parameterSetModels[i].name,
              description: this.parameterSetData.parameterSetModels[i].description,
              uuid: this.parameterSetData.parameterSetModels[i].uuid,
              parameterModels: [],
            };
          } else {
            currentupdateParameterSetDataObj = {
              name: this.parameterSetData.parameterSetModels[i].name,
              description: this.parameterSetData.parameterSetModels[i].description,
              parameterModels: [],
            };
          }

          const prop = { propKey: '', propValue: '' };

          prop.propKey = `udef.ds.${this.currentInputToEdit.name}.cols`;
          prop.propValue = JSON.stringify(this.currentInputToEdit.body.cols);
          currentupdateParameterSetDataObj.parameterModels.push(JSON.parse(JSON.stringify(prop)));

          prop.propKey = `udef.ds.${this.currentInputToEdit.name}.feed`;
          prop.propValue = this.currentInputToEdit.body.feed;
          currentupdateParameterSetDataObj.parameterModels.push(JSON.parse(JSON.stringify(prop)));

          prop.propKey = `udef.ds.${this.currentInputToEdit.name}.key`;
          prop.propValue = JSON.stringify(this.currentInputToEdit.body.key);
          currentupdateParameterSetDataObj.parameterModels.push(JSON.parse(JSON.stringify(prop)));

          prop.propKey = `udef.ds.${this.currentInputToEdit.name}.prov`;
          prop.propValue = this.currentInputToEdit.body.prov;
          currentupdateParameterSetDataObj.parameterModels.push(JSON.parse(JSON.stringify(prop)));

          prop.propKey = `udef.ds.${this.currentInputToEdit.name}.source`;
          prop.propValue = this.currentInputToEdit.body.source;
          currentupdateParameterSetDataObj.parameterModels.push(JSON.parse(JSON.stringify(prop)));

          // eslint-disable-next-line vue/no-mutating-props
          this.parameterSetData.parameterSetModels[i].parameterModels.push(...currentupdateParameterSetDataObj.parameterModels);

          if (Object.hasOwn(currentupdateParameterSetDataObj, 'id')) {
            currentupdateParameterSetData.add.push(currentupdateParameterSetDataObj);
          } else {
            const index = currentupdateParameterSetData.add.findIndex(p => p.name === currentupdateParameterSetDataObj.name);
            if (index >= 0) {
              currentupdateParameterSetData.add[index].parameterModels = _.unionBy(currentupdateParameterSetDataObj
                .parameterModels, currentupdateParameterSetData.add[index].parameterModels, 'propKey');
            } else {
              currentupdateParameterSetData.add.push(currentupdateParameterSetDataObj);
            }
          }
        }
        this.updateParameterSetData.add = currentupdateParameterSetData.add;
        this.$emit('newPublishBubbleAdded', this.parameterSetData, this.currentInputToEdit, this.node);
      }
    },
    isEditedAgain() {
      if (this.updateParameterSetData.add.length > 0) {
        const arr = [];
        for (let i = 0; i < this.updateParameterSetData.add.length; i++) {
          for (let j = 0; j < this.updateParameterSetData.add[i].parameterModels.length; j++) {
            const element = this.updateParameterSetData.add[i].parameterModels[j];
            if ((this.currentInputToEdit.name === this.currentInputToEdit.prevName)
              && element.propKey.startsWith(`udef.ds.${this.currentInputToEdit.name}`)) {
              return 0;
            }
            if (element.propKey.startsWith(`udef.ds.${this.currentInputToEdit.prevName}`)) {
              arr.push(i);
              break;
            }
          }
        }
        this.updateParameterSetData.add = this.updateParameterSetData.add.filter((_, index) => !arr.includes(index));
        return 1;
      }
      return -1;
    },
    disableTab(event) {
      // Prevent the default behavior for the tab key
      if (event.key === 'Tab') {
        event.preventDefault();
      }
    },
  },
};
</script>

<style>
.wf-edit-action {
  height: 96% !important;
  padding: 1%;
  overflow-y: auto;
  overflow-x: hidden;
}
.wf-edit-action form {
  height: 100% !important;
}
#fullscreen .disabled {
  pointer-events: none;
  cursor: no-drop;
  opacity: 0.5;
  display: block !important;
}
</style>
