<template>
  <div class="wf-edit-action">
    <!-- eslint-disable-next-line vue/no-lone-template -->
    <template>
      <mds-loader
        v-if="showLoader"
        size="small"
        aria-label="Small Loader"
      />
      <mds-notification-container v-if="showCopyBanner">
        <mds-notification
          key="error-tinted"
          :variation="bannerType"
          :title="bannerType"
          tinted
          :dismiss-delay="3000"
          @mds-notification-dismissed="showCopyBanner=false"
        >
          {{ bannerMessage }}
        </mds-notification>
      </mds-notification-container>
      <div v-if="['formula', 'qa', 'publish', 'save'].includes(node.title)">
        <mds-row>
          <mds-col :cols="9">
            <mds-combo-box
              v-model="parameterSetModelsVal"
              label="Choose Parameter Set:"
              :data-set="parameterSetModelsData"
            />
          </mds-col>
          <mds-col>
            <mds-button-container>
              <mds-button
                variation="primary"
                icon-right="gear"
                style="margin-top: 1.3rem"
                @click="generateFormula()"
              >
                Generate
              </mds-button>
              <mds-button
                variation="secondary"
                icon-right="open-new"
                style="margin-top: 1.3rem"
                :disabled="generatedFormula === ''"
                @click="copyFormula(generatedFormula === '')"
              >
                Copy
              </mds-button>
            </mds-button-container>
          </mds-col>
        </mds-row>
        <mds-row style="margin-top: 0.5rem">
          <mds-col :cols="11">
            <mds-row
              align-vertical="center"
            >
              <mds-col :cols="7">
                <h3>
                  <b>Formula Editor</b>
                </h3>
              </mds-col>
              <mds-col :cols="5">
                <mds-button-group
                  :content="buttonGroupContent"
                  aria-label="Theme"
                  style="margin: 8px; padding-left: 185px"
                  @mds-button-group-item-active="setActiveItem"
                />
              </mds-col>
            </mds-row>
            <mds-row>
              <div
                id="formula-editor"
                style="height: 600px; width: 100%;"
              />
            </mds-row>
          </mds-col>
        </mds-row>
      </div>
    </template>
  </div>
</template>

<script>
import { MdsButton, MdsButtonContainer } from '@mds/button';
import { MdsRow, MdsCol } from '@mds/layout-grid';
import { mapActions, mapGetters } from 'vuex';
import MdsComboBox from '@mds/combo-box';
import MdsButtonGroup from '@mds/button-group';
import MdsTextarea from '@mds/textarea';
import MdsAlert from '@mds/alert';
import { MdsNotification, MdsNotificationContainer } from '@mds/notification';
import loader from '@monaco-editor/loader';
import _ from 'lodash';
import WorkflowPreviewResultsVue from '../../../PreviewResults/WorkflowPreviewResults.vue';


const EOL = '\n';

export default {
  name: 'WorkflowQATestActions',
  components: {
    MdsRow,
    MdsCol,
    MdsButton,
    MdsComboBox,
    MdsButtonContainer,
    MdsNotification,
    MdsNotificationContainer,
    MdsTextarea,
    MdsAlert,
    WorkflowPreviewResultsVue,
    MdsButtonGroup,
  },
  props: {
    node: {
      type: Object,
      default: null,
    },
    formulas: {
      type: Array,
      default: null,
    },
    parameterSetData: {
      type: Object,
      default: null,
    },
    workflowData: {
      type: Object,
      default: null,
    },
    workflow: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      previewDataResponse: '',
      showTestLoader: false,
      showLoader: false,
      showCopyBanner: false,
      bannerMessage: '',
      bannerType: 'error',
      parameterSetModelsVal: [],
      currentNodeFormula: '',
      formula_id: '',
      parameterSetModelsData: [],
      currentFormula: '',
      generatedFormula: '',
      props: [],
      formulaTestResult: {
        statusCode: '',
        output: '',
      },
      editor: {},
      buttonGroupContent: [
        {
          id: '1', text: 'Light', active: true, val: 'vs',
        },
        {
          id: '2', text: 'Dark', active: false, val: 'vs-dark',
        },
        {
          id: '3', text: 'Contrast Dark', active: false, val: 'hc-black',
        },
        {
          id: '4', text: 'Contrast Light', active: false, val: 'hc-light',
        },
      ],
      editorTheme: 'vs',
    };
  },
  computed: {
    ...mapGetters('workflowModule', [
      'getParamStatus',
      'getWorkflowFormulas',
      'getEditableWorkflowDetails',
      'getEditableWorkflowUI',
      'getEditableParamSet',
      'getEditableFormulae',
      'getEditableNewFormulae',
      'getEditableNodes',
      'getEditableLinks',
      'getEditableTargets',
      'getCreateNewWorkflowDetails',
      'getCreateNewWorkflowUI',
      'getCreateNewParamSet',
      'getCreateNewFormulae',
      'getallWorkflowsForName',
      'getCurrentWorkflowManager',
    ]),
  },
  mounted() {
    this.init();
  },
  methods: {
    ...mapActions('workflowModule', ['updateUserWorkflowsDiagram', 'runJSFormula']),
    init() {
      if (['formula', 'qa'].includes(this.node.title)) {
        this.currentFormula = this.formulas.filter(f => f.uuid === this.node.data.formula_id)[0].formula;
        this.parameterSetModelsData = this.parameterSetData['parameterSetModels'].map(psd => ({
          text: psd.name,
          value: psd.name,
        }));
      }

      if (['publish', 'save'].includes(this.node.title)) {
        const formulaId = this.getCurrentWorkflowManager._priv.bubbles[this.node.id]._ins[0]._src._data.formula_id;
        this.currentFormula = this.formulas.filter(f => f.uuid === formulaId)[0].formula;
        this.parameterSetModelsData = this.parameterSetData['parameterSetModels'].map(psd => ({
          text: psd.name,
          value: psd.name,
        }));
      }
    },
    getFormattedDate() {
      const date = new Date();
      const year = date.getFullYear();
      let month = date.getMonth() + 1;
      let day = date.getDate();

      day = +day <= 9 ? `0${day}` : day;
      month = +month <= 9 ? `0${month}` : month;

      return [year, month, day].join('-');
    },
    generatePropMap(parameterSetModelArray = []) {
      const propMap = {};
      for (let i = 0; i < parameterSetModelArray.length; i++) {
        const prop = parameterSetModelArray[i];
        propMap[prop['propKey']] = prop['propValue'];
      }
      propMap['workflow.time_zone'] = this.workflow.timeZone;
      return propMap;
    },
    setActiveItem(event) {
      this.buttonGroupContent.forEach((item) => {
        if (item.id === event.target.id) {
          item.active = true;
          this.editor._themeService.setTheme(item.val);
          this.editorTheme = item.val;
        } else {
          item.active = false;
        }
      });
    },
    generateFormula() {
      this.props = this.parameterSetData['parameterSetModels']
        .filter(psm => psm.name === this.parameterSetModelsVal[0])[0].parameterModels;
      let code = this.currentFormula;
      if (['formula', 'qa'].includes(this.node.title)) {
        const propsMap = this.generatePropMap(this.props);
        const propMapKeys = Object.keys(propsMap);
        propMapKeys.forEach((key) => {
          const regex = new RegExp(
            `Parameters.(getFloat|getBool|getString|getJson|getDate)\\('(${key})'\\)`,
          );
          const found = regex.test(code);
          const match = regex.exec(code);

          if (found) {
            if (match[1] === 'getString') {
              code = code.replace(regex, `'${propsMap[key]}'`);
            } else if (match[1] === 'getBool') {
              code = code.replace(regex, propsMap[key] === 'true');
            } else if (match[1] === 'getFloat') {
              code = code.replace(regex, parseFloat(propsMap[key]));
            } else if (match[1] === 'getJson') {
              code = code.replace(regex, propsMap[key]);
            } else if (match[1] === 'getDate') {
              code = code.replace(regex, `IDate.create('${propsMap[key]}')`);
            }
          }
        });

        code = code.replace(
          /get_run_date\(\)/,
          `IDate.create('${this.getFormattedDate()}')`,
        );

        const suspendSaveFormulaRegex = /save_(curve|series)|(submit_pending_data)/gm;
        code = code.replace(suspendSaveFormulaRegex, '// $&');
      } else {
        code = `${this.currentFormula }// Inserted for debugging convenience. ${EOL}as.csv(union(final));`;
      }
      this.generatedFormula = code;

      if (code) {
        loader.init().then((monaco) => {
          const libUri = 'js/lim';
          monaco.editor.getModels().forEach(model => model.dispose());
          monaco.languages.typescript.javascriptDefaults.addExtraLib('', libUri);
          monaco.editor.createModel('', 'javscript', monaco.Uri.parse(libUri));

          const editor = monaco.editor.create(
            document.getElementById('formula-editor'), {
              value: this.generatedFormula.trim() + EOL,
              language: 'javascript',
              automaticLayout: true,
              wordWrap: 'bounded',
              theme: this.editorTheme,
              readOnly: true,
            },
          );

          editor.getModel().onDidChangeContent((event) => {
            this.generatedFormula = editor.getValue().trim();
          });
          this.editor = editor;
        });
      }
    },
    copyFormula(isDisabled) {
      if (isDisabled) {
        return false;
      }
      if (!navigator.clipboard) {
        this.bannerMessage = 'Clipboard API not supported';
        this.bannerType = 'error';
        this.showCopyBanner = true;
        return false;
      }
      navigator.clipboard.writeText(this.generatedFormula)
        .then(() => {
          this.bannerType = 'success';
          this.bannerMessage = 'Formula copied to clipboard!';
        })
        .catch((error) => {
          this.bannerType = 'error';
          this.bannerMessage = `Error copying Formula to clipboard: ${error}`;
        });
      this.showCopyBanner = true;
      return true;
    },
    testFormula() {
      this.showTestLoader = true;
      this.runJSFormula({ formulaPayload: this.generatedFormula })
        .then((response) => {
          this.previewDataResponse = response.data;
          this.formulaTestResult = {
            statusCode: response.status,
            output: response.data.split().map(d => d.trim()).join('\n'),
          };
          console.log(response.data);
        })
        .catch((err) => {
          this.formulaTestResult = {
            statusCode: err.response.status,
            output: err.response.data.trim(),
          };
        })
        .finally(() => {
          this.showTestLoader = false;
        });
    },
  },
};
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono&display=swap');
#fullscreen .mds-select___Mcd-ui {
  margin-top: 2% !important;
}
#fullscreen .btn-row {
  padding-top: 1%;
}
.wf-edit-action {
  height: 97% !important;
  padding: 1%;
}
.wf-edit-action form {
  height: 100% !important;
}
.formula-plane {
  margin-top: 1rem;
  border: 1px solid #1e1e1e;
}
.code {
  font-family: 'Roboto Mono', monospace !important;
}
.mds-textarea___Mcd-ui.mds-textarea--large___Mcd-ui {
  font-family: 'Roboto Mono', monospace !important;
  font-size: 18px;
  height: 90vh;
}
.formula-test-error {
  font-size: 20px;
}
#formula-editor {
  padding: 2rem;
  border: 1px solid rgb(191, 191, 191);
  border-radius: 10px;
  margin-left: 0.5rem;
  margin-top: 1rem;
  height: 450px;
}
</style>
