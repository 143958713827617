import axios from 'axios';
import templateString from '../../../utils/stringUtils';
import getAPIConfigs from './config.js';

const config = getAPIConfigs();

const companyName = '';
let replacement;

const actions = {
  getCompanyList({ commit }) {
    return axios
      .get(templateString(config.GET_ALL_COMPANIES))
      .then((response) => {
        commit('SET_COMPANY_LIST', response.data);
        return response;
      })
      .catch(error => error.response);
  },

  getAllUsersForCompany({ commit }, company) {
    replacement = {
      COMPANY: company,
    };

    return axios
      .get(templateString(config.GET_COMPANY_USERS, replacement)).then((response) => {
        commit('COMPANY_USERS', response.data);
        return response;
      }).catch(errors => errors);
  },

  getCompanyUserReport({ commit }, company) {
    replacement = {
      COMPANY: company,
    };

    return axios
      .get(templateString(config.GET_USER_REPORT_BY_COMPANY_NAME, replacement)).then((response) => {
        commit('COMPANY_USERS', response.data);
        return response;
      }).catch(errors => errors);
  },
};


export default actions;
