<template>
  <div id="notfullscreen">
    <mds-modal
      v-model="toggleFullscreen"
      :title="actionsName"
      :disable-focus-trap="true"
      :action-required="true"
      @mds-modal-dismissed="dissmissModel"
    >
      <template #mds-modal-actions>
        <mds-button-container right-aligned>
          <mds-button
            variation="icon-only"
            icon="remove"
            type="button"
            @click="doDismissModel"
          >
            Close
          </mds-button>
        </mds-button-container>
      </template>
      <mds-tabs
        :content="tabsContent"
        @mds-tabs-item-active="setActiveItem"
      />
      <div v-if="tabsContent[0].active">
        <div
          v-if="node.title === 'data'"
          class="tab-action"
        >
          <workflow-data-edit-actions
            :node="node"
            :workflow-data="workflowData"
            :node-var-name-edit="nodeVarName"
            :parameter-set-data="parameterSetData"
            :workflow="workflow"
            :bad-formula-bubbles="badFormulaBubbles"
            :is-new-node="isNewNode"
            :formulas="formulas"
            :is-editable="isEditable"
            @dataBubbleEdited="dataBubbleEdited"
            @newDataBubbleAdded="newDataBubbleAdded"
            @gotFormulaError="gotFormulaError"
          />
        </div>
        <div
          v-if="node.title === 'qa'"
          class="tab-action"
        >
          <mds-notification-container v-if="showFormulaValidationBanner">
            <mds-notification
              key="error-tinted"
              variation="error"
              title="Formula Validation Error"
              tinted
              :dismiss-delay="3000"
              @mds-notification-dismissed="showFormulaValidationBanner=false"
            >
              {{ formulaValidationErrorMessage }}
            </mds-notification>
          </mds-notification-container>
          <workflow-q-a-edit-actions
            ref="qaEditRef"
            :node="node"
            :workflow-data="workflowData"
            :node-var-name-edit="nodeVarName"
            :parameter-set-data="parameterSetData"
            :workflow="workflow"
            :is-new-node="isNewNode"
            :bad-formula-bubbles="badFormulaBubbles"
            :formulas="formulas"
            :is-editable="isEditable"
            @newQAFormulaAdded="newQAFormulaAdded"
            @showPreviewResults="showPreviewResults"
            @diagramChanged="diagramChanged"
            @gotFormulaError="gotFormulaError"
            @formula-modified="formulaModified"
            @formula-saved="formulaSaved"
            @formula-save-cancle="formulaSaveCancle"
          />
        </div>
        <div
          v-if="node.title === 'publish' || node.title === 'save'"
          class="tab-action"
        >
          <workflow-publish-edit-actions
            :node="node"
            :workflow-data="workflowData"
            :node-var-name-edit="nodeVarName"
            :parameter-set-data="parameterSetData"
            :workflow="workflow"
            :is-new-node="isNewNode"
            :formulas="formulas"
            :is-editable="isEditable"
            :bad-formula-bubbles="badFormulaBubbles"
            @publishBubbleEdited="publishBubbleEdited"
            @newPublishBubbleAdded="newPublishBubbleAdded"
            @gotFormulaError="gotFormulaError"
          />
        </div>
        <div
          v-if="node.title === 'formula'"
          class="tab-action"
        >
          <mds-notification-container v-if="showFormulaValidationBanner">
            <mds-notification
              key="error-tinted"
              variation="error"
              title="Formula Validation Error"
              tinted
              :dismiss-delay="3000"
              @mds-notification-dismissed="showFormulaValidationBanner=false"
            >
              {{ formulaValidationErrorMessage }}
            </mds-notification>
          </mds-notification-container>
          <workflow-formula-edit-actions
            ref="formulaEditRef"
            :node="node"
            :workflow-data="workflowData"
            :node-var-name-edit="nodeVarName"
            :parameter-set-data="parameterSetData"
            :workflow="workflow"
            :is-new-node="isNewNode"
            :formulas="formulas"
            :is-editable="isEditable"
            :bad-formula-bubbles="badFormulaBubbles"
            @newFormulaAdded="newFormulaAdded"
            @showPreviewResults="showPreviewResults"
            @clearPreviewDropDown="clearPreviewDropDown"
            @diagramChanged="diagramChanged"
            @gotFormulaError="gotFormulaError"
            @formula-modified="formulaModified"
            @formula-saved="formulaSaved"
            @formula-save-cancle="formulaSaveCancle"
          />
        </div>
        <div
          v-if="node.title === 'notification'"
          class="tab-action"
        >
          <workflow-notification-edit-action
            :node="node"
            :workflow-data="workflowData"
            :node-var-name-edit="nodeVarName"
            :parameter-set-data="parameterSetData"
            :workflow="workflow"
            :is-new-node="isNewNode"
            :formulas="formulas"
            :is-editable="isEditable"
            @notificationEdit="notificationEdit"
          />
        </div>
        <div
          v-if="node.title === 'single_var' || node.title === 'variable'"
          class="tab-action"
        >
          <workflow-single-var-edit-action
            :node="node"
            :workflow-data="workflowData"
            :node-var-name-edit="nodeVarName"
            :parameter-set-data="parameterSetData"
            :workflow="workflow"
            :is-new-node="isNewNode"
            :bad-formula-bubbles="badFormulaBubbles"
            :formulas="formulas"
            :is-editable="isEditable"
            @variableBubbleEdited="variableBubbleEdited"
            @newVariableBubbleAdded="newVariableBubbleAdded"
            @gotFormulaError="gotFormulaError"
          />
        </div>
      </div>
      <div v-if="tabsContent[1] && tabsContent[1].active">
        <div
          v-if="node.title === 'qa'"
          class="tab-action"
        >
          <workflow-formula-prevres-action
            :node="node"
            :workflow-data="workflowData"
            :node-var-name-edit="nodeVarName"
            :parameter-set-data="parameterSetData"
            :formulas="formulas"
            :workflow="workflow"
            :initial-data="initialPreviewDropdownData"
            @clearPreviewDropDown="clearPreviewDropDown"
          />
        </div>
        <div
          v-if="node.title === 'publish' || node.title === 'save'"
          class="tab-action"
        >
          <workflow-formula-prevres-action
            :node="node"
            :workflow-data="workflowData"
            :node-var-name-edit="nodeVarName"
            :parameter-set-data="parameterSetData"
            :formulas="formulas"
            :workflow="workflow"
            @clearPreviewDropDown="clearPreviewDropDown"
          />
        </div>
        <div
          v-if="node.title === 'formula'"
          class="tab-action"
        >
          <workflow-formula-prevres-action
            :node="node"
            :workflow-data="workflowData"
            :parameter-set-data="parameterSetData"
            :formulas="formulas"
            :workflow="workflow"
            :initial-data="initialPreviewDropdownData"
            @clearPreviewDropDown="clearPreviewDropDown"
          />
        </div>
        <div
          v-if="node.title === 'notification'"
          class="tab-action"
        >
          <workflow-notification-launch-phase-action
            :node="node"
            :workflow-data="workflowData"
            :node-var-name-edit="nodeVarName"
            :parameter-set-data="parameterSetData"
            :workflow="workflow"
            :is-new-node="isNewNode"
            :is-editable="isEditable"
            :formulas="formulas"
          />
        </div>
      </div>
      <div v-if="tabsContent[2] && tabsContent[2].active">
        <div
          v-if="node.title === 'qa'"
          class="tab-action"
        >
          <workflow-q-a-launch-phase-actions
            v-if="tabsContent[2].id === 'launchThisPhase'"
            :node="node"
            :workflow-data="workflowData"
            :node-var-name-edit="nodeVarName"
            :parameter-set-data="parameterSetData"
            :workflow="workflow"
          />
          <workflow-q-a-debug-actions
            v-if="tabsContent[2].id === 'debug'"
            :node="node"
            :workflow-data="workflowData"
            :node-var-name-edit="nodeVarName"
            :parameter-set-data="parameterSetData"
            :workflow="workflow"
            :is-new-node="isNewNode"
            :formulas="formulas"
            :is-editable="isEditable"
          />
          <workflow-q-a-test-actions
            v-if="tabsContent[2].id === 'test'"
            :node="node"
            :workflow-data="workflowData"
            :node-var-name-edit="nodeVarName"
            :parameter-set-data="parameterSetData"
            :workflow="workflow"
            :formulas="formulas"
          />
        </div>
        <div
          v-if="node.title === 'formula'"
          class="tab-action"
        >
          <workflow-formula-launch-phase-actions
            v-if="tabsContent[2].id === 'launchThisPhase'"
            :node="node"
            :node-var-name-edit="nodeVarName"
            :parameter-set-data="parameterSetData"
            :is-editable="isEditable"
            :workflow="workflow"
          />
          <workflow-formula-launch-history-actions
            v-if="tabsContent[2].id === 'launchHistory'"
            :node="node"
            :workflow-data="workflowData"
            :node-var-name-edit="nodeVarName"
            :parameter-set-data="parameterSetData"
            :workflow="workflow"
          />
          <workflow-q-a-debug-actions
            v-if="tabsContent[2].id === 'debug'"
            :node="node"
            :workflow-data="workflowData"
            :node-var-name-edit="nodeVarName"
            :parameter-set-data="parameterSetData"
            :workflow="workflow"
            :is-new-node="isNewNode"
            :formulas="formulas"
            :is-editable="isEditable"
          />
        </div>
        <div
          v-if="node.title === 'publish' || node.title === 'save'"
          class="tab-action"
        >
          <workflow-formula-launch-phase-actions
            v-if="tabsContent[2].id === 'launchThisPhase'"
            :node="node"
            :workflow-data="workflowData"
            :node-var-name-edit="nodeVarName"
            :parameter-set-data="parameterSetData"
            :workflow="workflow"
            :is-editable="isEditable"
          />
          <workflow-q-a-debug-actions
            v-if="tabsContent[2].id === 'debug'"
            :node="node"
            :workflow-data="workflowData"
            :node-var-name-edit="nodeVarName"
            :parameter-set-data="parameterSetData"
            :workflow="workflow"
            :is-new-node="isNewNode"
            :formulas="formulas"
            :is-editable="isEditable"
          />
        </div>
      </div>
      <div v-if="tabsContent[3] && tabsContent[3].active">
        <div
          v-if="node.title === 'qa'"
          class="tab-action"
        >
          <workflow-q-a-debug-actions
            v-if="tabsContent[3].id === 'debug'"
            :node="node"
            :workflow-data="workflowData"
            :node-var-name-edit="nodeVarName"
            :parameter-set-data="parameterSetData"
            :workflow="workflow"
            :is-new-node="isNewNode"
            :formulas="formulas"
            :is-editable="isEditable"
          />
          <workflow-q-a-test-actions
            v-if="tabsContent[3].id === 'test'"
            :node="node"
            :workflow-data="workflowData"
            :node-var-name-edit="nodeVarName"
            :parameter-set-data="parameterSetData"
            :workflow="workflow"
            :formulas="formulas"
          />
        </div>
        <div
          v-if="node.title === 'formula'"
          class="tab-action"
        >
          <workflow-formula-launch-history-actions
            v-if="tabsContent[3].id === 'launchHistory'"
            :node="node"
            :workflow-data="workflowData"
            :node-var-name-edit="nodeVarName"
            :parameter-set-data="parameterSetData"
            :workflow="workflow"
            :index="index"
            @apiCallsCompleted="handleApiCallsCompleted"
            @launchHistryStart="handleLaunchHistryStart"
          />
          <workflow-q-a-debug-actions
            v-if="tabsContent[3].id === 'debug'"
            :node="node"
            :workflow-data="workflowData"
            :node-var-name-edit="nodeVarName"
            :parameter-set-data="parameterSetData"
            :workflow="workflow"
            :is-new-node="isNewNode"
            :formulas="formulas"
            :is-editable="isEditable"
          />
          <workflow-q-a-test-actions
            v-if="tabsContent[3].id === 'test'"
            :node="node"
            :workflow-data="workflowData"
            :node-var-name-edit="nodeVarName"
            :parameter-set-data="parameterSetData"
            :workflow="workflow"
            :formulas="formulas"
          />
        </div>
        <div
          v-if="node.title === 'publish' || node.title === 'save'"
          class="tab-action"
        >
          <workflow-q-a-debug-actions
            v-if="tabsContent[3].id === 'debug'"
            :node="node"
            :workflow-data="workflowData"
            :node-var-name-edit="nodeVarName"
            :parameter-set-data="parameterSetData"
            :workflow="workflow"
            :is-new-node="isNewNode"
            :formulas="formulas"
            :is-editable="isEditable"
          />
          <workflow-q-a-test-actions
            v-if="tabsContent[3].id === 'test'"
            :node="node"
            :workflow-data="workflowData"
            :node-var-name-edit="nodeVarName"
            :parameter-set-data="parameterSetData"
            :workflow="workflow"
            :formulas="formulas"
          />
        </div>
      </div>
      <div v-if="tabsContent[4] && tabsContent[4].active">
        <div
          v-if="node.title === 'qa'"
          class="tab-action"
        >
          <workflow-q-a-test-actions
            v-if="tabsContent[4].id === 'test'"
            :node="node"
            :workflow-data="workflowData"
            :node-var-name-edit="nodeVarName"
            :parameter-set-data="parameterSetData"
            :workflow="workflow"
            :formulas="formulas"
          />
        </div>
        <div
          v-if="node.title === 'formula'"
          class="tab-action"
        >
          <workflow-q-a-debug-actions
            v-if="tabsContent[4].id === 'debug'"
            :node="node"
            :workflow-data="workflowData"
            :node-var-name-edit="nodeVarName"
            :parameter-set-data="parameterSetData"
            :workflow="workflow"
            :is-new-node="isNewNode"
            :formulas="formulas"
            :is-editable="isEditable"
          />
          <workflow-q-a-test-actions
            v-if="tabsContent[4].id === 'test'"
            :node="node"
            :workflow-data="workflowData"
            :node-var-name-edit="nodeVarName"
            :parameter-set-data="parameterSetData"
            :workflow="workflow"
            :formulas="formulas"
          />
        </div>
        <div
          v-if="node.title === 'publish' || node.title === 'save'"
          class="tab-action"
        >
          <workflow-q-a-test-actions
            v-if="tabsContent[4].id === 'test'"
            :node="node"
            :workflow-data="workflowData"
            :node-var-name-edit="nodeVarName"
            :parameter-set-data="parameterSetData"
            :workflow="workflow"
            :formulas="formulas"
          />
        </div>
      </div>
      <div v-if="tabsContent[5] && tabsContent[5].active">
        <div
          v-if="node.title === 'formula'"
          class="tab-action"
        >
          <workflow-q-a-test-actions
            :node="node"
            :workflow-data="workflowData"
            :node-var-name-edit="nodeVarName"
            :parameter-set-data="parameterSetData"
            :workflow="workflow"
            :formulas="formulas"
          />
        </div>
      </div>
    </mds-modal>
    <mds-dialog
      v-if="showConfirmationDialog"
      v-model="showConfirmationDialog"
      action-required
      title="Are you sure?"
    >
      {{ $t('labels.workflowManager.jobsAreRunning') }}
      <template #mds-dialog-actions-right>
        <mds-button-container right-aligned>
          <mds-button
            type="button"
            variation="secondary"
            @click="showConfirmationDialog=!showConfirmationDialog"
          >
            Cancel
          </mds-button>
          <mds-button
            type="button"
            variation="primary"
            @click="toggleFullscreen = false"
          >
            Yes, Proceed
          </mds-button>
        </mds-button-container>
      </template>
    </mds-dialog>
    <mds-dialog
      v-if="showTabSwitchDialog"
      v-model="showTabSwitchDialog"
      action-required
      title="Are you sure?"
    >
      {{ $t('labels.workflowManager.jobsAreRunning') }}
      <template #mds-dialog-actions-right>
        <mds-button-container right-aligned>
          <mds-button
            type="button"
            variation="secondary"
            @click="showTabSwitchDialog=!showTabSwitchDialog"
          >
            Cancel
          </mds-button>
          <mds-button
            type="button"
            variation="primary"
            @click="switchTab"
          >
            Yes, Proceed
          </mds-button>
        </mds-button-container>
      </template>
    </mds-dialog>
  </div>
</template>

<script>
import MdsModal from '@mds/modal';
import MdsDialog from '@mds/dialog';
import { MdsTabs, MdsTabsItem } from '@mds/tabs';
import { MdsButtonContainer, MdsButton } from '@mds/button';
import { MdsNotification, MdsNotificationContainer } from '@mds/notification';
import { mapGetters, mapActions } from 'vuex';
import WorkflowDataEditActions from './WorkflowDataEditActions.vue';
import WorkflowQAEditActions from './WorkflowQAEditActions.vue';
import WorkflowQALaunchPhaseActions from './WorkflowQALaunchPhaseActions.vue';
import WorkflowQADebugActions from './WorkflowQADebugActions.vue';
import WorkflowQATestActions from './WorkflowQATestActions.vue';
import WorkflowFormulaEditActions from './WorkflowFormulaEditActions.vue';
import WorkflowFormulaLaunchPhaseActions from './WorkflowFormulaLaunchPhaseActions.vue';
import WorkflowPublishEditActions from './WorkflowPublishEditActions.vue';
import WorkflowNotificationEditAction from './WorkflowNotificationEditAction.vue';
import WorkflowNotificationLaunchPhaseAction from './WorkflowNotificationLaunchPhaseAction.vue';
import WorkflowFormulaPrevresAction from './WorkflowFormulaPrevresAction.vue';
import WorkflowSingleVarEditAction from './WorkflowSingleVarEditAction.vue';
import WorkflowFormulaLaunchHistoryActions from './WorkflowFormulaLaunchHistoryActions.vue';
import { getUserName, getUserRoles } from '../../../../../utils/authService';
import commonUtils from '../../../scripts/commonUtils';

export default {
  name: 'WorkflowActions',
  components: {
    MdsModal,
    MdsDialog,
    MdsButtonContainer,
    MdsButton,
    MdsTabs,
    WorkflowDataEditActions,
    WorkflowQAEditActions,
    WorkflowQALaunchPhaseActions,
    WorkflowQADebugActions,
    WorkflowFormulaEditActions,
    WorkflowPublishEditActions,
    WorkflowNotificationEditAction,
    WorkflowNotificationLaunchPhaseAction,
    WorkflowSingleVarEditAction,
    WorkflowFormulaPrevresAction,
    WorkflowFormulaLaunchPhaseActions,
    WorkflowQATestActions,
    WorkflowFormulaLaunchHistoryActions,
    MdsNotification,
    MdsNotificationContainer,
  },
  props: {
    node: {
      type: Object,
      default: null,
    },
    workflowData: {
      type: Object,
      default: null,
    },
    nodeVarName: {
      type: String,
      default: null,
    },
    formulas: {
      type: Array,
      default: null,
    },
    parameterSetData: {
      type: Object,
      default: null,
    },
    workflow: {
      type: Object,
      required: false,
      default: null,
    },
    isNewNode: {
      type: Boolean,
      required: false,
      default: false,
    },
    isEditable: {
      type: Boolean,
      required: false,
      default: false,
    },
    badFormulaBubbles: {
      type: Array,
      default: null,
    },
    index: {
      type: Number,
      default: -2,
    },
  },
  data() {
    return {
      toggleFullscreen: true,
      actionsName: '',
      tabsContent: [{
        text: this.isEditable ? 'Edit' : 'View',
        id: 'edit',
        active: true,
      }],
      initialPreviewDropdownData: {
        inputName: [],
        previewVars: [],
        type: '',
      },
      formulaModify: false,
      prevEventId: '',
      showConfirmationDialog: false,
      showTabSwitchDialog: false,
      tabToBeSwitched: '',
      formulaValidationErrorMessage: '',
      showFormulaValidationBanner: false,
    };
  },
  computed: {
    ...mapGetters('workflowModule', ['getCurrentWorkflowManager', 'getFormulaDataNodeSet', 'getUnsavedFormula']),
  },
  watch: {
    toggleFullscreen(value) {
      if (!value) {
        this.$emit('closeEdit', this.index);
      }
    },
  },
  beforeMount() {
    this.addUnsavedFormula('');
  },
  beforeDestroy() {
    this.addUnsavedFormula('');
  },
  mounted() {
    this.init();
  },
  methods: {
    ...mapActions('workflowModule', [
      'updateUserWorkflowsDiagram', 'getWorkflowStatus', 'getParamStatus', 'setFormulaDataNodeSet', 'addUnsavedFormula',
    ]),
    doDismissModel() {
      if (
        this.node.title === 'formula'
        && this.tabsContent[3]
        && this.tabsContent[3].active
        && this.tabsContent[3].id === 'launchHistory'
      ) {
        this.showConfirmationDialog = true;
        return;
      }
      this.toggleFullscreen = false;
    },
    init() {
      this.processTabContent();
      this.actionsName = `${this.node.title.toUpperCase() } - Actions ${this.node.data.targetName
        ? this.node.data.targetName
        : this.nodeVarName.replace(/[^a-zA-Z0-9_]/g, '')}`;
    },
    setActiveItem(event) {
      if (this.node.title === 'formula'
        && this.tabsContent[3]
        && this.tabsContent[3].active
        && this.tabsContent[3].id === 'launchHistory'
      ) {
        this.showTabSwitchDialog = true;
        this.tabToBeSwitched = event.currentTarget.id;
        return;
      }
      this.showTabSwitchDialog = false;

      if ((this.node.title === 'qa' || this.node.title === 'formula') && this.tabsContent[0] && this.tabsContent[0].active) {
        if (this.getUnsavedFormula.length > 0) {
          const formulaSyntaxErrorMessage = commonUtils.checkFormulaSyntaxError(this.getUnsavedFormula);
          if (formulaSyntaxErrorMessage) {
            this.showFormulaValidationBanner = true;
            this.formulaValidationErrorMessage = formulaSyntaxErrorMessage;
            return;
          }
        }
      }
      this.tabsContent.forEach((item) => {
        if (item.id === event.currentTarget.id) {
          if ((this.node.title === 'qa' || this.node.title === 'formula')
          && event.currentTarget.id !== 'edit' && this.formulaModify) {
            this.prevEventId = event.currentTarget.id;
            if (this.node.title === 'formula') {
              this.$refs.formulaEditRef.showFormulaSaveModal = true;
              this.$refs.formulaEditRef.showFormulaSaveModalFlag = true;
            } else if (this.node.title === 'qa') {
              this.$refs.qaEditRef.showFormulaSaveModal = true;
              this.$refs.qaEditRef.showFormulaSaveModalFlag = true;
            }
            item.active = false;
            this.tabsContent[0].active = true;
          } else {
            item.active = true;
          }
        } else {
          item.active = false;
        }
      });
    },
    switchTab() {
      this.tabsContent.forEach((item) => {
        if (item.id === this.tabToBeSwitched) {
          if ((this.node.title === 'qa' || this.node.title === 'formula')
          && this.tabToBeSwitched !== 'edit' && this.formulaModify) {
            this.prevEventId = this.tabToBeSwitched;
            if (this.node.title === 'formula') {
              this.$refs.formulaEditRef.showFormulaSaveModal = true;
              this.$refs.formulaEditRef.showFormulaSaveModalFlag = true;
            } else if (this.node.title === 'qa') {
              this.$refs.qaEditRef.showFormulaSaveModal = true;
              this.$refs.qaEditRef.showFormulaSaveModalFlag = true;
            }
            item.active = false;
            this.tabsContent[0].active = true;
          } else {
            item.active = true;
          }
        } else {
          item.active = false;
        }
      });
      this.showTabSwitchDialog = false;
    },
    processTabContent() {
      const tabsContent = [{
        text: this.isEditable ? 'Edit' : 'View',
        id: 'edit',
        active: true,
        isShow: false,
      },
      {
        text: 'Preview results',
        id: 'previewResults',
        active: false,
        isShow: false,
      },
      {
        text: 'Launch this phase',
        id: 'launchThisPhase',
        active: false,
        isShow: false,
      },
      {
        text: 'Launch history',
        id: 'launchHistory',
        active: false,
        isShow: false,
      },
      {
        text: 'Debug',
        id: 'debug',
        active: false,
        isShow: false,
      },
      {
        text: 'Test',
        id: 'test',
        active: false,
        isShow: false,
      }];
      this.tabsContent = tabsContent;
      const userName = getUserName();
      let allowedOps;
      if (this.node.title === 'data') {
        allowedOps = [0];
      } else if (this.node.title === 'qa') {
        if (getUserRoles().includes('ROLE_LDS_ADMIN') || (userName.toLowerCase() === this.workflow.owner.toLowerCase())) {
          allowedOps = [0, 1, 2, 4, 5];
        } else if (this.workflow.permissions.includes('edit')) {
          allowedOps = [0, 1, 4, 5];
        } else {
          allowedOps = [0, 1];
        }
      } else if (this.node.title === 'formula') {
        if (getUserRoles().includes('ROLE_LDS_ADMIN') || (userName.toLowerCase() === this.workflow.owner.toLowerCase())) {
          allowedOps = [0, 1, 2, 3, 4, 5];
        } else if (this.workflow.permissions.includes('edit')) {
          allowedOps = [0, 1, 4, 5];
        } else {
          allowedOps = [0, 1];
        }
      } else if (this.node.title === 'publish' || this.node.title === 'save') {
        if (getUserRoles().includes('ROLE_LDS_ADMIN') || (userName.toLowerCase() === this.workflow.owner.toLowerCase())) {
          allowedOps = [0, 1, 2, 4, 5];
        } else if (this.workflow.permissions.includes('edit')) {
          allowedOps = [0, 1, 4, 5];
        } else {
          allowedOps = [0, 1];
        }
      } else if (this.node.title === 'notification') {
        if (getUserRoles().includes('ROLE_LDS_ADMIN') || (userName.toLowerCase() === this.workflow.owner.toLowerCase())) {
          allowedOps = [0, 2];
        } else {
          allowedOps = [0];
        }
      } else if (this.node.title === 'single_var' || this.node.title === 'variable') {
        allowedOps = [0];
      } else if (this.node.title === 'apmq_task') {
        allowedOps = [0];
      } else {
        allowedOps = [0];
      }

      for (let i = 0; i < allowedOps.length; i++) {
        this.tabsContent[allowedOps[i]].isShow = true;
      }

      this.tabsContent = this.tabsContent.filter(item => item.isShow);
    },
    showPreviewResults(dataNode) {
      this.initialPreviewDropdownData = {
        previewVars: [dataNode._name],
        type: ['Latest Available'],
      };
      this.tabsContent.forEach((item) => {
        if (item.id === 'previewResults') {
          item.active = true;
        } else {
          item.active = false;
        }
      });
    },
    clearPreviewDropDown() {
      this.initialPreviewDropdownData = {
        inputName: [],
        previewVars: [],
        type: '',
      };
    },
    newFormulaAdded(objformula, node) {
      this.$emit('newFormulaAdded', objformula, node);
    },
    newQAFormulaAdded(objformula, node) {
      this.$emit('newQAFormulaAdded', objformula, node);
    },
    notificationEdit(node) {
      this.$emit('notificationEdit', this.node);
    },
    dataBubbleEdited(parameterSetData, currentInputToEdit, node) {
      this.$emit('dataBubbleEdited', parameterSetData, currentInputToEdit, node);
    },
    newDataBubbleAdded(parameterSetData, currentInputToEdit, node) {
      this.$emit('newDataBubbleAdded', parameterSetData, currentInputToEdit, node);
    },
    publishBubbleEdited(parameterSetData, currentInputToEdit, node) {
      this.$emit('publishBubbleEdited', parameterSetData, currentInputToEdit, node);
    },
    newPublishBubbleAdded(parameterSetData, currentInputToEdit, node) {
      this.$emit('newPublishBubbleAdded', parameterSetData, currentInputToEdit, node);
    },
    variableBubbleEdited(parameterSetData, currentInputToEdit, node) {
      this.$emit('variableBubbleEdited', parameterSetData, currentInputToEdit, node);
    },
    newVariableBubbleAdded(parameterSetData, currentInputToEdit, node) {
      this.$emit('newVariableBubbleAdded', parameterSetData, currentInputToEdit, node);
    },
    gotFormulaError(badFormulaBubbles, errorMessage) {
      this.$emit('gotFormulaError', badFormulaBubbles, errorMessage);
    },
    diagramChanged() {
      this.$emit('diagramChanged');
    },
    dissmissModel(e) {
      // this.setFormulaDataNodeSet('', []);
      this.$emit('closeEdit');
    },
    formulaModified() {
      // this.formulaModify = true;
    },
    performTabSwitch() {
      this.tabsContent.forEach((item) => {
        if (item.id === this.prevEventId) {
          item.active = true;
        } else {
          item.active = false;
        }
      });
    },
    formulaSaved() {
      this.formulaModify = false;
      this.performTabSwitch();
    },
    formulaSaveCancle() {
      this.formulaModify = false;
      this.performTabSwitch();
    },
    handleApiCallsCompleted() {
      this.$emit('apiCallsCompleted');
    },
    handleLaunchHistryStart() {
      console.log('called - action');
      this.$emit('launchHistryStart');
    },
  },
};
</script>

<style>
  #fullscreen #notfullscreen {
    cursor: auto;
  }

  #fullscreen #notfullscreen .mds-section___Mcd-ui{
    position: relative;
    resize:both;
    height: 80vh;
    width:80vw;
    min-height: auto !important;
    margin: auto !important;
    margin-top: 5% !important;
  }
  #fullscreen #notfullscreen .mds-section__content___Mcd-ui {
    padding-top: 3px;
  }
  .tab-action {
    position: absolute;
    height: 80%;
    width: 97%;
  }
</style>
