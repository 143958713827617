<template>
  <div class="entitlements-slidein">
    <mds-section
      border="none"
      title="Search Workflows"
      bold
    >
      <template #mds-section-actions>
        <mds-button-container right-aligned>
          <mds-button
            type="button"
            variation="icon-only"
            icon="remove"
            @click="closeModal"
          >
            Close Modal
          </mds-button>
        </mds-button-container>
      </template>
      <mds-layout-grid>
        <mds-row>
          <mds-col :cols="4">
            <mds-form size="small">
              <mds-input
                v-model="searchWorkflowName"
                size="small"
                label="Workflow"
                placeholder="partial name (wildcards '*' and '?' are supported)"
                @keyup.enter="getWorkflows"
              />
              <mds-input
                v-model="searchWorkflowFeed"
                size="small"
                label="Feed"
                placeholder="partial name (wildcards '*' and '?' are supported)"
                @keyup.enter="getWorkflows"
              />
              <mds-input
                v-model="searchWorkflowSymbol"
                size="small"
                label="Symbol"
                placeholder="partial name (wildcards '*' and '?' are supported)"
                @keyup.enter="getWorkflows"
              />
              <mds-input
                v-model="searchWorkflowOwner"
                size="small"
                label="Owner"
                placeholder="partial name (wildcards '*' and '?' are supported)"
                @keyup.enter="getWorkflows"
              />
              <mds-input
                v-model="searchWorkflowCompany"
                size="small"
                label="Company"
                placeholder="partial name (wildcards '*' and '?' are supported)"
                @keyup.enter="getWorkflows"
              />
              <mds-input
                v-model="searchWorkflowID"
                size="small"
                label="Workflow ID"
                placeholder="Workflow id e.g. 1234"
                @keyup.enter="getWorkflows"
              />
              <mds-input
                v-model="searchWorkflowFormula"
                size="small"
                label="Formula"
                placeholder="Formula containing a method e.g. monthly_average()"
                @keyup.enter="getWorkflows"
              />
            </mds-form>
            <div class="btn-container-serach">
              <mds-button-container>
                <mds-button
                  variation="primary"
                  size="small"
                  @click="getWorkflows"
                >
                  Submit
                </mds-button>
                <mds-button
                  variation="secondary"
                  size="small"
                  @click="clearFilters"
                >
                  Clear
                </mds-button>
              </mds-button-container>
            </div>
          </mds-col>
          <mds-col :cols="8">
            <div v-if="showSearchLoader">
              <mds-loader size="large" />
            </div>
            <div v-else-if="showSearchTable">
              <mds-table>
                <mds-thead>
                  <mds-th
                    v-for="(header, index) in searchTableHeaders"
                    :key="index"
                    style="padding-left: 30px;"
                  >
                    {{ header.text }}
                  </mds-th>
                </mds-thead>
              </mds-table>
              <mds-table
                multiselection
                row-hover
              >
                <mds-thead
                  hidden-header
                >
                  <mds-th
                    v-for="(header, index) in searchTableHeaders"
                    :key="index"
                  >
                    {{ header.text }}
                  </mds-th>
                </mds-thead>
                <mds-tbody>
                  <mds-tr
                    v-for="(row, index) in paginatedData"
                    :key="index"
                    :checked="row.checked"
                    :multiselection-label="row.name"
                    @mds-tr-multiselection-changed="handleRowSelectEvent(index, $event)"
                  >
                    <mds-td
                      v-for="(header, i) in searchTableHeaders"
                      :key="i"
                    >
                      {{ row[header.fieldName] }}
                    </mds-td>
                  </mds-tr>
                </mds-tbody>
              </mds-table>
              <mds-pagination
                :total-items="searchedWorkflowRows.length"
                show-items-info
                :page-sizes="pageSize"
                @mds-pagination-page-changed="handlePageChange"
              />
              <mds-button-container
                style="padding-top: 10px;"
              >
                <mds-button
                  variation="primary"
                  :disabled="workflowsToAdd.length == 0 && workflowsToRemove.length == 0"
                  @click="showSaveWorkflowsModal(workflowsToAdd.length == 0 && workflowsToRemove.length == 0)"
                >
                  Save
                </mds-button>
                <mds-button
                  variation="secondary"
                  @click="closeModal"
                >
                  Close
                </mds-button>
              </mds-button-container>
            </div>
            <div v-else>
              <mds-empty-state
                title="No Results"
                message="Try adjusting your search query."
                :list="[
                  'Try removing filters.',
                  'Try different keywords.'
                ]"
                size="large"
                style="margin: auto;"
              />
            </div>
          </mds-col>
        </mds-row>
      </mds-layout-grid>
      <mds-modal
        v-model="toggleSaveModal"
        title="Save Workflows"
        :width="'600px'"
        action-required
      >
        <template #mds-modal-actions>
          <mds-button-container right-aligned>
            <mds-button
              variation="secondary"
              @click="toggleSaveModal = !toggleSaveModal"
            >
              Cancel
            </mds-button>
            <mds-button
              variation="primary"
              @click="saveWorkflows"
            >
              Save
            </mds-button>
          </mds-button-container>
        </template>
        <mds-table v-if="workflowsToAdd.length > 0">
          <mds-thead>
            <mds-th> Adding below workflows </mds-th>
          </mds-thead>
          <mds-tbody>
            <mds-tr
              v-for="(row, index) in workflowsToAdd"
              :key="index + row.name + row.id"
            >
              <mds-td
                size="small"
                style="background-color: #e5f7eb;"
              >
                <mds-inline-message variation="success">
                  {{ row['name'] }}
                </mds-inline-message>
              </mds-td>
            </mds-tr>
          </mds-tbody>
        </mds-table>
        <mds-table v-if="workflowsToRemove.length > 0">
          <mds-thead>
            <mds-th> Removing below workflows </mds-th>
          </mds-thead>
          <mds-tbody>
            <mds-tr
              v-for="(row, index) in workflowsToRemove"
              :key="index + row.name + row.id"
            >
              <mds-td
                size="small"
                style="background-color: #ffe5e5;"
              >
                <mds-inline-message variation="error">
                  {{ row['name'] }}
                </mds-inline-message>
              </mds-td>
            </mds-tr>
          </mds-tbody>
        </mds-table>
      </mds-modal>
    </mds-section>
  </div>
</template>

<script>
import { MdsLayoutGrid, MdsRow, MdsCol } from '@mds/layout-grid';
import MdsSection from '@mds/section';
import {
  MdsTable, MdsThead, MdsTh, MdsTbody, MdsTr, MdsTd,
} from '@mds/data-table';
import { MdsButton, MdsButtonContainer } from '@mds/button';
import MdsForm from '@mds/form';
import MdsInput from '@mds/input';
import MdsPagination from '@mds/pagination';
import MdsEmptyState from '@mds/empty-state';
import MdsLoader from '@mds/loader';
import MdsModal from '@mds/modal';
import MdsInlineMessage from '@mds/inline-message';
import { mapActions, mapGetters } from 'vuex';
import { getUserName } from '../../../utils/authService';

export default {
  name: 'WorkflowSearch',
  components: {
    MdsLayoutGrid,
    MdsCol,
    MdsRow,
    MdsSection,
    MdsTable,
    MdsThead,
    MdsTh,
    MdsTbody,
    MdsTr,
    MdsTd,
    MdsButton,
    MdsButtonContainer,
    MdsForm,
    MdsInput,
    MdsPagination,
    MdsEmptyState,
    MdsLoader,
    MdsModal,
    MdsInlineMessage,
  },
  props: {

  },
  data() {
    return {
      toggle: true,
      searchWorkflowName: '',
      searchWorkflowFeed: '',
      searchWorkflowSymbol: '',
      searchWorkflowOwner: '',
      searchWorkflowCompany: '',
      searchWorkflowID: '',
      searchWorkflowFormula: '',
      searchTableHeaders: [
        {
          text: 'Workflow',
          fieldName: 'name',
        },
        {
          text: 'Owner',
          fieldName: 'owner',
        },
        {
          text: 'Company',
          fieldName: 'company',
        },
      ],
      searchedWorkflowRows: [],
      paginatedSearchedWorkflowRows: [],
      paginationOptions: {
        firstItem: 1,
        pageSize: 15,
      },
      pageSize: [15, 30, 50, -1],
      showSearchLoader: false,
      toggleSaveModal: false,
      toggle600px: false,
      workflowsToAdd: [],
      workflowsToRemove: [],
    };
  },
  computed: {
    ...mapGetters('workflowModule', ['getUserWorkflows']),
    showSearchTable() {
      return this.searchedWorkflowRows.length > 0;
    },
    paginatedData() {
      // set new start and end values based on the updated pagination options
      const start = this.paginationOptions ? this.paginationOptions.firstItem - 1 : 0;
      const end = this.paginationOptions && this.paginationOptions.pageSize !== -1
        ? start + this.paginationOptions.pageSize
        : this.searchedWorkflowRows.length;

      // use the start and end values to return the visible page slice
      return this.searchedWorkflowRows.slice(start, end);
    },
  },
  methods: {
    ...mapActions('workflowModule', ['searchWorkflows', 'updateUserWorkflows']),
    closeModal(event, refresh) {
      this.clearFilters();
      this.toggle = !this.toggle;
      this.$emit('closeModal', refresh);
    },
    calculateAddAndRemove() {
      const checkedWf = this.searchedWorkflowRows.filter(wf => wf.checked);
      // wfs to add
      const userWfIds = this.getUserWorkflows ? this.getUserWorkflows.map(wf => wf.id) : [];
      const alreadyAddedWfs = this.workflowsToAdd.map(wf => wf.id);
      this.workflowsToAdd.push(...(checkedWf.filter(wf => !userWfIds.includes(wf.id) && !alreadyAddedWfs.includes(wf.id))));
      // wfs to remove
      const uncheckedWf = this.searchedWorkflowRows.filter(wf => !wf.checked);
      const alreadyRemovedWfs = this.workflowsToRemove.map(wf => wf.id);
      this.workflowsToRemove.push(...(uncheckedWf.filter(wf => userWfIds.includes(wf.id) && !alreadyRemovedWfs.includes(wf.id))));

      const uncheckedWfIds = uncheckedWf.map(wf => wf.id);
      const checkedWfIds = checkedWf.map(wf => wf.id);
      this.workflowsToAdd = this.workflowsToAdd.filter(wf => !uncheckedWfIds.includes(wf.id));
      this.workflowsToRemove = this.workflowsToRemove.filter(wf => !checkedWfIds.includes(wf.id));
    },
    showSaveWorkflowsModal(isDisabled) {
      if (isDisabled) {
        return;
      }
      this.calculateAddAndRemove();
      this.toggleSaveModal = true;
    },
    saveWorkflows() {
      const username = getUserName();
      this.updateUserWorkflows({
        username,
        workflowsToAdd: this.workflowsToAdd.map(wf => wf.id),
        workflowsToRemove: this.workflowsToRemove.map(wf => wf.id),
      }).finally(() => {
        this.toggleSaveModal = false;
        this.closeModal(undefined, true);
      });
    },
    getWorkflows() {
      this.showSearchLoader = true;
      this.paginationOptions = {
        firstItem: 1,
        pageSize: 15,
      };
      const params = {
        name: this.searchWorkflowName,
        symbol: this.searchWorkflowSymbol,
        feed: this.searchWorkflowFeed,
        owner: this.searchWorkflowOwner,
        company: this.searchWorkflowCompany,
        id: this.searchWorkflowID,
        formula: this.searchWorkflowFormula,
      };
      this.searchWorkflows(params).then((response) => {
        this.searchedWorkflowRows = response.data;
        const userWfIds = this.getUserWorkflows.map(wf => wf.id);
        const alreadyAddedWfs = this.workflowsToAdd.map(wf => wf.id);
        const alreadyRemovedWfs = this.workflowsToRemove.map(wf => wf.id);
        this.searchedWorkflowRows.forEach((row) => {
          if (!alreadyRemovedWfs.includes(row.id) && (userWfIds.includes(row.id) || alreadyAddedWfs.includes(row.id))) {
            row.checked = true;
          }
        });
      }).finally(() => {
        this.showSearchLoader = false;
      });
    },
    handleRowSelectEvent(rowIndex, isChecked) {
      // set the checked property in the row data to the checked value of the checkbox
      if (this.paginatedData[rowIndex].checked !== isChecked) {
        this.$set(this.paginatedData[rowIndex], 'checked', isChecked);
        this.paginatedData[rowIndex].checked = isChecked;
        this.calculateAddAndRemove();
      }
    },
    handlePageChange(paginationOptions) {
      // on page change, retrieve the new pagination options from the pagination component
      this.paginationOptions = paginationOptions;
    },
    clearFilters() {
      this.searchWorkflowName = '';
      this.searchWorkflowFeed = '';
      this.searchWorkflowSymbol = '';
      this.searchWorkflowOwner = '';
      this.searchWorkflowCompany = '';
      this.searchWorkflowID = '';
      this.searchWorkflowFormula = '';
      this.searchedWorkflowRows = [];
    },
  },
};
</script>

<style>
.btn-container-serach {
  margin-top: 5%;
}
</style>
